import React from 'react'
import {connect} from 'react-redux'
import {useSnackbar} from 'notistack'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {manageSubscription} from 'redux/actions/subscriptions/manageSubscription'
import Trialing from './Trialing'
import TrialEndingSoon from './TrialEndingSoon'
import TrialEnded from './TrialEnded'
import Incomplete from './Incomplete'
import IncompleteExpired from './IncompleteExpired'
import Canceled from './Canceled'
import Unpaid from './Unpaid'
import PastDue from './PastDue'
import NewFeatureAnnouncement from './NewFeatureAnnouncement'
import {RenderInDevice} from 'functional-components'

const Wrapper = styled.div``

const StatusBanner = ({currentUser = {}, manageSubscription}) => {
  const subscription = currentUser?.currentAccount?.subscription ?? {}
  const trialEnd = subscription?.trialEnd ?? ''
  const status = subscription?.status ?? ''

  const {enqueueSnackbar} = useSnackbar()

  function renderStatusBanner() {
    if (!Object.keys(subscription).length) return null

    switch (status) {
      case 'trialing':
        return <Trialing trialEnd={trialEnd} />
      case 'trial_ending_soon':
        return <TrialEndingSoon trialEnd={trialEnd} />
      case 'trial_has_ended':
        return <TrialEnded />
      case 'trial_ended_recently':
        return <TrialEnded />
      case 'canceled':
        return <Canceled />
      case 'past_due':
        return <PastDue onClick={handleClickManageSubscription} />
      case 'unpaid':
        return <Unpaid onClick={handleClickManageSubscription} />
      case 'incomplete':
        return <Incomplete onClick={handleClickManageSubscription} />
      case 'incomplete_expired':
        return <IncompleteExpired onClick={handleClickManageSubscription} />
      default:
        // return(
        //   <RenderInDevice devices={['laptop', 'desktop']}>
        //     <NewFeatureAnnouncement />
        //   </RenderInDevice>
        // )
        return null
    }
  }

  function handleClickManageSubscription() {
    manageSubscription(handleManageSubscriptionResponse)
  }

  function handleManageSubscriptionResponse(response) {
    if (response.status === 'success') {
      window.location.href = response.portalSessionUrl
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
    }
  }

  return <Wrapper>{renderStatusBanner()}</Wrapper>
}

StatusBanner.propTypes = {
  currentUser: PropTypes.object.isRequired,
  manageSubscription: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  currentUser: state.profile.currentUser,
})

const mapDispatchToProps = {
  manageSubscription: manageSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBanner)
