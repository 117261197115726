export const FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_REQUEST = 'FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_REQUEST'
export const FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_SUCCESS = 'FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_SUCCESS'
export const FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_FAILURE = 'FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_FAILURE'

export const FETCH_BRIDGING_LOAN_APPLICATION_REQUEST = 'FETCH_BRIDGING_LOAN_APPLICATION_REQUEST'
export const FETCH_BRIDGING_LOAN_APPLICATION_SUCCESS = 'FETCH_BRIDGING_LOAN_APPLICATION_SUCCESS'
export const FETCH_BRIDGING_LOAN_APPLICATION_FAILURE = 'FETCH_BRIDGING_LOAN_APPLICATION_FAILURE'

export const CREATE_BRIDGING_LOAN_APPLICATION_REQUEST = 'CREATE_BRIDGING_LOAN_APPLICATION_REQUEST'
export const CREATE_BRIDGING_LOAN_APPLICATION_SUCCESS = 'CREATE_BRIDGING_LOAN_APPLICATION_SUCCESS'
export const CREATE_BRIDGING_LOAN_APPLICATION_FAILURE = 'CREATE_BRIDGING_LOAN_APPLICATION_FAILURE'

export const UPDATE_BRIDGING_LOAN_APPLICATION_REQUEST = 'UPDATE_BRIDGING_LOAN_APPLICATION_REQUEST'
export const UPDATE_BRIDGING_LOAN_APPLICATION_SUCCESS = 'UPDATE_BRIDGING_LOAN_APPLICATION_SUCCESS'
export const UPDATE_BRIDGING_LOAN_APPLICATION_FAILURE = 'UPDATE_BRIDGING_LOAN_APPLICATION_FAILURE'

export const ARCHIVE_BRIDGING_LOAN_APPLICATION_REQUEST = 'ARCHIVE_BRIDGING_LOAN_APPLICATION_REQUEST'
export const ARCHIVE_BRIDGING_LOAN_APPLICATION_SUCCESS = 'ARCHIVE_BRIDGING_LOAN_APPLICATION_SUCCESS'
export const ARCHIVE_BRIDGING_LOAN_APPLICATION_FAILURE = 'ARCHIVE_BRIDGING_LOAN_APPLICATION_FAILURE'

export const UNARCHIVE_BRIDGING_LOAN_APPLICATION_REQUEST = 'UNARCHIVE_BRIDGING_LOAN_APPLICATION_REQUEST'
export const UNARCHIVE_BRIDGING_LOAN_APPLICATION_SUCCESS = 'UNARCHIVE_BRIDGING_LOAN_APPLICATION_SUCCESS'
export const UNARCHIVE_BRIDGING_LOAN_APPLICATION_FAILURE = 'UNARCHIVE_BRIDGING_LOAN_APPLICATION_FAILURE'

export const DELETE_BRIDGING_LOAN_APPLICATION_REQUEST = 'DELETE_BRIDGING_LOAN_APPLICATION_REQUEST'
export const DELETE_BRIDGING_LOAN_APPLICATION_SUCCESS = 'DELETE_BRIDGING_LOAN_APPLICATION_SUCCESS'
export const DELETE_BRIDGING_LOAN_APPLICATION_FAILURE = 'DELETE_BRIDGING_LOAN_APPLICATION_FAILURE'