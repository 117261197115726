import React from 'react'
import Navbar from 'sharedComponents/Navbar.js'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import Brand from 'sharedComponents/UserTopNavbar/Brand.js'
import Links from 'sharedComponents/PublicTopNavbar/Links.js'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;

  #navbar {
    padding-left: ${props => props.theme.space.l};
    padding-right: ${props => props.theme.space.l};
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
  }
`

function PublicTopNavbar(props) {
  let backgroundColor

  if (props.navbarTheme === 'white') {
    backgroundColor = props => props.theme.colors.white
  } else {
    backgroundColor = props => props.theme.colors.primary
  }

  return (
    <Wrapper>
      <Navbar backgroundColor={backgroundColor} height={props.height}>
        <Row>
          <Col xs="4" sm="4">
            <Brand navbarTheme={props.navbarTheme} />
          </Col>
          <Col xs="8" sm="8">
            {/* <Links navbarTheme={props.navbarTheme} /> */}
          </Col>
        </Row>
      </Navbar>
    </Wrapper>
  )
}

export default PublicTopNavbar

PublicTopNavbar.propTypes = {
  navbarTheme: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}
