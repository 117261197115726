import React from 'react';
import styled from 'styled-components';
import TopNavbar from 'sharedComponents/UserTopNavbar/UserTopNavbar.js';
import PublicFooter from 'sharedComponents/PublicFooter/PublicFooter.js';
import PricingContent from 'spas/Pricing/PricingContent'
import SimplifyAndSave from 'spas/Pricing/SimplifyAndSave'
import CommonQuestions from 'spas/Pricing/CommonQuestions'
import { Helmet } from "react-helmet-async";

const Wrapper = styled.div``

function Pricing() {
  return (
    <Wrapper>
      <Helmet>
        <title>Property Investment Platform: Pricing - Stacked</title>
        <meta
          name="description"
          content="Stacked provides an all-in-one platform for building and scaling a property investment business. See how much it costs."
        />
      </Helmet>

      <TopNavbar />
      <PricingContent />
      <SimplifyAndSave />
      <CommonQuestions />
      <PublicFooter />
    </Wrapper>
  );
};

export default Pricing;
