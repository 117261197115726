export const FETCH_PRODUCT_TRANSFER_DEAL_REQUEST = 'FETCH_PRODUCT_TRANSFER_DEAL_REQUEST'
export const FETCH_PRODUCT_TRANSFER_DEAL_SUCCESS = 'FETCH_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const FETCH_PRODUCT_TRANSFER_DEAL_FAILURE = 'FETCH_PRODUCT_TRANSFER_DEAL_FAILURE'

export const UPDATE_PRODUCT_TRANSFER_DEAL_REQUEST = 'UPDATE_PRODUCT_TRANSFER_DEAL_REQUEST'
export const UPDATE_PRODUCT_TRANSFER_DEAL_SUCCESS = 'UPDATE_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const UPDATE_PRODUCT_TRANSFER_DEAL_FAILURE = 'UPDATE_PRODUCT_TRANSFER_DEAL_FAILURE'

export const ARCHIVE_PRODUCT_TRANSFER_DEAL_REQUEST = 'ARCHIVE_PRODUCT_TRANSFER_DEAL_REQUEST'
export const ARCHIVE_PRODUCT_TRANSFER_DEAL_SUCCESS = 'ARCHIVE_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const ARCHIVE_PRODUCT_TRANSFER_DEAL_FAILURE = 'ARCHIVE_PRODUCT_TRANSFER_DEAL_FAILURE'

export const UNARCHIVE_PRODUCT_TRANSFER_DEAL_REQUEST = 'UNARCHIVE_PRODUCT_TRANSFER_DEAL_REQUEST'
export const UNARCHIVE_PRODUCT_TRANSFER_DEAL_SUCCESS = 'UNARCHIVE_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const UNARCHIVE_PRODUCT_TRANSFER_DEAL_FAILURE = 'UNARCHIVE_PRODUCT_TRANSFER_DEAL_FAILURE'

export const DELETE_PRODUCT_TRANSFER_DEAL_REQUEST = 'DELETE_PRODUCT_TRANSFER_DEAL_REQUEST'
export const DELETE_PRODUCT_TRANSFER_DEAL_SUCCESS = 'DELETE_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const DELETE_PRODUCT_TRANSFER_DEAL_FAILURE = 'DELETE_PRODUCT_TRANSFER_DEAL_FAILURE'