import update from 'immutability-helper'
import { FETCH_RENT_COMPARABLE_REQUEST } from 'v2/spas/prospective-property/rent-comparables/redux/action-types.tsx'
import { FETCH_RENT_COMPARABLE_SUCCESS } from 'v2/spas/prospective-property/rent-comparables/redux/action-types.tsx'
import { FETCH_RENT_COMPARABLE_FAILURE } from 'v2/spas/prospective-property/rent-comparables/redux/action-types.tsx'
import { UPDATE_RENT_COMPARABLE_REQUEST } from 'v2/spas/prospective-property/rent-comparables/redux/action-types.tsx'
import { UPDATE_RENT_COMPARABLE_SUCCESS } from 'v2/spas/prospective-property/rent-comparables/redux/action-types.tsx'
import { UPDATE_RENT_COMPARABLE_FAILURE } from 'v2/spas/prospective-property/rent-comparables/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  rentComparable: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_RENT_COMPARABLE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_RENT_COMPARABLE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_RENT_COMPARABLE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        rentComparable: {
          $set: action.payload.rentComparable,
        }
      })
    }

    case UPDATE_RENT_COMPARABLE_REQUEST: {
      return update(state, {
        updateRentComparableStatus: { $set: 'requested' },
      })
    }

    case UPDATE_RENT_COMPARABLE_FAILURE: {
      return update(state, {
        updateRentComparableStatus: {
          $set: 'error',
        },
        updateRentComparableError: { $set: action.payload.error },
      })
    }

    case UPDATE_RENT_COMPARABLE_SUCCESS: {
      return update(state, {
        updateRentComparableStatus: { $set: 'success' },
        rentComparable: {
          $set: action.payload.rentComparable,
        }
      })
    }

    default:
      return state
  }
}