import update from 'immutability-helper'
import {FETCH_RESOURCE_CATEGORIES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_RESOURCE_CATEGORIES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_RESOURCE_CATEGORIES_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchResourceCategoriesStatus: 'requested',
  fetchResourceCategoriesError: '',
  resourceCategories: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_RESOURCE_CATEGORIES_REQUEST: {
      return update(state, {
        fetchResourceCategoriesStatus: {$set: 'requested'},
      })
    }

    case FETCH_RESOURCE_CATEGORIES_FAILURE: {
      return update(state, {
        fetchResourceCategoriesStatus: {
          $set: 'error',
        },
        fetchResourceCategoriesError: {$set: action.payload.error},
      })
    }

    case FETCH_RESOURCE_CATEGORIES_SUCCESS: {
      return update(state, {
        resourceCategories: {
          $set: action.payload.resourceCategories,
        },
      })
    }

    default:
      return state
  }
}
