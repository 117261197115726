import React, {useState} from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {SubmitButton} from 'buttons'
import TextInput from 'sharedComponents/Forms/TextInput.js'
import {setPassword} from 'redux/actions/profile/setPassword'
import {connect} from 'react-redux'
import {useSnackbar} from 'notistack'

const FormSchema = Yup.object().shape({
  email: Yup.string().email().required('Required field *'),
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number',
    ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
})

function SetPasswordForm(props) {
  const {enqueueSnackbar} = useSnackbar()

  let currentEmailIdentity = props.currentEmailIdentity

  function handleResponse(response) {
    if (response.status === 'success') {
      enqueueSnackbar('Password updated successfully!')
      window.location.href = '/'
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      window.setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: currentEmailIdentity.email,
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={FormSchema}
        onSubmit={(values, {setSubmitting}) => {
          props.setPassword(currentEmailIdentity.id, values, handleResponse)
        }}
      >
        {({isSubmitting}) => (
          <Form>
            <TextInput disabled label="Email" name="email" type="email" />

            <TextInput label="Password" name="password" type="password" />

            <TextInput
              label="Confirm Password"
              name="passwordConfirmation"
              type="password"
            />

            <SubmitButton content="Set Password" isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const mapDispatchToProps = {
  setPassword: setPassword,
}

export default connect(null, mapDispatchToProps)(SetPasswordForm)
