export const FETCH_OWNED_PROPERTY_APPLIANCES_REQUEST = 'FETCH_OWNED_PROPERTY_APPLIANCES_REQUEST'
export const FETCH_OWNED_PROPERTY_APPLIANCES_SUCCESS = 'FETCH_OWNED_PROPERTY_APPLIANCES_SUCCESS'
export const FETCH_OWNED_PROPERTY_APPLIANCES_FAILURE = 'FETCH_OWNED_PROPERTY_APPLIANCES_FAILURE'

export const FETCH_APPLIANCE_REQUEST = 'FETCH_APPLIANCE_REQUEST'
export const FETCH_APPLIANCE_SUCCESS = 'FETCH_APPLIANCE_SUCCESS'
export const FETCH_APPLIANCE_FAILURE = 'FETCH_APPLIANCE_FAILURE'

export const CREATE_APPLIANCE_REQUEST = 'CREATE_APPLIANCE_REQUEST'
export const CREATE_APPLIANCE_SUCCESS = 'CREATE_APPLIANCE_SUCCESS'
export const CREATE_APPLIANCE_FAILURE = 'CREATE_APPLIANCE_FAILURE'

export const UPDATE_APPLIANCE_REQUEST = 'UPDATE_APPLIANCE_REQUEST'
export const UPDATE_APPLIANCE_SUCCESS = 'UPDATE_APPLIANCE_SUCCESS'
export const UPDATE_APPLIANCE_FAILURE = 'UPDATE_APPLIANCE_FAILURE'

export const ARCHIVE_APPLIANCE_REQUEST = 'ARCHIVE_APPLIANCE_REQUEST'
export const ARCHIVE_APPLIANCE_SUCCESS = 'ARCHIVE_APPLIANCE_SUCCESS'
export const ARCHIVE_APPLIANCE_FAILURE = 'ARCHIVE_APPLIANCE_FAILURE'

export const UNARCHIVE_APPLIANCE_REQUEST = 'UNARCHIVE_APPLIANCE_REQUEST'
export const UNARCHIVE_APPLIANCE_SUCCESS = 'UNARCHIVE_APPLIANCE_SUCCESS'
export const UNARCHIVE_APPLIANCE_FAILURE = 'UNARCHIVE_APPLIANCE_FAILURE'

export const DELETE_APPLIANCE_REQUEST = 'DELETE_APPLIANCE_REQUEST'
export const DELETE_APPLIANCE_SUCCESS = 'DELETE_APPLIANCE_SUCCESS'
export const DELETE_APPLIANCE_FAILURE = 'DELETE_APPLIANCE_FAILURE'
