import React from 'react'
import Container from 'react-bootstrap/Container'
import TopNavbar from 'sharedComponents/UserTopNavbar/UserTopNavbar.js'
import Page from 'sharedComponents/Page.js'
import styled from 'styled-components'
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation'
import {ReturnToSafetyButton} from 'buttons'

const IconWrapper = styled.div`
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
`

const Text = styled.div`
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 32px;
`

function PageNotFound(props) {
  return (
    <Container fluid>
      <TopNavbar />
      <Page paddingTop="75px">
        <IconWrapper>
          <NotListedLocationIcon fontSize="large" />
        </IconWrapper>
        <Text>Page not found</Text>
        <ReturnToSafetyButton />
      </Page>
    </Container>
  )
}

export default PageNotFound
