export const FETCH_FURTHER_ADVANCE_DEAL_REQUEST = 'FETCH_FURTHER_ADVANCE_DEAL_REQUEST'
export const FETCH_FURTHER_ADVANCE_DEAL_SUCCESS = 'FETCH_FURTHER_ADVANCE_DEAL_SUCCESS'
export const FETCH_FURTHER_ADVANCE_DEAL_FAILURE = 'FETCH_FURTHER_ADVANCE_DEAL_FAILURE'

export const UPDATE_FURTHER_ADVANCE_DEAL_REQUEST = 'UPDATE_FURTHER_ADVANCE_DEAL_REQUEST'
export const UPDATE_FURTHER_ADVANCE_DEAL_SUCCESS = 'UPDATE_FURTHER_ADVANCE_DEAL_SUCCESS'
export const UPDATE_FURTHER_ADVANCE_DEAL_FAILURE = 'UPDATE_FURTHER_ADVANCE_DEAL_FAILURE'

export const ARCHIVE_FURTHER_ADVANCE_DEAL_REQUEST = 'ARCHIVE_FURTHER_ADVANCE_DEAL_REQUEST'
export const ARCHIVE_FURTHER_ADVANCE_DEAL_SUCCESS = 'ARCHIVE_FURTHER_ADVANCE_DEAL_SUCCESS'
export const ARCHIVE_FURTHER_ADVANCE_DEAL_FAILURE = 'ARCHIVE_FURTHER_ADVANCE_DEAL_FAILURE'

export const UNARCHIVE_FURTHER_ADVANCE_DEAL_REQUEST = 'UNARCHIVE_FURTHER_ADVANCE_DEAL_REQUEST'
export const UNARCHIVE_FURTHER_ADVANCE_DEAL_SUCCESS = 'UNARCHIVE_FURTHER_ADVANCE_DEAL_SUCCESS'
export const UNARCHIVE_FURTHER_ADVANCE_DEAL_FAILURE = 'UNARCHIVE_FURTHER_ADVANCE_DEAL_FAILURE'

export const DELETE_FURTHER_ADVANCE_DEAL_REQUEST = 'DELETE_FURTHER_ADVANCE_DEAL_REQUEST'
export const DELETE_FURTHER_ADVANCE_DEAL_SUCCESS = 'DELETE_FURTHER_ADVANCE_DEAL_SUCCESS'
export const DELETE_FURTHER_ADVANCE_DEAL_FAILURE = 'DELETE_FURTHER_ADVANCE_DEAL_FAILURE'