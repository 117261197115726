import update from 'immutability-helper'
import {FETCH_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_TEMPLATES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_TEMPLATES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_TEMPLATES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_REMORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_REMORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_REMORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_BRIDGING_LOAN_REQUEST} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_BRIDGING_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_HMO_DEAL_BRIDGING_LOAN_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_SUPPORT_NEEDED_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_SUPPORT_NEEDED_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_HMO_DEAL_SUPPORT_NEEDED_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchHmoDealStatus: 'requested',
  fetchHmoDealError: '',
  hmoDeal: {
    deal: {},
  },
  fetchHmoDealTemplatesStatus: 'requested',
  fetchHmoDealTemplatesError: '',
  dealTemplates: [],
  fetchHmoDealFixedPurchaseExpensesStatus: 'requested',
  fetchHmoDealFixedPurchaseExpensesError: '',
  fixedPurchaseExpenses: [],
  fetchHmoDealVariablePurchaseExpensesStatus: 'requested',
  fetchHmoDealVariablePurchaseExpensesError: '',
  variablePurchaseExpenses: [],
  fetchHmoDealDevelopmentExpensesStatus: 'requested',
  fetchHmoDealDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchHmoDealFixedRecurringExpensesStatus: 'requested',
  fetchHmoDealFixedRecurringExpensesError: '',
  fixedRecurringExpenses: [],
  fetchHmoDealVariableRecurringExpensesStatus: 'requested',
  fetchHmoDealVariableRecurringExpensesError: '',
  variableRecurringExpenses: [],
  fetchHmoDealDuringDevelopmentExpensesStatus: 'requested',
  fetchHmoDealDuringDevelopmentExpensesError: '',
  duringDevelopmentExpenses: [],
  fetchHmoDealMortgageStatus: 'requested',
  fetchHmoDealMortgageError: '',
  mortgage: {},
  fetchHmoDealRemortgageStatus: 'requested',
  fetchHmoDealRemortgageError: '',
  remortgage: {},
  fetchHmoDealCashFlowSummaryStatus: 'requested',
  fetchHmoDealCashFlowSummaryError: '',
  cashFlowSummary: {
    schedule: [],
  },
  fetchHmoDealBridgingLoanStatus: 'requested',
  fetchHmoDealBridgingLoanError: '',
  bridgingLoan: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_HMO_DEAL_REQUEST: {
      return update(state, {
        fetchHmoDealStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_FAILURE: {
      return update(state, {
        fetchHmoDealStatus: {
          $set: 'error',
        },
        fetchHmoDealError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_SUCCESS: {
      return update(state, {
        fetchHmoDealStatus: {$set: 'success'},
        hmoDeal: {
          $set: action.payload.hmoDeal,
        },
      })
    }

    case FETCH_HMO_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        fetchHmoDealTemplatesStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        fetchHmoDealTemplatesStatus: {
          $set: 'error',
        },
        fetchHmoDealTemplatesError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchHmoDealTemplatesStatus: {$set: 'success'},
        dealTemplates: {
          $set: action.payload.dealTemplates,
        },
      })
    }

    case FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchHmoDealFixedPurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchHmoDealFixedPurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchHmoDealFixedPurchaseExpensesError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchHmoDealFixedPurchaseExpensesStatus: {$set: 'success'},
        fixedPurchaseExpenses: {
          $set: action.payload.fixedPurchaseExpenses,
        },
      })
    }

    case FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchHmoDealVariablePurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchHmoDealVariablePurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchHmoDealVariablePurchaseExpensesError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchHmoDealVariablePurchaseExpensesStatus: {$set: 'success'},
        variablePurchaseExpenses: {
          $set: action.payload.variablePurchaseExpenses,
        },
      })
    }

    case FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchHmoDealDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchHmoDealDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchHmoDealDevelopmentExpensesError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchHmoDealDevelopmentExpensesStatus: {$set: 'success'},
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchHmoDealFixedRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchHmoDealFixedRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchHmoDealFixedRecurringExpensesError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchHmoDealFixedRecurringExpensesStatus: {$set: 'success'},
        fixedRecurringExpenses: {
          $set: action.payload.fixedRecurringExpenses,
        },
      })
    }

    case FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchHmoDealVariableRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchHmoDealVariableRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchHmoDealVariableRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchHmoDealVariableRecurringExpensesStatus: {$set: 'success'},
        variableRecurringExpenses: {
          $set: action.payload.variableRecurringExpenses,
        },
      })
    }

    case FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchHmoDealDuringDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchHmoDealDuringDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchHmoDealDuringDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchHmoDealDuringDevelopmentExpensesStatus: {$set: 'success'},
        duringDevelopmentExpenses: {
          $set: action.payload.duringDevelopmentExpenses,
        },
      })
    }

    case FETCH_HMO_DEAL_MORTGAGE_REQUEST: {
      return update(state, {
        fetchHmoDealMortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_MORTGAGE_FAILURE: {
      return update(state, {
        fetchHmoDealMortgageStatus: {
          $set: 'error',
        },
        fetchHmoDealMortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_MORTGAGE_SUCCESS: {
      return update(state, {
        fetchHmoDealMortgageStatus: {$set: 'success'},
        mortgage: {
          $set: action.payload.mortgage,
        },
      })
    }

    case FETCH_HMO_DEAL_REMORTGAGE_REQUEST: {
      return update(state, {
        fetchHmoDealRemortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_REMORTGAGE_FAILURE: {
      return update(state, {
        fetchHmoDealRemortgageStatus: {
          $set: 'error',
        },
        fetchHmoDealRemortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_REMORTGAGE_SUCCESS: {
      return update(state, {
        fetchHmoDealRemortgageStatus: {$set: 'success'},
        remortgage: {
          $set: action.payload.remortgage,
        },
      })
    }

    case FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return update(state, {
        fetchHmoDealCashFlowSummaryStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return update(state, {
        fetchHmoDealCashFlowSummaryStatus: {
          $set: 'error',
        },
        fetchHmoDealCashFlowSummaryError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        fetchHmoDealCashFlowSummaryStatus: {$set: 'success'},
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_HMO_DEAL_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_HMO_DEAL_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_HMO_DEAL_EXPENSES_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          totalProfit: { $set: action.payload.hmoDeal.totalProfit },
          totalProjectCost: { $set: action.payload.hmoDeal.totalProjectCost },
          developmentCosts: {$set: action.payload.hmoDeal.developmentCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.hmoDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.hmoDeal.bridgingLoan,
        },
      })
    }

    case CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        hmoDeal: {
          developmentCosts: {$set: action.payload.hmoDeal.developmentCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        hmoDeal: {
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.hmoDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.hmoDeal.mortgage,
        },
      })
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          returnOnInvestment: {
            $set: action.payload.hmoDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.hmoDeal.remortgage,
        },
      })
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.hmoDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.hmoDeal.mortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.hmoDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          prospectiveMortgage: {
            term: {
              $set: action.payload.hmoDeal.mortgage.prospectiveMortgage.term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.hmoDeal.mortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.hmoDeal.mortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.hmoDeal.mortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.hmoDeal.mortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.hmoDeal.mortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.hmoDeal.remortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          prospectiveMortgage: {
            term: {
              $set: action.payload.hmoDeal.remortgage.prospectiveMortgage.term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.hmoDeal.remortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.hmoDeal.remortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.hmoDeal.remortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
          },
        },
      })
    }

    case RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return state
    }

    case RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return state
    }

    case RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_HMO_DEAL_REQUEST: {
      return state
    }

    case UPDATE_HMO_DEAL_FAILURE: {
      return state
    }

    case UPDATE_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseType: {$set: action.payload.hmoDeal.purchaseType},
          purchasePrice: {$set: action.payload.hmoDeal.purchasePrice},
          totalProfit: { $set: action.payload.hmoDeal.totalProfit },
          totalProjectCost: { $set: action.payload.hmoDeal.totalProjectCost },
          entryFinanceValue: { $set: action.payload.hmoDeal.entryFinanceValue },
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          developmentCosts: {$set: action.payload.hmoDeal.developmentCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyRent: {$set: action.payload.hmoDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          endValue: {$set: action.payload.hmoDeal.endValue},
          stampDuty: { $set: action.payload.hmoDeal.stampDuty },
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
          purchaseTerm: {$set: action.payload.hmoDeal.purchaseTerm},
          refurbTerm: {$set: action.payload.hmoDeal.refurbTerm},
          refinanceTerm: {$set: action.payload.hmoDeal.refinanceTerm},
        },
        mortgage: {
          $set: action.payload.hmoDeal.mortgage,
        },
        remortgage: {
          $set: action.payload.hmoDeal.remortgage,
        },
        bridgingLoan: {
          $set: action.payload.hmoDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          deal: {
            name: {
              $set: action.payload.deal.name,
            },
          },
        },
      })
    }

    case CLEAR_EXPENSES_REQUEST: {
      return state
    }

    case CLEAR_EXPENSES_FAILURE: {
      return state
    }

    case CLEAR_EXPENSES_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          developmentCosts: {$set: action.payload.hmoDeal.developmentCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
          mortgage: {
            $set: action.payload.hmoDeal.mortgage,
          },
        },
      })
    }

    case FETCH_HMO_DEAL_BRIDGING_LOAN_REQUEST: {
      return update(state, {
        fetchHmoDealBridgingLoanStatus: {$set: 'requested'},
      })
    }

    case FETCH_HMO_DEAL_BRIDGING_LOAN_FAILURE: {
      return update(state, {
        fetchHmoDealBridgingLoanStatus: {
          $set: 'error',
        },
        fetchHmoDealBridgingLoanError: {$set: action.payload.error},
      })
    }

    case FETCH_HMO_DEAL_BRIDGING_LOAN_SUCCESS: {
      return update(state, {
        fetchHmoDealBridgingLoanStatus: {$set: 'success'},
        bridgingLoan: {
          $set: action.payload.bridgingLoan,
        },
      })
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.hmoDeal.monthlyExpenses },
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.hmoDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.hmoDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          prospectiveBridgingLoan: {
            term: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .term,
            },
            interestRate: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .interestRate,
            },
            repaymentType: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .repaymentType,
            },
            netAmount: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .netAmount,
            },
            interestAmount: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .interestAmount,
            },
            rebateAmount: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .rebateAmount,
            },
            monthlyPayment: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .monthlyPayment,
            },
            developmentFinanceBuffer: {
              $set:
                action.payload.hmoDeal.bridgingLoan.prospectiveBridgingLoan
                  .developmentFinanceBuffer,
            },
          },
        },
      })
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_SUCCESS: {
      return update(state, {
        hmoDeal: {
          purchaseCosts: {$set: action.payload.hmoDeal.purchaseCosts},
          cashRequired: {$set: action.payload.hmoDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.hmoDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.hmoDeal.duringDevelopmentCosts },
          monthlyCashFlow: {$set: action.payload.hmoDeal.monthlyCashFlow},
          carryingCosts: { $set: action.payload.hmoDeal.carryingCosts },
          annualProfit: {$set: action.payload.hmoDeal.annualProfit},
          returnOnInvestment: {$set: action.payload.hmoDeal.returnOnInvestment},
          projectedMonthlyCashFlow: {
            $set: action.payload.hmoDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.hmoDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.hmoDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.hmoDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.hmoDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.hmoDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_HMO_DEAL_SUPPORT_NEEDED_REQUEST: {
      return state
    }

    case UPDATE_HMO_DEAL_SUPPORT_NEEDED_FAILURE: {
      return state
    }

    case UPDATE_HMO_DEAL_SUPPORT_NEEDED_SUCCESS: {
      return update(state, {
        hmoDeal: {
          deal: {
            stackingSupportNeeded: {
              $set: action.payload.stackingSupportNeeded
            }
          },
        },
      })
    }

    default:
      return state
  }
}
