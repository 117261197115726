import update from 'immutability-helper'
import { FETCH_ACCOUNT_USER_WORKFLOW_REPORT_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_WORKFLOW_REPORT_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_WORKFLOW_REPORT_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  fetchCurrentAccountUserStatus: 'requested',
  fetchCurrentAccountUserError: '',
  currentAccountUser: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_USER_WORKFLOW_REPORT_REQUEST: {
      return update(state, {
        fetchCurrentAccountUserStatus: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_WORKFLOW_REPORT_FAILURE: {
      return update(state, {
        fetchCurrentAccountUserStatus: {
          $set: 'error',
        },
        fetchCurrentAccountUserError: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_WORKFLOW_REPORT_SUCCESS: {
      return update(state, {
        fetchCurrentAccountUserStatus: { $set: 'success' },
        currentAccountUser: {
          $set: action.payload.currentAccountUser,
        },
      })
    }

    default:
      return state
  }
}
