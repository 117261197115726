import update from 'immutability-helper'
import {FETCH_DEAL_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_DEAL_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_DEAL_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_DEAL_TEMPLATE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_DEAL_TEMPLATE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_DEAL_TEMPLATE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_DEAL_TEMPLATE_EXPENSE_REQUEST} from 'redux/actionTypes.js'
import {DELETE_DEAL_TEMPLATE_EXPENSE_FAILURE} from 'redux/actionTypes.js'
import {DELETE_DEAL_TEMPLATE_EXPENSE_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchDealTemplateStatus: 'requested',
  fetchDealTemplateError: '',
  dealTemplate: {
    purchaseExpenses: [],
    developmentExpenses: [],
    duringDevelopmentExpenses: [],
    recurringExpenses: [],
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DEAL_TEMPLATE_REQUEST: {
      return update(state, {
        fetchDealTemplateStatus: {$set: 'requested'},
      })
    }

    case FETCH_DEAL_TEMPLATE_FAILURE: {
      return update(state, {
        fetchDealTemplateStatus: {
          $set: 'error',
        },
        fetchDealTemplateError: {$set: action.payload.error},
      })
    }

    case FETCH_DEAL_TEMPLATE_SUCCESS: {
      return update(state, {
        fetchDealTemplateStatus: {$set: 'success'},
        dealTemplate: {
          $set: action.payload.dealTemplate,
        },
      })
    }

    case UPDATE_DEAL_TEMPLATE_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_DEAL_TEMPLATE_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_DEAL_TEMPLATE_EXPENSES_SUCCESS: {
      return Object.assign({}, state, {
        dealTemplate: action.payload.dealTemplate,
      })
    }

    case DELETE_DEAL_TEMPLATE_EXPENSE_REQUEST: {
      return state
    }

    case DELETE_DEAL_TEMPLATE_EXPENSE_FAILURE: {
      return state
    }

    case DELETE_DEAL_TEMPLATE_EXPENSE_SUCCESS: {
      return update(state, {
        dealTemplate: {
          $set: action.payload.dealTemplate,
        },
      })
    }

    default:
      return state
  }
}
