import update from 'immutability-helper'
import { FETCH_PROPERTY_OWNER_REQUEST } from 'v2/spas/owned-property/property-owners/redux/action-types.tsx'
import { FETCH_PROPERTY_OWNER_SUCCESS } from 'v2/spas/owned-property/property-owners/redux/action-types.tsx'
import { FETCH_PROPERTY_OWNER_FAILURE } from 'v2/spas/owned-property/property-owners/redux/action-types.tsx'
import { UPDATE_PROPERTY_OWNER_REQUEST } from 'v2/spas/owned-property/property-owners/redux/action-types.tsx'
import { UPDATE_PROPERTY_OWNER_SUCCESS } from 'v2/spas/owned-property/property-owners/redux/action-types.tsx'
import { UPDATE_PROPERTY_OWNER_FAILURE } from 'v2/spas/owned-property/property-owners/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  propertyOwner: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTY_OWNER_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROPERTY_OWNER_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROPERTY_OWNER_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        propertyOwner: {
          $set: action.payload.propertyOwner,
        }
      })
    }

    case UPDATE_PROPERTY_OWNER_REQUEST: {
      return update(state, {
        updatePropertyOwnerStatus: { $set: 'requested' },
      })
    }

    case UPDATE_PROPERTY_OWNER_FAILURE: {
      return update(state, {
        updatePropertyOwnerStatus: {
          $set: 'error',
        },
        updatePropertyOwnerError: { $set: action.payload.error },
      })
    }

    case UPDATE_PROPERTY_OWNER_SUCCESS: {
      return update(state, {
        updatePropertyOwnerStatus: { $set: 'success' },
        propertyOwner: {
          $set: action.payload.propertyOwner,
        }
      })
    }

    default:
      return state
  }
}