import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'buttons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ButtonContainer = styled.div`
  width: 100%;
  padding-top: ${props => props.theme.space.xl};

  #button {
    width: 100%;
    margin-bottom: ${props => props.theme.space.m};
  }

  @media ${props => props.theme.devices.desktop} {
    #button {
      width: 90%;
    }
  }
`;

function PricingButtons({interval, handleOnClickInterval}) {
  function getStyles(selected) {
    return selected ? { 
      backgroundColor: props => props.theme.colors.green, 
      color: props => props.theme.colors.white, 
      opacity: 1 
    } : { 
      backgroundColor: props => props.theme.colors.offWhite, 
      color: props => props.theme.colors.grey, 
      opacity: 0.6 
    }
  };
  return (
    <ButtonContainer>
      <Row>
        <Col lg='3' />
        <Col xs='12' lg='3'>
          <Button
            content="Monthly"
            onClick={() => handleOnClickInterval('month')}
            {...getStyles(interval === 'month')}
          />
        </Col>
        <Col xs='12' lg='3'>
          <Button
            content="Annual (2 months free)"
            onClick={() => handleOnClickInterval('annual')}
            {...getStyles(interval === 'annual')}
          />
        </Col>
        <Col lg='3' />
      </Row>
    </ButtonContainer>
  )
}

PricingButtons.propTypes = {
  interval: PropTypes.string.isRequired,
  handleOnClickInterval: PropTypes.func.isRequired,
}

export default PricingButtons