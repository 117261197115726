export const FETCH_OWNED_PROPERTY_LICENCES_REQUEST = 'FETCH_OWNED_PROPERTY_LICENCES_REQUEST'
export const FETCH_OWNED_PROPERTY_LICENCES_SUCCESS = 'FETCH_OWNED_PROPERTY_LICENCES_SUCCESS'
export const FETCH_OWNED_PROPERTY_LICENCES_FAILURE = 'FETCH_OWNED_PROPERTY_LICENCES_FAILURE'

export const FETCH_LICENCE_REQUEST = 'FETCH_LICENCE_REQUEST'
export const FETCH_LICENCE_SUCCESS = 'FETCH_LICENCE_SUCCESS'
export const FETCH_LICENCE_FAILURE = 'FETCH_LICENCE_FAILURE'

export const CREATE_LICENCE_REQUEST = 'CREATE_LICENCE_REQUEST'
export const CREATE_LICENCE_SUCCESS = 'CREATE_LICENCE_SUCCESS'
export const CREATE_LICENCE_FAILURE = 'CREATE_LICENCE_FAILURE'

export const UPDATE_LICENCE_REQUEST = 'UPDATE_LICENCE_REQUEST'
export const UPDATE_LICENCE_SUCCESS = 'UPDATE_LICENCE_SUCCESS'
export const UPDATE_LICENCE_FAILURE = 'UPDATE_LICENCE_FAILURE'

export const ARCHIVE_LICENCE_REQUEST = 'ARCHIVE_LICENCE_REQUEST'
export const ARCHIVE_LICENCE_SUCCESS = 'ARCHIVE_LICENCE_SUCCESS'
export const ARCHIVE_LICENCE_FAILURE = 'ARCHIVE_LICENCE_FAILURE'

export const UNARCHIVE_LICENCE_REQUEST = 'UNARCHIVE_LICENCE_REQUEST'
export const UNARCHIVE_LICENCE_SUCCESS = 'UNARCHIVE_LICENCE_SUCCESS'
export const UNARCHIVE_LICENCE_FAILURE = 'UNARCHIVE_LICENCE_FAILURE'

export const DELETE_LICENCE_REQUEST = 'DELETE_LICENCE_REQUEST'
export const DELETE_LICENCE_SUCCESS = 'DELETE_LICENCE_SUCCESS'
export const DELETE_LICENCE_FAILURE = 'DELETE_LICENCE_FAILURE'