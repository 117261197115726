import React from 'react'
import PropTypes from 'prop-types'
import StatusWrapper from 'sharedComponents/StatusBanner/StatusContainer.js'
import styled from 'styled-components'

const NewFeatureHeader = styled.div`
  display: flex;
  font-weight: 800;
  padding-right: ${props => props.theme.space.s};
`

const ReadMoreLink = styled.div`
  display: flex;
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  padding-left: ${props => props.theme.space.s};

  a {
    color: ${props => props.theme.colors.white};
  }

  a:hover {
    color: ${props => props.theme.colors.white};
  }
`

function NewFeatureAnnouncement(props) {
  return (
    <StatusWrapper background={props => props.theme.colors.lightGrey}>
      <NewFeatureHeader>
        New on Stacked: 
      </NewFeatureHeader>
      Improved Text Editor for Workflows.
      <ReadMoreLink>
        <a
          href="https://blog.stacked.group/new-on-stacked-improved-text-editor-for-workflows"
          target="_blank"
          rel="noopener noreferrer"
        >
          Full details here
        </a>
      </ReadMoreLink>
    </StatusWrapper>
  )
}

NewFeatureAnnouncement.propTypes = {
  trialEnd: PropTypes.string.isRequired,
}

export default NewFeatureAnnouncement
