import update from 'immutability-helper'
import { FETCH_TENANCY_GUARANTORS_REQUEST } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { FETCH_TENANCY_GUARANTORS_SUCCESS } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { FETCH_TENANCY_GUARANTORS_FAILURE } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { ARCHIVE_GUARANTOR_REQUEST } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { ARCHIVE_GUARANTOR_SUCCESS } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { ARCHIVE_GUARANTOR_FAILURE } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { DELETE_GUARANTOR_REQUEST } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { DELETE_GUARANTOR_SUCCESS } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'
import { DELETE_GUARANTOR_FAILURE } from 'v2/spas/tenancy/guarantors/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  tenancy: {},
  guarantors: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANCY_GUARANTORS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_TENANCY_GUARANTORS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_TENANCY_GUARANTORS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        tenancy: {
          $set: action.payload.tenancy,
        },
        guarantors: {
          $set: action.payload.tenancy.tenancyGuarantors
        },
      })
    }

    case ARCHIVE_GUARANTOR_REQUEST: {
      return state
    }

    case ARCHIVE_GUARANTOR_FAILURE: {
      return state
    }

    case ARCHIVE_GUARANTOR_SUCCESS: {
      let index = state.guarantors.findIndex(x => x.id === action.payload.guarantor.id)

      return update(state, {
        guarantors: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_GUARANTOR_REQUEST: {
      return state
    }
    
    case DELETE_GUARANTOR_FAILURE: {
      return state
    }
    
    case DELETE_GUARANTOR_SUCCESS: {
      let index = state.guarantors.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        guarantors: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
