import React from 'react'
import styled from 'styled-components'
import InputWrapper from 'sharedComponents/Forms/InputWrapper.js'
import LabelWrapper from 'sharedComponents/Forms/LabelWrapper.js'

const FieldWrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};
`

function TextInput(props) {
  function labelWrapper() {
    if (props.label) {
      return (
        <LabelWrapper
          label={props.label}
          name={props.name}
          toolTipContent={props.toolTipContent}
          videoToolTipHeading={props.videoToolTipHeading}
          videoToolTipUrl={props.videoToolTipUrl}
          required={props.required}
        />
      )
    }
  }

  return (
    <FieldWrapper id="text-input">
      {labelWrapper()}
      <InputWrapper
        id={props.id}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.name}
        type={props.type}
        submitForm={props.submitForm}
        handleChange={props.handleChange}
        submitOnChange={props.submitOnChange}
        onBlur={props.onBlur}
        onClick={props.onClick}
        max={props.max}
        onKeyPress={props.onKeyPress}
      />
    </FieldWrapper>
  )
}

export default TextInput
