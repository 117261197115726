import { useFormattedMoney } from 'functions'

function useFormattedArrangementFee(bridgingLoanProduct) {
  function arrangementFee() {
    if (bridgingLoanProduct) {
      return bridgingLoanProduct.arrangementFee
    } else {
      return 0
    }
  }

  function arrangementFeeType() {
    if (bridgingLoanProduct) {
      return bridgingLoanProduct.arrangementFeeType
    } else {
      return ''
    }
  }

  const arrangementFeeAmount = useFormattedMoney(arrangementFee())
  const arrangementFeePercentage = arrangementFee() + '%'

  if (arrangementFeeType() === 'percentage') {
    return arrangementFeePercentage
  } else {
    return arrangementFeeAmount
  }
}

export default useFormattedArrangementFee
