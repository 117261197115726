import {ADD_CHECKOUT_SESSION_REQUEST} from 'redux/actionTypes.js'
import {ADD_CHECKOUT_SESSION_SUCCESS} from 'redux/actionTypes.js'
import {ADD_CHECKOUT_SESSION_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function addCheckoutSession({
  priceId: priceId,
  clientReferenceId: clientReferenceId,
  handleResponse: handleResponse,
}) {
  return function (dispatch) {
    dispatch(addCheckoutSessionRequest())

    let variables = {
      priceId: priceId,
      clientReferenceId: clientReferenceId,
    }

    let query = `
      mutation($priceId: String!, $clientReferenceId: String) {
        createCheckoutSession(
          priceId: $priceId
          clientReferenceId: $clientReferenceId
        ) {
          checkoutSessionUrl
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(addCheckoutSessionFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(
            addCheckoutSessionSuccess(
              res.data.data.createCheckoutSession.checkoutSessionUrl,
            ),
          )
          handleResponse({
            status: 'success',
            checkoutSessionUrl:
              res.data.data.createCheckoutSession.checkoutSessionUrl,
          })
        }
      })
      .catch(error => {
        dispatch(addCheckoutSessionFailure(error.message))
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const addCheckoutSessionRequest = () => ({
  type: ADD_CHECKOUT_SESSION_REQUEST,
})

export const addCheckoutSessionSuccess = checkoutSessionUrl => ({
  type: ADD_CHECKOUT_SESSION_SUCCESS,
  payload: {
    checkoutSessionUrl: checkoutSessionUrl,
  },
})

export const addCheckoutSessionFailure = error => ({
  type: ADD_CHECKOUT_SESSION_FAILURE,
  payload: {
    error: error,
  },
})
