import React from 'react'
import styled from 'styled-components'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import MobileSidebarLinks from 'sharedComponents/MobileSidebar/MobileSidebarLinks.js'
import PropTypes from 'prop-types'
import MobileSidebarLink from 'sharedComponents/MobileSidebar/MobileSidebarLink.js'
import {Row} from 'react-bootstrap'
import {useCurrentUser} from 'sharedComponents/useCurrentUser.js'
import {logout} from 'redux/actions/authentication/logout'
import {connect} from 'react-redux'
import {NotificationBell} from 'badges'
import CalendarIcon from 'sharedComponents/CalendarIcon';
// import Search from 'sharedComponents/TopNavbar/PropertyInvestorTopNavbar/Search.js'
import Support from 'sharedComponents/UserTopNavbar/Support.js'
import { useCurrentAccount } from 'sharedComponents/useCurrentAccount'
import { useCurrentAccountUser } from 'sharedComponents/useCurrentAccountUser'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    color: ${props =>
      props.navbarTheme ? props.theme.colors.green : props.theme.colors.white};
  }
`

function MobileSidebar(props) {
  const [state, setState] = React.useState({
    left: false,
  })

  const currentAccount = useCurrentAccount()
  const currentUser = useCurrentUser()
  const currentAccountUser = useCurrentAccountUser()

  let knowledgeBase = currentAccount.knowledgeBase || {}

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({...state, [anchor]: open})
  }

  const anchor = 'left'

  function logOut(event) {
    props.logout(handleLogOut)
  }

  function handleLogOut() {
    window.location.href = '/'
  }

  function links() {
    if (currentUser.id) {
      return (
        <Row>
          <MobileSidebarLink to="/prospective-properties" text="Prospective properties" />
          <MobileSidebarLink to="/owned-properties" text="Portfolio" />
          <MobileSidebarLink to={`/accounts/${currentAccount.id}/account-users/${currentAccountUser.id}`} text="My stuff" />
          <MobileSidebarLink to={`/accounts/${currentAccount.id}`} text="Account" />
          <MobileSidebarLink to={`/accounts/${currentAccount.id}/account-users/${currentAccountUser.id}`} text="My stuff" />
          <MobileSidebarLink onClick={logOut} text="Logout" />
        </Row>
      )
    } else if (window.location.pathname === '/mastermind-sign-up') {
      return <Row></Row>
    } else {
      return (
        <Row>
          <MobileSidebarLink to="/login" text="Sign in" />
          <MobileSidebarLink to="/sign-up" text="Try it FREE" />
        </Row>
      )
    }
  }

  const renderNotification = () => {
    if (currentUser.id) {
      return <NotificationBell />
    }
  }

  const renderCalendar = () => {
    if (currentUser.id) {
      return <CalendarIcon />
    }
  }

  const renderSearch = () => {
    if (currentUser.id) {
      return <Search />
    }
  }

  const renderSupport = () => {
    if (currentUser.id) {
      return <Support />
    }
  }

  return (
    <Wrapper navbarTheme={props.navbarTheme}>
      {/* {renderSearch()} */}
      {renderSupport()}
      {renderCalendar()}
      {renderNotification()}
      <MenuIcon onClick={toggleDrawer(anchor, true)} />
      <Drawer
        id="drawer"
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        <MobileSidebarLinks anchor={anchor} toggleDrawer={toggleDrawer}>
          {links()}
        </MobileSidebarLinks>
      </Drawer>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  logout: logout,
}

export default connect(null, mapDispatchToProps)(MobileSidebar)

MobileSidebar.propTypes = {
  navbarTheme: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}
