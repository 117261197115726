import update from 'immutability-helper'
import { FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_REQUEST } from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/action-types.tsx'
import { FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_SUCCESS } from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/action-types.tsx'
import { FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_FAILURE } from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_APPLICANT_REQUEST } from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_APPLICANT_SUCCESS } from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_APPLICANT_FAILURE } from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  bridgingLoanApplication: {},
  bridgingLoanApplicants: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        bridgingLoanApplication: {
          $set: action.payload.bridgingLoanApplication,
        },
        bridgingLoanApplicants: {
          $set: action.payload.bridgingLoanApplication.bridgingLoanApplicants
        },
      })
    }

    case DELETE_BRIDGING_LOAN_APPLICANT_REQUEST: {
      return state
    }
    
    case DELETE_BRIDGING_LOAN_APPLICANT_FAILURE: {
      return state
    }
    
    case DELETE_BRIDGING_LOAN_APPLICANT_SUCCESS: {
      let index = state.bridgingLoanApplicants.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        bridgingLoanApplicants: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
