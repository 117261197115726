export const FETCH_OWNED_PROPERTY_UNITS_REQUEST = 'FETCH_OWNED_PROPERTY_UNITS_REQUEST'
export const FETCH_OWNED_PROPERTY_UNITS_SUCCESS = 'FETCH_OWNED_PROPERTY_UNITS_SUCCESS'
export const FETCH_OWNED_PROPERTY_UNITS_FAILURE = 'FETCH_OWNED_PROPERTY_UNITS_FAILURE'

export const FETCH_UNIT_REQUEST = 'FETCH_UNIT_REQUEST'
export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS'
export const FETCH_UNIT_FAILURE = 'FETCH_UNIT_FAILURE'

export const CREATE_UNIT_REQUEST = 'CREATE_UNIT_REQUEST'
export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS'
export const CREATE_UNIT_FAILURE = 'CREATE_UNIT_FAILURE'

export const UPDATE_UNIT_REQUEST = 'UPDATE_UNIT_REQUEST'
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS'
export const UPDATE_UNIT_FAILURE = 'UPDATE_UNIT_FAILURE'

export const ARCHIVE_UNIT_REQUEST = 'ARCHIVE_UNIT_REQUEST'
export const ARCHIVE_UNIT_SUCCESS = 'ARCHIVE_UNIT_SUCCESS'
export const ARCHIVE_UNIT_FAILURE = 'ARCHIVE_UNIT_FAILURE'

export const UNARCHIVE_UNIT_REQUEST = 'UNARCHIVE_UNIT_REQUEST'
export const UNARCHIVE_UNIT_SUCCESS = 'UNARCHIVE_UNIT_SUCCESS'
export const UNARCHIVE_UNIT_FAILURE = 'UNARCHIVE_UNIT_FAILURE'

export const DELETE_UNIT_REQUEST = 'DELETE_UNIT_REQUEST'
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS'
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE'