import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {EditResourceButton} from 'buttons'

function ManageSubscriptionButton({status, onClick}) {
  return ['trialing', 'trial_ending_soon', 'trial_has_ended'].includes(status) ? (
    <Link to="/subscription">
      <EditResourceButton text="Manage" onClick={() => {}} />
    </Link> 
    ) : (
      <React.Fragment>
        <EditResourceButton text="Manage" onClick={onClick} />
      </React.Fragment>
    )
}

ManageSubscriptionButton.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ManageSubscriptionButton