import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: ${props => props.flexDirection || 'row'};
  background: ${props => props.background || props.theme.colors.primary};
  font-size: ${props => props.theme.space.m};
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  padding-top: ${props => props.theme.space.s};
  padding-bottom: ${props => props.theme.space.s};
  padding-left: ${props => props.theme.space.s};
  padding-right: ${props => props.theme.space.s};

  position: fixed;
  bottom: 0;
  z-index: 1;

  a {
    color: white;
  }

  a:hover {
    color: white;
  }
`

function StatusWrapper(props) {
  return (
    <Wrapper id='status-wrapper' flexDirection={props.flexDirection} background={props.background}>
      {props.children}
    </Wrapper>
  )
}

export default StatusWrapper
