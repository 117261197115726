import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_REQUEST } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_SUCCESS } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_FAILURE } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { ARCHIVE_BRIDGING_LOAN_APPLICATION_REQUEST } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { ARCHIVE_BRIDGING_LOAN_APPLICATION_SUCCESS } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { ARCHIVE_BRIDGING_LOAN_APPLICATION_FAILURE } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { UPDATE_BRIDGING_LOAN_APPLICATION_REQUEST } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { UPDATE_BRIDGING_LOAN_APPLICATION_SUCCESS } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { UPDATE_BRIDGING_LOAN_APPLICATION_FAILURE } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_APPLICATION_REQUEST } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_APPLICATION_SUCCESS } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_APPLICATION_FAILURE } from 'v2/spas/owned-property/bridging-loan-applications/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  bridgingLoanApplications: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_BRIDGING_LOAN_APPLICATIONS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        bridgingLoanApplications: {
          $set: action.payload.ownedProperty.property.bridgingLoanApplications
        },
      })
    }

    case UPDATE_BRIDGING_LOAN_APPLICATION_REQUEST: {
      return state
    }

    case UPDATE_BRIDGING_LOAN_APPLICATION_FAILURE: {
      return state
    }

    case UPDATE_BRIDGING_LOAN_APPLICATION_SUCCESS: {
      let index = state.bridgingLoanApplications.findIndex(
        x => x.id === action.payload.bridgingLoanApplication.id,
      )

      return update(state, {
        bridgingLoanApplications: {
          [index]: { $set: action.payload.bridgingLoanApplication },
        },
      })
    }

    case ARCHIVE_BRIDGING_LOAN_APPLICATION_REQUEST: {
      return state
    }

    case ARCHIVE_BRIDGING_LOAN_APPLICATION_FAILURE: {
      return state
    }

    case ARCHIVE_BRIDGING_LOAN_APPLICATION_SUCCESS: {
      let index = state.bridgingLoanApplications.findIndex(x => x.id === action.payload.bridgingLoanApplication.id)

      return update(state, {
        bridgingLoanApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_BRIDGING_LOAN_APPLICATION_REQUEST: {
      return state
    }

    case DELETE_BRIDGING_LOAN_APPLICATION_FAILURE: {
      return state
    }

    case DELETE_BRIDGING_LOAN_APPLICATION_SUCCESS: {
      let index = state.bridgingLoanApplications.findIndex(x => x.id === action.payload.id)

      return update(state, {
        bridgingLoanApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
