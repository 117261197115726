export const FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_REQUEST = 'FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_REQUEST'
export const FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_SUCCESS = 'FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_SUCCESS'
export const FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_FAILURE = 'FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_FAILURE'

export const CREATE_INVESTMENT_OPPORTUNITY_REQUEST = 'CREATE_INVESTMENT_OPPORTUNITY_REQUEST'
export const CREATE_INVESTMENT_OPPORTUNITY_SUCCESS = 'CREATE_INVESTMENT_OPPORTUNITY_SUCCESS'
export const CREATE_INVESTMENT_OPPORTUNITY_FAILURE = 'CREATE_INVESTMENT_OPPORTUNITY_FAILURE'

export const DELETE_INVESTMENT_OPPORTUNITY_REQUEST = 'DELETE_INVESTMENT_OPPORTUNITY_REQUEST'
export const DELETE_INVESTMENT_OPPORTUNITY_SUCCESS = 'DELETE_INVESTMENT_OPPORTUNITY_SUCCESS'
export const DELETE_INVESTMENT_OPPORTUNITY_FAILURE = 'DELETE_INVESTMENT_OPPORTUNITY_FAILURE'