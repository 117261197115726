import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_REQUEST } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_SUCCESS } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_FAILURE } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { ARCHIVE_CONVEYANCING_APPLICATION_REQUEST } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { ARCHIVE_CONVEYANCING_APPLICATION_SUCCESS } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { ARCHIVE_CONVEYANCING_APPLICATION_FAILURE } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { UPDATE_CONVEYANCING_APPLICATION_REQUEST } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { UPDATE_CONVEYANCING_APPLICATION_SUCCESS } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { UPDATE_CONVEYANCING_APPLICATION_FAILURE } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { DELETE_CONVEYANCING_APPLICATION_REQUEST } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { DELETE_CONVEYANCING_APPLICATION_SUCCESS } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'
import { DELETE_CONVEYANCING_APPLICATION_FAILURE } from 'v2/spas/prospective-property/conveyancing-applications/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  conveyancingApplications: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        conveyancingApplications: {
          $set: action.payload.prospectiveProperty.property.conveyancingApplications
        },
      })
    }

    case UPDATE_CONVEYANCING_APPLICATION_REQUEST: {
      return state
    }

    case UPDATE_CONVEYANCING_APPLICATION_FAILURE: {
      return state
    }

    case UPDATE_CONVEYANCING_APPLICATION_SUCCESS: {
      let index = state.conveyancingApplications.findIndex(
        x => x.id === action.payload.conveyancingApplication.id,
      )

      return update(state, {
        conveyancingApplications: {
          [index]: { $set: action.payload.conveyancingApplication },
        },
      })
    }

    case ARCHIVE_CONVEYANCING_APPLICATION_REQUEST: {
      return state
    }

    case ARCHIVE_CONVEYANCING_APPLICATION_FAILURE: {
      return state
    }

    case ARCHIVE_CONVEYANCING_APPLICATION_SUCCESS: {
      let index = state.conveyancingApplications.findIndex(x => x.id === action.payload.conveyancingApplication.id)

      return update(state, {
        conveyancingApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_CONVEYANCING_APPLICATION_REQUEST: {
      return state
    }
    
    case DELETE_CONVEYANCING_APPLICATION_FAILURE: {
      return state
    }
    
    case DELETE_CONVEYANCING_APPLICATION_SUCCESS: {
      let index = state.conveyancingApplications.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        conveyancingApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
