export const FETCH_MORTGAGE_APPLICATION_MORTGAGE_APPLICANTS_REQUEST = 'FETCH_MORTGAGE_APPLICATION_MORTGAGE_APPLICANTS_REQUEST'
export const FETCH_MORTGAGE_APPLICATION_MORTGAGE_APPLICANTS_SUCCESS = 'FETCH_MORTGAGE_APPLICATION_MORTGAGE_APPLICANTS_SUCCESS'
export const FETCH_MORTGAGE_APPLICATION_MORTGAGE_APPLICANTS_FAILURE = 'FETCH_MORTGAGE_APPLICATION_MORTGAGE_APPLICANTS_FAILURE'

export const CREATE_MORTGAGE_APPLICANT_REQUEST = 'CREATE_MORTGAGE_APPLICANT_REQUEST'
export const CREATE_MORTGAGE_APPLICANT_SUCCESS = 'CREATE_MORTGAGE_APPLICANT_SUCCESS'
export const CREATE_MORTGAGE_APPLICANT_FAILURE = 'CREATE_MORTGAGE_APPLICANT_FAILURE'

export const DELETE_MORTGAGE_APPLICANT_REQUEST = 'DELETE_MORTGAGE_APPLICANT_REQUEST'
export const DELETE_MORTGAGE_APPLICANT_SUCCESS = 'DELETE_MORTGAGE_APPLICANT_SUCCESS'
export const DELETE_MORTGAGE_APPLICANT_FAILURE = 'DELETE_MORTGAGE_APPLICANT_FAILURE'