import moment from 'moment'
import 'moment/locale/en-gb'

function useFormattedDate(date) {
  if (date) {
    return moment(date).locale('en-gb').format('L')
  } else {
    return null
  }
}

export default useFormattedDate
