export const FETCH_PROSPECTIVE_PROPERTY_DEALS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_DEALS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_DEALS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_DEALS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_DEALS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_DEALS_FAILURE'

export const CREATE_HMO_DEAL_REQUEST = 'CREATE_HMO_DEAL_REQUEST'
export const CREATE_HMO_DEAL_SUCCESS = 'CREATE_HMO_DEAL_SUCCESS'
export const CREATE_HMO_DEAL_FAILURE = 'CREATE_HMO_DEAL_FAILURE'

export const CREATE_SINGLE_LET_DEAL_REQUEST = 'CREATE_SINGLE_LET_DEAL_REQUEST'
export const CREATE_SINGLE_LET_DEAL_SUCCESS = 'CREATE_SINGLE_LET_DEAL_SUCCESS'
export const CREATE_SINGLE_LET_DEAL_FAILURE = 'CREATE_SINGLE_LET_DEAL_FAILURE'

export const CREATE_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'CREATE_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'CREATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'CREATE_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const CREATE_RENT_TO_RENT_DEAL_REQUEST = 'CREATE_RENT_TO_RENT_DEAL_REQUEST'
export const CREATE_RENT_TO_RENT_DEAL_SUCCESS = 'CREATE_RENT_TO_RENT_DEAL_SUCCESS'
export const CREATE_RENT_TO_RENT_DEAL_FAILURE = 'CREATE_RENT_TO_RENT_DEAL_FAILURE'

export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const CREATE_FLIP_DEAL_REQUEST = 'CREATE_FLIP_DEAL_REQUEST'
export const CREATE_FLIP_DEAL_SUCCESS = 'CREATE_FLIP_DEAL_SUCCESS'
export const CREATE_FLIP_DEAL_FAILURE = 'CREATE_FLIP_DEAL_FAILURE'

export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST = 'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS = 'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE = 'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const UPDATE_DEAL_REQUEST = 'UPDATE_DEAL_REQUEST'
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS'
export const UPDATE_DEAL_FAILURE = 'UPDATE_DEAL_FAILURE'

export const ARCHIVE_DEAL_REQUEST = 'ARCHIVE_DEAL_REQUEST'
export const ARCHIVE_DEAL_SUCCESS = 'ARCHIVE_DEAL_SUCCESS'
export const ARCHIVE_DEAL_FAILURE = 'ARCHIVE_DEAL_FAILURE'

export const UNARCHIVE_DEAL_REQUEST = 'UNARCHIVE_DEAL_REQUEST'
export const UNARCHIVE_DEAL_SUCCESS = 'UNARCHIVE_DEAL_SUCCESS'
export const UNARCHIVE_DEAL_FAILURE = 'UNARCHIVE_DEAL_FAILURE'

export const DELETE_DEAL_REQUEST = 'DELETE_DEAL_REQUEST'
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS'
export const DELETE_DEAL_FAILURE = 'DELETE_DEAL_FAILURE'

export const DUPLICATE_HMO_DEAL_REQUEST = 'DUPLICATE_HMO_DEAL_REQUEST'
export const DUPLICATE_HMO_DEAL_SUCCESS = 'DUPLICATE_HMO_DEAL_SUCCESS'
export const DUPLICATE_HMO_DEAL_FAILURE = 'DUPLICATE_HMO_DEAL_FAILURE'

export const DUPLICATE_SINGLE_LET_DEAL_REQUEST = 'DUPLICATE_SINGLE_LET_DEAL_REQUEST'
export const DUPLICATE_SINGLE_LET_DEAL_SUCCESS = 'DUPLICATE_SINGLE_LET_DEAL_SUCCESS'
export const DUPLICATE_SINGLE_LET_DEAL_FAILURE = 'DUPLICATE_SINGLE_LET_DEAL_FAILURE'

export const DUPLICATE_FLIP_DEAL_REQUEST = 'DUPLICATE_FLIP_DEAL_REQUEST'
export const DUPLICATE_FLIP_DEAL_SUCCESS = 'DUPLICATE_FLIP_DEAL_SUCCESS'
export const DUPLICATE_FLIP_DEAL_FAILURE = 'DUPLICATE_FLIP_DEAL_FAILURE'

export const DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST = 'DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS = 'DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE = 'DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const DUPLICATE_RENT_TO_RENT_DEAL_REQUEST = 'DUPLICATE_RENT_TO_RENT_DEAL_REQUEST'
export const DUPLICATE_RENT_TO_RENT_DEAL_SUCCESS = 'DUPLICATE_RENT_TO_RENT_DEAL_SUCCESS'
export const DUPLICATE_RENT_TO_RENT_DEAL_FAILURE = 'DUPLICATE_RENT_TO_RENT_DEAL_FAILURE'

export const DUPLICATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'DUPLICATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const DUPLICATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'DUPLICATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const DUPLICATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'DUPLICATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const DUPLICATE_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'DUPLICATE_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const DUPLICATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'DUPLICATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const DUPLICATE_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'DUPLICATE_SERVICED_ACCOMMODATION_DEAL_FAILURE'
