import React, {useState} from 'react'
import ProspectivePropertyResourceCard from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCard.js'
import ProspectivePropertyResourceCardHeader from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardHeader.js'
import {FormModal} from 'modals'
import {EditResourceButton} from 'buttons'
import ProspectivePropertyResourceCardRow from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardRow.js'
import Col from 'react-bootstrap/Col'
import ProspectivePropertyResourceCardRowAttributes from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardRowAttributes.js'
import EmailForm from 'spas/Profile/EmailForm.js'
import PasswordForm from 'spas/Profile/PasswordForm.js'
import {useDevice} from 'sharedComponents/useDevice'
import {useCurrentEmailIdentity} from 'sharedComponents/useCurrentEmailIdentity'

function LoginDetailsCard() {
  const device = useDevice()
  const [showEmailForm, setShowEmailForm] = useState(false)
  const [showPasswordForm, setShowPasswordForm] = useState(false)

  const currentEmailIdentity = useCurrentEmailIdentity()

  const fontSize = device === 'mobile' || device === 'tablet' ? '10px' : '14px'

  return (
    <ProspectivePropertyResourceCard>
      <ProspectivePropertyResourceCardHeader heading="Email & Password">
        <EditResourceButton
          text="Email"
          onClick={() => setShowEmailForm(true)}
          fontSize={fontSize}
        />
        <EditResourceButton
          text="Password"
          onClick={() => setShowPasswordForm(true)}
          fontSize={fontSize}
        />
      </ProspectivePropertyResourceCardHeader>

      <ProspectivePropertyResourceCardRow>
        <Col sm="6" xs="12">
          <ProspectivePropertyResourceCardRowAttributes
            heading="Email"
            value={currentEmailIdentity.email}
          />
        </Col>

        <Col sm="6" xs="12">
          <ProspectivePropertyResourceCardRowAttributes
            heading="Password"
            value="**********"
          />
        </Col>
      </ProspectivePropertyResourceCardRow>

      <FormModal
        show={showEmailForm}
        setShow={setShowEmailForm}
        heading="Email"
      >
        <EmailForm showModal={setShowEmailForm} />
      </FormModal>

      <FormModal
        show={showPasswordForm}
        setShow={setShowPasswordForm}
        heading="Password"
      >
        <PasswordForm showModal={setShowPasswordForm} />
      </FormModal>
    </ProspectivePropertyResourceCard>
  )
}

export default LoginDetailsCard
