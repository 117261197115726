import update from 'immutability-helper'
import {FETCH_USER_PROPERTIES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_USER_PROPERTIES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_USER_PROPERTIES_SUCCESS} from 'redux/actionTypes.js'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_PROPERTIES_REQUEST: {
      return Object.assign({}, state, {
        networkRequestStatus: 'requested',
      })
    }

    case FETCH_USER_PROPERTIES_FAILURE: {
      return Object.assign({}, state, {
        networkRequestStatus: 'error',
        error: action.payload.error,
      })
    }

    case FETCH_USER_PROPERTIES_SUCCESS: {
      return update(state, {
        currentUser: {
          properties: {
            $set: action.payload.properties,
          },
        },
      })
    }

    default:
      return state
  }
}
