import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_VIDEOS_REQUEST } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_VIDEOS_FAILURE } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { ARCHIVE_VIDEO_REQUEST } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { ARCHIVE_VIDEO_SUCCESS } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { ARCHIVE_VIDEO_FAILURE } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { UPDATE_VIDEO_REQUEST } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { UPDATE_VIDEO_SUCCESS } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { UPDATE_VIDEO_FAILURE } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { DELETE_VIDEO_REQUEST } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { DELETE_VIDEO_SUCCESS } from 'v2/spas/owned-property/videos/redux/action-types.tsx'
import { DELETE_VIDEO_FAILURE } from 'v2/spas/owned-property/videos/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  videos: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_VIDEOS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_VIDEOS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        videos: {
          $set: action.payload.ownedProperty.property.videos
        },
      })
    }

    case UPDATE_VIDEO_REQUEST: {
      return state
    }

    case UPDATE_VIDEO_FAILURE: {
      return state
    }

    case UPDATE_VIDEO_SUCCESS: {
      let index = state.videos.findIndex(
        x => x.id === action.payload.video.id,
      )

      return update(state, {
        videos: {
          [index]: { $set: action.payload.video },
        },
      })
    }

    case ARCHIVE_VIDEO_REQUEST: {
      return state
    }

    case ARCHIVE_VIDEO_FAILURE: {
      return state
    }

    case ARCHIVE_VIDEO_SUCCESS: {
      let index = state.videos.findIndex(x => x.id === action.payload.video.id)

      return update(state, {
        videos: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_VIDEO_REQUEST: {
      return state
    }
    
    case DELETE_VIDEO_FAILURE: {
      return state
    }
    
    case DELETE_VIDEO_SUCCESS: {
      let index = state.videos.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        videos: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
