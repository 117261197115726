export const FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST = 'FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST'
export const FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS = 'FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS'
export const FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE = 'FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE'

export const FETCH_MORTGAGE_PRODUCT_REQUEST = 'FETCH_MORTGAGE_PRODUCT_REQUEST'
export const FETCH_MORTGAGE_PRODUCT_SUCCESS = 'FETCH_MORTGAGE_PRODUCT_SUCCESS'
export const FETCH_MORTGAGE_PRODUCT_FAILURE = 'FETCH_MORTGAGE_PRODUCT_FAILURE'

export const CREATE_MORTGAGE_PRODUCT_REQUEST = 'CREATE_MORTGAGE_PRODUCT_REQUEST'
export const CREATE_MORTGAGE_PRODUCT_SUCCESS = 'CREATE_MORTGAGE_PRODUCT_SUCCESS'
export const CREATE_MORTGAGE_PRODUCT_FAILURE = 'CREATE_MORTGAGE_PRODUCT_FAILURE'

export const UPDATE_MORTGAGE_PRODUCT_REQUEST = 'UPDATE_MORTGAGE_PRODUCT_REQUEST'
export const UPDATE_MORTGAGE_PRODUCT_SUCCESS = 'UPDATE_MORTGAGE_PRODUCT_SUCCESS'
export const UPDATE_MORTGAGE_PRODUCT_FAILURE = 'UPDATE_MORTGAGE_PRODUCT_FAILURE'

export const ARCHIVE_MORTGAGE_PRODUCT_REQUEST = 'ARCHIVE_MORTGAGE_PRODUCT_REQUEST'
export const ARCHIVE_MORTGAGE_PRODUCT_SUCCESS = 'ARCHIVE_MORTGAGE_PRODUCT_SUCCESS'
export const ARCHIVE_MORTGAGE_PRODUCT_FAILURE = 'ARCHIVE_MORTGAGE_PRODUCT_FAILURE'

export const UNARCHIVE_MORTGAGE_PRODUCT_REQUEST = 'UNARCHIVE_MORTGAGE_PRODUCT_REQUEST'
export const UNARCHIVE_MORTGAGE_PRODUCT_SUCCESS = 'UNARCHIVE_MORTGAGE_PRODUCT_SUCCESS'
export const UNARCHIVE_MORTGAGE_PRODUCT_FAILURE = 'UNARCHIVE_MORTGAGE_PRODUCT_FAILURE'

export const DELETE_MORTGAGE_PRODUCT_REQUEST = 'DELETE_MORTGAGE_PRODUCT_REQUEST'
export const DELETE_MORTGAGE_PRODUCT_SUCCESS = 'DELETE_MORTGAGE_PRODUCT_SUCCESS'
export const DELETE_MORTGAGE_PRODUCT_FAILURE = 'DELETE_MORTGAGE_PRODUCT_FAILURE'