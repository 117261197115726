import React from 'react'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  border-radius: 32px;
  padding: 5px 10px;
`

const VerifiedAccountIcon = styled(VerifiedUserIcon)`
  height: 14px;
  width: 14px;
  margin-right: 5px;
`

const Text = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
`

function AccountVerified() {
  return (
    <Wrapper>
      <VerifiedAccountIcon />
      <Text>Verified</Text>
    </Wrapper>
  )
}

export default AccountVerified