import React, {useState} from 'react'
import ProspectivePropertyResourceCard from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCard.js'
import ProspectivePropertyResourceCardHeader from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardHeader.js'
import {FormModal} from 'modals'
import {EditResourceButton} from 'buttons'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import moment from 'moment'
import WithdrawUserConsent from 'spas/GDPR/WithdrawUserConsent.js'
import GrantUserConsent from 'spas/GDPR/GrantUserConsent.js'
import {useCurrentUser} from 'sharedComponents/useCurrentUser'
import {useCurrentAccountUser} from 'sharedComponents/useCurrentAccountUser'

const TermsAndConditions = styled.div`
  padding-top: 25px;
  padding-left: 50px;
  text-align: left;

  @media ${props => props.theme.devices.mobile} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.tablet} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }
`

const ProcessPropertyInvestmentData = styled.div`
  padding-top: 15px;
  padding-left: 50px;
  text-align: left;

  @media ${props => props.theme.devices.mobile} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.tablet} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }
`

function DataProcessingConsentsCard() {
  const currentUser = useCurrentUser()
  // const currentAccountUser = useCurrentAccountUser()

  const [show, setShow] = useState(
    !currentUser.consentedToProcessPropertyInvestmentDataAt,
  )

  let termsAcceptedAtTime = moment(currentUser.termsAcceptedAt).format(
    'h:mm:ss a',
  )
  let termsAcceptedAtDate = moment(currentUser.termsAcceptedAt).format(
    'MMMM Do YYYY',
  )

  return (
    <ProspectivePropertyResourceCard>
      <ProspectivePropertyResourceCardHeader heading="Data Processing Consents">
        <EditResourceButton text="Manage" onClick={() => setShow(true)} />
      </ProspectivePropertyResourceCardHeader>

      <FormModal
        show={show}
        setShow={setShow}
        heading="Data Processing Consents"
      >
        <TermsAndConditions>
          <h6>Terms and conditions</h6>
          <p>
            You accepted our{' '}
            <Link
              to="/terms_and_conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </Link>{' '}
            at <strong>{termsAcceptedAtTime}</strong> on{' '}
            <strong>{termsAcceptedAtDate}</strong>
          </p>
        </TermsAndConditions>

        <ProcessPropertyInvestmentData>
          <WithdrawUserConsent />
          <GrantUserConsent />
        </ProcessPropertyInvestmentData>
      </FormModal>
    </ProspectivePropertyResourceCard>
  )
}

export default DataProcessingConsentsCard
