import update from 'immutability-helper'
import {FETCH_MORTGAGE_APPLICATION_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_APPLICATION_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_APPLICATION_FAILURE} from 'redux/actionTypes.js'

const initialState = {
  fetchMortgageApplicationStatus: 'requested',
  fetchMortgageApplicationError: '',
  mortgageApplication: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MORTGAGE_APPLICATION_REQUEST: {
      return update(state, {
        fetchMortgageApplicationStatus: {$set: 'requested'},
      })
    }

    case FETCH_MORTGAGE_APPLICATION_FAILURE: {
      return update(state, {
        fetchMortgageApplicationStatus: {
          $set: 'error',
        },
        fetchMortgageApplicationError: {$set: action.payload.error},
      })
    }

    case FETCH_MORTGAGE_APPLICATION_SUCCESS: {
      return update(state, {
        fetchMortgageApplicationStatus: {$set: 'success'},
        mortgageApplication: {
          $set: action.payload.mortgageApplication,
        },
      })
    }

    default:
      return state
  }
}
