export const LOAD_STACKED_REQUEST = 'LOAD_STACKED_REQUEST'
export const LOAD_STACKED_SUCCESS = 'LOAD_STACKED_SUCCESS'
export const LOAD_STACKED_FAILURE = 'LOAD_STACKED_FAILURE'

export const SESSION_REQUEST = 'SESSION_REQUEST'
export const SESSION_SUCCESS = 'SESSION_SUCCESS'
export const SESSION_FAILURE = 'SESSION_FAILURE'

export const SIGN_IN_WITH_MAGIC_LINK_REQUEST = 'SIGN_IN_WITH_MAGIC_LINK_REQUEST'
export const SIGN_IN_WITH_MAGIC_LINK_SUCCESS = 'SIGN_IN_WITH_MAGIC_LINK_SUCCESS'
export const SIGN_IN_WITH_MAGIC_LINK_FAILURE = 'SIGN_IN_WITH_MAGIC_LINK_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTIES_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTIES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTIES_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTIES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTIES_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTIES_FAILURE'

export const ADD_PROSPECTIVE_PROPERTY_REQUEST =
  'ADD_PROSPECTIVE_PROPERTY_REQUEST'
export const ADD_PROSPECTIVE_PROPERTY_SUCCESS =
  'ADD_PROSPECTIVE_PROPERTY_SUCCESS'
export const ADD_PROSPECTIVE_PROPERTY_FAILURE =
  'ADD_PROSPECTIVE_PROPERTY_FAILURE'

export const UPDATE_PROSPECTIVE_PROPERTY_REQUEST =
  'UPDATE_PROSPECTIVE_PROPERTY_REQUEST'
export const UPDATE_PROSPECTIVE_PROPERTY_SUCCESS =
  'UPDATE_PROSPECTIVE_PROPERTY_SUCCESS'
export const UPDATE_PROSPECTIVE_PROPERTY_FAILURE =
  'UPDATE_PROSPECTIVE_PROPERTY_FAILURE'

export const ARCHIVE_PROSPECTIVE_PROPERTY_REQUEST =
  'ARCHIVE_PROSPECTIVE_PROPERTY_REQUEST'
export const ARCHIVE_PROSPECTIVE_PROPERTY_SUCCESS =
  'ARCHIVE_PROSPECTIVE_PROPERTY_SUCCESS'
export const ARCHIVE_PROSPECTIVE_PROPERTY_FAILURE =
  'ARCHIVE_PROSPECTIVE_PROPERTY_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_FAILURE'

export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST =
  'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST'
export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS =
  'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS'
export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE =
  'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_PHOTOS_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_PHOTOS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_PHOTOS_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_PHOTOS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_PHOTOS_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_PHOTOS_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_DOCUMENTS_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_DOCUMENTS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_DOCUMENTS_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_DOCUMENTS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_DOCUMENTS_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_DOCUMENTS_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_FAILURE'

export const FETCH_PHOTOS_REQUEST = 'FETCH_PHOTOS_REQUEST'
export const FETCH_PHOTOS_SUCCESS = 'FETCH_PHOTOS_SUCCESS'
export const FETCH_PHOTOS_FAILURE = 'FETCH_PHOTOS_FAILURE'

export const FETCH_RENT_COMPARABLES_REQUEST = 'FETCH_RENT_COMPARABLES_REQUEST'
export const FETCH_RENT_COMPARABLES_SUCCESS = 'FETCH_RENT_COMPARABLES_SUCCESS'
export const FETCH_RENT_COMPARABLES_FAILURE = 'FETCH_RENT_COMPARABLES_FAILURE'

export const FETCH_SALE_COMPARABLES_REQUEST = 'FETCH_SALE_COMPARABLES_REQUEST'
export const FETCH_SALE_COMPARABLES_SUCCESS = 'FETCH_SALE_COMPARABLES_SUCCESS'
export const FETCH_SALE_COMPARABLES_FAILURE = 'FETCH_SALE_COMPARABLES_FAILURE'

export const FETCH_RENT_LISTINGS_REQUEST = 'FETCH_RENT_LISTINGS_REQUEST'
export const FETCH_RENT_LISTINGS_SUCCESS = 'FETCH_RENT_LISTINGS_SUCCESS'
export const FETCH_RENT_LISTINGS_FAILURE = 'FETCH_RENT_LISTINGS_FAILURE'

export const FETCH_SALE_LISTINGS_REQUEST = 'FETCH_SALE_LISTINGS_REQUEST'
export const FETCH_SALE_LISTINGS_SUCCESS = 'FETCH_SALE_LISTINGS_SUCCESS'
export const FETCH_SALE_LISTINGS_FAILURE = 'FETCH_SALE_LISTINGS_FAILURE'

export const ADD_RENT_COMPARABLE_REQUEST = 'ADD_RENT_COMPARABLE_REQUEST'
export const ADD_RENT_COMPARABLE_SUCCESS = 'ADD_RENT_COMPARABLE_SUCCESS'
export const ADD_RENT_COMPARABLE_FAILURE = 'ADD_RENT_COMPARABLE_FAILURE'

export const ADD_SALE_COMPARABLE_REQUEST = 'ADD_SALE_COMPARABLE_REQUEST'
export const ADD_SALE_COMPARABLE_SUCCESS = 'ADD_SALE_COMPARABLE_SUCCESS'
export const ADD_SALE_COMPARABLE_FAILURE = 'ADD_SALE_COMPARABLE_FAILURE'

export const ADD_RENT_LISTING_REQUEST = 'ADD_RENT_LISTING_REQUEST'
export const ADD_RENT_LISTING_SUCCESS = 'ADD_RENT_LISTING_SUCCESS'
export const ADD_RENT_LISTING_FAILURE = 'ADD_RENT_LISTING_FAILURE'

export const ADD_SALE_LISTING_REQUEST = 'ADD_SALE_LISTING_REQUEST'
export const ADD_SALE_LISTING_SUCCESS = 'ADD_SALE_LISTING_SUCCESS'
export const ADD_SALE_LISTING_FAILURE = 'ADD_SALE_LISTING_FAILURE'

export const DELETE_RENT_COMPARABLE_REQUEST = 'DELETE_RENT_COMPARABLE_REQUEST'
export const DELETE_RENT_COMPARABLE_SUCCESS = 'DELETE_RENT_COMPARABLE_SUCCESS'
export const DELETE_RENT_COMPARABLE_FAILURE = 'DELETE_RENT_COMPARABLE_FAILURE'

export const UPDATE_RENT_COMPARABLE_REQUEST = 'UPDATE_RENT_COMPARABLE_REQUEST'
export const UPDATE_RENT_COMPARABLE_SUCCESS = 'UPDATE_RENT_COMPARABLE_SUCCESS'
export const UPDATE_RENT_COMPARABLE_FAILURE = 'UPDATE_RENT_COMPARABLE_FAILURE'

export const DELETE_SALE_COMPARABLE_REQUEST = 'DELETE_SALE_COMPARABLE_REQUEST'
export const DELETE_SALE_COMPARABLE_SUCCESS = 'DELETE_SALE_COMPARABLE_SUCCESS'
export const DELETE_SALE_COMPARABLE_FAILURE = 'DELETE_SALE_COMPARABLE_FAILURE'

export const UPDATE_SALE_COMPARABLE_REQUEST = 'UPDATE_SALE_COMPARABLE_REQUEST'
export const UPDATE_SALE_COMPARABLE_SUCCESS = 'UPDATE_SALE_COMPARABLE_SUCCESS'
export const UPDATE_SALE_COMPARABLE_FAILURE = 'UPDATE_SALE_COMPARABLE_FAILURE'

export const DELETE_RENT_LISTING_REQUEST = 'DELETE_RENT_LISTING_REQUEST'
export const DELETE_RENT_LISTING_SUCCESS = 'DELETE_RENT_LISTING_SUCCESS'
export const DELETE_RENT_LISTING_FAILURE = 'DELETE_RENT_LISTING_FAILURE'

export const UPDATE_RENT_LISTING_REQUEST = 'UPDATE_RENT_LISTING_REQUEST'
export const UPDATE_RENT_LISTING_SUCCESS = 'UPDATE_RENT_LISTING_SUCCESS'
export const UPDATE_RENT_LISTING_FAILURE = 'UPDATE_RENT_LISTING_FAILURE'

export const DELETE_SALE_LISTING_REQUEST = 'DELETE_SALE_LISTING_REQUEST'
export const DELETE_SALE_LISTING_SUCCESS = 'DELETE_SALE_LISTING_SUCCESS'
export const DELETE_SALE_LISTING_FAILURE = 'DELETE_SALE_LISTING_FAILURE'

export const UPDATE_SALE_LISTING_REQUEST = 'UPDATE_SALE_LISTING_REQUEST'
export const UPDATE_SALE_LISTING_SUCCESS = 'UPDATE_SALE_LISTING_SUCCESS'
export const UPDATE_SALE_LISTING_FAILURE = 'UPDATE_SALE_LISTING_FAILURE'

export const ADD_PHOTO_REQUEST = 'ADD_PHOTO_REQUEST'
export const ADD_PHOTO_SUCCESS = 'ADD_PHOTO_SUCCESS'
export const ADD_PHOTO_FAILURE = 'ADD_PHOTO_FAILURE'

export const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST'
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS'
export const DELETE_PHOTO_FAILURE = 'DELETE_PHOTO_FAILURE'

export const UPDATE_PHOTO_REQUEST = 'UPDATE_PHOTO_REQUEST'
export const UPDATE_PHOTO_SUCCESS = 'UPDATE_PHOTO_SUCCESS'
export const UPDATE_PHOTO_FAILURE = 'UPDATE_PHOTO_FAILURE'

export const UPLOAD_PHOTO_IMAGE_REQUEST = 'UPLOAD_PHOTO_IMAGE_REQUEST'
export const UPLOAD_PHOTO_IMAGE_SUCCESS = 'UPLOAD_PHOTO_IMAGE_SUCCESS'
export const UPLOAD_PHOTO_IMAGE_FAILURE = 'UPLOAD_PHOTO_IMAGE_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_DEALS_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_DEALS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_DEALS_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_DEALS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_DEALS_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_DEALS_FAILURE'

export const FETCH_HMO_DEAL_REQUEST = 'FETCH_HMO_DEAL_REQUEST'
export const FETCH_HMO_DEAL_SUCCESS = 'FETCH_HMO_DEAL_SUCCESS'
export const FETCH_HMO_DEAL_FAILURE = 'FETCH_HMO_DEAL_FAILURE'

export const FETCH_HMO_DEAL_TEMPLATES_REQUEST =
  'FETCH_HMO_DEAL_TEMPLATES_REQUEST'
export const FETCH_HMO_DEAL_TEMPLATES_SUCCESS =
  'FETCH_HMO_DEAL_TEMPLATES_SUCCESS'
export const FETCH_HMO_DEAL_TEMPLATES_FAILURE =
  'FETCH_HMO_DEAL_TEMPLATES_FAILURE'

export const FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST =
  'FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST'
export const FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE =
  'FETCH_HMO_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE'

export const FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST =
  'FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST'
export const FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE =
  'FETCH_HMO_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE'

export const FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_HMO_DEAL_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_REQUEST =
  'FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_REQUEST'
export const FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS =
  'FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS'
export const FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_FAILURE =
  'FETCH_HMO_DEAL_FIXED_RECURRING_EXPENSES_FAILURE'

export const FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST =
  'FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST'
export const FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS =
  'FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS'
export const FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE =
  'FETCH_HMO_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE'

export const FETCH_HMO_DEAL_MORTGAGE_REQUEST = 'FETCH_HMO_DEAL_MORTGAGE_REQUEST'
export const FETCH_HMO_DEAL_MORTGAGE_SUCCESS = 'FETCH_HMO_DEAL_MORTGAGE_SUCCESS'
export const FETCH_HMO_DEAL_MORTGAGE_FAILURE = 'FETCH_HMO_DEAL_MORTGAGE_FAILURE'

export const FETCH_HMO_DEAL_REMORTGAGE_REQUEST =
  'FETCH_HMO_DEAL_REMORTGAGE_REQUEST'
export const FETCH_HMO_DEAL_REMORTGAGE_SUCCESS =
  'FETCH_HMO_DEAL_REMORTGAGE_SUCCESS'
export const FETCH_HMO_DEAL_REMORTGAGE_FAILURE =
  'FETCH_HMO_DEAL_REMORTGAGE_FAILURE'

export const FETCH_DEAL_REQUEST = 'FETCH_DEAL_REQUEST'
export const FETCH_DEAL_SUCCESS = 'FETCH_DEAL_SUCCESS'
export const FETCH_DEAL_FAILURE = 'FETCH_DEAL_FAILURE'

export const FETCH_DEAL_LOANS_REQUEST = 'FETCH_DEAL_LOANS_REQUEST'
export const FETCH_DEAL_LOANS_SUCCESS = 'FETCH_DEAL_LOANS_SUCCESS'
export const FETCH_DEAL_LOANS_FAILURE = 'FETCH_DEAL_LOANS_FAILURE'

export const FETCH_DEAL_SHAREHOLDINGS_REQUEST =
  'FETCH_DEAL_SHAREHOLDINGS_REQUEST'
export const FETCH_DEAL_SHAREHOLDINGS_SUCCESS =
  'FETCH_DEAL_SHAREHOLDINGS_SUCCESS'
export const FETCH_DEAL_SHAREHOLDINGS_FAILURE =
  'FETCH_DEAL_SHAREHOLDINGS_FAILURE'

export const FETCH_DEAL_PRESENTATIONS_REQUEST =
  'FETCH_DEAL_PRESENTATIONS_REQUEST'
export const FETCH_DEAL_PRESENTATIONS_SUCCESS =
  'FETCH_DEAL_PRESENTATIONS_SUCCESS'
export const FETCH_DEAL_PRESENTATIONS_FAILURE =
  'FETCH_DEAL_PRESENTATIONS_FAILURE'

export const PRE_REGISTER_REQUEST = 'PRE_REGISTER_REQUEST'
export const PRE_REGISTER_SUCCESS = 'PRE_REGISTER_SUCCESS'
export const PRE_REGISTER_FAILURE = 'PRE_REGISTER_FAILURE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE'

export const FETCH_FORMATTED_ADDRESS_REQUEST = 'FETCH_FORMATTED_ADDRESS_REQUEST'
export const FETCH_FORMATTED_ADDRESS_SUCCESS = 'FETCH_FORMATTED_ADDRESS_SUCCESS'
export const FETCH_FORMATTED_ADDRESS_FAILURE = 'FETCH_FORMATTED_ADDRESS_FAILURE'

export const ADD_HMO_DEAL_REQUEST = 'ADD_HMO_DEAL_REQUEST'
export const ADD_HMO_DEAL_SUCCESS = 'ADD_HMO_DEAL_SUCCESS'
export const ADD_HMO_DEAL_FAILURE = 'ADD_HMO_DEAL_FAILURE'

export const ADD_SINGLE_LET_DEAL_REQUEST = 'ADD_SINGLE_LET_DEAL_REQUEST'
export const ADD_SINGLE_LET_DEAL_SUCCESS = 'ADD_SINGLE_LET_DEAL_SUCCESS'
export const ADD_SINGLE_LET_DEAL_FAILURE = 'ADD_SINGLE_LET_DEAL_FAILURE'

export const DELETE_DEAL_REQUEST = 'DELETE_DEAL_REQUEST'
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS'
export const DELETE_DEAL_FAILURE = 'DELETE_DEAL_FAILURE'

export const UPDATE_DEAL_REQUEST = 'UPDATE_DEAL_REQUEST'
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS'
export const UPDATE_DEAL_FAILURE = 'UPDATE_DEAL_FAILURE'

export const UPDATE_HMO_DEAL_REQUEST = 'UPDATE_HMO_DEAL_REQUEST'
export const UPDATE_HMO_DEAL_SUCCESS = 'UPDATE_HMO_DEAL_SUCCESS'
export const UPDATE_HMO_DEAL_FAILURE = 'UPDATE_HMO_DEAL_FAILURE'

export const ARCHIVE_HMO_DEAL_REQUEST = 'ARCHIVE_HMO_DEAL_REQUEST'
export const ARCHIVE_HMO_DEAL_SUCCESS = 'ARCHIVE_HMO_DEAL_SUCCESS'
export const ARCHIVE_HMO_DEAL_FAILURE = 'ARCHIVE_HMO_DEAL_FAILURE'

export const FETCH_MORTGAGE_LENDERS_REQUEST = 'FETCH_MORTGAGE_LENDERS_REQUEST'
export const FETCH_MORTGAGE_LENDERS_SUCCESS = 'FETCH_MORTGAGE_LENDERS_SUCCESS'
export const FETCH_MORTGAGE_LENDERS_FAILURE = 'FETCH_MORTGAGE_LENDERS_FAILURE'

export const FETCH_ACCOUNT_MORTGAGE_LENDERS_REQUEST =
  'FETCH_ACCOUNT_MORTGAGE_LENDERS_REQUEST'
export const FETCH_ACCOUNT_MORTGAGE_LENDERS_SUCCESS =
  'FETCH_ACCOUNT_MORTGAGE_LENDERS_SUCCESS'
export const FETCH_ACCOUNT_MORTGAGE_LENDERS_FAILURE =
  'FETCH_ACCOUNT_MORTGAGE_LENDERS_FAILURE'

export const ADD_MORTGAGE_PRODUCT_REQUEST = 'ADD_MORTGAGE_PRODUCT_REQUEST'
export const ADD_MORTGAGE_PRODUCT_SUCCESS = 'ADD_MORTGAGE_PRODUCT_SUCCESS'
export const ADD_MORTGAGE_PRODUCT_FAILURE = 'ADD_MORTGAGE_PRODUCT_FAILURE'

export const ARCHIVE_MORTGAGE_PRODUCT_REQUEST =
  'ARCHIVE_MORTGAGE_PRODUCT_REQUEST'
export const ARCHIVE_MORTGAGE_PRODUCT_SUCCESS =
  'ARCHIVE_MORTGAGE_PRODUCT_SUCCESS'
export const ARCHIVE_MORTGAGE_PRODUCT_FAILURE =
  'ARCHIVE_MORTGAGE_PRODUCT_FAILURE'

export const UPDATE_MORTGAGE_PRODUCT_REQUEST = 'UPDATE_MORTGAGE_PRODUCT_REQUEST'
export const UPDATE_MORTGAGE_PRODUCT_SUCCESS = 'UPDATE_MORTGAGE_PRODUCT_SUCCESS'
export const UPDATE_MORTGAGE_PRODUCT_FAILURE = 'UPDATE_MORTGAGE_PRODUCT_FAILURE'

export const ADD_MORTGAGE_REQUEST = 'ADD_MORTGAGE_REQUEST'
export const ADD_MORTGAGE_SUCCESS = 'ADD_MORTGAGE_SUCCESS'
export const ADD_MORTGAGE_FAILURE = 'ADD_MORTGAGE_FAILURE'

export const UPDATE_MORTGAGE_REQUEST = 'UPDATE_MORTGAGE_REQUEST'
export const UPDATE_MORTGAGE_SUCCESS = 'UPDATE_MORTGAGE_SUCCESS'
export const UPDATE_MORTGAGE_FAILURE = 'UPDATE_MORTGAGE_FAILURE'

export const UPDATE_HMO_DEAL_EXPENSES_REQUEST =
  'UPDATE_HMO_DEAL_EXPENSES_REQUEST'
export const UPDATE_HMO_DEAL_EXPENSES_SUCCESS =
  'UPDATE_HMO_DEAL_EXPENSES_SUCCESS'
export const UPDATE_HMO_DEAL_EXPENSES_FAILURE =
  'UPDATE_HMO_DEAL_EXPENSES_FAILURE'

export const DELETE_DEAL_EXPENSE_REQUEST = 'DELETE_DEAL_EXPENSE_REQUEST'
export const DELETE_DEAL_EXPENSE_SUCCESS = 'DELETE_DEAL_EXPENSE_SUCCESS'
export const DELETE_DEAL_EXPENSE_FAILURE = 'DELETE_DEAL_EXPENSE_FAILURE'

export const FETCH_DEAL_TEMPLATES_REQUEST = 'FETCH_DEAL_TEMPLATES_REQUEST'
export const FETCH_DEAL_TEMPLATES_SUCCESS = 'FETCH_DEAL_TEMPLATES_SUCCESS'
export const FETCH_DEAL_TEMPLATES_FAILURE = 'FETCH_DEAL_TEMPLATES_FAILURE'

export const ADD_DEAL_TEMPLATE_REQUEST = 'ADD_DEAL_TEMPLATE_REQUEST'
export const ADD_DEAL_TEMPLATE_SUCCESS = 'ADD_DEAL_TEMPLATE_SUCCESS'
export const ADD_DEAL_TEMPLATE_FAILURE = 'ADD_DEAL_TEMPLATE_FAILURE'

export const FETCH_DEAL_TEMPLATE_REQUEST = 'FETCH_DEAL_TEMPLATE_REQUEST'
export const FETCH_DEAL_TEMPLATE_SUCCESS = 'FETCH_DEAL_TEMPLATE_SUCCESS'
export const FETCH_DEAL_TEMPLATE_FAILURE = 'FETCH_DEAL_TEMPLATE_FAILURE'

export const UPDATE_DEAL_TEMPLATE_EXPENSES_REQUEST =
  'UPDATE_DEAL_TEMPLATE_EXPENSES_REQUEST'
export const UPDATE_DEAL_TEMPLATE_EXPENSES_SUCCESS =
  'UPDATE_DEAL_TEMPLATE_EXPENSES_SUCCESS'
export const UPDATE_DEAL_TEMPLATE_EXPENSES_FAILURE =
  'UPDATE_DEAL_TEMPLATE_EXPENSES_FAILURE'

export const CREATE_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const ADD_LOAN_REQUEST = 'ADD_LOAN_REQUEST'
export const ADD_LOAN_SUCCESS = 'ADD_LOAN_SUCCESS'
export const ADD_LOAN_FAILURE = 'ADD_LOAN_FAILURE'

export const ADD_SHAREHOLDING_REQUEST = 'ADD_SHAREHOLDING_REQUEST'
export const ADD_SHAREHOLDING_SUCCESS = 'ADD_SHAREHOLDING_SUCCESS'
export const ADD_SHAREHOLDING_FAILURE = 'ADD_SHAREHOLDING_FAILURE'

export const DELETE_DEAL_TEMPLATE_REQUEST = 'DELETE_DEAL_TEMPLATE_REQUEST'
export const DELETE_DEAL_TEMPLATE_SUCCESS = 'DELETE_DEAL_TEMPLATE_SUCCESS'
export const DELETE_DEAL_TEMPLATE_FAILURE = 'DELETE_DEAL_TEMPLATE_FAILURE'

export const UPDATE_DEAL_TEMPLATE_REQUEST = 'UPDATE_DEAL_TEMPLATE_REQUEST'
export const UPDATE_DEAL_TEMPLATE_SUCCESS = 'UPDATE_DEAL_TEMPLATE_SUCCESS'
export const UPDATE_DEAL_TEMPLATE_FAILURE = 'UPDATE_DEAL_TEMPLATE_FAILURE'

export const DELETE_DEAL_TEMPLATE_EXPENSE_REQUEST =
  'DELETE_DEAL_TEMPLATE_EXPENSE_REQUEST'
export const DELETE_DEAL_TEMPLATE_EXPENSE_SUCCESS =
  'DELETE_DEAL_TEMPLATE_EXPENSE_SUCCESS'
export const DELETE_DEAL_TEMPLATE_EXPENSE_FAILURE =
  'DELETE_DEAL_TEMPLATE_EXPENSE_FAILURE'

export const UPDATE_LOAN_REQUEST = 'UPDATE_LOAN_REQUEST'
export const UPDATE_LOAN_SUCCESS = 'UPDATE_LOAN_SUCCESS'
export const UPDATE_LOAN_FAILURE = 'UPDATE_LOAN_FAILURE'

export const DELETE_LOAN_REQUEST = 'DELETE_LOAN_REQUEST'
export const DELETE_LOAN_SUCCESS = 'DELETE_LOAN_SUCCESS'
export const DELETE_LOAN_FAILURE = 'DELETE_LOAN_FAILURE'

export const UPDATE_SHAREHOLDING_REQUEST = 'UPDATE_SHAREHOLDING_REQUEST'
export const UPDATE_SHAREHOLDING_SUCCESS = 'UPDATE_SHAREHOLDING_SUCCESS'
export const UPDATE_SHAREHOLDING_FAILURE = 'UPDATE_SHAREHOLDING_FAILURE'

export const DELETE_SHAREHOLDING_REQUEST = 'DELETE_SHAREHOLDING_REQUEST'
export const DELETE_SHAREHOLDING_SUCCESS = 'DELETE_SHAREHOLDING_SUCCESS'
export const DELETE_SHAREHOLDING_FAILURE = 'DELETE_SHAREHOLDING_FAILURE'

export const RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST =
  'RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST'
export const RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS =
  'RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS'
export const RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE =
  'RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE'

export const ADD_PRESENTATION_REQUEST = 'ADD_PRESENTATION_REQUEST'
export const ADD_PRESENTATION_SUCCESS = 'ADD_PRESENTATION_SUCCESS'
export const ADD_PRESENTATION_FAILURE = 'ADD_PRESENTATION_FAILURE'

export const UPDATE_PRESENTATION_REQUEST = 'UPDATE_PRESENTATION_REQUEST'
export const UPDATE_PRESENTATION_SUCCESS = 'UPDATE_PRESENTATION_SUCCESS'
export const UPDATE_PRESENTATION_FAILURE = 'UPDATE_PRESENTATION_FAILURE'

export const DELETE_PRESENTATION_REQUEST = 'DELETE_PRESENTATION_REQUEST'
export const DELETE_PRESENTATION_SUCCESS = 'DELETE_PRESENTATION_SUCCESS'
export const DELETE_PRESENTATION_FAILURE = 'DELETE_PRESENTATION_FAILURE'

export const FETCH_PRESENTATION_REQUEST = 'FETCH_PRESENTATION_REQUEST'
export const FETCH_PRESENTATION_SUCCESS = 'FETCH_PRESENTATION_SUCCESS'
export const FETCH_PRESENTATION_FAILURE = 'FETCH_PRESENTATION_FAILURE'

export const RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'RECOMPILE_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const RESET_PASSWORD_REQUEST_REQUEST = 'RESET_PASSWORD_REQUEST_REQUEST'
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS'
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST'
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE'

export const UPDATE_CURRENT_ACCOUNT_USER_REQUEST =
  'UPDATE_CURRENT_ACCOUNT_USER_REQUEST'
export const UPDATE_CURRENT_ACCOUNT_USER_SUCCESS =
  'UPDATE_CURRENT_ACCOUNT_USER_SUCCESS'
export const UPDATE_CURRENT_ACCOUNT_USER_FAILURE =
  'UPDATE_CURRENT_ACCOUNT_USER_FAILURE'

export const CHANGE_EMAIL_REQUEST_REQUEST = 'CHANGE_EMAIL_REQUEST_REQUEST'
export const CHANGE_EMAIL_REQUEST_SUCCESS = 'CHANGE_EMAIL_REQUEST_SUCCESS'
export const CHANGE_EMAIL_REQUEST_FAILURE = 'CHANGE_EMAIL_REQUEST_FAILURE'

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const UPDATE_AVATAR_REQUEST = 'UPDATE_AVATAR_REQUEST'
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS'
export const UPDATE_AVATAR_FAILURE = 'UPDATE_AVATAR_FAILURE'

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST'
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE'

export const SETUP_ACCOUNT_REQUEST = 'SETUP_ACCOUNT_REQUEST'
export const SETUP_ACCOUNT_SUCCESS = 'SETUP_ACCOUNT_SUCCESS'
export const SETUP_ACCOUNT_FAILURE = 'SETUP_ACCOUNT_FAILURE'

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE'

export const FETCH_TEAM_MEMBERS_REQUEST = 'FETCH_TEAM_MEMBERS_REQUEST'
export const FETCH_TEAM_MEMBERS_SUCCESS = 'FETCH_TEAM_MEMBERS_SUCCESS'
export const FETCH_TEAM_MEMBERS_FAILURE = 'FETCH_TEAM_MEMBERS_FAILURE'

export const INVITE_PROPERTY_INVESTOR_REQUEST =
  'INVITE_PROPERTY_INVESTOR_REQUEST'
export const INVITE_PROPERTY_INVESTOR_SUCCESS =
  'INVITE_PROPERTY_INVESTOR_SUCCESS'
export const INVITE_PROPERTY_INVESTOR_FAILURE =
  'INVITE_PROPERTY_INVESTOR_FAILURE'

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE'

export const REMOVE_PROPERTY_INVESTOR_REQUEST =
  'REMOVE_PROPERTY_INVESTOR_REQUEST'
export const REMOVE_PROPERTY_INVESTOR_SUCCESS =
  'REMOVE_PROPERTY_INVESTOR_SUCCESS'
export const REMOVE_PROPERTY_INVESTOR_FAILURE =
  'REMOVE_PROPERTY_INVESTOR_FAILURE'

export const ACCOUNT_SEARCH_REQUEST = 'ACCOUNT_SEARCH_REQUEST'
export const ACCOUNT_SEARCH_SUCCESS = 'ACCOUNT_SEARCH_SUCCESS'
export const ACCOUNT_SEARCH_FAILURE = 'ACCOUNT_SEARCH_FAILURE'

export const REQUEST_TO_JOIN_ACCOUNT_REQUEST = 'REQUEST_TO_JOIN_ACCOUNT_REQUEST'
export const REQUEST_TO_JOIN_ACCOUNT_SUCCESS = 'REQUEST_TO_JOIN_ACCOUNT_SUCCESS'
export const REQUEST_TO_JOIN_ACCOUNT_FAILURE = 'REQUEST_TO_JOIN_ACCOUNT_FAILURE'

export const GRANT_USER_ACCESS_TO_ACCOUNT_REQUEST =
  'GRANT_USER_ACCESS_TO_ACCOUNT_REQUEST'
export const GRANT_USER_ACCESS_TO_ACCOUNT_SUCCESS =
  'GRANT_USER_ACCESS_TO_ACCOUNT_SUCCESS'
export const GRANT_USER_ACCESS_TO_ACCOUNT_FAILURE =
  'GRANT_USER_ACCESS_TO_ACCOUNT_FAILURE'

export const EMAIL_PERSONAL_DATA_REQUEST = 'EMAIL_PERSONAL_DATA_REQUEST'
export const EMAIL_PERSONAL_DATA_SUCCESS = 'EMAIL_PERSONAL_DATA_SUCCESS'
export const EMAIL_PERSONAL_DATA_FAILURE = 'EMAIL_PERSONAL_DATA_FAILURE'

export const WITHDRAW_USER_CONSENT_REQUEST = 'WITHDRAW_USER_CONSENT_REQUEST'
export const WITHDRAW_USER_CONSENT_SUCCESS = 'WITHDRAW_USER_CONSENT_SUCCESS'
export const WITHDRAW_USER_CONSENT_FAILURE = 'WITHDRAW_USER_CONSENT_FAILURE'

export const GRANT_USER_CONSENT_REQUEST = 'GRANT_USER_CONSENT_REQUEST'
export const GRANT_USER_CONSENT_SUCCESS = 'GRANT_USER_CONSENT_SUCCESS'
export const GRANT_USER_CONSENT_FAILURE = 'GRANT_USER_CONSENT_FAILURE'

export const FORGET_USER_REQUEST = 'FORGET_USER_REQUEST'
export const FORGET_USER_SUCCESS = 'FORGET_USER_SUCCESS'
export const FORGET_USER_FAILURE = 'FORGET_USER_FAILURE'

export const FETCH_PRESENTATION_TEMPLATES_REQUEST =
  'FETCH_PRESENTATION_TEMPLATES_REQUEST'
export const FETCH_PRESENTATION_TEMPLATES_SUCCESS =
  'FETCH_PRESENTATION_TEMPLATES_SUCCESS'
export const FETCH_PRESENTATION_TEMPLATES_FAILURE =
  'FETCH_PRESENTATION_TEMPLATES_FAILURE'

export const FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'FETCH_HMO_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST =
  'FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST'
export const FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS =
  'FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS'
export const FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE =
  'FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE'

export const DELETE_MORTGAGE_REQUEST = 'DELETE_MORTGAGE_REQUEST'
export const DELETE_MORTGAGE_SUCCESS = 'DELETE_MORTGAGE_SUCCESS'
export const DELETE_MORTGAGE_FAILURE = 'DELETE_MORTGAGE_FAILURE'

export const CLEAR_EXPENSES_REQUEST = 'CLEAR_EXPENSES_REQUEST'
export const CLEAR_EXPENSES_SUCCESS = 'CLEAR_EXPENSES_SUCCESS'
export const CLEAR_EXPENSES_FAILURE = 'CLEAR_EXPENSES_FAILURE'

export const UPDATE_LOGO_REQUEST = 'UPDATE_LOGO_REQUEST'
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS'
export const UPDATE_LOGO_FAILURE = 'UPDATE_LOGO_FAILURE'

export const DELETE_SLIDE_REQUEST = 'DELETE_SLIDE_REQUEST'
export const DELETE_SLIDE_SUCCESS = 'DELETE_SLIDE_SUCCESS'
export const DELETE_SLIDE_FAILURE = 'DELETE_SLIDE_FAILURE'

export const ADD_RENT_COMPARABLE_SLIDE_REQUEST =
  'ADD_RENT_COMPARABLE_SLIDE_REQUEST'
export const ADD_RENT_COMPARABLE_SLIDE_SUCCESS =
  'ADD_RENT_COMPARABLE_SLIDE_SUCCESS'
export const ADD_RENT_COMPARABLE_SLIDE_FAILURE =
  'ADD_RENT_COMPARABLE_SLIDE_FAILURE'

export const ADD_SALE_COMPARABLE_SLIDE_REQUEST =
  'ADD_SALE_COMPARABLE_SLIDE_REQUEST'
export const ADD_SALE_COMPARABLE_SLIDE_SUCCESS =
  'ADD_SALE_COMPARABLE_SLIDE_SUCCESS'
export const ADD_SALE_COMPARABLE_SLIDE_FAILURE =
  'ADD_SALE_COMPARABLE_SLIDE_FAILURE'

export const ADD_RENT_LISTING_SLIDE_REQUEST = 'ADD_RENT_LISTING_SLIDE_REQUEST'
export const ADD_RENT_LISTING_SLIDE_SUCCESS = 'ADD_RENT_LISTING_SLIDE_SUCCESS'
export const ADD_RENT_LISTING_SLIDE_FAILURE = 'ADD_RENT_LISTING_SLIDE_FAILURE'

export const ADD_SALE_LISTING_SLIDE_REQUEST = 'ADD_SALE_LISTING_SLIDE_REQUEST'
export const ADD_SALE_LISTING_SLIDE_SUCCESS = 'ADD_SALE_LISTING_SLIDE_SUCCESS'
export const ADD_SALE_LISTING_SLIDE_FAILURE = 'ADD_SALE_LISTING_SLIDE_FAILURE'

export const ADD_PHOTO_SLIDE_REQUEST = 'ADD_PHOTO_SLIDE_REQUEST'
export const ADD_PHOTO_SLIDE_SUCCESS = 'ADD_PHOTO_SLIDE_SUCCESS'
export const ADD_PHOTO_SLIDE_FAILURE = 'ADD_PHOTO_SLIDE_FAILURE'

export const ADD_LOCATION_SLIDE_REQUEST = 'ADD_LOCATION_SLIDE_REQUEST'
export const ADD_LOCATION_SLIDE_SUCCESS = 'ADD_LOCATION_SLIDE_SUCCESS'
export const ADD_LOCATION_SLIDE_FAILURE = 'ADD_LOCATION_SLIDE_FAILURE'

export const ADD_TEXT_SLIDE_REQUEST = 'ADD_TEXT_SLIDE_REQUEST'
export const ADD_TEXT_SLIDE_SUCCESS = 'ADD_TEXT_SLIDE_SUCCESS'
export const ADD_TEXT_SLIDE_FAILURE = 'ADD_TEXT_SLIDE_FAILURE'

export const ADD_LOAN_SLIDE_REQUEST = 'ADD_LOAN_SLIDE_REQUEST'
export const ADD_LOAN_SLIDE_SUCCESS = 'ADD_LOAN_SLIDE_SUCCESS'
export const ADD_LOAN_SLIDE_FAILURE = 'ADD_LOAN_SLIDE_FAILURE'

export const ADD_SHAREHOLDING_SLIDE_REQUEST = 'ADD_SHAREHOLDING_SLIDE_REQUEST'
export const ADD_SHAREHOLDING_SLIDE_SUCCESS = 'ADD_SHAREHOLDING_SLIDE_SUCCESS'
export const ADD_SHAREHOLDING_SLIDE_FAILURE = 'ADD_SHAREHOLDING_SLIDE_FAILURE'

export const ADD_CASH_FLOW_SUMMARY_SLIDE_REQUEST =
  'ADD_CASH_FLOW_SUMMARY_SLIDE_REQUEST'
export const ADD_CASH_FLOW_SUMMARY_SLIDE_SUCCESS =
  'ADD_CASH_FLOW_SUMMARY_SLIDE_SUCCESS'
export const ADD_CASH_FLOW_SUMMARY_SLIDE_FAILURE =
  'ADD_CASH_FLOW_SUMMARY_SLIDE_FAILURE'

export const ADD_COVER_SLIDE_REQUEST = 'ADD_COVER_SLIDE_REQUEST'
export const ADD_COVER_SLIDE_SUCCESS = 'ADD_COVER_SLIDE_SUCCESS'
export const ADD_COVER_SLIDE_FAILURE = 'ADD_COVER_SLIDE_FAILURE'

export const UPDATE_COVER_SLIDE_REQUEST = 'UPDATE_COVER_SLIDE_REQUEST'
export const UPDATE_COVER_SLIDE_SUCCESS = 'UPDATE_COVER_SLIDE_SUCCESS'
export const UPDATE_COVER_SLIDE_FAILURE = 'UPDATE_COVER_SLIDE_FAILURE'

export const UPDATE_TEXT_SLIDE_REQUEST = 'UPDATE_TEXT_SLIDE_REQUEST'
export const UPDATE_TEXT_SLIDE_SUCCESS = 'UPDATE_TEXT_SLIDE_SUCCESS'
export const UPDATE_TEXT_SLIDE_FAILURE = 'UPDATE_TEXT_SLIDE_FAILURE'

export const UPDATE_RENT_COMPARABLE_SLIDE_REQUEST =
  'UPDATE_RENT_COMPARABLE_SLIDE_REQUEST'
export const UPDATE_RENT_COMPARABLE_SLIDE_SUCCESS =
  'UPDATE_RENT_COMPARABLE_SLIDE_SUCCESS'
export const UPDATE_RENT_COMPARABLE_SLIDE_FAILURE =
  'UPDATE_RENT_COMPARABLE_SLIDE_FAILURE'

export const UPDATE_SALE_COMPARABLE_SLIDE_REQUEST =
  'UPDATE_SALE_COMPARABLE_SLIDE_REQUEST'
export const UPDATE_SALE_COMPARABLE_SLIDE_SUCCESS =
  'UPDATE_SALE_COMPARABLE_SLIDE_SUCCESS'
export const UPDATE_SALE_COMPARABLE_SLIDE_FAILURE =
  'UPDATE_SALE_COMPARABLE_SLIDE_FAILURE'

export const UPDATE_RENT_LISTING_SLIDE_REQUEST =
  'UPDATE_RENT_LISTING_SLIDE_REQUEST'
export const UPDATE_RENT_LISTING_SLIDE_SUCCESS =
  'UPDATE_RENT_LISTING_SLIDE_SUCCESS'
export const UPDATE_RENT_LISTING_SLIDE_FAILURE =
  'UPDATE_RENT_LISTING_SLIDE_FAILURE'

export const UPDATE_SALE_LISTING_SLIDE_REQUEST =
  'UPDATE_SALE_LISTING_SLIDE_REQUEST'
export const UPDATE_SALE_LISTING_SLIDE_SUCCESS =
  'UPDATE_SALE_LISTING_SLIDE_SUCCESS'
export const UPDATE_SALE_LISTING_SLIDE_FAILURE =
  'UPDATE_SALE_LISTING_SLIDE_FAILURE'

export const UPDATE_PHOTO_SLIDE_REQUEST = 'UPDATE_PHOTO_SLIDE_REQUEST'
export const UPDATE_PHOTO_SLIDE_SUCCESS = 'UPDATE_PHOTO_SLIDE_SUCCESS'
export const UPDATE_PHOTO_SLIDE_FAILURE = 'UPDATE_PHOTO_SLIDE_FAILURE'

export const UPDATE_LOCATION_SLIDE_REQUEST = 'UPDATE_LOCATION_SLIDE_REQUEST'
export const UPDATE_LOCATION_SLIDE_SUCCESS = 'UPDATE_LOCATION_SLIDE_SUCCESS'
export const UPDATE_LOCATION_SLIDE_FAILURE = 'UPDATE_LOCATION_SLIDE_FAILURE'

export const UPDATE_LOAN_SLIDE_REQUEST = 'UPDATE_LOAN_SLIDE_REQUEST'
export const UPDATE_LOAN_SLIDE_SUCCESS = 'UPDATE_LOAN_SLIDE_SUCCESS'
export const UPDATE_LOAN_SLIDE_FAILURE = 'UPDATE_LOAN_SLIDE_FAILURE'

export const UPDATE_SHAREHOLDING_SLIDE_REQUEST =
  'UPDATE_SHAREHOLDING_SLIDE_REQUEST'
export const UPDATE_SHAREHOLDING_SLIDE_SUCCESS =
  'UPDATE_SHAREHOLDING_SLIDE_SUCCESS'
export const UPDATE_SHAREHOLDING_SLIDE_FAILURE =
  'UPDATE_SHAREHOLDING_SLIDE_FAILURE'

export const UPDATE_CASH_FLOW_SUMMARY_SLIDE_REQUEST =
  'UPDATE_CASH_FLOW_SUMMARY_SLIDE_REQUEST'
export const UPDATE_CASH_FLOW_SUMMARY_SLIDE_SUCCESS =
  'UPDATE_CASH_FLOW_SUMMARY_SLIDE_SUCCESS'
export const UPDATE_CASH_FLOW_SUMMARY_SLIDE_FAILURE =
  'UPDATE_CASH_FLOW_SUMMARY_SLIDE_FAILURE'

export const MOVE_SLIDE_UP_REQUEST = 'MOVE_SLIDE_UP_REQUEST'
export const MOVE_SLIDE_UP_SUCCESS = 'MOVE_SLIDE_UP_SUCCESS'
export const MOVE_SLIDE_UP_FAILURE = 'MOVE_SLIDE_UP_FAILURE'

export const MOVE_SLIDE_DOWN_REQUEST = 'MOVE_SLIDE_DOWN_REQUEST'
export const MOVE_SLIDE_DOWN_SUCCESS = 'MOVE_SLIDE_DOWN_SUCCESS'
export const MOVE_SLIDE_DOWN_FAILURE = 'MOVE_SLIDE_DOWN_FAILURE'

export const SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_REQUEST =
  'SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_REQUEST'
export const SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_SUCCESS =
  'SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_SUCCESS'
export const SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_FAILURE =
  'SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_FAILURE'

export const VERIFY_ACCESS_TO_PRESENTATION_REQUEST =
  'VERIFY_ACCESS_TO_PRESENTATION_REQUEST'
export const VERIFY_ACCESS_TO_PRESENTATION_SUCCESS =
  'VERIFY_ACCESS_TO_PRESENTATION_SUCCESS'
export const VERIFY_ACCESS_TO_PRESENTATION_FAILURE =
  'VERIFY_ACCESS_TO_PRESENTATION_FAILURE'

export const DUPLICATE_DEAL_REQUEST = 'DUPLICATE_DEAL_REQUEST'
export const DUPLICATE_DEAL_SUCCESS = 'DUPLICATE_DEAL_SUCCESS'
export const DUPLICATE_DEAL_FAILURE = 'DUPLICATE_DEAL_FAILURE'

export const DUPLICATE_HMO_DEAL_REQUEST = 'DUPLICATE_HMO_DEAL_REQUEST'
export const DUPLICATE_HMO_DEAL_SUCCESS = 'DUPLICATE_HMO_DEAL_SUCCESS'
export const DUPLICATE_HMO_DEAL_FAILURE = 'DUPLICATE_HMO_DEAL_FAILURE'

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST'
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE'

export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST'
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS'
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE'

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST'
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE'

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST'
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE'

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE'

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE'

export const MARK_TASK_AS_DONE_REQUEST = 'MARK_TASK_AS_DONE_REQUEST'
export const MARK_TASK_AS_DONE_SUCCESS = 'MARK_TASK_AS_DONE_SUCCESS'
export const MARK_TASK_AS_DONE_FAILURE = 'MARK_TASK_AS_DONE_FAILURE'

export const UNMARK_TASK_AS_DONE_REQUEST = 'UNMARK_TASK_AS_DONE_REQUEST'
export const UNMARK_TASK_AS_DONE_SUCCESS = 'UNMARK_TASK_AS_DONE_SUCCESS'
export const UNMARK_TASK_AS_DONE_FAILURE = 'UNMARK_TASK_AS_DONE_FAILURE'

export const ADD_REMINDER_TO_TASK_REQUEST = 'ADD_REMINDER_TO_TASK_REQUEST'
export const ADD_REMINDER_TO_TASK_SUCCESS = 'ADD_REMINDER_TO_TASK_SUCCESS'
export const ADD_REMINDER_TO_TASK_FAILURE = 'ADD_REMINDER_TO_TASK_FAILURE'

export const UPDATE_REMINDER_REQUEST = 'UPDATE_REMINDER_REQUEST'
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS'
export const UPDATE_REMINDER_FAILURE = 'UPDATE_REMINDER_FAILURE'

export const DELETE_REMINDER_FROM_TASK_REQUEST = 'DELETE_REMINDER_FROM_TASK_REQUEST'
export const DELETE_REMINDER_FROM_TASK_SUCCESS = 'DELETE_REMINDER_FROM_TASK_SUCCESS'
export const DELETE_REMINDER_FROM_TASK_FAILURE = 'DELETE_REMINDER_FROM_TASK_FAILURE'

export const ADD_REACTION_REQUEST = 'ADD_REACTION_REQUEST'
export const ADD_REACTION_SUCCESS = 'ADD_REACTION_SUCCESS'
export const ADD_REACTION_FAILURE = 'ADD_REACTION_FAILURE'

export const DELETE_REACTION_REQUEST = 'DELETE_REACTION_REQUEST'
export const DELETE_REACTION_SUCCESS = 'DELETE_REACTION_SUCCESS'
export const DELETE_REACTION_FAILURE = 'DELETE_REACTION_FAILURE'

export const ADD_ENERGY_PERFORMANCE_CERTIFICATE_TO_RESOURCE_REQUEST =
  'ADD_ENERGY_PERFORMANCE_CERTIFICATE_TO_RESOURCE_REQUEST'
export const ADD_ENERGY_PERFORMANCE_CERTIFICATE_TO_RESOURCE_SUCCESS =
  'ADD_ENERGY_PERFORMANCE_CERTIFICATE_TO_RESOURCE_SUCCESS'
export const ADD_ENERGY_PERFORMANCE_CERTIFICATE_TO_RESOURCE_FAILURE =
  'ADD_ENERGY_PERFORMANCE_CERTIFICATE_TO_RESOURCE_FAILURE'

export const DELETE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST =
  'DELETE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const DELETE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS =
  'DELETE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const DELETE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE =
  'DELETE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const ADD_PROPERTY_DOCUMENT_REQUEST = 'ADD_PROPERTY_DOCUMENT_REQUEST'
export const ADD_PROPERTY_DOCUMENT_SUCCESS = 'ADD_PROPERTY_DOCUMENT_SUCCESS'
export const ADD_PROPERTY_DOCUMENT_FAILURE = 'ADD_PROPERTY_DOCUMENT_FAILURE'

export const ADD_CONVERSATION_DOCUMENT_REQUEST =
  'ADD_CONVERSATION_DOCUMENT_REQUEST'
export const ADD_CONVERSATION_DOCUMENT_SUCCESS =
  'ADD_CONVERSATION_DOCUMENT_SUCCESS'
export const ADD_CONVERSATION_DOCUMENT_FAILURE =
  'ADD_CONVERSATION_DOCUMENT_FAILURE'

export const UPDATE_PROPERTY_DOCUMENT_REQUEST =
  'UPDATE_PROPERTY_DOCUMENT_REQUEST'
export const UPDATE_PROPERTY_DOCUMENT_SUCCESS =
  'UPDATE_PROPERTY_DOCUMENT_SUCCESS'
export const UPDATE_PROPERTY_DOCUMENT_FAILURE =
  'UPDATE_PROPERTY_DOCUMENT_FAILURE'

export const UPDATE_CONVERSATION_DOCUMENT_REQUEST =
  'UPDATE_CONVERSATION_DOCUMENT_REQUEST'
export const UPDATE_CONVERSATION_DOCUMENT_SUCCESS =
  'UPDATE_CONVERSATION_DOCUMENT_SUCCESS'
export const UPDATE_CONVERSATION_DOCUMENT_FAILURE =
  'UPDATE_CONVERSATION_DOCUMENT_FAILURE'

export const UPLOAD_PROPERTY_DOCUMENT_FILE_REQUEST =
  'UPLOAD_PROPERTY_DOCUMENT_FILE_REQUEST'
export const UPLOAD_PROPERTY_DOCUMENT_FILE_SUCCESS =
  'UPLOAD_PROPERTY_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_PROPERTY_DOCUMENT_FILE_FAILURE =
  'UPLOAD_PROPERTY_DOCUMENT_FILE_FAILURE'

export const UPLOAD_CONVERSATION_DOCUMENT_FILE_REQUEST =
  'UPLOAD_CONVERSATION_DOCUMENT_FILE_REQUEST'
export const UPLOAD_CONVERSATION_DOCUMENT_FILE_SUCCESS =
  'UPLOAD_CONVERSATION_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_CONVERSATION_DOCUMENT_FILE_FAILURE =
  'UPLOAD_CONVERSATION_DOCUMENT_FILE_FAILURE'

export const DELETE_PROPERTY_DOCUMENT_REQUEST =
  'DELETE_PROPERTY_DOCUMENT_REQUEST'
export const DELETE_PROPERTY_DOCUMENT_SUCCESS =
  'DELETE_PROPERTY_DOCUMENT_SUCCESS'
export const DELETE_PROPERTY_DOCUMENT_FAILURE =
  'DELETE_PROPERTY_DOCUMENT_FAILURE'

export const DELETE_CONVERSATION_DOCUMENT_REQUEST =
  'DELETE_CONVERSATION_DOCUMENT_REQUEST'
export const DELETE_CONVERSATION_DOCUMENT_SUCCESS =
  'DELETE_CONVERSATION_DOCUMENT_SUCCESS'
export const DELETE_CONVERSATION_DOCUMENT_FAILURE =
  'DELETE_CONVERSATION_DOCUMENT_FAILURE'

export const ADD_TASK_ASSIGNEE_REQUEST = 'ADD_TASK_ASSIGNEE_REQUEST'
export const ADD_TASK_ASSIGNEE_SUCCESS = 'ADD_TASK_ASSIGNEE_SUCCESS'
export const ADD_TASK_ASSIGNEE_FAILURE = 'ADD_TASK_ASSIGNEE_FAILURE'

export const DELETE_TASK_ASSIGNEE_REQUEST = 'DELETE_TASK_ASSIGNEE_REQUEST'
export const DELETE_TASK_ASSIGNEE_SUCCESS = 'DELETE_TASK_ASSIGNEE_SUCCESS'
export const DELETE_TASK_ASSIGNEE_FAILURE = 'DELETE_TASK_ASSIGNEE_FAILURE'

export const FETCH_USER_PROPERTIES_REQUEST = 'FETCH_USER_PROPERTIES_REQUEST'
export const FETCH_USER_PROPERTIES_SUCCESS = 'FETCH_USER_PROPERTIES_SUCCESS'
export const FETCH_USER_PROPERTIES_FAILURE = 'FETCH_USER_PROPERTIES_FAILURE'

export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE'

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST'
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS'
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE'

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE'

export const ARCHIVE_COMPANY_REQUEST = 'ARCHIVE_COMPANY_REQUEST'
export const ARCHIVE_COMPANY_SUCCESS = 'ARCHIVE_COMPANY_SUCCESS'
export const ARCHIVE_COMPANY_FAILURE = 'ARCHIVE_COMPANY_FAILURE'

export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE'

export const ADD_SHAREHOLDER_REQUEST = 'ADD_SHAREHOLDER_REQUEST'
export const ADD_SHAREHOLDER_SUCCESS = 'ADD_SHAREHOLDER_SUCCESS'
export const ADD_SHAREHOLDER_FAILURE = 'ADD_SHAREHOLDER_FAILURE'

export const UPDATE_SHAREHOLDER_REQUEST = 'UPDATE_SHAREHOLDER_REQUEST'
export const UPDATE_SHAREHOLDER_SUCCESS = 'UPDATE_SHAREHOLDER_SUCCESS'
export const UPDATE_SHAREHOLDER_FAILURE = 'UPDATE_SHAREHOLDER_FAILURE'

export const DELETE_SHAREHOLDER_REQUEST = 'DELETE_SHAREHOLDER_REQUEST'
export const DELETE_SHAREHOLDER_SUCCESS = 'DELETE_SHAREHOLDER_SUCCESS'
export const DELETE_SHAREHOLDER_FAILURE = 'DELETE_SHAREHOLDER_FAILURE'

export const ADD_DIRECTOR_REQUEST = 'ADD_DIRECTOR_REQUEST'
export const ADD_DIRECTOR_SUCCESS = 'ADD_DIRECTOR_SUCCESS'
export const ADD_DIRECTOR_FAILURE = 'ADD_DIRECTOR_FAILURE'

export const UPDATE_DIRECTOR_REQUEST = 'UPDATE_DIRECTOR_REQUEST'
export const UPDATE_DIRECTOR_SUCCESS = 'UPDATE_DIRECTOR_SUCCESS'
export const UPDATE_DIRECTOR_FAILURE = 'UPDATE_DIRECTOR_FAILURE'

export const DELETE_DIRECTOR_REQUEST = 'DELETE_DIRECTOR_REQUEST'
export const DELETE_DIRECTOR_SUCCESS = 'DELETE_DIRECTOR_SUCCESS'
export const DELETE_DIRECTOR_FAILURE = 'DELETE_DIRECTOR_FAILURE'

export const FETCH_OWNED_PROPERTY_REQUEST = 'FETCH_OWNED_PROPERTY_REQUEST'
export const FETCH_OWNED_PROPERTY_SUCCESS = 'FETCH_OWNED_PROPERTY_SUCCESS'
export const FETCH_OWNED_PROPERTY_FAILURE = 'FETCH_OWNED_PROPERTY_FAILURE'

export const ADD_PROPERTY_OWNER_TO_OWNED_PROPERTY_REQUEST =
  'ADD_PROPERTY_OWNER_TO_OWNED_PROPERTY_REQUEST'
export const ADD_PROPERTY_OWNER_TO_OWNED_PROPERTY_SUCCESS =
  'ADD_PROPERTY_OWNER_TO_OWNED_PROPERTY_SUCCESS'
export const ADD_PROPERTY_OWNER_TO_OWNED_PROPERTY_FAILURE =
  'ADD_PROPERTY_OWNER_TO_OWNED_PROPERTY_FAILURE'

export const UPDATE_PROPERTY_OWNER_FOR_OWNED_PROPERTY_REQUEST =
  'UPDATE_PROPERTY_OWNER_FOR_OWNED_PROPERTY_REQUEST'
export const UPDATE_PROPERTY_OWNER_FOR_OWNED_PROPERTY_SUCCESS =
  'UPDATE_PROPERTY_OWNER_FOR_OWNED_PROPERTY_SUCCESS'
export const UPDATE_PROPERTY_OWNER_FOR_OWNED_PROPERTY_FAILURE =
  'UPDATE_PROPERTY_OWNER_FOR_OWNED_PROPERTY_FAILURE'

export const DELETE_PROPERTY_OWNER_REQUEST = 'DELETE_PROPERTY_OWNER_REQUEST'
export const DELETE_PROPERTY_OWNER_SUCCESS = 'DELETE_PROPERTY_OWNER_SUCCESS'
export const DELETE_PROPERTY_OWNER_FAILURE = 'DELETE_PROPERTY_OWNER_FAILURE'

export const FETCH_ACCOUNT_COMPANIES_REQUEST = 'FETCH_ACCOUNT_COMPANIES_REQUEST'
export const FETCH_ACCOUNT_COMPANIES_SUCCESS = 'FETCH_ACCOUNT_COMPANIES_SUCCESS'
export const FETCH_ACCOUNT_COMPANIES_FAILURE = 'FETCH_ACCOUNT_COMPANIES_FAILURE'

export const ADD_PROSPECTIVE_MORTGAGE_REQUEST =
  'ADD_PROSPECTIVE_MORTGAGE_REQUEST'
export const ADD_PROSPECTIVE_MORTGAGE_SUCCESS =
  'ADD_PROSPECTIVE_MORTGAGE_SUCCESS'
export const ADD_PROSPECTIVE_MORTGAGE_FAILURE =
  'ADD_PROSPECTIVE_MORTGAGE_FAILURE'

export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_HMO_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_HMO_DEAL_FAILURE'

export const CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_HMO_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_HMO_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_HMO_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_HMO_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_REQUEST =
  'ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_REQUEST'
export const ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_FAILURE =
  'ADD_PROSPECTIVE_MORTGAGE_TO_HMO_DEAL_FAILURE'

export const ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_REQUEST =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_REQUEST'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_FAILURE =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_HMO_DEAL_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_HMO_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_HMO_DEAL_FAILURE'

export const FETCH_PRESENTATION_SLIDES_REQUEST =
  'FETCH_PRESENTATION_SLIDES_REQUEST'
export const FETCH_PRESENTATION_SLIDES_SUCCESS =
  'FETCH_PRESENTATION_SLIDES_SUCCESS'
export const FETCH_PRESENTATION_SLIDES_FAILURE =
  'FETCH_PRESENTATION_SLIDES_FAILURE'

export const FETCH_PRESENTATION_INVITATIONS_REQUEST =
  'FETCH_PRESENTATION_INVITATIONS_REQUEST'
export const FETCH_PRESENTATION_INVITATIONS_SUCCESS =
  'FETCH_PRESENTATION_INVITATIONS_SUCCESS'
export const FETCH_PRESENTATION_INVITATIONS_FAILURE =
  'FETCH_PRESENTATION_INVITATIONS_FAILURE'

export const FETCH_CONVERSATION_CONTRIBUTIONS_REQUEST =
  'FETCH_CONVERSATION_CONTRIBUTIONS_REQUEST'
export const FETCH_CONVERSATION_CONTRIBUTIONS_SUCCESS =
  'FETCH_CONVERSATION_CONTRIBUTIONS_SUCCESS'
export const FETCH_CONVERSATION_CONTRIBUTIONS_FAILURE =
  'FETCH_CONVERSATION_CONTRIBUTIONS_FAILURE'

export const FETCH_CONTRIBUTION_ITEMS_REQUEST =
  'FETCH_CONTRIBUTION_ITEMS_REQUEST'
export const FETCH_CONTRIBUTION_ITEMS_SUCCESS =
  'FETCH_CONTRIBUTION_ITEMS_SUCCESS'
export const FETCH_CONTRIBUTION_ITEMS_FAILURE =
  'FETCH_CONTRIBUTION_ITEMS_FAILURE'

export const ADD_COMMENT_REPLY_REQUEST = 'ADD_COMMENT_REPLY_REQUEST'
export const ADD_COMMENT_REPLY_SUCCESS = 'ADD_COMMENT_REPLY_SUCCESS'
export const ADD_COMMENT_REPLY_FAILURE = 'ADD_COMMENT_REPLY_FAILURE'

export const ADD_TASK_REPLY_REQUEST = 'ADD_TASK_REPLY_REQUEST'
export const ADD_TASK_REPLY_SUCCESS = 'ADD_TASK_REPLY_SUCCESS'
export const ADD_TASK_REPLY_FAILURE = 'ADD_TASK_REPLY_FAILURE'

export const ADD_CONVERSATION_DOCUMENT_REPLY_REQUEST =
  'ADD_CONVERSATION_DOCUMENT_REPLY_REQUEST'
export const ADD_CONVERSATION_DOCUMENT_REPLY_SUCCESS =
  'ADD_CONVERSATION_DOCUMENT_REPLY_SUCCESS'
export const ADD_CONVERSATION_DOCUMENT_REPLY_FAILURE =
  'ADD_CONVERSATION_DOCUMENT_REPLY_FAILURE'

export const FETCH_CURRENT_ACCOUNT_REQUEST = 'FETCH_CURRENT_ACCOUNT_REQUEST'
export const FETCH_CURRENT_ACCOUNT_SUCCESS = 'FETCH_CURRENT_ACCOUNT_SUCCESS'
export const FETCH_CURRENT_ACCOUNT_FAILURE = 'FETCH_CURRENT_ACCOUNT_FAILURE'

export const FETCH_CURRENT_EMAIL_IDENTITY_REQUEST =
  'FETCH_CURRENT_EMAIL_IDENTITY_REQUEST'
export const FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS =
  'FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS'
export const FETCH_CURRENT_EMAIL_IDENTITY_FAILURE =
  'FETCH_CURRENT_EMAIL_IDENTITY_FAILURE'

export const FETCH_CURRENT_USER_POLICY_REQUEST =
  'FETCH_CURRENT_USER_POLICY_REQUEST'
export const FETCH_CURRENT_USER_POLICY_SUCCESS =
  'FETCH_CURRENT_USER_POLICY_SUCCESS'
export const FETCH_CURRENT_USER_POLICY_FAILURE =
  'FETCH_CURRENT_USER_POLICY_FAILURE'

export const FETCH_SINGLE_LET_DEAL_REQUEST = 'FETCH_SINGLE_LET_DEAL_REQUEST'
export const FETCH_SINGLE_LET_DEAL_SUCCESS = 'FETCH_SINGLE_LET_DEAL_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_FAILURE = 'FETCH_SINGLE_LET_DEAL_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'FETCH_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'FETCH_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'FETCH_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const FETCH_RENT_TO_RENT_DEAL_REQUEST = 'FETCH_RENT_TO_RENT_DEAL_REQUEST'
export const FETCH_RENT_TO_RENT_DEAL_SUCCESS = 'FETCH_RENT_TO_RENT_DEAL_SUCCESS'
export const FETCH_RENT_TO_RENT_DEAL_FAILURE = 'FETCH_RENT_TO_RENT_DEAL_FAILURE'

export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST =
  'FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST'
export const FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE =
  'FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE'

export const FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST =
  'FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST'
export const FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE =
  'FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE'

export const FETCH_SINGLE_LET_DEAL_TEMPLATES_REQUEST =
  'FETCH_SINGLE_LET_DEAL_TEMPLATES_REQUEST'
export const FETCH_SINGLE_LET_DEAL_TEMPLATES_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_TEMPLATES_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_TEMPLATES_FAILURE =
  'FETCH_SINGLE_LET_DEAL_TEMPLATES_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE'

export const FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_REQUEST =
  'FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_REQUEST'
export const FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_FAILURE =
  'FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_FAILURE'

export const FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST =
  'FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST'
export const FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE =
  'FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE'

export const FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_SINGLE_LET_DEAL_MORTGAGE_REQUEST =
  'FETCH_SINGLE_LET_DEAL_MORTGAGE_REQUEST'
export const FETCH_SINGLE_LET_DEAL_MORTGAGE_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_MORTGAGE_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_MORTGAGE_FAILURE =
  'FETCH_SINGLE_LET_DEAL_MORTGAGE_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_FAILURE'

export const FETCH_SINGLE_LET_DEAL_REMORTGAGE_REQUEST =
  'FETCH_SINGLE_LET_DEAL_REMORTGAGE_REQUEST'
export const FETCH_SINGLE_LET_DEAL_REMORTGAGE_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_REMORTGAGE_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_REMORTGAGE_FAILURE =
  'FETCH_SINGLE_LET_DEAL_REMORTGAGE_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_FAILURE'

export const UPDATE_SINGLE_LET_DEAL_EXPENSES_REQUEST =
  'UPDATE_SINGLE_LET_DEAL_EXPENSES_REQUEST'
export const UPDATE_SINGLE_LET_DEAL_EXPENSES_SUCCESS =
  'UPDATE_SINGLE_LET_DEAL_EXPENSES_SUCCESS'
export const UPDATE_SINGLE_LET_DEAL_EXPENSES_FAILURE =
  'UPDATE_SINGLE_LET_DEAL_EXPENSES_FAILURE'

export const UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST =
  'UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST'
export const UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS =
  'UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS'
export const UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE =
  'UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE'

export const CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_REQUEST =
  'ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_REQUEST'
export const ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_FAILURE =
  'ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_FAILURE'

export const ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_REQUEST =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_REQUEST'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_FAILURE =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_FAILURE'

export const ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const UPDATE_SINGLE_LET_DEAL_REQUEST = 'UPDATE_SINGLE_LET_DEAL_REQUEST'
export const UPDATE_SINGLE_LET_DEAL_SUCCESS = 'UPDATE_SINGLE_LET_DEAL_SUCCESS'
export const UPDATE_SINGLE_LET_DEAL_FAILURE = 'UPDATE_SINGLE_LET_DEAL_FAILURE'

export const UPDATE_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'UPDATE_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const UPDATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'UPDATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const UPDATE_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'UPDATE_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const ARCHIVE_SINGLE_LET_DEAL_REQUEST = 'ARCHIVE_SINGLE_LET_DEAL_REQUEST'
export const ARCHIVE_SINGLE_LET_DEAL_SUCCESS = 'ARCHIVE_SINGLE_LET_DEAL_SUCCESS'
export const ARCHIVE_SINGLE_LET_DEAL_FAILURE = 'ARCHIVE_SINGLE_LET_DEAL_FAILURE'

export const ARCHIVE_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'ARCHIVE_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const ARCHIVE_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'ARCHIVE_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const ARCHIVE_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'ARCHIVE_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const UPDATE_RENT_TO_RENT_DEAL_REQUEST = 'UPDATE_RENT_TO_RENT_DEAL_REQUEST'
export const UPDATE_RENT_TO_RENT_DEAL_SUCCESS = 'UPDATE_RENT_TO_RENT_DEAL_SUCCESS'
export const UPDATE_RENT_TO_RENT_DEAL_FAILURE = 'UPDATE_RENT_TO_RENT_DEAL_FAILURE'

export const UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST = 'UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS = 'UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE = 'UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const DUPLICATE_SINGLE_LET_DEAL_REQUEST =
  'DUPLICATE_SINGLE_LET_DEAL_REQUEST'
export const DUPLICATE_SINGLE_LET_DEAL_SUCCESS =
  'DUPLICATE_SINGLE_LET_DEAL_SUCCESS'
export const DUPLICATE_SINGLE_LET_DEAL_FAILURE =
  'DUPLICATE_SINGLE_LET_DEAL_FAILURE'

export const DUPLICATE_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'DUPLICATE_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const DUPLICATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'DUPLICATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const DUPLICATE_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'DUPLICATE_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST =
  'CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST'
export const CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS =
  'CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS'
export const CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE =
  'CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE'

export const CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const FETCH_OWNED_PROPERTIES_REQUEST = 'FETCH_OWNED_PROPERTIES_REQUEST'
export const FETCH_OWNED_PROPERTIES_SUCCESS = 'FETCH_OWNED_PROPERTIES_SUCCESS'
export const FETCH_OWNED_PROPERTIES_FAILURE = 'FETCH_OWNED_PROPERTIES_FAILURE'

export const ADD_OWNED_PROPERTY_REQUEST = 'ADD_OWNED_PROPERTY_REQUEST'
export const ADD_OWNED_PROPERTY_SUCCESS = 'ADD_OWNED_PROPERTY_SUCCESS'
export const ADD_OWNED_PROPERTY_FAILURE = 'ADD_OWNED_PROPERTY_FAILURE'

export const UPDATE_OWNED_PROPERTY_REQUEST = 'UPDATE_OWNED_PROPERTY_REQUEST'
export const UPDATE_OWNED_PROPERTY_SUCCESS = 'UPDATE_OWNED_PROPERTY_SUCCESS'
export const UPDATE_OWNED_PROPERTY_FAILURE = 'UPDATE_OWNED_PROPERTY_FAILURE'

export const DELETE_OWNED_PROPERTY_REQUEST = 'DELETE_OWNED_PROPERTY_REQUEST'
export const DELETE_OWNED_PROPERTY_SUCCESS = 'DELETE_OWNED_PROPERTY_SUCCESS'
export const DELETE_OWNED_PROPERTY_FAILURE = 'DELETE_OWNED_PROPERTY_FAILURE'

export const FETCH_COMPANY_SHAREHOLDERS_REQUEST =
  'FETCH_COMPANY_SHAREHOLDERS_REQUEST'
export const FETCH_COMPANY_SHAREHOLDERS_SUCCESS =
  'FETCH_COMPANY_SHAREHOLDERS_SUCCESS'
export const FETCH_COMPANY_SHAREHOLDERS_FAILURE =
  'FETCH_COMPANY_SHAREHOLDERS_FAILURE'

export const FETCH_COMPANY_DIRECTORS_REQUEST = 'FETCH_COMPANY_DIRECTORS_REQUEST'
export const FETCH_COMPANY_DIRECTORS_SUCCESS = 'FETCH_COMPANY_DIRECTORS_SUCCESS'
export const FETCH_COMPANY_DIRECTORS_FAILURE = 'FETCH_COMPANY_DIRECTORS_FAILURE'

export const FETCH_PROPERTY_OWNERS_REQUEST = 'FETCH_PROPERTY_OWNERS_REQUEST'
export const FETCH_PROPERTY_OWNERS_SUCCESS = 'FETCH_PROPERTY_OWNERS_SUCCESS'
export const FETCH_PROPERTY_OWNERS_FAILURE = 'FETCH_PROPERTY_OWNERS_FAILURE'

export const FETCH_USER_DOCUMENTS_REQUEST = 'FETCH_USER_DOCUMENTS_REQUEST'
export const FETCH_USER_DOCUMENTS_SUCCESS = 'FETCH_USER_DOCUMENTS_SUCCESS'
export const FETCH_USER_DOCUMENTS_FAILURE = 'FETCH_USER_DOCUMENTS_FAILURE'

export const ADD_USER_DOCUMENT_REQUEST = 'ADD_USER_DOCUMENT_REQUEST'
export const ADD_USER_DOCUMENT_SUCCESS = 'ADD_USER_DOCUMENT_SUCCESS'
export const ADD_USER_DOCUMENT_FAILURE = 'ADD_USER_DOCUMENT_FAILURE'

export const UPDATE_USER_DOCUMENT_REQUEST = 'UPDATE_USER_DOCUMENT_REQUEST'
export const UPDATE_USER_DOCUMENT_SUCCESS = 'UPDATE_USER_DOCUMENT_SUCCESS'
export const UPDATE_USER_DOCUMENT_FAILURE = 'UPDATE_USER_DOCUMENT_FAILURE'

export const DELETE_USER_DOCUMENT_REQUEST = 'DELETE_USER_DOCUMENT_REQUEST'
export const DELETE_USER_DOCUMENT_SUCCESS = 'DELETE_USER_DOCUMENT_SUCCESS'
export const DELETE_USER_DOCUMENT_FAILURE = 'DELETE_USER_DOCUMENT_FAILURE'

export const UPLOAD_USER_DOCUMENT_FILE_REQUEST =
  'UPLOAD_USER_DOCUMENT_FILE_REQUEST'
export const UPLOAD_USER_DOCUMENT_FILE_SUCCESS =
  'UPLOAD_USER_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_USER_DOCUMENT_FILE_FAILURE =
  'UPLOAD_USER_DOCUMENT_FILE_FAILURE'

export const ADD_DOCUMENT_SLIDE_REQUEST = 'ADD_DOCUMENT_SLIDE_REQUEST'
export const ADD_DOCUMENT_SLIDE_SUCCESS = 'ADD_DOCUMENT_SLIDE_SUCCESS'
export const ADD_DOCUMENT_SLIDE_FAILURE = 'ADD_DOCUMENT_SLIDE_FAILURE'

export const UPDATE_DOCUMENT_SLIDE_REQUEST = 'UPDATE_DOCUMENT_SLIDE_REQUEST'
export const UPDATE_DOCUMENT_SLIDE_SUCCESS = 'UPDATE_DOCUMENT_SLIDE_SUCCESS'
export const UPDATE_DOCUMENT_SLIDE_FAILURE = 'UPDATE_DOCUMENT_SLIDE_FAILURE'

export const DELETE_DOCUMENT_SLIDE_REQUEST = 'DELETE_DOCUMENT_SLIDE_REQUEST'
export const DELETE_DOCUMENT_SLIDE_SUCCESS = 'DELETE_DOCUMENT_SLIDE_SUCCESS'
export const DELETE_DOCUMENT_SLIDE_FAILURE = 'DELETE_DOCUMENT_SLIDE_FAILURE'

export const FETCH_EXISTING_MORTGAGES_REQUEST =
  'FETCH_EXISTING_MORTGAGES_REQUEST'
export const FETCH_EXISTING_MORTGAGES_SUCCESS =
  'FETCH_EXISTING_MORTGAGES_SUCCESS'
export const FETCH_EXISTING_MORTGAGES_FAILURE =
  'FETCH_EXISTING_MORTGAGES_FAILURE'

export const ADD_EXISTING_MORTGAGE_REQUEST = 'ADD_EXISTING_MORTGAGE_REQUEST'
export const ADD_EXISTING_MORTGAGE_SUCCESS = 'ADD_EXISTING_MORTGAGE_SUCCESS'
export const ADD_EXISTING_MORTGAGE_FAILURE = 'ADD_EXISTING_MORTGAGE_FAILURE'

export const UPDATE_EXISTING_MORTGAGE_REQUEST =
  'UPDATE_EXISTING_MORTGAGE_REQUEST'
export const UPDATE_EXISTING_MORTGAGE_SUCCESS =
  'UPDATE_EXISTING_MORTGAGE_SUCCESS'
export const UPDATE_EXISTING_MORTGAGE_FAILURE =
  'UPDATE_EXISTING_MORTGAGE_FAILURE'

export const DELETE_EXISTING_MORTGAGE_REQUEST =
  'DELETE_EXISTING_MORTGAGE_REQUEST'
export const DELETE_EXISTING_MORTGAGE_SUCCESS =
  'DELETE_EXISTING_MORTGAGE_SUCCESS'
export const DELETE_EXISTING_MORTGAGE_FAILURE =
  'DELETE_EXISTING_MORTGAGE_FAILURE'

export const FETCH_EXISTING_MORTGAGE_REQUEST = 'FETCH_EXISTING_MORTGAGE_REQUEST'
export const FETCH_EXISTING_MORTGAGE_SUCCESS = 'FETCH_EXISTING_MORTGAGE_SUCCESS'
export const FETCH_EXISTING_MORTGAGE_FAILURE = 'FETCH_EXISTING_MORTGAGE_FAILURE'

export const FETCH_MORTGAGE_ACCOUNTS_REQUEST = 'FETCH_MORTGAGE_ACCOUNTS_REQUEST'
export const FETCH_MORTGAGE_ACCOUNTS_SUCCESS = 'FETCH_MORTGAGE_ACCOUNTS_SUCCESS'
export const FETCH_MORTGAGE_ACCOUNTS_FAILURE = 'FETCH_MORTGAGE_ACCOUNTS_FAILURE'

export const ADD_MORTGAGE_ACCOUNT_REQUEST = 'ADD_MORTGAGE_ACCOUNT_REQUEST'
export const ADD_MORTGAGE_ACCOUNT_SUCCESS = 'ADD_MORTGAGE_ACCOUNT_SUCCESS'
export const ADD_MORTGAGE_ACCOUNT_FAILURE = 'ADD_MORTGAGE_ACCOUNT_FAILURE'

export const UPDATE_MORTGAGE_ACCOUNT_REQUEST = 'UPDATE_MORTGAGE_ACCOUNT_REQUEST'
export const UPDATE_MORTGAGE_ACCOUNT_SUCCESS = 'UPDATE_MORTGAGE_ACCOUNT_SUCCESS'
export const UPDATE_MORTGAGE_ACCOUNT_FAILURE = 'UPDATE_MORTGAGE_ACCOUNT_FAILURE'

export const DELETE_MORTGAGE_ACCOUNT_REQUEST = 'DELETE_MORTGAGE_ACCOUNT_REQUEST'
export const DELETE_MORTGAGE_ACCOUNT_SUCCESS = 'DELETE_MORTGAGE_ACCOUNT_SUCCESS'
export const DELETE_MORTGAGE_ACCOUNT_FAILURE = 'DELETE_MORTGAGE_ACCOUNT_FAILURE'

export const FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_REQUEST =
  'FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_REQUEST'
export const FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_SUCCESS =
  'FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_SUCCESS'
export const FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_FAILURE =
  'FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_FAILURE'

export const FETCH_UNITS_REQUEST = 'FETCH_UNITS_REQUEST'
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS'
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE'

export const ADD_UNIT_REQUEST = 'ADD_UNIT_REQUEST'
export const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS'
export const ADD_UNIT_FAILURE = 'ADD_UNIT_FAILURE'

export const UPDATE_UNIT_REQUEST = 'UPDATE_UNIT_REQUEST'
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS'
export const UPDATE_UNIT_FAILURE = 'UPDATE_UNIT_FAILURE'

export const DELETE_UNIT_REQUEST = 'DELETE_UNIT_REQUEST'
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS'
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE'

export const FETCH_UNIT_REQUEST = 'FETCH_UNIT_REQUEST'
export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS'
export const FETCH_UNIT_FAILURE = 'FETCH_UNIT_FAILURE'

export const FETCH_TENANCIES_REQUEST = 'FETCH_TENANCIES_REQUEST'
export const FETCH_TENANCIES_SUCCESS = 'FETCH_TENANCIES_SUCCESS'
export const FETCH_TENANCIES_FAILURE = 'FETCH_TENANCIES_FAILURE'

export const ADD_TENANCY_REQUEST = 'ADD_TENANCY_REQUEST'
export const ADD_TENANCY_SUCCESS = 'ADD_TENANCY_SUCCESS'
export const ADD_TENANCY_FAILURE = 'ADD_TENANCY_FAILURE'

export const UPDATE_TENANCY_REQUEST = 'UPDATE_TENANCY_REQUEST'
export const UPDATE_TENANCY_SUCCESS = 'UPDATE_TENANCY_SUCCESS'
export const UPDATE_TENANCY_FAILURE = 'UPDATE_TENANCY_FAILURE'

export const ARCHIVE_TENANCY_REQUEST = 'ARCHIVE_TENANCY_REQUEST'
export const ARCHIVE_TENANCY_SUCCESS = 'ARCHIVE_TENANCY_SUCCESS'
export const ARCHIVE_TENANCY_FAILURE = 'ARCHIVE_TENANCY_FAILURE'

export const FETCH_BRIDGING_LENDERS_REQUEST = 'FETCH_BRIDGING_LENDERS_REQUEST'
export const FETCH_BRIDGING_LENDERS_SUCCESS = 'FETCH_BRIDGING_LENDERS_SUCCESS'
export const FETCH_BRIDGING_LENDERS_FAILURE = 'FETCH_BRIDGING_LENDERS_FAILURE'

export const FETCH_ACCOUNT_BRIDGING_LENDERS_REQUEST =
  'FETCH_ACCOUNT_BRIDGING_LENDERS_REQUEST'
export const FETCH_ACCOUNT_BRIDGING_LENDERS_SUCCESS =
  'FETCH_ACCOUNT_BRIDGING_LENDERS_SUCCESS'
export const FETCH_ACCOUNT_BRIDGING_LENDERS_FAILURE =
  'FETCH_ACCOUNT_BRIDGING_LENDERS_FAILURE'

export const ADD_BRIDGING_LOAN_PRODUCT_REQUEST =
  'ADD_BRIDGING_LOAN_PRODUCT_REQUEST'
export const ADD_BRIDGING_LOAN_PRODUCT_SUCCESS =
  'ADD_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const ADD_BRIDGING_LOAN_PRODUCT_FAILURE =
  'ADD_BRIDGING_LOAN_PRODUCT_FAILURE'

export const UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST =
  'UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST'
export const UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS =
  'UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE =
  'UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE'

export const ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST =
  'ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST'
export const ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS =
  'ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE =
  'ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE'

export const FETCH_HMO_DEAL_BRIDGING_LOAN_REQUEST =
  'FETCH_HMO_DEAL_BRIDGING_LOAN_REQUEST'
export const FETCH_HMO_DEAL_BRIDGING_LOAN_SUCCESS =
  'FETCH_HMO_DEAL_BRIDGING_LOAN_SUCCESS'
export const FETCH_HMO_DEAL_BRIDGING_LOAN_FAILURE =
  'FETCH_HMO_DEAL_BRIDGING_LOAN_FAILURE'

export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_REQUEST =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_REQUEST'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_FAILURE =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_HMO_DEAL_FAILURE'

export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_REQUEST =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_REQUEST'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_FAILURE =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_FAILURE'

export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_HMO_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_HMO_DEAL_FAILURE'

export const FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_REQUEST =
  'FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_REQUEST'
export const FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_SUCCESS =
  'FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_SUCCESS'
export const FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_FAILURE =
  'FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_FAILURE'

export const FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_REQUEST =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_REQUEST'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_SUCCESS =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_SUCCESS'
export const FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_FAILURE =
  'FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_FAILURE'

export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE'

export const ADD_FLIP_DEAL_REQUEST = 'ADD_FLIP_DEAL_REQUEST'
export const ADD_FLIP_DEAL_SUCCESS = 'ADD_FLIP_DEAL_SUCCESS'
export const ADD_FLIP_DEAL_FAILURE = 'ADD_FLIP_DEAL_FAILURE'

export const ARCHIVE_FLIP_DEAL_REQUEST = 'ARCHIVE_FLIP_DEAL_REQUEST'
export const ARCHIVE_FLIP_DEAL_SUCCESS = 'ARCHIVE_FLIP_DEAL_SUCCESS'
export const ARCHIVE_FLIP_DEAL_FAILURE = 'ARCHIVE_FLIP_DEAL_FAILURE'

export const DUPLICATE_FLIP_DEAL_REQUEST = 'DUPLICATE_FLIP_DEAL_REQUEST'
export const DUPLICATE_FLIP_DEAL_SUCCESS = 'DUPLICATE_FLIP_DEAL_SUCCESS'
export const DUPLICATE_FLIP_DEAL_FAILURE = 'DUPLICATE_FLIP_DEAL_FAILURE'

export const FETCH_FLIP_DEAL_REQUEST = 'FETCH_FLIP_DEAL_REQUEST'
export const FETCH_FLIP_DEAL_SUCCESS = 'FETCH_FLIP_DEAL_SUCCESS'
export const FETCH_FLIP_DEAL_FAILURE = 'FETCH_FLIP_DEAL_FAILURE'

export const FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST =
  'FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST'
export const FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE =
  'FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE'

export const FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST =
  'FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST'
export const FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE =
  'FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE'

export const FETCH_FLIP_DEAL_TEMPLATES_REQUEST =
  'FETCH_FLIP_DEAL_TEMPLATES_REQUEST'
export const FETCH_FLIP_DEAL_TEMPLATES_SUCCESS =
  'FETCH_FLIP_DEAL_TEMPLATES_SUCCESS'
export const FETCH_FLIP_DEAL_TEMPLATES_FAILURE =
  'FETCH_FLIP_DEAL_TEMPLATES_FAILURE'

export const FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_REQUEST =
  'FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_REQUEST'
export const FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS =
  'FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS'
export const FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_FAILURE =
  'FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_FAILURE'

export const FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST =
  'FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST'
export const FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS =
  'FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS'
export const FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE =
  'FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE'

export const FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_FLIP_DEAL_MORTGAGE_REQUEST =
  'FETCH_FLIP_DEAL_MORTGAGE_REQUEST'
export const FETCH_FLIP_DEAL_MORTGAGE_SUCCESS =
  'FETCH_FLIP_DEAL_MORTGAGE_SUCCESS'
export const FETCH_FLIP_DEAL_MORTGAGE_FAILURE =
  'FETCH_FLIP_DEAL_MORTGAGE_FAILURE'

export const FETCH_FLIP_DEAL_REMORTGAGE_REQUEST =
  'FETCH_FLIP_DEAL_REMORTGAGE_REQUEST'
export const FETCH_FLIP_DEAL_REMORTGAGE_SUCCESS =
  'FETCH_FLIP_DEAL_REMORTGAGE_SUCCESS'
export const FETCH_FLIP_DEAL_REMORTGAGE_FAILURE =
  'FETCH_FLIP_DEAL_REMORTGAGE_FAILURE'

export const UPDATE_FLIP_DEAL_EXPENSES_REQUEST =
  'UPDATE_FLIP_DEAL_EXPENSES_REQUEST'
export const UPDATE_FLIP_DEAL_EXPENSES_SUCCESS =
  'UPDATE_FLIP_DEAL_EXPENSES_SUCCESS'
export const UPDATE_FLIP_DEAL_EXPENSES_FAILURE =
  'UPDATE_FLIP_DEAL_EXPENSES_FAILURE'

export const CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_REQUEST =
  'ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_REQUEST'
export const ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_FAILURE =
  'ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_FAILURE'

export const ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_REQUEST =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_REQUEST'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_FAILURE =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_FAILURE'

export const FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const UPDATE_FLIP_DEAL_REQUEST = 'UPDATE_FLIP_DEAL_REQUEST'
export const UPDATE_FLIP_DEAL_SUCCESS = 'UPDATE_FLIP_DEAL_SUCCESS'
export const UPDATE_FLIP_DEAL_FAILURE = 'UPDATE_FLIP_DEAL_FAILURE'

export const CLEAR_EXPENSES_FROM_FLIP_DEAL_REQUEST =
  'CLEAR_EXPENSES_FROM_FLIP_DEAL_REQUEST'
export const CLEAR_EXPENSES_FROM_FLIP_DEAL_SUCCESS =
  'CLEAR_EXPENSES_FROM_FLIP_DEAL_SUCCESS'
export const CLEAR_EXPENSES_FROM_FLIP_DEAL_FAILURE =
  'CLEAR_EXPENSES_FROM_FLIP_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_FAILURE'

export const FETCH_FLIP_DEAL_BRIDGING_LOAN_REQUEST =
  'FETCH_FLIP_DEAL_BRIDGING_LOAN_REQUEST'
export const FETCH_FLIP_DEAL_BRIDGING_LOAN_SUCCESS =
  'FETCH_FLIP_DEAL_BRIDGING_LOAN_SUCCESS'
export const FETCH_FLIP_DEAL_BRIDGING_LOAN_FAILURE =
  'FETCH_FLIP_DEAL_BRIDGING_LOAN_FAILURE'

export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_REQUEST =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_REQUEST'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_FAILURE =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_FAILURE'

export const FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_REQUEST =
  'FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_REQUEST'
export const FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_SUCCESS =
  'FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_SUCCESS'
export const FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_FAILURE =
  'FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_FAILURE'

export const CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const FETCH_RESOURCE_CATEGORIES_REQUEST =
  'FETCH_RESOURCE_CATEGORIES_REQUEST'
export const FETCH_RESOURCE_CATEGORIES_SUCCESS =
  'FETCH_RESOURCE_CATEGORIES_SUCCESS'
export const FETCH_RESOURCE_CATEGORIES_FAILURE =
  'FETCH_RESOURCE_CATEGORIES_FAILURE'

export const FETCH_RESOURCE_CATEGORY_REQUEST = 'FETCH_RESOURCE_CATEGORY_REQUEST'
export const FETCH_RESOURCE_CATEGORY_SUCCESS = 'FETCH_RESOURCE_CATEGORY_SUCCESS'
export const FETCH_RESOURCE_CATEGORY_FAILURE = 'FETCH_RESOURCE_CATEGORY_FAILURE'

export const DUPLICATE_PRESENTATION_REQUEST = 'DUPLICATE_PRESENTATION_REQUEST'
export const DUPLICATE_PRESENTATION_SUCCESS = 'DUPLICATE_PRESENTATION_SUCCESS'
export const DUPLICATE_PRESENTATION_FAILURE = 'DUPLICATE_PRESENTATION_FAILURE'

export const CONVERT_PROSPECTIVE_PROPERTY_TO_OWNED_PROPERTY_REQUEST =
  'CONVERT_PROSPECTIVE_PROPERTY_TO_OWNED_PROPERTY_REQUEST'
export const CONVERT_PROSPECTIVE_PROPERTY_TO_OWNED_PROPERTY_SUCCESS =
  'CONVERT_PROSPECTIVE_PROPERTY_TO_OWNED_PROPERTY_SUCCESS'
export const CONVERT_PROSPECTIVE_PROPERTY_TO_OWNED_PROPERTY_FAILURE =
  'CONVERT_PROSPECTIVE_PROPERTY_TO_OWNED_PROPERTY_FAILURE'

export const FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_REQUEST =
  'FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_REQUEST'
export const FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_FAILURE =
  'FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_FAILURE'

export const FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_REQUEST =
  'FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_REQUEST'
export const FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_SUCCESS =
  'FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_SUCCESS'
export const FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_FAILURE =
  'FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_FAILURE'

export const UPDATE_OWNED_PROPERTY_EXPENSES_REQUEST =
  'UPDATE_OWNED_PROPERTY_EXPENSES_REQUEST'
export const UPDATE_OWNED_PROPERTY_EXPENSES_SUCCESS =
  'UPDATE_OWNED_PROPERTY_EXPENSES_SUCCESS'
export const UPDATE_OWNED_PROPERTY_EXPENSES_FAILURE =
  'UPDATE_OWNED_PROPERTY_EXPENSES_FAILURE'

export const FETCH_OWNED_PROPERTY_TENANCIES_REQUEST =
  'FETCH_OWNED_PROPERTY_TENANCIES_REQUEST'
export const FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS =
  'FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS'
export const FETCH_OWNED_PROPERTY_TENANCIES_FAILURE =
  'FETCH_OWNED_PROPERTY_TENANCIES_FAILURE'

export const FETCH_OWNED_PROPERTY_UNITS_REQUEST =
  'FETCH_OWNED_PROPERTY_UNITS_REQUEST'
export const FETCH_OWNED_PROPERTY_UNITS_SUCCESS =
  'FETCH_OWNED_PROPERTY_UNITS_SUCCESS'
export const FETCH_OWNED_PROPERTY_UNITS_FAILURE =
  'FETCH_OWNED_PROPERTY_UNITS_FAILURE'

export const FETCH_PRIVATE_INVESTORS_REQUEST = 'FETCH_PRIVATE_INVESTORS_REQUEST'
export const FETCH_PRIVATE_INVESTORS_SUCCESS = 'FETCH_PRIVATE_INVESTORS_SUCCESS'
export const FETCH_PRIVATE_INVESTORS_FAILURE = 'FETCH_PRIVATE_INVESTORS_FAILURE'

export const ADD_PRIVATE_INVESTOR_TO_ACCOUNT_REQUEST =
  'ADD_PRIVATE_INVESTOR_TO_ACCOUNT_REQUEST'
export const ADD_PRIVATE_INVESTOR_TO_ACCOUNT_SUCCESS =
  'ADD_PRIVATE_INVESTOR_TO_ACCOUNT_SUCCESS'
export const ADD_PRIVATE_INVESTOR_TO_ACCOUNT_FAILURE =
  'ADD_PRIVATE_INVESTOR_TO_ACCOUNT_FAILURE'

export const REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_REQUEST =
  'REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_REQUEST'
export const REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_SUCCESS =
  'REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_SUCCESS'
export const REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_FAILURE =
  'REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_FAILURE'

export const ADD_WORKFLOW_TO_PROSPECTIVE_PROPERTY_REQUEST =
  'ADD_WORKFLOW_TO_PROSPECTIVE_PROPERTY_REQUEST'
export const ADD_WORKFLOW_TO_PROSPECTIVE_PROPERTY_SUCCESS =
  'ADD_WORKFLOW_TO_PROSPECTIVE_PROPERTY_SUCCESS'
export const ADD_WORKFLOW_TO_PROSPECTIVE_PROPERTY_FAILURE =
  'ADD_WORKFLOW_TO_PROSPECTIVE_PROPERTY_FAILURE'

export const FETCH_WORKFLOW_REQUEST = 'FETCH_WORKFLOW_REQUEST'
export const FETCH_WORKFLOW_SUCCESS = 'FETCH_WORKFLOW_SUCCESS'
export const FETCH_WORKFLOW_FAILURE = 'FETCH_WORKFLOW_FAILURE'

export const FETCH_WORKFLOW_PIPELINE_REQUEST = 'FETCH_WORKFLOW_PIPELINE_REQUEST'
export const FETCH_WORKFLOW_PIPELINE_SUCCESS = 'FETCH_WORKFLOW_PIPELINE_SUCCESS'
export const FETCH_WORKFLOW_PIPELINE_FAILURE = 'FETCH_WORKFLOW_PIPELINE_FAILURE'

export const FETCH_WORKFLOW_CONVERSATION_REQUEST =
  'FETCH_WORKFLOW_CONVERSATION_REQUEST'
export const FETCH_WORKFLOW_CONVERSATION_SUCCESS =
  'FETCH_WORKFLOW_CONVERSATION_SUCCESS'
export const FETCH_WORKFLOW_CONVERSATION_FAILURE =
  'FETCH_WORKFLOW_CONVERSATION_FAILURE'

export const UPDATE_PIPELINE_STATUS_REQUEST = 'UPDATE_PIPELINE_STATUS_REQUEST'
export const UPDATE_PIPELINE_STATUS_SUCCESS = 'UPDATE_PIPELINE_STATUS_SUCCESS'
export const UPDATE_PIPELINE_STATUS_FAILURE = 'UPDATE_PIPELINE_STATUS_FAILURE'

export const ADD_MORTGAGE_APPLICATION_WORKFLOW_REQUEST =
  'ADD_MORTGAGE_APPLICATION_WORKFLOW_REQUEST'
export const ADD_MORTGAGE_APPLICATION_WORKFLOW_SUCCESS =
  'ADD_MORTGAGE_APPLICATION_WORKFLOW_SUCCESS'
export const ADD_MORTGAGE_APPLICATION_WORKFLOW_FAILURE =
  'ADD_MORTGAGE_APPLICATION_WORKFLOW_FAILURE'

export const FETCH_MORTGAGE_APPLICATIONS_REQUEST =
  'FETCH_MORTGAGE_APPLICATIONS_REQUEST'
export const FETCH_MORTGAGE_APPLICATIONS_SUCCESS =
  'FETCH_MORTGAGE_APPLICATIONS_SUCCESS'
export const FETCH_MORTGAGE_APPLICATIONS_FAILURE =
  'FETCH_MORTGAGE_APPLICATIONS_FAILURE'

export const FETCH_ACCOUNT_USERS_REQUEST = 'FETCH_ACCOUNT_USERS_REQUEST'
export const FETCH_ACCOUNT_USERS_SUCCESS = 'FETCH_ACCOUNT_USERS_SUCCESS'
export const FETCH_ACCOUNT_USERS_FAILURE = 'FETCH_ACCOUNT_USERS_FAILURE'

export const ADD_ACCOUNT_USER_REQUEST = 'ADD_ACCOUNT_USER_REQUEST'
export const ADD_ACCOUNT_USER_SUCCESS = 'ADD_ACCOUNT_USER_SUCCESS'
export const ADD_ACCOUNT_USER_FAILURE = 'ADD_ACCOUNT_USER_FAILURE'

export const UPDATE_ACCOUNT_USER_REQUEST = 'UPDATE_ACCOUNT_USER_REQUEST'
export const UPDATE_ACCOUNT_USER_SUCCESS = 'UPDATE_ACCOUNT_USER_SUCCESS'
export const UPDATE_ACCOUNT_USER_FAILURE = 'UPDATE_ACCOUNT_USER_FAILURE'

export const FETCH_ACCOUNT_USER_REQUEST = 'FETCH_ACCOUNT_USER_REQUEST'
export const FETCH_ACCOUNT_USER_SUCCESS = 'FETCH_ACCOUNT_USER_SUCCESS'
export const FETCH_ACCOUNT_USER_FAILURE = 'FETCH_ACCOUNT_USER_FAILURE'

export const ADD_PROPERTY_OWNER_TO_PROSPECTIVE_PROPERTY_REQUEST =
  'ADD_PROPERTY_OWNER_TO_PROSPECTIVE_PROPERTY_REQUEST'
export const ADD_PROPERTY_OWNER_TO_PROSPECTIVE_PROPERTY_SUCCESS =
  'ADD_PROPERTY_OWNER_TO_PROSPECTIVE_PROPERTY_SUCCESS'
export const ADD_PROPERTY_OWNER_TO_PROSPECTIVE_PROPERTY_FAILURE =
  'ADD_PROPERTY_OWNER_TO_PROSPECTIVE_PROPERTY_FAILURE'

export const UPDATE_PROPERTY_OWNER_FOR_PROSPECTIVE_PROPERTY_REQUEST =
  'UPDATE_PROPERTY_OWNER_FOR_PROSPECTIVE_PROPERTY_REQUEST'
export const UPDATE_PROPERTY_OWNER_FOR_PROSPECTIVE_PROPERTY_SUCCESS =
  'UPDATE_PROPERTY_OWNER_FOR_PROSPECTIVE_PROPERTY_SUCCESS'
export const UPDATE_PROPERTY_OWNER_FOR_PROSPECTIVE_PROPERTY_FAILURE =
  'UPDATE_PROPERTY_OWNER_FOR_PROSPECTIVE_PROPERTY_FAILURE'

export const ARCHIVE_ACCOUNT_USER_REQUEST = 'ARCHIVE_ACCOUNT_USER_REQUEST'
export const ARCHIVE_ACCOUNT_USER_SUCCESS = 'ARCHIVE_ACCOUNT_USER_SUCCESS'
export const ARCHIVE_ACCOUNT_USER_FAILURE = 'ARCHIVE_ACCOUNT_USER_FAILURE'

export const MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_REQUEST =
  'MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_REQUEST'
export const MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_SUCCESS =
  'MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_SUCCESS'
export const MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_FAILURE =
  'MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_FAILURE'

export const DELETE_PRIVATE_INVESTOR_REQUEST = 'DELETE_PRIVATE_INVESTOR_REQUEST'
export const DELETE_PRIVATE_INVESTOR_SUCCESS = 'DELETE_PRIVATE_INVESTOR_SUCCESS'
export const DELETE_PRIVATE_INVESTOR_FAILURE = 'DELETE_PRIVATE_INVESTOR_FAILURE'

export const MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_REQUEST =
  'MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_REQUEST'
export const MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_SUCCESS =
  'MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_SUCCESS'
export const MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_FAILURE =
  'MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_FAILURE'

export const DELETE_PROPERTY_INVESTOR_REQUEST =
  'DELETE_PROPERTY_INVESTOR_REQUEST'
export const DELETE_PROPERTY_INVESTOR_SUCCESS =
  'DELETE_PROPERTY_INVESTOR_SUCCESS'
export const DELETE_PROPERTY_INVESTOR_FAILURE =
  'DELETE_PROPERTY_INVESTOR_FAILURE'

export const FETCH_ACCOUNT_USER_SEARCH_RESULTS_REQUEST =
  'FETCH_ACCOUNT_USER_SEARCH_RESULTS_REQUEST'
export const FETCH_ACCOUNT_USER_SEARCH_RESULTS_SUCCESS =
  'FETCH_ACCOUNT_USER_SEARCH_RESULTS_SUCCESS'
export const FETCH_ACCOUNT_USER_SEARCH_RESULTS_FAILURE =
  'FETCH_ACCOUNT_USER_SEARCH_RESULTS_FAILURE'

export const JOIN_ACCOUNT_REQUEST = 'JOIN_ACCOUNT_REQUEST'
export const JOIN_ACCOUNT_SUCCESS = 'JOIN_ACCOUNT_SUCCESS'
export const JOIN_ACCOUNT_FAILURE = 'JOIN_ACCOUNT_FAILURE'

export const FETCH_TENANCY_REQUEST = 'FETCH_TENANCY_REQUEST'
export const FETCH_TENANCY_SUCCESS = 'FETCH_TENANCY_SUCCESS'
export const FETCH_TENANCY_FAILURE = 'FETCH_TENANCY_FAILURE'

export const FETCH_TENANCY_TENANTS_REQUEST = 'FETCH_TENANCY_TENANTS_REQUEST'
export const FETCH_TENANCY_TENANTS_SUCCESS = 'FETCH_TENANCY_TENANTS_SUCCESS'
export const FETCH_TENANCY_TENANTS_FAILURE = 'FETCH_TENANCY_TENANTS_FAILURE'

export const FETCH_TENANTS_REQUEST = 'FETCH_TENANTS_REQUEST'
export const FETCH_TENANTS_SUCCESS = 'FETCH_TENANTS_SUCCESS'
export const FETCH_TENANTS_FAILURE = 'FETCH_TENANTS_FAILURE'

export const ADD_TENANCY_TENANT_REQUEST = 'ADD_TENANCY_TENANT_REQUEST'
export const ADD_TENANCY_TENANT_SUCCESS = 'ADD_TENANCY_TENANT_SUCCESS'
export const ADD_TENANCY_TENANT_FAILURE = 'ADD_TENANCY_TENANT_FAILURE'

export const MAKE_ACCOUNT_USER_A_TENANT_REQUEST =
  'MAKE_ACCOUNT_USER_A_TENANT_REQUEST'
export const MAKE_ACCOUNT_USER_A_TENANT_SUCCESS =
  'MAKE_ACCOUNT_USER_A_TENANT_SUCCESS'
export const MAKE_ACCOUNT_USER_A_TENANT_FAILURE =
  'MAKE_ACCOUNT_USER_A_TENANT_FAILURE'

export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST'
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS'
export const DELETE_TENANT_FAILURE = 'DELETE_TENANT_FAILURE'

export const MAKE_ACCOUNT_USER_A_GUARANTOR_REQUEST =
  'MAKE_ACCOUNT_USER_A_GUARANTOR_REQUEST'
export const MAKE_ACCOUNT_USER_A_GUARANTOR_SUCCESS =
  'MAKE_ACCOUNT_USER_A_GUARANTOR_SUCCESS'
export const MAKE_ACCOUNT_USER_A_GUARANTOR_FAILURE =
  'MAKE_ACCOUNT_USER_A_GUARANTOR_FAILURE'

export const DELETE_GUARANTOR_REQUEST = 'DELETE_GUARANTOR_REQUEST'
export const DELETE_GUARANTOR_SUCCESS = 'DELETE_GUARANTOR_SUCCESS'
export const DELETE_GUARANTOR_FAILURE = 'DELETE_GUARANTOR_FAILURE'

export const DELETE_TENANCY_TENANT_REQUEST = 'DELETE_TENANCY_TENANT_REQUEST'
export const DELETE_TENANCY_TENANT_SUCCESS = 'DELETE_TENANCY_TENANT_SUCCESS'
export const DELETE_TENANCY_TENANT_FAILURE = 'DELETE_TENANCY_TENANT_FAILURE'

export const FETCH_TENANCY_GUARANTORS_REQUEST =
  'FETCH_TENANCY_GUARANTORS_REQUEST'
export const FETCH_TENANCY_GUARANTORS_SUCCESS =
  'FETCH_TENANCY_GUARANTORS_SUCCESS'
export const FETCH_TENANCY_GUARANTORS_FAILURE =
  'FETCH_TENANCY_GUARANTORS_FAILURE'

export const FETCH_GUARANTORS_REQUEST = 'FETCH_GUARANTORS_REQUEST'
export const FETCH_GUARANTORS_SUCCESS = 'FETCH_GUARANTORS_SUCCESS'
export const FETCH_GUARANTORS_FAILURE = 'FETCH_GUARANTORS_FAILURE'

export const ADD_TENANCY_GUARANTOR_REQUEST = 'ADD_TENANCY_GUARANTOR_REQUEST'
export const ADD_TENANCY_GUARANTOR_SUCCESS = 'ADD_TENANCY_GUARANTOR_SUCCESS'
export const ADD_TENANCY_GUARANTOR_FAILURE = 'ADD_TENANCY_GUARANTOR_FAILURE'

export const DELETE_TENANCY_GUARANTOR_REQUEST =
  'DELETE_TENANCY_GUARANTOR_REQUEST'
export const DELETE_TENANCY_GUARANTOR_SUCCESS =
  'DELETE_TENANCY_GUARANTOR_SUCCESS'
export const DELETE_TENANCY_GUARANTOR_FAILURE =
  'DELETE_TENANCY_GUARANTOR_FAILURE'

export const ADD_TENANCY_DOCUMENT_REQUEST = 'ADD_TENANCY_DOCUMENT_REQUEST'
export const ADD_TENANCY_DOCUMENT_SUCCESS = 'ADD_TENANCY_DOCUMENT_SUCCESS'
export const ADD_TENANCY_DOCUMENT_FAILURE = 'ADD_TENANCY_DOCUMENT_FAILURE'

export const DELETE_TENANCY_DOCUMENT_REQUEST = 'DELETE_TENANCY_DOCUMENT_REQUEST'
export const DELETE_TENANCY_DOCUMENT_SUCCESS = 'DELETE_TENANCY_DOCUMENT_SUCCESS'
export const DELETE_TENANCY_DOCUMENT_FAILURE = 'DELETE_TENANCY_DOCUMENT_FAILURE'

export const UPDATE_TENANCY_DOCUMENT_REQUEST = 'UPDATE_TENANCY_DOCUMENT_REQUEST'
export const UPDATE_TENANCY_DOCUMENT_SUCCESS = 'UPDATE_TENANCY_DOCUMENT_SUCCESS'
export const UPDATE_TENANCY_DOCUMENT_FAILURE = 'UPDATE_TENANCY_DOCUMENT_FAILURE'

export const FETCH_TENANCY_DOCUMENTS_REQUEST = 'FETCH_TENANCY_DOCUMENTS_REQUEST'
export const FETCH_TENANCY_DOCUMENTS_SUCCESS = 'FETCH_TENANCY_DOCUMENTS_SUCCESS'
export const FETCH_TENANCY_DOCUMENTS_FAILURE = 'FETCH_TENANCY_DOCUMENTS_FAILURE'

export const UPLOAD_TENANCY_DOCUMENT_FILE_REQUEST =
  'UPLOAD_TENANCY_DOCUMENT_FILE_REQUEST'
export const UPLOAD_TENANCY_DOCUMENT_FILE_SUCCESS =
  'UPLOAD_TENANCY_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_TENANCY_DOCUMENT_FILE_FAILURE =
  'UPLOAD_TENANCY_DOCUMENT_FILE_FAILURE'

export const FETCH_ACCOUNT_USER_OWNED_PROPERTIES_REQUEST =
  'FETCH_ACCOUNT_USER_OWNED_PROPERTIES_REQUEST'
export const FETCH_ACCOUNT_USER_OWNED_PROPERTIES_SUCCESS =
  'FETCH_ACCOUNT_USER_OWNED_PROPERTIES_SUCCESS'
export const FETCH_ACCOUNT_USER_OWNED_PROPERTIES_FAILURE =
  'FETCH_ACCOUNT_USER_OWNED_PROPERTIES_FAILURE'

export const FETCH_COMPANY_OWNED_PROPERTIES_REQUEST =
  'FETCH_COMPANY_OWNED_PROPERTIES_REQUEST'
export const FETCH_COMPANY_OWNED_PROPERTIES_SUCCESS =
  'FETCH_COMPANY_OWNED_PROPERTIES_SUCCESS'
export const FETCH_COMPANY_OWNED_PROPERTIES_FAILURE =
  'FETCH_COMPANY_OWNED_PROPERTIES_FAILURE'

export const FETCH_ACCOUNT_PARTNERS_REQUEST = 'FETCH_ACCOUNT_PARTNERS_REQUEST'
export const FETCH_ACCOUNT_PARTNERS_SUCCESS = 'FETCH_ACCOUNT_PARTNERS_SUCCESS'
export const FETCH_ACCOUNT_PARTNERS_FAILURE = 'FETCH_ACCOUNT_PARTNERS_FAILURE'

export const ADD_ACCOUNT_PARTNER_REQUEST = 'ADD_ACCOUNT_PARTNER_REQUEST'
export const ADD_ACCOUNT_PARTNER_SUCCESS = 'ADD_ACCOUNT_PARTNER_SUCCESS'
export const ADD_ACCOUNT_PARTNER_FAILURE = 'ADD_ACCOUNT_PARTNER_FAILURE'

export const UPDATE_ACCOUNT_PARTNER_REQUEST = 'UPDATE_ACCOUNT_PARTNER_REQUEST'
export const UPDATE_ACCOUNT_PARTNER_SUCCESS = 'UPDATE_ACCOUNT_PARTNER_SUCCESS'
export const UPDATE_ACCOUNT_PARTNER_FAILURE = 'UPDATE_ACCOUNT_PARTNER_FAILURE'

export const DELETE_ACCOUNT_PARTNER_REQUEST = 'DELETE_ACCOUNT_PARTNER_REQUEST'
export const DELETE_ACCOUNT_PARTNER_SUCCESS = 'DELETE_ACCOUNT_PARTNER_SUCCESS'
export const DELETE_ACCOUNT_PARTNER_FAILURE = 'DELETE_ACCOUNT_PARTNER_FAILURE'

export const FETCH_MORTGAGE_BROKER_ACCOUNTS_REQUEST =
  'FETCH_MORTGAGE_BROKER_ACCOUNTS_REQUEST'
export const FETCH_MORTGAGE_BROKER_ACCOUNTS_SUCCESS =
  'FETCH_MORTGAGE_BROKER_ACCOUNTS_SUCCESS'
export const FETCH_MORTGAGE_BROKER_ACCOUNTS_FAILURE =
  'FETCH_MORTGAGE_BROKER_ACCOUNTS_FAILURE'

export const ADD_MORTGAGE_APPLICATION_REQUEST =
  'ADD_MORTGAGE_APPLICATION_REQUEST'
export const ADD_MORTGAGE_APPLICATION_SUCCESS =
  'ADD_MORTGAGE_APPLICATION_SUCCESS'
export const ADD_MORTGAGE_APPLICATION_FAILURE =
  'ADD_MORTGAGE_APPLICATION_FAILURE'

export const FETCH_MORTGAGE_APPLICATION_REQUEST =
  'FETCH_MORTGAGE_APPLICATION_REQUEST'
export const FETCH_MORTGAGE_APPLICATION_SUCCESS =
  'FETCH_MORTGAGE_APPLICATION_SUCCESS'
export const FETCH_MORTGAGE_APPLICATION_FAILURE =
  'FETCH_MORTGAGE_APPLICATION_FAILURE'

export const ADD_EXISTING_MORTGAGE_DOCUMENT_REQUEST =
  'ADD_EXISTING_MORTGAGE_DOCUMENT_REQUEST'
export const ADD_EXISTING_MORTGAGE_DOCUMENT_SUCCESS =
  'ADD_EXISTING_MORTGAGE_DOCUMENT_SUCCESS'
export const ADD_EXISTING_MORTGAGE_DOCUMENT_FAILURE =
  'ADD_EXISTING_MORTGAGE_DOCUMENT_FAILURE'

export const DELETE_EXISTING_MORTGAGE_DOCUMENT_REQUEST =
  'DELETE_EXISTING_MORTGAGE_DOCUMENT_REQUEST'
export const DELETE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS =
  'DELETE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS'
export const DELETE_EXISTING_MORTGAGE_DOCUMENT_FAILURE =
  'DELETE_EXISTING_MORTGAGE_DOCUMENT_FAILURE'

export const FETCH_EXISTING_MORTGAGE_DOCUMENTS_REQUEST =
  'FETCH_EXISTING_MORTGAGE_DOCUMENTS_REQUEST'
export const FETCH_EXISTING_MORTGAGE_DOCUMENTS_SUCCESS =
  'FETCH_EXISTING_MORTGAGE_DOCUMENTS_SUCCESS'
export const FETCH_EXISTING_MORTGAGE_DOCUMENTS_FAILURE =
  'FETCH_EXISTING_MORTGAGE_DOCUMENTS_FAILURE'

export const UPDATE_EXISTING_MORTGAGE_DOCUMENT_REQUEST =
  'UPDATE_EXISTING_MORTGAGE_DOCUMENT_REQUEST'
export const UPDATE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS =
  'UPDATE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS'
export const UPDATE_EXISTING_MORTGAGE_DOCUMENT_FAILURE =
  'UPDATE_EXISTING_MORTGAGE_DOCUMENT_FAILURE'

export const UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_REQUEST =
  'UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_REQUEST'
export const UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_SUCCESS =
  'UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_FAILURE =
  'UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_FAILURE'

export const ADD_ACCOUNT_USER_DOCUMENT_REQUEST =
  'ADD_ACCOUNT_USER_DOCUMENT_REQUEST'
export const ADD_ACCOUNT_USER_DOCUMENT_SUCCESS =
  'ADD_ACCOUNT_USER_DOCUMENT_SUCCESS'
export const ADD_ACCOUNT_USER_DOCUMENT_FAILURE =
  'ADD_ACCOUNT_USER_DOCUMENT_FAILURE'

export const ARCHIVE_ACCOUNT_USER_DOCUMENT_REQUEST =
  'ARCHIVE_ACCOUNT_USER_DOCUMENT_REQUEST'
export const ARCHIVE_ACCOUNT_USER_DOCUMENT_SUCCESS =
  'ARCHIVE_ACCOUNT_USER_DOCUMENT_SUCCESS'
export const ARCHIVE_ACCOUNT_USER_DOCUMENT_FAILURE =
  'ARCHIVE_ACCOUNT_USER_DOCUMENT_FAILURE'

export const UPDATE_ACCOUNT_USER_DOCUMENT_REQUEST =
  'UPDATE_ACCOUNT_USER_DOCUMENT_REQUEST'
export const UPDATE_ACCOUNT_USER_DOCUMENT_SUCCESS =
  'UPDATE_ACCOUNT_USER_DOCUMENT_SUCCESS'
export const UPDATE_ACCOUNT_USER_DOCUMENT_FAILURE =
  'UPDATE_ACCOUNT_USER_DOCUMENT_FAILURE'

export const UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_REQUEST =
  'UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_REQUEST'
export const UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_SUCCESS =
  'UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_FAILURE =
  'UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_FAILURE'

export const ADD_COMPANY_DOCUMENT_REQUEST = 'ADD_COMPANY_DOCUMENT_REQUEST'
export const ADD_COMPANY_DOCUMENT_SUCCESS = 'ADD_COMPANY_DOCUMENT_SUCCESS'
export const ADD_COMPANY_DOCUMENT_FAILURE = 'ADD_COMPANY_DOCUMENT_FAILURE'

export const ARCHIVE_COMPANY_DOCUMENT_REQUEST = 'ARCHIVE_COMPANY_DOCUMENT_REQUEST'
export const ARCHIVE_COMPANY_DOCUMENT_SUCCESS = 'ARCHIVE_COMPANY_DOCUMENT_SUCCESS'
export const ARCHIVE_COMPANY_DOCUMENT_FAILURE = 'ARCHIVE_COMPANY_DOCUMENT_FAILURE'

export const UPDATE_COMPANY_DOCUMENT_REQUEST = 'UPDATE_COMPANY_DOCUMENT_REQUEST'
export const UPDATE_COMPANY_DOCUMENT_SUCCESS = 'UPDATE_COMPANY_DOCUMENT_SUCCESS'
export const UPDATE_COMPANY_DOCUMENT_FAILURE = 'UPDATE_COMPANY_DOCUMENT_FAILURE'

export const UPLOAD_COMPANY_DOCUMENT_FILE_REQUEST =
  'UPLOAD_COMPANY_DOCUMENT_FILE_REQUEST'
export const UPLOAD_COMPANY_DOCUMENT_FILE_SUCCESS =
  'UPLOAD_COMPANY_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_COMPANY_DOCUMENT_FILE_FAILURE =
  'UPLOAD_COMPANY_DOCUMENT_FILE_FAILURE'

export const ADD_CHECKOUT_SESSION_REQUEST = 'ADD_CHECKOUT_SESSION_REQUEST'
export const ADD_CHECKOUT_SESSION_SUCCESS = 'ADD_CHECKOUT_SESSION_SUCCESS'
export const ADD_CHECKOUT_SESSION_FAILURE = 'ADD_CHECKOUT_SESSION_FAILURE'

export const MANAGE_SUBSCRIPTION_REQUEST = 'MANAGE_SUBSCRIPTION_REQUEST'
export const MANAGE_SUBSCRIPTION_SUCCESS = 'MANAGE_SUBSCRIPTION_SUCCESS'
export const MANAGE_SUBSCRIPTION_FAILURE = 'MANAGE_SUBSCRIPTION_FAILURE'

export const FETCH_OWNED_PROPERTY_ACCOUNTS_REQUEST =
  'FETCH_OWNED_PROPERTY_ACCOUNTS_REQUEST'
export const FETCH_OWNED_PROPERTY_ACCOUNTS_SUCCESS =
  'FETCH_OWNED_PROPERTY_ACCOUNTS_SUCCESS'
export const FETCH_OWNED_PROPERTY_ACCOUNTS_FAILURE =
  'FETCH_OWNED_PROPERTY_ACCOUNTS_FAILURE'

export const ADD_OWNED_PROPERTY_ACCOUNT_REQUEST =
  'ADD_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const ADD_OWNED_PROPERTY_ACCOUNT_SUCCESS =
  'ADD_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const ADD_OWNED_PROPERTY_ACCOUNT_FAILURE =
  'ADD_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST =
  'UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS =
  'UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE =
  'UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST =
  'DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS =
  'DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE =
  'DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const FETCH_APPLIANCES_REQUEST = 'FETCH_APPLIANCESS_REQUEST'
export const FETCH_APPLIANCES_SUCCESS = 'FETCH_APPLIANCESS_SUCCESS'
export const FETCH_APPLIANCES_FAILURE = 'FETCH_APPLIANCESS_FAILURE'

export const ADD_APPLIANCE_REQUEST = 'ADD_APPLIANCE_REQUEST'
export const ADD_APPLIANCE_SUCCESS = 'ADD_APPLIANCE_SUCCESS'
export const ADD_APPLIANCE_FAILURE = 'ADD_APPLIANCE_FAILURE'

export const UPDATE_APPLIANCE_REQUEST = 'UPDATE_APPLIANCE_REQUEST'
export const UPDATE_APPLIANCE_SUCCESS = 'UPDATE_APPLIANCE_SUCCESS'
export const UPDATE_APPLIANCE_FAILURE = 'UPDATE_APPLIANCE_FAILURE'

export const DELETE_APPLIANCE_REQUEST = 'DELETE_APPLIANCE_REQUEST'
export const DELETE_APPLIANCE_SUCCESS = 'DELETE_APPLIANCE_SUCCESS'
export const DELETE_APPLIANCE_FAILURE = 'DELETE_APPLIANCE_FAILURE'

export const ADD_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'ADD_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const ADD_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'ADD_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const ADD_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'ADD_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST = 'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS = 'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE = 'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_FAILURE'

export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_REQUEST =
  'UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_REQUEST'
export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_SUCCESS =
  'UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_SUCCESS'
export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_FAILURE =
  'UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_FAILURE'

export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE =
  'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST =
  'RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST'
export const RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS =
  'RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS'
export const RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE =
  'RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE'

export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST = 'UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS = 'UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE = 'UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const ARCHIVE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST = 'ARCHIVE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const ARCHIVE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS = 'ARCHIVE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const ARCHIVE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE = 'ARCHIVE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'DUPLICATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_REQUEST =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_REQUEST'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_SUCCESS =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_SUCCESS'
export const FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_FAILURE =
  'FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_FAILURE'

export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE =
  'UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const FETCH_NEW_PROPERTIES_REQUEST = 'FETCH_NEW_PROPERTIES_REQUEST'
export const FETCH_NEW_PROPERTIES_SUCCESS = 'FETCH_NEW_PROPERTIES_SUCCESS'
export const FETCH_NEW_PROPERTIES_FAILURE = 'FETCH_NEW_PROPERTIES_FAILURE'

export const ADD_NEW_SINGLE_LET_PROPERTY_REQUEST =
  'ADD_NEW_SINGLE_LET_PROPERTY_REQUEST'
export const ADD_NEW_SINGLE_LET_PROPERTY_SUCCESS =
  'ADD_NEW_SINGLE_LET_PROPERTY_SUCCESS'
export const ADD_NEW_SINGLE_LET_PROPERTY_FAILURE =
  'ADD_NEW_SINGLE_LET_PROPERTY_FAILURE'

export const UPDATE_NEW_SINGLE_LET_PROPERTY_REQUEST =
  'UPDATE_NEW_SINGLE_LET_PROPERTY_REQUEST'
export const UPDATE_NEW_SINGLE_LET_PROPERTY_SUCCESS =
  'UPDATE_NEW_SINGLE_LET_PROPERTY_SUCCESS'
export const UPDATE_NEW_SINGLE_LET_PROPERTY_FAILURE =
  'UPDATE_NEW_SINGLE_LET_PROPERTY_FAILURE'

export const DELETE_NEW_SINGLE_LET_PROPERTY_REQUEST =
  'DELETE_NEW_SINGLE_LET_PROPERTY_REQUEST'
export const DELETE_NEW_SINGLE_LET_PROPERTY_SUCCESS =
  'DELETE_NEW_SINGLE_LET_PROPERTY_SUCCESS'
export const DELETE_NEW_SINGLE_LET_PROPERTY_FAILURE =
  'DELETE_NEW_SINGLE_LET_PROPERTY_FAILURE'

export const ADD_NEW_HMO_PROPERTY_REQUEST =
  'ADD_NEW_HMO_PROPERTY_REQUEST'
export const ADD_NEW_HMO_PROPERTY_SUCCESS =
  'ADD_NEW_HMO_PROPERTY_SUCCESS'
export const ADD_NEW_HMO_PROPERTY_FAILURE =
  'ADD_NEW_HMO_PROPERTY_FAILURE'

export const UPDATE_NEW_HMO_PROPERTY_REQUEST =
  'UPDATE_NEW_HMO_PROPERTY_REQUEST'
export const UPDATE_NEW_HMO_PROPERTY_SUCCESS =
  'UPDATE_NEW_HMO_PROPERTY_SUCCESS'
export const UPDATE_NEW_HMO_PROPERTY_FAILURE =
  'UPDATE_NEW_HMO_PROPERTY_FAILURE'

export const DELETE_NEW_HMO_PROPERTY_REQUEST =
  'DELETE_NEW_HMO_PROPERTY_REQUEST'
export const DELETE_NEW_HMO_PROPERTY_SUCCESS =
  'DELETE_NEW_HMO_PROPERTY_SUCCESS'
export const DELETE_NEW_HMO_PROPERTY_FAILURE =
  'DELETE_NEW_HMO_PROPERTY_FAILURE'

export const ADD_NEW_FLIP_PROPERTY_REQUEST =
  'ADD_NEW_FLIP_PROPERTY_REQUEST'
export const ADD_NEW_FLIP_PROPERTY_SUCCESS =
  'ADD_NEW_FLIP_PROPERTY_SUCCESS'
export const ADD_NEW_FLIP_PROPERTY_FAILURE =
  'ADD_NEW_FLIP_PROPERTY_FAILURE'

export const UPDATE_NEW_FLIP_PROPERTY_REQUEST =
  'UPDATE_NEW_FLIP_PROPERTY_REQUEST'
export const UPDATE_NEW_FLIP_PROPERTY_SUCCESS =
  'UPDATE_NEW_FLIP_PROPERTY_SUCCESS'
export const UPDATE_NEW_FLIP_PROPERTY_FAILURE =
  'UPDATE_NEW_FLIP_PROPERTY_FAILURE'

export const DELETE_NEW_FLIP_PROPERTY_REQUEST =
  'DELETE_NEW_FLIP_PROPERTY_REQUEST'
export const DELETE_NEW_FLIP_PROPERTY_SUCCESS =
  'DELETE_NEW_FLIP_PROPERTY_SUCCESS'
export const DELETE_NEW_FLIP_PROPERTY_FAILURE =
  'DELETE_NEW_FLIP_PROPERTY_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_VIDEOS_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_VIDEOS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_VIDEOS_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_VIDEOS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_VIDEOS_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_VIDEOS_FAILURE'

export const ADD_PROPERTY_VIDEO_REQUEST = 'ADD_PROPERTY_VIDEO_REQUEST'
export const ADD_PROPERTY_VIDEO_SUCCESS = 'ADD_PROPERTY_VIDEO_SUCCESS'
export const ADD_PROPERTY_VIDEO_FAILURE = 'ADD_PROPERTY_VIDEO_FAILURE'

export const UPDATE_PROPERTY_VIDEO_REQUEST = 'UPDATE_PROPERTY_VIDEO_REQUEST'
export const UPDATE_PROPERTY_VIDEO_SUCCESS = 'UPDATE_PROPERTY_VIDEO_SUCCESS'
export const UPDATE_PROPERTY_VIDEO_FAILURE = 'UPDATE_PROPERTY_VIDEO_FAILURE'

export const DELETE_PROPERTY_VIDEO_REQUEST = 'DELETE_PROPERTY_VIDEO_REQUEST'
export const DELETE_PROPERTY_VIDEO_SUCCESS = 'DELETE_PROPERTY_VIDEO_SUCCESS'
export const DELETE_PROPERTY_VIDEO_FAILURE = 'DELETE_PROPERTY_VIDEO_FAILURE'

export const FETCH_GOALS_REQUEST = 'FETCH_GOALS_REQUEST'
export const FETCH_GOALS_SUCCESS = 'FETCH_GOALS_SUCCESS'
export const FETCH_GOALS_FAILURE = 'FETCH_GOALS_FAILURE'

export const ADD_GOAL_REQUEST = 'ADD_GOAL_REQUEST'
export const ADD_GOAL_SUCCESS = 'ADD_GOAL_SUCCESS'
export const ADD_GOAL_FAILURE = 'ADD_GOAL_FAILURE'

export const UPDATE_GOAL_REQUEST = 'UPDATE_GOAL_REQUEST'
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS'
export const UPDATE_GOAL_FAILURE = 'UPDATE_GOAL_FAILURE'

export const DELETE_GOAL_REQUEST = 'DELETE_GOAL_REQUEST'
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS'
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE'

export const COMPLETE_GOAL_REQUEST = 'COMPLETE_GOAL_REQUEST'
export const COMPLETE_GOAL_SUCCESS = 'COMPLETE_GOAL_SUCCESS'
export const COMPLETE_GOAL_FAILURE = 'COMPLETE_GOAL_FAILURE'

export const FETCH_CALENDAR_EVENTS_REQUEST = 'FETCH_CALENDAR_EVENTS_REQUEST'
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS'
export const FETCH_CALENDAR_EVENTS_FAILURE = 'FETCH_CALENDAR_EVENTS_FAILURE'

export const FETCH_CERTIFICATES_REQUEST = 'FETCH_CERTIFICATES_REQUEST'
export const FETCH_CERTIFICATES_SUCCESS = 'FETCH_CERTIFICATES_SUCCESS'
export const FETCH_CERTIFICATES_FAILURE = 'FETCH_CERTIFICATES_FAILURE'

export const ADD_CERTIFICATE_REQUEST = 'ADD_CERTIFICATE_REQUEST'
export const ADD_CERTIFICATE_SUCCESS = 'ADD_CERTIFICATE_SUCCESS'
export const ADD_CERTIFICATE_FAILURE = 'ADD_CERTIFICATE_FAILURE'

export const UPDATE_CERTIFICATE_REQUEST = 'UPDATE_CERTIFICATE_REQUEST'
export const UPDATE_CERTIFICATE_SUCCESS = 'UPDATE_CERTIFICATE_SUCCESS'
export const UPDATE_CERTIFICATE_FAILURE = 'UPDATE_CERTIFICATE_FAILURE'

export const ARCHIVE_CERTIFICATE_REQUEST = 'ARCHIVE_CERTIFICATE_REQUEST'
export const ARCHIVE_CERTIFICATE_SUCCESS = 'ARCHIVE_CERTIFICATE_SUCCESS'
export const ARCHIVE_CERTIFICATE_FAILURE = 'ARCHIVE_CERTIFICATE_FAILURE'

export const UPLOAD_CERTIFICATE_DOCUMENT_FILE_REQUEST = 'UPLOAD_CERTIFICATE_DOCUMENT_FILE_REQUEST'
export const UPLOAD_CERTIFICATE_DOCUMENT_FILE_SUCCESS = 'UPLOAD_CERTIFICATE_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_CERTIFICATE_DOCUMENT_FILE_FAILURE = 'UPLOAD_CERTIFICATE_DOCUMENT_FILE_FAILURE'

export const FETCH_LICENCES_REQUEST = 'FETCH_LICENCES_REQUEST'
export const FETCH_LICENCES_SUCCESS = 'FETCH_LICENCES_SUCCESS'
export const FETCH_LICENCES_FAILURE = 'FETCH_LICENCES_FAILURE'

export const ADD_LICENCE_REQUEST = 'ADD_LICENCE_REQUEST'
export const ADD_LICENCE_SUCCESS = 'ADD_LICENCE_SUCCESS'
export const ADD_LICENCE_FAILURE = 'ADD_LICENCE_FAILURE'

export const UPDATE_LICENCE_REQUEST = 'UPDATE_LICENCE_REQUEST'
export const UPDATE_LICENCE_SUCCESS = 'UPDATE_LICENCE_SUCCESS'
export const UPDATE_LICENCE_FAILURE = 'UPDATE_LICENCE_FAILURE'

export const ARCHIVE_LICENCE_REQUEST = 'ARCHIVE_LICENCE_REQUEST'
export const ARCHIVE_LICENCE_SUCCESS = 'ARCHIVE_LICENCE_SUCCESS'
export const ARCHIVE_LICENCE_FAILURE = 'ARCHIVE_LICENCE_FAILURE'

export const FETCH_INSURANCE_POLICIES_REQUEST = 'FETCH_INSURANCE_POLICIES_REQUEST'
export const FETCH_INSURANCE_POLICIES_SUCCESS = 'FETCH_INSURANCE_POLICIES_SUCCESS'
export const FETCH_INSURANCE_POLICIES_FAILURE = 'FETCH_INSURANCE_POLICIES_FAILURE'

export const ADD_INSURANCE_POLICY_REQUEST = 'ADD_INSURANCE_POLICY_REQUEST'
export const ADD_INSURANCE_POLICY_SUCCESS = 'ADD_INSURANCE_POLICY_SUCCESS'
export const ADD_INSURANCE_POLICY_FAILURE = 'ADD_INSURANCE_POLICY_FAILURE'

export const UPDATE_INSURANCE_POLICY_REQUEST = 'UPDATE_INSURANCE_POLICY_REQUEST'
export const UPDATE_INSURANCE_POLICY_SUCCESS = 'UPDATE_INSURANCE_POLICY_SUCCESS'
export const UPDATE_INSURANCE_POLICY_FAILURE = 'UPDATE_INSURANCE_POLICY_FAILURE'

export const ARCHIVE_INSURANCE_POLICY_REQUEST = 'ARCHIVE_INSURANCE_POLICY_REQUEST'
export const ARCHIVE_INSURANCE_POLICY_SUCCESS = 'ARCHIVE_INSURANCE_POLICY_SUCCESS'
export const ARCHIVE_INSURANCE_POLICY_FAILURE = 'ARCHIVE_INSURANCE_POLICY_FAILURE'

export const FETCH_SMOKE_ALARM_TESTS_REQUEST = 'FETCH_SMOKE_ALARM_TESTS_REQUEST'
export const FETCH_SMOKE_ALARM_TESTS_SUCCESS = 'FETCH_SMOKE_ALARM_TESTS_SUCCESS'
export const FETCH_SMOKE_ALARM_TESTS_FAILURE = 'FETCH_SMOKE_ALARM_TESTS_FAILURE'

export const ADD_SMOKE_ALARM_TEST_REQUEST = 'ADD_SMOKE_ALARM_TEST_REQUEST'
export const ADD_SMOKE_ALARM_TEST_SUCCESS = 'ADD_SMOKE_ALARM_TEST_SUCCESS'
export const ADD_SMOKE_ALARM_TEST_FAILURE = 'ADD_SMOKE_ALARM_TEST_FAILURE'

export const UPDATE_SMOKE_ALARM_TEST_REQUEST = 'UPDATE_SMOKE_ALARM_TEST_REQUEST'
export const UPDATE_SMOKE_ALARM_TEST_SUCCESS = 'UPDATE_SMOKE_ALARM_TEST_SUCCESS'
export const UPDATE_SMOKE_ALARM_TEST_FAILURE = 'UPDATE_SMOKE_ALARM_TEST_FAILURE'

export const ARCHIVE_SMOKE_ALARM_TEST_REQUEST = 'ARCHIVE_SMOKE_ALARM_TEST_REQUEST'
export const ARCHIVE_SMOKE_ALARM_TEST_SUCCESS = 'ARCHIVE_SMOKE_ALARM_TEST_SUCCESS'
export const ARCHIVE_SMOKE_ALARM_TEST_FAILURE = 'ARCHIVE_SMOKE_ALARM_TEST_FAILURE'

export const ADD_INVOICE_REQUEST = 'ADD_INVOICE_REQUEST'
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS'
export const ADD_INVOICE_FAILURE = 'ADD_INVOICE_FAILURE'

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE'

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST'
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS'
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE'

export const FETCH_TENANCY_RENT_STATEMENT_ROWS_REQUEST = 'FETCH_TENANCY_RENT_STATEMENT_ROWS_REQUEST'
export const FETCH_TENANCY_RENT_STATEMENT_ROWS_SUCCESS = 'FETCH_TENANCY_RENT_STATEMENT_ROWS_SUCCESS'
export const FETCH_TENANCY_RENT_STATEMENT_ROWS_FAILURE = 'FETCH_TENANCY_RENT_STATEMENT_ROWS_FAILURE'

export const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST'
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS'
export const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_FAILURE'

export const UPDATE_TRANSACTION_REQUEST = 'UPDATE_TRANSACTION_REQUEST'
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS'
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE'

export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST'
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS'
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE'

export const UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST'
export const UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS'
export const UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE'

export const UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_REQUEST = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_REQUEST'
export const UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_SUCCESS = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_SUCCESS'
export const UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_FAILURE = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_FAILURE'

export const SET_STATUS_BANNER = 'SET_STATUS_BANNER'

export const FETCH_SUBSCRIPTION_PRODUCTS_REQUEST = 'FETCH_SUBSCRIPTION_PRODUCTS_REQUEST'
export const FETCH_SUBSCRIPTION_PRODUCTS_SUCCESS = 'FETCH_SUBSCRIPTION_PRODUCTS_SUCCESS'
export const FETCH_SUBSCRIPTION_PRODUCTS_FAILURE = 'FETCH_SUBSCRIPTION_PRODUCTS_FAILURE'
export const SET_CHECKLIST_STATUS = 'SET_CHECKLIST_STATUS'
export const TOGGLE_CHECKLIST_STATUS = 'TOGGLE_CHECKLIST_STATUS'

export const FETCH_OWNED_PROPERTY_DEALS_REQUEST = 'FETCH_OWNED_PROPERTY_DEALS_REQUEST'
export const FETCH_OWNED_PROPERTY_DEALS_SUCCESS = 'FETCH_OWNED_PROPERTY_DEALS_SUCCESS'
export const FETCH_OWNED_PROPERTY_DEALS_FAILURE = 'FETCH_OWNED_PROPERTY_DEALS_FAILURE'

export const FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST = 'FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST'
export const FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS = 'FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS'
export const FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE = 'FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE'

export const ADD_REMORTGAGE_DEAL_REQUEST = 'ADD_REMORTGAGE_DEAL_REQUEST'
export const ADD_REMORTGAGE_DEAL_SUCCESS = 'ADD_REMORTGAGE_DEAL_SUCCESS'
export const ADD_REMORTGAGE_DEAL_FAILURE = 'ADD_REMORTGAGE_DEAL_FAILURE'

export const UPDATE_REMORTGAGE_DEAL_REQUEST = 'UPDATE_REMORTGAGE_DEAL_REQUEST'
export const UPDATE_REMORTGAGE_DEAL_SUCCESS = 'UPDATE_REMORTGAGE_DEAL_SUCCESS'
export const UPDATE_REMORTGAGE_DEAL_FAILURE = 'UPDATE_REMORTGAGE_DEAL_FAILURE'

export const ARCHIVE_REMORTGAGE_DEAL_REQUEST = 'ARCHIVE_REMORTGAGE_DEAL_REQUEST'
export const ARCHIVE_REMORTGAGE_DEAL_SUCCESS = 'ARCHIVE_REMORTGAGE_DEAL_SUCCESS'
export const ARCHIVE_REMORTGAGE_DEAL_FAILURE = 'ARCHIVE_REMORTGAGE_DEAL_FAILURE'

export const FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST = 'FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST'
export const FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS = 'FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS'
export const FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE = 'FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE'

export const FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST = 'FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST'
export const FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS = 'FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS'
export const FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE = 'FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE'

export const CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST = 'CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST'
export const CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS = 'CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS'
export const CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE = 'CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE'

export const UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST'
export const UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS'
export const UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE = 'UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE'

export const DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST = 'DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST'
export const DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS = 'DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS'
export const DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE = 'DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE'

export const FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_REQUEST = 'FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_REQUEST'
export const FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_SUCCESS = 'FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_SUCCESS'
export const FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_FAILURE = 'FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_FAILURE'

export const ADD_CALENDAR_EVENT_REQUEST = 'ADD_CALENDAR_EVENT_REQUEST'
export const ADD_CALENDAR_EVENT_SUCCESS = 'ADD_CALENDAR_EVENT_SUCCESS'
export const ADD_CALENDAR_EVENT_FAILURE = 'ADD_CALENDAR_EVENT_FAILURE'

export const UPDATE_CALENDAR_EVENT_REQUEST = 'UPDATE_CALENDAR_EVENT_REQUEST'
export const UPDATE_CALENDAR_EVENT_SUCCESS = 'UPDATE_CALENDAR_EVENT_SUCCESS'
export const UPDATE_CALENDAR_EVENT_FAILURE = 'UPDATE_CALENDAR_EVENT_FAILURE'

export const DELETE_CALENDAR_EVENT_REQUEST = 'DELETE_CALENDAR_EVENT_REQUEST'
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS'
export const DELETE_CALENDAR_EVENT_FAILURE = 'DELETE_CALENDAR_EVENT_FAILURE'

export const ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST = 'ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST'
export const ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS = 'ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS'
export const ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE = 'ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE'

export const UPDATE_REMINDER_FOR_CALENDAR_EVENT_REQUEST = 'UPDATE_REMINDER_FOR_CALENDAR_EVENT_REQUEST'
export const UPDATE_REMINDER_FOR_CALENDAR_EVENT_SUCCESS = 'UPDATE_REMINDER_FOR_CALENDAR_EVENT_SUCCESS'
export const UPDATE_REMINDER_FOR_CALENDAR_EVENT_FAILURE = 'UPDATE_REMINDER_FOR_CALENDAR_EVENT_FAILURE'

export const DELETE_REMINDER_FROM_CALENDAR_EVENT_REQUEST = 'DELETE_REMINDER_FROM_CALENDAR_EVENT_REQUEST'
export const DELETE_REMINDER_FROM_CALENDAR_EVENT_SUCCESS = 'DELETE_REMINDER_FROM_CALENDAR_EVENT_SUCCESS'
export const DELETE_REMINDER_FROM_CALENDAR_EVENT_FAILURE = 'DELETE_REMINDER_FROM_CALENDAR_EVENT_FAILURE'

export const FETCH_PROPERTY_PRICE_PAID_RECORDS_REQUEST = 'FETCH_PROPERTY_PRICE_PAID_RECORDS_REQUEST'
export const FETCH_PROPERTY_PRICE_PAID_RECORDS_SUCCESS = 'FETCH_PROPERTY_PRICE_PAID_RECORDS_SUCCESS'
export const FETCH_PROPERTY_PRICE_PAID_RECORDS_FAILURE = 'FETCH_PROPERTY_PRICE_PAID_RECORDS_FAILURE'

export const ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_REQUEST = 'ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_REQUEST'
export const ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_SUCCESS = 'ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_SUCCESS'
export const ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_FAILURE = 'ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_FAILURE'

export const FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_REQUEST = 'FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_REQUEST'
export const FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_SUCCESS = 'FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_SUCCESS'
export const FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_FAILURE = 'FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_FAILURE'

export const UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_REQUEST = 'UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_REQUEST'
export const UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_SUCCESS'
export const UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_FAILURE = 'UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_FAILURE'

export const FETCH_PROPERTY_INVESTMENT_USER_PROFILE_REQUEST = 'FETCH_PROPERTY_INVESTMENT_USER_PROFILE_REQUEST'
export const FETCH_PROPERTY_INVESTMENT_USER_PROFILE_SUCCESS = 'FETCH_PROPERTY_INVESTMENT_USER_PROFILE_SUCCESS'
export const FETCH_PROPERTY_INVESTMENT_USER_PROFILE_FAILURE = 'FETCH_PROPERTY_INVESTMENT_USER_PROFILE_FAILURE'

export const UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_REQUEST = 'UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_REQUEST'
export const UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_SUCCESS'
export const UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_FAILURE = 'UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_FAILURE'

export const FETCH_MORTGAGE_BROKER_USER_PROFILE_REQUEST = 'FETCH_MORTGAGE_BROKER_USER_PROFILE_REQUEST'
export const FETCH_MORTGAGE_BROKER_USER_PROFILE_SUCCESS = 'FETCH_MORTGAGE_BROKER_USER_PROFILE_SUCCESS'
export const FETCH_MORTGAGE_BROKER_USER_PROFILE_FAILURE = 'FETCH_MORTGAGE_BROKER_USER_PROFILE_FAILURE'

export const UPDATE_MORTGAGE_BROKER_USER_PROFILE_REQUEST = 'UPDATE_MORTGAGE_BROKER_USER_PROFILE_REQUEST'
export const UPDATE_MORTGAGE_BROKER_USER_PROFILE_SUCCESS = 'UPDATE_MORTGAGE_BROKER_USER_PROFILE_SUCCESS'
export const UPDATE_MORTGAGE_BROKER_USER_PROFILE_FAILURE = 'UPDATE_MORTGAGE_BROKER_USER_PROFILE_FAILURE'

export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FOR_SALE_COMPARABLE_REQUEST =
  'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FOR_SALE_COMPARABLE_REQUEST'
export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FOR_SALE_COMPARABLE_SUCCESS =
  'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FOR_SALE_COMPARABLE_SUCCESS'
export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FOR_SALE_COMPARABLE_FAILURE =
  'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FOR_SALE_COMPARABLE_FAILURE'

export const FETCH_PROMOTION_CODE_REQUEST = 'FETCH_PROMOTION_CODE_REQUEST'
export const FETCH_PROMOTION_CODE_SUCCESS = 'FETCH_PROMOTION_CODE_SUCCESS'
export const FETCH_PROMOTION_CODE_FAILURE = 'FETCH_PROMOTION_CODE_FAILURE'

export const FETCH_OWNED_PROPERTY_WORKFLOWS_REQUEST = 'FETCH_OWNED_PROPERTY_WORKFLOWS_REQUEST'
export const FETCH_OWNED_PROPERTY_WORKFLOWS_SUCCESS = 'FETCH_OWNED_PROPERTY_WORKFLOWS_SUCCESS'
export const FETCH_OWNED_PROPERTY_WORKFLOWS_FAILURE = 'FETCH_OWNED_PROPERTY_WORKFLOWS_FAILURE'

export const ADD_WORKFLOW_TO_OWNED_PROPERTY_REQUEST = 'ADD_WORKFLOW_TO_OWNED_PROPERTY_REQUEST'
export const ADD_WORKFLOW_TO_OWNED_PROPERTY_SUCCESS = 'ADD_WORKFLOW_TO_OWNED_PROPERTY_SUCCESS'
export const ADD_WORKFLOW_TO_OWNED_PROPERTY_FAILURE = 'ADD_WORKFLOW_TO_OWNED_PROPERTY_FAILURE'

export const FETCH_TENANCY_WORKFLOWS_REQUEST = 'FETCH_TENANCY_WORKFLOWS_REQUEST'
export const FETCH_TENANCY_WORKFLOWS_SUCCESS = 'FETCH_TENANCY_WORKFLOWS_SUCCESS'
export const FETCH_TENANCY_WORKFLOWS_FAILURE = 'FETCH_TENANCY_WORKFLOWS_FAILURE'

export const ADD_WORKFLOW_TO_TENANCY_REQUEST = 'ADD_WORKFLOW_TO_TENANCY_REQUEST'
export const ADD_WORKFLOW_TO_TENANCY_SUCCESS = 'ADD_WORKFLOW_TO_TENANCY_SUCCESS'
export const ADD_WORKFLOW_TO_TENANCY_FAILURE = 'ADD_WORKFLOW_TO_TENANCY_FAILURE'

export const FETCH_TENANCY_PORTFOLIO_REPORT_REQUEST = 'FETCH_TENANCY_PORTFOLIO_REPORT_REQUEST'
export const FETCH_TENANCY_PORTFOLIO_REPORT_SUCCESS = 'FETCH_TENANCY_PORTFOLIO_REPORT_SUCCESS'
export const FETCH_TENANCY_PORTFOLIO_REPORT_FAILURE = 'FETCH_TENANCY_PORTFOLIO_REPORT_FAILURE'

export const FETCH_WORKFLOW_INVITATIONS_REQUEST = 'FETCH_WORKFLOW_INVITATIONS_REQUEST'
export const FETCH_WORKFLOW_INVITATIONS_SUCCESS = 'FETCH_WORKFLOW_INVITATIONS_SUCCESS'
export const FETCH_WORKFLOW_INVITATIONS_FAILURE = 'FETCH_WORKFLOW_INVITATIONS_FAILURE'

export const ADD_WORKFLOW_INVITATION_REQUEST = 'ADD_WORKFLOW_INVITATION_REQUEST'
export const ADD_WORKFLOW_INVITATION_SUCCESS = 'ADD_WORKFLOW_INVITATION_SUCCESS'
export const ADD_WORKFLOW_INVITATION_FAILURE = 'ADD_WORKFLOW_INVITATION_FAILURE'

export const DELETE_WORKFLOW_INVITATION_REQUEST = 'DELETE_WORKFLOW_INVITATION_REQUEST'
export const DELETE_WORKFLOW_INVITATION_SUCCESS = 'DELETE_WORKFLOW_INVITATION_SUCCESS'
export const DELETE_WORKFLOW_INVITATION_FAILURE = 'DELETE_WORKFLOW_INVITATION_FAILURE'

export const ARCHIVE_WORKFLOW_REQUEST = 'ARCHIVE_WORKFLOW_REQUEST'
export const ARCHIVE_WORKFLOW_SUCCESS = 'ARCHIVE_WORKFLOW_SUCCESS'
export const ARCHIVE_WORKFLOW_FAILURE = 'ARCHIVE_WORKFLOW_FAILURE'

export const FETCH_CERTIFICATE_PORTFOLIO_REPORT_REQUEST = 'FETCH_CERTIFICATE_PORTFOLIO_REPORT_REQUEST'
export const FETCH_CERTIFICATE_PORTFOLIO_REPORT_SUCCESS = 'FETCH_CERTIFICATE_PORTFOLIO_REPORT_SUCCESS'
export const FETCH_CERTIFICATE_PORTFOLIO_REPORT_FAILURE = 'FETCH_CERTIFICATE_PORTFOLIO_REPORT_FAILURE'

export const UPDATE_WORKFLOW_REQUEST = 'UPDATE_WORKFLOW_REQUEST'
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS'
export const UPDATE_WORKFLOW_FAILURE = 'UPDATE_WORKFLOW_FAILURE'

export const FETCH_OWNED_PROPERTY_ACCOUNT_PORTFOLIO_REPORT_REQUEST = 'FETCH_OWNED_PROPERTY_ACCOUNT_PORTFOLIO_REPORT_REQUEST'
export const FETCH_OWNED_PROPERTY_ACCOUNT_PORTFOLIO_REPORT_SUCCESS = 'FETCH_OWNED_PROPERTY_ACCOUNT_PORTFOLIO_REPORT_SUCCESS'
export const FETCH_OWNED_PROPERTY_ACCOUNT_PORTFOLIO_REPORT_FAILURE = 'FETCH_OWNED_PROPERTY_ACCOUNT_PORTFOLIO_REPORT_FAILURE'

export const ADD_MORTGAGE_APPLICATION_DEAL_REQUEST = 'ADD_MORTGAGE_APPLICATION_DEAL_REQUEST'
export const ADD_MORTGAGE_APPLICATION_DEAL_SUCCESS = 'ADD_MORTGAGE_APPLICATION_DEAL_SUCCESS'
export const ADD_MORTGAGE_APPLICATION_DEAL_FAILURE = 'ADD_MORTGAGE_APPLICATION_DEAL_FAILURE'

export const DELETE_MORTGAGE_APPLICATION_DEAL_REQUEST = 'DELETE_MORTGAGE_APPLICATION_DEAL_REQUEST'
export const DELETE_MORTGAGE_APPLICATION_DEAL_SUCCESS = 'DELETE_MORTGAGE_APPLICATION_DEAL_SUCCESS'
export const DELETE_MORTGAGE_APPLICATION_DEAL_FAILURE = 'DELETE_MORTGAGE_APPLICATION_DEAL_FAILURE'

export const UPDATE_MORTGAGE_APPLICATION_REQUEST = 'UPDATE_MORTGAGE_APPLICATION_REQUEST'
export const UPDATE_MORTGAGE_APPLICATION_SUCCESS = 'UPDATE_MORTGAGE_APPLICATION_SUCCESS'
export const UPDATE_MORTGAGE_APPLICATION_FAILURE = 'UPDATE_MORTGAGE_APPLICATION_FAILURE'

export const FETCH_MORTGAGE_ACCOUNT_PORTFOLIO_REPORT_REQUEST = 'FETCH_MORTGAGE_ACCOUNT_PORTFOLIO_REPORT_REQUEST'
export const FETCH_MORTGAGE_ACCOUNT_PORTFOLIO_REPORT_SUCCESS = 'FETCH_MORTGAGE_ACCOUNT_PORTFOLIO_REPORT_SUCCESS'
export const FETCH_MORTGAGE_ACCOUNT_PORTFOLIO_REPORT_FAILURE = 'FETCH_MORTGAGE_ACCOUNT_PORTFOLIO_REPORT_FAILURE'

export const FETCH_OWNED_PROPERTY_PHOTOS_REQUEST = 'FETCH_OWNED_PROPERTY_PHOTOS_REQUEST'
export const FETCH_OWNED_PROPERTY_PHOTOS_SUCCESS = 'FETCH_OWNED_PROPERTY_PHOTOS_SUCCESS'
export const FETCH_OWNED_PROPERTY_PHOTOS_FAILURE = 'FETCH_OWNED_PROPERTY_PHOTOS_FAILURE'

export const ADD_OWNED_PROPERTY_PHOTOS_REQUEST = 'ADD_OWNED_PROPERTY_PHOTOS_REQUEST'
export const ADD_OWNED_PROPERTY_PHOTOS_SUCCESS = 'ADD_OWNED_PROPERTY_PHOTOS_SUCCESS'
export const ADD_OWNED_PROPERTY_PHOTOS_FAILURE = 'ADD_OWNED_PROPERTY_PHOTOS_FAILURE'

export const UPDATE_OWNED_PROPERTY_PHOTOS_REQUEST = 'UPDATE_OWNED_PROPERTY_PHOTOS_REQUEST'
export const UPDATE_OWNED_PROPERTY_PHOTOS_SUCCESS = 'UPDATE_OWNED_PROPERTY_PHOTOS_SUCCESS'
export const UPDATE_OWNED_PROPERTY_PHOTOS_FAILURE = 'UPDATE_OWNED_PROPERTY_PHOTOS_FAILURE'

export const UPLOAD_OWNED_PROPERTY_PHOTOS_REQUEST = 'UPLOAD_OWNED_PROPERTY_PHOTOS_REQUEST'
export const UPLOAD_OWNED_PROPERTY_PHOTOS_SUCCESS = 'UPLOAD_OWNED_PROPERTY_PHOTOS_SUCCESS'
export const UPLOAD_OWNED_PROPERTY_PHOTOS_FAILURE = 'UPLOAD_OWNED_PROPERTY_PHOTOS_FAILURE'

export const DELETE_OWNED_PROPERTY_PHOTOS_REQUEST = 'DELETE_OWNED_PROPERTY_PHOTOS_REQUEST'
export const DELETE_OWNED_PROPERTY_PHOTOS_SUCCESS = 'DELETE_OWNED_PROPERTY_PHOTOS_SUCCESS'
export const DELETE_OWNED_PROPERTY_PHOTOS_FAILURE = 'DELETE_OWNED_PROPERTY_PHOTOS_FAILURE'

export const FETCH_OWNED_PROPERTY_VIDEOS_REQUEST = 'FETCH_OWNED_PROPERTY_VIDEOS_REQUEST'
export const FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS = 'FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS'
export const FETCH_OWNED_PROPERTY_VIDEOS_FAILURE = 'FETCH_OWNED_PROPERTY_VIDEOS_FAILURE'

export const ADD_OWNED_PROPERTY_VIDEO_REQUEST = 'ADD_OWNED_PROPERTY_VIDEO_REQUEST'
export const ADD_OWNED_PROPERTY_VIDEO_SUCCESS = 'ADD_OWNED_PROPERTY_VIDEO_SUCCESS'
export const ADD_OWNED_PROPERTY_VIDEO_FAILURE = 'ADD_OWNED_PROPERTY_VIDEO_FAILURE'

export const UPDATE_OWNED_PROPERTY_VIDEO_REQUEST = 'UPDATE_OWNED_PROPERTY_VIDEO_REQUEST'
export const UPDATE_OWNED_PROPERTY_VIDEO_SUCCESS = 'UPDATE_OWNED_PROPERTY_VIDEO_SUCCESS'
export const UPDATE_OWNED_PROPERTY_VIDEO_FAILURE = 'UPDATE_OWNED_PROPERTY_VIDEO_FAILURE'

export const DELETE_OWNED_PROPERTY_VIDEO_REQUEST = 'DELETE_OWNED_PROPERTY_VIDEO_REQUEST'
export const DELETE_OWNED_PROPERTY_VIDEO_SUCCESS = 'DELETE_OWNED_PROPERTY_VIDEO_SUCCESS'
export const DELETE_OWNED_PROPERTY_VIDEO_FAILURE = 'DELETE_OWNED_PROPERTY_VIDEO_FAILURE'

export const FETCH_ACCOUNT_WORKFLOWS_REQUEST = 'FETCH_ACCOUNT_WORKFLOWS_REQUEST'
export const FETCH_ACCOUNT_WORKFLOWS_SUCCESS = 'FETCH_ACCOUNT_WORKFLOWS_SUCCESS'
export const FETCH_ACCOUNT_WORKFLOWS_FAILURE = 'FETCH_ACCOUNT_WORKFLOWS_FAILURE'

export const ADD_ACCOUNT_WORKFLOW_REQUEST = 'ADD_ACCOUNT_WORKFLOW_REQUEST'
export const ADD_ACCOUNT_WORKFLOW_SUCCESS = 'ADD_ACCOUNT_WORKFLOW_SUCCESS'
export const ADD_ACCOUNT_WORKFLOW_FAILURE = 'ADD_ACCOUNT_WORKFLOW_FAILURE'

export const UPDATE_ACCOUNT_WORKFLOW_REQUEST = 'UPDATE_ACCOUNT_WORKFLOW_REQUEST'
export const UPDATE_ACCOUNT_WORKFLOW_SUCCESS = 'UPDATE_ACCOUNT_WORKFLOW_SUCCESS'
export const UPDATE_ACCOUNT_WORKFLOW_FAILURE = 'UPDATE_ACCOUNT_WORKFLOW_FAILURE'

export const ARCHIVE_ACCOUNT_WORKFLOW_REQUEST = 'ARCHIVE_ACCOUNT_WORKFLOW_REQUEST'
export const ARCHIVE_ACCOUNT_WORKFLOW_SUCCESS = 'ARCHIVE_ACCOUNT_WORKFLOW_SUCCESS'
export const ARCHIVE_ACCOUNT_WORKFLOW_FAILURE = 'ARCHIVE_ACCOUNT_WORKFLOW_FAILURE'

export const FETCH_COMPANY_BANK_STATEMENTS_REQUEST = 'FETCH_COMPANY_BANK_STATEMENTS_REQUEST'
export const FETCH_COMPANY_BANK_STATEMENTS_SUCCESS = 'FETCH_COMPANY_BANK_STATEMENTS_SUCCESS'
export const FETCH_COMPANY_BANK_STATEMENTS_FAILURE = 'FETCH_COMPANY_BANK_STATEMENTS_FAILURE'

export const FETCH_COMPANY_IDENTITY_DOCUMENTS_REQUEST = 'FETCH_COMPANY_IDENTITY_DOCUMENTS_REQUEST'
export const FETCH_COMPANY_IDENTITY_DOCUMENTS_SUCCESS = 'FETCH_COMPANY_IDENTITY_DOCUMENTS_SUCCESS'
export const FETCH_COMPANY_IDENTITY_DOCUMENTS_FAILURE = 'FETCH_COMPANY_IDENTITY_DOCUMENTS_FAILURE'

export const FETCH_COMPANY_TAX_DOCUMENTS_REQUEST = 'FETCH_COMPANY_TAX_DOCUMENTS_REQUEST'
export const FETCH_COMPANY_TAX_DOCUMENTS_SUCCESS = 'FETCH_COMPANY_TAX_DOCUMENTS_SUCCESS'
export const FETCH_COMPANY_TAX_DOCUMENTS_FAILURE = 'FETCH_COMPANY_TAX_DOCUMENTS_FAILURE'

export const FETCH_ACCOUNT_USER_BANK_STATEMENTS_REQUEST = 'FETCH_ACCOUNT_USER_BANK_STATEMENTS_REQUEST'
export const FETCH_ACCOUNT_USER_BANK_STATEMENTS_SUCCESS = 'FETCH_ACCOUNT_USER_BANK_STATEMENTS_SUCCESS'
export const FETCH_ACCOUNT_USER_BANK_STATEMENTS_FAILURE = 'FETCH_ACCOUNT_USER_BANK_STATEMENTS_FAILURE'

export const FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_REQUEST = 'FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_REQUEST'
export const FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_SUCCESS = 'FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_SUCCESS'
export const FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_FAILURE = 'FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_FAILURE'

export const FETCH_ACCOUNT_USER_TAX_DOCUMENTS_REQUEST = 'FETCH_ACCOUNT_USER_TAX_DOCUMENTS_REQUEST'
export const FETCH_ACCOUNT_USER_TAX_DOCUMENTS_SUCCESS = 'FETCH_ACCOUNT_USER_TAX_DOCUMENTS_SUCCESS'
export const FETCH_ACCOUNT_USER_TAX_DOCUMENTS_FAILURE = 'FETCH_ACCOUNT_USER_TAX_DOCUMENTS_FAILURE'

export const FETCH_ACCOUNT_USER_CREDIT_REPORTS_REQUEST = 'FETCH_ACCOUNT_USER_CREDIT_REPORTS_REQUEST'
export const FETCH_ACCOUNT_USER_CREDIT_REPORTS_SUCCESS = 'FETCH_ACCOUNT_USER_CREDIT_REPORTS_SUCCESS'
export const FETCH_ACCOUNT_USER_CREDIT_REPORTS_FAILURE = 'FETCH_ACCOUNT_USER_CREDIT_REPORTS_FAILURE'

export const GLOBAL_SEARCH_REQUEST = 'GLOBAL_SEARCH_REQUEST'
export const GLOBAL_SEARCH_SUCCESS = 'GLOBAL_SEARCH_SUCCESS'
export const GLOBAL_SEARCH_FAILURE = 'GLOBAL_SEARCH_FAILURE'

export const FETCH_TASK_CONVERSATION_REQUEST = 'FETCH_TASK_CONVERSATION_REQUEST'
export const FETCH_TASK_CONVERSATION_SUCCESS = 'FETCH_TASK_CONVERSATION_SUCCESS'
export const FETCH_TASK_CONVERSATION_FAILURE = 'FETCH_TASK_CONVERSATION_FAILURE'

export const ADD_MORTGAGE_APPLICANT_REQUEST = 'ADD_MORTGAGE_APPLICANT_REQUEST'
export const ADD_MORTGAGE_APPLICANT_SUCCESS = 'ADD_MORTGAGE_APPLICANT_SUCCESS'
export const ADD_MORTGAGE_APPLICANT_FAILURE = 'ADD_MORTGAGE_APPLICANT_FAILURE'

export const FETCH_MORTGAGE_APPLICANTS_REQUEST = 'FETCH_MORTGAGE_APPLICANTS_REQUEST'
export const FETCH_MORTGAGE_APPLICANTS_SUCCESS = 'FETCH_MORTGAGE_APPLICANTS_SUCCESS'
export const FETCH_MORTGAGE_APPLICANTS_FAILURE = 'FETCH_MORTGAGE_APPLICANTS_FAILURE'

export const DELETE_MORTGAGE_APPLICANT_REQUEST = 'DELETE_MORTGAGE_APPLICANT_REQUEST'
export const DELETE_MORTGAGE_APPLICANT_SUCCESS = 'DELETE_MORTGAGE_APPLICANT_SUCCESS'
export const DELETE_MORTGAGE_APPLICANT_FAILURE = 'DELETE_MORTGAGE_APPLICANT_FAILURE'

export const DUPLICATE_DEAL_TEMPLATE_REQUEST = 'DUPLICATE_DEAL_TEMPLATE_REQUEST'
export const DUPLICATE_DEAL_TEMPLATE_SUCCESS = 'DUPLICATE_DEAL_TEMPLATE_SUCCESS'
export const DUPLICATE_DEAL_TEMPLATE_FAILURE = 'DUPLICATE_DEAL_TEMPLATE_FAILURE'

export const FETCH_PORTFOLIO_OVERVIEW_REPORT_REQUEST = 'FETCH_PORTFOLIO_OVERVIEW_REPORT_REQUEST'
export const FETCH_PORTFOLIO_OVERVIEW_REPORT_SUCCESS = 'FETCH_PORTFOLIO_OVERVIEW_REPORT_SUCCESS'
export const FETCH_PORTFOLIO_OVERVIEW_REPORT_FAILURE = 'FETCH_PORTFOLIO_OVERVIEW_REPORT_FAILURE'

export const FETCH_MORTGAGE_APPLICANT_REQUEST = 'FETCH_MORTGAGE_APPLICANT_REQUEST'
export const FETCH_MORTGAGE_APPLICANT_SUCCESS = 'FETCH_MORTGAGE_APPLICANT_SUCCESS'
export const FETCH_MORTGAGE_APPLICANT_FAILURE = 'FETCH_MORTGAGE_APPLICANT_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST = 'FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE = 'FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE'

export const UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST = 'UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST'
export const UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS = 'UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS'
export const UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE = 'UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE'

export const FETCH_OWNED_PROPERTIES_WORKFLOW_REPORT_REQUEST = 'FETCH_OWNED_PROPERTIES_WORKFLOW_REPORT_REQUEST'
export const FETCH_OWNED_PROPERTIES_WORKFLOW_REPORT_SUCCESS = 'FETCH_OWNED_PROPERTIES_WORKFLOW_REPORT_SUCCESS'
export const FETCH_OWNED_PROPERTIES_WORKFLOW_REPORT_FAILURE = 'FETCH_OWNED_PROPERTIES_WORKFLOW_REPORT_FAILURE'

export const UPDATE_OWNED_PROPERTIES_WORKFLOW_REPORT_REQUEST = 'UPDATE_OWNED_PROPERTIES_WORKFLOW_REPORT_REQUEST'
export const UPDATE_OWNED_PROPERTIES_WORKFLOW_REPORT_SUCCESS = 'UPDATE_OWNED_PROPERTIES_WORKFLOW_REPORT_SUCCESS'
export const UPDATE_OWNED_PROPERTIES_WORKFLOW_REPORT_FAILURE = 'UPDATE_OWNED_PROPERTIES_WORKFLOW_REPORT_FAILURE'

export const FETCH_PROPERTY_DEALS_REQUEST = 'FETCH_PROPERTY_DEALS_REQUEST'
export const FETCH_PROPERTY_DEALS_SUCCESS = 'FETCH_PROPERTY_DEALS_SUCCESS'
export const FETCH_PROPERTY_DEALS_FAILURE = 'FETCH_PROPERTY_DEALS_FAILURE'

export const FETCH_OWNED_PROPERTY_SALE_COMPARABLES_REQUEST = 'FETCH_OWNED_PROPERTY_SALE_COMPARABLES_REQUEST'
export const FETCH_OWNED_PROPERTY_SALE_COMPARABLES_SUCCESS = 'FETCH_OWNED_PROPERTY_SALE_COMPARABLES_SUCCESS'
export const FETCH_OWNED_PROPERTY_SALE_COMPARABLES_FAILURE = 'FETCH_OWNED_PROPERTY_SALE_COMPARABLES_FAILURE'

export const FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST =
  'FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS =
  'FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE =
  'FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const FETCH_ACCOUNT_USER_WORKFLOWS_REQUEST = 'FETCH_ACCOUNT_USER_WORKFLOWS_REQUEST'
export const FETCH_ACCOUNT_USER_WORKFLOWS_SUCCESS = 'FETCH_ACCOUNT_USER_WORKFLOWS_SUCCESS'
export const FETCH_ACCOUNT_USER_WORKFLOWS_FAILURE = 'FETCH_ACCOUNT_USER_WORKFLOWS_FAILURE'

export const DELETE_CONTRIBUTION_REQUEST = 'DELETE_CONTRIBUTION_REQUEST'
export const DELETE_CONTRIBUTION_SUCCESS = 'DELETE_CONTRIBUTION_SUCCESS'
export const DELETE_CONTRIBUTION_FAILURE = 'DELETE_CONTRIBUTION_FAILURE'

export const UPDATE_WORKFLOW_OWNER_REQUEST = 'UPDATE_WORKFLOW_OWNER_REQUEST'
export const UPDATE_WORKFLOW_OWNER_SUCCESS = 'UPDATE_WORKFLOW_OWNER_SUCCESS'
export const UPDATE_WORKFLOW_OWNER_FAILURE = 'UPDATE_WORKFLOW_OWNER_FAILURE'

export const ADD_WORKFLOW_ASSIGNEE_REQUEST = 'ADD_WORKFLOW_ASSIGNEE_REQUEST'
export const ADD_WORKFLOW_ASSIGNEE_SUCCESS = 'ADD_WORKFLOW_ASSIGNEE_SUCCESS'
export const ADD_WORKFLOW_ASSIGNEE_FAILURE = 'ADD_WORKFLOW_ASSIGNEE_FAILURE'

export const FETCH_WORKFLOW_ASSIGNEES_REQUEST = 'FETCH_WORKFLOW_ASSIGNEES_REQUEST'
export const FETCH_WORKFLOW_ASSIGNEES_SUCCESS = 'FETCH_WORKFLOW_ASSIGNEES_SUCCESS'
export const FETCH_WORKFLOW_ASSIGNEES_FAILURE = 'FETCH_WORKFLOW_ASSIGNEES_FAILURE'

export const DELETE_WORKFLOW_ASSIGNEE_REQUEST = 'DELETE_WORKFLOW_ASSIGNEE_REQUEST'
export const DELETE_WORKFLOW_ASSIGNEE_SUCCESS = 'DELETE_WORKFLOW_ASSIGNEE_SUCCESS'
export const DELETE_WORKFLOW_ASSIGNEE_FAILURE = 'DELETE_WORKFLOW_ASSIGNEE_FAILURE'

export const ADD_REMINDER_TO_WORKFLOW_REQUEST = 'ADD_REMINDER_TO_WORKFLOW_REQUEST'
export const ADD_REMINDER_TO_WORKFLOW_SUCCESS = 'ADD_REMINDER_TO_WORKFLOW_SUCCESS'
export const ADD_REMINDER_TO_WORKFLOW_FAILURE = 'ADD_REMINDER_TO_WORKFLOW_FAILURE'

export const DELETE_REMINDER_FROM_WORKFLOW_REQUEST = 'DELETE_REMINDER_FROM_WORKFLOW_REQUEST'
export const DELETE_REMINDER_FROM_WORKFLOW_SUCCESS = 'DELETE_REMINDER_FROM_WORKFLOW_SUCCESS'
export const DELETE_REMINDER_FROM_WORKFLOW_FAILURE = 'DELETE_REMINDER_FROM_WORKFLOW_FAILURE'

export const FETCH_ACCOUNT_USER_WORKFLOW_REPORT_REQUEST = 'FETCH_ACCOUNT_USER_WORKFLOW_REPORT_REQUEST'
export const FETCH_ACCOUNT_USER_WORKFLOW_REPORT_SUCCESS = 'FETCH_ACCOUNT_USER_WORKFLOW_REPORT_SUCCESS'
export const FETCH_ACCOUNT_USER_WORKFLOW_REPORT_FAILURE = 'FETCH_ACCOUNT_USER_WORKFLOW_REPORT_FAILURE'

export const ADD_PRODUCT_TRANSFER_DEAL_REQUEST = 'ADD_PRODUCT_TRANSFER_DEAL_REQUEST'
export const ADD_PRODUCT_TRANSFER_DEAL_SUCCESS = 'ADD_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const ADD_PRODUCT_TRANSFER_DEAL_FAILURE = 'ADD_PRODUCT_TRANSFER_DEAL_FAILURE'

export const ARCHIVE_PRODUCT_TRANSFER_DEAL_REQUEST = 'ARCHIVE_PRODUCT_TRANSFER_DEAL_REQUEST'
export const ARCHIVE_PRODUCT_TRANSFER_DEAL_SUCCESS = 'ARCHIVE_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const ARCHIVE_PRODUCT_TRANSFER_DEAL_FAILURE = 'ARCHIVE_PRODUCT_TRANSFER_DEAL_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST =
  'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS =
  'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE =
  'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE'

export const FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST =
  'FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST'
export const FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS =
  'FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS'
export const FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE =
  'FETCH_OWNED_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE'

export const UNARCHIVE_WORKFLOW_REQUEST = 'UNARCHIVE_WORKFLOW_REQUEST'
export const UNARCHIVE_WORKFLOW_SUCCESS = 'UNARCHIVE_WORKFLOW_SUCCESS'
export const UNARCHIVE_WORKFLOW_FAILURE = 'UNARCHIVE_WORKFLOW_FAILURE'

export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST'
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS'
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE'

export const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE'

export const FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST'
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE'

export const FETCH_WORKFLOWS_REQUEST = 'FETCH_WORKFLOWS_REQUEST'
export const FETCH_WORKFLOWS_SUCCESS = 'FETCH_WORKFLOWS_SUCCESS'
export const FETCH_WORKFLOWS_FAILURE = 'FETCH_WORKFLOWS_FAILURE'

export const ADD_WORKFLOW_REQUEST = 'ADD_WORKFLOW_REQUEST'
export const ADD_WORKFLOW_SUCCESS = 'ADD_WORKFLOW_SUCCESS'
export const ADD_WORKFLOW_FAILURE = 'ADD_WORKFLOW_FAILURE'

export const ARCHIVE_OWNED_PROPERTY_REQUEST = 'ARCHIVE_OWNED_PROPERTY_REQUEST'
export const ARCHIVE_OWNED_PROPERTY_SUCCESS = 'ARCHIVE_OWNED_PROPERTY_SUCCESS'
export const ARCHIVE_OWNED_PROPERTY_FAILURE = 'ARCHIVE_OWNED_PROPERTY_FAILURE'

export const FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST =
  'FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST'
export const FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS =
  'FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS'
export const FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE =
  'FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE'

export const ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST =
  'ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS =
  'ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE =
  'ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const ARCHIVE_RENT_LISTING_REQUEST = 'ARCHIVE_RENT_LISTING_REQUEST'
export const ARCHIVE_RENT_LISTING_SUCCESS = 'ARCHIVE_RENT_LISTING_SUCCESS'
export const ARCHIVE_RENT_LISTING_FAILURE = 'ARCHIVE_RENT_LISTING_FAILURE'

export const FETCH_RENT_LISTING_REQUEST = 'FETCH_RENT_LISTING_REQUEST'
export const FETCH_RENT_LISTING_SUCCESS = 'FETCH_RENT_LISTING_SUCCESS'
export const FETCH_RENT_LISTING_FAILURE = 'FETCH_RENT_LISTING_FAILURE'

export const FETCH_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST = 'FETCH_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const FETCH_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS = 'FETCH_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const FETCH_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE = 'FETCH_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const ARCHIVE_SALE_LISTING_REQUEST = 'ARCHIVE_SALE_LISTING_REQUEST'
export const ARCHIVE_SALE_LISTING_SUCCESS = 'ARCHIVE_SALE_LISTING_SUCCESS'
export const ARCHIVE_SALE_LISTING_FAILURE = 'ARCHIVE_SALE_LISTING_FAILURE'

export const FETCH_SALE_LISTING_REQUEST = 'FETCH_SALE_LISTING_REQUEST'
export const FETCH_SALE_LISTING_SUCCESS = 'FETCH_SALE_LISTING_SUCCESS'
export const FETCH_SALE_LISTING_FAILURE = 'FETCH_SALE_LISTING_FAILURE'

export const ARCHIVE_RENT_COMPARABLE_REQUEST = 'ARCHIVE_RENT_COMPARABLE_REQUEST'
export const ARCHIVE_RENT_COMPARABLE_SUCCESS = 'ARCHIVE_RENT_COMPARABLE_SUCCESS'
export const ARCHIVE_RENT_COMPARABLE_FAILURE = 'ARCHIVE_RENT_COMPARABLE_FAILURE'

export const FETCH_RENT_COMPARABLE_REQUEST = 'FETCH_RENT_COMPARABLE_REQUEST'
export const FETCH_RENT_COMPARABLE_SUCCESS = 'FETCH_RENT_COMPARABLE_SUCCESS'
export const FETCH_RENT_COMPARABLE_FAILURE = 'FETCH_RENT_COMPARABLE_FAILURE'

export const ARCHIVE_SALE_COMPARABLE_REQUEST = 'ARCHIVE_SALE_COMPARABLE_REQUEST'
export const ARCHIVE_SALE_COMPARABLE_SUCCESS = 'ARCHIVE_SALE_COMPARABLE_SUCCESS'
export const ARCHIVE_SALE_COMPARABLE_FAILURE = 'ARCHIVE_SALE_COMPARABLE_FAILURE'

export const FETCH_SALE_COMPARABLE_REQUEST = 'FETCH_SALE_COMPARABLE_REQUEST'
export const FETCH_SALE_COMPARABLE_SUCCESS = 'FETCH_SALE_COMPARABLE_SUCCESS'
export const FETCH_SALE_COMPARABLE_FAILURE = 'FETCH_SALE_COMPARABLE_FAILURE'

export const ARCHIVE_PROPERTY_OWNER_REQUEST = 'ARCHIVE_PROPERTY_OWNER_REQUEST'
export const ARCHIVE_PROPERTY_OWNER_SUCCESS = 'ARCHIVE_PROPERTY_OWNER_SUCCESS'
export const ARCHIVE_PROPERTY_OWNER_FAILURE = 'ARCHIVE_PROPERTY_OWNER_FAILURE'

export const FETCH_PROPERTY_OWNER_REQUEST = 'FETCH_PROPERTY_OWNER_REQUEST'
export const FETCH_PROPERTY_OWNER_SUCCESS = 'FETCH_PROPERTY_OWNER_SUCCESS'
export const FETCH_PROPERTY_OWNER_FAILURE = 'FETCH_PROPERTY_OWNER_FAILURE'

export const FETCH_PROSPECTIVE_PROPERTY_PROPERTY_OWNERS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_PROPERTY_OWNERS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_PROPERTY_OWNERS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_PROPERTY_OWNERS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_PROPERTY_OWNERS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_PROPERTY_OWNERS_FAILURE'

export const FETCH_DOCUMENT_REQUEST = 'FETCH_DOCUMENT_REQUEST'
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS'
export const FETCH_DOCUMENT_FAILURE = 'FETCH_DOCUMENT_FAILURE'

export const ARCHIVE_DOCUMENT_REQUEST = 'ARCHIVE_DOCUMENT_REQUEST'
export const ARCHIVE_DOCUMENT_SUCCESS = 'ARCHIVE_DOCUMENT_SUCCESS'
export const ARCHIVE_DOCUMENT_FAILURE = 'ARCHIVE_DOCUMENT_FAILURE'

export const FETCH_VIDEO_REQUEST = 'FETCH_VIDEO_REQUEST'
export const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS'
export const FETCH_VIDEO_FAILURE = 'FETCH_VIDEO_FAILURE'

export const ARCHIVE_VIDEO_REQUEST = 'ARCHIVE_VIDEO_REQUEST'
export const ARCHIVE_VIDEO_SUCCESS = 'ARCHIVE_VIDEO_SUCCESS'
export const ARCHIVE_VIDEO_FAILURE = 'ARCHIVE_VIDEO_FAILURE'

export const UNARCHIVE_VIDEO_REQUEST = 'UNARCHIVE_VIDEO_REQUEST'
export const UNARCHIVE_VIDEO_SUCCESS = 'UNARCHIVE_VIDEO_SUCCESS'
export const UNARCHIVE_VIDEO_FAILURE = 'UNARCHIVE_VIDEO_FAILURE'

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST'
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS'
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE'

export const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST'
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS'
export const UPDATE_VIDEO_FAILURE = 'UPDATE_VIDEO_FAILURE'

export const UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_REQUEST =
  'UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_REQUEST'
export const UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_SUCCESS =
  'UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_SUCCESS'
export const UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_FAILURE =
  'UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_FAILURE'

export const UPLOAD_DOCUMENT_FILE_REQUEST = 'UPLOAD_DOCUMENT_FILE_REQUEST'
export const UPLOAD_DOCUMENT_FILE_SUCCESS = 'UPLOAD_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_DOCUMENT_FILE_FAILURE = 'UPLOAD_DOCUMENT_FILE_FAILURE'

export const ADD_FINANCIAL_SLIDE_REQUEST = 'ADD_FINANCIAL_SLIDE_REQUEST'
export const ADD_FINANCIAL_SLIDE_SUCCESS = 'ADD_FINANCIAL_SLIDE_SUCCESS'
export const ADD_FINANCIAL_SLIDE_FAILURE = 'ADD_FINANCIAL_SLIDE_FAILURE'

export const UPDATE_FINANCIAL_SLIDE_REQUEST = 'UPDATE_FINANCIAL_SLIDE_REQUEST'
export const UPDATE_FINANCIAL_SLIDE_SUCCESS = 'UPDATE_FINANCIAL_SLIDE_SUCCESS'
export const UPDATE_FINANCIAL_SLIDE_FAILURE = 'UPDATE_FINANCIAL_SLIDE_FAILURE'

export const ADD_PURCHASE_COST_SLIDE_REQUEST = 'ADD_PURCHASE_COST_SLIDE_REQUEST'
export const ADD_PURCHASE_COST_SLIDE_SUCCESS = 'ADD_PURCHASE_COST_SLIDE_SUCCESS'
export const ADD_PURCHASE_COST_SLIDE_FAILURE = 'ADD_PURCHASE_COST_SLIDE_FAILURE'

export const UPDATE_PURCHASE_COST_SLIDE_REQUEST = 'UPDATE_PURCHASE_COST_SLIDE_REQUEST'
export const UPDATE_PURCHASE_COST_SLIDE_SUCCESS = 'UPDATE_PURCHASE_COST_SLIDE_SUCCESS'
export const UPDATE_PURCHASE_COST_SLIDE_FAILURE = 'UPDATE_PURCHASE_COST_SLIDE_FAILURE'

export const ADD_DEVELOPMENT_COST_SLIDE_REQUEST = 'ADD_DEVELOPMENT_COST_SLIDE_REQUEST'
export const ADD_DEVELOPMENT_COST_SLIDE_SUCCESS = 'ADD_DEVELOPMENT_COST_SLIDE_SUCCESS'
export const ADD_DEVELOPMENT_COST_SLIDE_FAILURE = 'ADD_DEVELOPMENT_COST_SLIDE_FAILURE'

export const UPDATE_DEVELOPMENT_COST_SLIDE_REQUEST = 'UPDATE_DEVELOPMENT_COST_SLIDE_REQUEST'
export const UPDATE_DEVELOPMENT_COST_SLIDE_SUCCESS = 'UPDATE_DEVELOPMENT_COST_SLIDE_SUCCESS'
export const UPDATE_DEVELOPMENT_COST_SLIDE_FAILURE = 'UPDATE_DEVELOPMENT_COST_SLIDE_FAILURE'

export const ADD_CASH_FLOW_SLIDE_REQUEST = 'ADD_CASH_FLOW_SLIDE_REQUEST'
export const ADD_CASH_FLOW_SLIDE_SUCCESS = 'ADD_CASH_FLOW_SLIDE_SUCCESS'
export const ADD_CASH_FLOW_SLIDE_FAILURE = 'ADD_CASH_FLOW_SLIDE_FAILURE'

export const UPDATE_CASH_FLOW_SLIDE_REQUEST = 'UPDATE_CASH_FLOW_SLIDE_REQUEST'
export const UPDATE_CASH_FLOW_SLIDE_SUCCESS = 'UPDATE_CASH_FLOW_SLIDE_SUCCESS'
export const UPDATE_CASH_FLOW_SLIDE_FAILURE = 'UPDATE_CASH_FLOW_SLIDE_FAILURE'

export const ADD_LOAN_REPAYMENT_SLIDE_REQUEST = 'ADD_LOAN_REPAYMENT_SLIDE_REQUEST'
export const ADD_LOAN_REPAYMENT_SLIDE_SUCCESS = 'ADD_LOAN_REPAYMENT_SLIDE_SUCCESS'
export const ADD_LOAN_REPAYMENT_SLIDE_FAILURE = 'ADD_LOAN_REPAYMENT_SLIDE_FAILURE'

export const UPDATE_LOAN_REPAYMENT_SLIDE_REQUEST = 'UPDATE_LOAN_REPAYMENT_SLIDE_REQUEST'
export const UPDATE_LOAN_REPAYMENT_SLIDE_SUCCESS = 'UPDATE_LOAN_REPAYMENT_SLIDE_SUCCESS'
export const UPDATE_LOAN_REPAYMENT_SLIDE_FAILURE = 'UPDATE_LOAN_REPAYMENT_SLIDE_FAILURE'

export const ADD_EQUITY_STRUCTURE_SLIDE_REQUEST = 'ADD_EQUITY_STRUCTURE_SLIDE_REQUEST'
export const ADD_EQUITY_STRUCTURE_SLIDE_SUCCESS = 'ADD_EQUITY_STRUCTURE_SLIDE_SUCCESS'
export const ADD_EQUITY_STRUCTURE_SLIDE_FAILURE = 'ADD_EQUITY_STRUCTURE_SLIDE_FAILURE'

export const UPDATE_EQUITY_STRUCTURE_SLIDE_REQUEST = 'UPDATE_EQUITY_STRUCTURE_SLIDE_REQUEST'
export const UPDATE_EQUITY_STRUCTURE_SLIDE_SUCCESS = 'UPDATE_EQUITY_STRUCTURE_SLIDE_SUCCESS'
export const UPDATE_EQUITY_STRUCTURE_SLIDE_FAILURE = 'UPDATE_EQUITY_STRUCTURE_SLIDE_FAILURE'

export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_REQUEST = 'UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_REQUEST'
export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_SUCCESS = 'UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_SUCCESS'
export const UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_FAILURE = 'UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_FAILURE'

export const UPDATE_FLIP_DEAL_SUPPORT_NEEDED_REQUEST = 'UPDATE_FLIP_DEAL_SUPPORT_NEEDED_REQUEST'
export const UPDATE_FLIP_DEAL_SUPPORT_NEEDED_SUCCESS = 'UPDATE_FLIP_DEAL_SUPPORT_NEEDED_SUCCESS'
export const UPDATE_FLIP_DEAL_SUPPORT_NEEDED_FAILURE = 'UPDATE_FLIP_DEAL_SUPPORT_NEEDED_FAILURE'

export const UPDATE_HMO_DEAL_SUPPORT_NEEDED_REQUEST = 'UPDATE_HMO_DEAL_SUPPORT_NEEDED_REQUEST'
export const UPDATE_HMO_DEAL_SUPPORT_NEEDED_SUCCESS = 'UPDATE_HMO_DEAL_SUPPORT_NEEDED_SUCCESS'
export const UPDATE_HMO_DEAL_SUPPORT_NEEDED_FAILURE = 'UPDATE_HMO_DEAL_SUPPORT_NEEDED_FAILURE'

export const UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST = 'UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST'
export const UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS = 'UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS'
export const UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE = 'UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE'

export const UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_REQUEST = 'UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_REQUEST'
export const UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_SUCCESS = 'UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_SUCCESS'
export const UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_FAILURE = 'UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_FAILURE'

export const UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_REQUEST = 'UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_REQUEST'
export const UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_SUCCESS = 'UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_SUCCESS'
export const UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_FAILURE = 'UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_FAILURE'

export const FETCH_RENT_TO_RENT_DEAL_TEMPLATES_REQUEST = 'FETCH_RENT_TO_RENT_DEAL_TEMPLATES_REQUEST'
export const FETCH_RENT_TO_RENT_DEAL_TEMPLATES_SUCCESS = 'FETCH_RENT_TO_RENT_DEAL_TEMPLATES_SUCCESS'
export const FETCH_RENT_TO_RENT_DEAL_TEMPLATES_FAILURE = 'FETCH_RENT_TO_RENT_DEAL_TEMPLATES_FAILURE'

export const FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST = 'FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST'
export const FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS = 'FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE = 'FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE'

export const UPDATE_RENT_TO_RENT_DEAL_EXPENSES_REQUEST = 'UPDATE_RENT_TO_RENT_DEAL_EXPENSES_REQUEST'
export const UPDATE_RENT_TO_RENT_DEAL_EXPENSES_SUCCESS = 'UPDATE_RENT_TO_RENT_DEAL_EXPENSES_SUCCESS'
export const UPDATE_RENT_TO_RENT_DEAL_EXPENSES_FAILURE = 'UPDATE_RENT_TO_RENT_DEAL_EXPENSES_FAILURE'

export const CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_REQUEST = 'FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_SUCCESS = 'FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FAILURE = 'FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST = 'FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS = 'FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE = 'FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST = 'FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST'
export const FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS = 'FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE = 'FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE'

export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE'

export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE'

export const UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST = 'UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST'
export const UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS = 'UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS'
export const UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE = 'UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE'

export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE'

export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS'
export const CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE = 'CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE'

export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE'

export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS'
export const FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE = 'FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE'

export const DUPLICATE_NEW_PROPERTY_REQUEST = 'DUPLICATE_NEW_PROPERTY_REQUEST'
export const DUPLICATE_NEW_PROPERTY_SUCCESS = 'DUPLICATE_NEW_PROPERTY_SUCCESS'
export const DUPLICATE_NEW_PROPERTY_FAILURE = 'DUPLICATE_NEW_PROPERTY_FAILURE'

export const FETCH_INSURANCE_BROKER_ACCOUNTS_REQUEST = 'FETCH_INSURANCE_BROKER_ACCOUNTS_REQUEST'
export const FETCH_INSURANCE_BROKER_ACCOUNTS_SUCCESS = 'FETCH_INSURANCE_BROKER_ACCOUNTS_SUCCESS'
export const FETCH_INSURANCE_BROKER_ACCOUNTS_FAILURE = 'FETCH_INSURANCE_BROKER_ACCOUNTS_FAILURE'

export const FETCH_SOLICITOR_ACCOUNTS_REQUEST = 'FETCH_SOLICITOR_ACCOUNTS_REQUEST'
export const FETCH_SOLICITOR_ACCOUNTS_SUCCESS = 'FETCH_SOLICITOR_ACCOUNTS_SUCCESS'
export const FETCH_SOLICITOR_ACCOUNTS_FAILURE = 'FETCH_SOLICITOR_ACCOUNTS_FAILURE'

export const FETCH_BRIDGING_LENDER_ACCOUNTS_REQUEST = 'FETCH_BRIDGING_LENDER_ACCOUNTS_REQUEST'
export const FETCH_BRIDGING_LENDER_ACCOUNTS_SUCCESS = 'FETCH_BRIDGING_LENDER_ACCOUNTS_SUCCESS'
export const FETCH_BRIDGING_LENDER_ACCOUNTS_FAILURE = 'FETCH_BRIDGING_LENDER_ACCOUNTS_FAILURE'

export const ADD_VIDEO_SLIDE_REQUEST = 'ADD_VIDEO_SLIDE_REQUEST'
export const ADD_VIDEO_SLIDE_SUCCESS = 'ADD_VIDEO_SLIDE_SUCCESS'
export const ADD_VIDEO_SLIDE_FAILURE = 'ADD_VIDEO_SLIDE_FAILURE'

export const UPDATE_VIDEO_SLIDE_REQUEST = 'UPDATE_VIDEO_SLIDE_REQUEST'
export const UPDATE_VIDEO_SLIDE_SUCCESS = 'UPDATE_VIDEO_SLIDE_SUCCESS'
export const UPDATE_VIDEO_SLIDE_FAILURE = 'UPDATE_VIDEO_SLIDE_FAILURE'
