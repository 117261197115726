export const FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_REQUEST = 'FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_REQUEST'
export const FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_SUCCESS = 'FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_SUCCESS'
export const FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_FAILURE = 'FETCH_INSURANCE_APPLICATION_INSURANCE_APPLICANTS_FAILURE'

export const FETCH_INSURANCE_APPLICANT_REQUEST = 'FETCH_INSURANCE_APPLICANT_REQUEST'
export const FETCH_INSURANCE_APPLICANT_SUCCESS = 'FETCH_INSURANCE_APPLICANT_SUCCESS'
export const FETCH_INSURANCE_APPLICANT_FAILURE = 'FETCH_INSURANCE_APPLICANT_FAILURE'

export const CREATE_INSURANCE_APPLICANT_REQUEST = 'CREATE_INSURANCE_APPLICANT_REQUEST'
export const CREATE_INSURANCE_APPLICANT_SUCCESS = 'CREATE_INSURANCE_APPLICANT_SUCCESS'
export const CREATE_INSURANCE_APPLICANT_FAILURE = 'CREATE_INSURANCE_APPLICANT_FAILURE'

export const UPDATE_INSURANCE_APPLICANT_REQUEST = 'UPDATE_INSURANCE_APPLICANT_REQUEST'
export const UPDATE_INSURANCE_APPLICANT_SUCCESS = 'UPDATE_INSURANCE_APPLICANT_SUCCESS'
export const UPDATE_INSURANCE_APPLICANT_FAILURE = 'UPDATE_INSURANCE_APPLICANT_FAILURE'

export const ARCHIVE_INSURANCE_APPLICANT_REQUEST = 'ARCHIVE_INSURANCE_APPLICANT_REQUEST'
export const ARCHIVE_INSURANCE_APPLICANT_SUCCESS = 'ARCHIVE_INSURANCE_APPLICANT_SUCCESS'
export const ARCHIVE_INSURANCE_APPLICANT_FAILURE = 'ARCHIVE_INSURANCE_APPLICANT_FAILURE'

export const UNARCHIVE_INSURANCE_APPLICANT_REQUEST = 'UNARCHIVE_INSURANCE_APPLICANT_REQUEST'
export const UNARCHIVE_INSURANCE_APPLICANT_SUCCESS = 'UNARCHIVE_INSURANCE_APPLICANT_SUCCESS'
export const UNARCHIVE_INSURANCE_APPLICANT_FAILURE = 'UNARCHIVE_INSURANCE_APPLICANT_FAILURE'

export const DELETE_INSURANCE_APPLICANT_REQUEST = 'DELETE_INSURANCE_APPLICANT_REQUEST'
export const DELETE_INSURANCE_APPLICANT_SUCCESS = 'DELETE_INSURANCE_APPLICANT_SUCCESS'
export const DELETE_INSURANCE_APPLICANT_FAILURE = 'DELETE_INSURANCE_APPLICANT_FAILURE'