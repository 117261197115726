import update from 'immutability-helper'
import {FETCH_USER_DOCUMENTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_USER_DOCUMENTS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_USER_DOCUMENTS_SUCCESS} from 'redux/actionTypes.js'
import {ADD_USER_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {ADD_USER_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {ADD_USER_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_USER_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_USER_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_USER_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_USER_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {DELETE_USER_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {DELETE_USER_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {UPLOAD_USER_DOCUMENT_FILE_REQUEST} from 'redux/actionTypes.js'
import {UPLOAD_USER_DOCUMENT_FILE_FAILURE} from 'redux/actionTypes.js'
import {UPLOAD_USER_DOCUMENT_FILE_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchUserDocumentsStatus: 'requested',
  fetchUserDocumentsError: '',
  documents: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DOCUMENTS_REQUEST: {
      return update(state, {
        fetchUserDocumentsStatus: {$set: 'requested'},
      })
    }

    case FETCH_USER_DOCUMENTS_FAILURE: {
      return update(state, {
        fetchUserDocumentsStatus: {
          $set: 'error',
        },
        fetchUserDocumentsError: {$set: action.payload.error},
      })
    }

    case FETCH_USER_DOCUMENTS_SUCCESS: {
      return update(state, {
        fetchUserDocumentsStatus: {$set: 'success'},
        documents: {
          $set: action.payload.documents,
        },
      })
    }

    case ADD_USER_DOCUMENT_REQUEST: {
      return state
    }

    case ADD_USER_DOCUMENT_FAILURE: {
      return state
    }

    case ADD_USER_DOCUMENT_SUCCESS: {
      return update(state, {
        documents: {
          $push: [action.payload.document],
        },
      })
    }

    case UPDATE_USER_DOCUMENT_REQUEST: {
      return state
    }

    case UPDATE_USER_DOCUMENT_FAILURE: {
      return state
    }

    case UPDATE_USER_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(
        x => x.id === action.payload.document.id,
      )

      return update(state, {
        documents: {
          [index]: {$set: action.payload.document},
        },
      })
    }

    case DELETE_USER_DOCUMENT_REQUEST: {
      return state
    }

    case DELETE_USER_DOCUMENT_FAILURE: {
      return state
    }

    case DELETE_USER_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(x => x.id === action.payload.id)

      return update(state, {
        documents: {
          $splice: [[index, 1]],
        },
      })
    }

    case UPLOAD_USER_DOCUMENT_FILE_REQUEST: {
      return state
    }

    case UPLOAD_USER_DOCUMENT_FILE_FAILURE: {
      return state
    }

    case UPLOAD_USER_DOCUMENT_FILE_SUCCESS: {
      let index = state.documents.findIndex(
        x => x.id === action.payload.document.id,
      )

      return update(state, {
        documents: {
          [index]: {$set: action.payload.document},
        },
      })
    }

    default:
      return state
  }
}
