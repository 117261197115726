import update from 'immutability-helper'
import {FETCH_ACCOUNT_PARTNERS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_PARTNERS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_PARTNERS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_ACCOUNTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_ACCOUNTS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_ACCOUNTS_SUCCESS} from 'redux/actionTypes.js'
import { FETCH_BRIDGING_LENDER_ACCOUNTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_BRIDGING_LENDER_ACCOUNTS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_BRIDGING_LENDER_ACCOUNTS_SUCCESS } from 'redux/actionTypes.js'
import {ADD_ACCOUNT_PARTNER_REQUEST} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_PARTNER_FAILURE} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_PARTNER_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_PARTNER_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_PARTNER_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_PARTNER_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_ACCOUNT_PARTNER_REQUEST} from 'redux/actionTypes.js'
import {DELETE_ACCOUNT_PARTNER_FAILURE} from 'redux/actionTypes.js'
import {DELETE_ACCOUNT_PARTNER_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_INSURANCE_BROKER_ACCOUNTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_INSURANCE_BROKER_ACCOUNTS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_INSURANCE_BROKER_ACCOUNTS_SUCCESS} from 'redux/actionTypes.js'
import { FETCH_SOLICITOR_ACCOUNTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_SOLICITOR_ACCOUNTS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_SOLICITOR_ACCOUNTS_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchAccountPartnersStatus: 'requested',
  fetchAccountPartnersError: '',
  accountPartners: [],
  fetchMortgageBrokerAccountsStatus: 'requested',
  fetchMortgageBrokerAccountsError: '',
  mortgageBrokerAccounts: [],
  fetchInsuranceBrokerAccountsStatus: 'requested',
  fetchInsuranceBrokerAccountsError: '',
  insuranceBrokerAccounts: [],
  fetchSolicitorAccountsStatus: 'requested',
  fetchSolicitorAccountsError: '',
  solicitorAccounts: [],
  fetchBridgingLenderAccountsStatus: 'requested',
  fetchBridgingLenderAccountsError: '',
  bridgingLenderAccounts: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_PARTNERS_REQUEST: {
      return update(state, {
        fetchAccountPartnersStatus: {$set: 'requested'},
      })
    }

    case FETCH_ACCOUNT_PARTNERS_FAILURE: {
      return update(state, {
        fetchAccountPartnersStatus: {
          $set: 'error',
        },
        fetchAccountPartnersError: {$set: action.payload.error},
      })
    }

    case FETCH_ACCOUNT_PARTNERS_SUCCESS: {
      return update(state, {
        fetchAccountPartnersStatus: {$set: 'success'},
        accountPartners: {
          $set: action.payload.accountPartners,
        },
      })
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNTS_REQUEST: {
      return update(state, {
        fetchMortgageBrokerAccountsStatus: {$set: 'requested'},
      })
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNTS_FAILURE: {
      return update(state, {
        fetchMortgageBrokerAccountsStatus: {
          $set: 'error',
        },
        fetchMortgageBrokerAccountsError: {$set: action.payload.error},
      })
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNTS_SUCCESS: {
      return update(state, {
        fetchMortgageBrokerAccountsStatus: {$set: 'success'},
        mortgageBrokerAccounts: {
          $set: action.payload.mortgageBrokerAccounts,
        },
      })
    }

    case FETCH_BRIDGING_LENDER_ACCOUNTS_REQUEST: {
      return update(state, {
        fetchBridgingLenderAccountsStatus: { $set: 'requested' },
      })
    }

    case FETCH_BRIDGING_LENDER_ACCOUNTS_FAILURE: {
      return update(state, {
        fetchBridgingLenderAccountsStatus: {
          $set: 'error',
        },
        fetchBridgingLenderAccountsError: { $set: action.payload.error },
      })
    }

    case FETCH_BRIDGING_LENDER_ACCOUNTS_SUCCESS: {
      return update(state, {
        fetchBridgingLenderAccountsStatus: { $set: 'success' },
        bridgingLenderAccounts: {
          $set: action.payload.bridgingLenderAccounts,
        },
      })
    }

    case FETCH_INSURANCE_BROKER_ACCOUNTS_REQUEST: {
      return update(state, {
        fetchInsuranceBrokerAccountsStatus: {$set: 'requested'},
      })
    }

    case FETCH_INSURANCE_BROKER_ACCOUNTS_FAILURE: {
      return update(state, {
        fetchInsuranceBrokerAccountsStatus: {
          $set: 'error',
        },
        fetchInsuranceBrokerAccountsError: {$set: action.payload.error},
      })
    }

    case FETCH_INSURANCE_BROKER_ACCOUNTS_SUCCESS: {
      return update(state, {
        fetchInsuranceBrokerAccountsStatus: {$set: 'success'},
        insuranceBrokerAccounts: {
          $set: action.payload.insuranceBrokerAccounts,
        },
      })
    }

    case FETCH_SOLICITOR_ACCOUNTS_REQUEST: {
      return update(state, {
        fetchSolicitorAccountsStatus: { $set: 'requested' },
      })
    }

    case FETCH_SOLICITOR_ACCOUNTS_FAILURE: {
      return update(state, {
        fetchSolicitorAccountsStatus: {
          $set: 'error',
        },
        fetchSolicitorAccountsError: { $set: action.payload.error },
      })
    }

    case FETCH_SOLICITOR_ACCOUNTS_SUCCESS: {
      return update(state, {
        fetchSolicitorAccountsStatus: { $set: 'success' },
        solicitorAccounts: {
          $set: action.payload.solicitorAccounts,
        },
      })
    }

    case ADD_ACCOUNT_PARTNER_REQUEST: {
      return state
    }

    case ADD_ACCOUNT_PARTNER_FAILURE: {
      return state
    }

    case ADD_ACCOUNT_PARTNER_SUCCESS: {
      return update(state, {
        accountPartners: {
          $push: [action.payload.accountPartner],
        },
      })
    }

    case UPDATE_ACCOUNT_PARTNER_REQUEST: {
      return state
    }

    case UPDATE_ACCOUNT_PARTNER_FAILURE: {
      return state
    }

    case UPDATE_ACCOUNT_PARTNER_SUCCESS: {
      let index = state.accountPartners.findIndex(
        x => x.id === action.payload.accountPartner.id,
      )

      return update(state, {
        accountPartners: {
          [index]: {$set: action.payload.accountPartner},
        },
      })
    }

    case DELETE_ACCOUNT_PARTNER_REQUEST: {
      return state
    }

    case DELETE_ACCOUNT_PARTNER_FAILURE: {
      return state
    }

    case DELETE_ACCOUNT_PARTNER_SUCCESS: {
      let index = state.accountPartners.findIndex(
        x => x.id === action.payload.id,
      )

      return update(state, {
        accountPartners: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
