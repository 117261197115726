import update from 'immutability-helper'
import { FETCH_ACCOUNT_COMPANIES_REQUEST } from 'v2/spas/account/companies/redux/action-types.tsx'
import { FETCH_ACCOUNT_COMPANIES_SUCCESS } from 'v2/spas/account/companies/redux/action-types.tsx'
import { FETCH_ACCOUNT_COMPANIES_FAILURE } from 'v2/spas/account/companies/redux/action-types.tsx'
import { ARCHIVE_COMPANY_REQUEST } from 'v2/spas/account/companies/redux/action-types.tsx'
import { ARCHIVE_COMPANY_SUCCESS } from 'v2/spas/account/companies/redux/action-types.tsx'
import { ARCHIVE_COMPANY_FAILURE } from 'v2/spas/account/companies/redux/action-types.tsx'
import { UPDATE_COMPANY_REQUEST } from 'v2/spas/account/companies/redux/action-types.tsx'
import { UPDATE_COMPANY_SUCCESS } from 'v2/spas/account/companies/redux/action-types.tsx'
import { UPDATE_COMPANY_FAILURE } from 'v2/spas/account/companies/redux/action-types.tsx'
import { DELETE_COMPANY_REQUEST } from 'v2/spas/account/companies/redux/action-types.tsx'
import { DELETE_COMPANY_SUCCESS } from 'v2/spas/account/companies/redux/action-types.tsx'
import { DELETE_COMPANY_FAILURE } from 'v2/spas/account/companies/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  account: {},
  companies: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_COMPANIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_COMPANIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_COMPANIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        account: {
          $set: action.payload.account,
        },
        companies: {
          $set: action.payload.account.companies
        },
      })
    }

    case UPDATE_COMPANY_REQUEST: {
      return state
    }

    case UPDATE_COMPANY_FAILURE: {
      return state
    }

    case UPDATE_COMPANY_SUCCESS: {
      let index = state.companies.findIndex(
        x => x.id === action.payload.company.id,
      )

      return update(state, {
        companies: {
          [index]: { $set: action.payload.company },
        },
      })
    }

    case ARCHIVE_COMPANY_REQUEST: {
      return state
    }

    case ARCHIVE_COMPANY_FAILURE: {
      return state
    }

    case ARCHIVE_COMPANY_SUCCESS: {
      let index = state.companies.findIndex(x => x.id === action.payload.company.id)

      return update(state, {
        companies: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_COMPANY_REQUEST: {
      return state
    }
    
    case DELETE_COMPANY_FAILURE: {
      return state
    }
    
    case DELETE_COMPANY_SUCCESS: {
      let index = state.companies.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        companies: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
