import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_REQUEST } from 'v2/spas/owned-property/owned-property/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_SUCCESS } from 'v2/spas/owned-property/owned-property/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_FAILURE } from 'v2/spas/owned-property/owned-property/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_REQUEST } from 'v2/spas/owned-property/owned-property/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_SUCCESS } from 'v2/spas/owned-property/owned-property/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_FAILURE } from 'v2/spas/owned-property/owned-property/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        }
      })
    }

    case UPDATE_OWNED_PROPERTY_REQUEST: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_FAILURE: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_SUCCESS: {

      return update(state, {
        ownedProperty: {
          $set: action.payload.ownedProperty,
        }
      })
    }

    default:
      return state
  }
}