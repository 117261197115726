import update from 'immutability-helper'
import {FETCH_PRIVATE_INVESTORS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PRIVATE_INVESTORS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PRIVATE_INVESTORS_FAILURE} from 'redux/actionTypes.js'
import {ADD_PRIVATE_INVESTOR_TO_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {ADD_PRIVATE_INVESTOR_TO_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {ADD_PRIVATE_INVESTOR_TO_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchPrivateInvestorsStatus: 'requested',
  fetchPrivateInvestorsError: '',
  privateInvestors: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRIVATE_INVESTORS_REQUEST: {
      return update(state, {
        fetchPrivateInvestorsStatus: {$set: 'requested'},
      })
    }

    case FETCH_PRIVATE_INVESTORS_FAILURE: {
      return update(state, {
        fetchPrivateInvestorsStatus: {
          $set: 'error',
        },
        fetchPrivateInvestorsError: {$set: action.payload.error},
      })
    }

    case FETCH_PRIVATE_INVESTORS_SUCCESS: {
      return update(state, {
        fetchPrivateInvestorsStatus: {$set: 'success'},
        privateInvestors: {
          $set: action.payload.privateInvestors,
        },
      })
    }

    case ADD_PRIVATE_INVESTOR_TO_ACCOUNT_REQUEST: {
      return state
    }

    case ADD_PRIVATE_INVESTOR_TO_ACCOUNT_FAILURE: {
      return state
    }

    case ADD_PRIVATE_INVESTOR_TO_ACCOUNT_SUCCESS: {
      return update(state, {
        privateInvestors: {
          $push: [action.payload.privateInvestor],
        },
      })
    }

    case REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_REQUEST: {
      return state
    }

    case REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_FAILURE: {
      return state
    }

    case REMOVE_PRIVATE_INVESTOR_FROM_ACCOUNT_SUCCESS: {
      return update(state, {
        privateInvestors: {
          $set: action.payload.account.privateInvestors,
        },
      })
    }

    default:
      return state
  }
}
