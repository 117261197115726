import update from 'immutability-helper'
import { FETCH_WORKFLOWS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_WORKFLOWS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_WORKFLOWS_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchWorkflowsStatus: 'requested',
  fetchWorkflowsError: '',
  workflows: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKFLOWS_REQUEST: {
      return update(state, {
        fetchWorkflowsStatus: { $set: 'requested' },
      })
    }

    case FETCH_WORKFLOWS_FAILURE: {
      return update(state, {
        fetchWorkflowsStatus: {
          $set: 'error',
        },
        fetchWorkflowsError: { $set: action.payload.error },
      })
    }

    case FETCH_WORKFLOWS_SUCCESS: {
      return update(state, {
        fetchWorkflowsStatus: { $set: 'success' },
        workflows: {
          $set: action.payload.workflows,
        },
      })
    }

    default:
      return state
  }
}
