import React from 'react'
import {Formik, Form} from 'formik'
import {connect} from 'react-redux'
import {useSnackbar} from 'notistack'
import styled from 'styled-components'
import * as Yup from 'yup'
import {updateAvatar} from 'redux/actions/profile/updateAvatar'
import {useCurrentAccountUser} from 'sharedComponents/useCurrentAccountUser.js'
import {SubmitButton} from 'buttons'
import FileField from 'sharedComponents/Forms/FileField.js'

const Wrapper = styled.div`
  #text-input {
    padding: 0px 25px;
  }

  #submit-button {
    #button {
      background: ${props => props.theme.colors.primary};
    }
  }
`

const FILE_SIZE = 25_000_000
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const FormSchema = Yup.object().shape({
  avatar: Yup.mixed()
    .required()
    .test('fileType', 'Unsupported File Format', value =>
      SUPPORTED_FORMATS.includes(value.type),
    )
    .test(
      'fileSize',
      'File too large',
      value => value && value.size <= FILE_SIZE,
    ),
})

function AvatarForm(props) {
  const {enqueueSnackbar} = useSnackbar()
  const currentAccountUser = useCurrentAccountUser()

  function handleUpdate(response) {
    if (response.status === 'success') {
      props.showModal(false)
      enqueueSnackbar('Avatar updated successfully!')
    } else if (response.status === 'error') {
      props.showModal(false)
      enqueueSnackbar(response.error, {variant: 'error'})
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          avatar: {},
        }}
        validationSchema={FormSchema}
        onSubmit={values => {
          let formData = new FormData()
          formData.append('avatar', values.avatar)
          props.updateAvatar(currentAccountUser.id, formData, handleUpdate)
        }}
      >
        {({isSubmitting, setFieldValue, values}) => (
          <Form>
            <FileField
              label="Avatar"
              name="avatar"
              setFieldValue={setFieldValue}
              values={values}
              fileName={values.avatar.name}
              formats="* Must be either jpg, jpeg, gif or png."
              sizes="* Max file size 25MB."
            />
            <SubmitButton
              content="Update Profile Picture"
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  updateAvatar: updateAvatar,
}

export default connect(null, mapDispatchToProps)(AvatarForm)
