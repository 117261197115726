export const FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_REQUEST = 'FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_REQUEST'
export const FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_SUCCESS = 'FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_SUCCESS'
export const FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_FAILURE = 'FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_FAILURE'

export const FETCH_BRIDGING_LOAN_PRODUCT_REQUEST = 'FETCH_BRIDGING_LOAN_PRODUCT_REQUEST'
export const FETCH_BRIDGING_LOAN_PRODUCT_SUCCESS = 'FETCH_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const FETCH_BRIDGING_LOAN_PRODUCT_FAILURE = 'FETCH_BRIDGING_LOAN_PRODUCT_FAILURE'

export const CREATE_BRIDGING_LOAN_PRODUCT_REQUEST = 'CREATE_BRIDGING_LOAN_PRODUCT_REQUEST'
export const CREATE_BRIDGING_LOAN_PRODUCT_SUCCESS = 'CREATE_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const CREATE_BRIDGING_LOAN_PRODUCT_FAILURE = 'CREATE_BRIDGING_LOAN_PRODUCT_FAILURE'

export const UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST = 'UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST'
export const UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS = 'UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE = 'UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE'

export const ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST = 'ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST'
export const ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS = 'ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE = 'ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE'

export const UNARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST = 'UNARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST'
export const UNARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS = 'UNARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const UNARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE = 'UNARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE'

export const DELETE_BRIDGING_LOAN_PRODUCT_REQUEST = 'DELETE_BRIDGING_LOAN_PRODUCT_REQUEST'
export const DELETE_BRIDGING_LOAN_PRODUCT_SUCCESS = 'DELETE_BRIDGING_LOAN_PRODUCT_SUCCESS'
export const DELETE_BRIDGING_LOAN_PRODUCT_FAILURE = 'DELETE_BRIDGING_LOAN_PRODUCT_FAILURE'