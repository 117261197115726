import React from 'react'
import styled from 'styled-components'
import { Mortarboard } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import { useCurrentAccount } from 'sharedComponents/useCurrentAccount'

const IconWrapper = styled.div`
  height: 100%;
  justify-content: flex-end;

  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  padding-right: 25px;
  cursor: pointer;

	a {
		text-decoration: none;
		color: white;
	}

  a:hover {
    text-decoration: none;
		color: white;
  }
`

function AcademyIcon(props) {

  const currentAccount = useCurrentAccount()

  let academy = currentAccount.academy || {}

  return (
    <IconWrapper aria-haspopup="true">
      <Link to={`/academies/${academy.id}`}>
        <Mortarboard size={22.5} />
      </Link>
    </IconWrapper>
  )
}

export default AcademyIcon
