import React from 'react'
import styled from 'styled-components'
import NavbarLink from 'sharedComponents/NavbarLink.js'
import {useDevice} from 'sharedComponents/useDevice.js'
import MobileSidebar from 'sharedComponents/MobileSidebar/MobileSidebar.js'
import PropTypes from 'prop-types'
import { Button } from 'buttons'
import {Link} from 'react-router-dom'
import { useCurrentAccountUser } from 'sharedComponents/useCurrentAccountUser'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    height: 100%;
    color: ${props => props.navbarTheme === 'white' ? props.theme.colors.green : 'white'};
    cursor: pointer;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

const RegisterButton = styled.div``

function Links(props) {
  const device = useDevice()
  const currentAccountUser = useCurrentAccountUser()

  let tenant = currentAccountUser.tenant || {}

  let textColour

  if (props.navbarTheme === 'white') {
    textColour = props => props.theme.colors.grey
  } else {
    textColour = props => props.theme.colors.white
  }

  let activeBarColour

  if (props.navbarTheme === 'white') {
    activeBarColour = props => props.theme.colors.green
  } else {
    activeBarColour = props => props.theme.colors.white
  }

  let registerButtonBackgroundColor

  if (props.navbarTheme === 'white') {
    registerButtonBackgroundColor = props => props.theme.colors.green
  } else {
    registerButtonBackgroundColor = props => props.theme.colors.white
  }

  let registerButtonColor

  if (props.navbarTheme === 'white') {
    registerButtonColor = props => props.theme.colors.white
  } else {
    registerButtonColor = props => props.theme.colors.green
  }

  let links

  if (device === 'mobile' || device === 'tablet' || device === 'laptop') {
    links = <MobileSidebar navbarTheme={props.navbarTheme} />
  } else if (window.location.pathname === '/mastermind-sign-up') {
    links = <LinksWrapper></LinksWrapper>
  } else if (tenant.id) {
    links = <LinksWrapper></LinksWrapper>
  } else {
    links = (
      <LinksWrapper>
        {/* <NavbarLink
          text="How it works"
          textColour={textColour}
          activeBarColour={activeBarColour}
          to="/how-it-works"
        /> */}
        {/* <NavbarLink
          text="About"
          textColour={textColour}
          activeBarColour={activeBarColour}
          to="/about-us"
        /> */}
        {/* <NavbarLink
          text="Pricing"
          textColour={textColour}
          activeBarColour={activeBarColour}
          to="/pricing"
        /> */}
        {/* <NavbarLink
          text="Platform updates"
          textColour={textColour}
          activeBarColour={activeBarColour}
          to="/platform-updates"
        /> */}
        {/* <NavbarLink
          text="Community"
          textColour={textColour}
          activeBarColour={activeBarColour}
          to="/community"
        /> */}
        <NavbarLink
          text="Sign in"
          textColour={textColour}
          activeBarColour={activeBarColour}
          to="/login"
        />
        <RegisterButton>
          <Link to="/sign-up">
            <Button
              content="Try it FREE"
              backgroundColor={registerButtonBackgroundColor}
              color={registerButtonColor}
              size={props => props.theme.space.s}
            />
          </Link>
        </RegisterButton>
      </LinksWrapper>
    )
  }

  return (
    <Wrapper id="public-top-navbar-links" navbarTheme={props.navbarTheme}>
      {links}
    </Wrapper>
  )
}

export default Links

Links.propTypes = {
  navbarTheme: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}
