import update from 'immutability-helper'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_NEW_PROPERTIES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_NEW_PROPERTIES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_NEW_PROPERTIES_FAILURE} from 'redux/actionTypes.js'
import {ADD_NEW_SINGLE_LET_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {ADD_NEW_SINGLE_LET_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {ADD_NEW_SINGLE_LET_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_NEW_SINGLE_LET_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_NEW_SINGLE_LET_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_NEW_SINGLE_LET_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {DELETE_NEW_SINGLE_LET_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {DELETE_NEW_SINGLE_LET_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_NEW_SINGLE_LET_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {ADD_NEW_HMO_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {ADD_NEW_HMO_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {ADD_NEW_HMO_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_NEW_HMO_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_NEW_HMO_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_NEW_HMO_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {DELETE_NEW_HMO_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {DELETE_NEW_HMO_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_NEW_HMO_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {ADD_NEW_FLIP_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {ADD_NEW_FLIP_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {ADD_NEW_FLIP_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_NEW_FLIP_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_NEW_FLIP_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_NEW_FLIP_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {DELETE_NEW_FLIP_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {DELETE_NEW_FLIP_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_NEW_FLIP_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_SUCCESS} from 'redux/actionTypes.js'
import {DUPLICATE_NEW_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {DUPLICATE_NEW_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {DUPLICATE_NEW_PROPERTY_FAILURE} from 'redux/actionTypes.js'

const initialState = {
  fetchCommercialToResidentialDealStatus: 'requested',
  fetchCommercialToResidentialDealError: '',
  commercialToResidentialDeal: {
    deal: {},
  },
  fetchCommercialToResidentialDealTemplatesStatus: 'requested',
  fetchCommercialToResidentialDealTemplatesError: '',
  dealTemplates: [],
  fetchCommercialToResidentialDealFixedPurchaseExpensesStatus: 'requested',
  fetchCommercialToResidentialDealFixedPurchaseExpensesError: '',
  fixedPurchaseExpenses: [],
  fetchCommercialToResidentialDealVariablePurchaseExpensesStatus: 'requested',
  fetchCommercialToResidentialDealVariablePurchaseExpensesError: '',
  variablePurchaseExpenses: [],
  fetchCommercialToResidentialDealDevelopmentExpensesStatus: 'requested',
  fetchCommercialToResidentialDealDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchCommercialToResidentialDealFixedRecurringExpensesStatus: 'requested',
  fetchCommercialToResidentialDealFixedRecurringExpensesError: '',
  fixedRecurringExpenses: [],
  fetchCommercialToResidentialDealVariableRecurringExpensesStatus: 'requested',
  fetchCommercialToResidentialDealVariableRecurringExpensesError: '',
  variableRecurringExpenses: [],
  fetchCommercialToResidentialDealDuringDevelopmentExpensesStatus: 'requested',
  fetchCommercialToResidentialDealDuringDevelopmentExpensesError: '',
  duringDevelopmentExpenses: [],
  fetchCommercialToResidentialDealMortgageStatus: 'requested',
  fetchCommercialToResidentialDealMortgageError: '',
  mortgage: {
    prospectiveMortgage: {},
  },
  fetchCommercialToResidentialDealRemortgageStatus: 'requested',
  fetchCommercialToResidentialDealRemortgageError: '',
  remortgage: {
    prospectiveMortgage: {},
  },
  fetchCommercialToResidentialDealCashFlowSummaryStatus: 'requested',
  fetchCommercialToResidentialDealCashFlowSummaryError: '',
  cashFlowSummary: {
    schedule: [],
  },
  fetchCommercialToResidentialDealBridgingLoanStatus: 'requested',
  fetchCommercialToResidentialDealBridgingLoanError: '',
  bridgingLoan: {},
  fetchNewPropertiesStatus: 'requested',
  fetchNewPropertiesError: '',
  newProperties: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealError: {$set: action.payload.error},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealStatus: {$set: 'success'},
        commercialToResidentialDeal: {
          $set: action.payload.commercialToResidentialDeal,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealTemplatesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealTemplatesStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealTemplatesError: {$set: action.payload.error},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealTemplatesStatus: {$set: 'success'},
        dealTemplates: {
          $set: action.payload.dealTemplates,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealFixedPurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealFixedPurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealFixedPurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealFixedPurchaseExpensesStatus: {$set: 'success'},
        fixedPurchaseExpenses: {
          $set: action.payload.fixedPurchaseExpenses,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealVariablePurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealVariablePurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealVariablePurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealVariablePurchaseExpensesStatus: {$set: 'success'},
        variablePurchaseExpenses: {
          $set: action.payload.variablePurchaseExpenses,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealDevelopmentExpensesStatus: {$set: 'success'},
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealFixedRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealFixedRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealFixedRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealFixedRecurringExpensesStatus: {$set: 'success'},
        fixedRecurringExpenses: {
          $set: action.payload.fixedRecurringExpenses,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealVariableRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealVariableRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealVariableRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealVariableRecurringExpensesStatus: {$set: 'success'},
        variableRecurringExpenses: {
          $set: action.payload.variableRecurringExpenses,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealDuringDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealDuringDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealDuringDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealDuringDevelopmentExpensesStatus: {$set: 'success'},
        duringDevelopmentExpenses: {
          $set: action.payload.duringDevelopmentExpenses,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealMortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealMortgageStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealMortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_MORTGAGE_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealMortgageStatus: {$set: 'success'},
        mortgage: {
          $set: action.payload.mortgage,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealRemortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealRemortgageStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealRemortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_REMORTGAGE_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealRemortgageStatus: {$set: 'success'},
        remortgage: {
          $set: action.payload.remortgage,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealCashFlowSummaryStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealCashFlowSummaryStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealCashFlowSummaryError: {$set: action.payload.error},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealCashFlowSummaryStatus: {$set: 'success'},
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_EXPENSES_SUCCESS: {
      let bridgingLoan = action.payload.commercialToResidentialDeal.bridgingLoan || {}
      let prospectiveBridgingLoan = bridgingLoan.prospectiveBridgingLoan || {}

      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          totalProfit: { $set: action.payload.commercialToResidentialDeal.totalProfit },
          totalProjectCost: { $set: action.payload.commercialToResidentialDeal.totalProjectCost },
          developmentCosts: {
            $set: action.payload.commercialToResidentialDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.commercialToResidentialDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.commercialToResidentialDeal.bridgingLoan,
        },
      })
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          developmentCosts: {
            $set: action.payload.commercialToResidentialDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.commercialToResidentialDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.commercialToResidentialDeal.mortgage,
        },
      })
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          returnOnInvestment: { $set: action.payload.commercialToResidentialDeal.returnOnInvestment },
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          endValue: {
            $set: action.payload.commercialToResidentialDeal.endValue,
          },
        },
        remortgage: {
          $set: action.payload.commercialToResidentialDeal.remortgage,
        },
      })
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.commercialToResidentialDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.commercialToResidentialDeal.mortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.commercialToResidentialDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          prospectiveMortgage: {
            term: {
              $set:
                action.payload.commercialToResidentialDeal.mortgage.prospectiveMortgage.term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.commercialToResidentialDeal.mortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.commercialToResidentialDeal.mortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.commercialToResidentialDeal.mortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.commercialToResidentialDeal.mortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.commercialToResidentialDeal.mortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.commercialToResidentialDeal.remortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          prospectiveMortgage: {
            term: {
              $set:
                action.payload.commercialToResidentialDeal.remortgage.prospectiveMortgage
                  .term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.commercialToResidentialDeal.remortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.commercialToResidentialDeal.remortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.commercialToResidentialDeal.remortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.commercialToResidentialDeal.remortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.commercialToResidentialDeal.remortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return state
    }

    case RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return state
    }

    case RECOMPILE_COMMERCIAL_TO_RESIDENTIAL_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseType: {$set: action.payload.commercialToResidentialDeal.purchaseType},
          purchasePrice: {$set: action.payload.commercialToResidentialDeal.purchasePrice},
          totalProfit: { $set: action.payload.commercialToResidentialDeal.totalProfit },
          totalProjectCost: { $set: action.payload.commercialToResidentialDeal.totalProjectCost },
          entryFinanceValue: { $set: action.payload.commercialToResidentialDeal.entryFinanceValue },
          exitFinanceValue: { $set: action.payload.commercialToResidentialDeal.exitFinanceValue },
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.commercialToResidentialDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyRent: {$set: action.payload.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          stampDuty: { $set: action.payload.commercialToResidentialDeal.stampDuty },
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          purchaseTerm: {$set: action.payload.commercialToResidentialDeal.purchaseTerm},
          refurbTerm: {$set: action.payload.commercialToResidentialDeal.refurbTerm},
          refinanceTerm: {$set: action.payload.commercialToResidentialDeal.refinanceTerm},
        },
        mortgage: {
          $set: action.payload.commercialToResidentialDeal.mortgage,
        },
        remortgage: {
          $set: action.payload.commercialToResidentialDeal.remortgage,
        },
        bridgingLoan: {
          $set: action.payload.commercialToResidentialDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          deal: {
            name: {
              $set: action.payload.deal.name,
            },
          },
        },
      })
    }

    case CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case CLEAR_EXPENSES_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.commercialToResidentialDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          mortgage: {
            $set: action.payload.commercialToResidentialDeal.mortgage,
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.commercialToResidentialDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.commercialToResidentialDeal.bridgingLoan,
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealBridgingLoanStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealBridgingLoanStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealBridgingLoanError: {$set: action.payload.error},
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealBridgingLoanStatus: {$set: 'success'},
        bridgingLoan: {
          $set: action.payload.bridgingLoan,
        },
      })
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.commercialToResidentialDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.commercialToResidentialDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          purchaseCosts: {$set: action.payload.commercialToResidentialDeal.purchaseCosts},
          cashRequired: {$set: action.payload.commercialToResidentialDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.commercialToResidentialDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.commercialToResidentialDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          prospectiveBridgingLoan: {
            term: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.term,
            },
            interestRate: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.interestRate,
            },
            repaymentType: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.repaymentType,
            },
            netAmount: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.netAmount,
            },
            interestAmount: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.interestAmount,
            },
            rebateAmount: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.rebateAmount,
            },
            monthlyPayment: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.monthlyPayment,
            },
            developmentFinanceBuffer: {
              $set:
                action.payload.commercialToResidentialDeal.bridgingLoan
                  .prospectiveBridgingLoan.developmentFinanceBuffer,
            },
          },
        },
      })
    }

    case FETCH_NEW_PROPERTIES_REQUEST: {
      return update(state, {
        fetchNewPropertiesStatus: {$set: 'requested'},
      })
    }

    case FETCH_NEW_PROPERTIES_FAILURE: {
      return update(state, {
        fetchNewPropertiesStatus: {
          $set: 'error',
        },
        fetchNewPropertiesError: {$set: action.payload.error},
      })
    }

    case FETCH_NEW_PROPERTIES_SUCCESS: {
      return update(state, {
        fetchNewPropertiesStatus: {$set: 'success'},
        newProperties: {
          $set: action.payload.newProperties,
        },
      })
    }

    case ADD_NEW_SINGLE_LET_PROPERTY_REQUEST: {
      return state
    }

    case ADD_NEW_SINGLE_LET_PROPERTY_FAILURE: {
      return state
    }

    case ADD_NEW_SINGLE_LET_PROPERTY_SUCCESS: {
      return update(state, {
        newProperties: {
          $push: [action.payload.newSingleLetProperty.newProperty],
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.moneyLeftIn},
          cashRecycled: {$set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.newSingleLetProperty.newProperty.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case UPDATE_NEW_SINGLE_LET_PROPERTY_REQUEST: {
      return state
    }

    case UPDATE_NEW_SINGLE_LET_PROPERTY_FAILURE: {
      return state
    }

    case UPDATE_NEW_SINGLE_LET_PROPERTY_SUCCESS: {
      let index = state.newProperties.findIndex(
        x => x.id === action.payload.newProperty.id,
      )

      return update(state, {
        newProperties: {
          [index]: {$set: action.payload.newProperty},
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.newProperty.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.newProperty.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.newProperty.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.newProperty.commercialToResidentialDeal.moneyLeftIn},
          cashRecycled: {$set: action.payload.newProperty.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.newProperty.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.newProperty.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.newProperty.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case DELETE_NEW_SINGLE_LET_PROPERTY_REQUEST: {
      return state
    }

    case DELETE_NEW_SINGLE_LET_PROPERTY_FAILURE: {
      return state
    }

    case DELETE_NEW_SINGLE_LET_PROPERTY_SUCCESS: {
      return update(state, {
        newProperties: {
          $set: action.payload.commercialToResidentialDeal.newProperties
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          saleProceeds: {$set: action.payload.commercialToResidentialDeal.saleProceeds},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case ADD_NEW_HMO_PROPERTY_REQUEST: {
      return state
    }

    case ADD_NEW_HMO_PROPERTY_FAILURE: {
      return state
    }

    case ADD_NEW_HMO_PROPERTY_SUCCESS: {
      return update(state, {
        newProperties: {
          $push: [action.payload.newHmoProperty.newProperty],
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.moneyLeftIn},
          saleProceeds: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.saleProceeds},
          cashRecycled: {$set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.newHmoProperty.newProperty.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case UPDATE_NEW_HMO_PROPERTY_REQUEST: {
      return state
    }

    case UPDATE_NEW_HMO_PROPERTY_FAILURE: {
      return state
    }

    case UPDATE_NEW_HMO_PROPERTY_SUCCESS: {
      let index = state.newProperties.findIndex(
        x => x.id === action.payload.newProperty.id,
      )

      return update(state, {
        newProperties: {
          [index]: {$set: action.payload.newProperty},
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.newProperty.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.newProperty.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.newProperty.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.newProperty.commercialToResidentialDeal.moneyLeftIn},
          cashRecycled: {$set: action.payload.newProperty.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.newProperty.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.newProperty.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.newProperty.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case DELETE_NEW_HMO_PROPERTY_REQUEST: {
      return state
    }

    case DELETE_NEW_HMO_PROPERTY_FAILURE: {
      return state
    }

    case DELETE_NEW_HMO_PROPERTY_SUCCESS: {
      return update(state, {
        newProperties: {
          $set: action.payload.commercialToResidentialDeal.newProperties
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          saleProceeds: {$set: action.payload.commercialToResidentialDeal.saleProceeds},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case ADD_NEW_FLIP_PROPERTY_REQUEST: {
      return state
    }

    case ADD_NEW_FLIP_PROPERTY_FAILURE: {
      return state
    }

    case ADD_NEW_FLIP_PROPERTY_SUCCESS: {
      return update(state, {
        newProperties: {
          $push: [action.payload.newFlipProperty.newProperty],
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.moneyLeftIn},
          saleProceeds: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.saleProceeds},
          cashRecycled: {$set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.newFlipProperty.newProperty.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case UPDATE_NEW_FLIP_PROPERTY_REQUEST: {
      return state
    }

    case UPDATE_NEW_FLIP_PROPERTY_FAILURE: {
      return state
    }

    case UPDATE_NEW_FLIP_PROPERTY_SUCCESS: {
      let index = state.newProperties.findIndex(
        x => x.id === action.payload.newProperty.id,
      )

      return update(state, {
        newProperties: {
          [index]: {$set: action.payload.newProperty},
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.newProperty.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.newProperty.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.newProperty.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.newProperty.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.newProperty.commercialToResidentialDeal.moneyLeftIn},
          saleProceeds: {$set: action.payload.newProperty.commercialToResidentialDeal.saleProceeds},
          cashRecycled: {$set: action.payload.newProperty.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.newProperty.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.newProperty.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.newProperty.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case DELETE_NEW_FLIP_PROPERTY_REQUEST: {
      return state
    }

    case DELETE_NEW_FLIP_PROPERTY_FAILURE: {
      return state
    }

    case DELETE_NEW_FLIP_PROPERTY_SUCCESS: {
      return update(state, {
        newProperties: {
          $set: action.payload.commercialToResidentialDeal.newProperties
        },
        commercialToResidentialDeal: {
          monthlyRent: {$set: action.payload.commercialToResidentialDeal.monthlyRent},
          monthlyExpenses: {$set: action.payload.commercialToResidentialDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.commercialToResidentialDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.commercialToResidentialDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.commercialToResidentialDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.commercialToResidentialDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.commercialToResidentialDeal.moneyLeftIn},
          saleProceeds: {$set: action.payload.commercialToResidentialDeal.saleProceeds},
          cashRecycled: {$set: action.payload.commercialToResidentialDeal.cashRecycled},
          projectedReturnOnInvestment: {
            $set: action.payload.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
          buyAndHold: { $set: action.payload.commercialToResidentialDeal.buyAndHold },
          flip: { $set: action.payload.commercialToResidentialDeal.flip },
          totalProfit: { $set: action.payload.commercialToResidentialDeal.totalProfit },
        },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_REQUEST: {
      return update(state, {
        fetchCommercialToResidentialDealBridgingLoanStatus: { $set: 'requested' },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_FAILURE: {
      return update(state, {
        fetchCommercialToResidentialDealBridgingLoanStatus: {
          $set: 'error',
        },
        fetchCommercialToResidentialDealBridgingLoanError: { $set: action.payload.error },
      })
    }

    case FETCH_COMMERCIAL_TO_RESIDENTIAL_DEAL_BRIDGING_LOAN_SUCCESS: {
      return update(state, {
        fetchCommercialToResidentialDealBridgingLoanStatus: { $set: 'success' },
        bridgingLoan: {
          $set: action.payload.bridgingLoan,
        },
      })
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_REQUEST: {
      return state
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_FAILURE: {
      return state
    }

    case UPDATE_COMMERCIAL_TO_RESIDENTIAL_SUPPORT_NEEDED_SUCCESS: {
      return update(state, {
        commercialToResidentialDeal: {
          deal: {
            stackingSupportNeeded: {
              $set: action.payload.stackingSupportNeeded
            }
          },
        },
      })
    }

    case DUPLICATE_NEW_PROPERTY_REQUEST: {
      return state
    }

    case DUPLICATE_NEW_PROPERTY_FAILURE: {
      return state
    }

    case DUPLICATE_NEW_PROPERTY_SUCCESS: {
      let newProperty = action.payload.newProperty.newSingleLetProperty || action.payload.newProperty.newHmoProperty || action.payload.newProperty.newFlipProperty

      return update(state, {
        newProperties: {
          $push: [action.payload.newProperty],
        },
        commercialToResidentialDeal: {
          monthlyRent: { $set: action.payload.newProperty.commercialToResidentialDeal.monthlyRent },
          monthlyExpenses: { $set: action.payload.newProperty.commercialToResidentialDeal.monthlyExpenses },
          monthlyCashFlow: { $set: action.payload.newProperty.commercialToResidentialDeal.monthlyCashFlow },
          annualProfit: { $set: action.payload.newProperty.commercialToResidentialDeal.annualProfit },
          returnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.returnOnInvestment,
          },
          endValue: { $set: action.payload.newProperty.commercialToResidentialDeal.endValue },
          projectedMonthlyCashFlow: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedAnnualProfit,
          },
          equityReleased: { $set: action.payload.newProperty.commercialToResidentialDeal.equityReleased },
          moneyLeftIn: { $set: action.payload.newProperty.commercialToResidentialDeal.moneyLeftIn },
          saleProceeds: { $set: action.payload.newProperty.commercialToResidentialDeal.saleProceeds },
          cashRecycled: { $set: action.payload.newProperty.commercialToResidentialDeal.cashRecycled },
          projectedReturnOnInvestment: {
            $set: action.payload.newProperty.commercialToResidentialDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    default:
      return state
  }
}
