import React, {useEffect, useState, useMemo} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {fetchSubscriptionProducts} from 'redux/actions/fetchSubscriptionProducts'
import PricingCard from 'sharedComponents/Pricing/PricingCard'
import PricingInterval from 'sharedComponents/Pricing/PricingInterval'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: ${props => props.theme.space.l};
  padding-right: ${props => props.theme.space.l};
  padding-top: ${props => props.theme.space.xl};
  padding-bottom: ${props => props.theme.space.xl};

  h1 {
    text-align: center;
    padding-top: ${props => props.theme.space.xl};
    padding-bottom: ${props => props.theme.space.m};
  }

  h2 {
    text-align: center;
    padding-bottom: ${props => props.theme.space.m}
  }

  p {
    color: ${props => props.theme.colors.grey};
    padding-top: ${props => props.theme.space.m};
    font-weight: bold;
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: ${props => props.theme.space.xxl};
    padding-right: ${props => props.theme.space.xxl};
  }
`

const PricingWrapper = styled.div`
  width: 100%;

  @media ${props => props.theme.devices.desktop} {
    display: flex;

    padding-left: 25%;
    padding-right: 25%;
  }
`

function PricingContent(props) {
  const [subscriptionTerm, setSubscriptionTerm] = useState('month')

  useEffect(() => {
    props.fetchSubscriptionProducts()
  }, [])

  let renderSubscriptionProducts = props.subscriptionProducts.filter(({ interval }) => interval === subscriptionTerm).map(function(subscriptionProduct) {
    return(
      <PricingCard  
        key={subscriptionProduct.id}
        subscriptionProduct={subscriptionProduct}
      />
    )
  })

  return (
    <Wrapper>
      <h1>
        Join 2,500+ property investors
      </h1>
      <h2>
        Using Stacked to manage
      </h2>
      <h2>
        £500,000,000+ worth of property
      </h2>

      <PricingInterval interval={subscriptionTerm} handleOnClickInterval={setSubscriptionTerm} />
      
      <PricingWrapper>
        {renderSubscriptionProducts}
      </PricingWrapper>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  subscriptionProducts: state.subscriptionProducts.subscriptionProducts,
})

const mapDispatchToProps = {
  fetchSubscriptionProducts: fetchSubscriptionProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingContent)