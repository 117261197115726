import update from 'immutability-helper'
import { FETCH_TENANCY_REQUEST } from 'v2/spas/unit/tenancies/redux/action-types.tsx'
import { FETCH_TENANCY_SUCCESS } from 'v2/spas/unit/tenancies/redux/action-types.tsx'
import { FETCH_TENANCY_FAILURE } from 'v2/spas/unit/tenancies/redux/action-types.tsx'
import { UPDATE_TENANCY_REQUEST } from 'v2/spas/unit/tenancies/redux/action-types.tsx'
import { UPDATE_TENANCY_SUCCESS } from 'v2/spas/unit/tenancies/redux/action-types.tsx'
import { UPDATE_TENANCY_FAILURE } from 'v2/spas/unit/tenancies/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  tenancy: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANCY_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_TENANCY_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_TENANCY_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        tenancy: {
          $set: action.payload.tenancy,
        }
      })
    }

    case UPDATE_TENANCY_REQUEST: {
      return update(state, {
        updateTenancyStatus: { $set: 'requested' },
      })
    }

    case UPDATE_TENANCY_FAILURE: {
      return update(state, {
        updateTenancyStatus: {
          $set: 'error',
        },
        updateTenancyError: { $set: action.payload.error },
      })
    }

    case UPDATE_TENANCY_SUCCESS: {
      return update(state, {
        updateTenancyStatus: { $set: 'success' },
        tenancy: {
          $set: action.payload.tenancy,
        }
      })
    }

    default:
      return state
  }
}