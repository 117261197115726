import update from 'immutability-helper'
import {LOAD_STACKED_REQUEST} from 'redux/actionTypes.js'
import {LOAD_STACKED_FAILURE} from 'redux/actionTypes.js'
import {LOAD_STACKED_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_EMAIL_IDENTITY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_EMAIL_IDENTITY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_POLICY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_POLICY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_POLICY_SUCCESS} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_REQUEST_REQUEST} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_REQUEST_FAILURE} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_REQUEST_SUCCESS} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_REQUEST} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_FAILURE} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_SUCCESS} from 'redux/actionTypes.js'
import {EMAIL_PERSONAL_DATA_REQUEST} from 'redux/actionTypes.js'
import {EMAIL_PERSONAL_DATA_FAILURE} from 'redux/actionTypes.js'
import {EMAIL_PERSONAL_DATA_SUCCESS} from 'redux/actionTypes.js'
import {FORGET_USER_REQUEST} from 'redux/actionTypes.js'
import {FORGET_USER_FAILURE} from 'redux/actionTypes.js'
import {FORGET_USER_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {WITHDRAW_USER_CONSENT_REQUEST} from 'redux/actionTypes.js'
import {WITHDRAW_USER_CONSENT_FAILURE} from 'redux/actionTypes.js'
import {WITHDRAW_USER_CONSENT_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_LOGO_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_LOGO_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_LOGO_SUCCESS} from 'redux/actionTypes.js'
import {GRANT_USER_CONSENT_REQUEST} from 'redux/actionTypes.js'
import {GRANT_USER_CONSENT_FAILURE} from 'redux/actionTypes.js'
import {GRANT_USER_CONSENT_SUCCESS} from 'redux/actionTypes.js'
import {SETUP_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {SETUP_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {SETUP_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {SET_PASSWORD_REQUEST} from 'redux/actionTypes.js'
import {SET_PASSWORD_FAILURE} from 'redux/actionTypes.js'
import {SET_PASSWORD_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PASSWORD_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PASSWORD_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PASSWORD_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_AVATAR_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_AVATAR_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_AVATAR_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_FAILURE} from 'redux/actionTypes.js'
import {SET_STATUS_BANNER} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST} from 'redux/actionTypes'
import {FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS} from 'redux/actionTypes'
import {FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE} from 'redux/actionTypes'
import {FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_FAILURE} from 'redux/actionTypes.js'
import {CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST} from 'redux/actionTypes.js'
import {CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE} from 'redux/actionTypes.js'
import {DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST} from 'redux/actionTypes.js'
import {DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE} from 'redux/actionTypes.js'
import {FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_PROPERTY_INVESTMENT_USER_PROFILE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PROPERTY_INVESTMENT_USER_PROFILE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PROPERTY_INVESTMENT_USER_PROFILE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_USER_PROFILE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_USER_PROFILE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_BROKER_USER_PROFILE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_USER_PROFILE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_USER_PROFILE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_BROKER_USER_PROFILE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  loadStackedStatus: 'requested',
  loadStackedError: '',
  fetchCurrentUserStatus: 'requested',
  fetchCurrentUserError: '',
  currentUser: {},
  fetchCurrentAccountUserStatus: 'requested',
  fetchCurrentAccountUserError: '',
  currentAccountUser: {},
  fetchCurrentEmailIdentityStatus: 'requested',
  fetchCurrentEmailIdentityError: '',
  currentEmailIdentity: {},
  fetchCurrentAccountStatus: 'requested',
  fetchCurrentAccountError: '',
  currentAccount: {},
  fetchCurrentUserPolicyStatus: 'requested',
  fetchCurrentUserPolicyError: '',
  policy: {},
  isStatusBannerShown: true,
  isChecklistSuspended: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_STACKED_REQUEST: {
      return update(state, {
        loadStackedStatus: {$set: 'requested'},
      });
    }

    case LOAD_STACKED_FAILURE: {
      return update(state, {
        loadStackedStatus: {
          $set: 'error',
        },
        loadStackedError: {$set: action.payload.error},
      });
    }

    case LOAD_STACKED_SUCCESS: {
      return update(state, {
        loadStackedStatus: {$set: 'success'},
        currentUser: {
          $set: action.payload.currentUser,
        },
        currentAccountUser: {
          $set: action.payload.currentUser.currentAccountUser,
        },
        currentAccount: {
          id: {$set: action.payload.currentUser.currentAccount.id},
          name: {$set: action.payload.currentUser.currentAccount.name},
          logoUrl: {$set: action.payload.currentUser.currentAccount.logoUrl},
          typeName: {$set: action.payload.currentUser.currentAccount.typeName},
          subscription: {$set: action.payload.currentUser.currentAccount.subscription},
          mortgageBrokerAccount: { $set: action.payload.currentUser.currentAccount.mortgageBrokerAccount },
          counters: { $set: action.payload.currentUser.currentAccount.counters },
          currencySymbol: { $set: action.payload.currentUser.currentAccount.currencySymbol },
          knowledgeBase: { $set: action.payload.currentUser.currentAccount.knowledgeBase },
          academy: { $set: action.payload.currentUser.currentAccount.academy },
          community: { $set: action.payload.currentUser.currentAccount.community },
        },
        currentEmailIdentity: {
          $set: action.payload.currentUser.emailIdentity,
        },
        policy: {
          $set: action.payload.currentUser.policy,
        },
      });
    }

    case FETCH_CURRENT_USER_REQUEST: {
      return update(state, {
        fetchCurrentUserStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_USER_FAILURE: {
      return update(state, {
        fetchCurrentUserStatus: {
          $set: 'error',
        },
        fetchCurrentUserError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_USER_SUCCESS: {
      return update(state, {
        fetchCurrentUserStatus: {$set: 'success'},
        currentUser: {
          $set: action.payload.currentUser,
        },
      });
    }

    case FETCH_CURRENT_EMAIL_IDENTITY_REQUEST: {
      return update(state, {
        fetchCurrentEmailIdentityStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_EMAIL_IDENTITY_FAILURE: {
      return update(state, {
        fetchCurrentEmailIdentityStatus: {
          $set: 'error',
        },
        fetchCurrentEmailIdentityError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS: {
      return update(state, {
        fetchCurrentEmailIdentityStatus: {$set: 'success'},
        currentEmailIdentity: {
          $set: action.payload.emailIdentity,
        },
      });
    }

    case FETCH_CURRENT_ACCOUNT_REQUEST: {
      return update(state, {
        fetchCurrentAccountStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_ACCOUNT_FAILURE: {
      return update(state, {
        fetchCurrentAccountStatus: {
          $set: 'error',
        },
        fetchCurrentAccountError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_ACCOUNT_SUCCESS: {
      return update(state, {
        fetchCurrentAccountStatus: {$set: 'success'},
        currentAccount: {
          $set: action.payload.currentAccount,
        },
      });
    }

    case FETCH_CURRENT_USER_POLICY_REQUEST: {
      return update(state, {
        fetchCurrentUserPolicyStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_USER_POLICY_FAILURE: {
      return update(state, {
        fetchCurrentUserPolicyStatus: {
          $set: 'error',
        },
        fetchCurrentUserPolicyError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_USER_POLICY_SUCCESS: {
      return update(state, {
        fetchCurrentUserPolicyStatus: {$set: 'success'},
        policy: {
          $set: action.payload.policy,
        },
      });
    }

    case UPDATE_ACCOUNT_REQUEST: {
      return state;
    }

    case UPDATE_ACCOUNT_FAILURE: {
      return state;
    }

    case UPDATE_ACCOUNT_SUCCESS: {
      return update(state, {
        currentAccount: {
          name: {$set: action.payload.account.name},
          currencySymbol: { $set: action.payload.account.currencySymbol }
        },
      });
    }

    case CHANGE_EMAIL_REQUEST_REQUEST: {
      return state;
    }

    case CHANGE_EMAIL_REQUEST_FAILURE: {
      return state;
    }

    case CHANGE_EMAIL_REQUEST_SUCCESS: {
      return state;
    }

    case CHANGE_EMAIL_REQUEST: {
      return state;
    }

    case CHANGE_EMAIL_FAILURE: {
      return state;
    }

    case CHANGE_EMAIL_SUCCESS: {
      return state;
    }

    case EMAIL_PERSONAL_DATA_REQUEST: {
      return state;
    }

    case EMAIL_PERSONAL_DATA_FAILURE: {
      return state;
    }

    case EMAIL_PERSONAL_DATA_SUCCESS: {
      return state;
    }

    case WITHDRAW_USER_CONSENT_REQUEST: {
      return state;
    }

    case WITHDRAW_USER_CONSENT_FAILURE: {
      return state;
    }

    case WITHDRAW_USER_CONSENT_SUCCESS: {
      return state;
    }

    case FORGET_USER_REQUEST: {
      return state;
    }

    case FORGET_USER_FAILURE: {
      return state;
    }

    case FORGET_USER_SUCCESS: {
      return state;
    }

    case UPDATE_LOGO_REQUEST: {
      return state;
    }

    case UPDATE_LOGO_FAILURE: {
      return state;
    }

    case UPDATE_LOGO_SUCCESS: {
      return update(state, {
        currentAccount: {
          logoUrl: {
            $set: action.payload.logoUrl,
          },
        },
      });
    }

    case SETUP_ACCOUNT_REQUEST: {
      return state;
    }

    case SETUP_ACCOUNT_FAILURE: {
      return state;
    }

    case SETUP_ACCOUNT_SUCCESS: {
      return state;
    }

    case SET_PASSWORD_REQUEST: {
      return state;
    }

    case SET_PASSWORD_FAILURE: {
      return state;
    }

    case SET_PASSWORD_SUCCESS: {
      return state;
    }

    case GRANT_USER_CONSENT_REQUEST: {
      return state;
    }

    case GRANT_USER_CONSENT_FAILURE: {
      return state;
    }

    case GRANT_USER_CONSENT_SUCCESS: {
      return state;
    }

    case UPDATE_PASSWORD_REQUEST: {
      return state;
    }

    case UPDATE_PASSWORD_FAILURE: {
      return state;
    }

    case UPDATE_PASSWORD_SUCCESS: {
      return state;
    }

    case UPDATE_AVATAR_REQUEST: {
      return state;
    }

    case UPDATE_AVATAR_FAILURE: {
      return state;
    }

    case UPDATE_AVATAR_SUCCESS: {
      return update(state, {
        currentAccountUser: {
          avatarUrl: {
            $set: action.payload.avatarUrl,
          },
        },
      });
    }

    case FETCH_MORTGAGE_BROKER_USER_PROFILE_REQUEST: {
      return state
    }

    case FETCH_MORTGAGE_BROKER_USER_PROFILE_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccountUser: {
            mortgageBrokerUser: {$set: action.payload.mortgageBrokerUser}
          },
        },
        currentAccountUser: {
          mortgageBrokerUser: {$set: action.payload.mortgageBrokerUser}
        }
      })
    }

    case FETCH_MORTGAGE_BROKER_USER_PROFILE_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_USER_PROFILE_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_USER_PROFILE_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccountUser: {
            mortgageBrokerUser: {
              mortgageBrokerUserProfile: {$set: action.payload.mortgageBrokerUserProfile}
            }
          }
        },
        currentAccountUser: {
          mortgageBrokerUser: {
            mortgageBrokerUserProfile: {$set: action.payload.mortgageBrokerUserProfile}
          }
        }
      })
    }

    case UPDATE_MORTGAGE_BROKER_USER_PROFILE_FAILURE: {
      return state
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST: {
      return state
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccount: {
            mortgageBrokerAccount: {$set: action.payload.mortgageBrokerAccount}
          }
        },
        currentAccount: {
          mortgageBrokerAccount: {$set: action.payload.mortgageBrokerAccount}
        }
      })
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_PROFILE_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccount: {
            mortgageBrokerAccount: {
              mortgageBrokerAccountProfile: {$set: action.payload.mortgageBrokerAccountProfile},
            },
          },
        },
        currentAccount: {
          mortgageBrokerAccount: {
            mortgageBrokerAccountProfile: {$set: action.payload.mortgageBrokerAccountProfile},
          },
        },
      })
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_NAME_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccount: {
            id: {$set: action.payload.currentAccount.id},
            name: {$set: action.payload.currentAccount.name},
          },
        },
        currentAccount: {
          id: {$set: action.payload.currentAccount.id},
          name: {$set: action.payload.currentAccount.name},
        },
      })
    }

    case SET_STATUS_BANNER: {
      return update(state, { isStatusBannerShown: { $set: action.payload } })
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_REQUEST: {
      return state
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccount: {
            mortgageBrokerAccount: {
              mortgageBrokerAccountProfile: {
                specialisms: {$set: action.payload.mortgageBrokerAccountSpecialisms}
              }
            }
          },
        },
        currentAccount: {
          mortgageBrokerAccount: {
            mortgageBrokerAccountProfile: {
              specialisms: {$set: action.payload.mortgageBrokerAccountSpecialisms}
            }
          }
        }
      })
    }

    case FETCH_MORTGAGE_BROKER_ACCOUNT_SPECIALISMS_FAILURE: {
      return state
    }

    case CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST: {
      return state
    }

    case CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccount: {
            mortgageBrokerAccount: {
              mortgageBrokerAccountProfile: {
                specialisms: {$push: [action.payload.mortgageBrokerAccountSpecialism]}
              }
            }
          },
        },
        currentAccount: {
          mortgageBrokerAccount: {
            mortgageBrokerAccountProfile: {
              specialisms: {$push: [action.payload.mortgageBrokerAccountSpecialism]}
            }
          }
        }
      })
    }

    case CREATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS: {
      const index =
        state.currentAccount.mortgageBrokerAccount.mortgageBrokerAccountProfile.specialisms
        .findIndex(({id}) => id === action.payload.mortgageBrokerAccountSpecialism.id)
      return update(state, {
        currentUser: {
          currentAccount: {
            mortgageBrokerAccount: {
              mortgageBrokerAccountProfile: {
                specialisms: {
                  [index]: {$merge: action.payload.mortgageBrokerAccountSpecialism}
                }
              }
            }
          },
        },
        currentAccount: {
          mortgageBrokerAccount: {
            mortgageBrokerAccountProfile: {
              specialisms: {
                [index]: {$merge: action.payload.mortgageBrokerAccountSpecialism}
              }
            }
          }
        }
      })
    }

    case UPDATE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE: {
      return state
    }

    case DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_REQUEST: {
      return state
    }

    case DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_SUCCESS: {
      const specialismId = action.payload.specialismId
      const index = state.currentAccount.mortgageBrokerAccount.mortgageBrokerAccountProfile.specialisms.findIndex(({id}) => id === specialismId)
      return update(state, {
        currentUser: {
          currentAccount: {
            mortgageBrokerAccount: {
              mortgageBrokerAccountProfile: {
                specialisms: {$splice: [[index, 1]]}
              }
            }
          },
        },
        currentAccount: {
          mortgageBrokerAccount: {
            mortgageBrokerAccountProfile: {
              specialisms: {$splice: [[index, 1]]}
            }
          }
        }
      })
    }

    case DELETE_MORTGAGE_BROKER_ACCOUNT_SPECIALISM_FAILURE: {
      return state
    }

    case FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_REQUEST: {
      return state
    }

    case FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_SUCCESS: {
      return update(state, {
        currentAccount: {
          propertyInvestmentAccount: {$set: action.payload.propertyInvestmentAccount}
        }
      })
    }

    case FETCH_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_FAILURE: {
      return state
    }

    case UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_REQUEST: {
      return state
    }

    case UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_FAILURE: {
      return state
    }

    case UPDATE_PROPERTY_INVESTMENT_ACCOUNT_PROFILE_DETAILS_SUCCESS: {
      return update(state, {
        currentAccount: {
          propertyInvestmentAccount: {
            propertyInvestmentAccountProfile: {$set: action.payload.propertyInvestmentAccountProfile}
          }
        },
      })
    }

    case FETCH_PROPERTY_INVESTMENT_USER_PROFILE_REQUEST: {
      return state
    }

    case FETCH_PROPERTY_INVESTMENT_USER_PROFILE_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccountUser: {
            propertyInvestmentUser: {$set: action.payload.propertyInvestmentUser}
          }
        },
        currentAccountUser: {
          propertyInvestmentUser: {$set: action.payload.propertyInvestmentUser}
        }
      })
    }

    case FETCH_PROPERTY_INVESTMENT_USER_PROFILE_FAILURE: {
      return state
    }

    case UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_REQUEST: {
      return state
    }

    case UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_SUCCESS: {
      return update(state, {
        currentUser: {
          currentAccountUser: {
            propertyInvestmentUser: {
              propertyInvestorUserProfile: {$set: action.payload.propertyInvestmentUserProfile}
            }
          }
        },
        currentAccountUser: {
          propertyInvestmentUser: {
            propertyInvestorUserProfile: {$set: action.payload.propertyInvestmentUserProfile}
          }
        }
      })
    }

    case UPDATE_PROPERTY_INVESTMENT_USER_PROFILE_DETAILS_FAILURE: {
      return state
    }

    case UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_REQUEST: {
      return state
    }

    case UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_FAILURE: {
      return state
    }

    case UPDATE_ACCOUNT_USER_LAYOUT_PREFERENCE_SUCCESS: {
      return update(state, {
        currentAccountUser: {
          layoutPreferences: {
            $set: action.payload.currentAccountUser.layoutPreferences,
          }
        },
      });
    }

    default:
      return state;
  }
}
