import {combineReducers} from 'redux'
import stacked from './stacked'
// import prospectivePropertyDeals from './prospectivePropertyDeals'
import hmoDeal from './hmoDeal'
import singleLetDeal from './singleLetDeal'
import rentToRentDeal from './rentToRentDeal'
import rentToServicedAccommodationDeal from './rentToServicedAccommodationDeal'
import servicedAccommodationDeal from './servicedAccommodationDeal'
import investors from './investors'
import investorPack from './investorPack'
import mortgageLenders from './mortgageLenders'
import dealTemplate from './dealTemplate'
import profile from './profile'
import team from './team'
import authentication from './authentication'
// import ownedProperty from './ownedProperty'
import companies from './companies'
import company from './company'
import userDocuments from './userDocuments'
import existingMortgage from './existingMortgage'
import bridgingLenders from './bridgingLenders'
import flipDeal from './flipDeal'
import resourceCategories from './resourceCategories'
import resourceCategory from './resourceCategory'
import ownedPropertyFinancials from './ownedPropertyFinancials'
import privateInvestors from './privateInvestors'
// import prospectivePropertyWorkflows from './prospectivePropertyWorkflows'
import mortgageApplications from './mortgageApplications'
import contacts from './contacts'
import accountUser from './accountUser'
import tenancy from './tenancy'
import accountPartners from './accountPartners'
import prospectivePropertyFinance from './prospectivePropertyFinance'
import mortgageApplication from './mortgageApplication'
import commercialToResidentialDeal from './commercialToResidentialDeal'
import goals from './goals'
import calendar from './calendar'
import ownedPropertyManagement from './ownedPropertyManagement'
import subscriptionProducts from './subscriptionProducts'
// import ownedPropertyDeals from './ownedPropertyDeals'
import propertyPricePaidRecords from './propertyPricePaidRecords'
// import ownedPropertyWorkflows from './ownedPropertyWorkflows'
import tenancyWorkflows from './tenancyWorkflows'
import certificatePortfolioReport from './certificatePortfolioReport'
import tenancyPortfolioReport from './tenancyPortfolioReport'
import ownedPropertyAccountPortfolioReport from './ownedPropertyAccountPortfolioReport'
import mortgageAccountPortfolioReport from './mortgageAccountPortfolioReport'
import globalSearch from './globalSearch'
import portfolioOverviewReport from './portfolioOverviewReport'
import mortgageApplicants from './mortgageApplicants'
import mortgageApplicant from './mortgageApplicant'
import prospectivePropertyWorkflowReport from './prospectivePropertyWorkflowReport'
import ownedPropertyWorkflowReport from './ownedPropertyWorkflowReport'
import propertyDeals from './propertyDeals'
import accountUserWorkflows from './accountUserWorkflows'
import accountUserWorkflowReport from './accountUserWorkflowReport'
import articles from './articles'
import article from './article'
import workflows from './workflows'
import property from './property'
import prospectiveProperties from 'v2/spas/prospective-properties/redux/reducers/index.tsx'
import prospectiveProperty from 'v2/spas/prospective-property/prospective-property/redux/reducers/view.tsx'
import prospectivePropertyRentListings from 'v2/spas/prospective-property/rent-listings/redux/reducers/index.tsx'
import rentListing from 'v2/spas/prospective-property/rent-listings/redux/reducers/view.tsx'
import prospectivePropertySaleListings from 'v2/spas/prospective-property/sale-listings/redux/reducers/index.tsx'
import saleListing from 'v2/spas/prospective-property/sale-listings/redux/reducers/view.tsx'
import prospectivePropertyRentComparables from 'v2/spas/prospective-property/rent-comparables/redux/reducers/index.tsx'
import rentComparable from 'v2/spas/prospective-property/rent-comparables/redux/reducers/view.tsx'
import prospectivePropertyVideos from 'v2/spas/prospective-property/videos/redux/reducers/index.tsx'
import video from 'v2/spas/prospective-property/videos/redux/reducers/view.tsx'
import prospectivePropertyPhotos from 'v2/spas/prospective-property/photos/redux/reducers/index.tsx'
import photo from 'v2/spas/prospective-property/photos/redux/reducers/view.tsx'
import prospectivePropertyDocuments from 'v2/spas/prospective-property/documents/redux/reducers/index.tsx'
import document from 'v2/spas/prospective-property/documents/redux/reducers/view.tsx'
import prospectivePropertySaleComparables from 'v2/spas/prospective-property/sale-comparables/redux/reducers/index.tsx'
import saleComparable from 'v2/spas/prospective-property/sale-comparables/redux/reducers/view.tsx'
import prospectivePropertyPropertyOwners from 'v2/spas/prospective-property/property-owners/redux/reducers/index.tsx'
import propertyOwner from 'v2/spas/prospective-property/property-owners/redux/reducers/view.tsx'
import prospectivePropertyEnergyPerformanceCertificates from 'v2/spas/prospective-property/energy-performance-certificates/redux/reducers/index.tsx'
import energyPerformanceCertificate from 'v2/spas/prospective-property/energy-performance-certificates/redux/reducers/view.tsx'
import ownedPropertyUnits from 'v2/spas/owned-property/units/redux/reducers/index.tsx'
import unit from 'v2/spas/owned-property/units/redux/reducers/view.tsx'
import ownedPropertyAppliances from 'v2/spas/owned-property/appliances/redux/reducers/index.tsx'
import appliance from 'v2/spas/owned-property/appliances/redux/reducers/view.tsx'
import ownedProperties from 'v2/spas/owned-properties/redux/reducers/index.tsx'
import ownedProperty from 'v2/spas/owned-property/owned-property/redux/reducers/view.tsx'
import ownedPropertyPhotos from 'v2/spas/owned-property/photos/redux/reducers/index.tsx'
import ownedPropertyPhoto from 'v2/spas/owned-property/photos/redux/reducers/view.tsx'
import ownedPropertyVideos from 'v2/spas/owned-property/videos/redux/reducers/index.tsx'
import ownedPropertyVideo from 'v2/spas/owned-property/videos/redux/reducers/view.tsx'
import ownedPropertyCertificates from 'v2/spas/owned-property/certificates/redux/reducers/index.tsx'
import ownedPropertyCertificate from 'v2/spas/owned-property/certificates/redux/reducers/view.tsx'
import ownedPropertyExistingMortgages from 'v2/spas/owned-property/existing-mortgages/redux/reducers/index.tsx'
import ownedPropertyExistingMortgage from 'v2/spas/owned-property/existing-mortgages/redux/reducers/view.tsx'
import ownedPropertyPropertyOwners from 'v2/spas/owned-property/property-owners/redux/reducers/index.tsx'
import ownedPropertyPropertyOwner from 'v2/spas/owned-property/property-owners/redux/reducers/view.tsx'
import ownedPropertyEnergyPerformanceCertificates from 'v2/spas/owned-property/energy-performance-certificates/redux/reducers/index.tsx'
import ownedPropertyEnergyPerformanceCertificate from 'v2/spas/owned-property/energy-performance-certificates/redux/reducers/view.tsx'
import ownedPropertyLicences from 'v2/spas/owned-property/licences/redux/reducers/index.tsx'
import ownedPropertyLicence from 'v2/spas/owned-property/licences/redux/reducers/view.tsx'
import ownedPropertyAccounts from 'v2/spas/owned-property/owned-property-accounts/redux/reducers/index.tsx'
import ownedPropertyAccount from 'v2/spas/owned-property/owned-property-accounts/redux/reducers/view.tsx'
import ownedPropertySmokeAlarmTests from 'v2/spas/owned-property/smoke-alarm-tests/redux/reducers/index.tsx'
import ownedPropertySmokeAlarmTest from 'v2/spas/owned-property/smoke-alarm-tests/redux/reducers/view.tsx'
import ownedPropertyTenancies from 'v2/spas/owned-property/tenancies/redux/reducers/index.tsx'
import ownedPropertyTenancy from 'v2/spas/owned-property/tenancies/redux/reducers/view.tsx'
import tenancyDocuments from 'v2/spas/tenancy/documents/redux/reducers/index.tsx'
import tenancyDocument from 'v2/spas/tenancy/documents/redux/reducers/view.tsx'
import tenancyTenants from 'v2/spas/tenancy/tenants/redux/reducers/index.tsx'
import tenancyGuarantors from 'v2/spas/tenancy/guarantors/redux/reducers/index.tsx'
import existingMortgageMortgageAccounts from 'v2/spas/existing-mortgage/mortgage-accounts/redux/reducers/index.tsx'
import existingMortgageMortgageAccount from 'v2/spas/existing-mortgage/mortgage-accounts/redux/reducers/view.tsx'
import existingMortgageDocuments from 'v2/spas/existing-mortgage/documents/redux/reducers/index.tsx'
import existingMortgageDocument from 'v2/spas/existing-mortgage/documents/redux/reducers/view.tsx'
import account from 'v2/spas/account/account/redux/reducers/view.tsx'
import accountBridgingLoanProducts from 'v2/spas/account/bridging-loan-products/redux/reducers/index.tsx'
import accountBridgingLoanProduct from 'v2/spas/account/bridging-loan-products/redux/reducers/view.tsx'
import accountMortgageProducts from 'v2/spas/account/mortgage-products/redux/reducers/index.tsx'
import accountMortgageProduct from 'v2/spas/account/mortgage-products/redux/reducers/view.tsx'
import accountCompanies from 'v2/spas/account/companies/redux/reducers/index.tsx'
import accountCompany from 'v2/spas/account/companies/redux/reducers/view.tsx'
import accountAccountUsers from 'v2/spas/account/account-users/redux/reducers/index.tsx'
import accountAccountUser from 'v2/spas/account/account-users/redux/reducers/view.tsx'
import ownedPropertyInsurancePolicies from 'v2/spas/owned-property/insurance-policies/redux/reducers/index.tsx'
import ownedPropertyInsurancePolicy from 'v2/spas/owned-property/insurance-policies/redux/reducers/view.tsx'
import unitTenancies from 'v2/spas/unit/tenancies/redux/reducers/index.tsx'
import unitTenancy from 'v2/spas/unit/tenancies/redux/reducers/view.tsx'
import prospectivePropertyDeals from 'v2/spas/prospective-property/deals/redux/reducers/index.tsx'
import accountUserDocuments from 'v2/spas/account-user/documents/redux/reducers/index.tsx'
import accountUserDocument from 'v2/spas/account-user/documents/redux/reducers/view.tsx'
import companyDocuments from 'v2/spas/company/documents/redux/reducers/index.tsx'
import companyDocument from 'v2/spas/company/documents/redux/reducers/view.tsx'
import companyDirectors from 'v2/spas/company/directors/redux/reducers/index.tsx'
import companyShareholders from 'v2/spas/company/shareholders/redux/reducers/index.tsx'
import companyOwnedProperties from 'v2/spas/company/owned-properties/redux/reducers/index.tsx'
import accountUserOwnedProperties from 'v2/spas/account-user/owned-properties/redux/reducers/index.tsx'
import accountWorkflows from 'v2/spas/account/workflows/redux/reducers/index.tsx'
import prospectivePropertyWorkflows from 'v2/spas/prospective-property/workflows/redux/reducers/index.tsx'
import ownedPropertyWorkflows from 'v2/spas/owned-property/workflows/redux/reducers/index.tsx'
import accountUserWorkflowAssignments from 'v2/spas/account-user/workflow-assignments/redux/reducers/index.tsx'
import prospectivePropertyInvestmentOpportunities from 'v2/spas/prospective-property/investment-opportunities/redux/reducers/index.tsx'
import prospectivePropertyInvestmentOpportunity from 'v2/spas/prospective-property/investment-opportunities/redux/reducers/view.tsx'
import ownedPropertyInvestmentOpportunities from 'v2/spas/owned-property/investment-opportunities/redux/reducers/index.tsx'
import ownedPropertyInvestmentOpportunity from 'v2/spas/owned-property/investment-opportunities/redux/reducers/view.tsx'
import prospectivePropertyMortgageApplications from 'v2/spas/prospective-property/mortgage-applications/redux/reducers/index.tsx'
import prospectivePropertyMortgageApplication from 'v2/spas/prospective-property/mortgage-applications/redux/reducers/view.tsx'
import prospectivePropertyBridgingLoanApplications from 'v2/spas/prospective-property/bridging-loan-applications/redux/reducers/index.tsx'
import prospectivePropertyBridgingLoanApplication from 'v2/spas/prospective-property/bridging-loan-applications/redux/reducers/view.tsx'
import mortgageApplicationMortgageApplicants from 'v2/spas/mortgage-application/mortgage-applicants/redux/reducers/index.tsx'
import bridgingLoanApplicationBridgingLoanApplicants from 'v2/spas/bridging-loan-application/bridging-loan-applicants/redux/reducers/index.tsx'
import knowledgeBase from 'v2/spas/knowledge-base/knowledge-base/redux/reducers/view.tsx'
import knowledgeBaseCategories from 'v2/spas/knowledge-base/categories/redux/reducers/index.tsx'
import knowledgeBaseCategory from 'v2/spas/knowledge-base/categories/redux/reducers/view.tsx'
import categorySections from 'v2/spas/category/sections/redux/reducers/index.tsx'
import categorySection from 'v2/spas/category/sections/redux/reducers/view.tsx'
import sectionVideos from 'v2/spas/section/videos/redux/reducers/index.tsx'
import academy from 'v2/spas/academy/academy/redux/reducers/view.tsx'
import academyCourses from 'v2/spas/academy/courses/redux/reducers/index.tsx'
import academyCourse from 'v2/spas/academy/courses/redux/reducers/view.tsx'
import courseLessons from 'v2/spas/course/lessons/redux/reducers/index.tsx'
import community from 'v2/spas/community/community/redux/reducers/view.tsx'
import communityCommunityEvents from 'v2/spas/community/community-events/redux/reducers/index.tsx'
import communityCommunityEvent from 'v2/spas/community/community-events/redux/reducers/view.tsx'
import prospectivePropertyPreviousSales from 'v2/spas/prospective-property/previous-sales/redux/reducers/index.tsx'
import tenancyRentStatement from 'v2/spas/tenancy/rent-statement/redux/reducers/index.tsx'
import accountDealTemplates from 'v2/spas/account/deal-templates/redux/reducers/index.tsx'
import accountDealTemplate from 'v2/spas/account/deal-templates/redux/reducers/view.tsx'
import ownedPropertyDeals from 'v2/spas/owned-property/deals/redux/reducers/index.tsx'
import ownedPropertyRemortgageDeal from 'v2/spas/owned-property/remortgage-deals/redux/reducers/view.tsx'
import ownedPropertyFurtherAdvanceDeal from 'v2/spas/owned-property/further-advance-deals/redux/reducers/view.tsx'
import ownedPropertyProductTransferDeal from 'v2/spas/owned-property/product-transfer-deals/redux/reducers/view.tsx'
import ownedPropertyMonthlyExpenses from 'v2/spas/owned-property/monthly-expenses/redux/reducers/index.tsx'
import ownedPropertyInsuranceApplications from 'v2/spas/owned-property/insurance-applications/redux/reducers/index.tsx'
import ownedPropertyInsuranceApplication from 'v2/spas/owned-property/insurance-applications/redux/reducers/view.tsx'
import prospectivePropertyInsuranceApplications from 'v2/spas/prospective-property/insurance-applications/redux/reducers/index.tsx'
import prospectivePropertyInsuranceApplication from 'v2/spas/prospective-property/insurance-applications/redux/reducers/view.tsx'
import insuranceApplicationInsuranceApplicants from 'v2/spas/insurance-application/insurance-applicants/redux/reducers/index.tsx'
import accountUserActivityFeedItems from 'v2/spas/account-user/activity-feed-items/redux/reducers/index.tsx'
import ownedPropertyDocuments from 'v2/spas/owned-property/documents/redux/reducers/index.tsx'
import workflow from 'v2/spas/workflow/redux/reducers/view.tsx'
import ownedPropertyKeys from 'v2/spas/owned-property/keys/redux/reducers/index.tsx'
import ownedPropertyKey from 'v2/spas/owned-property/keys/redux/reducers/view.tsx'
import ownedPropertyMortgageApplications from 'v2/spas/owned-property/mortgage-applications/redux/reducers/index.tsx'
import ownedPropertyMortgageApplication from 'v2/spas/owned-property/mortgage-applications/redux/reducers/view.tsx'
import investmentOpportunityInvestmentOpportunityInvitations from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/reducers/index.tsx'
import investmentOpportunityInvestmentOpportunityInvitation from 'v2/spas/investment-opportunity/investment-opportunity-invitations/redux/reducers/view.tsx'
import prospectivePropertyConveyancingApplications from 'v2/spas/prospective-property/conveyancing-applications/redux/reducers/index.tsx'
import prospectivePropertyConveyancingApplication from 'v2/spas/prospective-property/conveyancing-applications/redux/reducers/view.tsx'
import ownedPropertyBridgingLoanApplications from 'v2/spas/owned-property/bridging-loan-applications/redux/reducers/index.tsx'
import ownedPropertyBridgingLoanApplication from 'v2/spas/owned-property/bridging-loan-applications/redux/reducers/view.tsx'
import bridgingLoanApplicationInvestmentOpportunities from 'v2/spas/bridging-loan-application/investment-opportunities/redux/reducers/index.tsx'
import sectionArticles from 'v2/spas/section/articles/redux/reducers/index.tsx'
import sectionArticle from 'v2/spas/section/articles/redux/reducers/view.tsx'

const rootReducer = combineReducers({
  stacked,
  prospectiveProperties,
  prospectiveProperty,
  hmoDeal,
  singleLetDeal,
  investors,
  investorPack,
  mortgageLenders,
  dealTemplate,
  profile,
  team,
  authentication,
  companies,
  company,
  userDocuments,
  existingMortgage,
  bridgingLenders,
  flipDeal,
  resourceCategories,
  resourceCategory,
  ownedPropertyFinancials,
  privateInvestors,
  // prospectivePropertyWorkflows,
  mortgageApplications,
  contacts,
  accountUser,
  tenancy,
  accountPartners,
  prospectivePropertyFinance,
  mortgageApplication,
  commercialToResidentialDeal,
  goals,
  calendar,
  ownedPropertyManagement,
  subscriptionProducts,
  ownedPropertyDeals,
  propertyPricePaidRecords,
  // ownedPropertyWorkflows,
  tenancyWorkflows,
  certificatePortfolioReport,
  tenancyPortfolioReport,
  ownedPropertyAccountPortfolioReport,
  mortgageAccountPortfolioReport,
  globalSearch,
  portfolioOverviewReport,
  mortgageApplicants,
  mortgageApplicant,
  prospectivePropertyWorkflowReport,
  ownedPropertyWorkflowReport,
  propertyDeals,
  accountUserWorkflows,
  accountUserWorkflowReport,
  articles,
  article,
  workflows,
  property,
  prospectivePropertyRentListings,
  rentListing,
  prospectivePropertySaleListings,
  saleListing,
  prospectivePropertyRentComparables,
  rentComparable,
  prospectivePropertyVideos,
  video,
  prospectivePropertyPhotos,
  photo,
  prospectivePropertyDocuments,
  document,
  prospectivePropertySaleComparables,
  saleComparable,
  prospectivePropertyPropertyOwners,
  propertyOwner,
  prospectivePropertyEnergyPerformanceCertificates,
  energyPerformanceCertificate,
  ownedPropertyUnits,
  unit,
  ownedPropertyAppliances,
  appliance,
  ownedProperties,
  ownedProperty,
  ownedPropertyPhotos,
  ownedPropertyPhoto,
  ownedPropertyVideos,
  ownedPropertyVideo,
  ownedPropertyCertificates,
  ownedPropertyCertificate,
  ownedPropertyExistingMortgages,
  ownedPropertyExistingMortgage,
  ownedPropertyPropertyOwners,
  ownedPropertyPropertyOwner,
  ownedPropertyEnergyPerformanceCertificates,
  ownedPropertyEnergyPerformanceCertificate,
  ownedPropertyLicences,
  ownedPropertyLicence,
  ownedPropertyAccounts,
  ownedPropertyAccount,
  ownedPropertySmokeAlarmTests,
  ownedPropertySmokeAlarmTest,
  ownedPropertyTenancies,
  ownedPropertyTenancy,
  tenancyDocuments,
  tenancyDocument,
  tenancyTenants,
  tenancyGuarantors,
  existingMortgageMortgageAccounts,
  existingMortgageMortgageAccount,
  existingMortgageDocuments,
  existingMortgageDocument,
  account,
  accountBridgingLoanProducts,
  accountBridgingLoanProduct,
  accountMortgageProducts,
  accountMortgageProduct,
  accountCompanies,
  accountCompany,
  accountAccountUsers,
  accountAccountUser,
  ownedPropertyInsurancePolicies,
  ownedPropertyInsurancePolicy,
  unitTenancies,
  unitTenancy,
  prospectivePropertyDeals,
  accountUserDocuments,
  accountUserDocument,
  companyDocuments,
  companyDocument,
  companyDirectors,
  companyShareholders,
  companyOwnedProperties,
  accountUserOwnedProperties,
  accountWorkflows,
  prospectivePropertyWorkflows,
  ownedPropertyWorkflows,
  accountUserWorkflowAssignments,
  prospectivePropertyInvestmentOpportunities,
  prospectivePropertyInvestmentOpportunity,
  ownedPropertyInvestmentOpportunities,
  ownedPropertyInvestmentOpportunity,
  prospectivePropertyMortgageApplications,
  prospectivePropertyMortgageApplication,
  prospectivePropertyBridgingLoanApplications,
  prospectivePropertyBridgingLoanApplication,
  mortgageApplicationMortgageApplicants,
  bridgingLoanApplicationBridgingLoanApplicants,
  knowledgeBase,
  knowledgeBaseCategories,
  knowledgeBaseCategory,
  categorySections,
  categorySection,
  sectionVideos,
  academy,
  academyCourses,
  academyCourse,
  courseLessons,
  community,
  communityCommunityEvents,
  communityCommunityEvent,
  prospectivePropertyPreviousSales,
  rentToRentDeal,
  rentToServicedAccommodationDeal,
  servicedAccommodationDeal,
  tenancyRentStatement,
  accountDealTemplates,
  accountDealTemplate,
  ownedPropertyDeals,
  ownedPropertyRemortgageDeal,
  ownedPropertyFurtherAdvanceDeal,
  ownedPropertyProductTransferDeal,
  ownedPropertyMonthlyExpenses,
  ownedPropertyInsuranceApplications,
  ownedPropertyInsuranceApplication,
  prospectivePropertyInsuranceApplications,
  prospectivePropertyInsuranceApplication,
  accountUserActivityFeedItems,
  ownedPropertyDocuments,
  workflow,
  ownedPropertyKeys,
  ownedPropertyKey,
  ownedPropertyMortgageApplications,
  ownedPropertyMortgageApplication,
  investmentOpportunityInvestmentOpportunityInvitations,
  investmentOpportunityInvestmentOpportunityInvitation,
  insuranceApplicationInsuranceApplicants,
  prospectivePropertyConveyancingApplications,
  prospectivePropertyConveyancingApplication,
  ownedPropertyBridgingLoanApplications,
  ownedPropertyBridgingLoanApplication,
  bridgingLoanApplicationInvestmentOpportunities,
  sectionArticles,
  sectionArticle,
})

export default rootReducer
