import update from 'immutability-helper'
import { FETCH_MORTGAGE_APPLICANTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_MORTGAGE_APPLICANTS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_MORTGAGE_APPLICANTS_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchMortgageApplicantsStatus: 'requested',
  fetchMortgageApplicantsError: '',
  mortgageApplicants: [],
  mortgageApplication: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MORTGAGE_APPLICANTS_REQUEST: {
      return update(state, {
        fetchMortgageApplicantsStatus: { $set: 'requested' },
      })
    }

    case FETCH_MORTGAGE_APPLICANTS_FAILURE: {
      return update(state, {
        fetchMortgageApplicantsStatus: {
          $set: 'error',
        },
        fetchMortgageApplicantsError: { $set: action.payload.error },
      })
    }

    case FETCH_MORTGAGE_APPLICANTS_SUCCESS: {
      return update(state, {
        fetchMortgageApplicantsStatus: { $set: 'success' },
        mortgageApplication: {
          $set: action.payload.mortgageApplication,
        },
        mortgageApplicants: {
          $set: action.payload.mortgageApplication.mortgageApplicants,
        },
      })
    }

    default:
      return state
  }
}
