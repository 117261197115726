import React from 'react'
import styled from 'styled-components'
import SupportIcon from 'sharedComponents/SupportIcon';

const Wrapper = styled.div`
  height: 100%;
  justify-content: flex-end;

  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
`

function Support(props) {
  return (
    <Wrapper id="navbar-search">
      <SupportIcon />
    </Wrapper>
  )
}

export default Support
