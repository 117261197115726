import update from 'immutability-helper'
import { FETCH_COMPANY_SHAREHOLDERS_REQUEST } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { FETCH_COMPANY_SHAREHOLDERS_SUCCESS } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { FETCH_COMPANY_SHAREHOLDERS_FAILURE } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { ARCHIVE_SHAREHOLDER_REQUEST } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { ARCHIVE_SHAREHOLDER_SUCCESS } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { ARCHIVE_SHAREHOLDER_FAILURE } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { UPDATE_SHAREHOLDER_REQUEST } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { UPDATE_SHAREHOLDER_SUCCESS } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { UPDATE_SHAREHOLDER_FAILURE } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { DELETE_SHAREHOLDER_REQUEST } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { DELETE_SHAREHOLDER_SUCCESS } from 'v2/spas/company/shareholders/redux/action-types.tsx'
import { DELETE_SHAREHOLDER_FAILURE } from 'v2/spas/company/shareholders/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  company: {},
  shareholders: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_SHAREHOLDERS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_COMPANY_SHAREHOLDERS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_COMPANY_SHAREHOLDERS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        company: {
          $set: action.payload.company,
        },
        shareholders: {
          $set: action.payload.company.shareholders
        },
      })
    }

    case UPDATE_SHAREHOLDER_REQUEST: {
      return state
    }

    case UPDATE_SHAREHOLDER_FAILURE: {
      return state
    }

    case UPDATE_SHAREHOLDER_SUCCESS: {
      let index = state.shareholders.findIndex(
        x => x.id === action.payload.shareholder.id,
      )

      return update(state, {
        shareholders: {
          [index]: { $set: action.payload.shareholder },
        },
      })
    }

    case ARCHIVE_SHAREHOLDER_REQUEST: {
      return state
    }

    case ARCHIVE_SHAREHOLDER_FAILURE: {
      return state
    }

    case ARCHIVE_SHAREHOLDER_SUCCESS: {
      let index = state.shareholders.findIndex(x => x.id === action.payload.shareholder.id)

      return update(state, {
        shareholders: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_SHAREHOLDER_REQUEST: {
      return state
    }
    
    case DELETE_SHAREHOLDER_FAILURE: {
      return state
    }
    
    case DELETE_SHAREHOLDER_SUCCESS: {
      let index = state.shareholders.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        shareholders: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
