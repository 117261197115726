import axios from 'axios'
import Cookies from 'js-cookie'

function createEvent({
  eventType,
  eventObjectType,
  eventObjectId
}) {
  let variables = {
    eventType: eventType,
    eventObjectType: eventObjectType,
    eventObjectId: eventObjectId
  }

  let query = `
    mutation(
      $eventType: String!,
      $eventObjectType: String!,
      $eventObjectId: String!
    ) {
      createEvent(
        eventType: $eventType
        eventObjectType: $eventObjectType
        eventObjectId: $eventObjectId
      ) {
        message
      }
    }
  `

  return axios({
    url: process.env.REACT_APP_API_PATH + 'graphql',
    method: 'post',
    headers: {
      Authorization: Cookies.get('jwtToken'),
    },
    data: {
      variables: variables,
      query: query,
    },
  })
}

export default createEvent
