export const FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_CONVEYANCING_APPLICATIONS_FAILURE'

export const FETCH_CONVEYANCING_APPLICATION_REQUEST = 'FETCH_CONVEYANCING_APPLICATION_REQUEST'
export const FETCH_CONVEYANCING_APPLICATION_SUCCESS = 'FETCH_CONVEYANCING_APPLICATION_SUCCESS'
export const FETCH_CONVEYANCING_APPLICATION_FAILURE = 'FETCH_CONVEYANCING_APPLICATION_FAILURE'

export const CREATE_CONVEYANCING_APPLICATION_REQUEST = 'CREATE_CONVEYANCING_APPLICATION_REQUEST'
export const CREATE_CONVEYANCING_APPLICATION_SUCCESS = 'CREATE_CONVEYANCING_APPLICATION_SUCCESS'
export const CREATE_CONVEYANCING_APPLICATION_FAILURE = 'CREATE_CONVEYANCING_APPLICATION_FAILURE'

export const UPDATE_CONVEYANCING_APPLICATION_REQUEST = 'UPDATE_CONVEYANCING_APPLICATION_REQUEST'
export const UPDATE_CONVEYANCING_APPLICATION_SUCCESS = 'UPDATE_CONVEYANCING_APPLICATION_SUCCESS'
export const UPDATE_CONVEYANCING_APPLICATION_FAILURE = 'UPDATE_CONVEYANCING_APPLICATION_FAILURE'

export const ARCHIVE_CONVEYANCING_APPLICATION_REQUEST = 'ARCHIVE_CONVEYANCING_APPLICATION_REQUEST'
export const ARCHIVE_CONVEYANCING_APPLICATION_SUCCESS = 'ARCHIVE_CONVEYANCING_APPLICATION_SUCCESS'
export const ARCHIVE_CONVEYANCING_APPLICATION_FAILURE = 'ARCHIVE_CONVEYANCING_APPLICATION_FAILURE'

export const UNARCHIVE_CONVEYANCING_APPLICATION_REQUEST = 'UNARCHIVE_CONVEYANCING_APPLICATION_REQUEST'
export const UNARCHIVE_CONVEYANCING_APPLICATION_SUCCESS = 'UNARCHIVE_CONVEYANCING_APPLICATION_SUCCESS'
export const UNARCHIVE_CONVEYANCING_APPLICATION_FAILURE = 'UNARCHIVE_CONVEYANCING_APPLICATION_FAILURE'

export const DELETE_CONVEYANCING_APPLICATION_REQUEST = 'DELETE_CONVEYANCING_APPLICATION_REQUEST'
export const DELETE_CONVEYANCING_APPLICATION_SUCCESS = 'DELETE_CONVEYANCING_APPLICATION_SUCCESS'
export const DELETE_CONVEYANCING_APPLICATION_FAILURE = 'DELETE_CONVEYANCING_APPLICATION_FAILURE'