import React, {useState} from 'react'
import ProspectivePropertyResourceCard from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCard.js'
import ProspectivePropertyResourceCardHeader from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardHeader.js'
import {FormModal} from 'modals'
import {EditResourceButton} from 'buttons'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {forgetUser} from 'redux/actions/profile/forgetUser'
import { Button } from 'buttons'
import {ConfirmationModal} from 'modals'
import {useSnackbar} from 'notistack'

const Description = styled.div`
  padding-top: 25px;
  padding-left: 50px;
  margin-right: 20px;
  text-align: left;

  @media ${props => props.theme.devices.mobile} {
    padding-left: 0;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.tablet} {
    padding-left: 0;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: 0;
    margin-left: 30px;
  }
`

const ForgetMeButtonWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 25px;
`

function ForgetMeCard(props) {
  const {enqueueSnackbar} = useSnackbar()
  const [show, setShow] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  function forgetMe() {
    setShowConfirmationModal(true)
  }

  const handleConfirmForgetMe = () => {
    setShowConfirmationModal(false)
    props.forgetUser(handleResponse)
  }

  function handleResponse(response) {
    if (response.status === 'success') {
      enqueueSnackbar('Your request to be forgoten is being processed!')
      window.location.reload()
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      window.setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  return (
    <ProspectivePropertyResourceCard>
      <ProspectivePropertyResourceCardHeader heading="Right To Be Forgotten">
        <EditResourceButton text="Manage" onClick={() => setShow(true)} />
      </ProspectivePropertyResourceCardHeader>

      <FormModal show={show} setShow={setShow} heading="Forget Me">
        <Description>
          <p>
            In accordance with{' '}
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
              target="_blank"
              rel="noopener noreferrer"
            >
              GDPR
            </a>{' '}
            - you have the right to be forgotten.
          </p>
          <p>You can action this by clicking Forget Me.</p>
          <p>
            Before doing so - please be aware of the following implications:
          </p>
          <ul>
            <li>You will be immediately signed out.</li>
            <li>
              All of your Personally Identitiable Information will be redacted.
            </li>
            <li>
              As a result - you will no longer be able to access your account.
            </li>
            <li>
              This request will also be communicated to / actioned with any
              relevant 3rd parties where your data is stored.
            </li>
          </ul>

          <ForgetMeButtonWrapper>
            <Button
              onClick={forgetMe}
              content="Forget Me"
              backgroundColor={props => props.theme.colors.red}
            />
          </ForgetMeButtonWrapper>
        </Description>
      </FormModal>

      <ConfirmationModal
        show={showConfirmationModal}
        setShow={setShowConfirmationModal}
        handleConfirm={handleConfirmForgetMe}
        heading="Forget Me"
        description="Are you sure you want to be forgotten? You will no longer be able to access your account."
      />
    </ProspectivePropertyResourceCard>
  )
}

const mapDispatchToProps = {
  forgetUser: forgetUser,
}

export default connect(null, mapDispatchToProps)(ForgetMeCard)
