import update from 'immutability-helper'
import {FETCH_ACCOUNT_MORTGAGE_LENDERS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_MORTGAGE_LENDERS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_MORTGAGE_LENDERS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_LENDERS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_LENDERS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_LENDERS_FAILURE} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_PRODUCT_REQUEST} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_PRODUCT_FAILURE} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_PRODUCT_SUCCESS} from 'redux/actionTypes.js'
import {ARCHIVE_MORTGAGE_PRODUCT_REQUEST} from 'redux/actionTypes.js'
import {ARCHIVE_MORTGAGE_PRODUCT_SUCCESS} from 'redux/actionTypes.js'
import {ARCHIVE_MORTGAGE_PRODUCT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_PRODUCT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_PRODUCT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_PRODUCT_SUCCESS} from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  fetchAccountMortgageLendersStatus: 'requested',
  fetchAccountMortgageLendersError: '',
  accountMortgageLenders: [],
  fetchMortgageLendersStatus: 'requested',
  fetchMortgageLendersError: '',
  mortgageLenders: [],
  fetchAccountMortgageProductsStatus: 'requested',
  fetchAccountMortgageProductsError: '',
  accountMortgageProducts: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_MORTGAGE_LENDERS_REQUEST: {
      return update(state, {
        fetchAccountMortgageLendersStatus: {$set: 'requested'},
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_LENDERS_FAILURE: {
      return update(state, {
        fetchAccountMortgageLendersStatus: {
          $set: 'error',
        },
        fetchAccountMortgageLendersError: {$set: action.payload.error},
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_LENDERS_SUCCESS: {
      return update(state, {
        fetchAccountMortgageLendersStatus: {$set: 'success'},
        accountMortgageLenders: {
          $set: action.payload.mortgageLenders,
        },
      })
    }

    case FETCH_MORTGAGE_LENDERS_REQUEST: {
      return state
    }

    case FETCH_MORTGAGE_LENDERS_FAILURE: {
      return state
    }

    case FETCH_MORTGAGE_LENDERS_SUCCESS: {
      return update(state, {
        fetchMortgageLendersStatus: {$set: 'success'},
        mortgageLenders: {
          $set: action.payload.mortgageLenders,
        },
      })
    }

    case ADD_MORTGAGE_PRODUCT_REQUEST: {
      return state
    }

    case ADD_MORTGAGE_PRODUCT_FAILURE: {
      return state
    }

    case ADD_MORTGAGE_PRODUCT_SUCCESS: {
      return update(state, {
        accountMortgageLenders: {$set: action.payload.mortgageLenders},
      })
    }

    case UPDATE_MORTGAGE_PRODUCT_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_PRODUCT_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_PRODUCT_SUCCESS: {
      let index = state.accountMortgageLenders.findIndex(
        x => x.id === action.payload.mortgageProduct.mortgageLender.id,
      )

      return update(state, {
        accountMortgageLenders: {
          [index]: {$set: action.payload.mortgageProduct.mortgageLender},
        },
      })
    }

    case ARCHIVE_MORTGAGE_PRODUCT_REQUEST: {
      return state
    }

    case ARCHIVE_MORTGAGE_PRODUCT_FAILURE: {
      return state
    }

    case ARCHIVE_MORTGAGE_PRODUCT_SUCCESS: {
      let index = state.accountMortgageLenders.findIndex(
        x => x.id === action.payload.mortgageLender.id,
      )

      return update(state, {
        accountMortgageLenders: {
          [index]: {$set: action.payload.mortgageLender},
        },
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST: {
      return update(state, {
        fetchAccountMortgageProductsStatus: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE: {
      return update(state, {
        fetchAccountMortgageProductsStatus: {
          $set: 'error',
        },
        fetchAccountMortgageProductsError: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS: {
      return update(state, {
        fetchAccountMortgageProductsStatus: { $set: 'success' },
        accountMortgageProducts: {
          $set: action.payload.mortgageProducts,
        },
      })
    }

    default:
      return state
  }
}
