import update from 'immutability-helper'
import {FETCH_PROSPECTIVE_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PROSPECTIVE_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PROSPECTIVE_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_APPLICATIONS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_APPLICATIONS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_APPLICATIONS_FAILURE} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_APPLICATION_REQUEST} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_APPLICATION_SUCCESS} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_APPLICATION_FAILURE} from 'redux/actionTypes.js'

const initialState = {
  fetchProspectivePropertyStatus: 'requested',
  fetchProspectivePropertyError: '',
  prospectiveProperty: {},
  fetchMortgageApplicationsStatus: 'requested',
  fetchMortgageApplicationsError: '',
  mortgageApplications: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_REQUEST: {
      return update(state, {
        fetchProspectivePropertyStatus: {$set: 'requested'},
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_FAILURE: {
      return update(state, {
        fetchProspectivePropertyStatus: {
          $set: 'error',
        },
        fetchProspectivePropertyError: {$set: action.payload.error},
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_SUCCESS: {
      return update(state, {
        fetchProspectivePropertyStatus: {$set: 'success'},
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
      })
    }

    case FETCH_MORTGAGE_APPLICATIONS_REQUEST: {
      return update(state, {
        fetchMortgageApplicationsStatus: {$set: 'requested'},
      })
    }

    case FETCH_MORTGAGE_APPLICATIONS_FAILURE: {
      return update(state, {
        fetchMortgageApplicationsStatus: {
          $set: 'error',
        },
        fetchMortgageApplicationsError: {$set: action.payload.error},
      })
    }

    case FETCH_MORTGAGE_APPLICATIONS_SUCCESS: {
      return update(state, {
        fetchMortgageApplicationsStatus: {$set: 'success'},
        mortgageApplications: {
          $set: action.payload.mortgageApplications,
        },
      })
    }

    case ADD_MORTGAGE_APPLICATION_REQUEST: {
      return state
    }

    case ADD_MORTGAGE_APPLICATION_FAILURE: {
      return state
    }

    case ADD_MORTGAGE_APPLICATION_SUCCESS: {
      return state
    }

    default:
      return state
  }
}
