import {useCurrentUser} from 'sharedComponents/useCurrentUser.js'

export function useAuthenticated() {
  const currentUser = useCurrentUser()

  if (
    typeof currentUser.id !== 'undefined' &&
    currentUser.signUpStatus !== 'invited_to_presentation' &&
    currentUser.signUpStatus !== 'pre_registered'
  ) {
    return true
  } else {
    return false
  }
}
