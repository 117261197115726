import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_REQUEST } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_SUCCESS } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_FAILURE } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { CREATE_MONTHLY_EXPENSE_REQUEST } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { CREATE_MONTHLY_EXPENSE_SUCCESS } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { CREATE_MONTHLY_EXPENSE_FAILURE } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { UPDATE_MONTHLY_EXPENSE_REQUEST } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { UPDATE_MONTHLY_EXPENSE_SUCCESS } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { UPDATE_MONTHLY_EXPENSE_FAILURE } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { DELETE_MONTHLY_EXPENSE_REQUEST } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { DELETE_MONTHLY_EXPENSE_SUCCESS } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'
import { DELETE_MONTHLY_EXPENSE_FAILURE } from 'v2/spas/owned-property/monthly-expenses/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  monthlyExpenses: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        monthlyExpenses: {
          $set: action.payload.ownedProperty.recurringExpenses
        },
      })
    }

    case UPDATE_MONTHLY_EXPENSE_REQUEST: {
      return state
    }

    case UPDATE_MONTHLY_EXPENSE_FAILURE: {
      return state
    }

    case UPDATE_MONTHLY_EXPENSE_SUCCESS: {
      let index = state.monthlyExpenses.findIndex(
        x => x.id === action.payload.monthlyExpense.id,
      )

      return update(state, {
        monthlyExpenses: {
          [index]: { $set: action.payload.monthlyExpense },
        },
      })
    }

    case CREATE_MONTHLY_EXPENSE_REQUEST: {
      return state
    }

    case CREATE_MONTHLY_EXPENSE_FAILURE: {
      return state
    }

    case CREATE_MONTHLY_EXPENSE_SUCCESS: {
      return update(state, {
        monthlyExpenses: {
          $push: [action.payload.monthlyExpense],
        },
      })
    }

    case DELETE_MONTHLY_EXPENSE_REQUEST: {
      return state
    }
    
    case DELETE_MONTHLY_EXPENSE_FAILURE: {
      return state
    }
    
    case DELETE_MONTHLY_EXPENSE_SUCCESS: {
      let index = state.monthlyExpenses.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        monthlyExpenses: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
