import React from 'react'
import styled from 'styled-components'
import {useCurrentAccount} from 'sharedComponents/useCurrentAccount'
import {useDevice} from 'sharedComponents/useDevice'
import PageNavbar from 'sharedComponents/PageNavbar/PageNavbar'
import ProfilePageNavbarHeading from 'spas/Profile/ProfilePageNavbar/ProfilePageNavbarHeading'
import MortgageBrokerProfilePageNavbarLinks from 'spas/Profile/MortgageBrokerProfilePageNavbarLinks'
import PropertyInvestorProfilePageNavbarLinks from 'spas/Profile/PropertyInvestorProfilePageNavbarLinks'

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const TabContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

function ProfilePageNavbar(props) {
  const device = useDevice()
  const currentAccount = useCurrentAccount()
  const navBarHeight = device === 'mobile' ? 'auto' : '215px'

  function renderPageNavbarLinks() {
    switch (currentAccount.typeName) {
      case 'mortgage_broker':
        return <MortgageBrokerProfilePageNavbarLinks />
      case 'property_investment':
        return <PropertyInvestorProfilePageNavbarLinks />
      default:
        return null
    }
  }

  return (
    <PageNavbar height={navBarHeight}>
      <Content>
        <ProfilePageNavbarHeading {...props} />
        <TabContentWrapper>{renderPageNavbarLinks()}</TabContentWrapper>
      </Content>
    </PageNavbar>
  )
}

export default ProfilePageNavbar
