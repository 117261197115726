import React, { useState } from 'react'
import ProspectivePropertyResourceCard from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCard.js'
import ProspectivePropertyResourceCardHeader from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardHeader.js'
import {FormModal} from 'modals'
import {EditResourceButton} from 'buttons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'

const Description = styled.div`
  p {
    padding-bottom: ${props => props.theme.space.m}
  }
`

function NewsletterCard(props) {
  const [show, setShow] = useState(false)

  return (
    <ProspectivePropertyResourceCard>
      <ProspectivePropertyResourceCardHeader heading="Newsletter">
        <EditResourceButton text="Manage" onClick={() => setShow(true)} />
      </ProspectivePropertyResourceCardHeader>

      <FormModal
        show={show}
        setShow={setShow}
        heading="Newsletter"
        size="xl"
      >
        <Row>
          <Col xs="12">
            <Description>
              <p>
                You can manage your newsletter preferences by clicking on the link at the bottom of the
                emails you receive from Stacked.
              </p>
              <p>
                If something is wrong please contact us at hello@stacked.com.
              </p>
              <p>
                We would encourage to opt into newsletter emails, as this is the best way to stay up to date
                with new features that we are adding to the platform. These will support your efforts to
                Build & Scale Your Property Business.
              </p>
            </Description>
          </Col>
        </Row>
      </FormModal>
    </ProspectivePropertyResourceCard>
  )
}

export default NewsletterCard
