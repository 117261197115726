import React from 'react'
import styled from 'styled-components'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const SocialIcon = styled.div`
  padding-left: ${props => props.theme.space.m};
  padding-right: ${props => props.theme.space.m};

  svg {
    color: white;
    width: 32px;
    height: 32px;
  }
`

function SocialIcons() {
  return (
    <Wrapper id='social-icons'>
      <SocialIcon id='social-icon'>
        <a
          href="https://www.instagram.com/stackedgroup/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
      </SocialIcon>
      <SocialIcon id='social-icon'>
        <a
          href="https://www.facebook.com/groups/stackedproperty"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
      </SocialIcon>
      <SocialIcon id='social-icon'>
        <a
          href="https://twitter.com/stackedproperty"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </a>
      </SocialIcon>
      <SocialIcon id='social-icon'>
        <a
          href="https://www.youtube.com/channel/UCHcSeGvJQ1JjYATJCotx1VA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon />
        </a>
      </SocialIcon>
      <SocialIcon id='social-icon'>
        <a
          href="https://www.linkedin.com/company/stackedproperty"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </a>
      </SocialIcon>
    </Wrapper>
  )
}

export default SocialIcons
