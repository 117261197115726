import {useState, useEffect} from 'react'

export function useScreenWidth() {
  const isClient = typeof window === 'object'

  function getScreenWidth() {
    return window.innerWidth
  }

  const [screenWidth, setScreenWidth] = useState(getScreenWidth)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setScreenWidth(getScreenWidth())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return screenWidth
}
