import {FETCH_SUBSCRIPTION_PRODUCTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SUBSCRIPTION_PRODUCTS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SUBSCRIPTION_PRODUCTS_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function fetchSubscriptionProducts() {
  return function (dispatch) {
    dispatch(fetchSubscriptionProductsRequest())

    let query = `
      {
        subscriptionProducts {
          id
          order
          name
          unitAmount
          interval
          stripeProductId
          stripePriceId
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          dispatch(
            fetchSubscriptionProductsFailure(res.data.errors[0].message),
          )
        } else {
          dispatch(
            fetchSubscriptionProductsSuccess(
              res.data.data.subscriptionProducts,
            ),
          )
        }
      })
      .catch(error => {
        dispatch(fetchSubscriptionProductsFailure(error.message))
      })
  }
}

export const fetchSubscriptionProductsRequest = () => ({
  type: FETCH_SUBSCRIPTION_PRODUCTS_REQUEST,
})

export const fetchSubscriptionProductsSuccess = subscriptionProducts => ({
  type: FETCH_SUBSCRIPTION_PRODUCTS_SUCCESS,
  payload: {
    subscriptionProducts: subscriptionProducts,
  },
})

export const fetchSubscriptionProductsFailure = error => ({
  type: FETCH_SUBSCRIPTION_PRODUCTS_FAILURE,
  payload: {
    error: error,
  },
})
