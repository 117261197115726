import React from 'react'
import Dashboard from 'v2/spas/dashboard/pages/view.tsx'
import Login from 'spas/Login/Login'
import { MainWrapper } from 'functional-components'
import SetupAccount from 'spas/SetupAccount/SetupAccount.js'

function MortgageBrokerRootPath(currentUser) {
  let signUpStatus = currentUser.signUpStatus

  if (!currentUser.id) {
    return <Login />
  } else if (signUpStatus === 'create_account') {
    return <SetupAccount currentUser={currentUser} />
  } else if (signUpStatus === 'accept_terms_and_conditions') {
    return <SetupAccount currentUser={currentUser} />
  } else {
    return (
      <MainWrapper>
        <Dashboard />
      </MainWrapper>
    )
  }
}

export default MortgageBrokerRootPath
