export const FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_FAILURE'

export const FETCH_MORTGAGE_APPLICATION_REQUEST = 'FETCH_MORTGAGE_APPLICATION_REQUEST'
export const FETCH_MORTGAGE_APPLICATION_SUCCESS = 'FETCH_MORTGAGE_APPLICATION_SUCCESS'
export const FETCH_MORTGAGE_APPLICATION_FAILURE = 'FETCH_MORTGAGE_APPLICATION_FAILURE'

export const CREATE_MORTGAGE_APPLICATION_REQUEST = 'CREATE_MORTGAGE_APPLICATION_REQUEST'
export const CREATE_MORTGAGE_APPLICATION_SUCCESS = 'CREATE_MORTGAGE_APPLICATION_SUCCESS'
export const CREATE_MORTGAGE_APPLICATION_FAILURE = 'CREATE_MORTGAGE_APPLICATION_FAILURE'

export const UPDATE_MORTGAGE_APPLICATION_REQUEST = 'UPDATE_MORTGAGE_APPLICATION_REQUEST'
export const UPDATE_MORTGAGE_APPLICATION_SUCCESS = 'UPDATE_MORTGAGE_APPLICATION_SUCCESS'
export const UPDATE_MORTGAGE_APPLICATION_FAILURE = 'UPDATE_MORTGAGE_APPLICATION_FAILURE'

export const ARCHIVE_MORTGAGE_APPLICATION_REQUEST = 'ARCHIVE_MORTGAGE_APPLICATION_REQUEST'
export const ARCHIVE_MORTGAGE_APPLICATION_SUCCESS = 'ARCHIVE_MORTGAGE_APPLICATION_SUCCESS'
export const ARCHIVE_MORTGAGE_APPLICATION_FAILURE = 'ARCHIVE_MORTGAGE_APPLICATION_FAILURE'

export const UNARCHIVE_MORTGAGE_APPLICATION_REQUEST = 'UNARCHIVE_MORTGAGE_APPLICATION_REQUEST'
export const UNARCHIVE_MORTGAGE_APPLICATION_SUCCESS = 'UNARCHIVE_MORTGAGE_APPLICATION_SUCCESS'
export const UNARCHIVE_MORTGAGE_APPLICATION_FAILURE = 'UNARCHIVE_MORTGAGE_APPLICATION_FAILURE'

export const DELETE_MORTGAGE_APPLICATION_REQUEST = 'DELETE_MORTGAGE_APPLICATION_REQUEST'
export const DELETE_MORTGAGE_APPLICATION_SUCCESS = 'DELETE_MORTGAGE_APPLICATION_SUCCESS'
export const DELETE_MORTGAGE_APPLICATION_FAILURE = 'DELETE_MORTGAGE_APPLICATION_FAILURE'