import React from 'react'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'
import GreenTick from 'assets/green-tick.png'
import RedExclamationPoint from 'assets/red-exclamation-point.png'

const InputIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 10px;
`

function InputValidationIcon(props) {
  if (props.touched && props.error) {
    return (
      <InputIcon>
        <Image src={RedExclamationPoint} />
      </InputIcon>
    )
  } else if (props.touched && !props.error) {
    return (
      <InputIcon>
        <Image src={GreenTick} />
      </InputIcon>
    )
  } else {
    return null
  }
}

export default InputValidationIcon
