import {EMAIL_PERSONAL_DATA_REQUEST} from 'redux/actionTypes.js'
import {EMAIL_PERSONAL_DATA_SUCCESS} from 'redux/actionTypes.js'
import {EMAIL_PERSONAL_DATA_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function emailPersonalData(handleResponse) {
  return function (dispatch) {
    dispatch(emailPersonalDataRequest())

    let variables = {}

    let query = `
      mutation {
        emailPersonalData {
          message
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(emailPersonalDataFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(emailPersonalDataSuccess())
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(emailPersonalDataFailure())
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const emailPersonalDataRequest = () => ({
  type: EMAIL_PERSONAL_DATA_REQUEST,
})

export const emailPersonalDataSuccess = () => ({
  type: EMAIL_PERSONAL_DATA_SUCCESS,
})

export const emailPersonalDataFailure = error => ({
  type: EMAIL_PERSONAL_DATA_FAILURE,
  payload: {
    error: error,
  },
})
