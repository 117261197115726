export const FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_FAILURE'

export const FETCH_SALE_LISTING_REQUEST = 'FETCH_SALE_LISTING_REQUEST'
export const FETCH_SALE_LISTING_SUCCESS = 'FETCH_SALE_LISTING_SUCCESS'
export const FETCH_SALE_LISTING_FAILURE = 'FETCH_SALE_LISTING_FAILURE'

export const CREATE_SALE_LISTING_REQUEST = 'CREATE_SALE_LISTING_REQUEST'
export const CREATE_SALE_LISTING_SUCCESS = 'CREATE_SALE_LISTING_SUCCESS'
export const CREATE_SALE_LISTING_FAILURE = 'CREATE_SALE_LISTING_FAILURE'

export const UPDATE_SALE_LISTING_REQUEST = 'UPDATE_SALE_LISTING_REQUEST'
export const UPDATE_SALE_LISTING_SUCCESS = 'UPDATE_SALE_LISTING_SUCCESS'
export const UPDATE_SALE_LISTING_FAILURE = 'UPDATE_SALE_LISTING_FAILURE'

export const ARCHIVE_SALE_LISTING_REQUEST = 'ARCHIVE_SALE_LISTING_REQUEST'
export const ARCHIVE_SALE_LISTING_SUCCESS = 'ARCHIVE_SALE_LISTING_SUCCESS'
export const ARCHIVE_SALE_LISTING_FAILURE = 'ARCHIVE_SALE_LISTING_FAILURE'

export const UNARCHIVE_SALE_LISTING_REQUEST = 'UNARCHIVE_SALE_LISTING_REQUEST'
export const UNARCHIVE_SALE_LISTING_SUCCESS = 'UNARCHIVE_SALE_LISTING_SUCCESS'
export const UNARCHIVE_SALE_LISTING_FAILURE = 'UNARCHIVE_SALE_LISTING_FAILURE'

export const DELETE_SALE_LISTING_REQUEST = 'DELETE_SALE_LISTING_REQUEST'
export const DELETE_SALE_LISTING_SUCCESS = 'DELETE_SALE_LISTING_SUCCESS'
export const DELETE_SALE_LISTING_FAILURE = 'DELETE_SALE_LISTING_FAILURE'