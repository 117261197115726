export const FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_RENT_LISTINGS_FAILURE'

export const FETCH_RENT_LISTING_REQUEST = 'FETCH_RENT_LISTING_REQUEST'
export const FETCH_RENT_LISTING_SUCCESS = 'FETCH_RENT_LISTING_SUCCESS'
export const FETCH_RENT_LISTING_FAILURE = 'FETCH_RENT_LISTING_FAILURE'

export const CREATE_RENT_LISTING_REQUEST = 'CREATE_RENT_LISTING_REQUEST'
export const CREATE_RENT_LISTING_SUCCESS = 'CREATE_RENT_LISTING_SUCCESS'
export const CREATE_RENT_LISTING_FAILURE = 'CREATE_RENT_LISTING_FAILURE'

export const UPDATE_RENT_LISTING_REQUEST = 'UPDATE_RENT_LISTING_REQUEST'
export const UPDATE_RENT_LISTING_SUCCESS = 'UPDATE_RENT_LISTING_SUCCESS'
export const UPDATE_RENT_LISTING_FAILURE = 'UPDATE_RENT_LISTING_FAILURE'

export const ARCHIVE_RENT_LISTING_REQUEST = 'ARCHIVE_RENT_LISTING_REQUEST'
export const ARCHIVE_RENT_LISTING_SUCCESS = 'ARCHIVE_RENT_LISTING_SUCCESS'
export const ARCHIVE_RENT_LISTING_FAILURE = 'ARCHIVE_RENT_LISTING_FAILURE'

export const UNARCHIVE_RENT_LISTING_REQUEST = 'UNARCHIVE_RENT_LISTING_REQUEST'
export const UNARCHIVE_RENT_LISTING_SUCCESS = 'UNARCHIVE_RENT_LISTING_SUCCESS'
export const UNARCHIVE_RENT_LISTING_FAILURE = 'UNARCHIVE_RENT_LISTING_FAILURE'

export const DELETE_RENT_LISTING_REQUEST = 'DELETE_RENT_LISTING_REQUEST'
export const DELETE_RENT_LISTING_SUCCESS = 'DELETE_RENT_LISTING_SUCCESS'
export const DELETE_RENT_LISTING_FAILURE = 'DELETE_RENT_LISTING_FAILURE'