import update from 'immutability-helper'
import {FETCH_COMPANY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMPANY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMPANY_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_COMPANY_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_COMPANY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMPANY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMPANY_OWNED_PROPERTIES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMPANY_OWNED_PROPERTIES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_COMPANY_OWNED_PROPERTIES_FAILURE} from 'redux/actionTypes.js'

const initialState = {
  fetchCompanyStatus: 'requested',
  fetchCompanyError: '',
  company: {},
  fetchCompanyShareholdersStatus: 'requested',
  fetchCompanyShareholdersError: '',
  shareholders: [],
  fetchCompanyDirectorsStatus: 'requested',
  fetchCompanyDirectorsError: '',
  directors: [],
  fetchComanyOwnedPropertiesStatus: 'requested',
  fetchComanyOwnedPropertiesError: '',
  ownedProperties: [],
  fetchCompanyDocumentsStatus: 'requested',
  fetchCompanyDocumentsError: '',
  documents: [],
  fetchCompanyBankStatementsStatus: 'requested',
  fetchCompanyBankStatementsError: '',
  bankStatements: [],
  fetchCompanyIdentityDocumentsStatus: 'requested',
  fetchCompanyIdentityDocumentsError: '',
  identityDocuments: [],
  fetchCompanyTaxDocumentsStatus: 'requested',
  fetchCompanyTaxDocumentsError: '',
  taxDocuments: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_REQUEST: {
      return update(state, {
        fetchCompanyStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMPANY_FAILURE: {
      return update(state, {
        fetchCompanyStatus: {
          $set: 'error',
        },
        fetchCompanyError: {$set: action.payload.error},
      })
    }

    case FETCH_COMPANY_SUCCESS: {
      return update(state, {
        fetchCompanyStatus: {$set: 'success'},
        company: {
          $set: action.payload.company,
        },
      })
    }

    case FETCH_COMPANY_OWNED_PROPERTIES_REQUEST: {
      return update(state, {
        fetchCompanyOwnedPropertiesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMPANY_OWNED_PROPERTIES_FAILURE: {
      return update(state, {
        fetchCompanyOwnedPropertiesStatus: {
          $set: 'error',
        },
        fetchCompanyOwnedPropertiesError: {$set: action.payload.error},
      })
    }

    case FETCH_COMPANY_OWNED_PROPERTIES_SUCCESS: {
      return update(state, {
        fetchCompanyOwnedPropertiesStatus: {$set: 'success'},
        ownedProperties: {
          $set: action.payload.ownedProperties,
        },
      })
    }

    case UPDATE_COMPANY_REQUEST: {
      return state
    }

    case UPDATE_COMPANY_FAILURE: {
      return state
    }

    case UPDATE_COMPANY_SUCCESS: {
      return update(state, {
        company: {$set: action.payload.company},
      })
    }

    default:
      return state
  }
}
