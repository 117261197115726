import update from 'immutability-helper'
import { FETCH_DOCUMENT_REQUEST } from 'v2/spas/tenancy/documents/redux/action-types.tsx'
import { FETCH_DOCUMENT_SUCCESS } from 'v2/spas/tenancy/documents/redux/action-types.tsx'
import { FETCH_DOCUMENT_FAILURE } from 'v2/spas/tenancy/documents/redux/action-types.tsx'
import { UPDATE_DOCUMENT_REQUEST } from 'v2/spas/tenancy/documents/redux/action-types.tsx'
import { UPDATE_DOCUMENT_SUCCESS } from 'v2/spas/tenancy/documents/redux/action-types.tsx'
import { UPDATE_DOCUMENT_FAILURE } from 'v2/spas/tenancy/documents/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  document: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENT_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_DOCUMENT_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_DOCUMENT_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        document: {
          $set: action.payload.document,
        }
      })
    }

    case UPDATE_DOCUMENT_REQUEST: {
      return update(state, {
        updateDocumentStatus: { $set: 'requested' },
      })
    }

    case UPDATE_DOCUMENT_FAILURE: {
      return update(state, {
        updateDocumentStatus: {
          $set: 'error',
        },
        updateDocumentError: { $set: action.payload.error },
      })
    }

    case UPDATE_DOCUMENT_SUCCESS: {
      return update(state, {
        updateDocumentStatus: { $set: 'success' },
        document: {
          $set: action.payload.document,
        }
      })
    }

    default:
      return state
  }
}