export const FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_REQUEST = 'FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_REQUEST'
export const FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_SUCCESS = 'FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_SUCCESS'
export const FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_FAILURE = 'FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_FAILURE'

export const FETCH_INSURANCE_POLICY_REQUEST = 'FETCH_INSURANCE_POLICY_REQUEST'
export const FETCH_INSURANCE_POLICY_SUCCESS = 'FETCH_INSURANCE_POLICY_SUCCESS'
export const FETCH_INSURANCE_POLICY_FAILURE = 'FETCH_INSURANCE_POLICY_FAILURE'

export const CREATE_INSURANCE_POLICY_REQUEST = 'CREATE_INSURANCE_POLICY_REQUEST'
export const CREATE_INSURANCE_POLICY_SUCCESS = 'CREATE_INSURANCE_POLICY_SUCCESS'
export const CREATE_INSURANCE_POLICY_FAILURE = 'CREATE_INSURANCE_POLICY_FAILURE'

export const UPDATE_INSURANCE_POLICY_REQUEST = 'UPDATE_INSURANCE_POLICY_REQUEST'
export const UPDATE_INSURANCE_POLICY_SUCCESS = 'UPDATE_INSURANCE_POLICY_SUCCESS'
export const UPDATE_INSURANCE_POLICY_FAILURE = 'UPDATE_INSURANCE_POLICY_FAILURE'

export const ARCHIVE_INSURANCE_POLICY_REQUEST = 'ARCHIVE_INSURANCE_POLICY_REQUEST'
export const ARCHIVE_INSURANCE_POLICY_SUCCESS = 'ARCHIVE_INSURANCE_POLICY_SUCCESS'
export const ARCHIVE_INSURANCE_POLICY_FAILURE = 'ARCHIVE_INSURANCE_POLICY_FAILURE'

export const UNARCHIVE_INSURANCE_POLICY_REQUEST = 'UNARCHIVE_INSURANCE_POLICY_REQUEST'
export const UNARCHIVE_INSURANCE_POLICY_SUCCESS = 'UNARCHIVE_INSURANCE_POLICY_SUCCESS'
export const UNARCHIVE_INSURANCE_POLICY_FAILURE = 'UNARCHIVE_INSURANCE_POLICY_FAILURE'

export const DELETE_INSURANCE_POLICY_REQUEST = 'DELETE_INSURANCE_POLICY_REQUEST'
export const DELETE_INSURANCE_POLICY_SUCCESS = 'DELETE_INSURANCE_POLICY_SUCCESS'
export const DELETE_INSURANCE_POLICY_FAILURE = 'DELETE_INSURANCE_POLICY_FAILURE'