import React from 'react'
import styled from 'styled-components'

const Text = styled.div`
  display: flex;
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  font-size: ${props => props.fontSize || props.theme.space.m};
  padding-left: ${props => props.paddingLeft || props.theme.space.s};
  cursor: pointer;
`

function ManageSubscriptionLink(props) {
  return (
    <Text
      fontSize={props.fontSize}
      paddingLeft={props.paddingLeft}
      onClick={props.onClick}>
        Please check your subscription
    </Text>
  )
}

export default ManageSubscriptionLink
