import update from 'immutability-helper'
import { FETCH_TENANCY_TENANTS_REQUEST } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { FETCH_TENANCY_TENANTS_SUCCESS } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { FETCH_TENANCY_TENANTS_FAILURE } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { ARCHIVE_TENANT_REQUEST } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { ARCHIVE_TENANT_SUCCESS } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { ARCHIVE_TENANT_FAILURE } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { DELETE_TENANT_REQUEST } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { DELETE_TENANT_SUCCESS } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'
import { DELETE_TENANT_FAILURE } from 'v2/spas/tenancy/tenants/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  tenancy: {},
  tenants: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANCY_TENANTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_TENANCY_TENANTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_TENANCY_TENANTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        tenancy: {
          $set: action.payload.tenancy,
        },
        tenants: {
          $set: action.payload.tenancy.tenancyTenants
        },
      })
    }

    case ARCHIVE_TENANT_REQUEST: {
      return state
    }

    case ARCHIVE_TENANT_FAILURE: {
      return state
    }

    case ARCHIVE_TENANT_SUCCESS: {
      let index = state.tenants.findIndex(x => x.id === action.payload.tenant.id)

      return update(state, {
        tenants: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_TENANT_REQUEST: {
      return state
    }
    
    case DELETE_TENANT_FAILURE: {
      return state
    }
    
    case DELETE_TENANT_SUCCESS: {
      let index = state.tenants.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        tenants: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
