export const FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_WORKFLOWS_FAILURE'

export const UPDATE_WORKFLOW_REQUEST = 'UPDATE_WORKFLOW_REQUEST'
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS'
export const UPDATE_WORKFLOW_FAILURE = 'UPDATE_WORKFLOW_FAILURE'

export const ARCHIVE_WORKFLOW_REQUEST = 'ARCHIVE_WORKFLOW_REQUEST'
export const ARCHIVE_WORKFLOW_SUCCESS = 'ARCHIVE_WORKFLOW_SUCCESS'
export const ARCHIVE_WORKFLOW_FAILURE = 'ARCHIVE_WORKFLOW_FAILURE'

export const UNARCHIVE_WORKFLOW_REQUEST = 'UNARCHIVE_WORKFLOW_REQUEST'
export const UNARCHIVE_WORKFLOW_SUCCESS = 'UNARCHIVE_WORKFLOW_SUCCESS'
export const UNARCHIVE_WORKFLOW_FAILURE = 'UNARCHIVE_WORKFLOW_FAILURE'

export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST'
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS'
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE'