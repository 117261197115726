export const FETCH_OWNED_PROPERTY_DEALS_REQUEST = 'FETCH_OWNED_PROPERTY_DEALS_REQUEST'
export const FETCH_OWNED_PROPERTY_DEALS_SUCCESS = 'FETCH_OWNED_PROPERTY_DEALS_SUCCESS'
export const FETCH_OWNED_PROPERTY_DEALS_FAILURE = 'FETCH_OWNED_PROPERTY_DEALS_FAILURE'

export const FETCH_DEAL_REQUEST = 'FETCH_DEAL_REQUEST'
export const FETCH_DEAL_SUCCESS = 'FETCH_DEAL_SUCCESS'
export const FETCH_DEAL_FAILURE = 'FETCH_DEAL_FAILURE'

export const CREATE_DEAL_REQUEST = 'CREATE_DEAL_REQUEST'
export const CREATE_DEAL_SUCCESS = 'CREATE_DEAL_SUCCESS'
export const CREATE_DEAL_FAILURE = 'CREATE_DEAL_FAILURE'

export const ARCHIVE_DEAL_REQUEST = 'ARCHIVE_DEAL_REQUEST'
export const ARCHIVE_DEAL_SUCCESS = 'ARCHIVE_DEAL_SUCCESS'
export const ARCHIVE_DEAL_FAILURE = 'ARCHIVE_DEAL_FAILURE'

export const UNARCHIVE_DEAL_REQUEST = 'UNARCHIVE_DEAL_REQUEST'
export const UNARCHIVE_DEAL_SUCCESS = 'UNARCHIVE_DEAL_SUCCESS'
export const UNARCHIVE_DEAL_FAILURE = 'UNARCHIVE_DEAL_FAILURE'

export const DELETE_DEAL_REQUEST = 'DELETE_DEAL_REQUEST'
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS'
export const DELETE_DEAL_FAILURE = 'DELETE_DEAL_FAILURE'

export const CREATE_REMORTGAGE_DEAL_REQUEST = 'CREATE_REMORTGAGE_DEAL_REQUEST'
export const CREATE_REMORTGAGE_DEAL_SUCCESS = 'CREATE_REMORTGAGE_DEAL_SUCCESS'
export const CREATE_REMORTGAGE_DEAL_FAILURE = 'CREATE_REMORTGAGE_DEAL_FAILURE'

export const CREATE_PRODUCT_TRANSFER_DEAL_REQUEST = 'CREATE_PRODUCT_TRANSFER_DEAL_REQUEST'
export const CREATE_PRODUCT_TRANSFER_DEAL_SUCCESS = 'CREATE_PRODUCT_TRANSFER_DEAL_SUCCESS'
export const CREATE_PRODUCT_TRANSFER_DEAL_FAILURE = 'CREATE_PRODUCT_TRANSFER_DEAL_FAILURE'

export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST = 'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_REQUEST'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS = 'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_SUCCESS'
export const CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE = 'CREATE_COMMERCIAL_TO_RESIDENTIAL_DEAL_FAILURE'

export const CREATE_FURTHER_ADVANCE_DEAL_REQUEST = 'CREATE_FURTHER_ADVANCE_DEAL_REQUEST'
export const CREATE_FURTHER_ADVANCE_DEAL_SUCCESS = 'CREATE_FURTHER_ADVANCE_DEAL_SUCCESS'
export const CREATE_FURTHER_ADVANCE_DEAL_FAILURE = 'CREATE_FURTHER_ADVANCE_DEAL_FAILURE'