function useInvestorPackBodyHeight(preview, presentMode) {
  if (preview) {
    return '90px'
  } else if (presentMode) {
    return '85vh'
  } else {
    return '480px'
  }
}

export default useInvestorPackBodyHeight
