import update from 'immutability-helper'
import { FETCH_COMMUNITY_COMMUNITY_EVENTS_REQUEST } from 'v2/spas/community/community-events/redux/action-types.tsx'
import { FETCH_COMMUNITY_COMMUNITY_EVENTS_SUCCESS } from 'v2/spas/community/community-events/redux/action-types.tsx'
import { FETCH_COMMUNITY_COMMUNITY_EVENTS_FAILURE } from 'v2/spas/community/community-events/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  community: {},
  communityEvents: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMUNITY_COMMUNITY_EVENTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_COMMUNITY_COMMUNITY_EVENTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_COMMUNITY_COMMUNITY_EVENTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        community: {
          $set: action.payload.community,
        },
        communityEvents: {
          $set: action.payload.community.communityEvents
        },
      })
    }

    default:
      return state
  }
}
