import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { DELETE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { DELETE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'
import { DELETE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE } from 'v2/spas/prospective-property/energy-performance-certificates/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  energyPerformanceCertificates: [],
  possibleEnergyPerformanceCertificates: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        energyPerformanceCertificates: {
          $set: action.payload.prospectiveProperty.property.energyPerformanceCertificates,
        },
      })
    }

    case ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST: {
      return state
    }

    case ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE: {
      return state
    }

    case ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS: {
      let index = state.energyPerformanceCertificates.findIndex(x => x.id === action.payload.energyPerformanceCertificate.id)

      return update(state, {
        energyPerformanceCertificates: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        possibleEnergyPerformanceCertificates: {
          $set: action.payload.property.possibleEnergyPerformanceCertificates,
        },
      })
    }

    case DELETE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST: {
      return state
    }

    case DELETE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE: {
      return state
    }

    case DELETE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS: {
      let index = state.energyPerformanceCertificates.findIndex(x => x.id === action.payload.id)

      return update(state, {
        energyPerformanceCertificates: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
