import React, {useState} from 'react'
import styled from 'styled-components'
import {useField} from 'formik'
import LabelWrapper from 'sharedComponents/Forms/LabelWrapper.js'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;

  #label-wrapper {
    display: flex;
    justify-content: space-between
    flex-wrap: wrap;

    #label {
      padding-left: ${props => props.theme.space.m};

      #label-text {
        font-weight: normal;
        padding-bottom: 0px;
      }
    }
  }

  input[type='checkbox']{
    accent-color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
`

function CheckboxWrapper(props) {
  const [field, meta] = useField(props.name)
  const [checked, setChecked] = useState(props.checked || false)

  function toggleChecked() {
    if (checked) {
      setChecked(false)
    } else {
      setChecked(true)
    }
  }

  function handleChange(event) {
    if (props.submitOnChange) {
      toggleChecked()
      props.submitOnChange(event, props.handleChange, props.submitForm)
    } else {
      toggleChecked()
      props.handleChange()
    }
  }

  let input

  if (!props.handleChange) {
    input = (
      <input
        id={props.id}
        className="checkbox-input"
        {...field}
        name={props.name}
        type="checkbox"
        checked={checked}
      />
    )
  } else {
    input = (
      <input
        id={props.id}
        className="checkbox-input"
        {...field}
        name={props.name}
        type="checkbox"
        onChange={handleChange}
        checked={checked}
      />
    )
  }

  return (
    <Wrapper>
      {input}
      <LabelWrapper
        label={props.label}
        name={props.name}
        toolTipContent={props.toolTipContent}
        required={props.required}
      />
    </Wrapper>
  )
}

export default CheckboxWrapper
