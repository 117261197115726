import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {SubmitButton} from 'buttons'
import TextInput from 'sharedComponents/Forms/TextInput.js'
import CheckboxInput from 'sharedComponents/Forms/CheckboxInput.js'
import {setupAccount} from 'redux/actions/profile/setupAccount'
import {joinAccount} from 'redux/actions/profile/joinAccount'
import {connect} from 'react-redux'
import {useSnackbar} from 'notistack'
import {SelectInput} from 'forms'
import styled from 'styled-components'
import { useCurrentUser } from 'sharedComponents/useCurrentUser'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Wrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};
`

const Label = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.space.m};
  padding-bottom: ${props => props.theme.space.m};
`
const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/

const FormSchema = Yup.object().shape({
  accountName: Yup.string().required('Required field *'),
  firstName: Yup.string().required('Required field *'),
  lastName: Yup.string().required('Required field *'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
  currentStage: Yup.string().required('Required field *'),
  termsAndConditions: Yup.bool().oneOf([true], 'Must accept terms'),
  processPropertyInvestmentData: Yup.bool().oneOf([true], 'Must grant consent'),
})

function SetupAccountForm(props) {
  const {enqueueSnackbar} = useSnackbar()

  let currentUser = useCurrentUser()
  let currentAccount = currentUser.currentAccount || {}
  let currentAccountUser = currentUser.currentAccountUser || {}
  let signUpDetail = currentUser.signUpDetail || {}
  let marketingSource = signUpDetail.marketingSource
  let tenant = currentAccountUser.tenant || {}

  function handleResponse(response) {
    if (response.status === 'success') {
      enqueueSnackbar('Account updated successfully!')
      window.location.reload()
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      window.setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  let termsAndConditionsLabel = (
    <span>
      I accept the{' '}
      <a href="/terms-and-conditions" rel="noopener" target="_blank">
        terms & conditions
      </a>
    </span>
  )

  function renderTeamName() {
    if (currentAccount.name) {
      return (
        <TextInput
          required
          disabled
          label="Team name"
          name="accountName"
          type="string"
        />
      )
    } else {
      return(
        <TextInput 
          label="Team name" 
          name="accountName" 
          type="string" 
          toolTipContent="The current or intended trading name of your property business. You can always change this later." 
          required
        />
      )
    }
  }

  function renderPhone() {
    if (marketingSource !== 'invited_to_team') {
      return(
        <TextInput
          label="Phone (international format e.g. +44)"
          name="phone"
          type="string"
          placeholder="+44"
          required
        />
      )
    }
  }

  function renderCurrentStage() {
    if (marketingSource !== 'invited_to_team') {
      return (
        <SelectInput
          label="What stage are you at currently?"
          name="currentStage"
          type="string"
          options={[
            { name: "Planning - I'm just starting out", value: 'planning' },
            { name: "Village - I own 1-3 properties", value: 'village' },
            { name: "Town - I own 3-5 properties", value: 'town' },
            { name: "City - I own 5-10 properties", value: 'city' },
            { name: "Nation - I own more than 10 properties", value: 'nation' },
          ]}
          required
        />
      )
    }
  }

  function renderInterests(values, setFieldValue) {
    if (marketingSource !== 'invited_to_team') {
      return(
        <div>
          <Label>
            Which aspects of Stacked are you interested in? *(tick all that apply)
          </Label>

          <Row>
            <Col xs='6'>
              <CheckboxInput
                label='Stacking deals'
                name="interestedInStackingDeals"
                checked={values.interestedInStackingDeals}
                handleChange={() =>
                  setFieldValue('interestedInStackingDeals', !values.interestedInStackingDeals)
                }
              />
            </Col>

            <Col xs='6'>
              <CheckboxInput
                label='Raising public finance (mortgages & bridging)'
                name="interestedInRaisingPublicFinance"
                checked={values.interestedInRaisingPublicFinance}
                handleChange={() =>
                  setFieldValue('interestedInRaisingPublicFinance', !values.interestedInRaisingPublicFinance)
                }
              />
            </Col>

            <Col xs='6'>
              <CheckboxInput
                label='Raising private finance (from private investors)'
                name="interestedInRaisingPrivateFinance"
                checked={values.interestedInRaisingPrivateFinance}
                handleChange={() =>
                  setFieldValue('interestedInRaisingPrivateFinance', !values.interestedInRaisingPrivateFinance)
                }
              />
            </Col>

            <Col xs='6'>
              <CheckboxInput
                label='Managing my portfolio'
                name="interestedInPortfolioManagement"
                checked={values.interestedInPortfolioManagement}
                handleChange={() =>
                  setFieldValue('interestedInPortfolioManagement', !values.interestedInPortfolioManagement)
                }
              />
            </Col>

            <Col xs='6'>
              <CheckboxInput
                label="Project management & team communication"
                name="interestedInWorkflows"
                checked={values.interestedInWorkflows}
                handleChange={() =>
                  setFieldValue('interestedInWorkflows', !values.interestedInWorkflows)
                }
              />
            </Col>

            <Col xs='6'>
              <CheckboxInput
                label='Learning how to invest in property'
                name="interestedInTraining"
                checked={values.interestedInTraining}
                handleChange={() =>
                  setFieldValue('interestedInTraining', !values.interestedInTraining)
                }
              />
            </Col>

            <Col xs='6'>
              <CheckboxInput
                label='Connecting with more property professionals'
                name="interestedInCommunity"
                checked={values.interestedInCommunity}
                handleChange={() =>
                  setFieldValue('interestedInCommunity', !values.interestedInCommunity)
                }
              />
            </Col>

            <Col xs='6'>
              <CheckboxInput
                label='Coaching, mentoring & support'
                name="interestedInCoaching"
                checked={values.interestedInCoaching}
                handleChange={() =>
                  setFieldValue('interestedInCoaching', !values.interestedInCoaching)
                }
              />
            </Col>
          </Row>
        </div>
      )
    }
  }

  function submitButtonText() {
    if (marketingSource === 'invited_to_team') {
      return 'Join team'
    } else if(marketingSource === 'added_as_tenant') {
      return 'Create my account'
    } else {
      return 'Complete account setup'
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          accountName: currentAccount.name || '',
          firstName: currentAccountUser.firstName,
          lastName: currentAccountUser.lastName,
          termsAndConditions: false,
          currentStage: signUpDetail.currentStage,
          processPropertyInvestmentData: tenant.id ? true : false,
          interestedInStackingDeals: false,
          interestedInRaisingPublicFinance: false,
          interestedInRaisingPrivateFinance: false,
          interestedInPortfolioManagement: false,
          interestedInWorkflows: false,
          interestedInTraining: false,
          interestedInCommunity: false,
          interestedInCoaching: false,

        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          if (currentAccount.name) {
            props.joinAccount(values, handleResponse)
          } else {
            props.setupAccount(values, handleResponse)
          }
        }}
      >
        {({isSubmitting, setFieldValue, values}) => (
          <Form>
            {renderTeamName()}

            <TextInput label="First name" name="firstName" type="string" required />

            <TextInput label="Last name" name="lastName" type="string" required />

            {renderPhone()}

            {renderCurrentStage()}

            {/* {renderInterests(values, setFieldValue)} */}

            <Label>
              Please help us to protect your data & respect your privacy
            </Label>

            <CheckboxInput
              label="I consent to my data being processed for property investment activities"
              name="processPropertyInvestmentData"
              toolTipContent="This is to ensure we comply with the General Data Protection Regulations (GDPR)"
              checked={values.processPropertyInvestmentData}
              handleChange={() =>
                setFieldValue(
                  'processPropertyInvestmentData',
                  !values.processPropertyInvestmentData,
                )
              }
            />

            <CheckboxInput
              label={termsAndConditionsLabel}
              name="termsAndConditions"
              checked={values.termsAndConditions}
              handleChange={() =>
                setFieldValue('termsAndConditions', !values.termsAndConditions)
              }
            />

            <SubmitButton content={submitButtonText()} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  setupAccount: setupAccount,
  joinAccount: joinAccount,
}

export default connect(null, mapDispatchToProps)(SetupAccountForm)
