import update from 'immutability-helper'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchServicedAccommodationDealStatus: 'requested',
  fetchServicedAccommodationDealError: '',
  servicedAccommodationDeal: {
    deal: {},
  },
  fetchServicedAccommodationDealTemplatesStatus: 'requested',
  fetchServicedAccommodationDealTemplatesError: '',
  dealTemplates: [],
  fetchServicedAccommodationDealFixedPurchaseExpensesStatus: 'requested',
  fetchServicedAccommodationDealFixedPurchaseExpensesError: '',
  fixedPurchaseExpenses: [],
  fetchServicedAccommodationDealVariablePurchaseExpensesStatus: 'requested',
  fetchServicedAccommodationDealVariablePurchaseExpensesError: '',
  variablePurchaseExpenses: [],
  fetchServicedAccommodationDealDevelopmentExpensesStatus: 'requested',
  fetchServicedAccommodationDealDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchServicedAccommodationDealFixedRecurringExpensesStatus: 'requested',
  fetchServicedAccommodationDealFixedRecurringExpensesError: '',
  fixedRecurringExpenses: [],
  fetchServicedAccommodationDealVariableRecurringExpensesStatus: 'requested',
  fetchServicedAccommodationDealVariableRecurringExpensesError: '',
  variableRecurringExpenses: [],
  fetchServicedAccommodationDealDuringDevelopmentExpensesStatus: 'requested',
  fetchServicedAccommodationDealDuringDevelopmentExpensesError: '',
  duringDevelopmentExpenses: [],
  fetchServicedAccommodationDealMortgageStatus: 'requested',
  fetchServicedAccommodationDealMortgageError: '',
  mortgage: {
    prospectiveMortgage: {},
  },
  fetchServicedAccommodationDealRemortgageStatus: 'requested',
  fetchServicedAccommodationDealRemortgageError: '',
  remortgage: {
    prospectiveMortgage: {},
  },
  fetchServicedAccommodationDealCashFlowSummaryStatus: 'requested',
  fetchServicedAccommodationDealCashFlowSummaryError: '',
  cashFlowSummary: {
    schedule: [],
  },
  fetchHmoDealBridgingLoanStatus: 'requested',
  fetchHmoDealBridgingLoanError: '',
  bridgingLoan: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealError: {$set: action.payload.error},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealStatus: {$set: 'success'},
        servicedAccommodationDeal: {
          $set: action.payload.servicedAccommodationDeal,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealTemplatesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealTemplatesStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealTemplatesError: {$set: action.payload.error},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealTemplatesStatus: {$set: 'success'},
        dealTemplates: {
          $set: action.payload.dealTemplates,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealFixedPurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealFixedPurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealFixedPurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealFixedPurchaseExpensesStatus: {$set: 'success'},
        fixedPurchaseExpenses: {
          $set: action.payload.fixedPurchaseExpenses,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealVariablePurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealVariablePurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealVariablePurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealVariablePurchaseExpensesStatus: {$set: 'success'},
        variablePurchaseExpenses: {
          $set: action.payload.variablePurchaseExpenses,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealDevelopmentExpensesStatus: {$set: 'success'},
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealFixedRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealFixedRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealFixedRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealFixedRecurringExpensesStatus: {$set: 'success'},
        fixedRecurringExpenses: {
          $set: action.payload.fixedRecurringExpenses,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealVariableRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealVariableRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealVariableRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealVariableRecurringExpensesStatus: {$set: 'success'},
        variableRecurringExpenses: {
          $set: action.payload.variableRecurringExpenses,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealDuringDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealDuringDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealDuringDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealDuringDevelopmentExpensesStatus: {$set: 'success'},
        duringDevelopmentExpenses: {
          $set: action.payload.duringDevelopmentExpenses,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealMortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealMortgageStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealMortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_MORTGAGE_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealMortgageStatus: {$set: 'success'},
        mortgage: {
          $set: action.payload.mortgage,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealRemortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealRemortgageStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealRemortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_REMORTGAGE_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealRemortgageStatus: {$set: 'success'},
        remortgage: {
          $set: action.payload.remortgage,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealCashFlowSummaryStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealCashFlowSummaryStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealCashFlowSummaryError: {$set: action.payload.error},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealCashFlowSummaryStatus: {$set: 'success'},
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS: {
      let bridgingLoan = action.payload.servicedAccommodationDeal.bridgingLoan || {}
      let prospectiveBridgingLoan = bridgingLoan.prospectiveBridgingLoan || {}

      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          totalProfit: { $set: action.payload.servicedAccommodationDeal.totalProfit },
          totalProjectCost: { $set: action.payload.servicedAccommodationDeal.totalProjectCost },
          developmentCosts: {
            $set: action.payload.servicedAccommodationDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.servicedAccommodationDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.servicedAccommodationDeal.bridgingLoan,
        },
      })
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          developmentCosts: {
            $set: action.payload.servicedAccommodationDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.servicedAccommodationDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.servicedAccommodationDeal.mortgage,
        },
      })
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.servicedAccommodationDeal.remortgage,
        },
      })
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.servicedAccommodationDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.servicedAccommodationDeal.mortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.servicedAccommodationDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          prospectiveMortgage: {
            term: {
              $set:
                action.payload.servicedAccommodationDeal.mortgage.prospectiveMortgage.term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.servicedAccommodationDeal.mortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.servicedAccommodationDeal.mortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.servicedAccommodationDeal.mortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.servicedAccommodationDeal.mortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.servicedAccommodationDeal.mortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.servicedAccommodationDeal.remortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          prospectiveMortgage: {
            term: {
              $set:
                action.payload.servicedAccommodationDeal.remortgage.prospectiveMortgage
                  .term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.servicedAccommodationDeal.remortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.servicedAccommodationDeal.remortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.servicedAccommodationDeal.remortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.servicedAccommodationDeal.remortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.servicedAccommodationDeal.remortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return state
    }

    case RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return state
    }

    case RECOMPILE_SERVICED_ACCOMMODATION_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseType: {$set: action.payload.servicedAccommodationDeal.purchaseType},
          purchasePrice: {$set: action.payload.servicedAccommodationDeal.purchasePrice},
          totalProfit: { $set: action.payload.servicedAccommodationDeal.totalProfit },
          totalProjectCost: { $set: action.payload.servicedAccommodationDeal.totalProjectCost },
          entryFinanceValue: { $set: action.payload.servicedAccommodationDeal.entryFinanceValue },
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.servicedAccommodationDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyRent: {$set: action.payload.servicedAccommodationDeal.monthlyRent},
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          stampDuty: { $set: action.payload.servicedAccommodationDeal.stampDuty },
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.servicedAccommodationDeal.endValue},
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
          purchaseTerm: {$set: action.payload.servicedAccommodationDeal.purchaseTerm},
          refurbTerm: {$set: action.payload.servicedAccommodationDeal.refurbTerm},
          refinanceTerm: {$set: action.payload.servicedAccommodationDeal.refinanceTerm},
        },
        mortgage: {
          $set: action.payload.servicedAccommodationDeal.mortgage,
        },
        remortgage: {
          $set: action.payload.servicedAccommodationDeal.remortgage,
        },
        bridgingLoan: {
          $set: action.payload.servicedAccommodationDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          deal: {
            name: {
              $set: action.payload.deal.name,
            },
          },
        },
      })
    }

    case CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case CLEAR_EXPENSES_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.servicedAccommodationDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
          mortgage: {
            $set: action.payload.servicedAccommodationDeal.mortgage,
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.servicedAccommodationDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.servicedAccommodationDeal.bridgingLoan,
        },
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_REQUEST: {
      return update(state, {
        fetchServicedAccommodationDealBridgingLoanStatus: {$set: 'requested'},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_FAILURE: {
      return update(state, {
        fetchServicedAccommodationDealBridgingLoanStatus: {
          $set: 'error',
        },
        fetchServicedAccommodationDealBridgingLoanError: {$set: action.payload.error},
      })
    }

    case FETCH_SERVICED_ACCOMMODATION_DEAL_BRIDGING_LOAN_SUCCESS: {
      return update(state, {
        fetchServicedAccommodationDealBridgingLoanStatus: {$set: 'success'},
        bridgingLoan: {
          $set: action.payload.bridgingLoan,
        },
      })
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.servicedAccommodationDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          purchaseCosts: {$set: action.payload.servicedAccommodationDeal.purchaseCosts},
          cashRequired: {$set: action.payload.servicedAccommodationDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.servicedAccommodationDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.servicedAccommodationDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.servicedAccommodationDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.servicedAccommodationDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.servicedAccommodationDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.servicedAccommodationDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.servicedAccommodationDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.servicedAccommodationDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.servicedAccommodationDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.servicedAccommodationDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          prospectiveBridgingLoan: {
            term: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.term,
            },
            interestRate: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.interestRate,
            },
            repaymentType: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.repaymentType,
            },
            netAmount: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.netAmount,
            },
            interestAmount: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.interestAmount,
            },
            rebateAmount: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.rebateAmount,
            },
            monthlyPayment: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.monthlyPayment,
            },
            developmentFinanceBuffer: {
              $set:
                action.payload.servicedAccommodationDeal.bridgingLoan
                  .prospectiveBridgingLoan.developmentFinanceBuffer,
            },
          },
        },
      })
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_REQUEST: {
      return state
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_FAILURE: {
      return state
    }

    case UPDATE_SERVICED_ACCOMMODATION_DEAL_SUPPORT_NEEDED_SUCCESS: {
      return update(state, {
        servicedAccommodationDeal: {
          deal: {
            stackingSupportNeeded: {
              $set: action.payload.stackingSupportNeeded
            }
          },
        },
      })
    }

    default:
      return state
  }
}
