import update from 'immutability-helper'
import {FETCH_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_TEMPLATES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_TEMPLATES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_TEMPLATES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_REMORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_REMORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_REMORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchSingleLetDealStatus: 'requested',
  fetchSingleLetDealError: '',
  singleLetDeal: {
    deal: {},
  },
  fetchSingleLetDealTemplatesStatus: 'requested',
  fetchSingleLetDealTemplatesError: '',
  dealTemplates: [],
  fetchSingleLetDealFixedPurchaseExpensesStatus: 'requested',
  fetchSingleLetDealFixedPurchaseExpensesError: '',
  fixedPurchaseExpenses: [],
  fetchSingleLetDealVariablePurchaseExpensesStatus: 'requested',
  fetchSingleLetDealVariablePurchaseExpensesError: '',
  variablePurchaseExpenses: [],
  fetchSingleLetDealDevelopmentExpensesStatus: 'requested',
  fetchSingleLetDealDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchSingleLetDealFixedRecurringExpensesStatus: 'requested',
  fetchSingleLetDealFixedRecurringExpensesError: '',
  fixedRecurringExpenses: [],
  fetchSingleLetDealVariableRecurringExpensesStatus: 'requested',
  fetchSingleLetDealVariableRecurringExpensesError: '',
  variableRecurringExpenses: [],
  fetchSingleLetDealDuringDevelopmentExpensesStatus: 'requested',
  fetchSingleLetDealDuringDevelopmentExpensesError: '',
  duringDevelopmentExpenses: [],
  fetchSingleLetDealMortgageStatus: 'requested',
  fetchSingleLetDealMortgageError: '',
  mortgage: {
    prospectiveMortgage: {},
  },
  fetchSingleLetDealRemortgageStatus: 'requested',
  fetchSingleLetDealRemortgageError: '',
  remortgage: {
    prospectiveMortgage: {},
  },
  fetchSingleLetDealCashFlowSummaryStatus: 'requested',
  fetchSingleLetDealCashFlowSummaryError: '',
  cashFlowSummary: {
    schedule: [],
  },
  fetchHmoDealBridgingLoanStatus: 'requested',
  fetchHmoDealBridgingLoanError: '',
  bridgingLoan: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SINGLE_LET_DEAL_REQUEST: {
      return update(state, {
        fetchSingleLetDealStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_FAILURE: {
      return update(state, {
        fetchSingleLetDealStatus: {
          $set: 'error',
        },
        fetchSingleLetDealError: {$set: action.payload.error},
      })
    }

    case FETCH_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        fetchSingleLetDealStatus: {$set: 'success'},
        singleLetDeal: {
          $set: action.payload.singleLetDeal,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        fetchSingleLetDealTemplatesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        fetchSingleLetDealTemplatesStatus: {
          $set: 'error',
        },
        fetchSingleLetDealTemplatesError: {$set: action.payload.error},
      })
    }

    case FETCH_SINGLE_LET_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchSingleLetDealTemplatesStatus: {$set: 'success'},
        dealTemplates: {
          $set: action.payload.dealTemplates,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchSingleLetDealFixedPurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchSingleLetDealFixedPurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchSingleLetDealFixedPurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchSingleLetDealFixedPurchaseExpensesStatus: {$set: 'success'},
        fixedPurchaseExpenses: {
          $set: action.payload.fixedPurchaseExpenses,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchSingleLetDealVariablePurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchSingleLetDealVariablePurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchSingleLetDealVariablePurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchSingleLetDealVariablePurchaseExpensesStatus: {$set: 'success'},
        variablePurchaseExpenses: {
          $set: action.payload.variablePurchaseExpenses,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchSingleLetDealDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchSingleLetDealDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchSingleLetDealDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchSingleLetDealDevelopmentExpensesStatus: {$set: 'success'},
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchSingleLetDealFixedRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchSingleLetDealFixedRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchSingleLetDealFixedRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchSingleLetDealFixedRecurringExpensesStatus: {$set: 'success'},
        fixedRecurringExpenses: {
          $set: action.payload.fixedRecurringExpenses,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchSingleLetDealVariableRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchSingleLetDealVariableRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchSingleLetDealVariableRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchSingleLetDealVariableRecurringExpensesStatus: {$set: 'success'},
        variableRecurringExpenses: {
          $set: action.payload.variableRecurringExpenses,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchSingleLetDealDuringDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchSingleLetDealDuringDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchSingleLetDealDuringDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchSingleLetDealDuringDevelopmentExpensesStatus: {$set: 'success'},
        duringDevelopmentExpenses: {
          $set: action.payload.duringDevelopmentExpenses,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_MORTGAGE_REQUEST: {
      return update(state, {
        fetchSingleLetDealMortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_MORTGAGE_FAILURE: {
      return update(state, {
        fetchSingleLetDealMortgageStatus: {
          $set: 'error',
        },
        fetchSingleLetDealMortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_SINGLE_LET_DEAL_MORTGAGE_SUCCESS: {
      return update(state, {
        fetchSingleLetDealMortgageStatus: {$set: 'success'},
        mortgage: {
          $set: action.payload.mortgage,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_REMORTGAGE_REQUEST: {
      return update(state, {
        fetchSingleLetDealRemortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_REMORTGAGE_FAILURE: {
      return update(state, {
        fetchSingleLetDealRemortgageStatus: {
          $set: 'error',
        },
        fetchSingleLetDealRemortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_SINGLE_LET_DEAL_REMORTGAGE_SUCCESS: {
      return update(state, {
        fetchSingleLetDealRemortgageStatus: {$set: 'success'},
        remortgage: {
          $set: action.payload.remortgage,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return update(state, {
        fetchSingleLetDealCashFlowSummaryStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return update(state, {
        fetchSingleLetDealCashFlowSummaryStatus: {
          $set: 'error',
        },
        fetchSingleLetDealCashFlowSummaryError: {$set: action.payload.error},
      })
    }

    case FETCH_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        fetchSingleLetDealCashFlowSummaryStatus: {$set: 'success'},
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_SINGLE_LET_DEAL_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_SINGLE_LET_DEAL_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_SINGLE_LET_DEAL_EXPENSES_SUCCESS: {
      let bridgingLoan = action.payload.singleLetDeal.bridgingLoan || {}
      let prospectiveBridgingLoan = bridgingLoan.prospectiveBridgingLoan || {}

      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          totalProfit: { $set: action.payload.singleLetDeal.totalProfit },
          totalProjectCost: { $set: action.payload.singleLetDeal.totalProjectCost },
          developmentCosts: {
            $set: action.payload.singleLetDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.singleLetDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.singleLetDeal.bridgingLoan,
        },
      })
    }

    case CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          developmentCosts: {
            $set: action.payload.singleLetDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_SINGLE_LET_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.singleLetDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.singleLetDeal.mortgage,
        },
      })
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.singleLetDeal.remortgage,
        },
      })
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.singleLetDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.singleLetDeal.mortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.singleLetDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        mortgage: {
          prospectiveMortgage: {
            term: {
              $set:
                action.payload.singleLetDeal.mortgage.prospectiveMortgage.term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.singleLetDeal.mortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.singleLetDeal.mortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.singleLetDeal.mortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.singleLetDeal.mortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.singleLetDeal.mortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.singleLetDeal.remortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        remortgage: {
          prospectiveMortgage: {
            term: {
              $set:
                action.payload.singleLetDeal.remortgage.prospectiveMortgage
                  .term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.singleLetDeal.remortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.singleLetDeal.remortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.singleLetDeal.remortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.singleLetDeal.remortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.singleLetDeal.remortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return state
    }

    case RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return state
    }

    case RECOMPILE_SINGLE_LET_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case UPDATE_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case UPDATE_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseType: {$set: action.payload.singleLetDeal.purchaseType},
          purchasePrice: {$set: action.payload.singleLetDeal.purchasePrice},
          totalProfit: { $set: action.payload.singleLetDeal.totalProfit },
          totalProjectCost: { $set: action.payload.singleLetDeal.totalProjectCost },
          entryFinanceValue: { $set: action.payload.singleLetDeal.entryFinanceValue },
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.singleLetDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyRent: {$set: action.payload.singleLetDeal.monthlyRent},
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          stampDuty: { $set: action.payload.singleLetDeal.stampDuty },
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.singleLetDeal.endValue},
          exitFinanceValue: { $set: action.payload.singleLetDeal.exitFinanceValue },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
          purchaseTerm: {$set: action.payload.singleLetDeal.purchaseTerm},
          refurbTerm: {$set: action.payload.singleLetDeal.refurbTerm},
          refinanceTerm: {$set: action.payload.singleLetDeal.refinanceTerm},
        },
        mortgage: {
          $set: action.payload.singleLetDeal.mortgage,
        },
        remortgage: {
          $set: action.payload.singleLetDeal.remortgage,
        },
        bridgingLoan: {
          $set: action.payload.singleLetDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          deal: {
            name: {
              $set: action.payload.deal.name,
            },
          },
        },
      })
    }

    case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.singleLetDeal.developmentCosts,
          },
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
          mortgage: {
            $set: action.payload.singleLetDeal.mortgage,
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.singleLetDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.singleLetDeal.bridgingLoan,
        },
      })
    }

    case FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_REQUEST: {
      return update(state, {
        fetchSingleLetDealBridgingLoanStatus: {$set: 'requested'},
      })
    }

    case FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_FAILURE: {
      return update(state, {
        fetchSingleLetDealBridgingLoanStatus: {
          $set: 'error',
        },
        fetchSingleLetDealBridgingLoanError: {$set: action.payload.error},
      })
    }

    case FETCH_SINGLE_LET_DEAL_BRIDGING_LOAN_SUCCESS: {
      return update(state, {
        fetchSingleLetDealBridgingLoanStatus: {$set: 'success'},
        bridgingLoan: {
          $set: action.payload.bridgingLoan,
        },
      })
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.singleLetDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_SINGLE_LET_DEAL_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          purchaseCosts: {$set: action.payload.singleLetDeal.purchaseCosts},
          cashRequired: {$set: action.payload.singleLetDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.singleLetDeal.monthlyExpenses},
          duringDevelopmentCosts: { $set: action.payload.singleLetDeal.duringDevelopmentCosts },
          carryingCosts: { $set: action.payload.singleLetDeal.carryingCosts },
          monthlyCashFlow: {$set: action.payload.singleLetDeal.monthlyCashFlow},
          annualProfit: {$set: action.payload.singleLetDeal.annualProfit},
          returnOnInvestment: {
            $set: action.payload.singleLetDeal.returnOnInvestment,
          },
          projectedMonthlyCashFlow: {
            $set: action.payload.singleLetDeal.projectedMonthlyCashFlow,
          },
          projectedAnnualProfit: {
            $set: action.payload.singleLetDeal.projectedAnnualProfit,
          },
          equityReleased: {$set: action.payload.singleLetDeal.equityReleased},
          moneyLeftIn: {$set: action.payload.singleLetDeal.moneyLeftIn},
          projectedReturnOnInvestment: {
            $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
          },
        },
        bridgingLoan: {
          prospectiveBridgingLoan: {
            term: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.term,
            },
            interestRate: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.interestRate,
            },
            repaymentType: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.repaymentType,
            },
            netAmount: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.netAmount,
            },
            interestAmount: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.interestAmount,
            },
            rebateAmount: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.rebateAmount,
            },
            monthlyPayment: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.monthlyPayment,
            },
            developmentFinanceBuffer: {
              $set:
                action.payload.singleLetDeal.bridgingLoan
                  .prospectiveBridgingLoan.developmentFinanceBuffer,
            },
          },
        },
      })
    }

    case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST: {
      return state
    }

    case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE: {
      return state
    }

    case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS: {
      return update(state, {
        singleLetDeal: {
          deal: {
            stackingSupportNeeded: {
              $set: action.payload.stackingSupportNeeded
            }
          },
        },
      })
    }

    default:
      return state
  }
}
