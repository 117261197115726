import update from 'immutability-helper'
import { FETCH_ACCOUNT_USER_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { FETCH_ACCOUNT_USER_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { FETCH_ACCOUNT_USER_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_ACCOUNT_USER_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_ACCOUNT_USER_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_ACCOUNT_USER_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_AVATAR_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_AVATAR_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_AVATAR_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_CURRENT_ACCOUNT_USER_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_CURRENT_ACCOUNT_USER_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_CURRENT_ACCOUNT_USER_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  accountUser: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_USER_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        accountUser: {
          $set: action.payload.accountUser,
        }
      })
    }

    case UPDATE_ACCOUNT_USER_REQUEST: {
      return update(state, {
        updateAccountUserStatus: { $set: 'requested' },
      })
    }

    case UPDATE_ACCOUNT_USER_FAILURE: {
      return update(state, {
        updateAccountUserStatus: {
          $set: 'error',
        },
        updateAccountUserError: { $set: action.payload.error },
      })
    }

    case UPDATE_ACCOUNT_USER_SUCCESS: {
      return update(state, {
        updateAccountUserStatus: { $set: 'success' },
        accountUser: {
          $set: action.payload.accountUser,
        }
      })
    }

    case UPDATE_AVATAR_REQUEST: {
      return state
    }

    case UPDATE_AVATAR_FAILURE: {
      return state
    }

    case UPDATE_AVATAR_SUCCESS: {
      return update(state, {
        accountUser: {
          avatarUrl: {
            $set: action.payload.avatarUrl
          }
        }
      })
    }

    case UPDATE_CURRENT_ACCOUNT_USER_REQUEST: {
      return state
    }

    case UPDATE_CURRENT_ACCOUNT_USER_FAILURE: {
      return state
    }

    case UPDATE_CURRENT_ACCOUNT_USER_SUCCESS: {
      return update(state, {
        accountUser: {
          firstName: {
            $set: action.payload.firstName
          },
          lastName: {
            $set: action.payload.lastName
          },
          fullName: {
            $set: action.payload.fullName
          }
        }
      })
    }

    default:
      return state
  }
}