import React from 'react'
import PropTypes from 'prop-types'
import StatusWrapper from 'sharedComponents/StatusBanner/StatusContainer.js'
import StatusSubContent from 'sharedComponents/StatusBanner/StatusSubContent.js'
import StatusMobileTextContainer from 'sharedComponents/StatusBanner/StatusMobileTextContainer.js'
import ManageSubscriptionLink from 'sharedComponents/StatusBanner/ManageSubscriptionLink.js'
import {RenderInDevice} from 'functional-components'

const Incomplete = ({onClick}) => {
  return (
    <>
      <RenderInDevice devices={['desktop', 'laptop', 'tablet']}>
        <StatusWrapper background="#d45454">
          <StatusSubContent>There was an issue with your payment details.</StatusSubContent>
          <ManageSubscriptionLink onClick={onClick} />
        </StatusWrapper>
      </RenderInDevice>

      <RenderInDevice devices={['smallMobile', 'mobile']}>
        <StatusWrapper flexDirection="column" background="#d45454">
          <StatusMobileTextContainer fontSize="0.8rem">
            <StatusSubContent>There was an issue with your payment details.</StatusSubContent>
          </StatusMobileTextContainer>
          <ManageSubscriptionLink fontSize="0.8rem" paddingLeft={props => props.theme.space.xs} onClick={onClick} />
        </StatusWrapper>
      </RenderInDevice>
    </>
  )
}

Incomplete.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Incomplete
