import React from 'react'
import Container from 'react-bootstrap/Container'
import TopNavbar from 'sharedComponents/UserTopNavbar/UserTopNavbar.js'
import Page from 'sharedComponents/Page.js'
import styled from 'styled-components'
import PanToolIcon from '@material-ui/icons/PanTool'
import {ReturnToSafetyButton} from 'buttons'

const IconWrapper = styled.div`
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
`

const Text = styled.div`
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 32px;
`

function NotAuthorised(props) {
  let warningOnly = props.warningOnly

  if (warningOnly) {
    return(
      <div>
        <IconWrapper>
          <PanToolIcon fontSize="large" />
        </IconWrapper>
        <Text>You're not authorised to do that!</Text>
        <ReturnToSafetyButton />
      </div>
    )
  } else {
    return (
      <Container fluid>
        <TopNavbar />
        <Page paddingTop="75px">
          <IconWrapper>
            <PanToolIcon fontSize="large" />
          </IconWrapper>
          <Text>You're not authorised to do that!</Text>
          <ReturnToSafetyButton />
        </Page>
      </Container>
    )
  }
}

export default NotAuthorised
