import update from 'immutability-helper'
import { FETCH_CALENDAR_EVENTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_CALENDAR_EVENTS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_CALENDAR_EVENTS_SUCCESS } from 'redux/actionTypes.js'
import { ADD_CALENDAR_EVENT_REQUEST } from 'redux/actionTypes.js'
import { ADD_CALENDAR_EVENT_FAILURE } from 'redux/actionTypes.js'
import { ADD_CALENDAR_EVENT_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_CALENDAR_EVENT_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_CALENDAR_EVENT_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_CALENDAR_EVENT_SUCCESS } from 'redux/actionTypes.js'
import { DELETE_CALENDAR_EVENT_REQUEST } from 'redux/actionTypes.js'
import { DELETE_CALENDAR_EVENT_FAILURE } from 'redux/actionTypes.js'
import { DELETE_CALENDAR_EVENT_SUCCESS } from 'redux/actionTypes.js'
import { ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST } from 'redux/actionTypes.js'
import { ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE } from 'redux/actionTypes.js'
import { ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_REMINDER_FOR_CALENDAR_EVENT_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_REMINDER_FOR_CALENDAR_EVENT_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_REMINDER_FOR_CALENDAR_EVENT_SUCCESS } from 'redux/actionTypes.js'
import { DELETE_REMINDER_FROM_CALENDAR_EVENT_REQUEST } from 'redux/actionTypes.js'
import { DELETE_REMINDER_FROM_CALENDAR_EVENT_FAILURE } from 'redux/actionTypes.js'
import { DELETE_REMINDER_FROM_CALENDAR_EVENT_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
	fetchEventsStatus: 'requested',
	fetchEventsError: '',
	events: [],
}

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_CALENDAR_EVENTS_REQUEST: {
			return update(state, {
				fetchEventsStatus: { $set: 'requested' },
			})
		}

		case FETCH_CALENDAR_EVENTS_FAILURE: {
			return update(state, {
				fetchEventsStatus: {
					$set: 'error',
				},
				fetchEventsError: { $set: action.payload.error },
			})
		}

		case FETCH_CALENDAR_EVENTS_SUCCESS: {
			return update(state, {
				fetchEventsStatus: { $set: 'success' },
				events: {
					$set: action.payload.events,
				},
			})
		}

		case ADD_CALENDAR_EVENT_REQUEST: {
			return state
		}

		case ADD_CALENDAR_EVENT_FAILURE: {
			return state
		}

		case ADD_CALENDAR_EVENT_SUCCESS: {
			return update(state, {
				events: {
					$push: [action.payload.calendarEvent],
				},
			})
		}

		case UPDATE_CALENDAR_EVENT_REQUEST: {
			return state
		}

		case UPDATE_CALENDAR_EVENT_FAILURE: {
			return state
		}

		case UPDATE_CALENDAR_EVENT_SUCCESS: {
			let index = state.events.findIndex(
				x => x.id === action.payload.calendarEvent.id,
			)

			return update(state, {
				events: {
					[index]: { $set: action.payload.calendarEvent },
				},
			})
		}

		case DELETE_CALENDAR_EVENT_REQUEST: {
			return state
		}

		case DELETE_CALENDAR_EVENT_FAILURE: {
			return state
		}

		case DELETE_CALENDAR_EVENT_SUCCESS: {
			let index = state.events.findIndex(x => x.id === action.payload.id)

			return update(state, {
				events: {
					$splice: [[index, 1]],
				},
			})
		}

		case ADD_REMINDER_TO_CALENDAR_EVENT_REQUEST: {
			return state
		}

		case ADD_REMINDER_TO_CALENDAR_EVENT_FAILURE: {
			return state
		}

		case ADD_REMINDER_TO_CALENDAR_EVENT_SUCCESS: {
			let index = state.events.findIndex(x => x.id === action.payload.reminder.calendarEvent.id)

			return update(state, {
				events: {
					[index]: { 
						reminder: {
							$set: action.payload.reminder
						}
					 },
				},
			})
		}

		case UPDATE_REMINDER_FOR_CALENDAR_EVENT_REQUEST: {
			return state
		}

		case UPDATE_REMINDER_FOR_CALENDAR_EVENT_FAILURE: {
			return state
		}

		case UPDATE_REMINDER_FOR_CALENDAR_EVENT_SUCCESS: {
			let index = state.events.findIndex(
				x => x.id === action.payload.reminder.calendarEvent.id,
			)

			return update(state, {
				events: {
					[index]: { 
						reminder: {
							$set: action.payload.reminder
						}
					 },
				},
			})
		}

		case DELETE_REMINDER_FROM_CALENDAR_EVENT_REQUEST: {
			return state
		}

		case DELETE_REMINDER_FROM_CALENDAR_EVENT_FAILURE: {
			return state
		}

		case DELETE_REMINDER_FROM_CALENDAR_EVENT_SUCCESS: {
			let index = state.events.findIndex(
				x => x.id === action.payload.calendarEvent.id,
			)

			return update(state, {
				events: {
					[index]: { $set: action.payload.calendarEvent },
				},
			})
		}

		default:
			return state
	}
}
