export const FETCH_EXISTING_MORTGAGE_MORTGAGE_ACCOUNTS_REQUEST = 'FETCH_EXISTING_MORTGAGE_MORTGAGE_ACCOUNTS_REQUEST'
export const FETCH_EXISTING_MORTGAGE_MORTGAGE_ACCOUNTS_SUCCESS = 'FETCH_EXISTING_MORTGAGE_MORTGAGE_ACCOUNTS_SUCCESS'
export const FETCH_EXISTING_MORTGAGE_MORTGAGE_ACCOUNTS_FAILURE = 'FETCH_EXISTING_MORTGAGE_MORTGAGE_ACCOUNTS_FAILURE'

export const FETCH_MORTGAGE_ACCOUNT_REQUEST = 'FETCH_MORTGAGE_ACCOUNT_REQUEST'
export const FETCH_MORTGAGE_ACCOUNT_SUCCESS = 'FETCH_MORTGAGE_ACCOUNT_SUCCESS'
export const FETCH_MORTGAGE_ACCOUNT_FAILURE = 'FETCH_MORTGAGE_ACCOUNT_FAILURE'

export const CREATE_MORTGAGE_ACCOUNT_REQUEST = 'CREATE_MORTGAGE_ACCOUNT_REQUEST'
export const CREATE_MORTGAGE_ACCOUNT_SUCCESS = 'CREATE_MORTGAGE_ACCOUNT_SUCCESS'
export const CREATE_MORTGAGE_ACCOUNT_FAILURE = 'CREATE_MORTGAGE_ACCOUNT_FAILURE'

export const UPDATE_MORTGAGE_ACCOUNT_REQUEST = 'UPDATE_MORTGAGE_ACCOUNT_REQUEST'
export const UPDATE_MORTGAGE_ACCOUNT_SUCCESS = 'UPDATE_MORTGAGE_ACCOUNT_SUCCESS'
export const UPDATE_MORTGAGE_ACCOUNT_FAILURE = 'UPDATE_MORTGAGE_ACCOUNT_FAILURE'

export const ARCHIVE_MORTGAGE_ACCOUNT_REQUEST = 'ARCHIVE_MORTGAGE_ACCOUNT_REQUEST'
export const ARCHIVE_MORTGAGE_ACCOUNT_SUCCESS = 'ARCHIVE_MORTGAGE_ACCOUNT_SUCCESS'
export const ARCHIVE_MORTGAGE_ACCOUNT_FAILURE = 'ARCHIVE_MORTGAGE_ACCOUNT_FAILURE'

export const UNARCHIVE_MORTGAGE_ACCOUNT_REQUEST = 'UNARCHIVE_MORTGAGE_ACCOUNT_REQUEST'
export const UNARCHIVE_MORTGAGE_ACCOUNT_SUCCESS = 'UNARCHIVE_MORTGAGE_ACCOUNT_SUCCESS'
export const UNARCHIVE_MORTGAGE_ACCOUNT_FAILURE = 'UNARCHIVE_MORTGAGE_ACCOUNT_FAILURE'

export const DELETE_MORTGAGE_ACCOUNT_REQUEST = 'DELETE_MORTGAGE_ACCOUNT_REQUEST'
export const DELETE_MORTGAGE_ACCOUNT_SUCCESS = 'DELETE_MORTGAGE_ACCOUNT_SUCCESS'
export const DELETE_MORTGAGE_ACCOUNT_FAILURE = 'DELETE_MORTGAGE_ACCOUNT_FAILURE'