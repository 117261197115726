import React, {useState} from 'react';
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

import {useCurrentAccountUser} from 'sharedComponents/useCurrentAccountUser'
import {FormModal} from 'modals'
import {RenderInDevice} from 'functional-components'
import AccountVerified from 'spas/Profile/AccountVerified'
import UserForm from 'spas/Profile/UserForm'

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${props => props.theme.devices.mobile} {
    margin-top: 1rem;
  }
`

const ContentDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 25px;

  @media ${props => props.theme.devices.mobile} {
    padding-left: 0;
    align-items: center;
  }
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Name = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  display: flex;
  align-items: center;
  color: #1a293a;

  @media ${props => props.theme.devices.mobile} {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
`

const Position = styled.div`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.theme.colors.blue};
`

const EditButton = styled(IconButton)`
  color: ${props => props.theme.colors.lightGrey};
`

function ContentDetails(props) {
  const {isEditable = false} = props
  const currentAccountUser = useCurrentAccountUser()
  const [showNameForm, setShowNameForm] = useState(false)

  const name = `${currentAccountUser?.firstName ?? ''} ${currentAccountUser?.lastName ?? ''}`
  const position = currentAccountUser?.position ?? ''

  return (
    <DetailsWrapper>
      <ContentDetailsWrapper>
        <NameWrapper>
          <Name>{name}</Name>
          {isEditable && (
            <RenderInDevice devices={['desktop', 'laptop', 'tablet']}>
              <EditButton size="medium" onClick={() => setShowNameForm(true)}>
                <EditIcon fontSize="inherit" />
              </EditButton>
            </RenderInDevice>
          )}
        </NameWrapper>
        <Position>{position}</Position>
      </ContentDetailsWrapper>
      <FormModal show={showNameForm} setShow={setShowNameForm} heading="Account Name" size="md">
        <UserForm showModal={setShowNameForm} />
      </FormModal>
    </DetailsWrapper>
  )
}

export default ContentDetails