import React from 'react'
import Container from 'react-bootstrap/Container'
import TopNavbar from 'sharedComponents/UserTopNavbar/UserTopNavbar.js'
import Page from 'sharedComponents/Page.js'
import ProfilePageNavbar from 'spas/Profile/ProfilePageNavbar/ProfilePageNavbar.js'
import ProfileDetailsCard from 'spas/Profile/ProfileDetailsCard.js'
import NewsletterCard from 'spas/Profile/NewsletterCard.js'
import LoginDetailsCard from 'spas/Profile/LoginDetailsCard.js'
import PersonalDataCard from 'spas/GDPR/PersonalDataCard.js'
import SubscriptionCard from 'spas/Subscriptions/SubscriptionCard.js'
import DataProcessingConsentsCard from 'spas/GDPR/DataProcessingConsentsCard.js'
import ForgetMeCard from 'spas/GDPR/ForgetMeCard.js'

function Profile() {
  return (
    <Container fluid>
      <TopNavbar />
      <Page paddingTop="50px">
        <ProfileDetailsCard />
        <LoginDetailsCard />
        <SubscriptionCard />
        <NewsletterCard />
        <PersonalDataCard />
        <DataProcessingConsentsCard />
        <ForgetMeCard />
      </Page>
    </Container>
  )
}

export default Profile
