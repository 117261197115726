export const FETCH_PROSPECTIVE_PROPERTY_PHOTOS_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_PHOTOS_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_PHOTOS_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_PHOTOS_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_PHOTOS_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_PHOTOS_FAILURE'

export const FETCH_PHOTO_REQUEST = 'FETCH_PHOTO_REQUEST'
export const FETCH_PHOTO_SUCCESS = 'FETCH_PHOTO_SUCCESS'
export const FETCH_PHOTO_FAILURE = 'FETCH_PHOTO_FAILURE'

export const CREATE_PHOTO_REQUEST = 'CREATE_PHOTO_REQUEST'
export const CREATE_PHOTO_SUCCESS = 'CREATE_PHOTO_SUCCESS'
export const CREATE_PHOTO_FAILURE = 'CREATE_PHOTO_FAILURE'

export const UPDATE_PHOTO_REQUEST = 'UPDATE_PHOTO_REQUEST'
export const UPDATE_PHOTO_SUCCESS = 'UPDATE_PHOTO_SUCCESS'
export const UPDATE_PHOTO_FAILURE = 'UPDATE_PHOTO_FAILURE'

export const ARCHIVE_PHOTO_REQUEST = 'ARCHIVE_PHOTO_REQUEST'
export const ARCHIVE_PHOTO_SUCCESS = 'ARCHIVE_PHOTO_SUCCESS'
export const ARCHIVE_PHOTO_FAILURE = 'ARCHIVE_PHOTO_FAILURE'

export const UNARCHIVE_PHOTO_REQUEST = 'UNARCHIVE_PHOTO_REQUEST'
export const UNARCHIVE_PHOTO_SUCCESS = 'UNARCHIVE_PHOTO_SUCCESS'
export const UNARCHIVE_PHOTO_FAILURE = 'UNARCHIVE_PHOTO_FAILURE'

export const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST'
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS'
export const DELETE_PHOTO_FAILURE = 'DELETE_PHOTO_FAILURE'