export const FETCH_COMPANY_DIRECTORS_REQUEST = 'FETCH_COMPANY_DIRECTORS_REQUEST'
export const FETCH_COMPANY_DIRECTORS_SUCCESS = 'FETCH_COMPANY_DIRECTORS_SUCCESS'
export const FETCH_COMPANY_DIRECTORS_FAILURE = 'FETCH_COMPANY_DIRECTORS_FAILURE'

export const CREATE_DIRECTOR_REQUEST = 'CREATE_DIRECTOR_REQUEST'
export const CREATE_DIRECTOR_SUCCESS = 'CREATE_DIRECTOR_SUCCESS'
export const CREATE_DIRECTOR_FAILURE = 'CREATE_DIRECTOR_FAILURE'

export const ARCHIVE_DIRECTOR_REQUEST = 'ARCHIVE_DIRECTOR_REQUEST'
export const ARCHIVE_DIRECTOR_SUCCESS = 'ARCHIVE_DIRECTOR_SUCCESS'
export const ARCHIVE_DIRECTOR_FAILURE = 'ARCHIVE_DIRECTOR_FAILURE'

export const UNARCHIVE_DIRECTOR_REQUEST = 'UNARCHIVE_DIRECTOR_REQUEST'
export const UNARCHIVE_DIRECTOR_SUCCESS = 'UNARCHIVE_DIRECTOR_SUCCESS'
export const UNARCHIVE_DIRECTOR_FAILURE = 'UNARCHIVE_DIRECTOR_FAILURE'

export const DELETE_DIRECTOR_REQUEST = 'DELETE_DIRECTOR_REQUEST'
export const DELETE_DIRECTOR_SUCCESS = 'DELETE_DIRECTOR_SUCCESS'
export const DELETE_DIRECTOR_FAILURE = 'DELETE_DIRECTOR_FAILURE'