import update from 'immutability-helper'
import { FETCH_ACADEMY_COURSES_REQUEST } from 'v2/spas/academy/courses/redux/action-types.tsx'
import { FETCH_ACADEMY_COURSES_SUCCESS } from 'v2/spas/academy/courses/redux/action-types.tsx'
import { FETCH_ACADEMY_COURSES_FAILURE } from 'v2/spas/academy/courses/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  academy: {},
  courses: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACADEMY_COURSES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACADEMY_COURSES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACADEMY_COURSES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        academy: {
          $set: action.payload.academy,
        },
        courses: {
          $set: action.payload.academy.courses
        },
      })
    }

    default:
      return state
  }
}
