import update from 'immutability-helper'
import {FETCH_DEAL_REQUEST} from 'redux/actionTypes.js'
import {FETCH_DEAL_FAILURE} from 'redux/actionTypes.js'
import {FETCH_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_DEAL_LOANS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_DEAL_LOANS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_DEAL_LOANS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_DEAL_SHAREHOLDINGS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_DEAL_SHAREHOLDINGS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_DEAL_SHAREHOLDINGS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_DEAL_PRESENTATIONS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_DEAL_PRESENTATIONS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_DEAL_PRESENTATIONS_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {ADD_LOAN_REQUEST} from 'redux/actionTypes.js'
import {ADD_LOAN_FAILURE} from 'redux/actionTypes.js'
import {ADD_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_LOAN_REQUEST} from 'redux/actionTypes.js'
import {DELETE_LOAN_FAILURE} from 'redux/actionTypes.js'
import {DELETE_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_LOAN_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_LOAN_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {ADD_SHAREHOLDING_REQUEST} from 'redux/actionTypes.js'
import {ADD_SHAREHOLDING_FAILURE} from 'redux/actionTypes.js'
import {ADD_SHAREHOLDING_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_SHAREHOLDING_REQUEST} from 'redux/actionTypes.js'
import {DELETE_SHAREHOLDING_FAILURE} from 'redux/actionTypes.js'
import {DELETE_SHAREHOLDING_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_SHAREHOLDING_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SHAREHOLDING_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SHAREHOLDING_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PRESENTATION_REQUEST} from 'redux/actionTypes.js'
import {ADD_PRESENTATION_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PRESENTATION_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PRESENTATION_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PRESENTATION_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PRESENTATION_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PRESENTATION_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PRESENTATION_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PRESENTATION_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_TEMPLATES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_TEMPLATES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_TEMPLATES_SUCCESS} from 'redux/actionTypes.js'
import {DUPLICATE_PRESENTATION_REQUEST} from 'redux/actionTypes.js'
import {DUPLICATE_PRESENTATION_FAILURE} from 'redux/actionTypes.js'
import {DUPLICATE_PRESENTATION_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchDealStatus: 'requested',
  fetchDealError: '',
  deal: {},
  fetchDealLoansStatus: 'requested',
  fetchDealLoansError: '',
  loans: [],
  fetchDealShareholdingsStatus: 'requested',
  fetchDealShareholdingsError: '',
  shareholdings: [],
  fetchDealPresentationsStatus: 'requested',
  fetchDealPresentationsError: '',
  presentations: [],
  fetchPresentationTemplatesStatus: 'requested',
  fetchPresentationTemplatesError: '',
  presentationTemplates: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DEAL_REQUEST: {
      return update(state, {
        fetchDealStatus: {$set: 'requested'},
      })
    }

    case FETCH_DEAL_FAILURE: {
      return update(state, {
        fetchDealStatus: {
          $set: 'error',
        },
        fetchDealError: {$set: action.payload.error},
      })
    }

    case FETCH_DEAL_SUCCESS: {
      return update(state, {
        fetchDealStatus: {$set: 'success'},
        deal: {
          $set: action.payload.deal,
        },
      })
    }

    case FETCH_DEAL_LOANS_REQUEST: {
      return update(state, {
        fetchDealLoansStatus: {$set: 'requested'},
      })
    }

    case FETCH_DEAL_LOANS_FAILURE: {
      return update(state, {
        fetchDealLoansStatus: {
          $set: 'error',
        },
        fetchDealLoansError: {$set: action.payload.error},
      })
    }

    case FETCH_DEAL_LOANS_SUCCESS: {
      return update(state, {
        fetchDealLoansStatus: {$set: 'success'},
        loans: {
          $set: action.payload.loans,
        },
      })
    }

    case FETCH_DEAL_SHAREHOLDINGS_REQUEST: {
      return update(state, {
        fetchDealShareholdingsStatus: {$set: 'requested'},
      })
    }

    case FETCH_DEAL_SHAREHOLDINGS_FAILURE: {
      return update(state, {
        fetchDealShareholdingsStatus: {
          $set: 'error',
        },
        fetchDealShareholdingsError: {$set: action.payload.error},
      })
    }

    case FETCH_DEAL_SHAREHOLDINGS_SUCCESS: {
      return update(state, {
        fetchDealShareholdingsStatus: {$set: 'success'},
        shareholdings: {
          $set: action.payload.shareholdings,
        },
      })
    }

    case FETCH_DEAL_PRESENTATIONS_REQUEST: {
      return update(state, {
        fetchDealPresentationsStatus: {$set: 'requested'},
      })
    }

    case FETCH_DEAL_PRESENTATIONS_FAILURE: {
      return update(state, {
        fetchDealPresentationsStatus: {
          $set: 'error',
        },
        fetchDealPresentationsError: {$set: action.payload.error},
      })
    }

    case FETCH_DEAL_PRESENTATIONS_SUCCESS: {
      return update(state, {
        fetchDealPresentationsStatus: {$set: 'success'},
        presentations: {
          $set: action.payload.presentations,
        },
      })
    }

    case FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST: {
      return state
    }

    case FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE: {
      return state
    }

    case FETCH_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS: {
      let index = state.shareholdings.findIndex(
        x => x.id === action.payload.shareholding.id,
      )

      return update(state, {
        shareholdings: {
          [index]: {
            cashFlowSummary: {
              $set: action.payload.shareholding.cashFlowSummary,
            },
          },
        },
      })
    }

    case ADD_LOAN_REQUEST: {
      return state
    }

    case ADD_LOAN_FAILURE: {
      return state
    }

    case ADD_LOAN_SUCCESS: {
      return update(state, {
        loans: {
          $push: [action.payload.loan],
        },
      })
    }

    case UPDATE_LOAN_REQUEST: {
      return state
    }

    case UPDATE_LOAN_FAILURE: {
      return state
    }

    case UPDATE_LOAN_SUCCESS: {
      let index = state.loans.findIndex(x => x.id === action.payload.loan.id)

      return update(state, {
        loans: {
          [index]: {$set: action.payload.loan},
        },
      })
    }

    case DELETE_LOAN_REQUEST: {
      return state
    }

    case DELETE_LOAN_FAILURE: {
      return state
    }

    case DELETE_LOAN_SUCCESS: {
      let index = state.loans.findIndex(x => x.id === action.payload.id)

      return update(state, {
        loans: {
          $splice: [[index, 1]],
        },
      })
    }

    case ADD_SHAREHOLDING_REQUEST: {
      return state
    }

    case ADD_SHAREHOLDING_FAILURE: {
      return state
    }

    case ADD_SHAREHOLDING_SUCCESS: {
      return update(state, {
        shareholdings: {
          $push: [action.payload.shareholding],
        },
      })
    }

    case UPDATE_SHAREHOLDING_REQUEST: {
      return state
    }

    case UPDATE_SHAREHOLDING_FAILURE: {
      return state
    }

    case UPDATE_SHAREHOLDING_SUCCESS: {
      let index = state.shareholdings.findIndex(
        x => x.id === action.payload.shareholding.id,
      )

      return update(state, {
        shareholdings: {
          [index]: {$set: action.payload.shareholding},
        },
      })
    }

    case DELETE_SHAREHOLDING_REQUEST: {
      return state
    }

    case DELETE_SHAREHOLDING_FAILURE: {
      return state
    }

    case DELETE_SHAREHOLDING_SUCCESS: {
      let index = state.shareholdings.findIndex(x => x.id === action.payload.id)

      return update(state, {
        shareholdings: {
          $splice: [[index, 1]],
        },
      })
    }

    case ADD_PRESENTATION_REQUEST: {
      return state
    }

    case ADD_PRESENTATION_FAILURE: {
      return state
    }

    case ADD_PRESENTATION_SUCCESS: {
      return update(state, {
        presentations: {
          $push: [action.payload.presentation],
        },
      })
    }

    case UPDATE_PRESENTATION_REQUEST: {
      return state
    }

    case UPDATE_PRESENTATION_FAILURE: {
      return state
    }

    case UPDATE_PRESENTATION_SUCCESS: {
      let index = state.presentations.findIndex(
        x => x.id === action.payload.presentation.id,
      )

      return update(state, {
        presentations: {
          [index]: {$set: action.payload.presentation},
        },
      })
    }

    case DELETE_PRESENTATION_REQUEST: {
      return state
    }

    case DELETE_PRESENTATION_FAILURE: {
      return state
    }

    case DELETE_PRESENTATION_SUCCESS: {
      let index = state.presentations.findIndex(x => x.id === action.payload.id)

      return update(state, {
        presentations: {
          $splice: [[index, 1]],
        },
      })
    }

    case RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_REQUEST: {
      return state
    }

    case RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_FAILURE: {
      return state
    }

    case RECOMPILE_SHAREHOLDING_CASH_FLOW_SUMMARY_SUCCESS: {
      let index = state.shareholdings.findIndex(
        x => x.id === action.payload.shareholding.id,
      )

      return update(state, {
        shareholdings: {
          [index]: {
            cashFlowSummary: {
              $set: action.payload.shareholding.cashFlowSummary,
            },
          },
        },
      })
    }

    case FETCH_PRESENTATION_TEMPLATES_REQUEST: {
      return update(state, {
        fetchPresentationTemplatesStatus: {$set: 'requested'},
      })
    }

    case FETCH_PRESENTATION_TEMPLATES_FAILURE: {
      return update(state, {
        fetchPresentationTemplatesStatus: {
          $set: 'error',
        },
        fetchPresentationTemplatesError: {$set: action.payload.error},
      })
    }

    case FETCH_PRESENTATION_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchPresentationTemplatesStatus: {$set: 'success'},
        presentationTemplates: {
          $set: action.payload.presentationTemplates,
        },
      })
    }

    case DUPLICATE_PRESENTATION_REQUEST: {
      return state
    }

    case DUPLICATE_PRESENTATION_FAILURE: {
      return state
    }

    case DUPLICATE_PRESENTATION_SUCCESS: {
      return update(state, {
        presentations: {
          $push: [action.payload.presentation],
        },
      })
    }

    default:
      return state
  }
}
