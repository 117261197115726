export const FETCH_OWNED_PROPERTIES_REQUEST = 'FETCH_OWNED_PROPERTIES_REQUEST'
export const FETCH_OWNED_PROPERTIES_SUCCESS = 'FETCH_OWNED_PROPERTIES_SUCCESS'
export const FETCH_OWNED_PROPERTIES_FAILURE = 'FETCH_OWNED_PROPERTIES_FAILURE'

export const CREATE_OWNED_PROPERTY_REQUEST = 'CREATE_OWNED_PROPERTY_REQUEST'
export const CREATE_OWNED_PROPERTY_SUCCESS = 'CREATE_OWNED_PROPERTY_SUCCESS'
export const CREATE_OWNED_PROPERTY_FAILURE = 'CREATE_OWNED_PROPERTY_FAILURE'

export const UPDATE_OWNED_PROPERTY_REQUEST = 'UPDATE_OWNED_PROPERTY_REQUEST'
export const UPDATE_OWNED_PROPERTY_SUCCESS = 'UPDATE_OWNED_PROPERTY_SUCCESS'
export const UPDATE_OWNED_PROPERTY_FAILURE = 'UPDATE_OWNED_PROPERTY_FAILURE'

export const ARCHIVE_OWNED_PROPERTY_REQUEST = 'ARCHIVE_OWNED_PROPERTY_REQUEST'
export const ARCHIVE_OWNED_PROPERTY_SUCCESS = 'ARCHIVE_OWNED_PROPERTY_SUCCESS'
export const ARCHIVE_OWNED_PROPERTY_FAILURE = 'ARCHIVE_OWNED_PROPERTY_FAILURE'

export const UNARCHIVE_OWNED_PROPERTY_REQUEST = 'UNARCHIVE_OWNED_PROPERTY_REQUEST'
export const UNARCHIVE_OWNED_PROPERTY_SUCCESS = 'UNARCHIVE_OWNED_PROPERTY_SUCCESS'
export const UNARCHIVE_OWNED_PROPERTY_FAILURE = 'UNARCHIVE_OWNED_PROPERTY_FAILURE'

export const DELETE_OWNED_PROPERTY_REQUEST = 'DELETE_OWNED_PROPERTY_REQUEST'
export const DELETE_OWNED_PROPERTY_SUCCESS = 'DELETE_OWNED_PROPERTY_SUCCESS'
export const DELETE_OWNED_PROPERTY_FAILURE = 'DELETE_OWNED_PROPERTY_FAILURE'