import React from 'react'
import styled from 'styled-components'
import CheckboxWrapper from 'sharedComponents/Forms/CheckboxWrapper.js'
import PropTypes from 'prop-types'

const FieldWrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};
`

function CheckboxInput(props) {
  return (
    <FieldWrapper id="text-input">
      <CheckboxWrapper
        id={props.id}
        label={props.label}
        name={props.name}
        submitForm={props.submitForm}
        handleChange={props.handleChange}
        submitOnChange={props.submitOnChange}
        checked={props.checked}
        toolTipContent={props.toolTipContent}
        required={props.required}
      />
    </FieldWrapper>
  )
}

export default CheckboxInput

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  submitForm: PropTypes.func,
  handleChange: PropTypes.func,
  submitOnChange: PropTypes.func,
}
