import update from 'immutability-helper'
import { FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  property: {},
  address: {},
  prospectiveProperty: {},
  ownedProperty: {},
  energyPerformanceCertificates: [],
  fetchPropertyEnergyPerformanceCertificatesStatus: 'requested',
  fetchPropertyEnergyPerformanceCertificatesError: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST: {
      return update(state, {
        fetchPropertyEnergyPerformanceCertificatesStatus: { 
          $set: 'requested' 
        },
      })
    }

    case FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE: {
      return update(state, {
        fetchPropertyEnergyPerformanceCertificatesStatus: {
          $set: 'error',
        },
        fetchProspectivePropertyEnergyPerformanceCertificatesError: {
          $set: action.payload.error 
        },
      })
    }

    case FETCH_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS: {
      return update(state, {
        fetchPropertyEnergyPerformanceCertificatesStatus: { $set: 'success' },
        property: {
          $set: {
            id: action.payload.property.id
          },
        },
        prospectiveProperty: {
          $set: action.payload.property.prospectiveProperty,
        },
        ownedProperty: {
          $set: action.payload.property.ownedProperty,
        },
        address: {
          $set: action.payload.property.address
        },
        energyPerformanceCertificates: {
          $set: action.payload.property.energyPerformanceCertificates,
        },
      })
    }

    default:
      return state
  }
}
