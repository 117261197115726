import React from 'react'
import { Trash } from 'react-bootstrap-icons';
import Button from './Button';

function DeleteResourceButton(props) {
  return (
    <Button
      onClick={props.onClick}
      content={<span><Trash /> {props.text}</span>}
      backgroundColor={props => props.theme.colors.red}
      color={props => props.theme.colors.white}
      size={props => props.theme.space.s}
    />
  )
}

export default DeleteResourceButton
