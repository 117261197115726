import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_REQUEST } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_SUCCESS } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_FAILURE } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { ARCHIVE_INSURANCE_POLICY_REQUEST } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { ARCHIVE_INSURANCE_POLICY_SUCCESS } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { ARCHIVE_INSURANCE_POLICY_FAILURE } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { UPDATE_INSURANCE_POLICY_REQUEST } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { UPDATE_INSURANCE_POLICY_SUCCESS } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { UPDATE_INSURANCE_POLICY_FAILURE } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { DELETE_INSURANCE_POLICY_REQUEST } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { DELETE_INSURANCE_POLICY_SUCCESS } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'
import { DELETE_INSURANCE_POLICY_FAILURE } from 'v2/spas/owned-property/insurance-policies/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  insurancePolicies: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_INSURANCE_POLICIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        insurancePolicies: {
          $set: action.payload.ownedProperty.property.insurancePolicies
        },
      })
    }

    case UPDATE_INSURANCE_POLICY_REQUEST: {
      return state
    }

    case UPDATE_INSURANCE_POLICY_FAILURE: {
      return state
    }

    case UPDATE_INSURANCE_POLICY_SUCCESS: {
      let index = state.insurancePolicies.findIndex(
        x => x.id === action.payload.insurancePolicy.id,
      )

      return update(state, {
        insurancePolicies: {
          [index]: { $set: action.payload.insurancePolicy },
        },
      })
    }

    case ARCHIVE_INSURANCE_POLICY_REQUEST: {
      return state
    }

    case ARCHIVE_INSURANCE_POLICY_FAILURE: {
      return state
    }

    case ARCHIVE_INSURANCE_POLICY_SUCCESS: {
      let index = state.insurancePolicies.findIndex(x => x.id === action.payload.insurancePolicy.id)

      return update(state, {
        insurancePolicies: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_INSURANCE_POLICY_REQUEST: {
      return state
    }

    case DELETE_INSURANCE_POLICY_FAILURE: {
      return state
    }

    case DELETE_INSURANCE_POLICY_SUCCESS: {
      let index = state.insurancePolicies.findIndex(x => x.id === action.payload.id)

      return update(state, {
        insurancePolicies: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
