import update from 'immutability-helper'
import { FETCH_BRIDGING_LOAN_APPLICATION_REQUEST } from 'v2/spas/prospective-property/bridging-loan-applications/redux/action-types.tsx'
import { FETCH_BRIDGING_LOAN_APPLICATION_SUCCESS } from 'v2/spas/prospective-property/bridging-loan-applications/redux/action-types.tsx'
import { FETCH_BRIDGING_LOAN_APPLICATION_FAILURE } from 'v2/spas/prospective-property/bridging-loan-applications/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  bridgingLoanApplication: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BRIDGING_LOAN_APPLICATION_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_BRIDGING_LOAN_APPLICATION_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_BRIDGING_LOAN_APPLICATION_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        bridgingLoanApplication: {
          $set: action.payload.bridgingLoanApplication,
        }
      })
    }

    default:
      return state
  }
}