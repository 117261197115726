import update from 'immutability-helper'
import { FETCH_PHOTO_REQUEST } from 'v2/spas/owned-property/photos/redux/action-types.tsx'
import { FETCH_PHOTO_SUCCESS } from 'v2/spas/owned-property/photos/redux/action-types.tsx'
import { FETCH_PHOTO_FAILURE } from 'v2/spas/owned-property/photos/redux/action-types.tsx'
import { UPDATE_PHOTO_REQUEST } from 'v2/spas/owned-property/photos/redux/action-types.tsx'
import { UPDATE_PHOTO_SUCCESS } from 'v2/spas/owned-property/photos/redux/action-types.tsx'
import { UPDATE_PHOTO_FAILURE } from 'v2/spas/owned-property/photos/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  photo: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PHOTO_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PHOTO_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PHOTO_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        photo: {
          $set: action.payload.photo,
        }
      })
    }

    case UPDATE_PHOTO_REQUEST: {
      return update(state, {
        updatePhotoStatus: { $set: 'requested' },
      })
    }

    case UPDATE_PHOTO_FAILURE: {
      return update(state, {
        updatePhotoStatus: {
          $set: 'error',
        },
        updatePhotoError: { $set: action.payload.error },
      })
    }

    case UPDATE_PHOTO_SUCCESS: {
      return update(state, {
        updatePhotoStatus: { $set: 'success' },
        photo: {
          $set: action.payload.photo,
        }
      })
    }

    default:
      return state
  }
}