import React from 'react';
import styled from 'styled-components';
import CommonQuestion from 'spas/Pricing/CommonQuestion.js';

const Wrapper = styled.div`
  padding-top: ${props => props.theme.space.xl};
  text-align: center;

  h1 {
    padding-bottom: ${props => props.theme.space.xl};
  }
`

function CommonQuestions(props) {
  return (
    <Wrapper>
      <h1>Common questions</h1>
      <CommonQuestion 
        question='Can I have a discount?'
        answer='
          Yes. You will get 2 months free if you subscribe to an annual plan and pay upfront.
        '
      />

      <CommonQuestion
        question='Is there a limit to the number of deals I can stack?'
        answer='
          No. You can stack as many deals, as you like.
        '
      />

      <CommonQuestion
        question='Is there a limit to the number of investor packs I can generate and share?'
        answer='
          No. You can generate and share, as many investor packs as you like.
        '
      />

      <CommonQuestion
        question='Will I pay more, as my team grows?'
        answer='
          No. You can add an unlimited number of team members, without incurring any extra charges.
        '
      />

      <CommonQuestion
        question='Will I pay more, as my portfolio grows?'
        answer='
          No. You can add an unlimited number of properties, units and tenancies, without incurring any extra charges.
        '
      />

      <CommonQuestion
        question='Will I be charged automatically when my trial ends?'
        answer='
          No. You do not need to supply card details, in order to start a free trial.
          You will only be charged if you choose to enter your card details and create
          a subscription.
        '
      />

      <CommonQuestion
        question='Can I cancel at any time?'
        answer='
          Yes. 
          Please note that immediately after doing so, you will no longer be able to access your account.
          You will not receive a refund for any payments, which you have already made.
        '
      />

      <CommonQuestion
        question='What if I have more questions?'
        answer='
          Email us and we will be happy to answer them - hello@stacked.group
        '
      />
    </Wrapper>
  );
};

export default CommonQuestions
