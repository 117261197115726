import update from 'immutability-helper'
import { FETCH_COMPANY_OWNED_PROPERTIES_REQUEST } from 'v2/spas/company/owned-properties/redux/action-types.tsx'
import { FETCH_COMPANY_OWNED_PROPERTIES_SUCCESS } from 'v2/spas/company/owned-properties/redux/action-types.tsx'
import { FETCH_COMPANY_OWNED_PROPERTIES_FAILURE } from 'v2/spas/company/owned-properties/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  company: {},
  ownedProperties: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_OWNED_PROPERTIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_COMPANY_OWNED_PROPERTIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_COMPANY_OWNED_PROPERTIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        company: {
          $set: action.payload.company,
        },
        ownedProperties: {
          $set: action.payload.company.ownedProperties
        },
      })
    }

    default:
      return state
  }
}
