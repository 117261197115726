import update from 'immutability-helper'
import {FETCH_TENANCY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_TENANCY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_TENANCY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_TENANCY_TENANTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_TENANCY_TENANTS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_TENANCY_TENANTS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_TENANTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_TENANTS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_TENANTS_FAILURE} from 'redux/actionTypes.js'
import {ADD_TENANCY_TENANT_REQUEST} from 'redux/actionTypes.js'
import {ADD_TENANCY_TENANT_SUCCESS} from 'redux/actionTypes.js'
import {ADD_TENANCY_TENANT_FAILURE} from 'redux/actionTypes.js'
// import {DELETE_TENANCY_TENANT_REQUEST} from 'redux/actionTypes.js'
// import {DELETE_TENANCY_TENANT_SUCCESS} from 'redux/actionTypes.js'
// import {DELETE_TENANCY_TENANT_FAILURE} from 'redux/actionTypes.js'
import {FETCH_TENANCY_GUARANTORS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_TENANCY_GUARANTORS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_TENANCY_GUARANTORS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_GUARANTORS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_GUARANTORS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_GUARANTORS_FAILURE} from 'redux/actionTypes.js'
import {ADD_TENANCY_GUARANTOR_REQUEST} from 'redux/actionTypes.js'
import {ADD_TENANCY_GUARANTOR_SUCCESS} from 'redux/actionTypes.js'
import {ADD_TENANCY_GUARANTOR_FAILURE} from 'redux/actionTypes.js'
import {DELETE_TENANCY_GUARANTOR_REQUEST} from 'redux/actionTypes.js'
import {DELETE_TENANCY_GUARANTOR_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_TENANCY_GUARANTOR_FAILURE} from 'redux/actionTypes.js'
import {FETCH_TENANCY_DOCUMENTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_TENANCY_DOCUMENTS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_TENANCY_DOCUMENTS_FAILURE} from 'redux/actionTypes.js'
import {ADD_TENANCY_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {ADD_TENANCY_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {ADD_TENANCY_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {UPLOAD_TENANCY_DOCUMENT_FILE_REQUEST} from 'redux/actionTypes.js'
import {UPLOAD_TENANCY_DOCUMENT_FILE_FAILURE} from 'redux/actionTypes.js'
import {UPLOAD_TENANCY_DOCUMENT_FILE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_TENANCY_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_TENANCY_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_TENANCY_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_TENANCY_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {DELETE_TENANCY_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {DELETE_TENANCY_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_TENANCY_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_TENANCY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_TENANCY_SUCCESS} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_REQUEST} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_FAILURE} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchTenancyStatus: 'requested',
  fetchTenancyError: '',
  tenancy: {},
  fetchTenancyTenantsStatus: 'requested',
  fetchTenancyTenantsError: '',
  tenancyTenants: [],
  fetchTenantsStatus: 'requested',
  fetchTenantsError: '',
  tenants: [],
  fetchTenancyGuarantorsStatus: 'requested',
  fetchTenancyGuarantorsError: '',
  tenancyGuarantors: [],
  fetchGuarantorsStatus: 'requested',
  fetchGuarantorsError: '',
  guarantors: [],
  fetchTenancyDocumentsStatus: 'requested',
  fetchTenancyDocumentsError: '',
  documents: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANCY_REQUEST: {
      return update(state, {
        fetchTenancyStatus: {$set: 'requested'},
      })
    }

    case FETCH_TENANCY_FAILURE: {
      return update(state, {
        fetchTenancyStatus: {
          $set: 'error',
        },
        fetchTenancyError: {$set: action.payload.error},
      })
    }

    case FETCH_TENANCY_SUCCESS: {
      return update(state, {
        fetchTenancyStatus: {$set: 'success'},
        tenancy: {
          $set: action.payload.tenancy,
        },
      })
    }

    case FETCH_TENANCY_TENANTS_REQUEST: {
      return update(state, {
        fetchTenancyTenantsStatus: {$set: 'requested'},
      })
    }

    case FETCH_TENANCY_TENANTS_FAILURE: {
      return update(state, {
        fetchTenancyTenantsStatus: {
          $set: 'error',
        },
        fetchTenancyTenantsError: {$set: action.payload.error},
      })
    }

    case FETCH_TENANCY_TENANTS_SUCCESS: {
      return update(state, {
        fetchTenancyTenantsStatus: {$set: 'success'},
        tenancyTenants: {
          $set: action.payload.tenancy.tenancyTenants,
        },
      })
    }

    case FETCH_TENANTS_REQUEST: {
      return update(state, {
        fetchTenantsStatus: {$set: 'requested'},
      })
    }

    case FETCH_TENANTS_FAILURE: {
      return update(state, {
        fetchTenantsStatus: {
          $set: 'error',
        },
        fetchTenantsError: {$set: action.payload.error},
      })
    }

    case FETCH_TENANTS_SUCCESS: {
      return update(state, {
        fetchTenantsStatus: {$set: 'success'},
        tenants: {
          $set: action.payload.tenants,
        },
      })
    }

    case ADD_TENANCY_TENANT_REQUEST: {
      return state
    }

    case ADD_TENANCY_TENANT_FAILURE: {
      return state
    }

    case ADD_TENANCY_TENANT_SUCCESS: {
      return update(state, {
        tenancyTenants: {
          $push: [action.payload.tenancyTenant],
        },
      })
    }

    // case DELETE_TENANCY_TENANT_REQUEST: {
    //   return state
    // }

    // case DELETE_TENANCY_TENANT_FAILURE: {
    //   return state
    // }

    // case DELETE_TENANCY_TENANT_SUCCESS: {
    //   let index = state.tenancyTenants.findIndex(
    //     x => x.id === action.payload.id,
    //   )

    //   return update(state, {
    //     tenancyTenants: {
    //       $splice: [[index, 1]],
    //     },
    //   })
    // }

    case FETCH_TENANCY_GUARANTORS_REQUEST: {
      return update(state, {
        fetchTenancyGuarantorsStatus: {$set: 'requested'},
      })
    }

    case FETCH_TENANCY_GUARANTORS_FAILURE: {
      return update(state, {
        fetchTenancyGuarantorsStatus: {
          $set: 'error',
        },
        fetchTenancyGuarantorsError: {$set: action.payload.error},
      })
    }

    case FETCH_TENANCY_GUARANTORS_SUCCESS: {
      return update(state, {
        fetchTenancyGuarantorsStatus: {$set: 'success'},
        tenancyGuarantors: {
          $set: action.payload.tenancy.tenancyGuarantors,
        },
      })
    }

    case FETCH_GUARANTORS_REQUEST: {
      return update(state, {
        fetchGuarantorsStatus: {$set: 'requested'},
      })
    }

    case FETCH_GUARANTORS_FAILURE: {
      return update(state, {
        fetchGuarantorsStatus: {
          $set: 'error',
        },
        fetchGuarantorsError: {$set: action.payload.error},
      })
    }

    case FETCH_GUARANTORS_SUCCESS: {
      return update(state, {
        fetchGuarantorsStatus: {$set: 'success'},
        guarantors: {
          $set: action.payload.guarantors,
        },
      })
    }

    case ADD_TENANCY_GUARANTOR_REQUEST: {
      return state
    }

    case ADD_TENANCY_GUARANTOR_FAILURE: {
      return state
    }

    case ADD_TENANCY_GUARANTOR_SUCCESS: {
      return update(state, {
        tenancyGuarantors: {
          $push: [action.payload.tenancyGuarantor],
        },
      })
    }

    case DELETE_TENANCY_GUARANTOR_REQUEST: {
      return state
    }

    case DELETE_TENANCY_GUARANTOR_FAILURE: {
      return state
    }

    case DELETE_TENANCY_GUARANTOR_SUCCESS: {
      let index = state.tenancyGuarantors.findIndex(
        x => x.id === action.payload.id,
      )

      return update(state, {
        tenancyGuarantors: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_TENANCY_DOCUMENTS_REQUEST: {
      return update(state, {
        fetchTenancyDocumentsStatus: {$set: 'requested'},
      })
    }

    case FETCH_TENANCY_DOCUMENTS_FAILURE: {
      return update(state, {
        fetchTenancyDocumentsStatus: {
          $set: 'error',
        },
        fetchTenancyDocumentsError: {$set: action.payload.error},
      })
    }

    case FETCH_TENANCY_DOCUMENTS_SUCCESS: {
      return update(state, {
        fetchTenancyDocumentsStatus: {$set: 'success'},
        documents: {
          $set: action.payload.documents,
        },
      })
    }

    case ADD_TENANCY_DOCUMENT_REQUEST: {
      return state
    }

    case ADD_TENANCY_DOCUMENT_FAILURE: {
      return state
    }

    case ADD_TENANCY_DOCUMENT_SUCCESS: {
      return update(state, {
        documents: {
          $push: [action.payload.document],
        },
      })
    }

    case UPDATE_TENANCY_DOCUMENT_REQUEST: {
      return state
    }

    case UPDATE_TENANCY_DOCUMENT_FAILURE: {
      return state
    }

    case UPDATE_TENANCY_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(
        x => x.id === action.payload.document.id,
      )

      return update(state, {
        documents: {
          [index]: {$set: action.payload.document},
        },
      })
    }

    case UPLOAD_TENANCY_DOCUMENT_FILE_REQUEST: {
      return state
    }

    case UPLOAD_TENANCY_DOCUMENT_FILE_FAILURE: {
      return state
    }

    case UPLOAD_TENANCY_DOCUMENT_FILE_SUCCESS: {
      let index = state.documents.findIndex(
        x => x.id === action.payload.document.id,
      )

      return update(state, {
        documents: {
          [index]: {$set: action.payload.document},
        },
      })
    }

    case DELETE_TENANCY_DOCUMENT_REQUEST: {
      return state
    }

    case DELETE_TENANCY_DOCUMENT_FAILURE: {
      return state
    }

    case DELETE_TENANCY_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(x => x.id === action.payload.id)

      return update(state, {
        documents: {
          $splice: [[index, 1]],
        },
      })
    }

    case UPDATE_TENANCY_REQUEST: {
      return state
    }

    case UPDATE_TENANCY_FAILURE: {
      return state
    }

    case UPDATE_TENANCY_SUCCESS: {
      return update(state, {
        tenancy: {
          $set: action.payload.tenancy,
        },
      })
    }

    case ADD_ACCOUNT_USER_REQUEST: {
      return state
    }

    case ADD_ACCOUNT_USER_FAILURE: {
      return state
    }

    case ADD_ACCOUNT_USER_SUCCESS: {
      if (action.payload.accountUser.tenant) {
        return update(state, {
          tenants: {
            $push: [action.payload.accountUser.tenant],
          },
        })
      } else if (action.payload.accountUser.guarantor) {
        return update(state, {
          guarantors: {
            $push: [action.payload.accountUser.guarantor],
          },
        })
      } else {
        return state
      }
    }

    default:
      return state
  }
}
