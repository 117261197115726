import update from 'immutability-helper'
import { FETCH_ARTICLE_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ARTICLE_FAILURE } from 'redux/actionTypes.js'
import { FETCH_ARTICLE_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchArticleStatus: 'requested',
  fetchArticleError: '',
  article: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICLE_REQUEST: {
      return update(state, {
        fetchArticleStatus: { $set: 'requested' },
      })
    }

    case FETCH_ARTICLE_FAILURE: {
      return update(state, {
        fetchArticleStatus: {
          $set: 'error',
        },
        fetchArticleError: { $set: action.payload.error },
      })
    }

    case FETCH_ARTICLE_SUCCESS: {
      return update(state, {
        fetchArticleStatus: { $set: 'success' },
        article: {
          $set: action.payload.article,
        },
      })
    }

    default:
      return state
  }
}
