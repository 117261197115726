export const FETCH_ACCOUNT_ACCOUNT_USERS_REQUEST = 'FETCH_ACCOUNT_ACCOUNT_USERS_REQUEST'
export const FETCH_ACCOUNT_ACCOUNT_USERS_SUCCESS = 'FETCH_ACCOUNT_ACCOUNT_USERS_SUCCESS'
export const FETCH_ACCOUNT_ACCOUNT_USERS_FAILURE = 'FETCH_ACCOUNT_ACCOUNT_USERS_FAILURE'

export const FETCH_ACCOUNT_USER_REQUEST = 'FETCH_ACCOUNT_USER_REQUEST'
export const FETCH_ACCOUNT_USER_SUCCESS = 'FETCH_ACCOUNT_USER_SUCCESS'
export const FETCH_ACCOUNT_USER_FAILURE = 'FETCH_ACCOUNT_USER_FAILURE'

export const CREATE_ACCOUNT_USER_REQUEST = 'CREATE_ACCOUNT_USER_REQUEST'
export const CREATE_ACCOUNT_USER_SUCCESS = 'CREATE_ACCOUNT_USER_SUCCESS'
export const CREATE_ACCOUNT_USER_FAILURE = 'CREATE_ACCOUNT_USER_FAILURE'

export const UPDATE_ACCOUNT_USER_REQUEST = 'UPDATE_ACCOUNT_USER_REQUEST'
export const UPDATE_ACCOUNT_USER_SUCCESS = 'UPDATE_ACCOUNT_USER_SUCCESS'
export const UPDATE_ACCOUNT_USER_FAILURE = 'UPDATE_ACCOUNT_USER_FAILURE'

export const ARCHIVE_ACCOUNT_USER_REQUEST = 'ARCHIVE_ACCOUNT_USER_REQUEST'
export const ARCHIVE_ACCOUNT_USER_SUCCESS = 'ARCHIVE_ACCOUNT_USER_SUCCESS'
export const ARCHIVE_ACCOUNT_USER_FAILURE = 'ARCHIVE_ACCOUNT_USER_FAILURE'

export const UNARCHIVE_ACCOUNT_USER_REQUEST = 'UNARCHIVE_ACCOUNT_USER_REQUEST'
export const UNARCHIVE_ACCOUNT_USER_SUCCESS = 'UNARCHIVE_ACCOUNT_USER_SUCCESS'
export const UNARCHIVE_ACCOUNT_USER_FAILURE = 'UNARCHIVE_ACCOUNT_USER_FAILURE'

export const DELETE_ACCOUNT_USER_REQUEST = 'DELETE_ACCOUNT_USER_REQUEST'
export const DELETE_ACCOUNT_USER_SUCCESS = 'DELETE_ACCOUNT_USER_SUCCESS'
export const DELETE_ACCOUNT_USER_FAILURE = 'DELETE_ACCOUNT_USER_FAILURE'

export const UPDATE_AVATAR_REQUEST = 'UPDATE_AVATAR_REQUEST'
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS'
export const UPDATE_AVATAR_FAILURE = 'UPDATE_AVATAR_FAILURE'

export const MANAGE_SUBSCRIPTION_REQUEST = 'MANAGE_SUBSCRIPTION_REQUEST'
export const MANAGE_SUBSCRIPTION_SUCCESS = 'MANAGE_SUBSCRIPTION_SUCCESS'
export const MANAGE_SUBSCRIPTION_FAILURE = 'MANAGE_SUBSCRIPTION_FAILURE'

export const EMAIL_PERSONAL_DATA_REQUEST = 'EMAIL_PERSONAL_DATA_REQUEST'
export const EMAIL_PERSONAL_DATA_SUCCESS = 'EMAIL_PERSONAL_DATA_SUCCESS'
export const EMAIL_PERSONAL_DATA_FAILURE = 'EMAIL_PERSONAL_DATA_FAILURE'

export const WITHDRAW_USER_CONSENT_REQUEST = 'WITHDRAW_USER_CONSENT_REQUEST'
export const WITHDRAW_USER_CONSENT_SUCCESS = 'WITHDRAW_USER_CONSENT_SUCCESS'
export const WITHDRAW_USER_CONSENT_FAILURE = 'WITHDRAW_USER_CONSENT_FAILURE'

export const GRANT_USER_CONSENT_REQUEST = 'GRANT_USER_CONSENT_REQUEST'
export const GRANT_USER_CONSENT_SUCCESS = 'GRANT_USER_CONSENT_SUCCESS'
export const GRANT_USER_CONSENT_FAILURE = 'GRANT_USER_CONSENT_FAILURE'

export const FORGET_USER_REQUEST = 'FORGET_USER_REQUEST'
export const FORGET_USER_SUCCESS = 'FORGET_USER_SUCCESS'
export const FORGET_USER_FAILURE = 'FORGET_USER_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const UPDATE_CURRENT_ACCOUNT_USER_REQUEST = 'UPDATE_CURRENT_ACCOUNT_USER_REQUEST'
export const UPDATE_CURRENT_ACCOUNT_USER_SUCCESS = 'UPDATE_CURRENT_ACCOUNT_USER_SUCCESS'
export const UPDATE_CURRENT_ACCOUNT_USER_FAILURE = 'UPDATE_CURRENT_ACCOUNT_USER_FAILURE'

export const CHANGE_EMAIL_REQUEST_REQUEST = 'CHANGE_EMAIL_REQUEST_REQUEST'
export const CHANGE_EMAIL_REQUEST_SUCCESS = 'CHANGE_EMAIL_REQUEST_SUCCESS'
export const CHANGE_EMAIL_REQUEST_FAILURE = 'CHANGE_EMAIL_REQUEST_FAILURE'