import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Card from 'sharedComponents/Card.js'

const Wrapper = styled.div`
  #card {
    margin-bottom: ${props => props.theme.space.l};
  }
`

function ProspectivePropertyResourceCard(props) {
  return (
    <Wrapper>
      <Card borderRadius="4px">
        <Row>{props.children}</Row>
      </Card>
    </Wrapper>
  )
}

export default ProspectivePropertyResourceCard
