import update from 'immutability-helper'
import {FETCH_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_TEMPLATES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_TEMPLATES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_TEMPLATES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_REMORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_REMORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_REMORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_REQUEST} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_FAILURE} from 'redux/actionTypes.js'
import {CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST} from 'redux/actionTypes.js'
import {RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS} from 'redux/actionTypes.js'
import {RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {CLEAR_EXPENSES_FROM_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_BRIDGING_LOAN_REQUEST} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_BRIDGING_LOAN_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_FLIP_DEAL_BRIDGING_LOAN_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_SUPPORT_NEEDED_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_SUPPORT_NEEDED_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_FLIP_DEAL_SUPPORT_NEEDED_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchFlipDealStatus: 'requested',
  fetchFlipDealError: '',
  flipDeal: {
    deal: {},
  },
  fetchFlipDealTemplatesStatus: 'requested',
  fetchFlipDealTemplatesError: '',
  dealTemplates: [],
  fetchFlipDealFixedPurchaseExpensesStatus: 'requested',
  fetchFlipDealFixedPurchaseExpensesError: '',
  fixedPurchaseExpenses: [],
  fetchFlipDealVariablePurchaseExpensesStatus: 'requested',
  fetchFlipDealVariablePurchaseExpensesError: '',
  variablePurchaseExpenses: [],
  fetchFlipDealDevelopmentExpensesStatus: 'requested',
  fetchFlipDealDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchFlipDealFixedRecurringExpensesStatus: 'requested',
  fetchFlipDealFixedRecurringExpensesError: '',
  fixedRecurringExpenses: [],
  fetchFlipDealVariableRecurringExpensesStatus: 'requested',
  fetchFlipDealVariableRecurringExpensesError: '',
  variableRecurringExpenses: [],
  fetchFlipDealVariableSaleExpensesStatus: 'requested',
  fetchFlipDealVariableSaleExpensesError: '',
  variableSaleExpenses: [],
  fetchFlipDealDuringDevelopmentExpensesStatus: 'requested',
  fetchFlipDealDuringDevelopmentExpensesError: '',
  duringDevelopmentExpenses: [],
  fetchFlipDealMortgageStatus: 'requested',
  fetchFlipDealMortgageError: '',
  mortgage: {},
  fetchFlipDealRemortgageStatus: 'requested',
  fetchFlipDealRemortgageError: '',
  remortgage: {},
  fetchFlipDealCashFlowSummaryStatus: 'requested',
  fetchFlipDealCashFlowSummaryError: '',
  cashFlowSummary: {
    schedule: [],
  },
  fetchFlipDealBridgingLoanStatus: 'requested',
  fetchFlipDealBridgingLoanError: '',
  bridgingLoan: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FLIP_DEAL_REQUEST: {
      return update(state, {
        fetchFlipDealStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_FAILURE: {
      return update(state, {
        fetchFlipDealStatus: {
          $set: 'error',
        },
        fetchFlipDealError: {$set: action.payload.error},
      })
    }

    case FETCH_FLIP_DEAL_SUCCESS: {
      return update(state, {
        fetchFlipDealStatus: {$set: 'success'},
        flipDeal: {
          $set: action.payload.flipDeal,
        },
      })
    }

    case FETCH_FLIP_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        fetchFlipDealTemplatesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        fetchFlipDealTemplatesStatus: {
          $set: 'error',
        },
        fetchFlipDealTemplatesError: {$set: action.payload.error},
      })
    }

    case FETCH_FLIP_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchFlipDealTemplatesStatus: {$set: 'success'},
        dealTemplates: {
          $set: action.payload.dealTemplates,
        },
      })
    }

    case FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchFlipDealFixedPurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchFlipDealFixedPurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchFlipDealFixedPurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_FLIP_DEAL_FIXED_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchFlipDealFixedPurchaseExpensesStatus: {$set: 'success'},
        fixedPurchaseExpenses: {
          $set: action.payload.fixedPurchaseExpenses,
        },
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchFlipDealVariablePurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchFlipDealVariablePurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchFlipDealVariablePurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchFlipDealVariablePurchaseExpensesStatus: {$set: 'success'},
        variablePurchaseExpenses: {
          $set: action.payload.variablePurchaseExpenses,
        },
      })
    }

    case FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchFlipDealDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchFlipDealDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchFlipDealDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_FLIP_DEAL_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchFlipDealDevelopmentExpensesStatus: {$set: 'success'},
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchFlipDealFixedRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchFlipDealFixedRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchFlipDealFixedRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_FLIP_DEAL_FIXED_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchFlipDealFixedRecurringExpensesStatus: {$set: 'success'},
        fixedRecurringExpenses: {
          $set: action.payload.fixedRecurringExpenses,
        },
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchFlipDealVariableRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchFlipDealVariableRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchFlipDealVariableRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchFlipDealVariableRecurringExpensesStatus: {$set: 'success'},
        variableRecurringExpenses: {
          $set: action.payload.variableRecurringExpenses,
        },
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_REQUEST: {
      return update(state, {
        fetchFlipDealVariableSaleExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_FAILURE: {
      return update(state, {
        fetchFlipDealVariableSaleExpensesStatus: {
          $set: 'error',
        },
        fetchFlipDealVariableSaleExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_FLIP_DEAL_VARIABLE_SALE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchFlipDealVariableSaleExpensesStatus: {$set: 'success'},
        variableSaleExpenses: {
          $set: action.payload.variableSaleExpenses,
        },
      })
    }

    case FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchFlipDealDuringDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchFlipDealDuringDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchFlipDealDuringDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchFlipDealDuringDevelopmentExpensesStatus: {$set: 'success'},
        duringDevelopmentExpenses: {
          $set: action.payload.duringDevelopmentExpenses,
        },
      })
    }

    case FETCH_FLIP_DEAL_MORTGAGE_REQUEST: {
      return update(state, {
        fetchFlipDealMortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_MORTGAGE_FAILURE: {
      return update(state, {
        fetchFlipDealMortgageStatus: {
          $set: 'error',
        },
        fetchFlipDealMortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_FLIP_DEAL_MORTGAGE_SUCCESS: {
      return update(state, {
        fetchFlipDealMortgageStatus: {$set: 'success'},
        mortgage: {
          $set: action.payload.mortgage,
        },
      })
    }

    case FETCH_FLIP_DEAL_REMORTGAGE_REQUEST: {
      return update(state, {
        fetchFlipDealRemortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_REMORTGAGE_FAILURE: {
      return update(state, {
        fetchFlipDealRemortgageStatus: {
          $set: 'error',
        },
        fetchFlipDealRemortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_FLIP_DEAL_REMORTGAGE_SUCCESS: {
      return update(state, {
        fetchFlipDealRemortgageStatus: {$set: 'success'},
        remortgage: {
          $set: action.payload.remortgage,
        },
      })
    }

    case FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return update(state, {
        fetchFlipDealCashFlowSummaryStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return update(state, {
        fetchFlipDealCashFlowSummaryStatus: {
          $set: 'error',
        },
        fetchFlipDealCashFlowSummaryError: {$set: action.payload.error},
      })
    }

    case FETCH_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        fetchFlipDealCashFlowSummaryStatus: {$set: 'success'},
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_FLIP_DEAL_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_FLIP_DEAL_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_FLIP_DEAL_EXPENSES_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          totalProjectCost: { $set: action.payload.flipDeal.totalProjectCost },
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
        bridgingLoan: {
          $set: action.payload.flipDeal.bridgingLoan,
        },
      })
    }

    case CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_FLIP_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
      })
    }

    case CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_FLIP_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
      })
    }

    case CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_FLIP_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
      })
    }

    case CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_FLIP_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
      })
    }

    case CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_FLIP_DEAL_SALE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_TO_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.flipDeal.mortgage,
        },
      })
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_REMORTGAGE_TO_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.flipDeal.remortgage,
        },
      })
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_MORTGAGE_FROM_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
        mortgage: {
          $set: action.payload.flipDeal.mortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_MORTGAGE_FOR_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
        mortgage: {
          prospectiveMortgage: {
            term: {
              $set: action.payload.flipDeal.mortgage.prospectiveMortgage.term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.flipDeal.mortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.flipDeal.mortgage.prospectiveMortgage
                  .repaymentType,
            },
            amountWithProductFee: {
              $set:
                action.payload.flipDeal.mortgage.prospectiveMortgage
                  .amountWithProductFee,
            },
            monthlyPayment: {
              $set:
                action.payload.flipDeal.mortgage.prospectiveMortgage
                  .monthlyPayment,
            },
            productFeeAmount: {
              $set:
                action.payload.flipDeal.mortgage.prospectiveMortgage
                  .productFeeAmount,
            },
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_REMORTGAGE_FROM_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
        remortgage: {
          $set: action.payload.flipDeal.remortgage,
        },
      })
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_REMORTGAGE_FOR_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          saleExpenses: {
            $set: action.payload.flipDeal.saleExpenses,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
        },
        remortgage: {
          prospectiveMortgage: {
            term: {
              $set: action.payload.flipDeal.remortgage.prospectiveMortgage.term,
            },
            productFeeAddedToLoan: {
              $set:
                action.payload.flipDeal.remortgage.prospectiveMortgage
                  .productFeeAddedToLoan,
            },
            repaymentType: {
              $set:
                action.payload.flipDeal.remortgage.prospectiveMortgage
                  .repaymentType,
            },
          },
        },
      })
    }

    case RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_REQUEST: {
      return state
    }

    case RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_FAILURE: {
      return state
    }

    case RECOMPILE_FLIP_DEAL_CASH_FLOW_SUMMARY_SUCCESS: {
      return update(state, {
        cashFlowSummary: {
          $set: action.payload.cashFlowSummary,
        },
      })
    }

    case UPDATE_FLIP_DEAL_REQUEST: {
      return state
    }

    case UPDATE_FLIP_DEAL_FAILURE: {
      return state
    }

    case UPDATE_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseType: {$set: action.payload.flipDeal.purchaseType},
          purchasePrice: {$set: action.payload.flipDeal.purchasePrice},
          totalProjectCost: { $set: action.payload.flipDeal.totalProjectCost },
          entryFinanceValue: { $set: action.payload.flipDeal.entryFinanceValue },
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          saleExpenses: {$set: action.payload.flipDeal.saleExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          stampDuty: { $set: action.payload.flipDeal.stampDuty },
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.flipDeal.endValue},
          purchaseTerm: {$set: action.payload.flipDeal.purchaseTerm},
          refurbTerm: {$set: action.payload.flipDeal.refurbTerm},
          flipTerm: {$set: action.payload.flipDeal.flipTerm},
        },
        mortgage: {
          $set: action.payload.flipDeal.mortgage,
        },
        bridgingLoan: {
          $set: action.payload.flipDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          deal: {
            name: {
              $set: action.payload.deal.name,
            },
          },
        },
      })
    }

    case CLEAR_EXPENSES_FROM_FLIP_DEAL_REQUEST: {
      return state
    }

    case CLEAR_EXPENSES_FROM_FLIP_DEAL_FAILURE: {
      return state
    }

    case CLEAR_EXPENSES_FROM_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseType: {$set: action.payload.flipDeal.purchaseType},
          purchasePrice: {$set: action.payload.flipDeal.purchasePrice},
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          saleExpenses: {$set: action.payload.flipDeal.saleExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.flipDeal.endValue},
        },
      })
    }

    case ADD_PROSPECTIVE_MORTGAGE_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_MORTGAGE_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseType: {$set: action.payload.flipDeal.purchaseType},
          purchasePrice: {$set: action.payload.flipDeal.purchasePrice},
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          saleExpenses: {$set: action.payload.flipDeal.saleExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.flipDeal.endValue},
          mortgage: {
            $set: action.payload.flipDeal.mortgage,
          },
        },
      })
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_REQUEST: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_FAILURE: {
      return state
    }

    case DELETE_PROSPECTIVE_BRIDGING_LOAN_FROM_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseType: {$set: action.payload.flipDeal.purchaseType},
          purchasePrice: {$set: action.payload.flipDeal.purchasePrice},
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          saleExpenses: {$set: action.payload.flipDeal.saleExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.flipDeal.endValue},
        },
        bridgingLoan: {
          $set: action.payload.flipDeal.bridgingLoan,
        },
      })
    }

    case FETCH_FLIP_DEAL_BRIDGING_LOAN_REQUEST: {
      return update(state, {
        fetchFlipDealBridgingLoanStatus: {$set: 'requested'},
      })
    }

    case FETCH_FLIP_DEAL_BRIDGING_LOAN_FAILURE: {
      return update(state, {
        fetchFlipDealBridgingLoanStatus: {
          $set: 'error',
        },
        fetchFlipDealBridgingLoanError: {$set: action.payload.error},
      })
    }

    case FETCH_FLIP_DEAL_BRIDGING_LOAN_SUCCESS: {
      return update(state, {
        fetchFlipDealBridgingLoanStatus: {$set: 'success'},
        bridgingLoan: {
          $set: action.payload.bridgingLoan,
        },
      })
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_REQUEST: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_FAILURE: {
      return state
    }

    case ADD_PROSPECTIVE_BRIDGING_LOAN_TO_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseType: {$set: action.payload.flipDeal.purchaseType},
          purchasePrice: {$set: action.payload.flipDeal.purchasePrice},
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          saleExpenses: {$set: action.payload.flipDeal.saleExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.flipDeal.endValue},
        },
        bridgingLoan: {
          $set: action.payload.flipDeal.bridgingLoan,
        },
      })
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_BRIDGING_LOAN_FOR_FLIP_DEAL_SUCCESS: {
      return update(state, {
        flipDeal: {
          purchaseType: {$set: action.payload.flipDeal.purchaseType},
          purchasePrice: {$set: action.payload.flipDeal.purchasePrice},
          purchaseCosts: {$set: action.payload.flipDeal.purchaseCosts},
          developmentCosts: {
            $set: action.payload.flipDeal.developmentCosts,
          },
          duringDevelopmentCosts: {
            $set: action.payload.flipDeal.duringDevelopmentCosts,
          },
          cashRequired: {$set: action.payload.flipDeal.cashRequired},
          monthlyExpenses: {$set: action.payload.flipDeal.monthlyExpenses},
          carryingCosts: { $set: action.payload.flipDeal.carryingCosts },
          saleExpenses: {$set: action.payload.flipDeal.saleExpenses},
          totalProfit: {$set: action.payload.flipDeal.totalProfit},
          returnOnInvestment: {
            $set: action.payload.flipDeal.returnOnInvestment,
          },
          endValue: {$set: action.payload.flipDeal.endValue},
        },
        bridgingLoan: {
          prospectiveBridgingLoan: {
            term: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .term,
            },
            interestRate: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .interestRate,
            },
            repaymentType: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .repaymentType,
            },
            grossAmount: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .grossAmount,
            },
            netAmount: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .netAmount,
            },
            arrangementFeeAmount: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .arrangementFeeAmount,
            },
            interestAmount: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .interestAmount,
            },
            rebateAmount: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .rebateAmount,
            },
            monthlyPayment: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .monthlyPayment,
            },
            developmentFinanceBuffer: {
              $set:
                action.payload.flipDeal.bridgingLoan.prospectiveBridgingLoan
                  .developmentFinanceBuffer,
            },
          },
        },
      })
    }

    case UPDATE_FLIP_DEAL_SUPPORT_NEEDED_REQUEST: {
      return state
    }

    case UPDATE_FLIP_DEAL_SUPPORT_NEEDED_FAILURE: {
      return state
    }

    case UPDATE_FLIP_DEAL_SUPPORT_NEEDED_SUCCESS: {
      return update(state, {
        flipDeal: {
          deal: {
            stackingSupportNeeded: {
              $set: action.payload.stackingSupportNeeded
            }
          },
        },
      })
    }

    default:
      return state
  }
}
