import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_REQUEST } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_SUCCESS } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_FAILURE } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { ARCHIVE_SALE_LISTING_REQUEST } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { ARCHIVE_SALE_LISTING_SUCCESS } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { ARCHIVE_SALE_LISTING_FAILURE } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { DELETE_SALE_LISTING_REQUEST } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { DELETE_SALE_LISTING_SUCCESS } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { DELETE_SALE_LISTING_FAILURE } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { UPDATE_SALE_LISTING_REQUEST } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { UPDATE_SALE_LISTING_SUCCESS } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { UPDATE_SALE_LISTING_FAILURE } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  saleListings: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_SALE_LISTINGS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        saleListings: {
          $set: action.payload.prospectiveProperty.property.saleListings,
        },
      })
    }

    case UPDATE_SALE_LISTING_REQUEST: {
      return state
    }

    case UPDATE_SALE_LISTING_FAILURE: {
      return state
    }

    case UPDATE_SALE_LISTING_SUCCESS: {
      let index = state.saleListings.findIndex(
        x => x.id === action.payload.saleListing.id,
      )

      return update(state, {
        saleListings: {
          [index]: { $set: action.payload.saleListing },
        },
      })
    }

    case ARCHIVE_SALE_LISTING_REQUEST: {
      return state
    }

    case ARCHIVE_SALE_LISTING_FAILURE: {
      return state
    }

    case ARCHIVE_SALE_LISTING_SUCCESS: {
      let index = state.saleListings.findIndex(x => x.id === action.payload.saleListing.id)

      return update(state, {
        saleListings: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_SALE_LISTING_REQUEST: {
      return state
    }

    case DELETE_SALE_LISTING_FAILURE: {
      return state
    }

    case DELETE_SALE_LISTING_SUCCESS: {
      let index = state.saleListings.findIndex(x => x.id === action.payload.id)

      return update(state, {
        saleListings: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
