import update from 'immutability-helper'
import { FETCH_TENANCY_RENT_STATEMENT_REQUEST } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { FETCH_TENANCY_RENT_STATEMENT_SUCCESS } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { FETCH_TENANCY_RENT_STATEMENT_FAILURE } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { DELETE_RENT_STATEMENT_TRANSACTION_REQUEST } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { DELETE_RENT_STATEMENT_TRANSACTION_SUCCESS } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { DELETE_RENT_STATEMENT_TRANSACTION_FAILURE } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { CREATE_RENT_STATEMENT_TRANSACTION_REQUEST } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { CREATE_RENT_STATEMENT_TRANSACTION_SUCCESS } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { CREATE_RENT_STATEMENT_TRANSACTION_FAILURE } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { UPDATE_RENT_STATEMENT_TRANSACTION_REQUEST } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { UPDATE_RENT_STATEMENT_TRANSACTION_SUCCESS } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { UPDATE_RENT_STATEMENT_TRANSACTION_FAILURE } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { CREATE_RENT_STATEMENT_INVOICE_REQUEST } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { CREATE_RENT_STATEMENT_INVOICE_SUCCESS } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { CREATE_RENT_STATEMENT_INVOICE_FAILURE } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { UPDATE_RENT_STATEMENT_INVOICE_REQUEST } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { UPDATE_RENT_STATEMENT_INVOICE_SUCCESS } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { UPDATE_RENT_STATEMENT_INVOICE_FAILURE } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { DELETE_RENT_STATEMENT_INVOICE_REQUEST } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { DELETE_RENT_STATEMENT_INVOICE_SUCCESS } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'
import { DELETE_RENT_STATEMENT_INVOICE_FAILURE } from 'v2/spas/tenancy/rent-statement/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  tenancy: {},
  rentStatementRows: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANCY_RENT_STATEMENT_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_TENANCY_RENT_STATEMENT_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_TENANCY_RENT_STATEMENT_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        tenancy: {
          $set: action.payload.tenancy,
        },
        rentStatementRows: {
          $set: action.payload.tenancy.rentStatementRows
        },
      })
    }

    case CREATE_RENT_STATEMENT_TRANSACTION_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case CREATE_RENT_STATEMENT_TRANSACTION_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case CREATE_RENT_STATEMENT_TRANSACTION_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        rentStatementRows: {
          $set: action.payload.transaction.tenancy.rentStatementRows
        },
      })
    }

    case UPDATE_RENT_STATEMENT_TRANSACTION_REQUEST: {
      return state
    }

    case UPDATE_RENT_STATEMENT_TRANSACTION_FAILURE: {
      return state
    }

    case UPDATE_RENT_STATEMENT_TRANSACTION_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        rentStatementRows: {
          $set: action.payload.transaction.tenancy.rentStatementRows
        },
      })
    }

    case DELETE_RENT_STATEMENT_TRANSACTION_REQUEST: {
      return state
    }

    case DELETE_RENT_STATEMENT_TRANSACTION_FAILURE: {
      return state
    }

    case DELETE_RENT_STATEMENT_TRANSACTION_SUCCESS: {
      let index = state.rentStatementRows.findIndex(x => x.transaction.id === action.payload)

      return update(state, {
        rentStatementRows: {
          $splice: [[index, 1]],
        },
      })
    }

    case CREATE_RENT_STATEMENT_INVOICE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case CREATE_RENT_STATEMENT_INVOICE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case CREATE_RENT_STATEMENT_INVOICE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        rentStatementRows: {
          $set: action.payload.invoice.tenancy.rentStatementRows
        },
      })
    }

    case UPDATE_RENT_STATEMENT_INVOICE_REQUEST: {
      return state
    }

    case UPDATE_RENT_STATEMENT_INVOICE_FAILURE: {
      return state
    }

    case UPDATE_RENT_STATEMENT_INVOICE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        rentStatementRows: {
          $set: action.payload.invoice.tenancy.rentStatementRows
        },
      })
    }

    case DELETE_RENT_STATEMENT_INVOICE_REQUEST: {
      return state
    }

    case DELETE_RENT_STATEMENT_INVOICE_FAILURE: {
      return state
    }

    case DELETE_RENT_STATEMENT_INVOICE_SUCCESS: {
      let index = state.rentStatementRows.findIndex(x => x.invoice.id === action.payload)

      return update(state, {
        rentStatementRows: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
