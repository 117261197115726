import update from 'immutability-helper'
import { FETCH_APPLIANCE_REQUEST } from 'v2/spas/owned-property/appliances/redux/action-types.tsx'
import { FETCH_APPLIANCE_SUCCESS } from 'v2/spas/owned-property/appliances/redux/action-types.tsx'
import { FETCH_APPLIANCE_FAILURE } from 'v2/spas/owned-property/appliances/redux/action-types.tsx'
import { UPDATE_APPLIANCE_REQUEST } from 'v2/spas/owned-property/appliances/redux/action-types.tsx'
import { UPDATE_APPLIANCE_SUCCESS } from 'v2/spas/owned-property/appliances/redux/action-types.tsx'
import { UPDATE_APPLIANCE_FAILURE } from 'v2/spas/owned-property/appliances/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  appliance: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_APPLIANCE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_APPLIANCE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_APPLIANCE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        appliance: {
          $set: action.payload.appliance,
        }
      })
    }

    case UPDATE_APPLIANCE_REQUEST: {
      return update(state, {
        updateApplianceStatus: { $set: 'requested' },
      })
    }

    case UPDATE_APPLIANCE_FAILURE: {
      return update(state, {
        updateApplianceStatus: {
          $set: 'error',
        },
        updateApplianceError: { $set: action.payload.error },
      })
    }

    case UPDATE_APPLIANCE_SUCCESS: {
      return update(state, {
        updateApplianceStatus: { $set: 'success' },
        appliance: {
          $set: action.payload.appliance,
        }
      })
    }

    default:
      return state
  }
}
