export const FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_REQUEST = 'FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_REQUEST'
export const FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_SUCCESS = 'FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_SUCCESS'
export const FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_FAILURE = 'FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_FAILURE'

export const FETCH_SMOKE_ALARM_TEST_REQUEST = 'FETCH_SMOKE_ALARM_TEST_REQUEST'
export const FETCH_SMOKE_ALARM_TEST_SUCCESS = 'FETCH_SMOKE_ALARM_TEST_SUCCESS'
export const FETCH_SMOKE_ALARM_TEST_FAILURE = 'FETCH_SMOKE_ALARM_TEST_FAILURE'

export const CREATE_SMOKE_ALARM_TEST_REQUEST = 'CREATE_SMOKE_ALARM_TEST_REQUEST'
export const CREATE_SMOKE_ALARM_TEST_SUCCESS = 'CREATE_SMOKE_ALARM_TEST_SUCCESS'
export const CREATE_SMOKE_ALARM_TEST_FAILURE = 'CREATE_SMOKE_ALARM_TEST_FAILURE'

export const UPDATE_SMOKE_ALARM_TEST_REQUEST = 'UPDATE_SMOKE_ALARM_TEST_REQUEST'
export const UPDATE_SMOKE_ALARM_TEST_SUCCESS = 'UPDATE_SMOKE_ALARM_TEST_SUCCESS'
export const UPDATE_SMOKE_ALARM_TEST_FAILURE = 'UPDATE_SMOKE_ALARM_TEST_FAILURE'

export const ARCHIVE_SMOKE_ALARM_TEST_REQUEST = 'ARCHIVE_SMOKE_ALARM_TEST_REQUEST'
export const ARCHIVE_SMOKE_ALARM_TEST_SUCCESS = 'ARCHIVE_SMOKE_ALARM_TEST_SUCCESS'
export const ARCHIVE_SMOKE_ALARM_TEST_FAILURE = 'ARCHIVE_SMOKE_ALARM_TEST_FAILURE'

export const UNARCHIVE_SMOKE_ALARM_TEST_REQUEST = 'UNARCHIVE_SMOKE_ALARM_TEST_REQUEST'
export const UNARCHIVE_SMOKE_ALARM_TEST_SUCCESS = 'UNARCHIVE_SMOKE_ALARM_TEST_SUCCESS'
export const UNARCHIVE_SMOKE_ALARM_TEST_FAILURE = 'UNARCHIVE_SMOKE_ALARM_TEST_FAILURE'

export const DELETE_SMOKE_ALARM_TEST_REQUEST = 'DELETE_SMOKE_ALARM_TEST_REQUEST'
export const DELETE_SMOKE_ALARM_TEST_SUCCESS = 'DELETE_SMOKE_ALARM_TEST_SUCCESS'
export const DELETE_SMOKE_ALARM_TEST_FAILURE = 'DELETE_SMOKE_ALARM_TEST_FAILURE'