import React from 'react'
import styled from 'styled-components'
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { ExternalLink } from 'links'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px; 
  padding: ${props => props.theme.space.m};
  z-index: 1;

  svg {
    color: black;
    cursor: pointer;
  }
`

function SupportWidget(props) {
  return (
    <Wrapper>
      <ExternalLink href={props.link} text={<QuestionCircleFill size={60} />}>
        <QuestionCircleFill size={60} />
      </ExternalLink>
    </Wrapper>
  )
}


export default SupportWidget

