export const FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_REQUEST = 'FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_REQUEST'
export const FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_SUCCESS = 'FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_SUCCESS'
export const FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_FAILURE = 'FETCH_OWNED_PROPERTY_EXISTING_MORTGAGES_FAILURE'

export const FETCH_EXISTING_MORTGAGE_REQUEST = 'FETCH_EXISTING_MORTGAGE_REQUEST'
export const FETCH_EXISTING_MORTGAGE_SUCCESS = 'FETCH_EXISTING_MORTGAGE_SUCCESS'
export const FETCH_EXISTING_MORTGAGE_FAILURE = 'FETCH_EXISTING_MORTGAGE_FAILURE'

export const CREATE_EXISTING_MORTGAGE_REQUEST = 'CREATE_EXISTING_MORTGAGE_REQUEST'
export const CREATE_EXISTING_MORTGAGE_SUCCESS = 'CREATE_EXISTING_MORTGAGE_SUCCESS'
export const CREATE_EXISTING_MORTGAGE_FAILURE = 'CREATE_EXISTING_MORTGAGE_FAILURE'

export const UPDATE_EXISTING_MORTGAGE_REQUEST = 'UPDATE_EXISTING_MORTGAGE_REQUEST'
export const UPDATE_EXISTING_MORTGAGE_SUCCESS = 'UPDATE_EXISTING_MORTGAGE_SUCCESS'
export const UPDATE_EXISTING_MORTGAGE_FAILURE = 'UPDATE_EXISTING_MORTGAGE_FAILURE'

export const ARCHIVE_EXISTING_MORTGAGE_REQUEST = 'ARCHIVE_EXISTING_MORTGAGE_REQUEST'
export const ARCHIVE_EXISTING_MORTGAGE_SUCCESS = 'ARCHIVE_EXISTING_MORTGAGE_SUCCESS'
export const ARCHIVE_EXISTING_MORTGAGE_FAILURE = 'ARCHIVE_EXISTING_MORTGAGE_FAILURE'

export const UNARCHIVE_EXISTING_MORTGAGE_REQUEST = 'UNARCHIVE_EXISTING_MORTGAGE_REQUEST'
export const UNARCHIVE_EXISTING_MORTGAGE_SUCCESS = 'UNARCHIVE_EXISTING_MORTGAGE_SUCCESS'
export const UNARCHIVE_EXISTING_MORTGAGE_FAILURE = 'UNARCHIVE_EXISTING_MORTGAGE_FAILURE'

export const DELETE_EXISTING_MORTGAGE_REQUEST = 'DELETE_EXISTING_MORTGAGE_REQUEST'
export const DELETE_EXISTING_MORTGAGE_SUCCESS = 'DELETE_EXISTING_MORTGAGE_SUCCESS'
export const DELETE_EXISTING_MORTGAGE_FAILURE = 'DELETE_EXISTING_MORTGAGE_FAILURE'