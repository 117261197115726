import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: ${props => props.theme.space.xl};
  text-align: center;

  h2 {
    padding-bottom: ${props => props.theme.space.m};
  }

  p {
    font-size: 18px;
  }

  padding-left: 20%;
  padding-right: 20%;

  @media ${props => props.theme.devices.tablet} {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media ${props => props.theme.devices.mobile} {
    padding-left: 5%;
    padding-right: 5%;
  }
`

function CommonQuestion(props) {
  return (
    <Wrapper>
      <h2>{props.question}</h2>
      <p>{props.answer}</p>
    </Wrapper>
  );
};

export default CommonQuestion
