import {GRANT_USER_CONSENT_REQUEST} from 'redux/actionTypes.js'
import {GRANT_USER_CONSENT_SUCCESS} from 'redux/actionTypes.js'
import {GRANT_USER_CONSENT_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function grantUserConsent(consentType, handleResponse) {
  return function (dispatch) {
    dispatch(grantUserConsentRequest())

    let variables = {
      type: consentType,
    }

    let query = `
      mutation($type: String!) {
        grantUserConsent(
          type: $type
        ) {
          id
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(grantUserConsentFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(grantUserConsentSuccess())
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(grantUserConsentFailure(error.message))
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const grantUserConsentRequest = () => ({
  type: GRANT_USER_CONSENT_REQUEST,
})

export const grantUserConsentSuccess = () => ({
  type: GRANT_USER_CONSENT_SUCCESS,
  payload: {},
})

export const grantUserConsentFailure = error => ({
  type: GRANT_USER_CONSENT_FAILURE,
  payload: {
    error: error,
  },
})
