export const FETCH_COMPANY_SHAREHOLDERS_REQUEST = 'FETCH_COMPANY_SHAREHOLDERS_REQUEST'
export const FETCH_COMPANY_SHAREHOLDERS_SUCCESS = 'FETCH_COMPANY_SHAREHOLDERS_SUCCESS'
export const FETCH_COMPANY_SHAREHOLDERS_FAILURE = 'FETCH_COMPANY_SHAREHOLDERS_FAILURE'

export const CREATE_SHAREHOLDER_REQUEST = 'CREATE_SHAREHOLDER_REQUEST'
export const CREATE_SHAREHOLDER_SUCCESS = 'CREATE_SHAREHOLDER_SUCCESS'
export const CREATE_SHAREHOLDER_FAILURE = 'CREATE_SHAREHOLDER_FAILURE'

export const UPDATE_SHAREHOLDER_REQUEST = 'UPDATE_SHAREHOLDER_REQUEST'
export const UPDATE_SHAREHOLDER_SUCCESS = 'UPDATE_SHAREHOLDER_SUCCESS'
export const UPDATE_SHAREHOLDER_FAILURE = 'UPDATE_SHAREHOLDER_FAILURE'

export const ARCHIVE_SHAREHOLDER_REQUEST = 'ARCHIVE_SHAREHOLDER_REQUEST'
export const ARCHIVE_SHAREHOLDER_SUCCESS = 'ARCHIVE_SHAREHOLDER_SUCCESS'
export const ARCHIVE_SHAREHOLDER_FAILURE = 'ARCHIVE_SHAREHOLDER_FAILURE'

export const UNARCHIVE_SHAREHOLDER_REQUEST = 'UNARCHIVE_SHAREHOLDER_REQUEST'
export const UNARCHIVE_SHAREHOLDER_SUCCESS = 'UNARCHIVE_SHAREHOLDER_SUCCESS'
export const UNARCHIVE_SHAREHOLDER_FAILURE = 'UNARCHIVE_SHAREHOLDER_FAILURE'

export const DELETE_SHAREHOLDER_REQUEST = 'DELETE_SHAREHOLDER_REQUEST'
export const DELETE_SHAREHOLDER_SUCCESS = 'DELETE_SHAREHOLDER_SUCCESS'
export const DELETE_SHAREHOLDER_FAILURE = 'DELETE_SHAREHOLDER_FAILURE'