import React from 'react'
import Container from 'react-bootstrap/Container'
import PublicTopNavbar from 'sharedComponents/PublicTopNavbar/PublicTopNavbar.js'
import Card from 'sharedComponents/Card.js'
import SetupAccountForm from 'spas/SetupAccount/SetupAccountForm.js'
import Page from 'sharedComponents/Page.js'
import Heading from 'sharedComponents/Forms/Heading.js'
import FormWrapper from 'sharedComponents/Forms/FormWrapper.js'

function SetupAccount(props) {
  return (
    <Container fluid>
      <PublicTopNavbar />
      <Page>
        <FormWrapper>
          <Card backgroundColor={props => props.theme.colors.white}>
            <Heading text="Setup your account" />
            <SetupAccountForm />
          </Card>
        </FormWrapper>
      </Page>
    </Container>
  )
}

export default SetupAccount
