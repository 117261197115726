import update from 'immutability-helper'
import {FETCH_RESOURCE_CATEGORY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_RESOURCE_CATEGORY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_RESOURCE_CATEGORY_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchResourceCategoryStatus: 'requested',
  fetchResourceCategoryError: '',
  resourceCategory: {
    teachingResources: [],
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_RESOURCE_CATEGORY_REQUEST: {
      return update(state, {
        fetchResourceCategoryStatus: {$set: 'requested'},
      })
    }

    case FETCH_RESOURCE_CATEGORY_FAILURE: {
      return update(state, {
        fetchResourceCategoryStatus: {
          $set: 'error',
        },
        fetchResourceCategoryError: {$set: action.payload.error},
      })
    }

    case FETCH_RESOURCE_CATEGORY_SUCCESS: {
      return update(state, {
        resourceCategory: {
          $set: action.payload.resourceCategory,
        },
      })
    }

    default:
      return state
  }
}
