import update from 'immutability-helper'
import { FETCH_ACCOUNT_DEAL_TEMPLATES_REQUEST } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { FETCH_ACCOUNT_DEAL_TEMPLATES_SUCCESS } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { FETCH_ACCOUNT_DEAL_TEMPLATES_FAILURE } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { ARCHIVE_DEAL_TEMPLATE_REQUEST } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { ARCHIVE_DEAL_TEMPLATE_SUCCESS } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { ARCHIVE_DEAL_TEMPLATE_FAILURE } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { UPDATE_DEAL_TEMPLATE_REQUEST } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { UPDATE_DEAL_TEMPLATE_SUCCESS } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { UPDATE_DEAL_TEMPLATE_FAILURE } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { DELETE_DEAL_TEMPLATE_REQUEST } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { DELETE_DEAL_TEMPLATE_SUCCESS } from 'v2/spas/account/deal-templates/redux/action-types.tsx'
import { DELETE_DEAL_TEMPLATE_FAILURE } from 'v2/spas/account/deal-templates/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  account: {},
  dealTemplates: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        account: {
          $set: action.payload.account,
        },
        dealTemplates: {
          $set: action.payload.account.dealTemplates
        },
      })
    }

    case UPDATE_DEAL_TEMPLATE_REQUEST: {
      return state
    }

    case UPDATE_DEAL_TEMPLATE_FAILURE: {
      return state
    }

    case UPDATE_DEAL_TEMPLATE_SUCCESS: {
      let index = state.dealTemplates.findIndex(
        x => x.id === action.payload.dealTemplate.id,
      )

      return update(state, {
        dealTemplates: {
          [index]: { $set: action.payload.dealTemplate },
        },
      })
    }

    case ARCHIVE_DEAL_TEMPLATE_REQUEST: {
      return state
    }

    case ARCHIVE_DEAL_TEMPLATE_FAILURE: {
      return state
    }

    case ARCHIVE_DEAL_TEMPLATE_SUCCESS: {
      let index = state.dealTemplates.findIndex(x => x.id === action.payload.dealTemplate.id)

      return update(state, {
        dealTemplates: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_DEAL_TEMPLATE_REQUEST: {
      return state
    }
    
    case DELETE_DEAL_TEMPLATE_FAILURE: {
      return state
    }
    
    case DELETE_DEAL_TEMPLATE_SUCCESS: {
      let index = state.dealTemplates.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        dealTemplates: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
