import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_REQUEST } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_SUCCESS } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_FAILURE } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_APPLICATION_REQUEST } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_APPLICATION_SUCCESS } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_APPLICATION_FAILURE } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { UPDATE_MORTGAGE_APPLICATION_REQUEST } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { UPDATE_MORTGAGE_APPLICATION_SUCCESS } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { UPDATE_MORTGAGE_APPLICATION_FAILURE } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { DELETE_MORTGAGE_APPLICATION_REQUEST } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { DELETE_MORTGAGE_APPLICATION_SUCCESS } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'
import { DELETE_MORTGAGE_APPLICATION_FAILURE } from 'v2/spas/prospective-property/mortgage-applications/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  mortgageApplications: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_MORTGAGE_APPLICATIONS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        mortgageApplications: {
          $set: action.payload.prospectiveProperty.property.mortgageApplications
        },
      })
    }

    case UPDATE_MORTGAGE_APPLICATION_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_APPLICATION_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_APPLICATION_SUCCESS: {
      let index = state.mortgageApplications.findIndex(
        x => x.id === action.payload.mortgageApplication.id,
      )

      return update(state, {
        mortgageApplications: {
          [index]: { $set: action.payload.mortgageApplication },
        },
      })
    }

    case ARCHIVE_MORTGAGE_APPLICATION_REQUEST: {
      return state
    }

    case ARCHIVE_MORTGAGE_APPLICATION_FAILURE: {
      return state
    }

    case ARCHIVE_MORTGAGE_APPLICATION_SUCCESS: {
      let index = state.mortgageApplications.findIndex(x => x.id === action.payload.mortgageApplication.id)

      return update(state, {
        mortgageApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_MORTGAGE_APPLICATION_REQUEST: {
      return state
    }
    
    case DELETE_MORTGAGE_APPLICATION_FAILURE: {
      return state
    }
    
    case DELETE_MORTGAGE_APPLICATION_SUCCESS: {
      let index = state.mortgageApplications.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        mortgageApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
