import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import StatusWrapper from 'sharedComponents/StatusBanner/StatusContainer.js'
import StatusSubContent from 'sharedComponents/StatusBanner/StatusSubContent.js'
import StatusMobileTextContainer from 'sharedComponents/StatusBanner/StatusMobileTextContainer.js'
import CreateSubscriptionLink from 'sharedComponents/StatusBanner/CreateSubscriptionLink.js'
import {RenderInDevice} from 'functional-components'

const TrialEndingSoon = ({trialEnd}) => {
  const today = moment()
  const diff = moment(trialEnd).diff(today)
  const duration = moment.duration(diff)
  const daysLeft = Math.round(duration.asDays())
  const isExpiringToday = today.isSame(moment(trialEnd), 'day')

  const renderDaysLeft = () => {
    if (isExpiringToday) {
      return (
        <>
          <StatusSubContent>Hurry, your</StatusSubContent>
          free trial
          <StatusSubContent>expires</StatusSubContent>
          today.
        </>
      )
    }
    if (daysLeft === 1) {
      return (
        <>
          <StatusSubContent>Hurry, only</StatusSubContent>
          {`${daysLeft} day`}
          <StatusSubContent>left on your</StatusSubContent>
          free trial.
        </>
      )
    }
    return (
      <>
        <StatusSubContent>Hurry, only</StatusSubContent>
        {`${daysLeft} days`}
        <StatusSubContent>left on your</StatusSubContent>
        free trial.
      </>
    )
  }

  const renderMobileDaysLeft = () => {
    if (isExpiringToday) {
      return (
        <StatusMobileTextContainer fontSize="0.8rem">
          <StatusSubContent>Hurry, your</StatusSubContent>
          free trial
          <StatusSubContent>expires</StatusSubContent>
          today.
        </StatusMobileTextContainer>
      )
    }
    if (daysLeft === 1) {
      return (
        <StatusMobileTextContainer fontSize="0.8rem">
          <StatusSubContent>Hurry, only</StatusSubContent>
          {`${daysLeft} day`}
          <StatusSubContent>left on your</StatusSubContent>
          free trial.
        </StatusMobileTextContainer>
      )
    }
    return (
      <StatusMobileTextContainer fontSize="0.8rem">
        <StatusSubContent>Hurry, only</StatusSubContent>
        {`${daysLeft} days`}
        <StatusSubContent>left on your</StatusSubContent>
        free trial.
      </StatusMobileTextContainer>
    )
  }

  return (
    <>
      <RenderInDevice devices={['desktop', 'laptop', 'tablet']}>
        <StatusWrapper>
          {renderDaysLeft()}
          <CreateSubscriptionLink />
        </StatusWrapper>
      </RenderInDevice>

      <RenderInDevice devices={['smallMobile', 'mobile']}>
        <StatusWrapper flexDirection="column">
          {renderMobileDaysLeft()}
          <CreateSubscriptionLink fontSize="0.8rem" paddingLeft={props => props.theme.space.xs} />
        </StatusWrapper>
      </RenderInDevice>
    </>
  )
}

TrialEndingSoon.propTypes = {
  trialEnd: PropTypes.string.isRequired,
}

export default TrialEndingSoon
