import update from 'immutability-helper'
import { FETCH_ACADEMY_REQUEST } from 'v2/spas/community/community/redux/action-types.tsx'
import { FETCH_ACADEMY_SUCCESS } from 'v2/spas/community/community/redux/action-types.tsx'
import { FETCH_ACADEMY_FAILURE } from 'v2/spas/community/community/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  community: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACADEMY_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACADEMY_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACADEMY_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        community: {
          $set: action.payload.community,
        }
      })
    }

    default:
      return state
  }
}