import update from 'immutability-helper'
import { FETCH_INVESTMENT_OPPORTUNITY_REQUEST } from 'v2/spas/prospective-property/investment-opportunities/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_SUCCESS } from 'v2/spas/prospective-property/investment-opportunities/redux/action-types.tsx'
import { FETCH_INVESTMENT_OPPORTUNITY_FAILURE } from 'v2/spas/prospective-property/investment-opportunities/redux/action-types.tsx'
import { UPDATE_INVESTMENT_OPPORTUNITY_REQUEST } from 'v2/spas/prospective-property/investment-opportunities/redux/action-types.tsx'
import { UPDATE_INVESTMENT_OPPORTUNITY_SUCCESS } from 'v2/spas/prospective-property/investment-opportunities/redux/action-types.tsx'
import { UPDATE_INVESTMENT_OPPORTUNITY_FAILURE } from 'v2/spas/prospective-property/investment-opportunities/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  investmentOpportunity: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INVESTMENT_OPPORTUNITY_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_INVESTMENT_OPPORTUNITY_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        investmentOpportunity: {
          $set: action.payload.investmentOpportunity,
        }
      })
    }

    case UPDATE_INVESTMENT_OPPORTUNITY_REQUEST: {
      return update(state, {
        updateInvestmentOpportunityStatus: { $set: 'requested' },
      })
    }

    case UPDATE_INVESTMENT_OPPORTUNITY_FAILURE: {
      return update(state, {
        updateInvestmentOpportunityStatus: {
          $set: 'error',
        },
        updateInvestmentOpportunityError: { $set: action.payload.error },
      })
    }

    case UPDATE_INVESTMENT_OPPORTUNITY_SUCCESS: {
      return update(state, {
        updateInvestmentOpportunityStatus: { $set: 'success' },
        investmentOpportunity: {
          $set: action.payload.investmentOpportunity,
        }
      })
    }

    default:
      return state
  }
}