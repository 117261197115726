import {UPDATE_AVATAR_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_AVATAR_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_AVATAR_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function updateAvatar(accountUserId, formData, handleResponse) {
  return function (dispatch) {
    dispatch(updateAvatarRequest())

    return axios({
      url:
        process.env.REACT_APP_API_PATH + 'account_users/' + accountUserId + '/update_avatar',
      method: 'put',
      data: formData,
      headers: {
        Authorization: Cookies.get('jwtToken'),
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(updateAvatarFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(updateAvatarSuccess(res.data.data.updateAvatar.avatarUrl))
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(updateAvatarFailure(error.message))
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const updateAvatarRequest = () => ({
  type: UPDATE_AVATAR_REQUEST,
})

export const updateAvatarSuccess = avatarUrl => ({
  type: UPDATE_AVATAR_SUCCESS,
  payload: {
    avatarUrl: avatarUrl,
  },
})

export const updateAvatarFailure = error => ({
  type: UPDATE_AVATAR_FAILURE,
  payload: {
    error: error,
  },
})
