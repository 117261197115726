export const FETCH_PROSPECTIVE_PROPERTY_INVESTMENT_OPPORTUNITIES_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_INVESTMENT_OPPORTUNITIES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_INVESTMENT_OPPORTUNITIES_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_INVESTMENT_OPPORTUNITIES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_INVESTMENT_OPPORTUNITIES_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_INVESTMENT_OPPORTUNITIES_FAILURE'

export const FETCH_INVESTMENT_OPPORTUNITY_REQUEST = 'FETCH_INVESTMENT_OPPORTUNITY_REQUEST'
export const FETCH_INVESTMENT_OPPORTUNITY_SUCCESS = 'FETCH_INVESTMENT_OPPORTUNITY_SUCCESS'
export const FETCH_INVESTMENT_OPPORTUNITY_FAILURE = 'FETCH_INVESTMENT_OPPORTUNITY_FAILURE'

export const CREATE_INVESTMENT_OPPORTUNITY_REQUEST = 'CREATE_INVESTMENT_OPPORTUNITY_REQUEST'
export const CREATE_INVESTMENT_OPPORTUNITY_SUCCESS = 'CREATE_INVESTMENT_OPPORTUNITY_SUCCESS'
export const CREATE_INVESTMENT_OPPORTUNITY_FAILURE = 'CREATE_INVESTMENT_OPPORTUNITY_FAILURE'

export const UPDATE_INVESTMENT_OPPORTUNITY_REQUEST = 'UPDATE_INVESTMENT_OPPORTUNITY_REQUEST'
export const UPDATE_INVESTMENT_OPPORTUNITY_SUCCESS = 'UPDATE_INVESTMENT_OPPORTUNITY_SUCCESS'
export const UPDATE_INVESTMENT_OPPORTUNITY_FAILURE = 'UPDATE_INVESTMENT_OPPORTUNITY_FAILURE'

export const ARCHIVE_INVESTMENT_OPPORTUNITY_REQUEST = 'ARCHIVE_INVESTMENT_OPPORTUNITY_REQUEST'
export const ARCHIVE_INVESTMENT_OPPORTUNITY_SUCCESS = 'ARCHIVE_INVESTMENT_OPPORTUNITY_SUCCESS'
export const ARCHIVE_INVESTMENT_OPPORTUNITY_FAILURE = 'ARCHIVE_INVESTMENT_OPPORTUNITY_FAILURE'

export const UNARCHIVE_INVESTMENT_OPPORTUNITY_REQUEST = 'UNARCHIVE_INVESTMENT_OPPORTUNITY_REQUEST'
export const UNARCHIVE_INVESTMENT_OPPORTUNITY_SUCCESS = 'UNARCHIVE_INVESTMENT_OPPORTUNITY_SUCCESS'
export const UNARCHIVE_INVESTMENT_OPPORTUNITY_FAILURE = 'UNARCHIVE_INVESTMENT_OPPORTUNITY_FAILURE'

export const DELETE_INVESTMENT_OPPORTUNITY_REQUEST = 'DELETE_INVESTMENT_OPPORTUNITY_REQUEST'
export const DELETE_INVESTMENT_OPPORTUNITY_SUCCESS = 'DELETE_INVESTMENT_OPPORTUNITY_SUCCESS'
export const DELETE_INVESTMENT_OPPORTUNITY_FAILURE = 'DELETE_INVESTMENT_OPPORTUNITY_FAILURE'