import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import StatusWrapper from 'sharedComponents/StatusBanner/StatusContainer.js'
import StatusSubContent from 'sharedComponents/StatusBanner/StatusSubContent.js'
import StatusMobileTextContainer from 'sharedComponents/StatusBanner/StatusMobileTextContainer.js'
import CreateSubscriptionLink from 'sharedComponents/StatusBanner/CreateSubscriptionLink.js'
import {RenderInDevice} from 'functional-components'

const Trialing = ({trialEnd}) => {
  const today = moment()
  const diff = moment(trialEnd).diff(today)
  const duration = moment.duration(diff)
  const daysLeft = Math.round(duration.asDays())

  return (
    <>
      <RenderInDevice devices={['desktop', 'laptop', 'tablet']}>
        <StatusWrapper>
          {`${daysLeft} days`}
          <StatusSubContent>left on your</StatusSubContent>
          free trial.
          <CreateSubscriptionLink />
        </StatusWrapper>
      </RenderInDevice>

      <RenderInDevice devices={['smallMobile', 'mobile']}>
        <StatusWrapper flexDirection="column">
          <StatusMobileTextContainer fontSize="0.8rem">
            {`${daysLeft} days`}
            <StatusSubContent>left on your</StatusSubContent>
            free trial.
          </StatusMobileTextContainer>
          <CreateSubscriptionLink fontSize="0.8rem" paddingLeft="0" />
        </StatusWrapper>
      </RenderInDevice>
    </>
  )
}

Trialing.propTypes = {
  trialEnd: PropTypes.string.isRequired,
}

export default Trialing
