import update from 'immutability-helper'
import { FETCH_GOALS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_GOALS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_GOALS_SUCCESS } from 'redux/actionTypes.js'
import { ADD_GOAL_REQUEST } from 'redux/actionTypes.js'
import { ADD_GOAL_FAILURE } from 'redux/actionTypes.js'
import { ADD_GOAL_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_GOAL_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_GOAL_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_GOAL_SUCCESS } from 'redux/actionTypes.js'
import { DELETE_GOAL_REQUEST } from 'redux/actionTypes.js'
import { DELETE_GOAL_FAILURE } from 'redux/actionTypes.js'
import { DELETE_GOAL_SUCCESS } from 'redux/actionTypes.js'
import { COMPLETE_GOAL_REQUEST } from 'redux/actionTypes.js'
import { COMPLETE_GOAL_FAILURE } from 'redux/actionTypes.js'
import { COMPLETE_GOAL_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchGoalsStatus: 'requested',
  fetchGoalsError: '',
  goals: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_GOALS_REQUEST: {
            return update(state, {
                fetchGoalsStatus: { $set: 'requested' },
            })
        }

        case FETCH_GOALS_FAILURE: {
            return update(state, {
                fetchGoalsStatus: {
                    $set: 'error',
                },
                fetchGoalsError: { $set: action.payload.error },
            })
        }

        case FETCH_GOALS_SUCCESS: {
            return update(state, {
                fetchGoalsStatus: { $set: 'success' },
                goals: {
                    $set: action.payload.goals,
                },
            })
        }

        case ADD_GOAL_REQUEST: {
            return state
        }

        case ADD_GOAL_FAILURE: {
            return state
        }

        case ADD_GOAL_SUCCESS: {
            return update(state, {
                goals: {
                    $push: [action.payload.goal],
                },
            })
        }

        case UPDATE_GOAL_REQUEST: {
            return state
        }

        case UPDATE_GOAL_FAILURE: {
            return state
        }

        case UPDATE_GOAL_SUCCESS: {
            let index = state.goals.findIndex(
                x => x.id === action.payload.goal.id,
            )

            return update(state, {
                goals: {
                    [index]: { $set: action.payload.goal },
                },
            })
        }

        case DELETE_GOAL_REQUEST: {
            return state
        }

        case DELETE_GOAL_FAILURE: {
            return state
        }

        case DELETE_GOAL_SUCCESS: {
            let index = state.goals.findIndex(
                x => x.id === action.payload.id,
            )

            return update(state, {
                goals: {
                    $splice: [[index, 1]],
                },
            })
        }

        case COMPLETE_GOAL_REQUEST: {
            return state
        }

        case COMPLETE_GOAL_FAILURE: {
            return state
        }

        case COMPLETE_GOAL_SUCCESS: {
            let index = state.goals.findIndex(
                x => x.id === action.payload.id,
            )

            return update(state, {
                goals: {
                    $splice: [[index, 1]],
                },
            })
        }

        default:
            return state
    }
}
