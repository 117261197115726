export const FETCH_OWNED_PROPERTY_VIDEOS_REQUEST = 'FETCH_OWNED_PROPERTY_VIDEOS_REQUEST'
export const FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS = 'FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS'
export const FETCH_OWNED_PROPERTY_VIDEOS_FAILURE = 'FETCH_OWNED_PROPERTY_VIDEOS_FAILURE'

export const FETCH_VIDEO_REQUEST = 'FETCH_VIDEO_REQUEST'
export const FETCH_VIDEO_SUCCESS = 'FETCH_VIDEO_SUCCESS'
export const FETCH_VIDEO_FAILURE = 'FETCH_VIDEO_FAILURE'

export const CREATE_VIDEO_REQUEST = 'CREATE_VIDEO_REQUEST'
export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS'
export const CREATE_VIDEO_FAILURE = 'CREATE_VIDEO_FAILURE'

export const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST'
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS'
export const UPDATE_VIDEO_FAILURE = 'UPDATE_VIDEO_FAILURE'

export const ARCHIVE_VIDEO_REQUEST = 'ARCHIVE_VIDEO_REQUEST'
export const ARCHIVE_VIDEO_SUCCESS = 'ARCHIVE_VIDEO_SUCCESS'
export const ARCHIVE_VIDEO_FAILURE = 'ARCHIVE_VIDEO_FAILURE'

export const UNARCHIVE_VIDEO_REQUEST = 'UNARCHIVE_VIDEO_REQUEST'
export const UNARCHIVE_VIDEO_SUCCESS = 'UNARCHIVE_VIDEO_SUCCESS'
export const UNARCHIVE_VIDEO_FAILURE = 'UNARCHIVE_VIDEO_FAILURE'

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST'
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS'
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE'