import update from 'immutability-helper'
import {FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE} from 'redux/actionTypes.js'

const initialState = {
  fetchProspectivePropertiesStatus: 'requested',
  fetchProspectivePropertiesError: '',
  prospectiveProperties: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST: {
      return update(state, {
        fetchProspectivePropertiesStatus: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE: {
      return update(state, {
        fetchProspectivePropertiesStatus: {
          $set: 'error',
        },
        fetchprospectivePropertiesError: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS: {
      return update(state, {
        fetchProspectivePropertiesStatus: { $set: 'success' },
        prospectiveProperties: {
          $set: action.payload.currentAccount.prospectiveProperties,
        },
      })
    }

    case UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_PROPERTIES_WORKFLOW_REPORT_SUCCESS: {
      const prospectivePropertyIndex = 
        state.prospectiveProperties.findIndex(({ workflows }) => {
          return workflows.findIndex(({ id }) => id === action.payload.workflow.id) !== -1
        })
      let index = state.prospectiveProperties[prospectivePropertyIndex].workflows.findIndex(
        x => x.id === action.payload.workflow.id,
      )

      return update(state, {
        prospectiveProperties: {
          [prospectivePropertyIndex]: {
            workflows: {
              [index]: { $set: action.payload.workflow }
            }
          },
        },
      })
    }

    default:
      return state
  }
}
