export const FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_REQUEST = 'FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_REQUEST'
export const FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_SUCCESS = 'FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_SUCCESS'
export const FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_FAILURE = 'FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_FAILURE'

export const FETCH_OWNED_PROPERTY_ACCOUNT_REQUEST = 'FETCH_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const FETCH_OWNED_PROPERTY_ACCOUNT_SUCCESS = 'FETCH_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const FETCH_OWNED_PROPERTY_ACCOUNT_FAILURE = 'FETCH_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const CREATE_OWNED_PROPERTY_ACCOUNT_REQUEST = 'CREATE_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const CREATE_OWNED_PROPERTY_ACCOUNT_SUCCESS = 'CREATE_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const CREATE_OWNED_PROPERTY_ACCOUNT_FAILURE = 'CREATE_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST = 'UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS = 'UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE = 'UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const ARCHIVE_OWNED_PROPERTY_ACCOUNT_REQUEST = 'ARCHIVE_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const ARCHIVE_OWNED_PROPERTY_ACCOUNT_SUCCESS = 'ARCHIVE_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const ARCHIVE_OWNED_PROPERTY_ACCOUNT_FAILURE = 'ARCHIVE_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const UNARCHIVE_OWNED_PROPERTY_ACCOUNT_REQUEST = 'UNARCHIVE_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const UNARCHIVE_OWNED_PROPERTY_ACCOUNT_SUCCESS = 'UNARCHIVE_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const UNARCHIVE_OWNED_PROPERTY_ACCOUNT_FAILURE = 'UNARCHIVE_OWNED_PROPERTY_ACCOUNT_FAILURE'

export const DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST = 'DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST'
export const DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS = 'DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS'
export const DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE = 'DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE'