import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_REQUEST } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_SUCCESS } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_FAILURE } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { ARCHIVE_SMOKE_ALARM_TEST_REQUEST } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { ARCHIVE_SMOKE_ALARM_TEST_SUCCESS } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { ARCHIVE_SMOKE_ALARM_TEST_FAILURE } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { UPDATE_SMOKE_ALARM_TEST_REQUEST } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { UPDATE_SMOKE_ALARM_TEST_SUCCESS } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { UPDATE_SMOKE_ALARM_TEST_FAILURE } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { DELETE_SMOKE_ALARM_TEST_REQUEST } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { DELETE_SMOKE_ALARM_TEST_SUCCESS } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'
import { DELETE_SMOKE_ALARM_TEST_FAILURE } from 'v2/spas/owned-property/smoke-alarm-tests/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  smokeAlarmTests: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_SMOKE_ALARM_TESTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        smokeAlarmTests: {
          $set: action.payload.ownedProperty.property.smokeAlarmTests,
        },
      })
    }

    case UPDATE_SMOKE_ALARM_TEST_REQUEST: {
      return state
    }

    case UPDATE_SMOKE_ALARM_TEST_FAILURE: {
      return state
    }

    case UPDATE_SMOKE_ALARM_TEST_SUCCESS: {
      let index = state.smokeAlarmTests.findIndex(
        x => x.id === action.payload.smokeAlarmTest.id,
      )

      return update(state, {
        smokeAlarmTests: {
          [index]: { $set: action.payload.smokeAlarmTest },
        },
      })
    }

    case ARCHIVE_SMOKE_ALARM_TEST_REQUEST: {
      return state
    }

    case ARCHIVE_SMOKE_ALARM_TEST_FAILURE: {
      return state
    }

    case ARCHIVE_SMOKE_ALARM_TEST_SUCCESS: {
      let index = state.smokeAlarmTests.findIndex(x => x.id === action.payload.smokeAlarmTest.id)

      return update(state, {
        smokeAlarmTests: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_SMOKE_ALARM_TEST_REQUEST: {
      return state
    }

    case DELETE_SMOKE_ALARM_TEST_FAILURE: {
      return state
    }

    case DELETE_SMOKE_ALARM_TEST_SUCCESS: {
      let index = state.smokeAlarmTests.findIndex(x => x.id === action.payload.id)

      return update(state, {
        smokeAlarmTests: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
