import React from 'react'
import {connect} from 'react-redux'
import {useSnackbar} from 'notistack'
import {manageSubscription} from 'redux/actions/subscriptions/manageSubscription'
import ProspectivePropertyResourceCard from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCard.js'
import ProspectivePropertyResourceCardHeader from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardHeader.js'
import ManageSubscriptionButton from 'spas/Subscriptions/ManageSubscriptionButton'

function SubscriptionCard(props) {
  const {subscription, manageSubscription} = props
  const {enqueueSnackbar} = useSnackbar()
  const status = subscription?.status ?? ''

  function handleClickManageSubscription() {
    manageSubscription(handleManageSubscriptionResponse)
  }

  function handleManageSubscriptionResponse(response) {
    if (response.status === 'success') {
      window.location.href = response.portalSessionUrl
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
    }
  }

  return (
    <ProspectivePropertyResourceCard>
      <ProspectivePropertyResourceCardHeader heading="My Subscription">
        <ManageSubscriptionButton status={status} onClick={handleClickManageSubscription} />
      </ProspectivePropertyResourceCardHeader>
    </ProspectivePropertyResourceCard>
  )
}

const mapStateToProps = state => ({
  subscription: state.profile.currentUser.currentAccount.subscription,
})

const mapDispatchToProps = {
  manageSubscription: manageSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCard)
