import {SET_PASSWORD_REQUEST} from 'redux/actionTypes.js'
import {SET_PASSWORD_SUCCESS} from 'redux/actionTypes.js'
import {SET_PASSWORD_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function setPassword(emailIdentityId, values, handleResponse) {
  return function (dispatch) {
    dispatch(setPasswordRequest())

    let variables = {
      id: emailIdentityId,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    }

    let query = `
      mutation($id: String!, $password: String!, $passwordConfirmation: String!) {
        setPassword(
          id: $id
          password: $password
          passwordConfirmation: $passwordConfirmation
        ) {
          id
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(setPasswordFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(setPasswordSuccess())
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(setPasswordFailure())
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const setPasswordRequest = () => ({
  type: SET_PASSWORD_REQUEST,
})

export const setPasswordSuccess = () => ({
  type: SET_PASSWORD_SUCCESS,
})

export const setPasswordFailure = error => ({
  type: SET_PASSWORD_FAILURE,
  payload: {
    error: error,
  },
})
