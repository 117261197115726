export const FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_REQUEST = 'FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_REQUEST'
export const FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_SUCCESS = 'FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_SUCCESS'
export const FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_FAILURE = 'FETCH_BRIDGING_LOAN_APPLICATION_BRIDGING_LOAN_APPLICANTS_FAILURE'

export const CREATE_BRIDGING_LOAN_APPLICANT_REQUEST = 'CREATE_BRIDGING_LOAN_APPLICANT_REQUEST'
export const CREATE_BRIDGING_LOAN_APPLICANT_SUCCESS = 'CREATE_BRIDGING_LOAN_APPLICANT_SUCCESS'
export const CREATE_BRIDGING_LOAN_APPLICANT_FAILURE = 'CREATE_BRIDGING_LOAN_APPLICANT_FAILURE'

export const DELETE_BRIDGING_LOAN_APPLICANT_REQUEST = 'DELETE_BRIDGING_LOAN_APPLICANT_REQUEST'
export const DELETE_BRIDGING_LOAN_APPLICANT_SUCCESS = 'DELETE_BRIDGING_LOAN_APPLICANT_SUCCESS'
export const DELETE_BRIDGING_LOAN_APPLICANT_FAILURE = 'DELETE_BRIDGING_LOAN_APPLICANT_FAILURE'
