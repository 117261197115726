import React from 'react'
import Dashboard from 'v2/spas/dashboard/pages/view.tsx'
import SetPassword from 'spas/SetPassword/SetPassword.js'
import SetupAccount from 'spas/SetupAccount/SetupAccount.js'
import Profile from 'spas/Profile/Profile.js'
import Subscription from 'spas/Subscriptions/Subscription.js'
import Login from 'spas/Login/Login'
import MainWrapper from 'sharedComponents/Authentication/MainWrapper'

export function rootPath(currentUser, currentEmailIdentity, currentAccount) {
  let signUpStatus = currentUser.signUpStatus
  let subscription = currentAccount.subscription || {}
  let subscriptionStatus = subscription.status
  let currentAccountUser = currentUser.currentAccountUser || {}

  if (!currentUser.id) {
    return <Login />
  } else if (signUpStatus === 'confirm_email') {
    return <Login />
  } else if (signUpStatus === 'set_password') {
    return (
      <SetPassword
        currentUser={currentUser}
        currentEmailIdentity={currentEmailIdentity}
      />
    )
  } else if (signUpStatus === 'create_account') {
    return <SetupAccount currentUser={currentUser} />
  } else if (signUpStatus === 'accept_terms_and_conditions') {
    return <SetupAccount currentUser={currentUser} />
  } else if (signUpStatus === 'process_property_investment_data') {
    return <Profile />
  } else if (subscriptionStatus === 'unredeemed_promotion_code') {
    return <Subscription />
  } else if (currentAccountUser.propertyInvestor) {
    return(
      <MainWrapper>
        <Dashboard />
      </MainWrapper>
    )
  }
}
