import { formatMoney } from 'accounting'
import { useCurrentAccount } from 'functions'

export function useFormattedMoney(amount) {
  let currentAccount = useCurrentAccount()
  let currencySymbol = currentAccount.currencySymbol || '£'

  return formatMoney(amount, currencySymbol, 2, ',', '.', '%s%v')
}

export default useFormattedMoney
