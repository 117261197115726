import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_KEYS_REQUEST } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_KEYS_SUCCESS } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_KEYS_FAILURE } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { ARCHIVE_KEY_REQUEST } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { ARCHIVE_KEY_SUCCESS } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { ARCHIVE_KEY_FAILURE } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { UPDATE_KEY_REQUEST } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { UPDATE_KEY_SUCCESS } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { UPDATE_KEY_FAILURE } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { DELETE_KEY_REQUEST } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { DELETE_KEY_SUCCESS } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { DELETE_KEY_FAILURE } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { FETCH_PROPERTY_INVESTORS_REQUEST } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { FETCH_PROPERTY_INVESTORS_SUCCESS } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { FETCH_PROPERTY_INVESTORS_FAILURE } from 'v2/spas/owned-property/keys/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  keys: [],
  fetchPropertyInvestorsStatus: 'requested',
  fetchPropertyInvestorsError: '',
  propertyInvestors: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_KEYS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_KEYS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_KEYS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        keys: {
          $set: action.payload.ownedProperty.property.keys
        },
      })
    }

    case UPDATE_KEY_REQUEST: {
      return state
    }

    case UPDATE_KEY_FAILURE: {
      return state
    }

    case UPDATE_KEY_SUCCESS: {
      let index = state.keys.findIndex(
        x => x.id === action.payload.key.id,
      )

      return update(state, {
        keys: {
          [index]: { $set: action.payload.key },
        },
      })
    }

    case ARCHIVE_KEY_REQUEST: {
      return state
    }

    case ARCHIVE_KEY_FAILURE: {
      return state
    }

    case ARCHIVE_KEY_SUCCESS: {
      let index = state.keys.findIndex(x => x.id === action.payload.key.id)

      return update(state, {
        keys: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_KEY_REQUEST: {
      return state
    }

    case DELETE_KEY_FAILURE: {
      return state
    }

    case DELETE_KEY_SUCCESS: {
      let index = state.keys.findIndex(x => x.id === action.payload.id)

      return update(state, {
        keys: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_PROPERTY_INVESTORS_REQUEST: {
      return update(state, {
        fetchPropertyInvestorsStatus: {$set: 'requested'},
      })
    }

    case FETCH_PROPERTY_INVESTORS_FAILURE: {
      return update(state, {
        fetchPropertyInvestorsStatus: {
          $set: 'error',
        },
        fetchPropertyInvestorsError: {$set: action.payload.error},
      })
    }

    case FETCH_PROPERTY_INVESTORS_SUCCESS: {
      return update(state, {
        fetchPropertyInvestorsStatus: {$set: 'success'},
        propertyInvestors: {
          $set: action.payload.currentAccount.propertyInvestors,
        },
      })
    }

    default:
      return state
  }
}
