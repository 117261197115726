import React from 'react'
import NavbarLink from 'sharedComponents/NavbarLink.js'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
`

function PropertyInvestorProfilePageNavbarLinks(props) {
  return (
    <Wrapper>
      <NavbarLink
        to="/profile"
        text="Details"
        textColour={props => props.theme.colors.black}
        activeBarColour={props => props.theme.colors.green}
      />
    </Wrapper>
  )
}

export default PropertyInvestorProfilePageNavbarLinks
