function useSlideHeight(preview, presentMode) {
  if (preview) {
    return '105px'
  } else if (presentMode) {
    return '100vh'
  } else {
    return '640px'
  }
}

export default useSlideHeight
