import update from 'immutability-helper'
import {FETCH_COMPANIES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_COMPANIES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_COMPANIES_SUCCESS} from 'redux/actionTypes.js'
import {ADD_COMPANY_REQUEST} from 'redux/actionTypes.js'
import {ADD_COMPANY_FAILURE} from 'redux/actionTypes.js'
import {ADD_COMPANY_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_COMPANY_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_COMPANY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COMPANY_SUCCESS} from 'redux/actionTypes.js'
import {ARCHIVE_COMPANY_REQUEST} from 'redux/actionTypes.js'
import {ARCHIVE_COMPANY_FAILURE} from 'redux/actionTypes.js'
import {ARCHIVE_COMPANY_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchCompaniesStatus: 'requested',
  fetchCompaniesError: '',
  companies: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST: {
      return update(state, {
        fetchCompaniesStatus: {$set: 'requested'},
      })
    }

    case FETCH_COMPANIES_FAILURE: {
      return update(state, {
        fetchCompaniesStatus: {
          $set: 'error',
        },
        fetchCompaniesError: {$set: action.payload.error},
      })
    }

    case FETCH_COMPANIES_SUCCESS: {
      return update(state, {
        fetchCompaniesStatus: {$set: 'success'},
        companies: {
          $set: action.payload.companies,
        },
      })
    }

    case ADD_COMPANY_REQUEST: {
      return state
    }

    case ADD_COMPANY_FAILURE: {
      return state
    }

    case ADD_COMPANY_SUCCESS: {
      return update(state, {
        companies: {
          $push: [action.payload.company],
        },
      })
    }

    case UPDATE_COMPANY_REQUEST: {
      return state
    }

    case UPDATE_COMPANY_FAILURE: {
      return state
    }

    case UPDATE_COMPANY_SUCCESS: {
      let index = state.companies.findIndex(
        x => x.id === action.payload.company.id,
      )

      return update(state, {
        companies: {
          [index]: {$set: action.payload.company},
        },
      })
    }

    case ARCHIVE_COMPANY_REQUEST: {
      return state
    }

    case ARCHIVE_COMPANY_FAILURE: {
      return state
    }

    case ARCHIVE_COMPANY_SUCCESS: {
      let index = state.companies.findIndex(x => x.id === action.payload.id)

      return update(state, {
        companies: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
