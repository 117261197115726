import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {SubmitButton} from 'buttons'
import TextInput from 'sharedComponents/Forms/TextInput.js'
import {updatePassword} from 'redux/actions/profile/updatePassword'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {useSnackbar} from 'notistack'
import {useCurrentEmailIdentity} from 'sharedComponents/useCurrentEmailIdentity'

const Wrapper = styled.div``

const FormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number',
    ),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
})

function PasswordForm(props) {
  const {enqueueSnackbar} = useSnackbar()

  let currentEmailIdentity = useCurrentEmailIdentity()

  function handleUpdate(response) {
    if (response.status === 'success') {
      props.showModal(false)
      enqueueSnackbar('Your password has been updated successfully!')
    } else if (response.status === 'error') {
      props.showModal(false)
      enqueueSnackbar(response.error, {variant: 'error'})
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
        }}
        validationSchema={FormSchema}
        onSubmit={(values, {setSubmitting}) => {
          props.updatePassword(currentEmailIdentity.id, values, handleUpdate)
        }}
      >
        {({isSubmitting}) => (
          <Form>
            <TextInput
              label="Current Password"
              name="currentPassword"
              type="password"
            />

            <TextInput
              label="New Password"
              name="newPassword"
              type="password"
            />

            <TextInput
              label="Confirmation"
              name="newPasswordConfirmation"
              type="password"
            />

            <SubmitButton content="Update Password" isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  updatePassword: updatePassword,
}

export default connect(null, mapDispatchToProps)(PasswordForm)
