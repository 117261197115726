import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TodayIcon from '@material-ui/icons/Today';
import Badge from '@material-ui/core/Badge'
import {Link} from 'react-router-dom'

const IconWrapper = styled.div`
  height: 100%;
  justify-content: flex-end;

  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  padding-right: 25px;
  cursor: pointer;

  .MuiBadge-badge {
    border-radius: 0.25rem;
    background-color: ${props => props.theme.colors.red};
    padding: 0;
    border: 2px solid ${props => props.theme.colors.white};
    font-weight: 600;
    min-width: 17px;
    z-index: unset;
  }

	a {
		text-decoration: none;
		color: white;
	}

  a:hover {
    text-decoration: none;
		color: white;
  }
`

function CalendarIcon(props) {
    return (
			<IconWrapper aria-haspopup="true">
				<Link to='/calendar'>
					<Badge
						badgeContent={props.unreadActivityFeedItems}
						max={99}
						overlap="circle"
					>
						<TodayIcon />
					</Badge>
				</Link>
			</IconWrapper>
    )
}

export default CalendarIcon
