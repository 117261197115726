import React from 'react'
import styled from 'styled-components'
import NavbarLink from 'sharedComponents/NavbarLink.js'
import Support from 'sharedComponents/UserTopNavbar/Support.js'
import Academy from 'sharedComponents/UserTopNavbar/Academy.js'
// import Community from 'sharedComponents/UserTopNavbar/Community.js'
// import Search from 'sharedComponents/UserTopNavbar/Search.js'
import ProfileMenu from 'sharedComponents/UserTopNavbar/ProfileMenu.js'
import {NotificationBell} from 'badges'
import CalendarIcon from 'sharedComponents/CalendarIcon';
import { useCurrentAccount } from 'sharedComponents/useCurrentAccount'
import { useCurrentAccountUser } from 'sharedComponents/useCurrentAccountUser'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

function Links(props) {

  const currentAccount = useCurrentAccount()
  const currentAccountUser = useCurrentAccountUser()

  return (
    <Wrapper>
      <NavbarLink
        to="/prospective-properties"
        text="Prospective Properties"
        textColour={props => props.theme.colors.white}
        activeBarColour={props => props.theme.colors.white}
      />
      <NavbarLink
        to="/owned-properties"
        text="Portfolio"
        textColour={props => props.theme.colors.white}
        activeBarColour={props => props.theme.colors.white}
      />
      <NavbarLink
        to={`/accounts/${currentAccount.id}/account-users/${currentAccountUser.id}`}
        text="My Stuff"
        textColour={props => props.theme.colors.white}
        activeBarColour={props => props.theme.colors.white}
      />
      <NavbarLink
        to={`/accounts/${currentAccount.id}`}
        text="Account"
        textColour={props => props.theme.colors.white}
        activeBarColour={props => props.theme.colors.white}
      />
      {/* <Search /> */}
      {/* <Support /> */}
      {/* <Community /> */}
      {/* <Academy /> */}
      <CalendarIcon />
      <NotificationBell />
      <ProfileMenu />
    </Wrapper>
  )
}

export default Links
