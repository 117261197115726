import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedPropertyAccount: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedPropertyAccount: {
          $set: action.payload.ownedPropertyAccount,
        }
      })
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return update(state, {
        updateOwnedPropertyAccountStatus: { $set: 'requested' },
      })
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return update(state, {
        updateOwnedPropertyAccountStatus: {
          $set: 'error',
        },
        updateOwnedPropertyAccountError: { $set: action.payload.error },
      })
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      return update(state, {
        updateOwnedPropertyAccountStatus: { $set: 'success' },
        ownedPropertyAccount: {
          $set: action.payload.ownedPropertyAccount,
        }
      })
    }

    default:
      return state
  }
}