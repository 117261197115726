function useInvestorPackFooterHeight(preview, presentMode) {
  if (preview) {
    return '5px'
  } else if (presentMode) {
    return '5vh'
  } else {
    return '40px'
  }
}

export default useInvestorPackFooterHeight