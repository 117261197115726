import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_REQUEST } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_SUCCESS } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_FAILURE } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { ARCHIVE_SALE_COMPARABLE_REQUEST } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { ARCHIVE_SALE_COMPARABLE_SUCCESS } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { ARCHIVE_SALE_COMPARABLE_FAILURE } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { DELETE_SALE_COMPARABLE_REQUEST } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { DELETE_SALE_COMPARABLE_SUCCESS } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { DELETE_SALE_COMPARABLE_FAILURE } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { UPDATE_SALE_COMPARABLE_REQUEST } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { UPDATE_SALE_COMPARABLE_SUCCESS } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'
import { UPDATE_SALE_COMPARABLE_FAILURE } from 'v2/spas/prospective-property/sale-comparables/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  saleComparables: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        saleComparables: {
          $set: action.payload.prospectiveProperty.property.saleComparables,
        },
      })
    }

    case UPDATE_SALE_COMPARABLE_REQUEST: {
      return state
    }

    case UPDATE_SALE_COMPARABLE_FAILURE: {
      return state
    }

    case UPDATE_SALE_COMPARABLE_SUCCESS: {
      let index = state.saleComparables.findIndex(
        x => x.id === action.payload.saleComparable.id,
      )

      return update(state, {
        saleComparables: {
          [index]: { $set: action.payload.saleComparable },
        },
      })
    }

    case ARCHIVE_SALE_COMPARABLE_REQUEST: {
      return state
    }

    case ARCHIVE_SALE_COMPARABLE_FAILURE: {
      return state
    }

    case ARCHIVE_SALE_COMPARABLE_SUCCESS: {
      let index = state.saleComparables.findIndex(x => x.id === action.payload.saleComparable.id)

      return update(state, {
        saleComparables: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_SALE_COMPARABLE_REQUEST: {
      return state
    }

    case DELETE_SALE_COMPARABLE_FAILURE: {
      return state
    }

    case DELETE_SALE_COMPARABLE_SUCCESS: {
      let index = state.saleComparables.findIndex(x => x.id === action.payload.id)

      return update(state, {
        saleComparables: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
