import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_DEALS_REQUEST } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_DEALS_SUCCESS } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_DEALS_FAILURE } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { ARCHIVE_DEAL_REQUEST } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { ARCHIVE_DEAL_SUCCESS } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { ARCHIVE_DEAL_FAILURE } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { DELETE_DEAL_REQUEST } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { DELETE_DEAL_SUCCESS } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { DELETE_DEAL_FAILURE } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { CREATE_REMORTGAGE_DEAL_REQUEST } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { CREATE_REMORTGAGE_DEAL_SUCCESS } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { CREATE_REMORTGAGE_DEAL_FAILURE } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { CREATE_PRODUCT_TRANSFER_DEAL_REQUEST } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { CREATE_PRODUCT_TRANSFER_DEAL_SUCCESS } from 'v2/spas/owned-property/deals/redux/action-types.tsx'
import { CREATE_PRODUCT_TRANSFER_DEAL_FAILURE } from 'v2/spas/owned-property/deals/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  mortgageAccounts: [],
  ownedProperty: {},
  deals: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_DEALS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_DEALS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_DEALS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },

        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        deals: {
          $set: action.payload.ownedProperty.deals
        },
        mortgageAccounts: {
          $set: action.payload.ownedProperty.mortgageAccounts
        }
      })
    }

    case ARCHIVE_DEAL_REQUEST: {
      return state
    }

    case ARCHIVE_DEAL_FAILURE: {
      return state
    }

    case ARCHIVE_DEAL_SUCCESS: {
      let index = state.deals.findIndex(x => x.id === action.payload.deal.id)

      return update(state, {
        deals: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_DEAL_REQUEST: {
      return state
    }

    case DELETE_DEAL_FAILURE: {
      return state
    }

    case DELETE_DEAL_SUCCESS: {
      let index = state.deals.findIndex(x => x.id === action.payload.id)

      return update(state, {
        deals: {
          $splice: [[index, 1]],
        },
      })
    }

    case CREATE_REMORTGAGE_DEAL_REQUEST: {
      return state
    }
    case CREATE_REMORTGAGE_DEAL_FAILURE: {
      return state
    }
    case CREATE_REMORTGAGE_DEAL_SUCCESS: {
      return state
    }

    case CREATE_PRODUCT_TRANSFER_DEAL_REQUEST: {
      return state
    }
    case CREATE_PRODUCT_TRANSFER_DEAL_FAILURE: {
      return state
    }
    case CREATE_PRODUCT_TRANSFER_DEAL_SUCCESS: {
      return state
    }

    default:
      return state
  }
}
