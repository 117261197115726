import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_CERTIFICATES_REQUEST } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_CERTIFICATES_SUCCESS } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_CERTIFICATES_FAILURE } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { ARCHIVE_CERTIFICATE_REQUEST } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { ARCHIVE_CERTIFICATE_SUCCESS } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { ARCHIVE_CERTIFICATE_FAILURE } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { UPDATE_CERTIFICATE_REQUEST } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { UPDATE_CERTIFICATE_SUCCESS } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { UPDATE_CERTIFICATE_FAILURE } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { DELETE_CERTIFICATE_REQUEST } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { DELETE_CERTIFICATE_SUCCESS } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'
import { DELETE_CERTIFICATE_FAILURE } from 'v2/spas/owned-property/certificates/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  certificates: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_CERTIFICATES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_CERTIFICATES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_CERTIFICATES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        certificates: {
          $set: action.payload.ownedProperty.property.certificates
        },
      })
    }

    case UPDATE_CERTIFICATE_REQUEST: {
      return state
    }

    case UPDATE_CERTIFICATE_FAILURE: {
      return state
    }

    case UPDATE_CERTIFICATE_SUCCESS: {
      let index = state.certificates.findIndex(
        x => x.id === action.payload.certificate.id,
      )

      return update(state, {
        certificates: {
          [index]: { $set: action.payload.certificate },
        },
      })
    }

    case ARCHIVE_CERTIFICATE_REQUEST: {
      return state
    }

    case ARCHIVE_CERTIFICATE_FAILURE: {
      return state
    }

    case ARCHIVE_CERTIFICATE_SUCCESS: {
      let index = state.certificates.findIndex(x => x.id === action.payload.certificate.id)

      return update(state, {
        certificates: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_CERTIFICATE_REQUEST: {
      return state
    }
    
    case DELETE_CERTIFICATE_FAILURE: {
      return state
    }
    
    case DELETE_CERTIFICATE_SUCCESS: {
      let index = state.certificates.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        certificates: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
