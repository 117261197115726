import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_TENANCIES_REQUEST } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_TENANCIES_FAILURE } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { ARCHIVE_TENANCY_REQUEST } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { ARCHIVE_TENANCY_SUCCESS } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { ARCHIVE_TENANCY_FAILURE } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { UPDATE_TENANCY_REQUEST } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { UPDATE_TENANCY_SUCCESS } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { UPDATE_TENANCY_FAILURE } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { DELETE_TENANCY_REQUEST } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { DELETE_TENANCY_SUCCESS } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'
import { DELETE_TENANCY_FAILURE } from 'v2/spas/owned-property/tenancies/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  tenancies: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_TENANCIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_TENANCIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        tenancies: {
          $set: action.payload.ownedProperty.tenancies
        },
      })
    }

    case UPDATE_TENANCY_REQUEST: {
      return state
    }

    case UPDATE_TENANCY_FAILURE: {
      return state
    }

    case UPDATE_TENANCY_SUCCESS: {
      let index = state.tenancies.findIndex(
        x => x.id === action.payload.tenancy.id,
      )

      return update(state, {
        tenancies: {
          [index]: { $set: action.payload.tenancy },
        },
      })
    }

    case ARCHIVE_TENANCY_REQUEST: {
      return state
    }

    case ARCHIVE_TENANCY_FAILURE: {
      return state
    }

    case ARCHIVE_TENANCY_SUCCESS: {
      let index = state.tenancies.findIndex(x => x.id === action.payload.tenancy.id)

      return update(state, {
        tenancies: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_TENANCY_REQUEST: {
      return state
    }
    
    case DELETE_TENANCY_FAILURE: {
      return state
    }
    
    case DELETE_TENANCY_SUCCESS: {
      let index = state.tenancies.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        tenancies: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
