import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_PHOTOS_REQUEST } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_PHOTOS_SUCCESS } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_PHOTOS_FAILURE } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { ARCHIVE_PHOTO_REQUEST } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { ARCHIVE_PHOTO_SUCCESS } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { ARCHIVE_PHOTO_FAILURE } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { DELETE_PHOTO_REQUEST } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { DELETE_PHOTO_SUCCESS } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { DELETE_PHOTO_FAILURE } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { UPDATE_PHOTO_REQUEST } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { UPDATE_PHOTO_SUCCESS } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'
import { UPDATE_PHOTO_FAILURE } from 'v2/spas/prospective-property/photos/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  photos: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_PHOTOS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_PHOTOS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_PHOTOS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        photos: {
          $set: action.payload.prospectiveProperty.property.photos,
        },
      })
    }

    case UPDATE_PHOTO_REQUEST: {
      return state
    }

    case UPDATE_PHOTO_FAILURE: {
      return state
    }

    case UPDATE_PHOTO_SUCCESS: {
      let index = state.photos.findIndex(
        x => x.id === action.payload.photo.id,
      )

      return update(state, {
        photos: {
          [index]: { $set: action.payload.photo },
        },
      })
    }

    case ARCHIVE_PHOTO_REQUEST: {
      return state
    }

    case ARCHIVE_PHOTO_FAILURE: {
      return state
    }

    case ARCHIVE_PHOTO_SUCCESS: {
      let index = state.photos.findIndex(x => x.id === action.payload.photo.id)

      return update(state, {
        photos: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_PHOTO_REQUEST: {
      return state
    }

    case DELETE_PHOTO_FAILURE: {
      return state
    }

    case DELETE_PHOTO_SUCCESS: {
      let index = state.photos.findIndex(x => x.id === action.payload.id)

      return update(state, {
        photos: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
