import update from 'immutability-helper'
import { FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_REQUEST } from 'v2/spas/bridging-loan-application/investment-opportunities/redux/action-types.tsx'
import { FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_SUCCESS } from 'v2/spas/bridging-loan-application/investment-opportunities/redux/action-types.tsx'
import { FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_FAILURE } from 'v2/spas/bridging-loan-application/investment-opportunities/redux/action-types.tsx'
import { DELETE_INVESTMENT_OPPORTUNITY_REQUEST } from 'v2/spas/bridging-loan-application/investment-opportunities/redux/action-types.tsx'
import { DELETE_INVESTMENT_OPPORTUNITY_SUCCESS } from 'v2/spas/bridging-loan-application/investment-opportunities/redux/action-types.tsx'
import { DELETE_INVESTMENT_OPPORTUNITY_FAILURE } from 'v2/spas/bridging-loan-application/investment-opportunities/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  bridgingLoanApplication: {},
  investmentOpportunities: [],
  bridgingLoanApplicationInvestmentOpportunities: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_BRIDGING_LOAN_APPLICATION_INVESTMENT_OPPORTUNITIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        bridgingLoanApplication: {
          $set: action.payload.bridgingLoanApplication,
        },
        bridgingLoanApplicationInvestmentOpportunities: {
          $set: [action.payload.bridgingLoanApplication.bridgingLoanApplicationInvestmentOpportunity]
        },
      })
    }

    case DELETE_INVESTMENT_OPPORTUNITY_REQUEST: {
      return state
    }
    
    case DELETE_INVESTMENT_OPPORTUNITY_FAILURE: {
      return state
    }
    
    case DELETE_INVESTMENT_OPPORTUNITY_SUCCESS: {
      let index = state.investmentOpportunities.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        investmentOpportunities: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
