import update from 'immutability-helper'
import { FETCH_SECTION_VIDEOS_REQUEST } from 'v2/spas/section/videos/redux/action-types.tsx'
import { FETCH_SECTION_VIDEOS_SUCCESS } from 'v2/spas/section/videos/redux/action-types.tsx'
import { FETCH_SECTION_VIDEOS_FAILURE } from 'v2/spas/section/videos/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  section: {},
  videos: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SECTION_VIDEOS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_SECTION_VIDEOS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_SECTION_VIDEOS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        section: {
          $set: action.payload.section,
        },
        videos: {
          $set: action.payload.section.videos
        },
      })
    }

    default:
      return state
  }
}
