import update from 'immutability-helper'
import {FETCH_ACCOUNT_BRIDGING_LENDERS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_BRIDGING_LENDERS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_BRIDGING_LENDERS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_BRIDGING_LENDERS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_BRIDGING_LENDERS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_BRIDGING_LENDERS_FAILURE} from 'redux/actionTypes.js'
import {ADD_BRIDGING_LOAN_PRODUCT_REQUEST} from 'redux/actionTypes.js'
import {ADD_BRIDGING_LOAN_PRODUCT_FAILURE} from 'redux/actionTypes.js'
import {ADD_BRIDGING_LOAN_PRODUCT_SUCCESS} from 'redux/actionTypes.js'
import {ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST} from 'redux/actionTypes.js'
import {ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS} from 'redux/actionTypes.js'
import {ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchAccountBridgingLendersStatus: 'requested',
  fetchAccountBridgingLendersError: '',
  accountBridgingLenders: [],
  fetchBridgingLendersStatus: 'requested',
  fetchBridgingLendersError: '',
  bridgingLenders: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_BRIDGING_LENDERS_REQUEST: {
      return update(state, {
        fetchAccountBridgingLendersStatus: {$set: 'requested'},
      })
    }

    case FETCH_ACCOUNT_BRIDGING_LENDERS_FAILURE: {
      return update(state, {
        fetchAccountBridgingLendersStatus: {
          $set: 'error',
        },
        fetchAccountBridgingLendersError: {$set: action.payload.error},
      })
    }

    case FETCH_ACCOUNT_BRIDGING_LENDERS_SUCCESS: {
      return update(state, {
        fetchAccountBridgingLendersStatus: {$set: 'success'},
        accountBridgingLenders: {
          $set: action.payload.bridgingLenders,
        },
      })
    }

    case FETCH_BRIDGING_LENDERS_REQUEST: {
      return update(state, {
        fetchBridgingLendersStatus: {$set: 'requested'},
      })
    }

    case FETCH_BRIDGING_LENDERS_FAILURE: {
      return update(state, {
        fetchBridgingLendersStatus: {
          $set: 'error',
        },
        fetchBridgingLendersError: {$set: action.payload.error},
      })
    }

    case FETCH_BRIDGING_LENDERS_SUCCESS: {
      return update(state, {
        fetchBridgingLendersStatus: {$set: 'success'},
        bridgingLenders: {
          $set: action.payload.bridgingLenders,
        },
      })
    }

    case ADD_BRIDGING_LOAN_PRODUCT_REQUEST: {
      return state
    }

    case ADD_BRIDGING_LOAN_PRODUCT_FAILURE: {
      return state
    }

    case ADD_BRIDGING_LOAN_PRODUCT_SUCCESS: {
      return update(state, {
        accountBridgingLenders: {$set: action.payload.bridgingLenders},
      })
    }

    case UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST: {
      return state
    }

    case UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE: {
      return state
    }

    case UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS: {
      let index = state.accountBridgingLenders.findIndex(
        x => x.id === action.payload.bridgingLoanProduct.bridgingLender.id,
      )

      return update(state, {
        accountBridgingLenders: {
          [index]: {$set: action.payload.bridgingLoanProduct.bridgingLender},
        },
      })
    }

    case ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST: {
      return state
    }

    case ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE: {
      return state
    }

    case ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS: {
      let index = state.accountBridgingLenders.findIndex(
        x => x.id === action.payload.bridgingLender.id,
      )

      return update(state, {
        accountBridgingLenders: {
          [index]: {$set: action.payload.bridgingLender},
        },
      })
    }

    default:
      return state
  }
}
