import React, {useState} from 'react'
import ProspectivePropertyResourceCard from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCard.js'
import ProspectivePropertyResourceCardHeader from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardHeader.js'
import {FormModal} from 'modals'
import {EditResourceButton} from 'buttons'
import ProspectivePropertyResourceCardRow from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardRow.js'
import Col from 'react-bootstrap/Col'
import ProspectivePropertyResourceCardRowAttributes from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardRowAttributes.js'
import UserForm from 'spas/Profile/UserForm.js'
import AccountForm from 'spas/Profile/AccountForm.js'
import {useDevice} from 'sharedComponents/useDevice'
import {useCurrentAccount} from 'sharedComponents/useCurrentAccount'
import {useCurrentAccountUser} from 'sharedComponents/useCurrentAccountUser'

function ProfileDetailsCard() {
  const device = useDevice()
  const [showUserForm, setShowUserForm] = useState(false)
  const [showAccountForm, setShowAccountForm] = useState(false)

  const currentAccount = useCurrentAccount()
  const currentAccountUser = useCurrentAccountUser()

  const paddingRight = device === 'mobile' ? '5px' : '25px'
  const buttonPaddingRight =
    device === 'mobile' || device === 'tablet' ? '5px' : '15px'
  const fontSize = device === 'mobile' || device === 'tablet' ? '10px' : '14px'

  return (
    <ProspectivePropertyResourceCard>
      <ProspectivePropertyResourceCardHeader
        heading="Details"
        actionsPaddingRight={paddingRight}
      >
        <EditResourceButton
          text="User Details"
          onClick={() => setShowUserForm(true)}
          paddingRight={buttonPaddingRight}
          fontSize={fontSize}
        />
        <EditResourceButton
          text="Team Details"
          onClick={() => setShowAccountForm(true)}
          paddingRight={buttonPaddingRight}
          fontSize={fontSize}
        />
      </ProspectivePropertyResourceCardHeader>

      <ProspectivePropertyResourceCardRow>
        <Col md="6" sm="6" xs="12">
          <ProspectivePropertyResourceCardRowAttributes
            heading="Your Name"
            value={
              currentAccountUser.firstName + ' ' + currentAccountUser.lastName
            }
          />
        </Col>

        <Col md="6" sm="6" xs="12">
          <ProspectivePropertyResourceCardRowAttributes
            heading="Team Name"
            value={currentAccount.name}
          />
        </Col>
      </ProspectivePropertyResourceCardRow>

      <FormModal
        show={showUserForm}
        setShow={setShowUserForm}
        heading="User Details"
      >
        <UserForm showModal={setShowUserForm} />
      </FormModal>

      <FormModal
        show={showAccountForm}
        setShow={setShowAccountForm}
        heading="Team Details"
      >
        <AccountForm showModal={setShowAccountForm} />
      </FormModal>
    </ProspectivePropertyResourceCard>
  )
}

export default ProfileDetailsCard
