import update from 'immutability-helper'
import { FETCH_KNOWLEDGE_BASE_REQUEST } from 'v2/spas/knowledge-base/knowledge-base/redux/action-types.tsx'
import { FETCH_KNOWLEDGE_BASE_SUCCESS } from 'v2/spas/knowledge-base/knowledge-base/redux/action-types.tsx'
import { FETCH_KNOWLEDGE_BASE_FAILURE } from 'v2/spas/knowledge-base/knowledge-base/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  knowledgeBase: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_KNOWLEDGE_BASE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_KNOWLEDGE_BASE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_KNOWLEDGE_BASE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        knowledgeBase: {
          $set: action.payload.knowledgeBase,
        }
      })
    }

    default:
      return state
  }
}