import React from 'react'
import { CardGrid } from 'grids'
import AssociationCard from 'spas/ProspectiveProperty/cards/AssociationCard.js'
import Col from 'react-bootstrap/Col'
import { Houses } from 'react-bootstrap-icons';
import { HousesFill } from 'react-bootstrap-icons';
import { CardChecklist } from 'react-bootstrap-icons';
import { QuestionCircle } from 'react-bootstrap-icons';
import { Mortarboard } from 'react-bootstrap-icons';
import { People } from 'react-bootstrap-icons';
import { Compass } from 'react-bootstrap-icons';
import { GridSectionHeading } from 'headings'
import { useCurrentAccount } from 'sharedComponents/useCurrentAccount'
import { useCurrentAccountUser } from 'sharedComponents/useCurrentAccountUser'

function Grid() {

  const account = useCurrentAccount()
  const accountUser = useCurrentAccountUser()
  const knowledgeBase = account.knowledgeBase || {}
  const academy = account.academy || {}
  const community = account.community || {}

  let accountCounters = account.counters || {}
  let accountUserCounters = accountUser.counters || {}

  return (
    <CardGrid>
      <Col lg='12'>
        <GridSectionHeading
          heading='What do you want to do?'
          description='Here are some helpful shortcuts, to point you in the right direction'
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/prospective-properties`}
          heading='Buy more properties'
          icon={<Houses size={50} />}
          description="Manage your pipeline of properties"
          count={accountCounters.prospectiveProperties.active}
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/owned-properties`}
          heading='Manage your portfolio'
          icon={<HousesFill size={50} />}
          description="Manage your portfolio of existing properties"
          count={accountCounters.ownedProperties.active}
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`account-users/${accountUser.id}/workflow-assignments`}
          heading='Review my assignments'
          icon={<CardChecklist size={50} />}
          description="Review your current assignments"
          count={accountUserCounters.workflowAssignments.active}
        />
      </Col>

      <Col lg='12'>
        <GridSectionHeading
          heading='Need help using Stacked?'
          description="Check out our guides or send an email to hello@stacked.group"
        />
      </Col>

      {/* <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`academies/${academy.id}`}
          heading='Academy'
          icon={<Mortarboard size={50} />}
          description="Want to learn more about property investment? Check out our courses"
        />
      </Col> */}

      {/* <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/coaching`}
          heading='Coaching'
          icon={<Compass size={50} />}
          description="Could you benefit from the support of an experienced property investor, who has knowledge and experience that will help you get to the next level?"
        />
      </Col> */}

      {/* <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`communities/${community.id}`}
          heading='Community'
          icon={<People size={50} />}
          description="Want to connect with other members and attend exclusive (members only) events?"
        />
      </Col> */}

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          externalLink='https://support.stacked.group'
          heading='Guides'
          icon={<QuestionCircle size={50} />}
          description="Browse our collection of helpful guides, which explain how the platform works"
        />
      </Col>
    </CardGrid>
  )
}

export default Grid