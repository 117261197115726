import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Theme from 'Theme'
import GlobalStyles from 'GlobalStyles'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { HelmetProvider } from "react-helmet-async";
import Spinner from 'sharedComponents/Spinner'
import { SupportWidget } from 'functional-components';

import { loadStacked } from 'redux/actions/profile/loadStacked'
import { logout } from 'redux/actions/authentication/logout'
import { session } from 'redux/actions/authentication/session'

import { useInterval } from 'sharedComponents/useInterval.js'
import { rootPath } from 'sharedComponents/rootPath.js'
import FetchResource from 'sharedComponents/FetchResource.js'
import {ModalButton} from 'buttons'
import PageNotFound from 'sharedComponents/PageNotFound.js';
import PrivateRoute from 'sharedComponents/Authentication/PrivateRoute.js'
import ScrollToTop from 'sharedComponents/ScrollToTop.js'
import Snackbar from 'sharedComponents/Snackbar';
import {WarningModal} from 'modals'

const ZendeskWidget = lazy(() => import('sharedComponents/ZendeskWidget'));

const AboutUs = lazy(() => import('spas/AboutUs/AboutUs.js'));
const Webinar = lazy(() => import('spas/Webinar/Webinar.js'));
const PlatformUpdates = lazy(() => import('spas/PlatformUpdates/PlatformUpdates.js'));
const Article = lazy(() => import('spas/Article/Article.js'));
const HowItWorks = lazy(() => import('spas/HowItWorks/HowItWorks.js'));
const AccountPartners = lazy(() => import('spas/AccountPartners/AccountPartners.js'));
const BetaTerms = lazy(() => import('spas/BetaTerms/BetaTerms.js'));
const BridgingLoanProducts = lazy(() => import('spas/BridgingLoanProducts/BridgingLoanProducts.js'));
const Calendar = lazy(() => import('spas/Calendar/Calendar.js'));
const CertificatePortfolioReport = lazy(() => import('spas/PortfolioReports/CertificatePortfolioReport/CertificatePortfolioReport.js'));
const ChangeEmail = lazy(() => import('spas/Profile/ChangeEmail.js'));
const CommercialToResidentialDeal = lazy(() => import('spas/CommercialToResidentialDeal/CommercialToResidentialDeal.js'));
const ConfirmEmail = lazy(() => import('spas/ConfirmEmail/ConfirmEmail.js'));
const CookiePolicy = lazy(() => import('spas/CookiePolicy/CookiePolicy.js'));
const FlipDeal = lazy(() => import('spas/FlipDeal/FlipDeal.js'));
const Goals = lazy(() => import('spas/Goals/Goals.js'));
const HmoDeal = lazy(() => import('spas/HmoDeal/HmoDeal.js'));
const HmoDealSupport = lazy(() => import('v2/spas/hmo-deal/hmo-deal/pages/support.tsx'));
const InvestorPack = lazy(() => import('spas/InvestorPack/InvestorPack.js'));
const InvestorPackView = lazy(() => import('spas/InvestorPack/InvestorPackView.js'));
const InvestorPackVerifyAccess = lazy(() => import('spas/InvestorPack/VerifyAccess/VerifyAccess.js'));
const Login = lazy(() => import('spas/Login/Login.js'));
const SignInWithMagicLink = lazy(() => import('spas/SignInWithMagicLink/SignInWithMagicLink.tsx'));
const MortgageAccountPortfolioReport = lazy(() => import('spas/PortfolioReports/MortgageAccountPortfolioReport/MortgageAccountPortfolioReport'));
const MortgageProducts = lazy(() => import('spas/MortgageProducts/MortgageProducts.js'));
const OwnedPropertyAccountPortfolioReport = lazy(() => import('spas/PortfolioReports/OwnedPropertyAccountPortfolioReport/OwnedPropertyAccountPortfolioReport.js'));
const MortgageBrokerUserProfile = lazy(() => import('spas/MortgageBrokerUserProfile/MortgageBrokerUserProfile.js'));
const MortgageBrokerAccountProfile = lazy(() => import('spas/MortgageBrokerAccountProfile/MortgageBrokerAccountProfile.js'));
const MortgageBrokerUserDetails = lazy(() => import('spas/MortgageBrokerUserDetails/MortgageBrokerUserDetails.js'));
const PersonalAssets = lazy(() => import('spas/PersonalAssets/PersonalAssets.js'));
const PortfolioReports = lazy(() => import('spas/PortfolioReports/PortfolioReports.js'));
const Pricing = lazy(() => import('spas/Pricing/Pricing.js'));
const PrivacyPolicy = lazy(() => import('spas/PrivacyPolicy/PrivacyPolicy.js'));
const Profile = lazy(() => import('spas/Profile/Profile.js'));
const PropertyInvestmentAccountProfile = lazy(() => import('spas/PropertyInvestmentAccountProfile/PropertyInvestmentAccountProfile.js'));
const PropertyInvestmentUserProfile = lazy(() => import('spas/PropertyInvestmentUserProfile/PropertyInvestmentUserProfile.js'));
const ResetPassword = lazy(() => import('spas/ResetPassword/ResetPassword.js'));
const ResetPasswordRequest = lazy(() => import('spas/ResetPasswordRequest/ResetPasswordRequest.js'));
const Resources = lazy(() => import('spas/Resources/Resources.js'));
const ResourceCategory = lazy(() => import('spas/ResourceCategory/ResourceCategory.js'));
const SetupAccount = lazy(() => import('spas/SetupAccount/SetupAccount.js'));
const SignUp = lazy(() => import('spas/SignUp/SignUp.js'));
const SingleLetDeal = lazy(() => import('spas/SingleLetDeal/SingleLetDeal.js'));
const ServicedAccommodationDeal = lazy(() => import('spas/ServicedAccommodationDeal/ServicedAccommodationDeal.js'));
const RentToRentDeal = lazy(() => import('spas/RentToRentDeal/RentToRentDeal.js'));
const RentToServicedAccommodationDeal = lazy(() => import('spas/RentToServicedAccommodationDeal/RentToServicedAccommodationDeal.js'));
const Subscription = lazy(() => import('spas/Subscriptions/Subscription.js'));
const TenancyPortfolioReport = lazy(() => import('spas/PortfolioReports/TenancyPortfolioReport/TenancyPortfolioReport'));
const TermsAndConditions = lazy(() => import('spas/TermsAndConditions/TermsAndConditions.js'));
const PortfolioOverviewReport = lazy(() => import('spas/PortfolioReports/PortfolioOverviewReport/PortfolioOverviewReport'));


const ProspectiveProperty = lazy(() => import('v2/spas/prospective-property/prospective-property/pages/view.tsx'));
const ProspectivePropertyDueDiligence = lazy(() => import('v2/spas/prospective-property/prospective-property/pages/due-diligence.tsx'));
const ProspectivePropertyRaiseMoney = lazy(() => import('v2/spas/prospective-property/prospective-property/pages/raise-money.tsx'));
const ProspectivePropertyApplications = lazy(() => import('v2/spas/prospective-property/prospective-property/pages/applications.tsx'));
const ProspectivePropertySupportingInformation = lazy(() => import('v2/spas/prospective-property/prospective-property/pages/supporting-information.tsx'));
const ProspectivePropertySupport = lazy(() => import('v2/spas/prospective-property/prospective-property/pages/support.tsx'));
const ProspectiveProperties = lazy(() => import('v2/spas/prospective-properties/pages/index.tsx'));
const ProspectivePropertiesArchive = lazy(() => import('v2/spas/prospective-properties/pages/archive.tsx'));
const ProspectivePropertyRentListings = lazy(() => import('v2/spas/prospective-property/rent-listings/pages/index.tsx'));
const ProspectivePropertyRentListing = lazy(() => import('v2/spas/prospective-property/rent-listings/pages/view.tsx'));
const ProspectivePropertyRentListingsArchive = lazy(() => import('v2/spas/prospective-property/rent-listings/pages/archive.tsx'));
const ProspectivePropertySaleListings = lazy(() => import('v2/spas/prospective-property/sale-listings/pages/index.tsx'));
const ProspectivePropertySaleListing = lazy(() => import('v2/spas/prospective-property/sale-listings/pages/view.tsx'));
const ProspectivePropertySaleListingsArchive = lazy(() => import('v2/spas/prospective-property/sale-listings/pages/archive.tsx'));
const ProspectivePropertyRentComparables = lazy(() => import('v2/spas/prospective-property/rent-comparables/pages/index.tsx'));
const ProspectivePropertyRentComparable = lazy(() => import('v2/spas/prospective-property/rent-comparables/pages/view.tsx'));
const ProspectivePropertyRentComparablesArchive = lazy(() => import('v2/spas/prospective-property/rent-comparables/pages/archive.tsx'));
const ProspectivePropertyVideos = lazy(() => import('v2/spas/prospective-property/videos/pages/index.tsx'));
const ProspectivePropertyVideo = lazy(() => import('v2/spas/prospective-property/videos/pages/view.tsx'));
const ProspectivePropertyVideosArchive = lazy(() => import('v2/spas/prospective-property/videos/pages/archive.tsx'));
const ProspectivePropertyPhotos = lazy(() => import('v2/spas/prospective-property/photos/pages/index.tsx'));
const ProspectivePropertyPhoto = lazy(() => import('v2/spas/prospective-property/photos/pages/view.tsx'));
const ProspectivePropertyPhotosArchive = lazy(() => import('v2/spas/prospective-property/photos/pages/archive.tsx'));
const ProspectivePropertyDocuments = lazy(() => import('v2/spas/prospective-property/documents/pages/index.tsx'));
const ProspectivePropertyDocument = lazy(() => import('v2/spas/prospective-property/documents/pages/view.tsx'));
const ProspectivePropertyDocumentsArchive = lazy(() => import('v2/spas/prospective-property/documents/pages/archive.tsx'));
const ProspectivePropertySaleComparables = lazy(() => import('v2/spas/prospective-property/sale-comparables/pages/index.tsx'));
const ProspectivePropertySaleComparable = lazy(() => import('v2/spas/prospective-property/sale-comparables/pages/view.tsx'));
const ProspectivePropertySaleComparablesArchive = lazy(() => import('v2/spas/prospective-property/sale-comparables/pages/archive.tsx'));
const ProspectivePropertyPropertyOwners = lazy(() => import('v2/spas/prospective-property/property-owners/pages/index.tsx'));
const ProspectivePropertyPropertyOwner = lazy(() => import('v2/spas/prospective-property/property-owners/pages/view.tsx'));
const ProspectivePropertyPropertyOwnersArchive = lazy(() => import('v2/spas/prospective-property/property-owners/pages/archive.tsx'));
const ProspectivePropertyEnergyPerformanceCertificates = lazy(() => import('v2/spas/prospective-property/energy-performance-certificates/pages/index.tsx'));
const ProspectivePropertyEnergyPerformanceCertificate = lazy(() => import('v2/spas/prospective-property/energy-performance-certificates/pages/view.tsx'));
const ProspectivePropertyEnergyPerformanceCertificatesArchive = lazy(() => import('v2/spas/prospective-property/energy-performance-certificates/pages/archive.tsx'));
const OwnedProperties = lazy(() => import('v2/spas/owned-properties/pages/index.tsx'));
const OwnedPropertiesArchive = lazy(() => import('v2/spas/owned-properties/pages/archive.tsx'));
const OwnedProperty = lazy(() => import('v2/spas/owned-property/owned-property/pages/view.tsx'));
const OwnedPropertyRaiseMoney = lazy(() => import('v2/spas/owned-property/owned-property/pages/raise-money.tsx'));
const OwnedPropertyApplications = lazy(() => import('v2/spas/owned-property/owned-property/pages/applications.tsx'));
const OwnedPropertySafety = lazy(() => import('v2/spas/owned-property/owned-property/pages/safety.tsx'));
const OwnedPropertyManagement = lazy(() => import('v2/spas/owned-property/owned-property/pages/management.tsx'));
const OwnedPropertyUnits = lazy(() => import('v2/spas/owned-property/units/pages/index.tsx'));
const OwnedPropertyUnit = lazy(() => import('v2/spas/owned-property/units/pages/view.tsx'));
const OwnedPropertyUnitsArchive = lazy(() => import('v2/spas/owned-property/units/pages/archive.tsx'));
const OwnedPropertyAppliances = lazy(() => import('v2/spas/owned-property/appliances/pages/index.tsx'));
const OwnedPropertyAppliance = lazy(() => import('v2/spas/owned-property/appliances/pages/view.tsx'));
const OwnedPropertyAppliancesArchive = lazy(() => import('v2/spas/owned-property/appliances/pages/archive.tsx'));
const OwnedPropertyPhotos = lazy(() => import('v2/spas/owned-property/photos/pages/index.tsx'));
const OwnedPropertyPhoto = lazy(() => import('v2/spas/owned-property/photos/pages/view.tsx'));
const OwnedPropertyPhotosArchive = lazy(() => import('v2/spas/owned-property/photos/pages/archive.tsx'));
const OwnedPropertyVideos = lazy(() => import('v2/spas/owned-property/videos/pages/index.tsx'));
const OwnedPropertyVideo = lazy(() => import('v2/spas/owned-property/videos/pages/view.tsx'));
const OwnedPropertyVideosArchive = lazy(() => import('v2/spas/owned-property/videos/pages/archive.tsx'));
const OwnedPropertyCertificates = lazy(() => import('v2/spas/owned-property/certificates/pages/index.tsx'));
const OwnedPropertyCertificate = lazy(() => import('v2/spas/owned-property/certificates/pages/view.tsx'));
const OwnedPropertyCertificatesArchive = lazy(() => import('v2/spas/owned-property/certificates/pages/archive.tsx'));
const OwnedPropertyExistingMortgages = lazy(() => import('v2/spas/owned-property/existing-mortgages/pages/index.tsx'));
const OwnedPropertyExistingMortgage = lazy(() => import('v2/spas/owned-property/existing-mortgages/pages/view.tsx'));
const OwnedPropertyExistingMortgagesArchive = lazy(() => import('v2/spas/owned-property/existing-mortgages/pages/archive.tsx'));
const OwnedPropertyPropertyOwners = lazy(() => import('v2/spas/owned-property/property-owners/pages/index.tsx'));
const OwnedPropertyPropertyOwner = lazy(() => import('v2/spas/owned-property/property-owners/pages/view.tsx'));
const OwnedPropertyPropertyOwnersArchive = lazy(() => import('v2/spas/owned-property/property-owners/pages/archive.tsx'));
const OwnedPropertyEnergyPerformanceCertificates = lazy(() => import('v2/spas/owned-property/energy-performance-certificates/pages/index.tsx'));
const OwnedPropertyEnergyPerformanceCertificate = lazy(() => import('v2/spas/owned-property/energy-performance-certificates/pages/view.tsx'));
const OwnedPropertyEnergyPerformanceCertificatesArchive = lazy(() => import('v2/spas/owned-property/energy-performance-certificates/pages/archive.tsx'));
const OwnedPropertyLicences = lazy(() => import('v2/spas/owned-property/licences/pages/index.tsx'));
const OwnedPropertyLicence = lazy(() => import('v2/spas/owned-property/licences/pages/view.tsx'));
const OwnedPropertyLicencesArchive = lazy(() => import('v2/spas/owned-property/licences/pages/archive.tsx'));
const OwnedPropertyAccounts = lazy(() => import('v2/spas/owned-property/owned-property-accounts/pages/index.tsx'));
const OwnedPropertyAccount = lazy(() => import('v2/spas/owned-property/owned-property-accounts/pages/view.tsx'));
const OwnedPropertyAccountsArchive = lazy(() => import('v2/spas/owned-property/owned-property-accounts/pages/archive.tsx'));
const OwnedPropertySmokeAlarmTests = lazy(() => import('v2/spas/owned-property/smoke-alarm-tests/pages/index.tsx'));
const OwnedPropertySmokeAlarmTest = lazy(() => import('v2/spas/owned-property/smoke-alarm-tests/pages/view.tsx'));
const OwnedPropertySmokeAlarmTestsArchive = lazy(() => import('v2/spas/owned-property/smoke-alarm-tests/pages/archive.tsx'));
const OwnedPropertyTenancies = lazy(() => import('v2/spas/owned-property/tenancies/pages/index.tsx'));
const OwnedPropertyTenancy = lazy(() => import('v2/spas/owned-property/tenancies/pages/view.tsx'));
const OwnedPropertyTenanciesArchive = lazy(() => import('v2/spas/owned-property/tenancies/pages/archive.tsx'));
const TenancyDocuments = lazy(() => import('v2/spas/tenancy/documents/pages/index.tsx'));
const TenancyDocument = lazy(() => import('v2/spas/tenancy/documents/pages/view.tsx'));
const TenancyDocumentsArchive = lazy(() => import('v2/spas/tenancy/documents/pages/archive.tsx'));
const TenancyTenants = lazy(() => import('v2/spas/tenancy/tenants/pages/index.tsx'));
const TenancyTenantsArchive = lazy(() => import('v2/spas/tenancy/tenants/pages/archive.tsx'));
const TenancyGuarantors = lazy(() => import('v2/spas/tenancy/guarantors/pages/index.tsx'));
const TenancyGuarantorsArchive = lazy(() => import('v2/spas/tenancy/guarantors/pages/archive.tsx'));
const TenancyRentStatement = lazy(() => import('v2/spas/tenancy/rent-statement/pages/index.tsx'));
const ExistingMortgageMortgageAccounts = lazy(() => import('v2/spas/existing-mortgage/mortgage-accounts/pages/index.tsx'));
const ExistingMortgageMortgageAccount = lazy(() => import('v2/spas/existing-mortgage/mortgage-accounts/pages/view.tsx'));
const ExistingMortgageMortgageAccountsArchive = lazy(() => import('v2/spas/existing-mortgage/mortgage-accounts/pages/archive.tsx'));
const ExistingMortgageDocuments = lazy(() => import('v2/spas/existing-mortgage/documents/pages/index.tsx'));
const ExistingMortgageDocument = lazy(() => import('v2/spas/existing-mortgage/documents/pages/view.tsx'));
const ExistingMortgageDocumentsArchive = lazy(() => import('v2/spas/existing-mortgage/documents/pages/archive.tsx'));
const Account = lazy(() => import('v2/spas/account/account/pages/view.tsx'));
const AccountBridgingLoanProducts = lazy(() => import('v2/spas/account/bridging-loan-products/pages/index.tsx'));
const AccountBridgingLoanProduct = lazy(() => import('v2/spas/account/bridging-loan-products/pages/view.tsx'));
const AccountBridgingLoanProductsArchive = lazy(() => import('v2/spas/account/bridging-loan-products/pages/archive.tsx'));
const AccountMortgageProducts = lazy(() => import('v2/spas/account/mortgage-products/pages/index.tsx'));
const AccountMortgageProduct = lazy(() => import('v2/spas/account/mortgage-products/pages/view.tsx'));
const AccountMortgageProductsArchive = lazy(() => import('v2/spas/account/mortgage-products/pages/archive.tsx'));
const AccountDealTemplates = lazy(() => import('v2/spas/account/deal-templates/pages/index.tsx'));
const AccountDealTemplate = lazy(() => import('v2/spas/account/deal-templates/pages/view.tsx'));
const AccountDealTemplatesArchive = lazy(() => import('v2/spas/account/deal-templates/pages/archive.tsx'));
const AccountCompanies = lazy(() => import('v2/spas/account/companies/pages/index.tsx'));
const AccountCompany = lazy(() => import('v2/spas/account/companies/pages/view.tsx'));
const AccountCompaniesArchive = lazy(() => import('v2/spas/account/companies/pages/archive.tsx'));
const AccountAccountUsers = lazy(() => import('v2/spas/account/account-users/pages/index.tsx'));
const AccountAccountUser = lazy(() => import('v2/spas/account/account-users/pages/view.tsx'));
const AccountAccountUsersArchive = lazy(() => import('v2/spas/account/account-users/pages/archive.tsx'));
const OwnedPropertyInsurancePolicies = lazy(() => import('v2/spas/owned-property/insurance-policies/pages/index.tsx'));
const OwnedPropertyInsurancePolicy = lazy(() => import('v2/spas/owned-property/insurance-policies/pages/view.tsx'));
const OwnedPropertyInsurancePoliciesArchive = lazy(() => import('v2/spas/owned-property/insurance-policies/pages/archive.tsx'));
const UnitTenancies = lazy(() => import('v2/spas/unit/tenancies/pages/index.tsx'));
const UnitTenancy = lazy(() => import('v2/spas/unit/tenancies/pages/view.tsx'));
const UnitTenanciesArchive = lazy(() => import('v2/spas/unit/tenancies/pages/archive.tsx'));
const ProspectivePropertyDeals = lazy(() => import('v2/spas/prospective-property/deals/pages/index.tsx'));
const ProspectivePropertyDealsArchive = lazy(() => import('v2/spas/prospective-property/deals/pages/archive.tsx'));
const AccountUserDocuments = lazy(() => import('v2/spas/account-user/documents/pages/index.tsx'));
const AccountUserDocument = lazy(() => import('v2/spas/account-user/documents/pages/view.tsx'));
const AccountUserDocumentsArchive = lazy(() => import('v2/spas/account-user/documents/pages/archive.tsx'));
const CompanyDocuments = lazy(() => import('v2/spas/company/documents/pages/index.tsx'));
const CompanyDocument = lazy(() => import('v2/spas/company/documents/pages/view.tsx'));
const CompanyDocumentsArchive = lazy(() => import('v2/spas/company/documents/pages/archive.tsx'));
const CompanyDirectors = lazy(() => import('v2/spas/company/directors/pages/index.tsx'));
const CompanyDirectorsArchive = lazy(() => import('v2/spas/company/directors/pages/archive.tsx'));
const CompanyShareholders = lazy(() => import('v2/spas/company/shareholders/pages/index.tsx'));
const CompanyShareholdersArchive = lazy(() => import('v2/spas/company/shareholders/pages/archive.tsx'));
const CompanyOwnedProperties = lazy(() => import('v2/spas/company/owned-properties/pages/index.tsx'));
const AccountUserOwnedProperties = lazy(() => import('v2/spas/account-user/owned-properties/pages/index.tsx'));
const AccountWorkflows = lazy(() => import('v2/spas/account/workflows/pages/index.tsx'));
const AccountWorkflowsArchive = lazy(() => import('v2/spas/account/workflows/pages/archive.tsx'));
const ProspectivePropertyWorkflows = lazy(() => import('v2/spas/prospective-property/workflows/pages/index.tsx'));
const ProspectivePropertyWorkflowsArchive = lazy(() => import('v2/spas/prospective-property/workflows/pages/archive.tsx'));
const OwnedPropertyWorkflows = lazy(() => import('v2/spas/owned-property/workflows/pages/index.tsx'));
const OwnedPropertyWorkflowsArchive = lazy(() => import('v2/spas/owned-property/workflows/pages/archive.tsx'));
const AccountUserWorkflowAssignments = lazy(() => import('v2/spas/account-user/workflow-assignments/pages/index.tsx'));
const ProspectivePropertyInvestmentOpportunities = lazy(() => import('v2/spas/prospective-property/investment-opportunities/pages/index.tsx'));
const ProspectivePropertyInvestmentOpportunity = lazy(() => import('v2/spas/prospective-property/investment-opportunities/pages/view.tsx'));
const ProspectivePropertyInvestmentOpportunityPreview = lazy(() => import('v2/spas/prospective-property/investment-opportunities/pages/preview.tsx'));
const ProspectivePropertyInvestmentOpportunitiesArchive = lazy(() => import('v2/spas/prospective-property/investment-opportunities/pages/archive.tsx'));
const OwnedPropertyInvestmentOpportunities = lazy(() => import('v2/spas/owned-property/investment-opportunities/pages/index.tsx'));
const OwnedPropertyInvestmentOpportunity = lazy(() => import('v2/spas/owned-property/investment-opportunities/pages/view.tsx'));
const OwnedPropertyInvestmentOpportunityPreview = lazy(() => import('v2/spas/owned-property/investment-opportunities/pages/preview.tsx'));
const OwnedPropertyInvestmentOpportunitiesArchive = lazy(() => import('v2/spas/owned-property/investment-opportunities/pages/archive.tsx'));
const ProspectivePropertyMortgageApplications = lazy(() => import('v2/spas/prospective-property/mortgage-applications/pages/index.tsx'));
const ProspectivePropertyMortgageApplication = lazy(() => import('v2/spas/prospective-property/mortgage-applications/pages/view.tsx'));
const ProspectivePropertyMortgageApplicationsArchive = lazy(() => import('v2/spas/prospective-property/mortgage-applications/pages/archive.tsx'));
const ProspectivePropertyBridgingLoanApplications = lazy(() => import('v2/spas/prospective-property/bridging-loan-applications/pages/index.tsx'));
const ProspectivePropertyBridgingLoanApplication = lazy(() => import('v2/spas/prospective-property/bridging-loan-applications/pages/view.tsx'));
const ProspectivePropertyBridgingLoanApplicationsArchive = lazy(() => import('v2/spas/prospective-property/bridging-loan-applications/pages/archive.tsx'));
const MortgageApplicationMortgageApplicants = lazy(() => import('v2/spas/mortgage-application/mortgage-applicants/pages/index.tsx'));
const BridgingLoanApplicationBridgingLoanApplicants = lazy(() => import('v2/spas/bridging-loan-application/bridging-loan-applicants/pages/index.tsx'));
const InsuranceApplicationInsuranceApplicants = lazy(() => import('v2/spas/insurance-application/insurance-applicants/pages/index.tsx'));
const KnowledgeBase = lazy(() => import('v2/spas/knowledge-base/knowledge-base/pages/view.tsx'));
const KnowledgeBaseCategories = lazy(() => import('v2/spas/knowledge-base/categories/pages/index.tsx'));
const KnowledgeBaseCategory = lazy(() => import('v2/spas/knowledge-base/categories/pages/view.tsx'));
const CategorySections = lazy(() => import('v2/spas/category/sections/pages/index.tsx'));
const CategorySection = lazy(() => import('v2/spas/category/sections/pages/view.tsx'));
const SectionVideos = lazy(() => import('v2/spas/section/videos/pages/index.tsx'));
const Academy = lazy(() => import('v2/spas/academy/academy/pages/view.tsx'));
const AcademyCourses = lazy(() => import('v2/spas/academy/courses/pages/index.tsx'));
const AcademyCourse = lazy(() => import('v2/spas/academy/courses/pages/view.tsx'));
const CourseLessons = lazy(() => import('v2/spas/course/lessons/pages/index.tsx'));
const Community = lazy(() => import('v2/spas/community/community/pages/view.tsx'));
const CommunityCommunityEvents = lazy(() => import('v2/spas/community/community-events/pages/index.tsx'));
const CommunityCommunityEvent = lazy(() => import('v2/spas/community/community-events/pages/view.tsx'));
const ProspectivePropertyPreviousSales = lazy(() => import('v2/spas/prospective-property/previous-sales/pages/index.tsx'));
const OwnedPropertyDeals = lazy(() => import('v2/spas/owned-property/deals/pages/index.tsx'));
const OwnedPropertyDealsArchive = lazy(() => import('v2/spas/owned-property/deals/pages/archive.tsx'));
const Coaching = lazy(() => import('v2/spas/coaching/pages/view.tsx'));
const OwnedPropertyRemortgageDeal = lazy(() => import('v2/spas/owned-property/remortgage-deals/pages/view.tsx'));
const OwnedPropertyFurtherAdvanceDeal = lazy(() => import('v2/spas/owned-property/further-advance-deals/pages/view.tsx'));
const OwnedPropertyProductTransferDeal = lazy(() => import('v2/spas/owned-property/product-transfer-deals/pages/view.tsx'));
const OwnedPropertyMonthlyExpenses = lazy(() => import('v2/spas/owned-property/monthly-expenses/pages/index.tsx'));
const OwnedPropertyInsuranceApplications = lazy(() => import('v2/spas/owned-property/insurance-applications/pages/index.tsx'));
const OwnedPropertyInsuranceApplication = lazy(() => import('v2/spas/owned-property/insurance-applications/pages/view.tsx'));
const ProspectivePropertyInsuranceApplications = lazy(() => import('v2/spas/prospective-property/insurance-applications/pages/index.tsx'));
const ProspectivePropertyInsuranceApplicationsArchive = lazy(() => import('v2/spas/prospective-property/insurance-applications/pages/archive.tsx'));
const ProspectivePropertyInsuranceApplication = lazy(() => import('v2/spas/prospective-property/insurance-applications/pages/view.tsx'));
const AccountUserActivityFeedItems = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/index.tsx'));
const AccountUserActivityFeedItemsArchive = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/archive.tsx'));
const OwnedPropertyDocuments = lazy(() => import('v2/spas/owned-property/documents/pages/index.tsx'));
const OwnedPropertyDocument = lazy(() => import('v2/spas/owned-property/documents/pages/view.tsx'));
const OwnedPropertyDocumentsArchive = lazy(() => import('v2/spas/owned-property/documents/pages/archive.tsx'));
const Workflow = lazy(() => import('v2/spas/workflow/pages/view.tsx'));
const OwnedPropertyKeys = lazy(() => import('v2/spas/owned-property/keys/pages/index.tsx'));
const OwnedPropertyKey = lazy(() => import('v2/spas/owned-property/keys/pages/view.tsx'));
const OwnedPropertyKeysArchive = lazy(() => import('v2/spas/owned-property/keys/pages/archive.tsx'));
const OwnedPropertyMortgageApplications = lazy(() => import('v2/spas/owned-property/mortgage-applications/pages/index.tsx'));
const OwnedPropertyMortgageApplication = lazy(() => import('v2/spas/owned-property/mortgage-applications/pages/view.tsx'));
const OwnedPropertyMortgageApplicationsArchive = lazy(() => import('v2/spas/owned-property/mortgage-applications/pages/archive.tsx'));
const InvestmentOpportunityInvestmentOpportunityInvitations = lazy(() => import('v2/spas/investment-opportunity/investment-opportunity-invitations/pages/index.tsx'));
const InvestmentOpportunityInvestmentOpportunityInvitation = lazy(() => import('v2/spas/investment-opportunity/investment-opportunity-invitations/pages/view.tsx'));
const InvestmentOpportunityInvestmentOpportunityInvitationsArchive = lazy(() => import('v2/spas/investment-opportunity/investment-opportunity-invitations/pages/archive.tsx'));
const ProspectivePropertyConveyancingApplications = lazy(() => import('v2/spas/prospective-property/conveyancing-applications/pages/index.tsx'));
const ProspectivePropertyConveyancingApplication = lazy(() => import('v2/spas/prospective-property/conveyancing-applications/pages/view.tsx'));
const ProspectivePropertyConveyancingApplicationsArchive = lazy(() => import('v2/spas/prospective-property/conveyancing-applications/pages/archive.tsx'));
const OwnedPropertyBridgingLoanApplications = lazy(() => import('v2/spas/owned-property/bridging-loan-applications/pages/index.tsx'));
const OwnedPropertyBridgingLoanApplication = lazy(() => import('v2/spas/owned-property/bridging-loan-applications/pages/view.tsx'));
const OwnedPropertyBridgingLoanApplicationsArchive = lazy(() => import('v2/spas/owned-property/bridging-loan-applications/pages/archive.tsx'));
const BridgingLoanApplicationInvestmentOpportunities = lazy(() => import('v2/spas/bridging-loan-application/investment-opportunities/pages/index.tsx'));
const SectionArticles = lazy(() => import('v2/spas/section/articles/pages/index.tsx'));
const SectionArticle = lazy(() => import('v2/spas/section/articles/pages/view.tsx'));

function initialiseSentry() {
  if (window.location.href.indexOf("stacked.group") > -1) {
    Sentry.init({
      dsn: "https://d422f667e28646289be0cd3d3c5ac859@o4504261359894528.ingest.sentry.io/4504261362909184",
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}

initialiseSentry()

const App = ({
  currentUser,
  currentAccount,
  currentEmailIdentity,
  policy,
  loadStackedStatus,
  loadStackedError,
  sessionStatus,
  sessionError,
  sessionExpiring,
  loadStacked,
  session,
  logout,
}) => {
  const logOut = () => {
    logout(handleLogOut);
  };

  const handleLogOut = response => {
    if (response.status === 'success') {
      window.location.href = '/login';
    } else if (response.status === 'error') {
      window.location.reload();
    }
  };

  useInterval(async () => {
    session();
  }, 600000); // Check every 10 minutes

  useEffect(() => {
    loadStacked();
  }, []);

  return (
    <HelmetProvider>
      <Theme>
        <Snackbar>
          <GlobalStyles />
          <Router>
            <Suspense fallback={<Spinner />}>
              <SupportWidget link='https://support.stacked.group/knowledge-bases/f2958b62-f896-4d7d-9c3c-63dc33927f4b' />
              <ScrollToTop />
              <FetchResource status={loadStackedStatus} error={loadStackedError}>
                <WarningModal
                  show={sessionExpiring}
                  heading="Your session is about to expire!">
                  <ModalButton
                    text="Please login again"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <WarningModal
                  show={currentUser.signUpStatus === 'confirm_email'}
                  heading="You have not confirmed your email address yet! Please check your emails and click the link to confirm your email address!">
                  <ModalButton
                    text="Continue"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <Switch>
                  <Route exact path="/">
                    {rootPath(currentUser, currentEmailIdentity, currentAccount)}
                  </Route>
                  <Route path="/webinar">
                    <Webinar />
                  </Route>
                  <Route path="/about-us">
                    <AboutUs />
                  </Route>
                  <Route path="/how-it-works">
                    <HowItWorks />
                  </Route>
                  <Route path="/beta-terms">
                    <BetaTerms />
                  </Route>
                  <Route path="/terms-and-conditions">
                    <TermsAndConditions />
                  </Route>
                  <Route path="/privacy-policy">
                    <PrivacyPolicy />
                  </Route>
                  <Route path="/cookie-policy">
                    <CookiePolicy />
                  </Route>
                  <Route exact path="/articles/:articleSlug">
                    <Article />
                  </Route>
                  <Route path="/platform-updates">
                    <PlatformUpdates />
                  </Route>
                  <Route path="/subscription">
                    <Subscription />
                  </Route>
                  <Route path="/resources">
                    <Resources />
                  </Route>
                  <Route path="/pricing">
                    <Pricing />
                  </Route>
                  <Route exact path="/resource-category/:resourceCategoryId">
                    <ResourceCategory />
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/sign-in-with-magic-link/:token">
                    <SignInWithMagicLink />
                  </Route>
                  <Route path="/mastermind-sign-up">
                    <SignUp />
                  </Route>
                  <Route path="/sign-up">
                    <SignUp />
                  </Route>
                  <Route path="/confirm_email/:token">
                    <ConfirmEmail />
                  </Route>
                  <PrivateRoute path="/set-user-details">
                    <SetupAccount />
                  </PrivateRoute>
                  <Route path="/reset-password-request">
                    <ResetPasswordRequest />
                  </Route>
                  <Route path="/reset_password/:token">
                    <ResetPassword />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/support"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySupport />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectiveProperty />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/due-diligence"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyDueDiligence />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/raise-money"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyRaiseMoney />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/supporting-information"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySupportingInformation />
                  </PrivateRoute>
                  <Route
                    exact
                    path="/mortgage-applications/:mortgageApplicationId/prospective-properties/:prospectivePropertyId"
                  >
                    <ProspectiveProperty />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/hmo-deals/:hmoDealId"
                    authorised={policy.canViewDeals}>
                    <HmoDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/hmo-deals/:hmoDealId/support"
                    authorised={policy.canViewDeals}>
                    <HmoDealSupport />
                  </PrivateRoute>
                  <Route
                    exact
                    path="/mortgage-applications/:mortgageApplicationId/hmo-deals/:hmoDealId"
                  >
                    <HmoDeal />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/single-let-deals/:singleLetDealId"
                    authorised={policy.canViewDeals}>
                    <SingleLetDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/serviced-accommodation-deals/:servicedAccommodationDealId"
                    authorised={policy.canViewDeals}>
                    <ServicedAccommodationDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/rent-to-rent-deals/:rentToRentDealId"
                    authorised={policy.canViewDeals}>
                    <RentToRentDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/rent-to-serviced-accommodation-deals/:rentToServicedAccommodationDealId"
                    authorised={policy.canViewDeals}>
                    <RentToServicedAccommodationDeal />
                  </PrivateRoute>
                  <Route
                    exact
                    path="/mortgage-applications/:mortgageApplicationId/single-let-deals/:singleLetDealId"
                  >
                    <SingleLetDeal />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/multi-dwelling-deals/:commercialToResidentialDealId"
                    authorised={policy.canViewDeals}>
                    <CommercialToResidentialDeal />
                  </PrivateRoute>
                  <Route
                    exact
                    path="/mortgage-applications/:mortgageApplicationId/commercial-to-residential-deals/:commercialToResidentialDealId"
                  >
                    <CommercialToResidentialDeal />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/flip-deals/:flipDealId"
                    authorised={policy.canViewDeals}>
                    <FlipDeal />
                  </PrivateRoute>
                  <Route
                    exact
                    path="/mortgage-applications/:mortgageApplicationId/flip-deals/:flipDealId"
                  >
                    <FlipDeal />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/mortgage-products"
                    authorised={policy.canManagePublicFinanceProcesses}>
                    <MortgageProducts />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/bridging-loan-products"
                    authorised={policy.canManagePublicFinanceProcesses}>
                    <BridgingLoanProducts />
                  </PrivateRoute>
                  <Route exact path="/investor-packs/:investorPackId/verify-access/:token">
                    <InvestorPackVerifyAccess />
                  </Route>
                  <Route exact path="/investor-packs/:investorPackId/preview">
                    {/* <InvestorPackView /> */}
                    <ProspectivePropertyInvestmentOpportunityPreview />
                  </Route>
                  <Route exact path="/investor-packs/:investorPackId/preview">
                    <OwnedPropertyInvestmentOpportunityPreview />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/investor-packs/:investorPackId"
                    authorised={policy.canManagePrivateFinanceProcesses}>
                    <InvestorPack />
                  </PrivateRoute>
                  <PrivateRoute exact path="/profile" authorised={policy.canEditUserInfo}>
                    <Profile />
                  </PrivateRoute>
                  <PrivateRoute exact path="/goals" authorised={policy.canEditUserInfo}>
                    <Goals />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/personal-assets"
                    authorised={policy.canManagePortfolio}>
                    <PersonalAssets />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-partners"
                    authorised={policy.canManageTeam}>
                    <AccountPartners />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/change_email/:token"
                    authorised={policy.canEditUserInfo}>
                    <ChangeEmail />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/portfolio-reports"
                    authorised={policy.canManagePortfolio}>
                    <PortfolioReports />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancy-report"
                    authorised={policy.canManagePortfolio}>
                    <TenancyPortfolioReport />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/certificate-report"
                    authorised={policy.canManagePortfolio}>
                    <CertificatePortfolioReport />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-property-account-report"
                    authorised={policy.canManagePortfolio}>
                    <OwnedPropertyAccountPortfolioReport />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/mortgage-account-report"
                    authorised={policy.canManagePortfolio}>
                    <MortgageAccountPortfolioReport />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/portfolio-overview-report"
                    authorised={policy.canManagePortfolio}>
                    <PortfolioOverviewReport />
                  </PrivateRoute>
                  <PrivateRoute exact path="/calendar" authorised={policy.canViewDeals}>
                    <Calendar />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/mortgage-broker-user-details"
                    authorised={policy.canSeeMortgageBrokerInterface}>
                    <MortgageBrokerUserDetails />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/mortgage-broker-user-profile"
                    authorised={policy.canSeeMortgageBrokerInterface}>
                    <MortgageBrokerUserProfile />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/mortgage-broker-team-profile"
                    authorised={policy.canSeeMortgageBrokerInterface}>
                    <MortgageBrokerAccountProfile />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/property-investment-user-profile"
                    authorised={policy.canManageTeam}>
                    <PropertyInvestmentUserProfile />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/property-investment-account-profile"
                    authorised={policy.canManageTeam}>
                    <PropertyInvestmentAccountProfile />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectiveProperties />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/archived-prospective-properties"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertiesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/rent-listings"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyRentListings />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/rent-listings/:rentListingId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyRentListing />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-rent-listings"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyRentListingsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/sale-listings"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySaleListings />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/sale-listings/:saleListingId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySaleListing />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-sale-listings"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySaleListingsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/rent-comparables"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyRentComparables />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/rent-comparables/:rentComparableId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyRentComparable />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-rent-comparables"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyRentComparablesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/videos"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyVideos />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/videos/:videoId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyVideo />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-videos"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyVideosArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/photos"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyPhotos />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/photos/:photoId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyPhoto />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-photos"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyPhotosArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/documents/:documentId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyDocumentsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/sale-comparables"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySaleComparables />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/sale-comparables/:saleComparableId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySaleComparable />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-sale-comparables"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertySaleComparablesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/previous-sales"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyPreviousSales />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/property-owners"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyPropertyOwners />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/property-owners/:propertyOwnerId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyPropertyOwner />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-property-owners"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyPropertyOwnersArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/energy-performance-certificates"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyEnergyPerformanceCertificates />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/energy-performance-certificates/:energyPerformanceCertificateId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyEnergyPerformanceCertificate />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-energy-performance-certificates"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyEnergyPerformanceCertificatesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/raise-money"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyRaiseMoney />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/safety"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertySafety />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/management"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyManagement />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/units"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyUnits />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/units/:unitId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyUnit />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-units"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyUnitsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/appliances"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyAppliances />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/appliances/:applianceId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyAppliance />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-appliances"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyAppliancesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedProperties />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/archived-owned-properties"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertiesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedProperty />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/photos"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyPhotos />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/photos/:photoId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyPhoto />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-photos"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyPhotosArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/videos"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyVideos />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/videos/:videoId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyVideo />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-videos"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyVideosArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/existing-mortgages"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyExistingMortgages />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/existing-mortgages/:existingMortgageId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyExistingMortgage />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-existing-mortgages"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyExistingMortgagesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/property-owners"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyPropertyOwners />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/property-owners/:propertyOwnerId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyPropertyOwner />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-property-owners"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyPropertyOwnersArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/energy-performance-certificates"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyEnergyPerformanceCertificates />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/energy-performance-certificates/:energyPerformanceCertificateId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyEnergyPerformanceCertificate />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-energy-performance-certificates"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyEnergyPerformanceCertificatesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/owned-property-accounts"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyAccounts />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/owned-property-accounts/:ownedPropertyAccountId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyAccount />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-owned-property-accounts"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyAccountsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/certificates"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyCertificates />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/certificates/:certificateId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyCertificate />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-certificates"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyCertificatesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/smoke-alarm-tests"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertySmokeAlarmTests />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/smoke-alarm-tests/:smokeAlarmTestId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertySmokeAlarmTest />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-smoke-alarm-tests"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertySmokeAlarmTestsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/licences"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyLicences />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/licences/:licenceId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyLicence />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-licences"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyLicencesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/tenancies"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyTenancies />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/tenancies/:tenancyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyTenancy />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-tenancies"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyTenanciesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/documents/:documentId"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/archived-documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyDocumentsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/rent-statements"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyRentStatement />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/tenants"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyTenants />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/archived-tenants"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyTenantsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/guarantors"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyGuarantors />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/tenancies/:tenancyId/archived-guarantors"
                    authorised={policy.canViewProspectiveProperties}>
                    <TenancyGuarantorsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/mortgage-accounts"
                    authorised={policy.canViewProspectiveProperties}>
                    <ExistingMortgageMortgageAccounts />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/mortgage-accounts/:mortgageAccountId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ExistingMortgageMortgageAccount />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/archived-mortgage-accounts"
                    authorised={policy.canViewProspectiveProperties}>
                    <ExistingMortgageMortgageAccountsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <ExistingMortgageDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/documents/:documentId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ExistingMortgageDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/existing-mortgages/:existingMortgageId/archived-documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <ExistingMortgageDocumentsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId"
                    authorised={policy.canViewProspectiveProperties}>
                    <Account />
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/mortgage-products"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountMortgageProducts />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/mortgage-products/:mortgageProductId"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountMortgageProduct />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/archived-mortgage-products"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountMortgageProductsArchive />
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/deal-templates"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountDealTemplates />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/deal-templates/:dealTemplateId"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountDealTemplate />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/archived-deal-templates"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountDealTemplatesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/companies"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountCompanies />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/companies/:companyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountCompany />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/archived-companies"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountCompaniesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/bridging-loan-products"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountBridgingLoanProducts />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/bridging-loan-products/:bridgingLoanProductId"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountBridgingLoanProduct />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/archived-bridging-loan-products"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountBridgingLoanProductsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/account-users/:accountUserId"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountAccountUser />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/account-users"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountAccountUsers />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/archived-account-users"
                    authorised={policy.canViewProspectiveProperties}
                  >
                    <AccountAccountUsersArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/insurance-policies/:insurancePolicyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInsurancePolicy />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/owned-properties/:ownedPropertyId/insurance-policies"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInsurancePolicies />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-insurance-policies"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInsurancePoliciesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/units/:unitId/tenancies"
                    authorised={policy.canViewProspectiveProperties}>
                    <UnitTenancies />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/units/:unitId/tenancies/:tenancyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <UnitTenancy />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/units/:unitId/archived-tenancies"
                    authorised={policy.canViewProspectiveProperties}>
                    <UnitTenanciesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/deals"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyDeals />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-deals"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyDealsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/documents/:documentId"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountUserDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/account-users/:accountUserId/documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountUserDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/archived-documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountUserDocumentsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/documents/:documentId"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/companies/:companyId/documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/archived-documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyDocumentsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/companies/:companyId/directors"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyDirectors />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/archived-directors"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyDirectorsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/companies/:companyId/shareholders"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyShareholders />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/companies/:companyId/archived-shareholders"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyShareholdersArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/companies/:companyId/owned-properties"
                    authorised={policy.canViewProspectiveProperties}>
                    <CompanyOwnedProperties />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/account-users/:accountUserId/owned-properties"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountUserOwnedProperties />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/accounts/:accountId/workflows"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountWorkflows />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/accounts/:accountId/archived-workflows"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountWorkflowsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/prospective-properties/:prospectivePropertyId/workflows"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyWorkflows />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-workflows"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyWorkflowsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/owned-properties/:ownedPropertyId/workflows"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyWorkflows />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-workflows"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyWorkflowsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/account-users/:accountUserId/workflow-assignments"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountUserWorkflowAssignments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/investment-opportunities/:investmentOpportunityId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyInvestmentOpportunity />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/prospective-properties/:prospectivePropertyId/investment-opportunities"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyInvestmentOpportunities />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-investment-opportunities"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyInvestmentOpportunitiesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/investment-opportunities/:investmentOpportunityId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInvestmentOpportunity />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/owned-properties/:ownedPropertyId/investment-opportunities"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInvestmentOpportunities />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-investment-opportunities"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInvestmentOpportunitiesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/mortgage-applications/:mortgageApplicationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyMortgageApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/prospective-properties/:prospectivePropertyId/mortgage-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyMortgageApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-mortgage-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyMortgageApplicationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/bridging-loan-applications/:bridgingLoanApplicationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyBridgingLoanApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/prospective-properties/:prospectivePropertyId/bridging-loan-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyBridgingLoanApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-bridging-loan-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyBridgingLoanApplicationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/mortgage-applications/:mortgageApplicationId/mortgage-applicants"
                    authorised={policy.canViewProspectiveProperties}>
                    <MortgageApplicationMortgageApplicants />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/bridging-loan-applications/:bridgingLoanApplicationId/bridging-loan-applicants"
                    authorised={policy.canViewProspectiveProperties}>
                    <BridgingLoanApplicationBridgingLoanApplicants />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/insurance-applications/:insuranceApplicationId/insurance-applicants"
                    authorised={policy.canViewProspectiveProperties}>
                    <InsuranceApplicationInsuranceApplicants />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/knowledge-bases/:knowledgeBaseId"
                    authorised={policy.canViewProspectiveProperties}>
                    <KnowledgeBase />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/knowledge-bases/:knowledgeBaseId/categories/:categoryId"
                    authorised={policy.canViewProspectiveProperties}>
                    <KnowledgeBaseCategory />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/knowledge-bases/:knowledgeBaseId/categories"
                    authorised={policy.canViewProspectiveProperties}>
                    <KnowledgeBaseCategories />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/categories/:categoryId/sections/:sectionId"
                    authorised={policy.canViewProspectiveProperties}>
                    <CategorySection />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/categories/:categoryId/sections"
                    authorised={policy.canViewProspectiveProperties}>
                    <CategorySections />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/sections/:sectionId/videos"
                    authorised={policy.canViewProspectiveProperties}>
                    <SectionVideos />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/academies/:academyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <Academy />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/academies/:academyId/courses/:courseId"
                    authorised={policy.canViewProspectiveProperties}>
                    <AcademyCourse />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/academies/:academyId/courses"
                    authorised={policy.canViewProspectiveProperties}>
                    <AcademyCourses />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/courses/:courseId/lessons"
                    authorised={policy.canViewProspectiveProperties}>
                    <CourseLessons />
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/communities/:communityId"
                    authorised={policy.canViewProspectiveProperties}>
                    <Community />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/communities/:communityId/community-events/:communityEventId"
                    authorised={policy.canViewProspectiveProperties}>
                    <CommunityCommunityEvent />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/communities/:communityId/community-events"
                    authorised={policy.canViewProspectiveProperties}>
                    <CommunityCommunityEvents />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/deals"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyDeals />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-deals"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyDealsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/remortgage-deals/:remortgageDealId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyRemortgageDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/further-advance-deals/:furtherAdvanceDealId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyFurtherAdvanceDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/product-transfer-deals/:productTransferDealId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyProductTransferDeal />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/monthly-expenses"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyMonthlyExpenses />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/insurance-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInsuranceApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/insurance-applications/:insuranceApplicationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyInsuranceApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/insurance-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyInsuranceApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-insurance-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyInsuranceApplicationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/insurance-applications/:insuranceApplicationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyInsuranceApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/activity-feed-items"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountUserActivityFeedItems />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/archived-activity-feed-items"
                    authorised={policy.canViewProspectiveProperties}>
                    <AccountUserActivityFeedItemsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyDocuments />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/documents/:documentId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyDocument />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-documents"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyDocumentsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/workflows/:workflowId"
                    authorised={true}
                  >
                    <Workflow />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/keys"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyKeys />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/keys/:keyId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyKey />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-keys"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyKeysArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/mortgage-applications/:mortgageApplicationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyMortgageApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/owned-properties/:ownedPropertyId/mortgage-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyMortgageApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/owned-properties/:ownedPropertyId/archived-mortgage-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyMortgageApplicationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/conveyancing-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyConveyancingApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/conveyancing-applications/:conveyancingApplicationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyConveyancingApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/prospective-properties/:prospectivePropertyId/archived-conveyancing-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <ProspectivePropertyConveyancingApplicationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/investment-opportunity-invitations"
                    authorised={policy.canViewProspectiveProperties}>
                    <InvestmentOpportunityInvestmentOpportunityInvitations />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/investment-opportunity-invitations/:investmentOpportunityInvitationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <InvestmentOpportunityInvestmentOpportunityInvitation />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/investment-opportunities/:investmentOpportunityId/archived-investment-opportunity-invitations"
                    authorised={policy.canViewProspectiveProperties}>
                    <InvestmentOpportunityInvestmentOpportunityInvitationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/bridging-loan-applications/:bridgingLoanApplicationId"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyBridgingLoanApplication />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/owned-properties/:ownedPropertyId/bridging-loan-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyBridgingLoanApplications />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/owned-properties/:ownedPropertyId/archived-bridging-loan-applications"
                    authorised={policy.canViewProspectiveProperties}>
                    <OwnedPropertyBridgingLoanApplicationsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/bridging-loan-applications/:bridgingLoanApplicationId/investment-opportunities"
                    authorised={policy.canViewProspectiveProperties}>
                    <BridgingLoanApplicationInvestmentOpportunities />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/sections/:sectionId/articles/:articleId"
                    authorised={policy.canViewProspectiveProperties}>
                    <SectionArticle />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/sections/:sectionId/articles"
                    authorised={policy.canViewProspectiveProperties}>
                    <SectionArticles />
                  </PrivateRoute>
                  <Route
                    exact
                    path="/coaching"
                  >
                    <Coaching />
                  </Route>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </FetchResource>
            </Suspense>
          </Router>
        </Snackbar>
      </Theme>
    </HelmetProvider>
  );
};

const mapStateToProps = state => ({
  loadStackedStatus: state.profile.loadStackedStatus,
  loadStackedError: state.profile.loadStackedError,
  currentUser: state.profile.currentUser,
  currentEmailIdentity: state.profile.currentEmailIdentity,
  currentAccount: state.profile.currentAccount,
  policy: state.profile.policy,
  sessionStatus: state.authentication.sessionStatus,
  sessionError: state.authentication.sessionError,
  sessionExpiring: state.authentication.sessionExpiring,
});

const mapDispatchToProps = {
  loadStacked: loadStacked,
  session: session,
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);