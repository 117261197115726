import update from 'immutability-helper'
import {FETCH_OWNED_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_TENANCIES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_TENANCIES_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_OWNED_PROPERTY_EXPENSES_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_OWNED_PROPERTY_EXPENSES_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_OWNED_PROPERTY_EXPENSES_FAILURE} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_UNITS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_UNITS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_OWNED_PROPERTY_UNITS_FAILURE} from 'redux/actionTypes.js'
import {ADD_TENANCY_REQUEST} from 'redux/actionTypes.js'
import {ADD_TENANCY_FAILURE} from 'redux/actionTypes.js'
import {ADD_TENANCY_SUCCESS} from 'redux/actionTypes.js'
// import {UPDATE_TENANCY_REQUEST} from 'redux/actionTypes.js'
// import {UPDATE_TENANCY_FAILURE} from 'redux/actionTypes.js'
// import {UPDATE_TENANCY_SUCCESS} from 'redux/actionTypes.js'
// import {ARCHIVE_TENANCY_REQUEST} from 'redux/actionTypes.js'
// import {ARCHIVE_TENANCY_FAILURE} from 'redux/actionTypes.js'
// import {ARCHIVE_TENANCY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGES_FAILURE} from 'redux/actionTypes.js'
import {ADD_EXISTING_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {ADD_EXISTING_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {ADD_EXISTING_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_EXISTING_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_EXISTING_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_EXISTING_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_EXISTING_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {DELETE_EXISTING_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {DELETE_EXISTING_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_OWNED_PROPERTY_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_OWNED_PROPERTY_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_OWNED_PROPERTY_SUCCESS} from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_SALE_COMPARABLES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_SALE_COMPARABLES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_SALE_COMPARABLES_FAILURE } from 'redux/actionTypes.js'
import { ADD_SALE_COMPARABLE_REQUEST } from 'redux/actionTypes.js'
import { ADD_SALE_COMPARABLE_FAILURE } from 'redux/actionTypes.js'
import { ADD_SALE_COMPARABLE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_SALE_COMPARABLE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_SALE_COMPARABLE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_SALE_COMPARABLE_SUCCESS } from 'redux/actionTypes.js'
import { ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_REQUEST } from 'redux/actionTypes.js'
import { ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_FAILURE } from 'redux/actionTypes.js'
import { ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchOwnedPropertyStatus: 'requested',
  fetchOwnedPropertyError: '',
  ownedProperty: {},
  fetchOwnedPropertyPurchaseExpensesStatus: 'requested',
  fetchOwnedPropertyPurchaseExpensesError: '',
  purchaseExpenses: [],
  fetchOwnedPropertyDevelopmentExpensesStatus: 'requested',
  fetchOwnedPropertyDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchOwnedPropertyRecurringExpensesStatus: 'requested',
  fetchOwnedPropertyRecurringExpensesError: '',
  recurringExpenses: [],
  fetchOwnedPropertyTenanciesStatus: 'requested',
  fetchOwnedPropertyTenanciesError: '',
  tenancies: [],
  fetchOwnedPropertyUnitsStatus: 'requested',
  fetchOwnedPropertyUnitsError: '',
  units: [],
  fetchExistingMortgagesStatus: 'requested',
  fetchExistingMortgagesError: '',
  mortgages: [],
  fetchOwnedPropertySaleComparablesStatus: 'requested',
  fetchOwnedPropertySaleComparablesError: '',
  saleComparables: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_REQUEST: {
      return update(state, {
        fetchOwnedPropertyStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_FAILURE: {
      return update(state, {
        fetchOwnedPropertyStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyError: {$set: action.payload.error},
      })
    }

    case FETCH_OWNED_PROPERTY_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyStatus: {$set: 'success'},
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchOwnedPropertyPurchaseExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchOwnedPropertyPurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyPurchaseExpensesError: {$set: action.payload.error},
      })
    }

    case FETCH_OWNED_PROPERTY_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyPurchaseExpensesStatus: {$set: 'success'},
        purchaseExpenses: {
          $set: action.payload.purchaseExpenses,
        },
      })
    }

    case UPDATE_OWNED_PROPERTY_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_EXPENSES_SUCCESS: {
      return update(state, {
        ownedProperty: {
          purchaseCosts: {$set: action.payload.ownedProperty.purchaseCosts},
          developmentCosts: {
            $set: action.payload.ownedProperty.developmentCosts,
          },
          cashInProperty: {$set: action.payload.ownedProperty.cashInProperty},
          monthlyExpenses: {$set: action.payload.ownedProperty.monthlyExpenses},
          monthlyCashFlow: {$set: action.payload.ownedProperty.monthlyCashFlow},
          annualProfit: { $set: action.payload.ownedProperty.annualProfit },
          returnOnEquity: { $set: action.payload.ownedProperty.returnOnEquity },
          returnOnInvestment: { $set: action.payload.ownedProperty.returnOnInvestment },
          acquisitionCosts: { $set: action.payload.ownedProperty.acquisitionCosts },
        },
      })
    }

    case FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchOwnedPropertyDevelopmentExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchOwnedPropertyDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyDevelopmentExpensesStatus: {$set: 'success'},
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchOwnedPropertyRecurringExpensesStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchOwnedPropertyRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyRecurringExpensesStatus: {$set: 'success'},
        recurringExpenses: {
          $set: action.payload.recurringExpenses,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_TENANCIES_REQUEST: {
      return update(state, {
        fetchOwnedPropertyTenanciesStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_TENANCIES_FAILURE: {
      return update(state, {
        fetchOwnedPropertyTenanciesStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyTenanciesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyTenanciesStatus: {$set: 'success'},
        tenancies: {
          $set: action.payload.tenancies,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_UNITS_REQUEST: {
      return update(state, {
        fetchOwnedPropertyUnitsStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_UNITS_FAILURE: {
      return update(state, {
        fetchOwnedPropertyUnitsStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyUnitsError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_UNITS_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyUnitsStatus: {$set: 'success'},
        units: {
          $set: action.payload.units,
        },
      })
    }

    case ADD_TENANCY_REQUEST: {
      return state
    }

    case ADD_TENANCY_FAILURE: {
      return state
    }

    case ADD_TENANCY_SUCCESS: {
      return update(state, {
        tenancies: {
          $push: [action.payload.tenancy],
        },
        ownedProperty: {
          rentalIncome: {
            $set: action.payload.tenancy.unit.ownedProperty.rentalIncome,
          },
          monthlyCashFlow: {
            $set: action.payload.tenancy.unit.ownedProperty.monthlyCashFlow,
          },
          annualProfit: {
            $set: action.payload.tenancy.unit.ownedProperty.annualProfit,
          },
          returnOnEquity: {
            $set: action.payload.tenancy.unit.ownedProperty.returnOnEquity,
          },
          returnOnInvestment: {
            $set: action.payload.tenancy.unit.ownedProperty.returnOnInvestment,
          },
        },
      })
    }

    // case UPDATE_TENANCY_REQUEST: {
    //   return state
    // }

    // case UPDATE_TENANCY_FAILURE: {
    //   return state
    // }

    // case UPDATE_TENANCY_SUCCESS: {
    //   let index = state.tenancies.findIndex(
    //     x => x.id === action.payload.tenancy.id,
    //   )

    //   return update(state, {
    //     tenancies: {
    //       [index]: {$set: action.payload.tenancy},
    //     },
    //     ownedProperty: {
    //       rentalIncome: {
    //         $set: action.payload.tenancy.unit.ownedProperty.rentalIncome,
    //       },
    //       monthlyCashFlow: {
    //         $set: action.payload.tenancy.unit.ownedProperty.monthlyCashFlow,
    //       },
    //       annualProfit: {
    //         $set: action.payload.tenancy.unit.ownedProperty.annualProfit,
    //       },
    //       returnOnEquity: {
    //         $set: action.payload.tenancy.unit.ownedProperty.returnOnEquity,
    //       },
    //       returnOnInvestment: {
    //         $set: action.payload.tenancy.unit.ownedProperty.returnOnInvestment,
    //       },
    //     },
    //   })
    // }

    // case ARCHIVE_TENANCY_REQUEST: {
    //   return state
    // }

    // case ARCHIVE_TENANCY_FAILURE: {
    //   return state
    // }

    // case ARCHIVE_TENANCY_SUCCESS: {
    //   let index = state.tenancies.findIndex(x => x.id === action.payload.id)

    //   return update(state, {
    //     tenancies: {
    //       $splice: [[index, 1]],
    //     },
    //   })
    // }

    case FETCH_EXISTING_MORTGAGES_REQUEST: {
      return update(state, {
        fetchExistingMortgagesStatus: {$set: 'requested'},
      })
    }

    case FETCH_EXISTING_MORTGAGES_FAILURE: {
      return update(state, {
        fetchExistingMortgagesStatus: {
          $set: 'error',
        },
        fetchExistingMortgagesError: {$set: action.payload.error},
      })
    }

    case FETCH_EXISTING_MORTGAGES_SUCCESS: {
      return update(state, {
        fetchExistingMortgagesStatus: {$set: 'success'},
        mortgages: {
          $set: action.payload.mortgages,
        },
      })
    }

    case ADD_EXISTING_MORTGAGE_REQUEST: {
      return state
    }

    case ADD_EXISTING_MORTGAGE_FAILURE: {
      return state
    }

    case ADD_EXISTING_MORTGAGE_SUCCESS: {
      return update(state, {
        mortgages: {
          $push: [action.payload.mortgage],
        },
      })
    }

    case UPDATE_EXISTING_MORTGAGE_REQUEST: {
      return state
    }

    case UPDATE_EXISTING_MORTGAGE_FAILURE: {
      return state
    }

    case UPDATE_EXISTING_MORTGAGE_SUCCESS: {
      let index = state.mortgages.findIndex(
        x => x.id === action.payload.mortgage.id,
      )

      return update(state, {
        mortgages: {
          [index]: {$set: action.payload.mortgage},
        },
      })
    }

    case DELETE_EXISTING_MORTGAGE_REQUEST: {
      return state
    }

    case DELETE_EXISTING_MORTGAGE_FAILURE: {
      return state
    }

    case DELETE_EXISTING_MORTGAGE_SUCCESS: {
      let index = state.mortgages.findIndex(x => x.id === action.payload.id)

      return update(state, {
        mortgages: {
          $splice: [[index, 1]],
        },
      })
    }

    case UPDATE_OWNED_PROPERTY_REQUEST: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_FAILURE: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_SUCCESS: {
      return update(state, {
        ownedProperty: {
          purchasePrice: {
            $set: action.payload.ownedProperty.purchasePrice,
          },
          purchaseCosts: {
            $set: action.payload.ownedProperty.purchaseCosts,
          },
          currentValue: {
            $set: action.payload.ownedProperty.currentValue,
          },
          cashInProperty: {
            $set: action.payload.ownedProperty.cashInProperty,
          },
          equity: {
            $set: action.payload.ownedProperty.equity,
          },
          returnOnEquity: {
            $set: action.payload.ownedProperty.returnOnEquity,
          },
          returnOnInvestment: {
            $set: action.payload.ownedProperty.returnOnInvestment,
          },
          acquisitionCosts: {
            $set: action.payload.ownedProperty.acquisitionCosts,
          },
        },
      })
    }

    case FETCH_OWNED_PROPERTY_SALE_COMPARABLES_REQUEST: {
      return update(state, {
        fetchOwnedPropertySaleComparablesStatus: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_SALE_COMPARABLES_FAILURE: {
      return update(state, {
        fetchOwnedPropertySaleComparablesStatus: {
          $set: 'error',
        },
        fetchOwnedPropertySaleComparablesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_SALE_COMPARABLES_SUCCESS: {
      return update(state, {
        fetchOwnedPropertySaleComparablesStatus: { $set: 'success' },
        saleComparables: {
          $set: action.payload.saleComparables,
        },
      })
    }

    case ADD_SALE_COMPARABLE_REQUEST: {
      return state
    }

    case ADD_SALE_COMPARABLE_FAILURE: {
      return state
    }

    case ADD_SALE_COMPARABLE_SUCCESS: {
      let ownedProperty = action.payload.saleComparable.property.ownedProperty || {}

      if (ownedProperty.id) {
        return update(state, {
          saleComparables: {
            $push: [action.payload.saleComparable],
          },
          ownedProperty: {
            property: {
              saleComparablesAverageCostPerSquareMetre: {
                $set: action.payload.saleComparable.property.saleComparablesAverageCostPerSquareMetre
              }
            }
          }
        })
      }
    }

    case UPDATE_SALE_COMPARABLE_REQUEST: {
      return state
    }

    case UPDATE_SALE_COMPARABLE_FAILURE: {
      return state
    }

    case UPDATE_SALE_COMPARABLE_SUCCESS: {
      let index = state.saleComparables.findIndex(
        x => x.id === action.payload.saleComparable.id,
      )

      let ownedProperty = action.payload.saleComparable.property.ownedProperty || {}

      if (ownedProperty.id) {
        return update(state, {
          saleComparables: {
            [index]: { $set: action.payload.saleComparable },
          },
          ownedProperty: {
            property: {
              saleComparablesAverageCostPerSquareMetre: {
                $set: action.payload.saleComparable.property.saleComparablesAverageCostPerSquareMetre
              }
            }
          }
        })
      }
    }

    case ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_REQUEST: {
      return state
    }

    case ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_FAILURE: {
      return state
    }

    case ADD_SALE_COMPARABLE_FROM_PROPERTY_PRICE_PAID_RECORD_SUCCESS: {
      return update(state, {
        saleComparables: {
          $push: [action.payload.saleComparable],
        }
      })
    }

    default:
      return state
  }
}
