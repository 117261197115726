import update from 'immutability-helper'
import { FETCH_TENANCY_WORKFLOWS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_TENANCY_WORKFLOWS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_TENANCY_WORKFLOWS_SUCCESS } from 'redux/actionTypes.js'
import { ADD_WORKFLOW_TO_TENANCY_REQUEST } from 'redux/actionTypes.js'
import { ADD_WORKFLOW_TO_TENANCY_FAILURE } from 'redux/actionTypes.js'
import { ADD_WORKFLOW_TO_TENANCY_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchTenancyWorkflowsStatus: 'requested',
  fetchTenancyWorkflowsError: '',
  tenancy: {},
  workflows: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANCY_WORKFLOWS_REQUEST: {
      return update(state, {
        fetchTenancyWorkflowsStatus: { $set: 'requested' },
      })
    }

    case FETCH_TENANCY_WORKFLOWS_FAILURE: {
      return update(state, {
        fetchTenancyWorkflowsStatus: {
          $set: 'error',
        },
        fetchTenancyWorkflowsError: { $set: action.payload.error },
      })
    }

    case FETCH_TENANCY_WORKFLOWS_SUCCESS: {
      return update(state, {
        fetchTenancyWorkflowsStatus: { $set: 'success' },
        tenancy: {
          $set: action.payload.tenancy,
        },
        workflows: {
          $set: action.payload.tenancy.workflows,
        },
      })
    }

    case ADD_WORKFLOW_TO_TENANCY_REQUEST: {
      return state
    }

    case ADD_WORKFLOW_TO_TENANCY_FAILURE: {
      return state
    }

    case ADD_WORKFLOW_TO_TENANCY_SUCCESS: {
      return state
    }

    default:
      return state
  }
}
