import moment from 'moment'
import 'moment/locale/en-gb'

function useFormattedDateTime(date) {
  if (date) {
    return moment(date).locale('en-gb').format('L - LT')
  } else {
    return 'N/A'
  }
}

export default useFormattedDateTime
