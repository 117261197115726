import React from 'react'
import styled from 'styled-components'
import Navbar from 'sharedComponents/Navbar.js'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.space.m};
  padding-right: ${props => props.theme.space.l};
  // position: fixed;
  top: 0;
`

function PageNavbar(props) {
  const { height } = props;
  return (
    <Navbar backgroundColor={props => props.theme.colors.white} height={props => height || props.theme.space.xl}>
      <Wrapper>
        {props.children}
      </Wrapper>
    </Navbar>
  )
}

export default PageNavbar
