import React from 'react'
import Container from 'react-bootstrap/Container'
import PublicTopNavbar from 'sharedComponents/PublicTopNavbar/PublicTopNavbar.js'
import Card from 'sharedComponents/Card.js'
import SetPasswordForm from 'spas/SetPassword/SetPasswordForm.js'
import Heading from 'sharedComponents/Forms/Heading.js'
import FormWrapper from 'sharedComponents/Forms/FormWrapper.js'

function SetPassword(props) {
  return (
    <Container fluid>
      <PublicTopNavbar />
      <FormWrapper>
        <Card backgroundColor={props => props.theme.colors.white}>
          <Heading text='Set Your Password' />
          <SetPasswordForm currentEmailIdentity={props.currentEmailIdentity} />
        </Card>
      </FormWrapper>
    </Container>
  )
}

export default SetPassword
