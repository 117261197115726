export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE'

export const FETCH_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST = 'FETCH_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const FETCH_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS = 'FETCH_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const FETCH_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE = 'FETCH_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const CREATE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST = 'CREATE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const CREATE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS = 'CREATE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const CREATE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE = 'CREATE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST = 'ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS = 'ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE = 'ARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const UNARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST = 'UNARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const UNARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS = 'UNARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const UNARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE = 'UNARCHIVE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const DELETE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST = 'DELETE_ENERGY_PERFORMANCE_CERTIFICATE_REQUEST'
export const DELETE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS = 'DELETE_ENERGY_PERFORMANCE_CERTIFICATE_SUCCESS'
export const DELETE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE = 'DELETE_ENERGY_PERFORMANCE_CERTIFICATE_FAILURE'

export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST = 'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_REQUEST'
export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS = 'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_SUCCESS'
export const FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE = 'FETCH_POSSIBLE_ENERGY_PERFORMANCE_CERTIFICATES_FAILURE'
