export const FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_REQUEST = 'FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_REQUEST'
export const FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_SUCCESS = 'FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_SUCCESS'
export const FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_FAILURE = 'FETCH_OWNED_PROPERTY_MONTHLY_EXPENSES_FAILURE'

export const CREATE_MONTHLY_EXPENSE_REQUEST = 'CREATE_MONTHLY_EXPENSE_REQUEST'
export const CREATE_MONTHLY_EXPENSE_SUCCESS = 'CREATE_MONTHLY_EXPENSE_SUCCESS'
export const CREATE_MONTHLY_EXPENSE_FAILURE = 'CREATE_MONTHLY_EXPENSE_FAILURE'

export const UPDATE_MONTHLY_EXPENSE_REQUEST = 'UPDATE_MONTHLY_EXPENSE_REQUEST'
export const UPDATE_MONTHLY_EXPENSE_SUCCESS = 'UPDATE_MONTHLY_EXPENSE_SUCCESS'
export const UPDATE_MONTHLY_EXPENSE_FAILURE = 'UPDATE_MONTHLY_EXPENSE_FAILURE'

export const DELETE_MONTHLY_EXPENSE_REQUEST = 'DELETE_MONTHLY_EXPENSE_REQUEST'
export const DELETE_MONTHLY_EXPENSE_SUCCESS = 'DELETE_MONTHLY_EXPENSE_SUCCESS'
export const DELETE_MONTHLY_EXPENSE_FAILURE = 'DELETE_MONTHLY_EXPENSE_FAILURE'