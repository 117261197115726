export const FETCH_OWNED_PROPERTY_PROPERTY_OWNERS_REQUEST = 'FETCH_OWNED_PROPERTY_PROPERTY_OWNERS_REQUEST'
export const FETCH_OWNED_PROPERTY_PROPERTY_OWNERS_SUCCESS = 'FETCH_OWNED_PROPERTY_PROPERTY_OWNERS_SUCCESS'
export const FETCH_OWNED_PROPERTY_PROPERTY_OWNERS_FAILURE = 'FETCH_OWNED_PROPERTY_PROPERTY_OWNERS_FAILURE'

export const FETCH_PROPERTY_OWNER_REQUEST = 'FETCH_PROPERTY_OWNER_REQUEST'
export const FETCH_PROPERTY_OWNER_SUCCESS = 'FETCH_PROPERTY_OWNER_SUCCESS'
export const FETCH_PROPERTY_OWNER_FAILURE = 'FETCH_PROPERTY_OWNER_FAILURE'

export const CREATE_PROPERTY_OWNER_REQUEST = 'CREATE_PROPERTY_OWNER_REQUEST'
export const CREATE_PROPERTY_OWNER_SUCCESS = 'CREATE_PROPERTY_OWNER_SUCCESS'
export const CREATE_PROPERTY_OWNER_FAILURE = 'CREATE_PROPERTY_OWNER_FAILURE'

export const UPDATE_PROPERTY_OWNER_REQUEST = 'UPDATE_PROPERTY_OWNER_REQUEST'
export const UPDATE_PROPERTY_OWNER_SUCCESS = 'UPDATE_PROPERTY_OWNER_SUCCESS'
export const UPDATE_PROPERTY_OWNER_FAILURE = 'UPDATE_PROPERTY_OWNER_FAILURE'

export const ARCHIVE_PROPERTY_OWNER_REQUEST = 'ARCHIVE_PROPERTY_OWNER_REQUEST'
export const ARCHIVE_PROPERTY_OWNER_SUCCESS = 'ARCHIVE_PROPERTY_OWNER_SUCCESS'
export const ARCHIVE_PROPERTY_OWNER_FAILURE = 'ARCHIVE_PROPERTY_OWNER_FAILURE'

export const UNARCHIVE_PROPERTY_OWNER_REQUEST = 'UNARCHIVE_PROPERTY_OWNER_REQUEST'
export const UNARCHIVE_PROPERTY_OWNER_SUCCESS = 'UNARCHIVE_PROPERTY_OWNER_SUCCESS'
export const UNARCHIVE_PROPERTY_OWNER_FAILURE = 'UNARCHIVE_PROPERTY_OWNER_FAILURE'

export const DELETE_PROPERTY_OWNER_REQUEST = 'DELETE_PROPERTY_OWNER_REQUEST'
export const DELETE_PROPERTY_OWNER_SUCCESS = 'DELETE_PROPERTY_OWNER_SUCCESS'
export const DELETE_PROPERTY_OWNER_FAILURE = 'DELETE_PROPERTY_OWNER_FAILURE'