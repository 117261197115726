import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-weight: normal;
  display: flex;
  padding-left: ${props => props.theme.space.xs};
  padding-right: ${props => props.theme.space.xs};
`

function StatusSubContent(props) {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}

export default StatusSubContent
