import update from 'immutability-helper'
import { FETCH_LICENCE_REQUEST } from 'v2/spas/owned-property/licences/redux/action-types.tsx'
import { FETCH_LICENCE_SUCCESS } from 'v2/spas/owned-property/licences/redux/action-types.tsx'
import { FETCH_LICENCE_FAILURE } from 'v2/spas/owned-property/licences/redux/action-types.tsx'
import { UPDATE_LICENCE_REQUEST } from 'v2/spas/owned-property/licences/redux/action-types.tsx'
import { UPDATE_LICENCE_SUCCESS } from 'v2/spas/owned-property/licences/redux/action-types.tsx'
import { UPDATE_LICENCE_FAILURE } from 'v2/spas/owned-property/licences/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  licence: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LICENCE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_LICENCE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_LICENCE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        licence: {
          $set: action.payload.licence,
        }
      })
    }

    case UPDATE_LICENCE_REQUEST: {
      return update(state, {
        updateLicenceStatus: { $set: 'requested' },
      })
    }

    case UPDATE_LICENCE_FAILURE: {
      return update(state, {
        updateLicenceStatus: {
          $set: 'error',
        },
        updateLicenceError: { $set: action.payload.error },
      })
    }

    case UPDATE_LICENCE_SUCCESS: {
      return update(state, {
        updateLicenceStatus: { $set: 'success' },
        licence: {
          $set: action.payload.licence,
        }
      })
    }

    default:
      return state
  }
}