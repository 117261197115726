import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTIES_REQUEST } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTIES_SUCCESS } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTIES_FAILURE } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { ARCHIVE_OWNED_PROPERTY_REQUEST } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { ARCHIVE_OWNED_PROPERTY_SUCCESS } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { ARCHIVE_OWNED_PROPERTY_FAILURE } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_REQUEST } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_SUCCESS } from 'v2/spas/owned-properties/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_FAILURE } from 'v2/spas/owned-properties/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperties: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperties: {
          $set: action.payload.currentAccount.ownedProperties,
        },
      })
    }

    case UPDATE_OWNED_PROPERTY_REQUEST: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_FAILURE: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_SUCCESS: {
      let index = state.ownedProperties.findIndex(
        x => x.id === action.payload.ownedProperty.id,
      )

      return update(state, {
        ownedProperties: {
          [index]: { $set: action.payload.ownedProperty },
        },
      })
    }

    case ARCHIVE_OWNED_PROPERTY_REQUEST: {
      return state
    }

    case ARCHIVE_OWNED_PROPERTY_FAILURE: {
      return state
    }

    case ARCHIVE_OWNED_PROPERTY_SUCCESS: {
      let index = state.ownedProperties.findIndex(x => x.id === action.payload.ownedProperty.id)

      return update(state, {
        ownedProperties: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
