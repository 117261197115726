export const FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_RENT_COMPARABLES_FAILURE'

export const FETCH_RENT_COMPARABLE_REQUEST = 'FETCH_RENT_COMPARABLE_REQUEST'
export const FETCH_RENT_COMPARABLE_SUCCESS = 'FETCH_RENT_COMPARABLE_SUCCESS'
export const FETCH_RENT_COMPARABLE_FAILURE = 'FETCH_RENT_COMPARABLE_FAILURE'

export const CREATE_RENT_COMPARABLE_REQUEST = 'CREATE_RENT_COMPARABLE_REQUEST'
export const CREATE_RENT_COMPARABLE_SUCCESS = 'CREATE_RENT_COMPARABLE_SUCCESS'
export const CREATE_RENT_COMPARABLE_FAILURE = 'CREATE_RENT_COMPARABLE_FAILURE'

export const UPDATE_RENT_COMPARABLE_REQUEST = 'UPDATE_RENT_COMPARABLE_REQUEST'
export const UPDATE_RENT_COMPARABLE_SUCCESS = 'UPDATE_RENT_COMPARABLE_SUCCESS'
export const UPDATE_RENT_COMPARABLE_FAILURE = 'UPDATE_RENT_COMPARABLE_FAILURE'

export const ARCHIVE_RENT_COMPARABLE_REQUEST = 'ARCHIVE_RENT_COMPARABLE_REQUEST'
export const ARCHIVE_RENT_COMPARABLE_SUCCESS = 'ARCHIVE_RENT_COMPARABLE_SUCCESS'
export const ARCHIVE_RENT_COMPARABLE_FAILURE = 'ARCHIVE_RENT_COMPARABLE_FAILURE'

export const UNARCHIVE_RENT_COMPARABLE_REQUEST = 'UNARCHIVE_RENT_COMPARABLE_REQUEST'
export const UNARCHIVE_RENT_COMPARABLE_SUCCESS = 'UNARCHIVE_RENT_COMPARABLE_SUCCESS'
export const UNARCHIVE_RENT_COMPARABLE_FAILURE = 'UNARCHIVE_RENT_COMPARABLE_FAILURE'

export const DELETE_RENT_COMPARABLE_REQUEST = 'DELETE_RENT_COMPARABLE_REQUEST'
export const DELETE_RENT_COMPARABLE_SUCCESS = 'DELETE_RENT_COMPARABLE_SUCCESS'
export const DELETE_RENT_COMPARABLE_FAILURE = 'DELETE_RENT_COMPARABLE_FAILURE'