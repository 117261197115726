import update from 'immutability-helper'
import {FETCH_PRESENTATION_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_FAILURE} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_SLIDES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_SLIDES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_SLIDES_FAILURE} from 'redux/actionTypes.js'
import {ADD_CASH_FLOW_SUMMARY_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_CASH_FLOW_SUMMARY_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_CASH_FLOW_SUMMARY_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {DELETE_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {DELETE_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_RENT_COMPARABLE_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_RENT_COMPARABLE_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_RENT_COMPARABLE_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_SALE_COMPARABLE_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_SALE_COMPARABLE_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_SALE_COMPARABLE_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_RENT_LISTING_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_RENT_LISTING_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_RENT_LISTING_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_SALE_LISTING_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_SALE_LISTING_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_SALE_LISTING_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_PHOTO_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_PHOTO_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_PHOTO_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_DOCUMENT_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_DOCUMENT_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_DOCUMENT_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_LOCATION_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_LOCATION_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_LOCATION_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import { ADD_FINANCIAL_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { ADD_FINANCIAL_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { ADD_FINANCIAL_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { ADD_PURCHASE_COST_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { ADD_PURCHASE_COST_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { ADD_PURCHASE_COST_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { ADD_DEVELOPMENT_COST_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { ADD_DEVELOPMENT_COST_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { ADD_DEVELOPMENT_COST_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { ADD_CASH_FLOW_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { ADD_CASH_FLOW_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { ADD_CASH_FLOW_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { ADD_LOAN_REPAYMENT_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { ADD_LOAN_REPAYMENT_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { ADD_LOAN_REPAYMENT_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { ADD_EQUITY_STRUCTURE_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { ADD_EQUITY_STRUCTURE_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { ADD_EQUITY_STRUCTURE_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import {ADD_TEXT_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_TEXT_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_TEXT_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_LOAN_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_LOAN_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_LOAN_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_SHAREHOLDING_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_SHAREHOLDING_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_SHAREHOLDING_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_COVER_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {ADD_COVER_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {ADD_COVER_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_COVER_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_COVER_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_COVER_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_TEXT_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_TEXT_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_TEXT_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_RENT_COMPARABLE_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_RENT_COMPARABLE_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_RENT_COMPARABLE_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_SALE_COMPARABLE_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SALE_COMPARABLE_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SALE_COMPARABLE_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_RENT_LISTING_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_RENT_LISTING_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_RENT_LISTING_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_SALE_LISTING_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SALE_LISTING_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SALE_LISTING_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_DOCUMENT_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_DOCUMENT_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_DOCUMENT_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PHOTO_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PHOTO_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PHOTO_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_LOCATION_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_LOCATION_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_LOCATION_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import { UPDATE_FINANCIAL_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_FINANCIAL_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_FINANCIAL_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_PURCHASE_COST_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_PURCHASE_COST_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_PURCHASE_COST_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_DEVELOPMENT_COST_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_DEVELOPMENT_COST_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_DEVELOPMENT_COST_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_CASH_FLOW_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_CASH_FLOW_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_CASH_FLOW_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_LOAN_REPAYMENT_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_LOAN_REPAYMENT_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_LOAN_REPAYMENT_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_EQUITY_STRUCTURE_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_EQUITY_STRUCTURE_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_EQUITY_STRUCTURE_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import {UPDATE_LOAN_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_LOAN_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_LOAN_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_SHAREHOLDING_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_SHAREHOLDING_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_SHAREHOLDING_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_CASH_FLOW_SUMMARY_SLIDE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_CASH_FLOW_SUMMARY_SLIDE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_CASH_FLOW_SUMMARY_SLIDE_SUCCESS} from 'redux/actionTypes.js'
import {MOVE_SLIDE_UP_REQUEST} from 'redux/actionTypes.js'
import {MOVE_SLIDE_UP_FAILURE} from 'redux/actionTypes.js'
import {MOVE_SLIDE_UP_SUCCESS} from 'redux/actionTypes.js'
import {MOVE_SLIDE_DOWN_REQUEST} from 'redux/actionTypes.js'
import {MOVE_SLIDE_DOWN_FAILURE} from 'redux/actionTypes.js'
import {MOVE_SLIDE_DOWN_SUCCESS} from 'redux/actionTypes.js'
import {SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_PRESENTATION_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_PRESENTATION_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_PRESENTATION_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_INVITATIONS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_INVITATIONS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_PRESENTATION_INVITATIONS_SUCCESS} from 'redux/actionTypes.js'
import { ADD_VIDEO_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { ADD_VIDEO_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { ADD_VIDEO_SLIDE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_VIDEO_SLIDE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_VIDEO_SLIDE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_VIDEO_SLIDE_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchPresentationStatus: 'requested',
  fetchPresentationError: '',
  presentation: {},
  fetchPresentationSlidesStatus: 'requested',
  fetchPresentationSlidesError: '',
  slides: [],
  fetchPresentationInvitationsStatus: 'requested',
  fetchPresentationInvitationsError: '',
  presentationInvitations: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRESENTATION_REQUEST: {
      return update(state, {
        fetchPresentationStatus: {$set: 'requested'},
      })
    }

    case FETCH_PRESENTATION_FAILURE: {
      return update(state, {
        fetchPresentationStatus: {
          $set: 'error',
        },
        fetchPresentationError: {$set: action.payload.error},
      })
    }

    case FETCH_PRESENTATION_SUCCESS: {
      return update(state, {
        fetchPresentationStatus: {$set: 'success'},
        presentation: {
          $set: action.payload.presentation,
        },
      })
    }

    case FETCH_PRESENTATION_SLIDES_REQUEST: {
      return update(state, {
        fetchPresentationSlidesStatus: {$set: 'requested'},
      })
    }

    case FETCH_PRESENTATION_SLIDES_FAILURE: {
      return update(state, {
        fetchPresentationSlidesStatus: {
          $set: 'error',
        },
        fetchPresentationSlidesError: {$set: action.payload.error},
      })
    }

    case FETCH_PRESENTATION_SLIDES_SUCCESS: {
      return update(state, {
        fetchPresentationSlidesStatus: {$set: 'success'},
        slides: {
          $set: action.payload.slides,
        },
      })
    }

    case FETCH_PRESENTATION_INVITATIONS_REQUEST: {
      return update(state, {
        fetchPresentationInvitationsStatus: {$set: 'requested'},
      })
    }

    case FETCH_PRESENTATION_INVITATIONS_FAILURE: {
      return update(state, {
        fetchPresentationInvitationsStatus: {
          $set: 'error',
        },
        fetchPresentationInvitationsError: {$set: action.payload.error},
      })
    }

    case FETCH_PRESENTATION_INVITATIONS_SUCCESS: {
      return update(state, {
        fetchPresentationInvitationsStatus: {$set: 'success'},
        presentationInvitations: {
          $set: action.payload.presentationInvitations,
        },
      })
    }

    case DELETE_SLIDE_REQUEST: {
      return state
    }

    case DELETE_SLIDE_FAILURE: {
      return state
    }

    case DELETE_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $set: action.payload.slides,
        },
      })
    }

    case ADD_RENT_COMPARABLE_SLIDE_REQUEST: {
      return state
    }

    case ADD_RENT_COMPARABLE_SLIDE_FAILURE: {
      return state
    }

    case ADD_RENT_COMPARABLE_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_SALE_COMPARABLE_SLIDE_REQUEST: {
      return state
    }

    case ADD_SALE_COMPARABLE_SLIDE_FAILURE: {
      return state
    }

    case ADD_SALE_COMPARABLE_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_RENT_LISTING_SLIDE_REQUEST: {
      return state
    }

    case ADD_RENT_LISTING_SLIDE_FAILURE: {
      return state
    }

    case ADD_RENT_LISTING_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_SALE_LISTING_SLIDE_REQUEST: {
      return state
    }

    case ADD_SALE_LISTING_SLIDE_FAILURE: {
      return state
    }

    case ADD_SALE_LISTING_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_PHOTO_SLIDE_REQUEST: {
      return state
    }

    case ADD_PHOTO_SLIDE_FAILURE: {
      return state
    }

    case ADD_PHOTO_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_DOCUMENT_SLIDE_REQUEST: {
      return state
    }

    case ADD_DOCUMENT_SLIDE_FAILURE: {
      return state
    }

    case ADD_DOCUMENT_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_LOCATION_SLIDE_REQUEST: {
      return state
    }

    case ADD_LOCATION_SLIDE_FAILURE: {
      return state
    }

    case ADD_LOCATION_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_FINANCIAL_SLIDE_REQUEST: {
      return state
    }

    case ADD_FINANCIAL_SLIDE_FAILURE: {
      return state
    }

    case ADD_FINANCIAL_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_PURCHASE_COST_SLIDE_REQUEST: {
      return state
    }

    case ADD_PURCHASE_COST_SLIDE_FAILURE: {
      return state
    }

    case ADD_PURCHASE_COST_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_DEVELOPMENT_COST_SLIDE_REQUEST: {
      return state
    }

    case ADD_DEVELOPMENT_COST_SLIDE_FAILURE: {
      return state
    }

    case ADD_DEVELOPMENT_COST_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_CASH_FLOW_SLIDE_REQUEST: {
      return state
    }

    case ADD_CASH_FLOW_SLIDE_FAILURE: {
      return state
    }

    case ADD_CASH_FLOW_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_LOAN_REPAYMENT_SLIDE_REQUEST: {
      return state
    }

    case ADD_LOAN_REPAYMENT_SLIDE_FAILURE: {
      return state
    }

    case ADD_LOAN_REPAYMENT_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_EQUITY_STRUCTURE_SLIDE_REQUEST: {
      return state
    }

    case ADD_EQUITY_STRUCTURE_SLIDE_FAILURE: {
      return state
    }

    case ADD_EQUITY_STRUCTURE_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_TEXT_SLIDE_REQUEST: {
      return state
    }

    case ADD_TEXT_SLIDE_FAILURE: {
      return state
    }

    case ADD_TEXT_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_VIDEO_SLIDE_REQUEST: {
      return state
    }

    case ADD_VIDEO_SLIDE_FAILURE: {
      return state
    }

    case ADD_VIDEO_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_LOAN_SLIDE_REQUEST: {
      return state
    }

    case ADD_LOAN_SLIDE_FAILURE: {
      return state
    }

    case ADD_LOAN_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_SHAREHOLDING_SLIDE_REQUEST: {
      return state
    }

    case ADD_SHAREHOLDING_SLIDE_FAILURE: {
      return state
    }

    case ADD_SHAREHOLDING_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_CASH_FLOW_SUMMARY_SLIDE_REQUEST: {
      return state
    }

    case ADD_CASH_FLOW_SUMMARY_SLIDE_FAILURE: {
      return state
    }

    case ADD_CASH_FLOW_SUMMARY_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case ADD_COVER_SLIDE_REQUEST: {
      return state
    }

    case ADD_COVER_SLIDE_FAILURE: {
      return state
    }

    case ADD_COVER_SLIDE_SUCCESS: {
      return update(state, {
        slides: {
          $push: [action.payload.slide],
        },
      })
    }

    case UPDATE_COVER_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_COVER_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_COVER_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_TEXT_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_TEXT_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_TEXT_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_VIDEO_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_VIDEO_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_VIDEO_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: { $set: action.payload.slide },
        },
      })
    }

    case UPDATE_RENT_COMPARABLE_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_RENT_COMPARABLE_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_RENT_COMPARABLE_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_SALE_COMPARABLE_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_SALE_COMPARABLE_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_SALE_COMPARABLE_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_RENT_LISTING_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_RENT_LISTING_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_RENT_LISTING_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_SALE_LISTING_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_SALE_LISTING_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_SALE_LISTING_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_DOCUMENT_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_DOCUMENT_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_DOCUMENT_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_PHOTO_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_PHOTO_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_PHOTO_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_LOCATION_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_LOCATION_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_LOCATION_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_FINANCIAL_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_FINANCIAL_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_FINANCIAL_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: { $set: action.payload.slide },
        },
      })
    }

    case UPDATE_PURCHASE_COST_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_PURCHASE_COST_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_PURCHASE_COST_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: { $set: action.payload.slide },
        },
      })
    }

    case UPDATE_DEVELOPMENT_COST_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_DEVELOPMENT_COST_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_DEVELOPMENT_COST_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: { $set: action.payload.slide },
        },
      })
    }

    case UPDATE_CASH_FLOW_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_CASH_FLOW_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_CASH_FLOW_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: { $set: action.payload.slide },
        },
      })
    }

    case UPDATE_LOAN_REPAYMENT_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_LOAN_REPAYMENT_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_LOAN_REPAYMENT_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: { $set: action.payload.slide },
        },
      })
    }

    case UPDATE_EQUITY_STRUCTURE_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_EQUITY_STRUCTURE_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_EQUITY_STRUCTURE_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: { $set: action.payload.slide },
        },
      })
    }

    case UPDATE_LOAN_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_LOAN_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_LOAN_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_SHAREHOLDING_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_SHAREHOLDING_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_SHAREHOLDING_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case UPDATE_CASH_FLOW_SUMMARY_SLIDE_REQUEST: {
      return state
    }

    case UPDATE_CASH_FLOW_SUMMARY_SLIDE_FAILURE: {
      return state
    }

    case UPDATE_CASH_FLOW_SUMMARY_SLIDE_SUCCESS: {
      let index = state.slides.findIndex(x => x.id === action.payload.slide.id)

      return update(state, {
        slides: {
          [index]: {$set: action.payload.slide},
        },
      })
    }

    case MOVE_SLIDE_UP_REQUEST: {
      return state
    }

    case MOVE_SLIDE_UP_FAILURE: {
      return state
    }

    case MOVE_SLIDE_UP_SUCCESS: {
      return update(state, {
        slides: {$set: action.payload.slides},
      })
    }

    case MOVE_SLIDE_DOWN_REQUEST: {
      return state
    }

    case MOVE_SLIDE_DOWN_FAILURE: {
      return state
    }

    case MOVE_SLIDE_DOWN_SUCCESS: {
      return update(state, {
        slides: {$set: action.payload.slides},
      })
    }

    case SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_REQUEST: {
      return state
    }

    case SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_FAILURE: {
      return state
    }

    case SHARE_PRESENTATION_WITH_PRIVATE_INVESTOR_SUCCESS: {
      return state
    }

    case UPDATE_PRESENTATION_REQUEST: {
      return state
    }

    case UPDATE_PRESENTATION_FAILURE: {
      return state
    }

    case UPDATE_PRESENTATION_SUCCESS: {
      return update(state, {
        presentation: {
          name: {$set: action.payload.presentation.name},
          brandColour: {$set: action.payload.presentation.brandColour},
          font: {$set: action.payload.presentation.font},
        },
      })
    }

    default:
      return state
  }
}
