import update from 'immutability-helper'
import {FETCH_SUBSCRIPTION_PRODUCTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_SUBSCRIPTION_PRODUCTS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_SUBSCRIPTION_PRODUCTS_FAILURE} from 'redux/actionTypes.js'

const initialState = {
  fetchSubscriptionProductsStatus: 'requested',
  fetchSubscriptionProductsError: '',
  subscriptionProducts: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_PRODUCTS_REQUEST: {
      return update(state, {
        fetchSubscriptionProductsStatus: {$set: 'requested'},
      })
    }

    case FETCH_SUBSCRIPTION_PRODUCTS_FAILURE: {
      return update(state, {
        fetchSubscriptionProductsStatus: {
          $set: 'error',
        },
        fetchSubscriptionProductsError: {$set: action.payload.error},
      })
    }

    case FETCH_SUBSCRIPTION_PRODUCTS_SUCCESS: {
      return update(state, {
        fetchSubscriptionProductsStatus: {$set: 'success'},
        subscriptionProducts: {
          $set: action.payload.subscriptionProducts,
        },
      })
    }

    default:
      return state
  }
}
