import update from 'immutability-helper'
import {FETCH_TEAM_MEMBERS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_TEAM_MEMBERS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_TEAM_MEMBERS_SUCCESS} from 'redux/actionTypes.js'
import {INVITE_PROPERTY_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {INVITE_PROPERTY_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {INVITE_PROPERTY_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {REMOVE_PROPERTY_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {REMOVE_PROPERTY_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {REMOVE_PROPERTY_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {GRANT_USER_ACCESS_TO_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {GRANT_USER_ACCESS_TO_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {GRANT_USER_ACCESS_TO_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchTeamMembersStatus: 'requested',
  fetchTeamMembersError: '',
  teamMembers: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TEAM_MEMBERS_REQUEST: {
      return update(state, {
        fetchTeamMembersStatus: {$set: 'requested'},
      })
    }

    case FETCH_TEAM_MEMBERS_FAILURE: {
      return update(state, {
        fetchTeamMembersStatus: {
          $set: 'error',
        },
        fetchTeamMembersError: {$set: action.payload.error},
      })
    }

    case FETCH_TEAM_MEMBERS_SUCCESS: {
      return update(state, {
        fetchTeamMembersStatus: {$set: 'success'},
        teamMembers: {
          $set: action.payload.teamMembers,
        },
      })
    }

    case INVITE_PROPERTY_INVESTOR_REQUEST: {
      return state
    }

    case INVITE_PROPERTY_INVESTOR_FAILURE: {
      return state
    }

    case INVITE_PROPERTY_INVESTOR_SUCCESS: {
      return update(state, {
        teamMembers: {
          $set: action.payload.teamMembers,
        },
      })
    }

    case REMOVE_PROPERTY_INVESTOR_REQUEST: {
      return state
    }

    case REMOVE_PROPERTY_INVESTOR_FAILURE: {
      return state
    }

    case REMOVE_PROPERTY_INVESTOR_SUCCESS: {
      return update(state, {
        teamMembers: {
          $set: action.payload.teamMembers,
        },
      })
    }

    case GRANT_USER_ACCESS_TO_ACCOUNT_REQUEST: {
      return state
    }

    case GRANT_USER_ACCESS_TO_ACCOUNT_FAILURE: {
      return state
    }

    case GRANT_USER_ACCESS_TO_ACCOUNT_SUCCESS: {
      return update(state, {
        teamMembers: {
          $set: action.payload.teamMembers,
        },
      })
    }

    default:
      return state
  }
}
