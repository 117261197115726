import React from 'react'
import NavbarLink from 'sharedComponents/NavbarLink.js'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
`

function MortgageBrokerProfilePageNavbarLinks() {
  return (
    <Wrapper>
      <NavbarLink
        to="/mortgage-broker-user-details"
        text="Details"
        textColour={props => props.theme.colors.black}
        activeBarColour={props => props.theme.colors.blue}
      />
      <NavbarLink
        to="/mortgage-broker-user-profile"
        text="User Profile"
        textColour={props => props.theme.colors.black}
        activeBarColour={props => props.theme.colors.blue}
      />
      <NavbarLink
        to="/mortgage-broker-team-profile"
        text="Team Profile"
        textColour={props => props.theme.colors.black}
        activeBarColour={props => props.theme.colors.blue}
      />
    </Wrapper>
  )
}

export default MortgageBrokerProfilePageNavbarLinks
