import React from 'react'
import { Plus } from 'react-bootstrap-icons';
import Button from './Button';
import { useDevice } from 'functions';

function AddResourceButton(props) {
  const color = props => props.theme.colors.white
  const backgroundColor = props => props.theme.colors.primary

  const device = useDevice()

  function content() {
    if ([ 'mobile', 'tablet', 'laptop' ].includes(device)) {
      return(
        <Plus />
      )
    } else {
      return(
        <span>
          <Plus />{props.text}
        </span>
      )
    }
  }

  return (
    <Button
      id='add-resource-button'
      onClick={props.onClick}
      content={content()}
      size={props => props.theme.space.s}
      color={color}
      backgroundColor={backgroundColor}
    />
  )
}

export default AddResourceButton
