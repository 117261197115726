import React from 'react'
import CoverDisabled from 'sharedComponents/CoverDisabled'
import StatusBanner from 'sharedComponents/StatusBanner'

const MainRouteContainer = ({children}) => {
  return (
    <React.Fragment>
      {/* <StatusBanner />
      <CoverDisabled>
        {children}
      </CoverDisabled> */}
      {children}
    </React.Fragment>
  )
}

export default MainRouteContainer