import React from 'react'
import StatusWrapper from 'sharedComponents/StatusBanner/StatusContainer.js'
import StatusSubContent from 'sharedComponents/StatusBanner/StatusSubContent.js'
import StatusMobileTextContainer from 'sharedComponents/StatusBanner/StatusMobileTextContainer.js'
import CreateSubscriptionLink from 'sharedComponents/StatusBanner/CreateSubscriptionLink.js'
import {RenderInDevice} from 'functional-components'

const Canceled = () => {
  return (
    <>
      <RenderInDevice devices={['desktop', 'laptop', 'tablet']}>
        <StatusWrapper background="#d45454">
          <StatusSubContent>
            Your subscription has been canceled.
          </StatusSubContent>
          <CreateSubscriptionLink />
        </StatusWrapper>
      </RenderInDevice>

      <RenderInDevice devices={['smallMobile', 'mobile']}>
        <StatusWrapper flexDirection="column" background="#d45454">
          <StatusMobileTextContainer fontSize="0.8rem">
            <StatusSubContent>
              Your subscription has been canceled.
            </StatusSubContent>
          </StatusMobileTextContainer>
          <CreateSubscriptionLink fontSize="0.8rem" paddingLeft={props => props.theme.space.xs} />
        </StatusWrapper>
      </RenderInDevice>
    </>
  )
}

export default Canceled
