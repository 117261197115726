import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {RenderInDevice} from 'functional-components';
import {Divider} from 'pages';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  padding-top: ${props => props.theme.space.xl};
  text-align: center;
`

const Heading = styled.div`
  h1 {
    padding-bottom: ${props => props.theme.space.m};
  }
`

const SubHeading = styled.div`
  padding-bottom: ${props => props.theme.space.xl};

  p {
    font-size: 18px;
    padding-bottom: ${props => props.theme.space.m};
  }

  h2 {
    padding-bottom: ${props => props.theme.space.m};
  }

  padding-left: 5%;
  padding-right: 5%;

  @media ${props => props.theme.devices.desktop} {
    padding-left: 25%;
    padding-right: 25%;
  }
`

const BeforeAndAfter = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  padding-top: ${props => props.theme.space.l};
  padding-bottom: ${props => props.theme.space.l};

  h2 {
    padding-bottom: ${props => props.theme.space.m};
  }

  @media ${props => props.theme.devices.desktop} {
    padding-left: 15%;
    padding-right: 15%;
  }
`

const BeforeRow = styled.div`
  padding-bottom: ${props => props.theme.space.m};

  p {
    font-size: 18px;
  }

  #divider {
    margin-top: ${props => props.theme.space.m};
  }
`

const AfterRow = styled.div`
  padding-bottom: ${props => props.theme.space.m};

  p {
    font-size: 18px;
    padding-bottom: ${props => props.theme.space.m};
  }

  h3 {
    font-size: 18px;
    padding-bottom: ${props => props.theme.space.m};
  }

  #divider {
    margin-bottom: ${props => props.theme.space.m};
  }
`

const ArrowWrapper = styled.div`
  display: flex;
  height: 100%;

  justify-content: center;

  svg {
    font-size: 75px;
  }
`

function SimplifyAndSave(props) {
  return (
    <Wrapper>
      <Heading>
        <h1>Save big with Stacked</h1>
      </Heading>

      <SubHeading>
        <p>
          Stacked replaces a bunch of apps
        </p>

        <p>
          <strong>See how our all-in-one, fixed price compares</strong> to bundling multiple apps together,
          especially as the size of your portfolio and team increases.
        </p>
      </SubHeading>

      <BeforeAndAfter>
        <Row>
          <Col xs='12' sm='5'>
            <h2>Before</h2>

            <BeforeRow>
              <p>
                <strong>File Storage + Calendar (e.g. Google workspace)</strong>
              </p>
              <p>£8.28/month per user</p>
            </BeforeRow>
            <BeforeRow>
              <p><strong>Communication (e.g. Slack)</strong></p>
              <p>£6.30/month per user</p>
            </BeforeRow>
            <BeforeRow>
              <p><strong>Workflow management (e.g. Monday.com)</strong></p>
              <p>£9/month per user</p>
            </BeforeRow>
            <BeforeRow>
              <p><strong>Spreadsheet + Presentations (e.g. Microsoft Office)</strong></p>
              <p>£5.99/month per user</p>
            </BeforeRow>
            <BeforeRow>
              <p><strong>Portfolio management (e.g. Arthur)</strong></p>
              <p>£78/month (increases with additional units)</p>

              <Divider />
            </BeforeRow>

            <BeforeRow>
              <p><strong>£107.57/month</strong> for 1 user</p>
            </BeforeRow>

            <BeforeRow>
              <p><strong>£166.71/month</strong> for 3 users</p>
            </BeforeRow>

            <BeforeRow>
              <p><strong>£225.85/month</strong> for 5 users</p>
            </BeforeRow>

            <BeforeRow>
              <p><strong>£373.70/month</strong> for 10 users</p>
            </BeforeRow>
          </Col>

          <RenderInDevice devices={['tablet', 'laptop', 'desktop']}>
            <Col sm='2'>
              <ArrowWrapper>
                <ArrowForwardIcon />
              </ArrowWrapper>
            </Col>
          </RenderInDevice>

          <Col xs='12' sm='5'>
            <h2>After</h2>

            {/* <AfterRow>
              <p>Stacked</p>
            </AfterRow> */}

            <AfterRow>
              <p>
                <strong>Stacked</strong>
              </p>
              <h3>99 / month for UNLIMITED users</h3>
              <Divider />
              <p>* Save £8.57 / month for 1 user</p>
              <p>* Save £67.71 / month for 3 users</p>
              <p>* Save £126.85 / month for 5 users</p>
              <p>* Save £274.70 / month for 10 users</p>
            </AfterRow>
          </Col>
        </Row>
      </BeforeAndAfter>

      <SubHeading>
        <h2>
          That's a saving of £126.85 per month
        </h2>
        <h2>
          for a 5 person team
        </h2>
      </SubHeading>
    </Wrapper>
  );
};

export default SimplifyAndSave
