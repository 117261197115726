import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_REQUEST } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_SUCCESS } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_FAILURE } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { ARCHIVE_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { ARCHIVE_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { ARCHIVE_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'
import { DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'v2/spas/owned-property/owned-property-accounts/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  ownedPropertyAccounts: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_OWNED_PROPERTY_ACCOUNTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        ownedPropertyAccounts: {
          $set: action.payload.ownedProperty.ownedPropertyAccounts,
        },
      })
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      let index = state.ownedPropertyAccounts.findIndex(
        x => x.id === action.payload.ownedPropertyAccount.id,
      )

      return update(state, {
        ownedPropertyAccounts: {
          [index]: { $set: action.payload.ownedPropertyAccount },
        },
      })
    }

    case ARCHIVE_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return state
    }

    case ARCHIVE_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return state
    }

    case ARCHIVE_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      let index = state.ownedPropertyAccounts.findIndex(x => x.id === action.payload.ownedPropertyAccount.id)

      return update(state, {
        ownedPropertyAccounts: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return state
    }

    case DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return state
    }

    case DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      let index = state.ownedPropertyAccounts.findIndex(x => x.id === action.payload.id)

      return update(state, {
        ownedPropertyAccounts: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
