import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_REQUEST } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_FAILURE } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_ACCOUNTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_ACCOUNTS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_ACCOUNTS_FAILURE } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_CERTIFICATES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_CERTIFICATES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_CERTIFICATES_FAILURE } from 'redux/actionTypes.js'
import { ADD_CERTIFICATE_REQUEST } from 'redux/actionTypes.js'
import { ADD_CERTIFICATE_FAILURE } from 'redux/actionTypes.js'
import { ADD_CERTIFICATE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_CERTIFICATE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_CERTIFICATE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_CERTIFICATE_SUCCESS } from 'redux/actionTypes.js'
import { ARCHIVE_CERTIFICATE_REQUEST } from 'redux/actionTypes.js'
import { ARCHIVE_CERTIFICATE_FAILURE } from 'redux/actionTypes.js'
import { ARCHIVE_CERTIFICATE_SUCCESS } from 'redux/actionTypes.js'
import { UPLOAD_CERTIFICATE_DOCUMENT_FILE_REQUEST } from 'redux/actionTypes.js'
import { UPLOAD_CERTIFICATE_DOCUMENT_FILE_FAILURE } from 'redux/actionTypes.js'
import { UPLOAD_CERTIFICATE_DOCUMENT_FILE_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_INSURANCE_POLICIES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_INSURANCE_POLICIES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_INSURANCE_POLICIES_FAILURE } from 'redux/actionTypes.js'
import { ADD_INSURANCE_POLICY_REQUEST } from 'redux/actionTypes.js'
import { ADD_INSURANCE_POLICY_FAILURE } from 'redux/actionTypes.js'
import { ADD_INSURANCE_POLICY_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_INSURANCE_POLICY_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_INSURANCE_POLICY_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_INSURANCE_POLICY_SUCCESS } from 'redux/actionTypes.js'
import { ARCHIVE_INSURANCE_POLICY_REQUEST } from 'redux/actionTypes.js'
import { ARCHIVE_INSURANCE_POLICY_FAILURE } from 'redux/actionTypes.js'
import { ARCHIVE_INSURANCE_POLICY_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_SMOKE_ALARM_TESTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_SMOKE_ALARM_TESTS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_SMOKE_ALARM_TESTS_FAILURE } from 'redux/actionTypes.js'
import { ADD_SMOKE_ALARM_TEST_REQUEST } from 'redux/actionTypes.js'
import { ADD_SMOKE_ALARM_TEST_FAILURE } from 'redux/actionTypes.js'
import { ADD_SMOKE_ALARM_TEST_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_SMOKE_ALARM_TEST_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_SMOKE_ALARM_TEST_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_SMOKE_ALARM_TEST_SUCCESS } from 'redux/actionTypes.js'
import { ARCHIVE_SMOKE_ALARM_TEST_REQUEST } from 'redux/actionTypes.js'
import { ARCHIVE_SMOKE_ALARM_TEST_FAILURE } from 'redux/actionTypes.js'
import { ARCHIVE_SMOKE_ALARM_TEST_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_PHOTOS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_PHOTOS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_PHOTOS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_LICENCES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_LICENCES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_LICENCES_FAILURE } from 'redux/actionTypes.js'
import { ADD_LICENCE_REQUEST } from 'redux/actionTypes.js'
import { ADD_LICENCE_FAILURE } from 'redux/actionTypes.js'
import { ADD_LICENCE_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_LICENCE_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_LICENCE_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_LICENCE_SUCCESS } from 'redux/actionTypes.js'
import { ARCHIVE_LICENCE_REQUEST } from 'redux/actionTypes.js'
import { ARCHIVE_LICENCE_FAILURE } from 'redux/actionTypes.js'
import { ARCHIVE_LICENCE_SUCCESS } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_PHOTOS_REQUEST } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_PHOTOS_FAILURE } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_PHOTOS_SUCCESS } from 'redux/actionTypes.js'
import { UPLOAD_OWNED_PROPERTY_PHOTOS_REQUEST } from 'redux/actionTypes.js'
import { UPLOAD_OWNED_PROPERTY_PHOTOS_FAILURE } from 'redux/actionTypes.js'
import { UPLOAD_OWNED_PROPERTY_PHOTOS_SUCCESS } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_PHOTOS_REQUEST } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_PHOTOS_FAILURE } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_PHOTOS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_VIDEOS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_VIDEOS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_VIDEO_REQUEST } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_VIDEO_SUCCESS } from 'redux/actionTypes.js'
import { ADD_OWNED_PROPERTY_VIDEO_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_OWNED_PROPERTY_VIDEO_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_OWNED_PROPERTY_VIDEO_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_OWNED_PROPERTY_VIDEO_FAILURE } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_VIDEO_REQUEST } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_VIDEO_SUCCESS } from 'redux/actionTypes.js'
import { DELETE_OWNED_PROPERTY_VIDEO_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  fetchOwnedPropertyStatus: 'requested',
  fetchOwnedPropertyError: '',
  ownedProperty: {},
  fetchOwnedPropertyAccountsStatus: 'requested',
  fetchOwnedPropertyAccountsError: '',
  ownedPropertyAccounts: [],
  fetchCertificatesStatus: 'requested',
  fetchCertificatesError: '',
  certificates: [],
  fetchLicencesStatus: 'requested',
  fetchLicencesError: '',
  licences: [],
  fetchInsurancePoliciesStatus: 'requested',
  fetchInsurancePoliciesError: '',
  insurancePolicies: [],
  fetchSmokeAlarmTestsStatus: 'requested',
  fetchSmokeAlarmTestsError: '',
  smokeAlarmTests: [],
  fetchOwnedPropertyPhotosStatus: 'requested',
  fetchOwnedPropertyPhotosError: '',
  photos: [],
  fetchOwnedPropertyVideosStatus: 'requested',
  fetchOwnedPropertyVideosError: '',
  videos: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_REQUEST: {
      return update(state, {
        fetchOwnedPropertyStatus: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_FAILURE: {
      return update(state, {
        fetchOwnedPropertyStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyError: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyStatus: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
      })
    }

    case FETCH_OWNED_PROPERTY_ACCOUNTS_REQUEST: {
      return update(state, {
        fetchOwnedPropertyAccountsStatus: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_ACCOUNTS_FAILURE: {
      return update(state, {
        fetchOwnedPropertyAccountsStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyAccountsError: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_ACCOUNTS_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyAccountsStatus: { $set: 'success' },
        ownedPropertyAccounts: {
          $set: action.payload.ownedPropertyAccounts,
        },
      })
    }

    case ADD_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return state
    }

    case ADD_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return state
    }

    case ADD_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      return update(state, {
        ownedPropertyAccounts: {
          $push: [action.payload.ownedPropertyAccount],
        },
      })
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      let index = state.ownedPropertyAccounts.findIndex(x => x.id === action.payload.ownedPropertyAccount.id)

      return update(state, {
        ownedPropertyAccounts: {
          [index]: { $set: action.payload.ownedPropertyAccount },
        },
      })
    }

    case DELETE_OWNED_PROPERTY_ACCOUNT_REQUEST: {
      return state
    }

    case DELETE_OWNED_PROPERTY_ACCOUNT_FAILURE: {
      return state
    }

    case DELETE_OWNED_PROPERTY_ACCOUNT_SUCCESS: {
      let index = state.ownedPropertyAccounts.findIndex(x => x.id === action.payload.id)

      return update(state, {
        ownedPropertyAccounts: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_CERTIFICATES_REQUEST: {
      return update(state, {
        fetchCertificatesStatus: { $set: 'requested' },
      })
    }

    case FETCH_CERTIFICATES_FAILURE: {
      return update(state, {
        fetchCertificatesStatus: {
          $set: 'error',
        },
        fetchCertificatesError: { $set: action.payload.error },
      })
    }

    case FETCH_CERTIFICATES_SUCCESS: {
      return update(state, {
        fetchCertificatesStatus: { $set: 'success' },
        certificates: {
          $set: action.payload.certificates,
        },
      })
    }

    case ADD_CERTIFICATE_REQUEST: {
      return state
    }

    case ADD_CERTIFICATE_FAILURE: {
      return state
    }

    case ADD_CERTIFICATE_SUCCESS: {
      return update(state, {
        certificates: {
          $push: [action.payload.certificate],
        },
      })
    }

    case UPDATE_CERTIFICATE_REQUEST: {
      return state
    }

    case UPDATE_CERTIFICATE_FAILURE: {
      return state
    }

    case UPDATE_CERTIFICATE_SUCCESS: {
      let index = state.certificates.findIndex(x => x.id === action.payload.certificate.id)

      return update(state, {
        certificates: {
          [index]: { $set: action.payload.certificate },
        },
      })
    }

    case ARCHIVE_CERTIFICATE_REQUEST: {
      return state
    }

    case ARCHIVE_CERTIFICATE_FAILURE: {
      return state
    }

    case ARCHIVE_CERTIFICATE_SUCCESS: {
      let index = state.certificates.findIndex(x => x.id === action.payload.id)

      return update(state, {
        certificates: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_LICENCES_REQUEST: {
      return update(state, {
        fetchLicencesStatus: { $set: 'requested' },
      })
    }

    case FETCH_LICENCES_FAILURE: {
      return update(state, {
        fetchLicencesStatus: {
          $set: 'error',
        },
        fetchLicencesError: { $set: action.payload.error },
      })
    }

    case FETCH_LICENCES_SUCCESS: {
      return update(state, {
        fetchLicencesStatus: { $set: 'success' },
        licences: {
          $set: action.payload.licences,
        },
      })
    }

    case ADD_LICENCE_REQUEST: {
      return state
    }

    case ADD_LICENCE_FAILURE: {
      return state
    }

    case ADD_LICENCE_SUCCESS: {
      return update(state, {
        licences: {
          $push: [action.payload.licence],
        },
      })
    }

    case UPDATE_LICENCE_REQUEST: {
      return state
    }

    case UPDATE_LICENCE_FAILURE: {
      return state
    }

    case UPDATE_LICENCE_SUCCESS: {
      let index = state.licences.findIndex(x => x.id === action.payload.licence.id)

      return update(state, {
        licences: {
          [index]: { $set: action.payload.licence },
        },
      })
    }

    case ARCHIVE_LICENCE_REQUEST: {
      return state
    }

    case ARCHIVE_LICENCE_FAILURE: {
      return state
    }

    case ARCHIVE_LICENCE_SUCCESS: {
      let index = state.licences.findIndex(x => x.id === action.payload.id)

      return update(state, {
        licences: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_INSURANCE_POLICIES_REQUEST: {
      return update(state, {
        fetchInsurancePoliciesStatus: { $set: 'requested' },
      })
    }

    case FETCH_INSURANCE_POLICIES_FAILURE: {
      return update(state, {
        fetchInsurancePoliciesStatus: {
          $set: 'error',
        },
        fetchInsurancePoliciesError: { $set: action.payload.error }
      })
    }

    case FETCH_INSURANCE_POLICIES_SUCCESS: {
      return update(state, {
        fetchInsurancePoliciesStatus: { $set: 'success' },
        insurancePolicies: {
          $set: action.payload.insurancePolicies
        }
      })
    }

    case ADD_INSURANCE_POLICY_REQUEST: {
      return state
    }

    case ADD_INSURANCE_POLICY_FAILURE: {
      return state
    }

    case ADD_INSURANCE_POLICY_SUCCESS: {
      return update(state, {
        insurancePolicies: {
          $push: [action.payload.insurancePolicy]
        }
      })
    }

    case UPDATE_INSURANCE_POLICY_REQUEST: {
      return state
    }

    case UPDATE_INSURANCE_POLICY_FAILURE: {
      return state
    }

    case UPDATE_INSURANCE_POLICY_SUCCESS: {
      let index = state.insurancePolicies.findIndex(x => x.id === action.payload.insurancePolicy.id)

      return update(state, {
        insurancePolicies: {
          [index]: { $set: action.payload.insurancePolicy },
        },
      })
    }

    case ARCHIVE_INSURANCE_POLICY_REQUEST: {
      return state
    }

    case ARCHIVE_INSURANCE_POLICY_FAILURE: {
      return state
    }

    case ARCHIVE_INSURANCE_POLICY_SUCCESS: {
      let index = state.insurancePolicies.findIndex(x => x.id === action.payload.id)

      return update(state, {
        insurancePolicies: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_SMOKE_ALARM_TESTS_REQUEST: {
      return update(state, {
        fetchSmokeAlarmTestsStatus: { $set: 'requested' },
      })
    }

    case FETCH_SMOKE_ALARM_TESTS_FAILURE: {
      return update(state, {
        fetchSmokeAlarmTestsStatus: {
          $set: 'error',
        },
        fetchSmokeAlarmTestsError: { $set: action.payload.error }
      })
    }

    case FETCH_SMOKE_ALARM_TESTS_SUCCESS: {
      return update(state, {
        fetchSmokeAlarmTestsStatus: { $set: 'success' },
        smokeAlarmTests: {
          $set: action.payload.smokeAlarmTests
        }
      })
    }

    case ADD_SMOKE_ALARM_TEST_REQUEST: {
      return state
    }

    case ADD_SMOKE_ALARM_TEST_FAILURE: {
      return state
    }

    case ADD_SMOKE_ALARM_TEST_SUCCESS: {
      return update(state, {
        smokeAlarmTests: {
          $push: [action.payload.smokeAlarmTest]
        }
      })
    }

    case UPDATE_SMOKE_ALARM_TEST_REQUEST: {
      return state
    }

    case UPDATE_SMOKE_ALARM_TEST_FAILURE: {
      return state
    }

    case UPDATE_SMOKE_ALARM_TEST_SUCCESS: {
      let index = state.smokeAlarmTests.findIndex(x => x.id === action.payload.smokeAlarmTest.id)

      return update(state, {
        smokeAlarmTests: {
          [index]: { $set: action.payload.smokeAlarmTest },
        },
      })
    }

    case ARCHIVE_SMOKE_ALARM_TEST_REQUEST: {
      return state
    }

    case ARCHIVE_SMOKE_ALARM_TEST_FAILURE: {
      return state
    }

    case ARCHIVE_SMOKE_ALARM_TEST_SUCCESS: {
      let index = state.smokeAlarmTests.findIndex(x => x.id === action.payload.id)

      return update(state, {
        smokeAlarmTests: {
          $splice: [[index, 1]],
        },
      })
    }

    case UPLOAD_CERTIFICATE_DOCUMENT_FILE_REQUEST: {
      return state
    }

    case UPLOAD_CERTIFICATE_DOCUMENT_FILE_FAILURE: {
      return state
    }

    case UPLOAD_CERTIFICATE_DOCUMENT_FILE_SUCCESS: {
      let index = state.certificates.findIndex(
        x => x.id === action.payload.certificate.id,
      )

      return update(state, {
        certificates: {
          [index]: {
            document: {
              $set: action.payload.document
            }
          },
        },
      })
    }

    case FETCH_OWNED_PROPERTY_PHOTOS_REQUEST: {
      return update(state, {
        fetchOwnedPropertyPhotosStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_PHOTOS_FAILURE: {
      return update(state, {
        fetchOwnedPropertyPhotosStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyPhotosError: {$set: action.payload.error},
      })
    }

    case FETCH_OWNED_PROPERTY_PHOTOS_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyPhotosStatus: {$set: 'success'},
        photos: {
          $set: action.payload.photos,
        },
      })
    }

    case ADD_OWNED_PROPERTY_PHOTOS_REQUEST: {
      return state
    }

    case ADD_OWNED_PROPERTY_PHOTOS_FAILURE: {
      return state
    }

    case ADD_OWNED_PROPERTY_PHOTOS_SUCCESS: {
      return update(state, {
        photos: {
          $push: [action.payload.photo],
        },
      })
    }

    case UPLOAD_OWNED_PROPERTY_PHOTOS_REQUEST: {
      return state
    }

    case UPLOAD_OWNED_PROPERTY_PHOTOS_FAILURE: {
      return state
    }

    case UPLOAD_OWNED_PROPERTY_PHOTOS_SUCCESS: {
      let index = state.photos.findIndex(x => x.id === action.payload.photo.id)

      return update(state, {
        photos: {
          [index]: {$set: action.payload.photo},
        },
      })
    }

    case DELETE_OWNED_PROPERTY_PHOTOS_REQUEST: {
      return state
    }

    case DELETE_OWNED_PROPERTY_PHOTOS_FAILURE: {
      return state
    }

    case DELETE_OWNED_PROPERTY_PHOTOS_SUCCESS: {
      let index = state.photos.findIndex(x => x.id === action.payload.id)

      return update(state, {
        photos: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_OWNED_PROPERTY_VIDEOS_REQUEST: {
      return update(state, {
        fetchOwnedPropertyVideosStatus: {$set: 'requested'},
      })
    }

    case FETCH_OWNED_PROPERTY_VIDEOS_FAILURE: {
      return update(state, {
        fetchOwnedPropertyVideosStatus: {
          $set: 'error',
        },
        fetchOwnedPropertyVideosError: {$set: action.payload.error},
      })
    }

    case FETCH_OWNED_PROPERTY_VIDEOS_SUCCESS: {
      return update(state, {
        fetchOwnedPropertyVideosStatus: {$set: 'success'},
        videos: {
          $set: action.payload.videos,
        },
      })
    }

    case ADD_OWNED_PROPERTY_VIDEO_REQUEST: {
      return state
    }

    case ADD_OWNED_PROPERTY_VIDEO_FAILURE: {
      return state
    }

    case ADD_OWNED_PROPERTY_VIDEO_SUCCESS: {
      return update(state, {
        videos: {
          $push: [action.payload.video],
        },
      })
    }

    case UPDATE_OWNED_PROPERTY_VIDEO_REQUEST: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_VIDEO_FAILURE: {
      return state
    }

    case UPDATE_OWNED_PROPERTY_VIDEO_SUCCESS: {
      let index = state.videos.findIndex(
        x => x.id === action.payload.video.id,
      )

      return update(state, {
        videos: {
          [index]: {$set: action.payload.video},
        },
      })
    }

    case DELETE_OWNED_PROPERTY_VIDEO_REQUEST: {
      return state
    }

    case DELETE_OWNED_PROPERTY_VIDEO_FAILURE: {
      return state
    }

    case DELETE_OWNED_PROPERTY_VIDEO_SUCCESS: {
      let index = state.videos.findIndex(x => x.id === action.payload.id)

      return update(state, {
        videos: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
