import update from 'immutability-helper'
import { FETCH_SALE_LISTING_REQUEST } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { FETCH_SALE_LISTING_SUCCESS } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { FETCH_SALE_LISTING_FAILURE } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { UPDATE_SALE_LISTING_REQUEST } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { UPDATE_SALE_LISTING_SUCCESS } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'
import { UPDATE_SALE_LISTING_FAILURE } from 'v2/spas/prospective-property/sale-listings/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  saleListing: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SALE_LISTING_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_SALE_LISTING_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_SALE_LISTING_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        saleListing: {
          $set: action.payload.saleListing,
        }
      })
    }

    case UPDATE_SALE_LISTING_REQUEST: {
      return update(state, {
        updateSaleListingStatus: { $set: 'requested' },
      })
    }

    case UPDATE_SALE_LISTING_FAILURE: {
      return update(state, {
        updateSaleListingStatus: {
          $set: 'error',
        },
        updateSaleListingError: { $set: action.payload.error },
      })
    }

    case UPDATE_SALE_LISTING_SUCCESS: {
      return update(state, {
        updateSaleListingStatus: { $set: 'success' },
        saleListing: {
          $set: action.payload.saleListing,
        }
      })
    }

    default:
      return state
  }
}