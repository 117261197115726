import React from 'react'
import {SnackbarProvider} from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import {usePrimaryColor} from 'sharedComponents/usePrimaryColor'

const useStyles = makeStyles({
  success: {backgroundColor: props => props.backgroundColor},
  error: {backgroundColor: '#EC0B0B !important'},
  warning: {},
  info: {},
  button: {color: 'inherit'},
})

const Snackbar = ({variant, children}) => {
  const backgroundColor = usePrimaryColor()
  const classes = useStyles({backgroundColor})
  const notistackRef = React.createRef()

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={5000}
      classes={{variantSuccess: classes.success}}
      variant={variant || 'success'}
      ref={notistackRef}
      action={key => (
        <IconButton
          aria-label="dismiss-snackbar"
          size="small"
          onClick={onClickDismiss(key)}
          classes={{root: classes.button}}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export default Snackbar
