import update from 'immutability-helper'
import {FETCH_ACCOUNT_USER_REQUEST} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_FAILURE} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PRIVATE_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PRIVATE_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PRIVATE_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROPERTY_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROPERTY_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROPERTY_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_FAILURE} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_TENANT_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_TENANT_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_TENANT_FAILURE} from 'redux/actionTypes.js'
// import {DELETE_TENANT_REQUEST} from 'redux/actionTypes.js'
// import {DELETE_TENANT_SUCCESS} from 'redux/actionTypes.js'
// import {DELETE_TENANT_FAILURE} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_GUARANTOR_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_GUARANTOR_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_GUARANTOR_FAILURE} from 'redux/actionTypes.js'
// import {DELETE_GUARANTOR_REQUEST} from 'redux/actionTypes.js'
// import {DELETE_GUARANTOR_SUCCESS} from 'redux/actionTypes.js'
// import {DELETE_GUARANTOR_FAILURE} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_OWNED_PROPERTIES_REQUEST} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_OWNED_PROPERTIES_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_OWNED_PROPERTIES_FAILURE} from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_BANK_STATEMENTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_BANK_STATEMENTS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_BANK_STATEMENTS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_TAX_DOCUMENTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_TAX_DOCUMENTS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_TAX_DOCUMENTS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_CREDIT_REPORTS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_CREDIT_REPORTS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_CREDIT_REPORTS_FAILURE } from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_REQUEST} from 'redux/actionTypes.js'
import {UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_FAILURE} from 'redux/actionTypes.js'
import {UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {ARCHIVE_ACCOUNT_USER_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {ARCHIVE_ACCOUNT_USER_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {ARCHIVE_ACCOUNT_USER_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchAccountUserStatus: 'requested',
  fetchAccountUserError: '',
  accountUser: {},
  fetchAccountUserOwnedPropertiesStatus: 'requested',
  fetchAccountUserOwnedPropertiesError: '',
  ownedProperties: [],
  fetchAccountUserBankStatementsStatus: 'requested',
  fetchAccountUserBankStatementsError: '',
  bankStatements: [],
  fetchAccountUserIdentityDocumentsStatus: 'requested',
  fetchAccountUserIdentityDocumentsError: '',
  identityDocuments: [],
  fetchAccountUserTaxDocumentsStatus: 'requested',
  fetchAccountUserTaxDocumentsError: '',
  taxDocuments: [],
  fetchAccountUserCreditReportsStatus: 'requested',
  fetchAccountUserCreditReportsError: '',
  creditReports: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_USER_REQUEST: {
      return update(state, {
        fetchAccountUserStatus: {$set: 'requested'},
      })
    }

    case FETCH_ACCOUNT_USER_FAILURE: {
      return update(state, {
        fetchAccountUserStatus: {
          $set: 'error',
        },
        fetchAccountUserError: {$set: action.payload.error},
      })
    }

    case FETCH_ACCOUNT_USER_SUCCESS: {
      return update(state, {
        fetchAccountUserStatus: {$set: 'success'},
        accountUser: {
          $set: action.payload.accountUser,
        },
      })
    }

    case FETCH_ACCOUNT_USER_OWNED_PROPERTIES_REQUEST: {
      return update(state, {
        fetchAccountUserOwnedPropertiesStatus: {$set: 'requested'},
      })
    }

    case FETCH_ACCOUNT_USER_OWNED_PROPERTIES_FAILURE: {
      return update(state, {
        fetchAccountUserOwnedPropertiesStatus: {
          $set: 'error',
        },
        fetchAccountUserOwnedPropertiesError: {$set: action.payload.error},
      })
    }

    case FETCH_ACCOUNT_USER_OWNED_PROPERTIES_SUCCESS: {
      return update(state, {
        fetchAccountUserOwnedPropertiesStatus: {$set: 'success'},
        ownedProperties: {
          $set: action.payload.ownedProperties,
        },
      })
    }

    case MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_SUCCESS: {
      return update(state, {
        accountUser: {
          privateInvestor: {
            $set: action.payload.accountUser.privateInvestor,
          },
        },
      })
    }

    case DELETE_PRIVATE_INVESTOR_REQUEST: {
      return state
    }

    case DELETE_PRIVATE_INVESTOR_FAILURE: {
      return state
    }

    case DELETE_PRIVATE_INVESTOR_SUCCESS: {
      return update(state, {
        accountUser: {
          privateInvestor: {
            $set: action.payload.accountUser.privateInvestor,
          },
        },
      })
    }

    case MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_SUCCESS: {
      return update(state, {
        accountUser: {
          propertyInvestor: {
            $set: action.payload.accountUser.propertyInvestor,
          },
        },
      })
    }

    case DELETE_PROPERTY_INVESTOR_REQUEST: {
      return state
    }

    case DELETE_PROPERTY_INVESTOR_FAILURE: {
      return state
    }

    case DELETE_PROPERTY_INVESTOR_SUCCESS: {
      return update(state, {
        accountUser: {
          propertyInvestor: {
            $set: action.payload.accountUser.propertyInvestor,
          },
        },
      })
    }

    case MAKE_ACCOUNT_USER_A_TENANT_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_TENANT_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_TENANT_SUCCESS: {
      return update(state, {
        accountUser: {
          tenant: {
            $set: action.payload.accountUser.tenant,
          },
        },
      })
    }

    // case DELETE_TENANT_REQUEST: {
    //   return state
    // }

    // case DELETE_TENANT_FAILURE: {
    //   return state
    // }

    // case DELETE_TENANT_SUCCESS: {
    //   return update(state, {
    //     accountUser: {
    //       tenant: {
    //         $set: action.payload.accountUser.tenant,
    //       },
    //     },
    //   })
    // }

    case MAKE_ACCOUNT_USER_A_GUARANTOR_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_GUARANTOR_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_GUARANTOR_SUCCESS: {
      return update(state, {
        accountUser: {
          guarantor: {
            $set: action.payload.accountUser.guarantor,
          },
        },
      })
    }

    // case DELETE_GUARANTOR_REQUEST: {
    //   return state
    // }

    // case DELETE_GUARANTOR_FAILURE: {
    //   return state
    // }

    // case DELETE_GUARANTOR_SUCCESS: {
    //   return update(state, {
    //     accountUser: {
    //       guarantor: {
    //         $set: action.payload.accountUser.guarantor,
    //       },
    //     },
    //   })
    // }

    case UPDATE_ACCOUNT_USER_REQUEST: {
      return state
    }

    case UPDATE_ACCOUNT_USER_FAILURE: {
      return state
    }

    case UPDATE_ACCOUNT_USER_SUCCESS: {
      return update(state, {
        accountUser: {
          $set: action.payload.accountUser,
        },
      })
    }

    case FETCH_ACCOUNT_USER_BANK_STATEMENTS_REQUEST: {
      return update(state, {
        fetchAccountUserBankStatementsStatus: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_BANK_STATEMENTS_FAILURE: {
      return update(state, {
        fetchAccountUserBankStatementsStatus: {
          $set: 'error',
        },
        fetchAccountUserBankStatementsError: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_BANK_STATEMENTS_SUCCESS: {
      return update(state, {
        fetchAccountUserBankStatementsStatus: { $set: 'success' },
        bankStatements: {
          $set: action.payload.documents,
        },
      })
    }

    case FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_REQUEST: {
      return update(state, {
        fetchAccountUserIdentityDocumentsStatus: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_FAILURE: {
      return update(state, {
        fetchAccountUserIdentityDocumentsStatus: {
          $set: 'error',
        },
        fetchAccountUserIdentityDocumentsError: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_IDENTITY_DOCUMENTS_SUCCESS: {
      return update(state, {
        fetchAccountUserIdentityDocumentsStatus: { $set: 'success' },
        identityDocuments: {
          $set: action.payload.documents,
        },
      })
    }

    case FETCH_ACCOUNT_USER_TAX_DOCUMENTS_REQUEST: {
      return update(state, {
        fetchAccountUserTaxDocumentsStatus: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_TAX_DOCUMENTS_FAILURE: {
      return update(state, {
        fetchAccountUserTaxDocumentsStatus: {
          $set: 'error',
        },
        fetchAccountUserTaxDocumentsError: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_TAX_DOCUMENTS_SUCCESS: {
      return update(state, {
        fetchAccountUserTaxDocumentsStatus: { $set: 'success' },
        taxDocuments: {
          $set: action.payload.documents,
        },
      })
    }

    case FETCH_ACCOUNT_USER_CREDIT_REPORTS_REQUEST: {
      return update(state, {
        fetchAccountUserCreditReportsStatus: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_CREDIT_REPORTS_FAILURE: {
      return update(state, {
        fetchAccountUserCreditReportsStatus: {
          $set: 'error',
        },
        fetchAccountUserCreditReportsError: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_CREDIT_REPORTS_SUCCESS: {
      return update(state, {
        fetchAccountUserCreditReportsStatus: { $set: 'success' },
        creditReports: {
          $set: action.payload.documents,
        },
      })
    }

    case ADD_ACCOUNT_USER_DOCUMENT_REQUEST: {
      return state
    }

    case ADD_ACCOUNT_USER_DOCUMENT_FAILURE: {
      return state
    }

    case ADD_ACCOUNT_USER_DOCUMENT_SUCCESS: {
      let document = action.payload.document
      let type = document.type

      if (type === 'bank_statement') {
        return update(state, {
          bankStatements: {
            $push: [action.payload.document],
          },
        })
      } else if (type === 'identity_document') {
        return update(state, {
          identityDocuments: {
            $push: [action.payload.document],
          },
        })
      } else if (type === 'tax_document') {
        return update(state, {
          taxDocuments: {
            $push: [action.payload.document],
          },
        })
      } else if (type === 'credit_report') {
        return update(state, {
          creditReports: {
            $push: [action.payload.document],
          },
        })
      }
    }

    case UPDATE_ACCOUNT_USER_DOCUMENT_REQUEST: {
      return state
    }

    case UPDATE_ACCOUNT_USER_DOCUMENT_FAILURE: {
      return state
    }

    case UPDATE_ACCOUNT_USER_DOCUMENT_SUCCESS: {
      let document = action.payload.document
      let type = document.type

      if (type === 'bank_statement') {
        let index = state.bankStatements.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          bankStatements: {
            [index]: {$set: action.payload.document},
          },
        })
      } else if (type === 'identity_document') {
        let index = state.identityDocuments.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          identityDocuments: {
            [index]: { $set: action.payload.document },
          },
        })
      } else if (type === 'tax_document') {
        let index = state.taxDocuments.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          taxDocuments: {
            [index]: { $set: action.payload.document },
          },
        })
      } else if (type === 'credit_report') {
        let index = state.creditReports.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          creditReports: {
            [index]: { $set: action.payload.document },
          },
        })
      }
    }

    case UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_REQUEST: {
      return state
    }

    case UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_FAILURE: {
      return state
    }

    case UPLOAD_ACCOUNT_USER_DOCUMENT_FILE_SUCCESS: {
      let document = action.payload.document
      let type = document.type

      if (type === 'bank_statement') {
        let index = state.bankStatements.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          bankStatements: {
            [index]: { $set: action.payload.document },
          },
        })
      } else if (type === 'identity_document') {
        let index = state.identityDocuments.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          identityDocuments: {
            [index]: { $set: action.payload.document },
          },
        })
      } else if (type === 'tax_document') {
        let index = state.taxDocuments.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          taxDocuments: {
            [index]: { $set: action.payload.document },
          },
        })
      } else if (type === 'credit_report') {
        let index = state.creditReports.findIndex(
          x => x.id === action.payload.document.id,
        )

        return update(state, {
          creditReports: {
            [index]: { $set: action.payload.document },
          },
        })
      }
    }

    case ARCHIVE_ACCOUNT_USER_DOCUMENT_REQUEST: {
      return state
    }

    case ARCHIVE_ACCOUNT_USER_DOCUMENT_FAILURE: {
      return state
    }

    case ARCHIVE_ACCOUNT_USER_DOCUMENT_SUCCESS: {
      return state
    }

    default:
      return state
    }
}
