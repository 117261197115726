import React from 'react'
import styled from 'styled-components'
import { Button } from 'buttons'
import {connect} from 'react-redux'
import {grantUserConsent} from 'redux/actions/profile/grantUserConsent'
import {useConsented} from 'sharedComponents/useConsented.js'
import {useSnackbar} from 'notistack'

const Wrapper = styled.div``

const GrantConsentButtonWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 25px;
`

function GrantUserConsent(props) {
  const {enqueueSnackbar} = useSnackbar()
  const consented = useConsented()

  function grantUserConsent() {
    props.grantUserConsent('process_property_investment_data', handleResponse)
  }

  function handleResponse(response) {
    if (response.status === 'success') {
      enqueueSnackbar('Your consent has been granted succefully!')
      window.location.reload()
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      window.setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  if (!consented) {
    return (
      <Wrapper>
        <h6>Data processing consents</h6>
        <p>
          You have NOT granted consent for us to process data in connection with
          your property investment activities.
        </p>
        <p>
          In order to access all property investment activities on our platform
          - you need to grant consent.
        </p>
        <p>You can withdraw this consent at any time.</p>
        <GrantConsentButtonWrapper>
          <Button onClick={grantUserConsent} content="Grant Consent" />
        </GrantConsentButtonWrapper>
      </Wrapper>
    )
  } else {
    return null
  }
}

const mapDispatchToProps = {
  grantUserConsent: grantUserConsent,
}

export default connect(null, mapDispatchToProps)(GrantUserConsent)
