import update from 'immutability-helper'
import { FETCH_VIDEO_REQUEST } from 'v2/spas/prospective-property/videos/redux/action-types.tsx'
import { FETCH_VIDEO_SUCCESS } from 'v2/spas/prospective-property/videos/redux/action-types.tsx'
import { FETCH_VIDEO_FAILURE } from 'v2/spas/prospective-property/videos/redux/action-types.tsx'
import { UPDATE_VIDEO_REQUEST } from 'v2/spas/prospective-property/videos/redux/action-types.tsx'
import { UPDATE_VIDEO_SUCCESS } from 'v2/spas/prospective-property/videos/redux/action-types.tsx'
import { UPDATE_VIDEO_FAILURE } from 'v2/spas/prospective-property/videos/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  video: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_VIDEO_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_VIDEO_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_VIDEO_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        video: {
          $set: action.payload.video,
        }
      })
    }

    case UPDATE_VIDEO_REQUEST: {
      return update(state, {
        updateVideoStatus: { $set: 'requested' },
      })
    }

    case UPDATE_VIDEO_FAILURE: {
      return update(state, {
        updateVideoStatus: {
          $set: 'error',
        },
        updateVideoError: { $set: action.payload.error },
      })
    }

    case UPDATE_VIDEO_SUCCESS: {
      return update(state, {
        updateVideoStatus: { $set: 'success' },
        video: {
          $set: action.payload.video,
        }
      })
    }

    default:
      return state
  }
}