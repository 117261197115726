import {MANAGE_SUBSCRIPTION_REQUEST} from 'redux/actionTypes.js'
import {MANAGE_SUBSCRIPTION_SUCCESS} from 'redux/actionTypes.js'
import {MANAGE_SUBSCRIPTION_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function manageSubscription(handleResponse) {
  return function (dispatch) {
    dispatch(manageSubscriptionRequest())

    let variables = {}

    let query = `
      mutation {
        manageSubscription {
          portalSessionUrl
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(manageSubscriptionFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(
            manageSubscriptionSuccess(
              res.data.data.manageSubscription.portalSessionUrl,
            ),
          )
          handleResponse({
            status: 'success',
            portalSessionUrl:
              res.data.data.manageSubscription.portalSessionUrl,
          })
        }
      })
      .catch(error => {
        dispatch(manageSubscriptionFailure(error.message))
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const manageSubscriptionRequest = () => ({
  type: MANAGE_SUBSCRIPTION_REQUEST,
})

export const manageSubscriptionSuccess = portalSessionUrl => ({
  type: MANAGE_SUBSCRIPTION_SUCCESS,
  payload: {
    portalSessionUrl: portalSessionUrl,
  },
})

export const manageSubscriptionFailure = error => ({
  type: MANAGE_SUBSCRIPTION_FAILURE,
  payload: {
    error: error,
  },
})
