import {UPDATE_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function updateAccount(id, values, handleResponse) {
  return function (dispatch) {
    dispatch(updateAccountRequest())

    let variables = {
      id: id,
      name: values.accountName,
    }

    let query = `
      mutation($id: String!, $name: String!) {
        updateAccount(
          id: $id
          name: $name
        ) {
          id
          name
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(updateAccountFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(updateAccountSuccess(res.data.data.updateAccount))
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(updateAccountFailure(error.message))
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const updateAccountRequest = () => ({
  type: UPDATE_ACCOUNT_REQUEST,
})

export const updateAccountSuccess = account => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: {
    account: account,
  },
})

export const updateAccountFailure = error => ({
  type: UPDATE_ACCOUNT_FAILURE,
  payload: {
    error: error,
  },
})
