import update from 'immutability-helper'
import {SESSION_REQUEST} from 'redux/actionTypes.js'
import {SESSION_FAILURE} from 'redux/actionTypes.js'
import {SESSION_SUCCESS} from 'redux/actionTypes.js'
import {ACCOUNT_SEARCH_REQUEST} from 'redux/actionTypes.js'
import {ACCOUNT_SEARCH_FAILURE} from 'redux/actionTypes.js'
import {ACCOUNT_SEARCH_SUCCESS} from 'redux/actionTypes.js'
import {CONFIRM_EMAIL_REQUEST} from 'redux/actionTypes.js'
import {CONFIRM_EMAIL_FAILURE} from 'redux/actionTypes.js'
import {CONFIRM_EMAIL_SUCCESS} from 'redux/actionTypes.js'
import {CREATE_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {CREATE_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {CREATE_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {LOGIN_REQUEST} from 'redux/actionTypes.js'
import {LOGIN_FAILURE} from 'redux/actionTypes.js'
import {LOGIN_SUCCESS} from 'redux/actionTypes.js'
import {LOGOUT_REQUEST} from 'redux/actionTypes.js'
import {LOGOUT_FAILURE} from 'redux/actionTypes.js'
import {LOGOUT_SUCCESS} from 'redux/actionTypes.js'
import {PRE_REGISTER_REQUEST} from 'redux/actionTypes.js'
import {PRE_REGISTER_SUCCESS} from 'redux/actionTypes.js'
import {PRE_REGISTER_FAILURE} from 'redux/actionTypes.js'
import {REQUEST_TO_JOIN_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {REQUEST_TO_JOIN_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {REQUEST_TO_JOIN_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {RESET_PASSWORD_REQUEST_REQUEST} from 'redux/actionTypes.js'
import {RESET_PASSWORD_REQUEST_FAILURE} from 'redux/actionTypes.js'
import {RESET_PASSWORD_REQUEST_SUCCESS} from 'redux/actionTypes.js'
import {RESET_PASSWORD_REQUEST} from 'redux/actionTypes.js'
import {RESET_PASSWORD_FAILURE} from 'redux/actionTypes.js'
import {RESET_PASSWORD_SUCCESS} from 'redux/actionTypes.js'
import {SIGN_UP_REQUEST} from 'redux/actionTypes.js'
import {SIGN_UP_FAILURE} from 'redux/actionTypes.js'
import {SIGN_UP_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_PROMOTION_CODE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_PROMOTION_CODE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_PROMOTION_CODE_SUCCESS} from 'redux/actionTypes.js'
import { SIGN_IN_WITH_MAGIC_LINK_REQUEST } from 'redux/actionTypes.js'
import { SIGN_IN_WITH_MAGIC_LINK_FAILURE } from 'redux/actionTypes.js'
import { SIGN_IN_WITH_MAGIC_LINK_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  sessionStatus: 'requested',
  sessionError: '',
  sessionExpiring: false,
  fetchPromotionCodeStatus: 'requested',
  fetchPromotionCodeError: '',
  promotionCode: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SESSION_REQUEST: {
      return update(state, {
        sessionStatus: {$set: 'requested'},
      })
    }

    case SESSION_FAILURE: {
      return update(state, {
        sessionStatus: {
          $set: 'error',
        },
        sessionError: {$set: action.payload.error},
      })
    }

    case SESSION_SUCCESS: {
      return update(state, {
        sessionStatus: {$set: 'success'},
        sessionExpiring: {
          $set: action.payload.sessionExpiring,
        },
      })
    }

    case LOGIN_REQUEST: {
      return state
    }

    case LOGIN_FAILURE: {
      return state
    }

    case LOGIN_SUCCESS: {
      return state
    }

    case LOGOUT_REQUEST: {
      return state
    }

    case LOGOUT_FAILURE: {
      return state
    }

    case LOGOUT_SUCCESS: {
      return state
    }

    case RESET_PASSWORD_REQUEST_REQUEST: {
      return state
    }

    case RESET_PASSWORD_REQUEST_FAILURE: {
      return state
    }

    case RESET_PASSWORD_REQUEST_SUCCESS: {
      return state
    }

    case RESET_PASSWORD_REQUEST: {
      return state
    }

    case RESET_PASSWORD_FAILURE: {
      return state
    }

    case RESET_PASSWORD_SUCCESS: {
      return state
    }

    case PRE_REGISTER_REQUEST: {
      return state
    }

    case PRE_REGISTER_FAILURE: {
      return state
    }

    case PRE_REGISTER_SUCCESS: {
      return state
    }

    case SIGN_UP_REQUEST: {
      return state
    }

    case SIGN_UP_FAILURE: {
      return state
    }

    case SIGN_UP_SUCCESS: {
      return state
    }

    case CONFIRM_EMAIL_REQUEST: {
      return state
    }

    case CONFIRM_EMAIL_FAILURE: {
      return state
    }

    case CONFIRM_EMAIL_SUCCESS: {
      return state
    }

    case CREATE_ACCOUNT_REQUEST: {
      return state
    }

    case CREATE_ACCOUNT_FAILURE: {
      return state
    }

    case CREATE_ACCOUNT_SUCCESS: {
      return state
    }

    case ACCOUNT_SEARCH_REQUEST: {
      return state
    }

    case ACCOUNT_SEARCH_FAILURE: {
      return state
    }

    case ACCOUNT_SEARCH_SUCCESS: {
      return Object.assign({}, state, {
        accountSearchResults: action.payload.accounts,
      })
    }

    case REQUEST_TO_JOIN_ACCOUNT_REQUEST: {
      return state
    }

    case REQUEST_TO_JOIN_ACCOUNT_FAILURE: {
      return state
    }

    case REQUEST_TO_JOIN_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, {
        networkRequestStatus: 'success',
      })
    }

    case FETCH_PROMOTION_CODE_REQUEST: {
      return update(state, {
        fetchPromotionCodeStatus: { $set: 'requested' },
      })
    }

    case FETCH_PROMOTION_CODE_FAILURE: {
      return update(state, {
        fetchPromotionCodeStatus: {
          $set: 'error',
        },
        fetchPromotionCodeError: { $set: action.payload.error },
      })
    }

    case FETCH_PROMOTION_CODE_SUCCESS: {
      return update(state, {
        fetchPromotionCodeStatus: { $set: 'success' },
        promotionCode: {
          $set: action.payload.promotionCode,
        },
      })
    }

    case SIGN_IN_WITH_MAGIC_LINK_REQUEST: {
      return state
    }

    case SIGN_IN_WITH_MAGIC_LINK_FAILURE: {
      return state
    }

    case SIGN_IN_WITH_MAGIC_LINK_SUCCESS: {
      return state
    }

    default:
      return state
  }
}
