import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {SubmitButton} from 'buttons'
import TextInput from 'sharedComponents/Forms/TextInput.js'
import {updateAccount} from 'redux/actions/profile/updateAccount'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {useSnackbar} from 'notistack'
import {useCurrentAccount} from 'sharedComponents/useCurrentAccount'

const Wrapper = styled.div``

const FormSchema = Yup.object().shape({
  accountName: Yup.string().required('Required field *'),
})

function AccountForm(props) {
  const {enqueueSnackbar} = useSnackbar()

  const currentAccount = useCurrentAccount()

  function handleUpdate(response) {
    if (response.status === 'success') {
      props.showModal(false)
      enqueueSnackbar('Account was successfully updated!')
    } else if (response.status === 'error') {
      props.showModal(false)
      enqueueSnackbar(response.error, {variant: 'error'})
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          accountName: currentAccount.name,
        }}
        validationSchema={FormSchema}
        onSubmit={(values, {setSubmitting}) => {
          props.updateAccount(currentAccount.id, values, handleUpdate)
        }}
      >
        {({isSubmitting, setFieldValue, values, setValues}) => (
          <Form>
            <TextInput label="Team name" name="accountName" type="string" />

            <SubmitButton
              content="Update Account Details"
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  updateAccount: updateAccount,
}

export default connect(null, mapDispatchToProps)(AccountForm)
