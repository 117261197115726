export const FETCH_TENANCY_TENANTS_REQUEST = 'FETCH_TENANCY_TENANTS_REQUEST'
export const FETCH_TENANCY_TENANTS_SUCCESS = 'FETCH_TENANCY_TENANTS_SUCCESS'
export const FETCH_TENANCY_TENANTS_FAILURE = 'FETCH_TENANCY_TENANTS_FAILURE'

export const CREATE_TENANT_REQUEST = 'CREATE_TENANT_REQUEST'
export const CREATE_TENANT_SUCCESS = 'CREATE_TENANT_SUCCESS'
export const CREATE_TENANT_FAILURE = 'CREATE_TENANT_FAILURE'

export const ARCHIVE_TENANT_REQUEST = 'ARCHIVE_TENANT_REQUEST'
export const ARCHIVE_TENANT_SUCCESS = 'ARCHIVE_TENANT_SUCCESS'
export const ARCHIVE_TENANT_FAILURE = 'ARCHIVE_TENANT_FAILURE'

export const UNARCHIVE_TENANT_REQUEST = 'UNARCHIVE_TENANT_REQUEST'
export const UNARCHIVE_TENANT_SUCCESS = 'UNARCHIVE_TENANT_SUCCESS'
export const UNARCHIVE_TENANT_FAILURE = 'UNARCHIVE_TENANT_FAILURE'

export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST'
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS'
export const DELETE_TENANT_FAILURE = 'DELETE_TENANT_FAILURE'