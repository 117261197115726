import React from 'react'
import {SuccessModal} from 'modals'
import {SpinnerModal} from 'modals'
import {FailureModal} from 'modals'
import {ModalButton} from 'buttons'
import NetworkRequestOutcomes from 'sharedComponents/NetworkRequestOutcomes.js'

function LoginFormSubmission(props) {
  let requested = <SpinnerModal show={true} />

  let success = (
    <SuccessModal show={true} heading="Welcome Back! 👋"></SuccessModal>
  )

  let error = (
    <FailureModal show={true} description={props.response.error}>
      <ModalButton
        text="Try again"
        backgroundColor={props => props.theme.colors.offWhite}
        color={props => props.theme.colors.black}
        handleClick={() => window.location.reload()}
      />
    </FailureModal>
  )

  return (
    <NetworkRequestOutcomes
      response={props.response}
      requested={requested}
      success={success}
      error={error}
    />
  )
}

export default LoginFormSubmission
