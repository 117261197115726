export const FETCH_OWNED_PROPERTY_CERTIFICATES_REQUEST = 'FETCH_OWNED_PROPERTY_CERTIFICATES_REQUEST'
export const FETCH_OWNED_PROPERTY_CERTIFICATES_SUCCESS = 'FETCH_OWNED_PROPERTY_CERTIFICATES_SUCCESS'
export const FETCH_OWNED_PROPERTY_CERTIFICATES_FAILURE = 'FETCH_OWNED_PROPERTY_CERTIFICATES_FAILURE'

export const FETCH_CERTIFICATE_REQUEST = 'FETCH_CERTIFICATE_REQUEST'
export const FETCH_CERTIFICATE_SUCCESS = 'FETCH_CERTIFICATE_SUCCESS'
export const FETCH_CERTIFICATE_FAILURE = 'FETCH_CERTIFICATE_FAILURE'

export const CREATE_CERTIFICATE_REQUEST = 'CREATE_CERTIFICATE_REQUEST'
export const CREATE_CERTIFICATE_SUCCESS = 'CREATE_CERTIFICATE_SUCCESS'
export const CREATE_CERTIFICATE_FAILURE = 'CREATE_CERTIFICATE_FAILURE'

export const UPDATE_CERTIFICATE_REQUEST = 'UPDATE_CERTIFICATE_REQUEST'
export const UPDATE_CERTIFICATE_SUCCESS = 'UPDATE_CERTIFICATE_SUCCESS'
export const UPDATE_CERTIFICATE_FAILURE = 'UPDATE_CERTIFICATE_FAILURE'

export const ARCHIVE_CERTIFICATE_REQUEST = 'ARCHIVE_CERTIFICATE_REQUEST'
export const ARCHIVE_CERTIFICATE_SUCCESS = 'ARCHIVE_CERTIFICATE_SUCCESS'
export const ARCHIVE_CERTIFICATE_FAILURE = 'ARCHIVE_CERTIFICATE_FAILURE'

export const UNARCHIVE_CERTIFICATE_REQUEST = 'UNARCHIVE_CERTIFICATE_REQUEST'
export const UNARCHIVE_CERTIFICATE_SUCCESS = 'UNARCHIVE_CERTIFICATE_SUCCESS'
export const UNARCHIVE_CERTIFICATE_FAILURE = 'UNARCHIVE_CERTIFICATE_FAILURE'

export const DELETE_CERTIFICATE_REQUEST = 'DELETE_CERTIFICATE_REQUEST'
export const DELETE_CERTIFICATE_SUCCESS = 'DELETE_CERTIFICATE_SUCCESS'
export const DELETE_CERTIFICATE_FAILURE = 'DELETE_CERTIFICATE_FAILURE'