function useDealLink(deal) {
  let typeName = deal.typeName || ''
  let id = deal.typeId || ''

  switch (typeName) {
    case 'hmo':
      return `/hmo-deals/${id}`
    case 'commercial_to_residential':
      return `/multi-dwelling-deals/${id}`
    case 'flip':
      return `/flip-deals/${id}`
    case 'product_transfer':
      return `/product-transfer-deals/${id}`
    case 'purchase_lease_option':
      return `/purchase-lease-option-deals/${id}`
    case 'purchase_option':
      return `/purchase-option-deals/${id}`
    case 'remortgage':
      return `/remortgage-deals/${id}`
    case 'rent_to_rent':
      return `/rent-to-rent-deals/${id}`
    case 'rent_to_serviced_accommodation':
      return `/rent-to-serviced-accommodation-deals/${id}`
    case 'serviced_accommodation':
      return `/serviced-accommodation-deals/${id}`
    case 'single_let':
      return `/single-let-deals/${id}`
    default:
      return null
  }
}

export default useDealLink
