import update from 'immutability-helper'
import { FETCH_COURSE_LESSONS_REQUEST } from 'v2/spas/course/lessons/redux/action-types.tsx'
import { FETCH_COURSE_LESSONS_SUCCESS } from 'v2/spas/course/lessons/redux/action-types.tsx'
import { FETCH_COURSE_LESSONS_FAILURE } from 'v2/spas/course/lessons/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  course: {},
  lessons: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COURSE_LESSONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_COURSE_LESSONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_COURSE_LESSONS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        course: {
          $set: action.payload.course,
        },
        lessons: {
          $set: action.payload.course.lessons
        },
      })
    }

    default:
      return state
  }
}
