import React from 'react'
import styled from 'styled-components'
import {useFormattedMoney} from 'sharedComponents/useFormattedMoney.js'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  h3 {
    padding-bottom: ${props => props.theme.space.m};
  }
  padding-bottom: ${props => props.theme.space.m};
`

function ProspectivePropertyResourceCardRowAttributes(props) {
  const amount = useFormattedMoney(props.amount)

  function renderValue(){
    if (props.amount) {
      return amount
    } else if (props.external && props.link) {
      return (
        <a href={props.link} target='_blank' rel='noopener noreferrer'>
          {props.value}
        </a>
      )
    } else if (props.link) {
      return(
        <Link to={props.link}>
          {props.value}
        </Link>
      )
    } else {
      return props.value
    }
  }

  return (
    <Wrapper paddingRight={props.paddingRight}>
      <h3>{props.heading}</h3>
      <p
        link={props.link}
        color={props.color}
        wordBreak={props.wordBreak}
        wordBreakHypen={props.wordBreakHypen}>
        {renderValue()}
      </p>
    </Wrapper>
  )
}

export default ProspectivePropertyResourceCardRowAttributes
