import update from 'immutability-helper'
import { FETCH_ARTICLE_REQUEST } from 'v2/spas/section/articles/redux/action-types.tsx'
import { FETCH_ARTICLE_SUCCESS } from 'v2/spas/section/articles/redux/action-types.tsx'
import { FETCH_ARTICLE_FAILURE } from 'v2/spas/section/articles/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  article: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICLE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ARTICLE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ARTICLE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        article: {
          $set: action.payload.article,
        }
      })
    }

    default:
      return state
  }
}