import update from 'immutability-helper'
import { FETCH_ARTICLES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ARTICLES_FAILURE } from 'redux/actionTypes.js'
import { FETCH_ARTICLES_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchArticlesStatus: 'requested',
  fetchArticlesError: '',
  articles: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICLES_REQUEST: {
      return update(state, {
        fetchArticlesStatus: { $set: 'requested' },
      })
    }

    case FETCH_ARTICLES_FAILURE: {
      return update(state, {
        fetchArticlesStatus: {
          $set: 'error',
        },
        fetchArticlesError: { $set: action.payload.error },
      })
    }

    case FETCH_ARTICLES_SUCCESS: {
      return update(state, {
        fetchArticlesStatus: { $set: 'success' },
        articles: {
          $set: action.payload.articles,
        },
      })
    }

    default:
      return state
  }
}
