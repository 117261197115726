import update from 'immutability-helper'
import { FETCH_PORTFOLIO_OVERVIEW_REPORT_REQUEST } from 'redux/actionTypes.js'
import { FETCH_PORTFOLIO_OVERVIEW_REPORT_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_PORTFOLIO_OVERVIEW_REPORT_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  fetchOwnedPropertiesStatus: 'requested',
  fetchOwnedPropertiesError: '',
  ownedProperties: [],
  account: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PORTFOLIO_OVERVIEW_REPORT_REQUEST: {
      return update(state, {
        fetchOwnedPropertiesStatus: { $set: 'requested' },
      })
    }

    case FETCH_PORTFOLIO_OVERVIEW_REPORT_FAILURE: {
      return update(state, {
        fetchOwnedPropertiesStatus: {
          $set: 'error',
        },
        fetchOwnedPropertiesError: { $set: action.payload.error },
      })
    }

    case FETCH_PORTFOLIO_OVERVIEW_REPORT_SUCCESS: {
      return update(state, {
        fetchOwnedPropertiesStatus: { $set: 'success' },
        account: {
          $set: action.payload.account,
        },
        ownedProperties: {
          $set: action.payload.account.ownedProperties,
        },
      })
    }

    default:
      return state
  }
}
