export const FETCH_TENANCY_RENT_STATEMENT_REQUEST = 'FETCH_TENANCY_RENT_STATEMENT_REQUEST'
export const FETCH_TENANCY_RENT_STATEMENT_SUCCESS = 'FETCH_TENANCY_RENT_STATEMENT_SUCCESS'
export const FETCH_TENANCY_RENT_STATEMENT_FAILURE = 'FETCH_TENANCY_RENT_STATEMENT_FAILURE'

export const CREATE_RENT_STATEMENT_TRANSACTION_REQUEST = 'CREATE_RENT_STATEMENT_TRANSACTION_REQUEST'
export const CREATE_RENT_STATEMENT_TRANSACTION_SUCCESS = 'CREATE_RENT_STATEMENT_TRANSACTION_SUCCESS'
export const CREATE_RENT_STATEMENT_TRANSACTION_FAILURE = 'CREATE_RENT_STATEMENT_TRANSACTION_FAILURE'

export const UPDATE_RENT_STATEMENT_TRANSACTION_REQUEST = 'UPDATE_RENT_STATEMENT_TRANSACTION_REQUEST'
export const UPDATE_RENT_STATEMENT_TRANSACTION_SUCCESS = 'UPDATE_RENT_STATEMENT_TRANSACTION_SUCCESS'
export const UPDATE_RENT_STATEMENT_TRANSACTION_FAILURE = 'UPDATE_RENT_STATEMENT_TRANSACTION_FAILURE'

export const DELETE_RENT_STATEMENT_TRANSACTION_REQUEST = 'DELETE_RENT_STATEMENT_TRANSACTION_REQUEST'
export const DELETE_RENT_STATEMENT_TRANSACTION_SUCCESS = 'DELETE_RENT_STATEMENT_TRANSACTION_SUCCESS'
export const DELETE_RENT_STATEMENT_TRANSACTION_FAILURE = 'DELETE_RENT_STATEMENT_TRANSACTION_FAILURE'

export const CREATE_RENT_STATEMENT_INVOICE_REQUEST = 'CREATE_RENT_STATEMENT_INVOICE_REQUEST'
export const CREATE_RENT_STATEMENT_INVOICE_SUCCESS = 'CREATE_RENT_STATEMENT_INVOICE_SUCCESS'
export const CREATE_RENT_STATEMENT_INVOICE_FAILURE = 'CREATE_RENT_STATEMENT_INVOICRENT_STATEMENT_TRANSACTION'

export const UPDATE_RENT_STATEMENT_INVOICE_REQUEST = 'UPDATE_RENT_STATEMENT_INVOICE_REQUEST'
export const UPDATE_RENT_STATEMENT_INVOICE_SUCCESS = 'UPDATE_RENT_STATEMENT_INVOICE_SUCCESS'
export const UPDATE_RENT_STATEMENT_INVOICE_FAILURE = 'UPDATE_RENT_STATEMENT_INVOICE_FAILURE'

export const DELETE_RENT_STATEMENT_INVOICE_REQUEST = 'DELETE_RENT_STATEMENT_INVOICE_REQUEST'
export const DELETE_RENT_STATEMENT_INVOICE_SUCCESS = 'DELETE_RENT_STATEMENT_INVOICE_SUCCESS'
export const DELETE_RENT_STATEMENT_INVOICE_FAILURE = 'DELETE_RENT_STATEMENT_INVOICE_FAILURE'