export const FETCH_OWNED_PROPERTY_KEYS_REQUEST = 'FETCH_OWNED_PROPERTY_KEYS_REQUEST'
export const FETCH_OWNED_PROPERTY_KEYS_SUCCESS = 'FETCH_OWNED_PROPERTY_KEYS_SUCCESS'
export const FETCH_OWNED_PROPERTY_KEYS_FAILURE = 'FETCH_OWNED_PROPERTY_KEYS_FAILURE'

export const FETCH_KEY_REQUEST = 'FETCH_KEY_REQUEST'
export const FETCH_KEY_SUCCESS = 'FETCH_KEY_SUCCESS'
export const FETCH_KEY_FAILURE = 'FETCH_KEY_FAILURE'

export const CREATE_KEY_REQUEST = 'CREATE_KEY_REQUEST'
export const CREATE_KEY_SUCCESS = 'CREATE_KEY_SUCCESS'
export const CREATE_KEY_FAILURE = 'CREATE_KEY_FAILURE'

export const UPDATE_KEY_REQUEST = 'UPDATE_KEY_REQUEST'
export const UPDATE_KEY_SUCCESS = 'UPDATE_KEY_SUCCESS'
export const UPDATE_KEY_FAILURE = 'UPDATE_KEY_FAILURE'

export const ARCHIVE_KEY_REQUEST = 'ARCHIVE_KEY_REQUEST'
export const ARCHIVE_KEY_SUCCESS = 'ARCHIVE_KEY_SUCCESS'
export const ARCHIVE_KEY_FAILURE = 'ARCHIVE_KEY_FAILURE'

export const UNARCHIVE_KEY_REQUEST = 'UNARCHIVE_KEY_REQUEST'
export const UNARCHIVE_KEY_SUCCESS = 'UNARCHIVE_KEY_SUCCESS'
export const UNARCHIVE_KEY_FAILURE = 'UNARCHIVE_KEY_FAILURE'

export const DELETE_KEY_REQUEST = 'DELETE_KEY_REQUEST'
export const DELETE_KEY_SUCCESS = 'DELETE_KEY_SUCCESS'
export const DELETE_KEY_FAILURE = 'DELETE_KEY_FAILURE'

export const FETCH_PROPERTY_INVESTORS_REQUEST = 'FETCH_PROPERTY_INVESTORS_REQUEST'
export const FETCH_PROPERTY_INVESTORS_SUCCESS = 'FETCH_PROPERTY_INVESTORS_SUCCESS'
export const FETCH_PROPERTY_INVESTORS_FAILURE = 'FETCH_PROPERTY_INVESTORS_FAILURE'