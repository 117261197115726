export const FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_REQUEST = 'FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_REQUEST'
export const FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_SUCCESS = 'FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_SUCCESS'
export const FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_FAILURE = 'FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_FAILURE'

export const FETCH_INSURANCE_APPLICATION_REQUEST = 'FETCH_INSURANCE_APPLICATION_REQUEST'
export const FETCH_INSURANCE_APPLICATION_SUCCESS = 'FETCH_INSURANCE_APPLICATION_SUCCESS'
export const FETCH_INSURANCE_APPLICATION_FAILURE = 'FETCH_INSURANCE_APPLICATION_FAILURE'

export const CREATE_INSURANCE_APPLICATION_REQUEST = 'CREATE_INSURANCE_APPLICATION_REQUEST'
export const CREATE_INSURANCE_APPLICATION_SUCCESS = 'CREATE_INSURANCE_APPLICATION_SUCCESS'
export const CREATE_INSURANCE_APPLICATION_FAILURE = 'CREATE_INSURANCE_APPLICATION_FAILURE'

export const UPDATE_INSURANCE_APPLICATION_REQUEST = 'UPDATE_INSURANCE_APPLICATION_REQUEST'
export const UPDATE_INSURANCE_APPLICATION_SUCCESS = 'UPDATE_INSURANCE_APPLICATION_SUCCESS'
export const UPDATE_INSURANCE_APPLICATION_FAILURE = 'UPDATE_INSURANCE_APPLICATION_FAILURE'

export const ARCHIVE_INSURANCE_APPLICATION_REQUEST = 'ARCHIVE_INSURANCE_APPLICATION_REQUEST'
export const ARCHIVE_INSURANCE_APPLICATION_SUCCESS = 'ARCHIVE_INSURANCE_APPLICATION_SUCCESS'
export const ARCHIVE_INSURANCE_APPLICATION_FAILURE = 'ARCHIVE_INSURANCE_APPLICATION_FAILURE'

export const UNARCHIVE_INSURANCE_APPLICATION_REQUEST = 'UNARCHIVE_INSURANCE_APPLICATION_REQUEST'
export const UNARCHIVE_INSURANCE_APPLICATION_SUCCESS = 'UNARCHIVE_INSURANCE_APPLICATION_SUCCESS'
export const UNARCHIVE_INSURANCE_APPLICATION_FAILURE = 'UNARCHIVE_INSURANCE_APPLICATION_FAILURE'

export const DELETE_INSURANCE_APPLICATION_REQUEST = 'DELETE_INSURANCE_APPLICATION_REQUEST'
export const DELETE_INSURANCE_APPLICATION_SUCCESS = 'DELETE_INSURANCE_APPLICATION_SUCCESS'
export const DELETE_INSURANCE_APPLICATION_FAILURE = 'DELETE_INSURANCE_APPLICATION_FAILURE'