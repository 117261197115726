import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import SocialIcons from 'sharedComponents/SocialIcons.js'

const Wrapper = styled.div`
  background-color: black;
  text-align: center;
  padding-top: ${props => props.theme.space.xl};
  padding-bottom: ${props => props.theme.space.xl};

  p {
    color: ${props => props.theme.colors.white};
    padding-bottom: ${props => props.theme.space.m};
  }

  a {
    font-weight: normal !important;
  }
`

function PublicFooter() {
  return (
    <Wrapper>
      <Row>
        <Col xs="12">
          <a href="https://community.stacked.group" target="_blank" rel="noopener noreferrer">
            <p>Community</p>
          </a>
        </Col>
        <Col xs="12">
          <a href="/platform-updates">
            <p>Platform updates</p>
          </a>
        </Col>

        <Col xs="12">
          <a href="https://open.spotify.com/show/09VpeDycyCsqVoM1JTuBPC?si=a21ab3796ea74fb8" target="_blank" rel="noopener noreferrer">
            <p>Podcast</p>
          </a>
        </Col>

        <Col xs="12">
          <Link to="/terms-and-conditions">
            <p>Terms</p>
          </Link>
        </Col>

        <Col xs="12">
          <Link to="/privacy-policy">
            <p>Privacy</p>
          </Link>
        </Col>
      </Row>
      <Row>
        <SocialIcons />
      </Row>
    </Wrapper>
  )
}

export default PublicFooter
