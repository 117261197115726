export const FETCH_ACCOUNT_DEAL_TEMPLATES_REQUEST = 'FETCH_ACCOUNT_DEAL_TEMPLATES_REQUEST'
export const FETCH_ACCOUNT_DEAL_TEMPLATES_SUCCESS = 'FETCH_ACCOUNT_DEAL_TEMPLATES_SUCCESS'
export const FETCH_ACCOUNT_DEAL_TEMPLATES_FAILURE = 'FETCH_ACCOUNT_DEAL_TEMPLATES_FAILURE'

export const FETCH_DEAL_TEMPLATE_REQUEST = 'FETCH_DEAL_TEMPLATE_REQUEST'
export const FETCH_DEAL_TEMPLATE_SUCCESS = 'FETCH_DEAL_TEMPLATE_SUCCESS'
export const FETCH_DEAL_TEMPLATE_FAILURE = 'FETCH_DEAL_TEMPLATE_FAILURE'

export const CREATE_DEAL_TEMPLATE_REQUEST = 'CREATE_DEAL_TEMPLATE_REQUEST'
export const CREATE_DEAL_TEMPLATE_SUCCESS = 'CREATE_DEAL_TEMPLATE_SUCCESS'
export const CREATE_DEAL_TEMPLATE_FAILURE = 'CREATE_DEAL_TEMPLATE_FAILURE'

export const UPDATE_DEAL_TEMPLATE_REQUEST = 'UPDATE_DEAL_TEMPLATE_REQUEST'
export const UPDATE_DEAL_TEMPLATE_SUCCESS = 'UPDATE_DEAL_TEMPLATE_SUCCESS'
export const UPDATE_DEAL_TEMPLATE_FAILURE = 'UPDATE_DEAL_TEMPLATE_FAILURE'

export const ARCHIVE_DEAL_TEMPLATE_REQUEST = 'ARCHIVE_DEAL_TEMPLATE_REQUEST'
export const ARCHIVE_DEAL_TEMPLATE_SUCCESS = 'ARCHIVE_DEAL_TEMPLATE_SUCCESS'
export const ARCHIVE_DEAL_TEMPLATE_FAILURE = 'ARCHIVE_DEAL_TEMPLATE_FAILURE'

export const UNARCHIVE_DEAL_TEMPLATE_REQUEST = 'UNARCHIVE_DEAL_TEMPLATE_REQUEST'
export const UNARCHIVE_DEAL_TEMPLATE_SUCCESS = 'UNARCHIVE_DEAL_TEMPLATE_SUCCESS'
export const UNARCHIVE_DEAL_TEMPLATE_FAILURE = 'UNARCHIVE_DEAL_TEMPLATE_FAILURE'

export const DELETE_DEAL_TEMPLATE_REQUEST = 'DELETE_DEAL_TEMPLATE_REQUEST'
export const DELETE_DEAL_TEMPLATE_SUCCESS = 'DELETE_DEAL_TEMPLATE_SUCCESS'
export const DELETE_DEAL_TEMPLATE_FAILURE = 'DELETE_DEAL_TEMPLATE_FAILURE'