import update from 'immutability-helper'
import { FETCH_ACCOUNT_ACCOUNT_USERS_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { FETCH_ACCOUNT_ACCOUNT_USERS_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { FETCH_ACCOUNT_ACCOUNT_USERS_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { ARCHIVE_ACCOUNT_USER_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { ARCHIVE_ACCOUNT_USER_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { ARCHIVE_ACCOUNT_USER_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_ACCOUNT_USER_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_ACCOUNT_USER_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { UPDATE_ACCOUNT_USER_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { DELETE_ACCOUNT_USER_REQUEST } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { DELETE_ACCOUNT_USER_SUCCESS } from 'v2/spas/account/account-users/redux/action-types.tsx'
import { DELETE_ACCOUNT_USER_FAILURE } from 'v2/spas/account/account-users/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  account: {},
  accountUsers: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_ACCOUNT_USERS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_ACCOUNT_USERS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_ACCOUNT_USERS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        account: {
          $set: action.payload.account,
        },
        accountUsers: {
          $set: action.payload.account.accountUsers
        },
      })
    }

    case UPDATE_ACCOUNT_USER_REQUEST: {
      return state
    }

    case UPDATE_ACCOUNT_USER_FAILURE: {
      return state
    }

    case UPDATE_ACCOUNT_USER_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      return update(state, {
        accountUsers: {
          [index]: { $set: action.payload.accountUser },
        },
      })
    }

    case ARCHIVE_ACCOUNT_USER_REQUEST: {
      return state
    }

    case ARCHIVE_ACCOUNT_USER_FAILURE: {
      return state
    }

    case ARCHIVE_ACCOUNT_USER_SUCCESS: {
      let index = state.accountUsers.findIndex(x => x.id === action.payload.accountUser.id)

      return update(state, {
        accountUsers: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_ACCOUNT_USER_REQUEST: {
      return state
    }

    case DELETE_ACCOUNT_USER_FAILURE: {
      return state
    }

    case DELETE_ACCOUNT_USER_SUCCESS: {
      let index = state.accountUsers.findIndex(x => x.id === action.payload.id)

      return update(state, {
        accountUsers: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
