import {createGlobalStyle} from 'styled-components'

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: ${props => props.theme.space.m};
  };

  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 75px;
  };

  .row {
    height: 100%;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  };

  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  a {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    font-weight: 900;
  }

  a:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  };

  button:focus {
    outline: 0;
  };

  .modal {
    background: rgba(0, 0, 0, 0.3);
    .modal-content {
      border-radius: 8px;
    }
    .modal-header {
      padding: 0px;
      // border-bottom: none;
    }
    .modal-body {
      padding: 0px;
    }
  }

  // Required for Google address search
  .pac-container {
    z-index: 10000 !important;
  }

  h1 {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-weight: 900;

    @media ${props => props.theme.devices.mobile} {
      font-size: ${props => props.theme.space.xl}
    }

    @media ${props => props.theme.devices.tablet} {
      font-size: ${props => props.theme.space.xl}
    }

    @media ${props => props.theme.devices.laptop} {
      font-size: ${props => props.theme.space.xl}
    }

    @media ${props => props.theme.devices.desktop} {
      font-size: ${props => props.theme.space.xl}
    }
  }

  h2 {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-weight: 900;

    @media ${props => props.theme.devices.mobile} {
      font-size: ${props => props.theme.space.l}
    }

    @media ${props => props.theme.devices.tablet} {
      font-size: ${props => props.theme.space.l}
    }

    @media ${props => props.theme.devices.laptop} {
      font-size: ${props => props.theme.space.l}
    }

    @media ${props => props.theme.devices.desktop} {
      font-size: ${props => props.theme.space.l}
    }
  }

  h3 {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-weight: 900;

    @media ${props => props.theme.devices.mobile} {
      font-size: ${props => props.theme.space.m}
    }

    @media ${props => props.theme.devices.tablet} {
      font-size: ${props => props.theme.space.m}
    }

    @media ${props => props.theme.devices.laptop} {
      font-size: ${props => props.theme.space.m}
    }

    @media ${props => props.theme.devices.desktop} {
      font-size: ${props => props.theme.space.m}
    }
  }

  p {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;

    @media ${props => props.theme.devices.mobile} {
      font-size: ${props => props.theme.space.m}
    }

    @media ${props => props.theme.devices.tablet} {
      font-size: ${props => props.theme.space.m}
    }

    @media ${props => props.theme.devices.laptop} {
      font-size: ${props => props.theme.space.m}
    }

    @media ${props => props.theme.devices.desktop} {
      font-size: ${props => props.theme.space.m}
    }
  }

  .dropdown-item {
    a {
      text-decoration: none;
      color: black;
      font-weight: normal;
    }
  }

  .dropdown-item:active {
    background-color: transparent;
  }
`

export default GlobalStyles
