import update from 'immutability-helper'
import {FETCH_ACCOUNT_USERS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USERS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USERS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_SEARCH_RESULTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_SEARCH_RESULTS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_ACCOUNT_USER_SEARCH_RESULTS_SUCCESS} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_REQUEST} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_FAILURE} from 'redux/actionTypes.js'
import {ADD_ACCOUNT_USER_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_ACCOUNT_USER_SUCCESS} from 'redux/actionTypes.js'
import {ARCHIVE_ACCOUNT_USER_REQUEST} from 'redux/actionTypes.js'
import {ARCHIVE_ACCOUNT_USER_FAILURE} from 'redux/actionTypes.js'
import {ARCHIVE_ACCOUNT_USER_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PRIVATE_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PRIVATE_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PRIVATE_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {DELETE_PROPERTY_INVESTOR_REQUEST} from 'redux/actionTypes.js'
import {DELETE_PROPERTY_INVESTOR_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_PROPERTY_INVESTOR_FAILURE} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_TENANT_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_TENANT_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_TENANT_FAILURE} from 'redux/actionTypes.js'
// import {DELETE_TENANT_REQUEST} from 'redux/actionTypes.js'
// import {DELETE_TENANT_SUCCESS} from 'redux/actionTypes.js'
// import {DELETE_TENANT_FAILURE} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_GUARANTOR_REQUEST} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_GUARANTOR_SUCCESS} from 'redux/actionTypes.js'
import {MAKE_ACCOUNT_USER_A_GUARANTOR_FAILURE} from 'redux/actionTypes.js'
// import {DELETE_GUARANTOR_REQUEST} from 'redux/actionTypes.js'
// import {DELETE_GUARANTOR_SUCCESS} from 'redux/actionTypes.js'
// import {DELETE_GUARANTOR_FAILURE} from 'redux/actionTypes.js'

const initialState = {
  fetchAccountUsersStatus: 'requested',
  fetchAccountUsersError: '',
  accountUsers: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_USERS_REQUEST: {
      return update(state, {
        fetchAccountUsersStatus: {$set: 'requested'},
      })
    }

    case FETCH_ACCOUNT_USERS_FAILURE: {
      return update(state, {
        fetchAccountUsersStatus: {
          $set: 'error',
        },
        fetchAccountUsersError: {$set: action.payload.error},
      })
    }

    case FETCH_ACCOUNT_USERS_SUCCESS: {
      return update(state, {
        fetchAccountUsersStatus: {$set: 'success'},
        accountUsers: {
          $set: action.payload.account.accountUsers,
        },
        count: {
          $set: action.payload.account.counters.accountUsers,
        },
      })
    }

    case FETCH_ACCOUNT_USER_SEARCH_RESULTS_REQUEST: {
      return update(state, {
        fetchAccountUsersStatus: {$set: 'requested'},
      })
    }

    case FETCH_ACCOUNT_USER_SEARCH_RESULTS_FAILURE: {
      return update(state, {
        fetchAccountUsersStatus: {
          $set: 'error',
        },
        fetchAccountUsersError: {$set: action.payload.error},
      })
    }

    case FETCH_ACCOUNT_USER_SEARCH_RESULTS_SUCCESS: {
      return update(state, {
        fetchAccountUsersStatus: {$set: 'success'},
        numberOfResults: {
          $set: action.payload.account.accountUserSearch.numberOfResults,
        },
        accountUsers: {
          $set: action.payload.account.accountUserSearch.results,
        },
      })
    }

    case ADD_ACCOUNT_USER_REQUEST: {
      return state
    }

    case ADD_ACCOUNT_USER_FAILURE: {
      return state
    }

    case ADD_ACCOUNT_USER_SUCCESS: {
      return update(state, {
        accountUsers: {
          $push: [action.payload.accountUser],
        },
      })
    }

    case UPDATE_ACCOUNT_USER_REQUEST: {
      return state
    }

    case UPDATE_ACCOUNT_USER_FAILURE: {
      return state
    }

    case UPDATE_ACCOUNT_USER_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      return update(state, {
        accountUsers: {
          [index]: {$set: action.payload.accountUser},
        },
      })
    }

    case ARCHIVE_ACCOUNT_USER_REQUEST: {
      return state
    }

    case ARCHIVE_ACCOUNT_USER_FAILURE: {
      return state
    }

    case ARCHIVE_ACCOUNT_USER_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      return update(state, {
        accountUsers: {
          $splice: [[index, 1]],
        },
      })
    }

    case MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PRIVATE_INVESTOR_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      if (index >= 0) {
        return update(state, {
          accountUsers: {
            [index]: {
              privateInvestor: {
                $set: action.payload.accountUser.privateInvestor,
              },
            },
          },
        })
      } else {
        return state
      }
    }

    case DELETE_PRIVATE_INVESTOR_REQUEST: {
      return state
    }

    case DELETE_PRIVATE_INVESTOR_FAILURE: {
      return state
    }

    case DELETE_PRIVATE_INVESTOR_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      if (index >= 0) {
        return update(state, {
          accountUsers: {
            [index]: {
              privateInvestor: {
                $set: action.payload.accountUser.privateInvestor,
              },
            },
          },
        })
      } else {
        return state
      }
    }

    case MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_PROPERTY_INVESTOR_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      if (index >= 0) {
        return update(state, {
          accountUsers: {
            [index]: {
              propertyInvestor: {
                $set: action.payload.accountUser.propertyInvestor,
              },
            },
          },
        })
      } else {
        return state
      }
    }

    case DELETE_PROPERTY_INVESTOR_REQUEST: {
      return state
    }

    case DELETE_PROPERTY_INVESTOR_FAILURE: {
      return state
    }

    case DELETE_PROPERTY_INVESTOR_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      if (index >= 0) {
        return update(state, {
          accountUsers: {
            [index]: {
              propertyInvestor: {
                $set: action.payload.accountUser.propertyInvestor,
              },
            },
          },
        })
      } else {
        return state
      }
    }

    case MAKE_ACCOUNT_USER_A_TENANT_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_TENANT_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_TENANT_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      if (index >= 0) {
        return update(state, {
          accountUsers: {
            [index]: {
              tenant: {
                $set: action.payload.accountUser.tenant,
              },
            },
          },
        })
      } else {
        return state
      }
    }

    // case DELETE_TENANT_REQUEST: {
    //   return state
    // }

    // case DELETE_TENANT_FAILURE: {
    //   return state
    // }

    // case DELETE_TENANT_SUCCESS: {
    //   let index = state.accountUsers.findIndex(
    //     x => x.id === action.payload.accountUser.id,
    //   )

    //   if (index >= 0) {
    //     return update(state, {
    //       accountUsers: {
    //         [index]: {
    //           tenant: {
    //             $set: action.payload.accountUser.tenant,
    //           },
    //         },
    //       },
    //     })
    //   } else {
    //     return state
    //   }
    // }

    case MAKE_ACCOUNT_USER_A_GUARANTOR_REQUEST: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_GUARANTOR_FAILURE: {
      return state
    }

    case MAKE_ACCOUNT_USER_A_GUARANTOR_SUCCESS: {
      let index = state.accountUsers.findIndex(
        x => x.id === action.payload.accountUser.id,
      )

      if (index >= 0) {
        return update(state, {
          accountUsers: {
            [index]: {
              guarantor: {
                $set: action.payload.accountUser.guarantor,
              },
            },
          },
        })
      } else {
        return state
      }
    }

    // case DELETE_GUARANTOR_REQUEST: {
    //   return state
    // }

    // case DELETE_GUARANTOR_FAILURE: {
    //   return state
    // }

    // case DELETE_GUARANTOR_SUCCESS: {
    //   let index = state.accountUsers.findIndex(
    //     x => x.id === action.payload.accountUser.id,
    //   )

    //   if (index >= 0) {
    //     return update(state, {
    //       accountUsers: {
    //         [index]: {
    //           guarantor: {
    //             $set: action.payload.accountUser.guarantor,
    //           },
    //         },
    //       },
    //     })
    //   } else {
    //     return state
    //   }
    // }

    default:
      return state
  }
}
