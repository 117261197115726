import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_DEALS_REQUEST } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_DEALS_SUCCESS } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_DEALS_FAILURE } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { ARCHIVE_DEAL_REQUEST } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { ARCHIVE_DEAL_SUCCESS } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { ARCHIVE_DEAL_FAILURE } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { UPDATE_DEAL_REQUEST } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { UPDATE_DEAL_SUCCESS } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { UPDATE_DEAL_FAILURE } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { DELETE_DEAL_REQUEST } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { DELETE_DEAL_SUCCESS } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'
import { DELETE_DEAL_FAILURE } from 'v2/spas/prospective-property/deals/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  deals: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_DEALS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_DEALS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_DEALS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        deals: {
          $set: action.payload.prospectiveProperty.deals
        },
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      let index = state.deals.findIndex(
        x => x.id === action.payload.deal.id,
      )

      if (index !== -1) {
        return update(state, {
          deals: {
            [index]: {
              name: {
                $set: action.payload.deal.name
              }
            },
          },
        })
      }
    }

    case ARCHIVE_DEAL_REQUEST: {
      return state
    }

    case ARCHIVE_DEAL_FAILURE: {
      return state
    }

    case ARCHIVE_DEAL_SUCCESS: {
      let index = state.deals.findIndex(x => x.id === action.payload.deal.id)

      return update(state, {
        deals: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_DEAL_REQUEST: {
      return state
    }
    
    case DELETE_DEAL_FAILURE: {
      return state
    }
    
    case DELETE_DEAL_SUCCESS: {
      let index = state.deals.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        deals: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
