import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_DOCUMENTS_REQUEST } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_DOCUMENTS_SUCCESS } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_DOCUMENTS_FAILURE } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { ARCHIVE_DOCUMENT_REQUEST } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { ARCHIVE_DOCUMENT_SUCCESS } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { ARCHIVE_DOCUMENT_FAILURE } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { UPDATE_DOCUMENT_REQUEST } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { UPDATE_DOCUMENT_SUCCESS } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { UPDATE_DOCUMENT_FAILURE } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { DELETE_DOCUMENT_REQUEST } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { DELETE_DOCUMENT_SUCCESS } from 'v2/spas/owned-property/documents/redux/action-types.tsx'
import { DELETE_DOCUMENT_FAILURE } from 'v2/spas/owned-property/documents/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  documents: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_DOCUMENTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_DOCUMENTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_DOCUMENTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        documents: {
          $set: action.payload.ownedProperty.property.documents
        },
      })
    }

    case UPDATE_DOCUMENT_REQUEST: {
      return state
    }

    case UPDATE_DOCUMENT_FAILURE: {
      return state
    }

    case UPDATE_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(
        x => x.id === action.payload.document.id,
      )

      return update(state, {
        documents: {
          [index]: { $set: action.payload.document },
        },
      })
    }

    case ARCHIVE_DOCUMENT_REQUEST: {
      return state
    }

    case ARCHIVE_DOCUMENT_FAILURE: {
      return state
    }

    case ARCHIVE_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(x => x.id === action.payload.document.id)

      return update(state, {
        documents: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_DOCUMENT_REQUEST: {
      return state
    }

    case DELETE_DOCUMENT_FAILURE: {
      return state
    }

    case DELETE_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(x => x.id === action.payload.id)

      return update(state, {
        documents: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
