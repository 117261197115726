import update from 'immutability-helper'
import { FETCH_MORTGAGE_APPLICATION_REQUEST } from 'v2/spas/owned-property/mortgage-applications/redux/action-types.tsx'
import { FETCH_MORTGAGE_APPLICATION_SUCCESS } from 'v2/spas/owned-property/mortgage-applications/redux/action-types.tsx'
import { FETCH_MORTGAGE_APPLICATION_FAILURE } from 'v2/spas/owned-property/mortgage-applications/redux/action-types.tsx'
import { UPDATE_MORTGAGE_APPLICATION_REQUEST } from 'v2/spas/owned-property/mortgage-applications/redux/action-types.tsx'
import { UPDATE_MORTGAGE_APPLICATION_SUCCESS } from 'v2/spas/owned-property/mortgage-applications/redux/action-types.tsx'
import { UPDATE_MORTGAGE_APPLICATION_FAILURE } from 'v2/spas/owned-property/mortgage-applications/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  mortgageApplication: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MORTGAGE_APPLICATION_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_MORTGAGE_APPLICATION_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_MORTGAGE_APPLICATION_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        mortgageApplication: {
          $set: action.payload.mortgageApplication,
        }
      })
    }

    case UPDATE_MORTGAGE_APPLICATION_REQUEST: {
      return update(state, {
        updateMortgageApplicationStatus: { $set: 'requested' },
      })
    }

    case UPDATE_MORTGAGE_APPLICATION_FAILURE: {
      return update(state, {
        updateMortgageApplicationStatus: {
          $set: 'error',
        },
        updateMortgageApplicationError: { $set: action.payload.error },
      })
    }

    case UPDATE_MORTGAGE_APPLICATION_SUCCESS: {
      return update(state, {
        updateMortgageApplicationStatus: { $set: 'success' },
        mortgageApplication: {
          $set: action.payload.mortgageApplication,
        }
      })
    }

    default:
      return state
  }
}