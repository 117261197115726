import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container'
import {connect} from 'react-redux'
import {useSnackbar} from 'notistack'
import PropTypes from 'prop-types'
import {addCheckoutSession} from 'redux/actions/subscriptions/addCheckoutSession.tsx'
import {manageSubscription} from 'redux/actions/subscriptions/manageSubscription'
import Pricing from 'spas/Pricing/Pricing'
import PageNavbar from 'spas/Subscriptions/SubscriptionNavbar'

function Subscription(props) {
  const {enqueueSnackbar} = useSnackbar()

  function getClientReferenceId() {
    return window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime());
  }

  function handleProductSelection(stripePriceId) {
    props.addCheckoutSession({
      priceId: stripePriceId,
      clientReferenceId: getClientReferenceId(),
      handleResponse: handleCreateCheckoutSession,
    })
  }

  function handleCreateCheckoutSession(response) {
    if (response.status === 'success') {
      window.location.href = response.checkoutSessionUrl
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
    }
  }

  return (
    <Container fluid>
      <PageNavbar />
      <Pricing handleProductSelection={handleProductSelection} />
    </Container>
  )
}

Subscription.propTypes = {
  addCheckoutSession: PropTypes.func.isRequired,
  manageSubscription: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  addCheckoutSession: addCheckoutSession,
  manageSubscription: manageSubscription,
}

export default connect(null, mapDispatchToProps)(Subscription)
