import React from 'react'
import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import ToolTip from 'sharedComponents/ToolTip.js'
import VideoToolTip from 'sharedComponents/VideoToolTip.js'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: ${props => props.theme.space.l};
  padding-right: ${props => props.theme.space.l};
`

const Heading = styled.div`
  display: flex; 
  align-items: center; 
  padding-bottom: ${props => props.theme.space.l};
  padding-top: ${props => props.theme.space.l};

  #tooltip {
    padding-left: ${props => props.theme.space.m};
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  #button {
    margin-left: ${props => props.theme.space.m};
  }

  #card-menu-icon {
    padding-left: ${props => props.theme.space.m};
  }
`

function ProspectivePropertyResourceCardHeader(props) {
  function tooltip() {
    if (props.toolTipContent) {
      return <ToolTip content={props.toolTipContent} />
    }
  }

  function videoTooltip() {
    if (props.videoToolTipUrl) {
      return (
        <VideoToolTip
          url={props.videoToolTipUrl}
          heading={props.videoToolTipHeading}
        />
      )
    }
  }

  return (
    <Wrapper>
      <Col xs='6'>
        <Heading>
          <h3>{props.heading}</h3> {tooltip()} {videoTooltip()}
        </Heading>
      </Col>
      <Col xs='6'>
        <Actions>
          {props.children}
        </Actions>
      </Col>
    </Wrapper>
  )
}

export default ProspectivePropertyResourceCardHeader
