import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_WORKFLOWS_REQUEST } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_WORKFLOWS_SUCCESS } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_WORKFLOWS_FAILURE } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { ARCHIVE_WORKFLOW_REQUEST } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { ARCHIVE_WORKFLOW_SUCCESS } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { ARCHIVE_WORKFLOW_FAILURE } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { UPDATE_WORKFLOW_REQUEST } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { UPDATE_WORKFLOW_SUCCESS } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { UPDATE_WORKFLOW_FAILURE } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { DELETE_WORKFLOW_REQUEST } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { DELETE_WORKFLOW_SUCCESS } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'
import { DELETE_WORKFLOW_FAILURE } from 'v2/spas/owned-property/workflows/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  workflows: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_WORKFLOWS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_WORKFLOWS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_WORKFLOWS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        workflows: {
          $set: action.payload.ownedProperty.workflows
        },
      })
    }

    case UPDATE_WORKFLOW_REQUEST: {
      return state
    }

    case UPDATE_WORKFLOW_FAILURE: {
      return state
    }

    case UPDATE_WORKFLOW_SUCCESS: {
      let index = state.workflows.findIndex(
        x => x.id === action.payload.workflow.id,
      )

      if (index !== -1) {
        return update(state, {
          workflows: {
            [index]: {
              name: {
                $set: action.payload.workflow.name,
              }
            },
          },
        })
      }
    }

    case ARCHIVE_WORKFLOW_REQUEST: {
      return state
    }

    case ARCHIVE_WORKFLOW_FAILURE: {
      return state
    }

    case ARCHIVE_WORKFLOW_SUCCESS: {
      let index = state.workflows.findIndex(x => x.id === action.payload.workflow.id)

      return update(state, {
        workflows: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_WORKFLOW_REQUEST: {
      return state
    }
    
    case DELETE_WORKFLOW_FAILURE: {
      return state
    }
    
    case DELETE_WORKFLOW_SUCCESS: {
      let index = state.workflows.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        workflows: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
