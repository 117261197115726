import React, {useState} from 'react'
import styled from 'styled-components'
import { Button } from 'buttons'
import {connect} from 'react-redux'
import {withdrawUserConsent} from 'redux/actions/profile/withdrawUserConsent'
import moment from 'moment'
import {ConfirmationModal} from 'modals'
import {useSnackbar} from 'notistack'
import {useCurrentUser} from 'sharedComponents/useCurrentUser'

const Wrapper = styled.div``

const WithdrawConsentButtonWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 25px;
`

function WithdrawUserConsent(props) {
  const {enqueueSnackbar} = useSnackbar()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const currentUser = useCurrentUser()

  let consentedToProcessPropertyInvestmentDataAtTime = moment(
    currentUser.consentedToProcessPropertyInvestmentDataAt,
  ).format('h:mm:ss a')
  let consentedToProcessPropertyInvestmentDataAtDate = moment(
    currentUser.consentedToProcessPropertyInvestmentDataAt,
  ).format('MMMM Do YYYY')

  function withdrawUserConsent() {
    setShowConfirmationModal(true)
  }

  const handleConfirmWithdrawUserConsent = () => {
    setShowConfirmationModal(false)
    props.withdrawUserConsent(
      'process_property_investment_data',
      handleResponse,
    )
  }

  function handleResponse(response) {
    if (response.status === 'success') {
      enqueueSnackbar('Your consent has been succefully withdrawn!')
      window.location.reload()
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      window.setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  if (currentUser.consentedToProcessPropertyInvestmentDataAt) {
    return (
      <Wrapper>
        <h6>Data processing consents</h6>
        <p>
          You granted consent for us to process data in connection with your
          property investment activities at{' '}
          <strong>{consentedToProcessPropertyInvestmentDataAtTime}</strong> on{' '}
          <strong>{consentedToProcessPropertyInvestmentDataAtDate}</strong>
        </p>
        <p>You can withdraw this consent by clicking the link below</p>
        <p>Before doing so - please be aware of the following implications:</p>
        <ul>
          <li>
            Your access to all property investment activities on our platform
            will be immediately restricted
          </li>
          <li>
            To regain access - you will need to grant your consent once again
          </li>
        </ul>
        <WithdrawConsentButtonWrapper>
          <Button
            onClick={withdrawUserConsent}
            content="Withdraw Consent"
            backgroundColor={props => props.theme.colors.red}
          />
        </WithdrawConsentButtonWrapper>

        <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          handleConfirm={handleConfirmWithdrawUserConsent}
          heading="Withdraw Consent"
        />
      </Wrapper>
    )
  } else {
    return null
  }
}

const mapDispatchToProps = {
  withdrawUserConsent: withdrawUserConsent,
}

export default connect(null, mapDispatchToProps)(WithdrawUserConsent)
