import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useCurrentUser } from 'sharedComponents/useCurrentUser'

const Text = styled.div`
  display: flex;
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  padding-left: ${props => props.paddingLeft || props.theme.space.s};
  font-size: ${props => props.fontSize || props.theme.space.m};
`

function CreateSubscriptionLink(props) {
  const currentUser = useCurrentUser()

  const signUpDetail = currentUser.signUpDetail || {}
  const promotionCode = signUpDetail.promotionCode || {}

  function renderText(){
    if (promotionCode.id) {
      return(
        `Upgrade to a paid plan and get your first ${promotionCode.numberOfIntervals} ${promotionCode.interval}s FREE`
      )
    } else {
      return (
        'Subscribe Now'
      )
    }
  }

  return (
    <Link to="/pricing">
      <Text paddingLeft={props.paddingLeft} fontSize={props.fontSize}>{renderText()}</Text>
    </Link>
  )
}

export default CreateSubscriptionLink
