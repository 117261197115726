import update from 'immutability-helper'
import { FETCH_COMPANY_DIRECTORS_REQUEST } from 'v2/spas/company/directors/redux/action-types.tsx'
import { FETCH_COMPANY_DIRECTORS_SUCCESS } from 'v2/spas/company/directors/redux/action-types.tsx'
import { FETCH_COMPANY_DIRECTORS_FAILURE } from 'v2/spas/company/directors/redux/action-types.tsx'
import { ARCHIVE_DIRECTOR_REQUEST } from 'v2/spas/company/directors/redux/action-types.tsx'
import { ARCHIVE_DIRECTOR_SUCCESS } from 'v2/spas/company/directors/redux/action-types.tsx'
import { ARCHIVE_DIRECTOR_FAILURE } from 'v2/spas/company/directors/redux/action-types.tsx'
import { DELETE_DIRECTOR_REQUEST } from 'v2/spas/company/directors/redux/action-types.tsx'
import { DELETE_DIRECTOR_SUCCESS } from 'v2/spas/company/directors/redux/action-types.tsx'
import { DELETE_DIRECTOR_FAILURE } from 'v2/spas/company/directors/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  company: {},
  directors: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_DIRECTORS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_COMPANY_DIRECTORS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_COMPANY_DIRECTORS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        company: {
          $set: action.payload.company,
        },
        directors: {
          $set: action.payload.company.directors
        },
      })
    }

    case ARCHIVE_DIRECTOR_REQUEST: {
      return state
    }

    case ARCHIVE_DIRECTOR_FAILURE: {
      return state
    }

    case ARCHIVE_DIRECTOR_SUCCESS: {
      let index = state.directors.findIndex(x => x.id === action.payload.director.id)

      return update(state, {
        directors: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_DIRECTOR_REQUEST: {
      return state
    }
    
    case DELETE_DIRECTOR_FAILURE: {
      return state
    }
    
    case DELETE_DIRECTOR_SUCCESS: {
      let index = state.directors.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        directors: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
