import update from 'immutability-helper'
import { FETCH_KEY_REQUEST } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { FETCH_KEY_SUCCESS } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { FETCH_KEY_FAILURE } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { UPDATE_KEY_REQUEST } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { UPDATE_KEY_SUCCESS } from 'v2/spas/owned-property/keys/redux/action-types.tsx'
import { UPDATE_KEY_FAILURE } from 'v2/spas/owned-property/keys/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  key: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_KEY_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_KEY_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_KEY_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        key: {
          $set: action.payload.key,
        }
      })
    }

    case UPDATE_KEY_REQUEST: {
      return update(state, {
        updateKeyStatus: { $set: 'requested' },
      })
    }

    case UPDATE_KEY_FAILURE: {
      return update(state, {
        updateKeyStatus: {
          $set: 'error',
        },
        updateKeyError: { $set: action.payload.error },
      })
    }

    case UPDATE_KEY_SUCCESS: {
      return update(state, {
        updateKeyStatus: { $set: 'success' },
        key: {
          $set: action.payload.key,
        }
      })
    }

    default:
      return state
  }
}