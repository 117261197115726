import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_PREVIOUS_SALES_REQUEST } from 'v2/spas/prospective-property/previous-sales/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_PREVIOUS_SALES_SUCCESS } from 'v2/spas/prospective-property/previous-sales/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_PREVIOUS_SALES_FAILURE } from 'v2/spas/prospective-property/previous-sales/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {},
  previousSales: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_PREVIOUS_SALES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_PREVIOUS_SALES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_PREVIOUS_SALES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        },
        previousSales: {
          $set: action.payload.prospectiveProperty.property.previousSales,
        },
      })
    }

    default:
      return state
  }
}
