import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import Navbar from 'sharedComponents/Navbar'
import Brand from 'sharedComponents/UserTopNavbar/Brand.js'

const Wrapper = styled.div`
  #brand {
    padding-left: 12.5%;

    #name {
      padding-left: 25px;
    }
}
`

function SubscriptionNavbar(props) {
  return (
    <Wrapper>
      <Navbar backgroundColor={props => props.theme.colors.green} height={props.height}>
        <Row>
          <Col xs="4" sm="4">
            <Brand navbarTheme={props.navbarTheme} />
          </Col>
        </Row>
      </Navbar>
    </Wrapper>
  )
}

export default SubscriptionNavbar