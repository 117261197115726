export const FETCH_REMORTGAGE_DEAL_REQUEST = 'FETCH_REMORTGAGE_DEAL_REQUEST'
export const FETCH_REMORTGAGE_DEAL_SUCCESS = 'FETCH_REMORTGAGE_DEAL_SUCCESS'
export const FETCH_REMORTGAGE_DEAL_FAILURE = 'FETCH_REMORTGAGE_DEAL_FAILURE'

export const UPDATE_REMORTGAGE_DEAL_REQUEST = 'UPDATE_REMORTGAGE_DEAL_REQUEST'
export const UPDATE_REMORTGAGE_DEAL_SUCCESS = 'UPDATE_REMORTGAGE_DEAL_SUCCESS'
export const UPDATE_REMORTGAGE_DEAL_FAILURE = 'UPDATE_REMORTGAGE_DEAL_FAILURE'

export const ARCHIVE_REMORTGAGE_DEAL_REQUEST = 'ARCHIVE_REMORTGAGE_DEAL_REQUEST'
export const ARCHIVE_REMORTGAGE_DEAL_SUCCESS = 'ARCHIVE_REMORTGAGE_DEAL_SUCCESS'
export const ARCHIVE_REMORTGAGE_DEAL_FAILURE = 'ARCHIVE_REMORTGAGE_DEAL_FAILURE'

export const UNARCHIVE_REMORTGAGE_DEAL_REQUEST = 'UNARCHIVE_REMORTGAGE_DEAL_REQUEST'
export const UNARCHIVE_REMORTGAGE_DEAL_SUCCESS = 'UNARCHIVE_REMORTGAGE_DEAL_SUCCESS'
export const UNARCHIVE_REMORTGAGE_DEAL_FAILURE = 'UNARCHIVE_REMORTGAGE_DEAL_FAILURE'

export const DELETE_REMORTGAGE_DEAL_REQUEST = 'DELETE_REMORTGAGE_DEAL_REQUEST'
export const DELETE_REMORTGAGE_DEAL_SUCCESS = 'DELETE_REMORTGAGE_DEAL_SUCCESS'
export const DELETE_REMORTGAGE_DEAL_FAILURE = 'DELETE_REMORTGAGE_DEAL_FAILURE'