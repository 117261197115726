export const FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_SALE_COMPARABLES_FAILURE'

export const FETCH_SALE_COMPARABLE_REQUEST = 'FETCH_SALE_COMPARABLE_REQUEST'
export const FETCH_SALE_COMPARABLE_SUCCESS = 'FETCH_SALE_COMPARABLE_SUCCESS'
export const FETCH_SALE_COMPARABLE_FAILURE = 'FETCH_SALE_COMPARABLE_FAILURE'

export const CREATE_SALE_COMPARABLE_REQUEST = 'CREATE_SALE_COMPARABLE_REQUEST'
export const CREATE_SALE_COMPARABLE_SUCCESS = 'CREATE_SALE_COMPARABLE_SUCCESS'
export const CREATE_SALE_COMPARABLE_FAILURE = 'CREATE_SALE_COMPARABLE_FAILURE'

export const UPDATE_SALE_COMPARABLE_REQUEST = 'UPDATE_SALE_COMPARABLE_REQUEST'
export const UPDATE_SALE_COMPARABLE_SUCCESS = 'UPDATE_SALE_COMPARABLE_SUCCESS'
export const UPDATE_SALE_COMPARABLE_FAILURE = 'UPDATE_SALE_COMPARABLE_FAILURE'

export const ARCHIVE_SALE_COMPARABLE_REQUEST = 'ARCHIVE_SALE_COMPARABLE_REQUEST'
export const ARCHIVE_SALE_COMPARABLE_SUCCESS = 'ARCHIVE_SALE_COMPARABLE_SUCCESS'
export const ARCHIVE_SALE_COMPARABLE_FAILURE = 'ARCHIVE_SALE_COMPARABLE_FAILURE'

export const UNARCHIVE_SALE_COMPARABLE_REQUEST = 'UNARCHIVE_SALE_COMPARABLE_REQUEST'
export const UNARCHIVE_SALE_COMPARABLE_SUCCESS = 'UNARCHIVE_SALE_COMPARABLE_SUCCESS'
export const UNARCHIVE_SALE_COMPARABLE_FAILURE = 'UNARCHIVE_SALE_COMPARABLE_FAILURE'

export const DELETE_SALE_COMPARABLE_REQUEST = 'DELETE_SALE_COMPARABLE_REQUEST'
export const DELETE_SALE_COMPARABLE_SUCCESS = 'DELETE_SALE_COMPARABLE_SUCCESS'
export const DELETE_SALE_COMPARABLE_FAILURE = 'DELETE_SALE_COMPARABLE_FAILURE'