import update from 'immutability-helper'
import { GLOBAL_SEARCH_REQUEST } from 'redux/actionTypes.js'
import { GLOBAL_SEARCH_FAILURE } from 'redux/actionTypes.js'
import { GLOBAL_SEARCH_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  globalSearchStatus: 'requested',
  globalSearchError: '',
  results: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GLOBAL_SEARCH_REQUEST: {
      return update(state, {
        globalSearchStatus: { $set: 'requested' },
      })
    }

    case GLOBAL_SEARCH_FAILURE: {
      return update(state, {
        globalSearchStatus: {
          $set: 'error',
        },
        globalSearchError: { $set: action.payload.error },
      })
    }

    case GLOBAL_SEARCH_SUCCESS: {
      return update(state, {
        globalSearchStatus: { $set: 'success' },
        results: {
          $set: action.payload.results,
        },
      })
    }

    default:
      return state
  }
}
