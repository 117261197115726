import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {SubmitButton} from 'buttons'
import TextInput from 'sharedComponents/Forms/TextInput.js'
import {changeEmailRequest} from 'redux/actions/profile/changeEmailRequest'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {useSnackbar} from 'notistack'
import {useCurrentEmailIdentity} from 'sharedComponents/useCurrentEmailIdentity'

const Wrapper = styled.div``

const FormSchema = Yup.object().shape({
  email: Yup.string().required('Required field *'),
})

function EmailForm(props) {
  const {enqueueSnackbar} = useSnackbar()
  const currentEmailIdentity = useCurrentEmailIdentity()

  function handleUpdate(response) {
    if (response.status === 'success') {
      props.showModal(false)
      enqueueSnackbar('Please check your emails!')
    } else if (response.status === 'error') {
      props.showModal(false)
      enqueueSnackbar(response.error, {variant: 'error'})
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          email: currentEmailIdentity.email,
        }}
        validationSchema={FormSchema}
        onSubmit={(values, {setSubmitting}) => {
          props.changeEmailRequest(
            currentEmailIdentity.id,
            values,
            handleUpdate,
          )
        }}
      >
        {({isSubmitting}) => (
          <Form>
            <TextInput label="Email" name="email" type="email" />

            <SubmitButton
              content="Update Email Address"
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  changeEmailRequest: changeEmailRequest,
}

export default connect(null, mapDispatchToProps)(EmailForm)
