import update from 'immutability-helper'
import { FETCH_PROPERTY_PRICE_PAID_RECORDS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_PROPERTY_PRICE_PAID_RECORDS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_PROPERTY_PRICE_PAID_RECORDS_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchPropertyPricePaidRecordsStatus: 'requested',
  fetchPropertyPricePaidRecordsError: '',
  propertyPricePaidRecords: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTY_PRICE_PAID_RECORDS_REQUEST: {
      return update(state, {
        fetchPropertyPricePaidRecordsStatus: { $set: 'requested' },
      })
    }

    case FETCH_PROPERTY_PRICE_PAID_RECORDS_FAILURE: {
      return update(state, {
        fetchPropertyPricePaidRecordsStatus: {
          $set: 'error',
        },
        fetchPropertyPricePaidRecordsError: { $set: action.payload.error },
      })
    }

    case FETCH_PROPERTY_PRICE_PAID_RECORDS_SUCCESS: {
      return update(state, {
        fetchPropertyPricePaidRecordsStatus: { $set: 'success' },
        propertyPricePaidRecords: {
          $set: action.payload.propertyPricePaidRecords,
        },
      })
    }

    default:
      return state
  }
}
