import update from 'immutability-helper'
import { FETCH_ACCOUNT_USER_WORKFLOWS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_WORKFLOWS_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_ACCOUNT_USER_WORKFLOWS_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  fetchAccountUserWorkflowsStatus: 'requested',
  fetchAccountUserWorkflowsError: '',
  accountUser: {},
  workflows: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_USER_WORKFLOWS_REQUEST: {
      return update(state, {
        fetchAccountUserWorkflowsStatus: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USER_WORKFLOWS_FAILURE: {
      return update(state, {
        fetchAccountUserWorkflowsStatus: {
          $set: 'error',
        },
        fetchAccountUserWorkflowsError: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USER_WORKFLOWS_SUCCESS: {
      return update(state, {
        fetchAccountUserWorkflowsStatus: { $set: 'success' },
        accountUser: {
          $set: action.payload.accountUser,
        },
        workflows: {
          $set: action.payload.accountUser.workflows,
        },
      })
    }

    default:
      return state
  }
}
