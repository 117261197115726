export const FETCH_ACCOUNT_COMPANIES_REQUEST = 'FETCH_ACCOUNT_COMPANIES_REQUEST'
export const FETCH_ACCOUNT_COMPANIES_SUCCESS = 'FETCH_ACCOUNT_COMPANIES_SUCCESS'
export const FETCH_ACCOUNT_COMPANIES_FAILURE = 'FETCH_ACCOUNT_COMPANIES_FAILURE'

export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE'

export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE'

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE'

export const ARCHIVE_COMPANY_REQUEST = 'ARCHIVE_COMPANY_REQUEST'
export const ARCHIVE_COMPANY_SUCCESS = 'ARCHIVE_COMPANY_SUCCESS'
export const ARCHIVE_COMPANY_FAILURE = 'ARCHIVE_COMPANY_FAILURE'

export const UNARCHIVE_COMPANY_REQUEST = 'UNARCHIVE_COMPANY_REQUEST'
export const UNARCHIVE_COMPANY_SUCCESS = 'UNARCHIVE_COMPANY_SUCCESS'
export const UNARCHIVE_COMPANY_FAILURE = 'UNARCHIVE_COMPANY_FAILURE'

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST'
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE'