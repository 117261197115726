export const FETCH_PROSPECTIVE_PROPERTY_REQUEST = 'FETCH_PROSPECTIVE_PROPERTY_REQUEST'
export const FETCH_PROSPECTIVE_PROPERTY_SUCCESS = 'FETCH_PROSPECTIVE_PROPERTY_SUCCESS'
export const FETCH_PROSPECTIVE_PROPERTY_FAILURE = 'FETCH_PROSPECTIVE_PROPERTY_FAILURE'

export const CREATE_PROSPECTIVE_PROPERTY_REQUEST = 'CREATE_PROSPECTIVE_PROPERTY_REQUEST'
export const CREATE_PROSPECTIVE_PROPERTY_SUCCESS = 'CREATE_PROSPECTIVE_PROPERTY_SUCCESS'
export const CREATE_PROSPECTIVE_PROPERTY_FAILURE = 'CREATE_PROSPECTIVE_PROPERTY_FAILURE'

export const UPDATE_PROSPECTIVE_PROPERTY_REQUEST = 'UPDATE_PROSPECTIVE_PROPERTY_REQUEST'
export const UPDATE_PROSPECTIVE_PROPERTY_SUCCESS = 'UPDATE_PROSPECTIVE_PROPERTY_SUCCESS'
export const UPDATE_PROSPECTIVE_PROPERTY_FAILURE = 'UPDATE_PROSPECTIVE_PROPERTY_FAILURE'

export const ARCHIVE_PROSPECTIVE_PROPERTY_REQUEST = 'ARCHIVE_PROSPECTIVE_PROPERTY_REQUEST'
export const ARCHIVE_PROSPECTIVE_PROPERTY_SUCCESS = 'ARCHIVE_PROSPECTIVE_PROPERTY_SUCCESS'
export const ARCHIVE_PROSPECTIVE_PROPERTY_FAILURE = 'ARCHIVE_PROSPECTIVE_PROPERTY_FAILURE'

export const UNARCHIVE_PROSPECTIVE_PROPERTY_REQUEST = 'UNARCHIVE_PROSPECTIVE_PROPERTY_REQUEST'
export const UNARCHIVE_PROSPECTIVE_PROPERTY_SUCCESS = 'UNARCHIVE_PROSPECTIVE_PROPERTY_SUCCESS'
export const UNARCHIVE_PROSPECTIVE_PROPERTY_FAILURE = 'UNARCHIVE_PROSPECTIVE_PROPERTY_FAILURE'

export const DELETE_PROSPECTIVE_PROPERTY_REQUEST = 'DELETE_PROSPECTIVE_PROPERTY_REQUEST'
export const DELETE_PROSPECTIVE_PROPERTY_SUCCESS = 'DELETE_PROSPECTIVE_PROPERTY_SUCCESS'
export const DELETE_PROSPECTIVE_PROPERTY_FAILURE = 'DELETE_PROSPECTIVE_PROPERTY_FAILURE'

export const UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_REQUEST = 'UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_REQUEST'
export const UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_SUCCESS = 'UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_SUCCESS'
export const UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_FAILURE = 'UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_FAILURE'