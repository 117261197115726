import update from 'immutability-helper'
import { FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_REQUEST } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_SUCCESS } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_FAILURE } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { ARCHIVE_INSURANCE_APPLICATION_REQUEST } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { ARCHIVE_INSURANCE_APPLICATION_SUCCESS } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { ARCHIVE_INSURANCE_APPLICATION_FAILURE } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { UPDATE_INSURANCE_APPLICATION_REQUEST } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { UPDATE_INSURANCE_APPLICATION_SUCCESS } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { UPDATE_INSURANCE_APPLICATION_FAILURE } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { DELETE_INSURANCE_APPLICATION_REQUEST } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { DELETE_INSURANCE_APPLICATION_SUCCESS } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'
import { DELETE_INSURANCE_APPLICATION_FAILURE } from 'v2/spas/owned-property/insurance-applications/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  ownedProperty: {},
  insuranceApplications: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_OWNED_PROPERTY_INSURANCE_APPLICATIONS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        ownedProperty: {
          $set: action.payload.ownedProperty,
        },
        insuranceApplications: {
          $set: action.payload.ownedProperty.property.insuranceApplications
        },
      })
    }

    case UPDATE_INSURANCE_APPLICATION_REQUEST: {
      return state
    }

    case UPDATE_INSURANCE_APPLICATION_FAILURE: {
      return state
    }

    case UPDATE_INSURANCE_APPLICATION_SUCCESS: {
      let index = state.insuranceApplications.findIndex(
        x => x.id === action.payload.insuranceApplication.id,
      )

      return update(state, {
        insuranceApplications: {
          [index]: { $set: action.payload.insuranceApplication },
        },
      })
    }

    case ARCHIVE_INSURANCE_APPLICATION_REQUEST: {
      return state
    }

    case ARCHIVE_INSURANCE_APPLICATION_FAILURE: {
      return state
    }

    case ARCHIVE_INSURANCE_APPLICATION_SUCCESS: {
      let index = state.insuranceApplications.findIndex(x => x.id === action.payload.insuranceApplication.id)

      return update(state, {
        insuranceApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_INSURANCE_APPLICATION_REQUEST: {
      return state
    }

    case DELETE_INSURANCE_APPLICATION_FAILURE: {
      return state
    }

    case DELETE_INSURANCE_APPLICATION_SUCCESS: {
      let index = state.insuranceApplications.findIndex(x => x.id === action.payload.id)

      return update(state, {
        insuranceApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
