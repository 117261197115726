import update from 'immutability-helper'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_DEAL_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_DEAL_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_DEAL_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
// import { CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST } from 'redux/actionTypes.js'
// import { CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE } from 'redux/actionTypes.js'
// import { CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS } from 'redux/actionTypes.js'
// import { UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST } from 'redux/actionTypes.js'
// import { UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE } from 'redux/actionTypes.js'
// import { UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchRentToServicedAccommodationDealStatus: 'requested',
  fetchRentToServicedAccommodationDealError: '',
  rentToServicedAccommodationDeal: {
    deal: {},
  },
  fetchRentToServicedAccommodationDealTemplatesStatus: 'requested',
  fetchRentToServicedAccommodationDealTemplatesError: '',
  dealTemplates: [],
  fetchRentToServicedAccommodationDealVariablePurchaseExpensesStatus: 'requested',
  fetchRentToServicedAccommodationDealVariablePurchaseExpensesError: '',
  variablePurchaseExpenses: [],
  fetchRentToServicedAccommodationDealDevelopmentExpensesStatus: 'requested',
  fetchRentToServicedAccommodationDealDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchRentToServicedAccommodationDealVariableRecurringExpensesStatus: 'requested',
  fetchRentToServicedAccommodationDealVariableRecurringExpensesError: '',
  variableRecurringExpenses: [],
  fetchRentToServicedAccommodationDealDuringDevelopmentExpensesStatus: 'requested',
  fetchRentToServicedAccommodationDealDuringDevelopmentExpensesError: '',
  duringDevelopmentExpenses: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return update(state, {
        fetchRentToServicedAccommodationDealStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return update(state, {
        fetchRentToServicedAccommodationDealStatus: {
          $set: 'error',
        },
        fetchRentToServicedAccommodationDealError: { $set: action.payload.error },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        fetchRentToServicedAccommodationDealStatus: { $set: 'success' },
        rentToServicedAccommodationDeal: {
          $set: action.payload.rentToServicedAccommodationDeal,
        },
      })
    }

    case UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_REQUEST: {
      return state
    }

    case UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_FAILURE: {
      return state
    }

    case UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_SUCCESS: {
      return update(state, {
        rentToServicedAccommodationDeal: {
          $set: action.payload.rentToServicedAccommodationDeal
        }
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      return update(state, {
        rentToServicedAccommodationDeal: {
          deal: {
            name: {
              $set: action.payload.deal.name,
            },
          },
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        fetchRentToServicedAccommodationDealTemplatesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        fetchRentToServicedAccommodationDealTemplatesStatus: {
          $set: 'error',
        },
        fetchRentToServicedAccommodationDealTemplatesError: { $set: action.payload.error },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchRentToServicedAccommodationDealTemplatesStatus: { $set: 'success' },
        dealTemplates: {
          $set: action.payload.dealTemplates,
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToServicedAccommodationDealVariablePurchaseExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToServicedAccommodationDealVariablePurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchRentToServicedAccommodationDealVariablePurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToServicedAccommodationDealVariablePurchaseExpensesStatus: { $set: 'success' },
        variablePurchaseExpenses: {
          $set: action.payload.variablePurchaseExpenses,
        },
      })
    }

    case UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_EXPENSES_SUCCESS: {
      return update(state, {
        rentToServicedAccommodationDeal: {
          $set: action.payload.rentToServicedAccommodationDeal
        }
      })
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToServicedAccommodationDeal: {
          $set: action.payload.rentToServicedAccommodationDeal
        }
      })
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToServicedAccommodationDeal: {
          $set: action.payload.rentToServicedAccommodationDeal
        }
      })
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToServicedAccommodationDeal: {
          $set: action.payload.rentToServicedAccommodationDeal
        }
      })
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToServicedAccommodationDeal: {
          $set: action.payload.rentToServicedAccommodationDeal
        }
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToServicedAccommodationDealDevelopmentExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToServicedAccommodationDealDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchRentToServicedAccommodationDealDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToServicedAccommodationDealDevelopmentExpensesStatus: { $set: 'success' },
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToServicedAccommodationDealVariableRecurringExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToServicedAccommodationDealVariableRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchRentToServicedAccommodationDealVariableRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToServicedAccommodationDealVariableRecurringExpensesStatus: { $set: 'success' },
        variableRecurringExpenses: {
          $set: action.payload.variableRecurringExpenses,
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToServicedAccommodationDealDuringDevelopmentExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToServicedAccommodationDealDuringDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchRentToServicedAccommodationDealDuringDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_SERVICED_ACCOMMODATION_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToServicedAccommodationDealDuringDevelopmentExpensesStatus: { $set: 'success' },
        duringDevelopmentExpenses: {
          $set: action.payload.duringDevelopmentExpenses,
        },
      })
    }

    // case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST: {
    //   return state
    // }

    // case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE: {
    //   return state
    // }

    // case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS: {
    //   return update(state, {
    //     singleLetDeal: {
    //       purchaseCosts: { $set: action.payload.singleLetDeal.purchaseCosts },
    //       developmentCosts: {
    //         $set: action.payload.singleLetDeal.developmentCosts,
    //       },
    //       cashRequired: { $set: action.payload.singleLetDeal.cashRequired },
    //       returnOnInvestment: {
    //         $set: action.payload.singleLetDeal.returnOnInvestment,
    //       },
    //       equityReleased: { $set: action.payload.singleLetDeal.equityReleased },
    //       moneyLeftIn: { $set: action.payload.singleLetDeal.moneyLeftIn },
    //       projectedReturnOnInvestment: {
    //         $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
    //       },
    //     },
    //   })
    // }

    // case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST: {
    //   return state
    // }

    // case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE: {
    //   return state
    // }

    // case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS: {
    //   return update(state, {
    //     singleLetDeal: {
    //       deal: {
    //         stackingSupportNeeded: {
    //           $set: action.payload.stackingSupportNeeded
    //         }
    //       },
    //     },
    //   })
    // }

    default:
      return state
  }
}
