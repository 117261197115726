import React from 'react'
import {Link} from 'react-router-dom'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import CheckIcon from '@material-ui/icons/Check'
import styled from 'styled-components'
import { Button } from 'buttons'
import { useCurrentAccountUser } from 'sharedComponents/useCurrentAccountUser'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { addCheckoutSession } from 'redux/actions/subscriptions/addCheckoutSession.tsx'

const Wrapper = styled(Card)`
  padding-top: ${props => props.theme.space.l};
  padding-bottom: ${props => props.theme.space.l};
  padding-left: ${props => props.theme.space.l};
  padding-right: ${props => props.theme.space.l};
  background: ${props => props.background || 'white'};
  border: solid ${props => props.theme.colors.green};
  border-width: ${props => props.isSelected ? '5px' : '5px 0 0'};
  box-shadow: 0 8px 40px -12px rgba(0,0,0,0.3);
  text-align: center;
  margin-top: ${props => props.theme.space.xl};
  &:hover {
    border: 5px solid ${props => props.theme.colors.green};
  }

  #button {
    margin-top: ${props => props.theme.space.l};
    width: 90%;
  }

  @media ${props => props.theme.devices.desktop} {
    margin-left: ${props => props.theme.space.m} !important;
    margin-right: ${props => props.theme.space.m} !important;
    width: 90%;
  }
`

const Description = styled.div`
  padding-bottom: ${props => props.theme.space.m};
  padding-left: ${props => props.theme.space.xl};
  padding-right: ${props => props.theme.space.xl};
`

const Checkmark = styled(CheckIcon)`
  color: ${props => props.theme.colors.green};
`;

const Benefits = styled.div`
  padding-top: ${props => props.theme.space.l};
  padding-bottom: ${props => props.theme.space.l};
`;

const Benefit = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: ${props => props.theme.space.m};
  text-align: left;
  padding-bottom: ${props => props.theme.space.s};

  p {
    padding-left: ${props => props.theme.space.m};
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    padding-top: 0px !important;
    padding-left: ${props => props.theme.space.s};
    margin-bottom: ${props => props.theme.space.s};
  }
`;

function PricingCard(props) {
  const currentAccountUser = useCurrentAccountUser()

  const { enqueueSnackbar } = useSnackbar()

  let subscriptionProduct = props.subscriptionProduct || {}
  let formattedPrice = `£${subscriptionProduct.unitAmount}`
  
  function formattedInterval() {
    if (subscriptionProduct.interval === 'annual') {
      return '/ year'
    } else {
      return '/ month'
    }
  }

  function renderButton() {
    if (currentAccountUser.id) {
      return (
        <Button 
          content='Subscribe Now'
          onClick={handleClickSubscribeNow}
        />
      )
    } else {
      return(
        <Link to='/sign-up'>
          <Button content='Start a FREE 30 day trial' />
        </Link>
      )
    }
  }

  function getClientReferenceId() {
    return window.Rewardful && window.Rewardful.referral || ('checkout_' + (new Date).getTime());
  }

  function handleClickSubscribeNow() {
    props.addCheckoutSession({
      priceId: subscriptionProduct.stripePriceId,
      clientReferenceId: getClientReferenceId(),
      handleResponse: handleCreateCheckoutSession
    })
  }

  function handleCreateCheckoutSession(response) {
    if (response.status === 'success') {
      window.location.href = response.checkoutSessionUrl
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, { variant: 'error' })
    }
  }

  return (
    <Wrapper id='pricing-card'>

      <h2>Business</h2>
      
      <Description>
        <p>
          If you want to build and scale your roperty Investment Business on Stacked, this is the plan for you.
        </p>
        <p>
          It includes unlimited access to every feature on the platform.
        </p>

      </Description>

      <PriceWrapper>
        <h2>{formattedPrice}</h2>
        <p>{formattedInterval()}</p>
      </PriceWrapper>
      
      <Divider />
      
      <Benefits>
        <Benefit>
          <Checkmark />
          <p>Stack deals (using a variety of creative strategies)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Raise public finance (e.g. mortgages & bridging)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Raise private finance (using investment opportunities)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Centralise all your communication (using workflows)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Manage your portfolio (properties, tenancies, mortgage accounts etc)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Receive timely reminders (e.g. for expiring tenancies and mortgage products)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Access to live and on-demand training</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Access to coaching, mentoring and support</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Expand your network of property professionals</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Priority access to members only events (online and in-person)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Stay accountable to your success (in-app notifications + regular webinars)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Live chat support facility</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Priority access to new features (released every week)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>30 day free trial (no credit card required - cancel any time)</p>
        </Benefit>

        <Benefit>
          <Checkmark />
          <p>Unlimited users (no additional per user fees)</p>
        </Benefit>
      </Benefits>
      
      {renderButton()}
    </Wrapper>
  );
};

const mapDispatchToProps = {
  addCheckoutSession: addCheckoutSession
}

export default connect(null, mapDispatchToProps)(PricingCard)
