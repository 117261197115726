import update from 'immutability-helper'
import { FETCH_RENT_TO_RENT_DEAL_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_RENT_DEAL_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_RENT_DEAL_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_RENT_DEAL_SUCCESS } from 'redux/actionTypes.js'
import { UPDATE_DEAL_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_DEAL_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_DEAL_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_TEMPLATES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_TEMPLATES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_TEMPLATES_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_RENT_DEAL_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_RENT_DEAL_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { UPDATE_RENT_TO_RENT_DEAL_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE } from 'redux/actionTypes.js'
import { CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS } from 'redux/actionTypes.js'
// import { CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST } from 'redux/actionTypes.js'
// import { CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE } from 'redux/actionTypes.js'
// import { CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS } from 'redux/actionTypes.js'
// import { UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST } from 'redux/actionTypes.js'
// import { UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE } from 'redux/actionTypes.js'
// import { UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchRentToRentDealStatus: 'requested',
  fetchRentToRentDealError: '',
  rentToRentDeal: {
    deal: {},
  },
  fetchRentToRentDealTemplatesStatus: 'requested',
  fetchRentToRentDealTemplatesError: '',
  dealTemplates: [],
  fetchRentToRentDealVariablePurchaseExpensesStatus: 'requested',
  fetchRentToRentDealVariablePurchaseExpensesError: '',
  variablePurchaseExpenses: [],
  fetchRentToRentDealDevelopmentExpensesStatus: 'requested',
  fetchRentToRentDealDevelopmentExpensesError: '',
  developmentExpenses: [],
  fetchRentToRentDealVariableRecurringExpensesStatus: 'requested',
  fetchRentToRentDealVariableRecurringExpensesError: '',
  variableRecurringExpenses: [],
  fetchRentToRentDealDuringDevelopmentExpensesStatus: 'requested',
  fetchRentToRentDealDuringDevelopmentExpensesError: '',
  duringDevelopmentExpenses: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_RENT_TO_RENT_DEAL_REQUEST: {
      return update(state, {
        fetchRentToRentDealStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_FAILURE: {
      return update(state, {
        fetchRentToRentDealStatus: {
          $set: 'error',
        },
        fetchRentToRentDealError: { $set: action.payload.error },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_SUCCESS: {
      return update(state, {
        fetchRentToRentDealStatus: { $set: 'success' },
        rentToRentDeal: {
          $set: action.payload.rentToRentDeal,
        },
      })
    }

    case UPDATE_RENT_TO_RENT_DEAL_REQUEST: {
      return state
    }

    case UPDATE_RENT_TO_RENT_DEAL_FAILURE: {
      return state
    }

    case UPDATE_RENT_TO_RENT_DEAL_SUCCESS: {
      return update(state, {
        rentToRentDeal: {
          $set: action.payload.rentToRentDeal
        }
      })
    }

    case UPDATE_DEAL_REQUEST: {
      return state
    }

    case UPDATE_DEAL_FAILURE: {
      return state
    }

    case UPDATE_DEAL_SUCCESS: {
      return update(state, {
        rentToRentDeal: {
          deal: {
            name: {
              $set: action.payload.deal.name,
            },
          },
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_TEMPLATES_REQUEST: {
      return update(state, {
        fetchRentToRentDealTemplatesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_TEMPLATES_FAILURE: {
      return update(state, {
        fetchRentToRentDealTemplatesStatus: {
          $set: 'error',
        },
        fetchRentToRentDealTemplatesError: { $set: action.payload.error },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_TEMPLATES_SUCCESS: {
      return update(state, {
        fetchRentToRentDealTemplatesStatus: { $set: 'success' },
        dealTemplates: {
          $set: action.payload.dealTemplates,
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToRentDealVariablePurchaseExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToRentDealVariablePurchaseExpensesStatus: {
          $set: 'error',
        },
        fetchRentToRentDealVariablePurchaseExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_VARIABLE_PURCHASE_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToRentDealVariablePurchaseExpensesStatus: { $set: 'success' },
        variablePurchaseExpenses: {
          $set: action.payload.variablePurchaseExpenses,
        },
      })
    }

    case UPDATE_RENT_TO_RENT_DEAL_EXPENSES_REQUEST: {
      return state
    }

    case UPDATE_RENT_TO_RENT_DEAL_EXPENSES_FAILURE: {
      return state
    }

    case UPDATE_RENT_TO_RENT_DEAL_EXPENSES_SUCCESS: {
      return update(state, {
        rentToRentDeal: {
          $set: action.payload.rentToRentDeal
        }
      })
    }

    case CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_PURCHASE_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToRentDeal: {
          $set: action.payload.rentToRentDeal
        }
      })
    }

    case CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToRentDeal: {
          $set: action.payload.rentToRentDeal
        }
      })
    }

    case CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_RECURRING_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToRentDeal: {
          $set: action.payload.rentToRentDeal
        }
      })
    }

    case CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_REQUEST: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_FAILURE: {
      return state
    }

    case CREATE_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FROM_TEMPLATE_SUCCESS: {
      return update(state, {
        rentToRentDeal: {
          $set: action.payload.rentToRentDeal
        }
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToRentDealDevelopmentExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToRentDealDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchRentToRentDealDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToRentDealDevelopmentExpensesStatus: { $set: 'success' },
        developmentExpenses: {
          $set: action.payload.developmentExpenses,
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToRentDealVariableRecurringExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToRentDealVariableRecurringExpensesStatus: {
          $set: 'error',
        },
        fetchRentToRentDealVariableRecurringExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_VARIABLE_RECURRING_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToRentDealVariableRecurringExpensesStatus: { $set: 'success' },
        variableRecurringExpenses: {
          $set: action.payload.variableRecurringExpenses,
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_REQUEST: {
      return update(state, {
        fetchRentToRentDealDuringDevelopmentExpensesStatus: { $set: 'requested' },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_FAILURE: {
      return update(state, {
        fetchRentToRentDealDuringDevelopmentExpensesStatus: {
          $set: 'error',
        },
        fetchRentToRentDealDuringDevelopmentExpensesError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_RENT_TO_RENT_DEAL_DURING_DEVELOPMENT_EXPENSES_SUCCESS: {
      return update(state, {
        fetchRentToRentDealDuringDevelopmentExpensesStatus: { $set: 'success' },
        duringDevelopmentExpenses: {
          $set: action.payload.duringDevelopmentExpenses,
        },
      })
    }

    // case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_REQUEST: {
    //   return state
    // }

    // case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_FAILURE: {
    //   return state
    // }

    // case CLEAR_EXPENSES_FROM_SINGLE_LET_DEAL_SUCCESS: {
    //   return update(state, {
    //     singleLetDeal: {
    //       purchaseCosts: { $set: action.payload.singleLetDeal.purchaseCosts },
    //       developmentCosts: {
    //         $set: action.payload.singleLetDeal.developmentCosts,
    //       },
    //       cashRequired: { $set: action.payload.singleLetDeal.cashRequired },
    //       returnOnInvestment: {
    //         $set: action.payload.singleLetDeal.returnOnInvestment,
    //       },
    //       equityReleased: { $set: action.payload.singleLetDeal.equityReleased },
    //       moneyLeftIn: { $set: action.payload.singleLetDeal.moneyLeftIn },
    //       projectedReturnOnInvestment: {
    //         $set: action.payload.singleLetDeal.projectedReturnOnInvestment,
    //       },
    //     },
    //   })
    // }

    // case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_REQUEST: {
    //   return state
    // }

    // case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_FAILURE: {
    //   return state
    // }

    // case UPDATE_SINGLE_LET_DEAL_SUPPORT_NEEDED_SUCCESS: {
    //   return update(state, {
    //     singleLetDeal: {
    //       deal: {
    //         stackingSupportNeeded: {
    //           $set: action.payload.stackingSupportNeeded
    //         }
    //       },
    //     },
    //   })
    // }

    default:
      return state
  }
}
