function useFormattedAddress(address) {
  if (!address) {
    return ''
  } else if (!address.addressLine1) {
    return ''
  } else if (address.addressLine2) {
    return (
      address.addressLine1 +
      ', ' +
      address.addressLine2 +
      ', ' +
      address.zipCode
    )
  } else {
    return address.addressLine1 + ', ' + address.zipCode
  }
}

export default useFormattedAddress
