import update from 'immutability-helper'
import { FETCH_MORTGAGE_APPLICANT_REQUEST } from 'redux/actionTypes.js'
import { FETCH_MORTGAGE_APPLICANT_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_MORTGAGE_APPLICANT_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  fetchMortgageApplicantStatus: 'requested',
  fetchMortgageApplicantError: '',
  mortgageApplicant: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MORTGAGE_APPLICANT_REQUEST: {
      return update(state, {
        fetchMortgageApplicantStatus: { $set: 'requested' },
      })
    }

    case FETCH_MORTGAGE_APPLICANT_FAILURE: {
      return update(state, {
        fetchMortgageApplicantStatus: {
          $set: 'error',
        },
        fetchMortgageApplicantError: { $set: action.payload.error },
      })
    }

    case FETCH_MORTGAGE_APPLICANT_SUCCESS: {
      return update(state, {
        fetchMortgageApplicantStatus: { $set: 'success' },
        mortgageApplicant: {
          $set: action.payload.mortgageApplicant,
        },
      })
    }

    default:
      return state
  }
}
