import React from 'react'
import styled from 'styled-components'
import UserAvatar from 'spas/Profile/ProfilePageNavbar/UserAvatar'
import ContentDetails from 'spas/Profile/ProfilePageNavbar/ContentDetails'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex: 1;
  padding-top: 25px;

  @media ${props => props.theme.devices.mobile} {
    flex-direction: column;
  }
`

function ProfilePageNavbarHeading(props) {
  return (
    <Wrapper>
      <UserAvatar />
      <ContentDetails {...props} />
    </Wrapper>
  )
}

export default ProfilePageNavbarHeading
