export const FETCH_OWNED_PROPERTY_TENANCIES_REQUEST = 'FETCH_OWNED_PROPERTY_TENANCIES_REQUEST'
export const FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS = 'FETCH_OWNED_PROPERTY_TENANCIES_SUCCESS'
export const FETCH_OWNED_PROPERTY_TENANCIES_FAILURE = 'FETCH_OWNED_PROPERTY_TENANCIES_FAILURE'

export const FETCH_TENANCY_REQUEST = 'FETCH_TENANCY_REQUEST'
export const FETCH_TENANCY_SUCCESS = 'FETCH_TENANCY_SUCCESS'
export const FETCH_TENANCY_FAILURE = 'FETCH_TENANCY_FAILURE'

export const CREATE_TENANCY_REQUEST = 'CREATE_TENANCY_REQUEST'
export const CREATE_TENANCY_SUCCESS = 'CREATE_TENANCY_SUCCESS'
export const CREATE_TENANCY_FAILURE = 'CREATE_TENANCY_FAILURE'

export const UPDATE_TENANCY_REQUEST = 'UPDATE_TENANCY_REQUEST'
export const UPDATE_TENANCY_SUCCESS = 'UPDATE_TENANCY_SUCCESS'
export const UPDATE_TENANCY_FAILURE = 'UPDATE_TENANCY_FAILURE'

export const ARCHIVE_TENANCY_REQUEST = 'ARCHIVE_TENANCY_REQUEST'
export const ARCHIVE_TENANCY_SUCCESS = 'ARCHIVE_TENANCY_SUCCESS'
export const ARCHIVE_TENANCY_FAILURE = 'ARCHIVE_TENANCY_FAILURE'

export const UNARCHIVE_TENANCY_REQUEST = 'UNARCHIVE_TENANCY_REQUEST'
export const UNARCHIVE_TENANCY_SUCCESS = 'UNARCHIVE_TENANCY_SUCCESS'
export const UNARCHIVE_TENANCY_FAILURE = 'UNARCHIVE_TENANCY_FAILURE'

export const DELETE_TENANCY_REQUEST = 'DELETE_TENANCY_REQUEST'
export const DELETE_TENANCY_SUCCESS = 'DELETE_TENANCY_SUCCESS'
export const DELETE_TENANCY_FAILURE = 'DELETE_TENANCY_FAILURE'