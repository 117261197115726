import update from 'immutability-helper'
import {FETCH_EXISTING_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_ACCOUNTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_ACCOUNTS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_ACCOUNTS_FAILURE} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_EXISTING_MORTGAGE_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_EXISTING_MORTGAGE_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_EXISTING_MORTGAGE_SUCCESS} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {ADD_MORTGAGE_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {UPDATE_MORTGAGE_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_MORTGAGE_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {DELETE_MORTGAGE_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {DELETE_MORTGAGE_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGE_DOCUMENTS_REQUEST} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGE_DOCUMENTS_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_EXISTING_MORTGAGE_DOCUMENTS_FAILURE} from 'redux/actionTypes.js'
import {ADD_EXISTING_MORTGAGE_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {ADD_EXISTING_MORTGAGE_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {ADD_EXISTING_MORTGAGE_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_REQUEST} from 'redux/actionTypes.js'
import {UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_FAILURE} from 'redux/actionTypes.js'
import {UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_SUCCESS} from 'redux/actionTypes.js'
// import {UPDATE_EXISTING_MORTGAGE_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
// import {UPDATE_EXISTING_MORTGAGE_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
// import {UPDATE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'
import {DELETE_EXISTING_MORTGAGE_DOCUMENT_REQUEST} from 'redux/actionTypes.js'
import {DELETE_EXISTING_MORTGAGE_DOCUMENT_FAILURE} from 'redux/actionTypes.js'
import {DELETE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS} from 'redux/actionTypes.js'

const initialState = {
  fetchExistingMortgageStatus: 'requested',
  fetchExistingMortgageError: '',
  mortgage: {
    mortgageLender: {
      currentAccountMortgageProducts: [],
    },
  },
  fetchMortgageAccountsStatus: 'requested',
  fetchMortgageAccountsError: '',
  mortgageAccounts: [],
  fetchMortgageLenderProductsForAccountStatus: 'requested',
  fetchMortgageLenderProductsForAccountError: '',
  mortgageProducts: [],
  fetchExistingMortgageDocumentsStatus: 'requested',
  fetchExistingMortgageDocumentsError: '',
  documents: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_EXISTING_MORTGAGE_REQUEST: {
      return update(state, {
        fetchExistingMortgageStatus: {$set: 'requested'},
      })
    }

    case FETCH_EXISTING_MORTGAGE_FAILURE: {
      return update(state, {
        fetchExistingMortgageStatus: {
          $set: 'error',
        },
        fetchExistingMortgageError: {$set: action.payload.error},
      })
    }

    case FETCH_EXISTING_MORTGAGE_SUCCESS: {
      return update(state, {
        fetchExistingMortgageStatus: {$set: 'success'},
        mortgage: {
          $set: action.payload.mortgage,
        },
      })
    }

    case FETCH_MORTGAGE_ACCOUNTS_REQUEST: {
      return update(state, {
        fetchMortgageAccountsStatus: {$set: 'requested'},
      })
    }

    case FETCH_MORTGAGE_ACCOUNTS_FAILURE: {
      return update(state, {
        fetchMortgageAccountsStatus: {
          $set: 'error',
        },
        fetchMortgageAccountsError: {$set: action.payload.error},
      })
    }

    case FETCH_MORTGAGE_ACCOUNTS_SUCCESS: {
      return update(state, {
        fetchMortgageAccountsStatus: {$set: 'success'},
        mortgageAccounts: {
          $set: action.payload.mortgageAccounts,
        },
      })
    }

    case FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_REQUEST: {
      return update(state, {
        fetchMortgageLenderProductsForAccountStatus: {$set: 'requested'},
      })
    }

    case FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_FAILURE: {
      return update(state, {
        fetchMortgageLenderProductsForAccountStatus: {
          $set: 'error',
        },
        fetchMortgageLenderProductsForAccountError: {
          $set: action.payload.error,
        },
      })
    }

    case FETCH_MORTGAGE_LENDER_PRODUCTS_FOR_ACCOUNT_SUCCESS: {
      return update(state, {
        fetchMortgageLenderProductsForAccountStatus: {$set: 'success'},
        mortgageProducts: {
          $set: action.payload.mortgageProducts,
        },
      })
    }

    case UPDATE_EXISTING_MORTGAGE_REQUEST: {
      return state
    }

    case UPDATE_EXISTING_MORTGAGE_FAILURE: {
      return state
    }

    case UPDATE_EXISTING_MORTGAGE_SUCCESS: {
      return update(state, {
        mortgage: {
          $set: action.payload.mortgage,
        },
      })
    }

    case ADD_MORTGAGE_ACCOUNT_REQUEST: {
      return state
    }

    case ADD_MORTGAGE_ACCOUNT_FAILURE: {
      return state
    }

    case ADD_MORTGAGE_ACCOUNT_SUCCESS: {
      return update(state, {
        mortgageAccounts: {
          $push: [action.payload.mortgageAccount],
        },
      })
    }

    case UPDATE_MORTGAGE_ACCOUNT_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_ACCOUNT_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_ACCOUNT_SUCCESS: {
      let index = state.mortgageAccounts.findIndex(
        x => x.id === action.payload.mortgageAccount.id,
      )

      return update(state, {
        mortgageAccounts: {
          [index]: {$set: action.payload.mortgageAccount},
        },
      })
    }

    case DELETE_MORTGAGE_ACCOUNT_REQUEST: {
      return state
    }

    case DELETE_MORTGAGE_ACCOUNT_FAILURE: {
      return state
    }

    case DELETE_MORTGAGE_ACCOUNT_SUCCESS: {
      let index = state.mortgageAccounts.findIndex(
        x => x.id === action.payload.id,
      )

      return update(state, {
        mortgageAccounts: {
          $splice: [[index, 1]],
        },
      })
    }

    case FETCH_EXISTING_MORTGAGE_DOCUMENTS_REQUEST: {
      return update(state, {
        fetchExistingMortgageDocumentsStatus: {$set: 'requested'},
      })
    }

    case FETCH_EXISTING_MORTGAGE_DOCUMENTS_FAILURE: {
      return update(state, {
        fetchExistingMortgageDocumentsStatus: {
          $set: 'error',
        },
        fetchExistingMortgageDocumentsError: {$set: action.payload.error},
      })
    }

    case FETCH_EXISTING_MORTGAGE_DOCUMENTS_SUCCESS: {
      return update(state, {
        fetchExistingMortgageDocumentsStatus: {$set: 'success'},
        documents: {
          $set: action.payload.documents,
        },
      })
    }

    case ADD_EXISTING_MORTGAGE_DOCUMENT_REQUEST: {
      return state
    }

    case ADD_EXISTING_MORTGAGE_DOCUMENT_FAILURE: {
      return state
    }

    case ADD_EXISTING_MORTGAGE_DOCUMENT_SUCCESS: {
      return update(state, {
        documents: {
          $push: [action.payload.document],
        },
      })
    }

    // case UPDATE_EXISTING_MORTGAGE_DOCUMENT_REQUEST: {
    //   return state
    // }

    // case UPDATE_EXISTING_MORTGAGE_DOCUMENT_FAILURE: {
    //   return state
    // }

    // case UPDATE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS: {
    //   let index = state.documents.findIndex(
    //     x => x.id === action.payload.document.id,
    //   )

    //   return update(state, {
    //     documents: {
    //       [index]: {$set: action.payload.document},
    //     },
    //   })
    // }

    case UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_REQUEST: {
      return state
    }

    case UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_FAILURE: {
      return state
    }

    case UPLOAD_EXISTING_MORTGAGE_DOCUMENT_FILE_SUCCESS: {
      let index = state.documents.findIndex(
        x => x.id === action.payload.document.id,
      )

      return update(state, {
        documents: {
          [index]: {$set: action.payload.document},
        },
      })
    }

    case DELETE_EXISTING_MORTGAGE_DOCUMENT_REQUEST: {
      return state
    }

    case DELETE_EXISTING_MORTGAGE_DOCUMENT_FAILURE: {
      return state
    }

    case DELETE_EXISTING_MORTGAGE_DOCUMENT_SUCCESS: {
      let index = state.documents.findIndex(x => x.id === action.payload.id)

      return update(state, {
        documents: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
