export const FETCH_ACCOUNT_USER_DOCUMENTS_REQUEST = 'FETCH_ACCOUNT_USER_DOCUMENTS_REQUEST'
export const FETCH_ACCOUNT_USER_DOCUMENTS_SUCCESS = 'FETCH_ACCOUNT_USER_DOCUMENTS_SUCCESS'
export const FETCH_ACCOUNT_USER_DOCUMENTS_FAILURE = 'FETCH_ACCOUNT_USER_DOCUMENTS_FAILURE'

export const FETCH_DOCUMENT_REQUEST = 'FETCH_DOCUMENT_REQUEST'
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS'
export const FETCH_DOCUMENT_FAILURE = 'FETCH_DOCUMENT_FAILURE'

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST'
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE'

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST'
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS'
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE'

export const ARCHIVE_DOCUMENT_REQUEST = 'ARCHIVE_DOCUMENT_REQUEST'
export const ARCHIVE_DOCUMENT_SUCCESS = 'ARCHIVE_DOCUMENT_SUCCESS'
export const ARCHIVE_DOCUMENT_FAILURE = 'ARCHIVE_DOCUMENT_FAILURE'

export const UNARCHIVE_DOCUMENT_REQUEST = 'UNARCHIVE_DOCUMENT_REQUEST'
export const UNARCHIVE_DOCUMENT_SUCCESS = 'UNARCHIVE_DOCUMENT_SUCCESS'
export const UNARCHIVE_DOCUMENT_FAILURE = 'UNARCHIVE_DOCUMENT_FAILURE'

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE'