import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: ${props => props.theme.space.l};
  padding-right: ${props => props.theme.space.l};
  padding-bottom: ${props => props.theme.space.l};
`

function ProspectivePropertyResourceCardRow(props) {
  return (
    <Wrapper paddingLeft={props.paddingLeft}>
      <Row>
        {props.children}
      </Row>
    </Wrapper>
  )
}

export default ProspectivePropertyResourceCardRow
