import update from 'immutability-helper'
import { FETCH_PROSPECTIVE_PROPERTY_REQUEST } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_SUCCESS } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { FETCH_PROSPECTIVE_PROPERTY_FAILURE } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { UPDATE_PROSPECTIVE_PROPERTY_REQUEST } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { UPDATE_PROSPECTIVE_PROPERTY_SUCCESS } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { UPDATE_PROSPECTIVE_PROPERTY_FAILURE } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_REQUEST } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_SUCCESS } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'
import { UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_FAILURE } from 'v2/spas/prospective-property/prospective-property/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  prospectiveProperty: {
    property: {
      supportNeeded: null,
      address: {}
    }
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTIVE_PROPERTY_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_PROSPECTIVE_PROPERTY_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        prospectiveProperty: {
          $set: action.payload.prospectiveProperty,
        }
      })
    }

    case UPDATE_PROSPECTIVE_PROPERTY_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_PROPERTY_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_PROPERTY_SUCCESS: {
      let prospectiveProperty = action.payload.prospectiveProperty || {}
      let property = prospectiveProperty.property || {}
      let address = property.address || {}

      return update(state, {
        prospectiveProperty: {
          name: {
            $set: prospectiveProperty.name,
          },
          link: {
            $set: prospectiveProperty.link,
          },
          property: {
            address: {
              $set: address
            }
          }
        }
      })
    }

    case UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_REQUEST: {
      return state
    }

    case UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_FAILURE: {
      return state
    }

    case UPDATE_PROSPECTIVE_PROPERTY_SUPPORT_NEEDED_SUCCESS: {
      return update(state, {
        prospectiveProperty: {
          property: {
            supportNeeded: { $set: action.payload.supportNeeded }
          }
        }
      })
    }

    default:
      return state
  }
}