import update from 'immutability-helper'
import { FETCH_REMORTGAGE_DEAL_REQUEST } from 'v2/spas/owned-property/remortgage-deals/redux/action-types.tsx'
import { FETCH_REMORTGAGE_DEAL_SUCCESS } from 'v2/spas/owned-property/remortgage-deals/redux/action-types.tsx'
import { FETCH_REMORTGAGE_DEAL_FAILURE } from 'v2/spas/owned-property/remortgage-deals/redux/action-types.tsx'
import { UPDATE_REMORTGAGE_DEAL_REQUEST } from 'v2/spas/owned-property/remortgage-deals/redux/action-types.tsx'
import { UPDATE_REMORTGAGE_DEAL_SUCCESS } from 'v2/spas/owned-property/remortgage-deals/redux/action-types.tsx'
import { UPDATE_REMORTGAGE_DEAL_FAILURE } from 'v2/spas/owned-property/remortgage-deals/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  remortgageDeal: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_REMORTGAGE_DEAL_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_REMORTGAGE_DEAL_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_REMORTGAGE_DEAL_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        remortgageDeal: {
          $set: action.payload.remortgageDeal,
        }
      })
    }

    case UPDATE_REMORTGAGE_DEAL_REQUEST: {
      return update(state, {
        updateRemortgageDealStatus: { $set: 'requested' },
      })
    }

    case UPDATE_REMORTGAGE_DEAL_FAILURE: {
      return update(state, {
        updateRemortgageDealStatus: {
          $set: 'error',
        },
        updateRemortgageDealError: { $set: action.payload.error },
      })
    }

    case UPDATE_REMORTGAGE_DEAL_SUCCESS: {
      return update(state, {
        updateRemortgageDealStatus: { $set: 'success' },
        remortgageDeal: {
          $set: action.payload.remortgageDeal,
        }
      })
    }

    default:
      return state
  }
}