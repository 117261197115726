import update from 'immutability-helper'
import { FETCH_PROPERTY_DEALS_REQUEST } from 'redux/actionTypes.js'
import { FETCH_PROPERTY_DEALS_FAILURE } from 'redux/actionTypes.js'
import { FETCH_PROPERTY_DEALS_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  fetchPropertyDealsStatus: 'requested',
  fetchPropertyDealsError: '',
  property: {},
  deals: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTY_DEALS_REQUEST: {
      return update(state, {
        fetchPropertyDealsStatus: { $set: 'requested' },
      })
    }

    case FETCH_PROPERTY_DEALS_FAILURE: {
      return update(state, {
        fetchPropertyDealsStatus: {
          $set: 'error',
        },
        fetchPropertyDealsError: { $set: action.payload.error },
      })
    }

    case FETCH_PROPERTY_DEALS_SUCCESS: {
      return update(state, {
        fetchPropertyDealsStatus: { $set: 'success' },
        property: {
          $set: action.payload.property,
        },
        deals: {
          $set: action.payload.property.deals,
        },
      })
    }

    default:
      return state
  }
}
