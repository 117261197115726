import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  font-size: ${props => props.fontSize || props.theme.space.m};
`

function StatusMobileTextContainer(props) {
  return (
    <Wrapper fontSize={props.fontSize}>
      {props.children}
    </Wrapper>
  )
}

export default StatusMobileTextContainer