import {JOIN_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {JOIN_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {JOIN_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function joinAccount(values, handleResponse) {
  return function (dispatch) {
    dispatch(joinAccountRequest())

    let variables = {
      firstName: values.firstName,
      lastName: values.lastName,
      termsAndConditions: values.termsAndConditions,
      processPropertyInvestmentData: values.processPropertyInvestmentData,
    }

    let query = `
      mutation($firstName: String!, $lastName: String!, $termsAndConditions: Boolean!, $processPropertyInvestmentData: Boolean!) {
        joinAccount(
          firstName: $firstName
          lastName: $lastName
          termsAndConditions: $termsAndConditions
          processPropertyInvestmentData: $processPropertyInvestmentData
        ) {
          id
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(joinAccountFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(joinAccountSuccess())
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(joinAccountFailure())
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const joinAccountRequest = () => ({
  type: JOIN_ACCOUNT_REQUEST,
})

export const joinAccountSuccess = () => ({
  type: JOIN_ACCOUNT_SUCCESS,
})

export const joinAccountFailure = error => ({
  type: JOIN_ACCOUNT_FAILURE,
  payload: {
    error: error,
  },
})
