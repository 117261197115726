import {CHANGE_EMAIL_REQUEST_REQUEST} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_REQUEST_SUCCESS} from 'redux/actionTypes.js'
import {CHANGE_EMAIL_REQUEST_FAILURE} from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function changeEmailRequest(emailIdentityId, values, handleResponse) {
  return function (dispatch) {
    dispatch(changeEmailRequestRequest())

    let variables = {
      emailIdentityId: emailIdentityId,
      email: values.email,
    }

    let query = `
      mutation($emailIdentityId: String!, $email: String!) {
        changeEmailRequest(
          emailIdentityId: $emailIdentityId
          email: $email
        ) {
          message
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(changeEmailRequestFailure(error))
          handleResponse({status: 'error', error: error})
        } else {
          dispatch(changeEmailRequestSuccess())
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(changeEmailRequestFailure())
        handleResponse({status: 'error', error: error.message})
      })
  }
}

export const changeEmailRequestRequest = () => ({
  type: CHANGE_EMAIL_REQUEST_REQUEST,
})

export const changeEmailRequestSuccess = () => ({
  type: CHANGE_EMAIL_REQUEST_SUCCESS,
})

export const changeEmailRequestFailure = error => ({
  type: CHANGE_EMAIL_REQUEST_FAILURE,
  payload: {
    error: error,
  },
})
