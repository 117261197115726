import update from 'immutability-helper'
import { FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_PRODUCT_REQUEST } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_PRODUCT_SUCCESS } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_PRODUCT_FAILURE } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { UPDATE_MORTGAGE_PRODUCT_REQUEST } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { UPDATE_MORTGAGE_PRODUCT_SUCCESS } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { UPDATE_MORTGAGE_PRODUCT_FAILURE } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { DELETE_MORTGAGE_PRODUCT_REQUEST } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { DELETE_MORTGAGE_PRODUCT_SUCCESS } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'
import { DELETE_MORTGAGE_PRODUCT_FAILURE } from 'v2/spas/account/mortgage-products/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  account: {},
  mortgageProducts: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_MORTGAGE_PRODUCTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_PRODUCTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_PRODUCTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        account: {
          $set: action.payload.account,
        },
        mortgageProducts: {
          $set: action.payload.account.mortgageProducts
        },
      })
    }

    case UPDATE_MORTGAGE_PRODUCT_REQUEST: {
      return state
    }

    case UPDATE_MORTGAGE_PRODUCT_FAILURE: {
      return state
    }

    case UPDATE_MORTGAGE_PRODUCT_SUCCESS: {
      let index = state.mortgageProducts.findIndex(
        x => x.id === action.payload.mortgageProduct.id,
      )

      return update(state, {
        mortgageProducts: {
          [index]: { $set: action.payload.mortgageProduct },
        },
      })
    }

    case ARCHIVE_MORTGAGE_PRODUCT_REQUEST: {
      return state
    }

    case ARCHIVE_MORTGAGE_PRODUCT_FAILURE: {
      return state
    }

    case ARCHIVE_MORTGAGE_PRODUCT_SUCCESS: {
      let index = state.mortgageProducts.findIndex(x => x.id === action.payload.mortgageProduct.id)

      return update(state, {
        mortgageProducts: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_MORTGAGE_PRODUCT_REQUEST: {
      return state
    }

    case DELETE_MORTGAGE_PRODUCT_FAILURE: {
      return state
    }

    case DELETE_MORTGAGE_PRODUCT_SUCCESS: {
      let index = state.mortgageProducts.findIndex(x => x.id === action.payload.id)

      return update(state, {
        mortgageProducts: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
