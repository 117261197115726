import update from 'immutability-helper'
import { FETCH_TENANCY_PORTFOLIO_REPORT_REQUEST } from 'redux/actionTypes.js'
import { FETCH_TENANCY_PORTFOLIO_REPORT_SUCCESS } from 'redux/actionTypes.js'
import { FETCH_TENANCY_PORTFOLIO_REPORT_FAILURE } from 'redux/actionTypes.js'

const initialState = {
  fetchOwnedPropertiesStatus: 'requested',
  fetchOwnedPropertiesError: '',
  ownedProperties: [],
  totalMonthlyRent: 0,
  totalRentArrears: 0,
  currentTenancies: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANCY_PORTFOLIO_REPORT_REQUEST: {
      return update(state, {
        fetchOwnedPropertiesStatus: { $set: 'requested' },
      })
    }

    case FETCH_TENANCY_PORTFOLIO_REPORT_FAILURE: {
      return update(state, {
        fetchOwnedPropertiesStatus: {
          $set: 'error',
        },
        fetchOwnedPropertiesError: { $set: action.payload.error },
      })
    }

    case FETCH_TENANCY_PORTFOLIO_REPORT_SUCCESS: {
      return update(state, {
        fetchOwnedPropertiesStatus: { $set: 'success' },
        ownedProperties: {
          $set: action.payload.currentAccount.ownedProperties,
        },
        currentTenancies: {
          $set: action.payload.currentAccount.counters.currentTenancies,
        },
        totalMonthlyRent: {
          $set: action.payload.currentAccount.totalMonthlyRent,
        },
        totalRentArrears: {
          $set: action.payload.currentAccount.totalRentArrears,
        },
      })
    }

    default:
      return state
  }
}
