function useInvestorPackHeaderHeight(preview, presentMode) {
  if (preview) {
    return '10px'
  } else if (presentMode) {
    return '10vh'
  } else {
    return '120px'
  }
}

export default useInvestorPackHeaderHeight
