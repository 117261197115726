export const FETCH_TENANCY_GUARANTORS_REQUEST = 'FETCH_TENANCY_GUARANTORS_REQUEST'
export const FETCH_TENANCY_GUARANTORS_SUCCESS = 'FETCH_TENANCY_GUARANTORS_SUCCESS'
export const FETCH_TENANCY_GUARANTORS_FAILURE = 'FETCH_TENANCY_GUARANTORS_FAILURE'

export const CREATE_GUARANTOR_REQUEST = 'CREATE_GUARANTOR_REQUEST'
export const CREATE_GUARANTOR_SUCCESS = 'CREATE_GUARANTOR_SUCCESS'
export const CREATE_GUARANTOR_FAILURE = 'CREATE_GUARANTOR_FAILURE'

export const ARCHIVE_GUARANTOR_REQUEST = 'ARCHIVE_GUARANTOR_REQUEST'
export const ARCHIVE_GUARANTOR_SUCCESS = 'ARCHIVE_GUARANTOR_SUCCESS'
export const ARCHIVE_GUARANTOR_FAILURE = 'ARCHIVE_GUARANTOR_FAILURE'

export const UNARCHIVE_GUARANTOR_REQUEST = 'UNARCHIVE_GUARANTOR_REQUEST'
export const UNARCHIVE_GUARANTOR_SUCCESS = 'UNARCHIVE_GUARANTOR_SUCCESS'
export const UNARCHIVE_GUARANTOR_FAILURE = 'UNARCHIVE_GUARANTOR_FAILURE'

export const DELETE_GUARANTOR_REQUEST = 'DELETE_GUARANTOR_REQUEST'
export const DELETE_GUARANTOR_SUCCESS = 'DELETE_GUARANTOR_SUCCESS'
export const DELETE_GUARANTOR_FAILURE = 'DELETE_GUARANTOR_FAILURE'