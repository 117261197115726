import update from 'immutability-helper'
import { FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_REQUEST } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_SUCCESS } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_FAILURE } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_PRODUCT_REQUEST } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_PRODUCT_SUCCESS } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'
import { DELETE_BRIDGING_LOAN_PRODUCT_FAILURE } from 'v2/spas/account/bridging-loan-products/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  account: {},
  bridgingLoanProducts: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_BRIDGING_LOAN_PRODUCTS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        account: {
          $set: action.payload.account,
        },
        bridgingLoanProducts: {
          $set: action.payload.account.bridgingLoanProducts
        },
      })
    }

    case UPDATE_BRIDGING_LOAN_PRODUCT_REQUEST: {
      return state
    }

    case UPDATE_BRIDGING_LOAN_PRODUCT_FAILURE: {
      return state
    }

    case UPDATE_BRIDGING_LOAN_PRODUCT_SUCCESS: {
      let index = state.bridgingLoanProducts.findIndex(
        x => x.id === action.payload.bridgingLoanProduct.id,
      )

      return update(state, {
        bridgingLoanProducts: {
          [index]: { $set: action.payload.bridgingLoanProduct },
        },
      })
    }

    case ARCHIVE_BRIDGING_LOAN_PRODUCT_REQUEST: {
      return state
    }

    case ARCHIVE_BRIDGING_LOAN_PRODUCT_FAILURE: {
      return state
    }

    case ARCHIVE_BRIDGING_LOAN_PRODUCT_SUCCESS: {
      let index = state.bridgingLoanProducts.findIndex(x => x.id === action.payload.bridgingLoanProduct.id)

      return update(state, {
        bridgingLoanProducts: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_BRIDGING_LOAN_PRODUCT_REQUEST: {
      return state
    }

    case DELETE_BRIDGING_LOAN_PRODUCT_FAILURE: {
      return state
    }

    case DELETE_BRIDGING_LOAN_PRODUCT_SUCCESS: {
      let index = state.bridgingLoanProducts.findIndex(x => x.id === action.payload.id)

      return update(state, {
        bridgingLoanProducts: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
