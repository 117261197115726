import React, {useState} from 'react'
import ProspectivePropertyResourceCard from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCard.js'
import ProspectivePropertyResourceCardHeader from 'spas/ProspectiveProperty/ProspectivePropertyResourceCard/ProspectivePropertyResourceCardHeader.js'
import {FormModal} from 'modals'
import {EditResourceButton} from 'buttons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import { Button } from 'buttons'
import {connect} from 'react-redux'
import {emailPersonalData} from 'redux/actions/profile/emailPersonalData'
import {Link} from 'react-router-dom'
import {useDevice} from 'sharedComponents/useDevice'
import {useSnackbar} from 'notistack'
import {useCurrentAccountUser} from 'sharedComponents/useCurrentAccountUser'
import {useCurrentEmailIdentity} from 'sharedComponents/useCurrentEmailIdentity'

const Description = styled.div`
  padding-top: 25px;
  padding-left: 50px;
  text-align: left;

  @media ${props => props.theme.devices.mobile} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.tablet} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.desktop} and (max-width: 1199px) {
    padding-left: 30px;
  }
`

const Data = styled.div`
  padding-top: 25px;
  padding-left: 150px;
  text-align: left;

  @media ${props => props.theme.devices.mobile} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.tablet} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.laptop} {
    padding-left: 0;
    margin-right: 20px;
    margin-left: 30px;
  }

  @media ${props => props.theme.devices.desktop} and (max-width: 1199px) {
    padding-left: 30px;
  }
`

const EmailDataButtonWrapper = styled.div`
  padding-top: 10px;
`

function PersonalDataCard(props) {
  const {enqueueSnackbar} = useSnackbar()
  const device = useDevice()
  const [show, setShow] = useState(false)

  const currentAccountUser = useCurrentAccountUser()
  const currentEmailIdentity = useCurrentEmailIdentity()

  function emailPersonalData() {
    props.emailPersonalData(handleResponse)
  }

  function handleResponse(response) {
    if (response.status === 'success') {
      enqueueSnackbar('Please check your emails!')
      setShow(false)
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      setShow(false)
    }
  }

  const text = device === 'desktop' ? 'opposite' : 'below'

  return (
    <ProspectivePropertyResourceCard>
      <ProspectivePropertyResourceCardHeader heading="My Personal Data">
        <EditResourceButton text="Manage" onClick={() => setShow(true)} />
      </ProspectivePropertyResourceCardHeader>

      <FormModal
        show={show}
        setShow={setShow}
        heading="Personal Data"
        size="xl"
      >
        <Row>
          <Col lg="6" md="12" sm="12" xs="12">
            <Description>
              <p>
                As part of the{' '}
                <a
                  href="https://ec.europa.eu/info/law/law-topic/data-protection_en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  General Data Protection Regulation (GDPR)
                </a>{' '}
                - you have a right to view the Personally Identitiable
                Information we are storing about you.
              </p>
              <p>You can view all your personal data listed {text}.</p>
              <p>If you believe this information is inaccurate you can:</p>
              <ul>
                <li>
                  Update certain information by visiting your profile page
                </li>
                <li>
                  Contact us anytime by clicking the Support button in the
                  bottom right hand corner of every page on our platform.
                </li>
              </ul>
              <p>
                If you believe we are storing additional Personally Identitiable
                Information that is not listed here, please contact us and we
                will take steps to update this information.
              </p>
              <p>
                You can find more information about how we protect your data by
                reviewing our{' '}
                <Link
                  to="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </Link>{' '}
                and our{' '}
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy.
                </Link>{' '}
              </p>
              <p>
                If you have any further questions about how your data is
                protected, you can contact us any time, by clicking the Help
                button in the bottom right hand corner of every page on our
                platform.
              </p>
            </Description>
          </Col>

          <Col lg="6" md="12" sm="12" xs="12">
            <Data>
              <h6>Names</h6>
              <ul>
                <li>First name - {currentAccountUser.firstName}</li>
                <li>Last name - {currentAccountUser.lastName}</li>
              </ul>
              <h6>Contact information</h6>
              <ul>
                <li>Email address - {currentEmailIdentity.email}</li>
              </ul>
              <hr />
              <h6>Want a copy of this information?</h6>
              <p>No problem - just click the link below.</p>
              <EmailDataButtonWrapper>
                <Button onClick={emailPersonalData} content="Email this to me" />
              </EmailDataButtonWrapper>
            </Data>
          </Col>
        </Row>
      </FormModal>
    </ProspectivePropertyResourceCard>
  )
}

const mapDispatchToProps = {
  emailPersonalData: emailPersonalData,
}

export default connect(null, mapDispatchToProps)(PersonalDataCard)
