import React from 'react'
import Spinner from 'sharedComponents/Spinner.js'
import styled from 'styled-components'

const SpinnerWrapper = styled.div`
  width: 100%;
  padding: 25px;
  text-align: center;
`

function FetchResource(props) {
  let status = props.status
  let error = props.error

  function noCurrentUser() {
    return status === 'error' && error.includes('There is no current user')
  }

  if (status === 'requested') {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  } else if (noCurrentUser()) {
    return props.children
  } else if (status === 'error') {
    return (
      <SpinnerWrapper>
        <Spinner color="red" />
        <p>{error}</p>
        <p>If this error persists - please contact support 🙏</p>
      </SpinnerWrapper>
    )
  } else if (status === 'success') {
    return props.children
  } else {
    return (
      <SpinnerWrapper>
        <Spinner color="red" />
        <p>
          Invalid status of <strong>{status}</strong> supplied to FetchResource
          component
        </p>
      </SpinnerWrapper>
    )
  }
}

export default FetchResource
