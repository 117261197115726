import React, {useState} from 'react'
import Image from 'react-bootstrap/Image'
import styled from 'styled-components'

import {useCurrentAccountUser} from 'sharedComponents/useCurrentAccountUser'
import {useInitials} from 'sharedComponents/useInitials'
import {FormModal} from 'modals'
import AvatarForm from 'spas/MortgageBrokerProfile/AvatarForm'

const AvatarWrapper = styled.div`
  cursor: pointer;
`

const Avatar = styled.div`
  width: 125px;
  height: 125px;
  border: 4px solid #ffffff;
  border-radius: 108px;
  background-color: ${props => props.theme.colors.primary};
`

const ImageWrapper = styled.div`
  img {
    width: 125px;
    height: 130px;
    object-fit: cover;
  }
`

const AvatarText = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`

function UserAvatar() {
  const [showAvatarForm, setShowAvatarForm] = useState(false)
  const currentAccountUser = useCurrentAccountUser()
  const initials = useInitials(currentAccountUser)

  function renderAvatar() {
    if (currentAccountUser.avatarUrl) {
      return (
        <ImageWrapper onClick={() => setShowAvatarForm(true)}>
          <Image roundedCircle src={currentAccountUser.avatarUrl} />
        </ImageWrapper>
      )
    }
    return (
      <Avatar onClick={() => setShowAvatarForm(true)}>
        <AvatarText>{initials}</AvatarText>
      </Avatar>
    )
  }

  return (
    <React.Fragment>
      <AvatarWrapper>{renderAvatar()}</AvatarWrapper>
      <FormModal
        show={showAvatarForm}
        setShow={setShowAvatarForm}
        heading="Edit Profile Picture">
        <AvatarForm showModal={setShowAvatarForm} />
      </FormModal>
    </React.Fragment>
  )
}

export default UserAvatar
